import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'src/assets/img/old-img/logo-mobile.svg';
import { authActions } from 'src/store/actions';

const Header = () => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (window.innerWidth < 992) {
      const mobMenuBtn = document.querySelectorAll('.header__sub__menu__item__m_btn');
      mobMenuBtn.forEach((item) => {
        item.addEventListener('click', () => {
          item.closest('.header__sub__menu__item').classList.toggle('active');
        });
      });
    }
  }, []);

  const handleLogOut = async () => {
    await dispatch(authActions.logout());
    navigate('/');
  };

  return (
    <>
      <header className="m_header">
        <div className="wrap">
          <div className="m_header__wrap">
            <button
              className={`mobBurger ${menu ? 'active' : ''}`}
              type="button"
              onClick={() => setMenu(!menu)}
            >
              <div className="mobBurger__line" />
              <div className="mobBurger__line" />
              <div className="mobBurger__line" />
            </button>
            <Link to="/" className="m_header__logo">
              <img src={logo} alt="" />
              ExpertLand
            </Link>
            <nav className="header__menu">
              <ul>
                <li>
                  <Link to="/admin/users">Користувачі</Link>
                  <div className="header__menu__sub">
                    <Link to="/admin/users-logs/">Журнал змін</Link>
                  </div>
                </li>
                <li>
                  <Link to="/admin/projects">Проєкти</Link>
                  <div className="header__menu__sub">
                    <Link to="/admin/projects-logs/">Журнал змін</Link>
                  </div>
                </li>
                <li>
                  <Link to="/admin/payment">Платежі</Link>
                </li>
                <li>
                  <Link to="/admin/baza">База знань</Link>
                  <div className="header__menu__sub">
                    <Link to="/admin/baza-logs/">Журнал змін</Link>
                  </div>
                </li>
              </ul>
            </nav>
            <nav className="header__auth">
              <button type="button" className="header__auth__signUp" onClick={handleLogOut}>
                Вихід
              </button>
            </nav>
          </div>
        </div>
      </header>
      <nav className={`header__sub ${menu ? 'active' : ''}`}>
        <div className="wrap">
          <div className="header__sub__mob">
            <nav className="header__menu">
              <ul>
                <li>
                  <Link to="/admin/users">Пользователи</Link>
                </li>
                <li>
                  <Link to="/admin/projects">Проекты</Link>
                </li>
                <li>
                  <Link to="/admin/projects-logs/">Журнал змін проєктів</Link>
                </li>
                <li>
                  <Link to="/admin/payment">Платежи</Link>
                </li>
                <li>
                  <Link to="/admin/baza">База знаний</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
