import { createSlice } from '@reduxjs/toolkit';

import { paymentActions } from '../actions';

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    services: [],
    servicesDop: null,
    paidServices: [],
    paidServicesPosition: [],
    paidServicesDop: [],
    packages: [],
    paidPackages: [],
    referralUrl: '',
    paymentsHistory: [],
    errors: false,
    waiter: false,
    waiterServices: false,
    waiterPaided: false,
    fixWaiter: false,
  },
  reducers: {
    clearErrors(state) {
      state.errors = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getServices
      .addCase(paymentActions.getServices.pending, (state) => {
        state.waiterServices = true;
      })
      .addCase(paymentActions.getServices.fulfilled, (state, action) => {
        state.services = action.payload.data;
        state.waiterServices = false;
      })
      .addCase(paymentActions.getServices.rejected, (state, action) => {
        state.waiterServices = false;
        state.errors = action.payload.errors;
      })

      // getServicesCompany
      .addCase(paymentActions.getServicesCompany.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(paymentActions.getServicesCompany.fulfilled, (state, action) => {
        state.services = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(paymentActions.getServicesCompany.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // getPaidServices
      .addCase(paymentActions.getPaidServices.pending, (state) => {
        state.waiterServices = true;
      })
      .addCase(paymentActions.getPaidServices.fulfilled, (state, action) => {
        state.paidServices = action.payload.data;
        state.waiterServices = false;
      })
      .addCase(paymentActions.getPaidServices.rejected, (state, action) => {
        state.waiterServices = false;
        state.errors = action.payload.errors;
      })

      // getPaidServicesCompany
      .addCase(paymentActions.getPaidServicesCompany.pending, (state) => {
        state.waiterPaided = true;
      })
      .addCase(paymentActions.getPaidServicesCompany.fulfilled, (state, action) => {
        state.paidServices = action.payload.data;
        state.waiterPaided = false;
      })
      .addCase(paymentActions.getPaidServicesCompany.rejected, (state, action) => {
        state.waiterPaided = false;
        state.errors = action.payload.errors;
      })

      // getPaidServicesCompanyPositionOfProject
      .addCase(paymentActions.getPaidServicesCompanyPositionOfProject.pending, (state) => {
        state.waiterPaided = true;
      })
      .addCase(
        paymentActions.getPaidServicesCompanyPositionOfProject.fulfilled,
        (state, action) => {
          state.paidServicesPosition = action.payload.data;
          state.waiterPaided = false;
        },
      )
      .addCase(paymentActions.getPaidServicesCompanyPositionOfProject.rejected, (state, action) => {
        state.waiterPaided = false;
        state.errors = action.payload.errors;
      })

      // businessСategoriesService
      .addCase(paymentActions.businessСategoriesService.pending, (state) => {
        state.waiter = true;
      })
      .addCase(paymentActions.businessСategoriesService.fulfilled, (state, action) => {
        state.servicesDop = action.payload.data;
        state.waiter = false;
      })
      .addCase(paymentActions.businessСategoriesService.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // paidBusinessCategories
      .addCase(paymentActions.paidBusinessCategories.pending, (state) => {
        state.waiter = true;
      })
      .addCase(paymentActions.paidBusinessCategories.fulfilled, (state, action) => {
        state.paidServicesDop = action.payload.data;
        state.waiter = false;
      })
      .addCase(paymentActions.paidBusinessCategories.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // getPackages
      .addCase(paymentActions.getPackages.pending, (state) => {
        state.waiter = true;
      })
      .addCase(paymentActions.getPackages.fulfilled, (state, action) => {
        state.packages = action.payload.data;
        state.waiter = false;
      })
      .addCase(paymentActions.getPackages.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // getPackagesCompany
      .addCase(paymentActions.getPackagesCompany.pending, (state) => {
        state.waiter = true;
      })
      .addCase(paymentActions.getPackagesCompany.fulfilled, (state, action) => {
        state.packages = action.payload.data;
        state.waiter = false;
      })
      .addCase(paymentActions.getPackagesCompany.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // getPaidPackages
      .addCase(paymentActions.getPaidPackages.pending, (state) => {
        state.waiter = true;
      })
      .addCase(paymentActions.getPaidPackages.fulfilled, (state, action) => {
        state.paidPackages = action.payload.data;
        state.waiter = false;
      })
      .addCase(paymentActions.getPaidPackages.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // getPaidPackagesCompany
      .addCase(paymentActions.getPaidPackagesCompany.pending, (state) => {
        state.waiter = true;
      })
      .addCase(paymentActions.getPaidPackagesCompany.fulfilled, (state, action) => {
        state.paidPackages = action.payload.data;
        state.waiter = false;
      })
      .addCase(paymentActions.getPaidPackagesCompany.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // getReferralUrl
      .addCase(paymentActions.getReferralUrl.pending, (state) => {
        state.waiter = true;
      })
      .addCase(paymentActions.getReferralUrl.fulfilled, (state, action) => {
        state.referralUrl = action.payload.data;
        state.waiter = false;
      })
      .addCase(paymentActions.getReferralUrl.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // postPayments
      .addCase(paymentActions.postPayments.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(paymentActions.postPayments.fulfilled, (state) => {
        state.fixWaiter = false;
      })
      .addCase(paymentActions.postPayments.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // postPaymentsCompany
      .addCase(paymentActions.postPaymentsCompany.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(paymentActions.postPaymentsCompany.fulfilled, (state) => {
        state.fixWaiter = false;
      })
      .addCase(paymentActions.postPaymentsCompany.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // paymentsHistory
      .addCase(paymentActions.paymentsHistory.pending, (state) => {
        state.waiter = true;
      })
      .addCase(paymentActions.paymentsHistory.fulfilled, (state, action) => {
        state.paymentsHistory = action.payload.data;
        state.waiter = false;
      })
      .addCase(paymentActions.paymentsHistory.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      });
  },
});

export const { clearErrors } = paymentSlice.actions;
export default paymentSlice.reducer;
