import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import docFile from 'src/assets/img/resume/2.png';
import sprite from 'src/assets/img/sprite.svg';
import { FixLoader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions, customActions } from 'src/store/actions/';
import * as Yup from 'yup';

const Form = ({ show, handleClose, setIsSub }) => {
  const haveEmail = useSelector((state) => state.authReducer.user.email);
  const isDesiner = useSelector((state) => state.authReducer.user.expert.categories[0].id) === 3;
  const waiter = useSelector((state) => state.customReducer.waiter);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const { translate } = useTranslation();

  const [file, setFile] = useState(false);

  const [maxsize, setMaxsize] = useState(false);

  const docRef = useRef();

  const contactSchema = Yup.object().shape({
    name: Yup.string().required(translate('Valid.req')),
  });

  const clear = () => {
    if (docRef.current) {
      docRef.current.value = null;
    }

    setFile(false);
  };

  const onPdf = (event) => {
    const res = event.target.files[0];
    if (res.size < 10485760) {
      setFile(res);

      setMaxsize(false);
    } else {
      clear();
      setMaxsize(true);
    }
  };

  const handleSubmit = async (values) => {
    setError(false);
    const res = await dispatch(customActions.setPortfolio({ values, file }));

    if (res.payload.status === 200) {
      handleClose();
      if (isDesiner) {
        dispatch(authActions.getProfileRefresh());
      }
      setIsSub(true);
      setFile(false);
      dispatch(customActions.getAllPortfolio());
      setTimeout(() => {
        setIsSub(false);
      }, 5000);
    } else {
      setError(res.payload.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: contactSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <>
      {waiter && <FixLoader />}

      <Modal show={show} onHide={handleClose} className="spec-modal">
        <Modal.Body>
          <div className="exp-modal">
            <div className="modal-header">
              <h5 className="modal-title">{translate('Portfolio.modalTitle')}</h5>
              <button type="button" onClick={handleClose} className="spec-modal__close">
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#modalCloseWhite`} />
                </svg>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="exp-modal__addProect">
                <div className="exp-modal__addProect__wrap">
                  <div className="label">{translate('Portfolio.nameLabel')}</div>
                  <input
                    className={`input ${formik.errors.name && formik.touched.name ? 'error' : ''}`}
                    placeholder={translate('Portfolio.namePl')}
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={50}
                  />
                  <div className="invalid-feedback">
                    {formik.errors.name && formik.touched.name && formik.errors.name}
                  </div>
                  <div className="upload-wrap">
                    <label className={`upload-wrap__col ${file ? 'upload' : ''}`} htmlFor="type2">
                      <img src={docFile} alt="" />
                      <div className="upload-wrap__text">{translate('Resume.downloadPdf')}</div>
                      <div className="upload-wrap__text upload-wrap__text--file">
                        {' '}
                        {file && file.name}
                      </div>
                      <input
                        type="file"
                        id="type2"
                        onChange={onPdf}
                        accept="application/pdf"
                        ref={docRef}
                      />
                      <button className="upload-wrap__col__button" type="button" onClick={clear}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref={`${sprite}#buttonClear`} />
                        </svg>
                      </button>
                    </label>
                  </div>
                  {maxsize && <div className="error-text mb-3">{translate('Resume.maxsize')}</div>}
                  {formik.errors.file && (
                    <div className="error-text mb-3">{formik.errors.file}</div>
                  )}
                  {error && <div className="error-text mb-3">{error}</div>}
                  {!haveEmail && (
                    <div className="error-text mb-3">{translate('Portfolio.haventEmail')}</div>
                  )}
                </div>
              </div>
              <br />
              <div className="upload-wrap__btns">
                <button type="submit" className="btn btn-primary" disabled={!file || !haveEmail}>
                  {translate('save')}
                </button>
                <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
                  {translate('cancel')}
                </button>
              </div>
              <br />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

Form.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setIsSub: PropTypes.func.isRequired,
};

export default Form;
