import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Route, Routes, useParams } from 'react-router-dom';
import { FixLoader } from 'src/components';
import { adminActions } from 'src/store/actions';

import Chat from './chat';
import Info from './info';
import Reviews from './Reviews';

const Edit = () => {
  const dispatch = useDispatch();

  const project = useSelector((state) => state.adminReducer.project);
  const projectFixWaiter = useSelector((state) => state.adminReducer.projectFixWaiter);

  const { id } = useParams();

  useEffect(() => {
    dispatch(adminActions.getProject({ id }));
  }, []);

  return (
    <>
      {projectFixWaiter ? (
        <FixLoader />
      ) : (
        <>
          {project && (
            <>
              <div className="admin_knowledge_header">{project.name}</div>
              <div className="admin-user__contacts">
                <div>Project ID: {project.id}</div>
              </div>
              <div className="admin_project_detail">
                <ul className="admin-user-detail__nav">
                  <li>
                    <NavLink to="info">Інформація про проєкт</NavLink>
                  </li>
                  <li>
                    {project.chatIsActive ? <NavLink to="chat">Чат</NavLink> : <>Чат не активний</>}
                  </li>
                  <li>
                    {project.expertReview || project.companyReview ? (
                      <NavLink to="reviews">Відгуки</NavLink>
                    ) : (
                      <>Відгуків немає</>
                    )}
                  </li>
                </ul>
                <div className="admin-user-detail__content">
                  <Routes>
                    <>
                      <Route path="/info" element={<Info />} />
                      <Route path="/chat" element={<Chat />} />
                      <Route path="/reviews" element={<Reviews />} />
                    </>
                  </Routes>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Edit;
