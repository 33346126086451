import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import tableRu from 'src/assets/img/otbor-dop/test-table-ru.png';
import tableUa from 'src/assets/img/otbor-dop/test-table-ua.png';
import sprite from 'src/assets/img/sprite.svg';
import { Fancybox, Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { otborActions } from 'src/store/actions';

import TimerForTestDop from './timer-for-test-dop';

const serverDomain = process.env.REACT_APP_API_URL;

const OtborTestDop = () => {
  const { translate, language } = useTranslation();

  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.otborReducer.waiter);
  const analyticalQa = useSelector((state) => state.otborReducer.analyticalQa);
  const analyticalResult = useSelector((state) => state.otborReducer.analyticalResult);
  const errors = useSelector((state) => state.otborReducer.errors);
  const [questions, setQuestions] = useState([]);
  const [timerKey, setTimerKey] = useState(1);
  const [time, setTime] = useState(25 * 60);

  useEffect(() => {
    if (analyticalQa) {
      setQuestions(analyticalQa.questions);
      setTimerKey(timerKey + 1);
      const dataStart = Math.ceil(new Date().getTime() / 1000);
      const dataEnd = new Date(analyticalQa.examEnd).getTime() / 1000;

      setTime(dataEnd - dataStart);
    }
  }, [analyticalQa]);

  useEffect(() => {
    dispatch(otborActions.getAnalyticalexam());
  }, []);

  const handleSubmit = async (values) => {
    const data = {
      answers: {},
    };
    values.questions.map((item) => {
      data.answers[item.id] = item.answer;
      return false;
    });

    await dispatch(otborActions.analyticalSendAnswer(data));
    window.scrollTo({
      top: 60,
      behavior: 'instant',
    });
  };

  const formik = useFormik({
    initialValues: {
      questions:
        questions && questions.length
          ? questions.map((item) => ({
              ...item,
              answer: '',
            }))
          : [],
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  const timerOut = () => {
    handleSubmit(formik.values);
  };

  return (
    <>
      {}

      {waiter ? (
        <Loader />
      ) : (
        <>
          {time >= 1 && !errors && (
            <div className="timer--small">
              <TimerForTestDop minutes={0} seconds={time} action={timerOut} key={timerKey} />
            </div>
          )}

          <div className="otbor mb-0">
            {time < 1 ? (
              <>
                {analyticalResult ? (
                  <>
                    <div className="otbor__title">
                      {analyticalResult.score > 0
                        ? translate('otbor.title3')
                        : `${translate('otbor.title3LowScore')} ${analyticalResult.score}/200`}{' '}
                    </div>
                    <div className="otbor__text">
                      <span>
                        {analyticalResult.score > 0 ? (
                          `${translate('otbor.for')} ${analyticalResult.score} ${translate(
                            'otbor.score',
                          )} ${translate('otbor.textDopRes')}`
                        ) : (
                          <>
                            {translate('otbor.notScore')}{' '}
                            <Link to="/baza-znan/">{translate('otbor.analiticLink')}</Link>
                          </>
                        )}
                      </span>
                    </div>
                    <div className="otbor__btn">
                      <Link to="/projects" className="btn btn-primary">
                        {translate('otbor.link')}
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="otbor__title">{translate('otbor.timeOver')}</div>
                    <div className="otbor__text">
                      <span>
                        {translate('otbor.timeOverText')}{' '}
                        <Link to="/baza-znan/">{translate('otbor.timeOverLink')}</Link>
                      </span>
                    </div>
                    <div className="otbor__btn">
                      <Link to="/projects" className="btn btn-primary">
                        {translate('otbor.link')}
                      </Link>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {errors ? (
                  <>
                    <div className="otbor__title">{errors.message}</div>
                    <div className="otbor__text">
                      <span>{translate('otbor.notScore')}</span>
                    </div>
                    <div className="otbor__btn">
                      <Link to="/projects" className="btn btn-primary">
                        {translate('otbor.link')}
                      </Link>
                    </div>
                  </>
                ) : (
                  <form className="otbor__cat" onSubmit={formik.handleSubmit}>
                    <div className="otbor__cat__name">{translate('otbor.anal')}</div>
                    <div className="otbor__list">
                      {formik.values.questions &&
                        formik.values.questions.map((item, questionsIndex) => (
                          <div className="otbor__qa" key={item.id}>
                            {questionsIndex === 17 && (
                              <div className="mb-2">
                                <Fancybox>
                                  <a
                                    href={language === 'ua' ? tableUa : tableRu}
                                    data-fancybox="itemTable"
                                  >
                                    <img alt="table" src={language === 'ua' ? tableUa : tableRu} />
                                  </a>
                                </Fancybox>
                              </div>
                            )}
                            <div
                              className="otbor__qa__text"
                              dangerouslySetInnerHTML={{ __html: item.text }}
                            />
                            {item.picture && (
                              <div className="otbor__qa__img">
                                <Fancybox>
                                  <a
                                    href={serverDomain + item.picture}
                                    data-fancybox={`item${item.id}`}
                                  >
                                    <img src={serverDomain + item.picture} alt="img" />
                                  </a>
                                </Fancybox>
                              </div>
                            )}

                            <div className="otbor__qa__list">
                              {item.answers.map((answer, answerIndex) => (
                                <label
                                  htmlFor={`questions[${questionsIndex}][${answerIndex}]`}
                                  key={answer.id}
                                >
                                  <input
                                    id={`questions[${questionsIndex}][${answerIndex}]`}
                                    type="radio"
                                    name={`questions[${questionsIndex}].answer`}
                                    onChange={() =>
                                      formik.setFieldValue(
                                        `questions[${questionsIndex}].answer`,
                                        answer.id,
                                      )
                                    }
                                  />
                                  <span />
                                  {answer.text}
                                  {answer.picture && (
                                    <div className="otbor__qa__img">
                                      <img src={serverDomain + answer.picture} alt="img" />
                                    </div>
                                  )}
                                </label>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="otbor__btnqa">
                      <button type="submit" className="btn btn-primary">
                        {translate('otbor.end')}
                      </button>
                    </div>
                  </form>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OtborTestDop;
