import { useSelector } from 'react-redux';

const ProjectsTable = () => {
  const projectsLogs = useSelector((state) => state.adminReducer.projectsLogs);
  const fixWaiter = useSelector((state) => state.adminReducer.fixWaiter);

  const renderRecord = (record) => {
    const json = JSON.stringify(record);
    return json;
  };

  const getAction = (action) => {
    switch (action) {
      case 1:
        return 'UPDATE';
      case 2:
        return 'CREATE';
      case 3:
        return 'DELETE';
      case 4:
        return 'RESET_PASSWORD';
      default:
        return '';
    }
  };

  return (
    <div className="knowledge_table_warp">
      {projectsLogs.length ? (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Автор</th>
              <th>Поле</th>
              <th>Дія</th>
              <th>Старе значення</th>
              <th>Нове значення</th>
              <th>Проэкт</th>
              <th>Коли</th>
            </tr>
          </thead>
          <tbody className="admin_knowledge_action_btn">
            {projectsLogs.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>
                  {item.admin.firstName} {item.admin.lastName}
                </td>
                <td>{item.field}</td>
                <td>{getAction(item.action)}</td>
                <td>
                  <div className="code">
                    {item.oldRecord ? renderRecord(item.oldRecord) : item.oldValue}
                  </div>
                </td>
                <td>
                  <div className="code">
                    {item.record ? renderRecord(item.record) : item.newValue}
                  </div>
                </td>
                <td>
                  <a
                    target="_blank"
                    href={`/admin/projects/${item.project.id}/info`}
                    rel="noreferrer"
                  >
                    {item.project.name}
                  </a>
                </td>

                <td>{item.created}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>{!fixWaiter && <div className="mt-2">Нічого не знайдено</div>}</>
      )}
    </div>
  );
};

export default ProjectsTable;
