import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const Breg = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nextStep, setNextStep] = useState(false);
  const waiter = useSelector((state) => state.authReducer.waiter);
  const [haveCat, setHaveCat] = useState(false);
  const { company } = useSelector((state) => state.authReducer.user);
  const sferaList = useSelector((state) =>
    state.authReducer.expertCategories.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  );

  const countriesList = useSelector((state) => state.authReducer.countries);
  const countries = useSelector((state) =>
    state.authReducer.countries.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  );

  const userFirstName = useSelector((state) => state.authReducer.user.firstName);
  const userLastName = useSelector((state) => state.authReducer.user.lastName);
  const userCountryId = useSelector(
    (state) => state.authReducer.user.company && state.authReducer.user.company.countryId,
  );
  const userCityId = useSelector(
    (state) => state.authReducer.user.company && state.authReducer.user.company.cityId,
  );
  const { firstName, lastName } = useSelector((state) => state.authReducer.memberFio);
  const haveCompany = !!useSelector((state) => state.authReducer.user.company);

  useEffect(() => () => dispatch(clearErrors()), []);

  const [show, setShow] = useState(false);

  const handleClose = () => navigate(`/user/about/`);
  const handleShow = () => setShow(true);

  const handleSubmit = async (values) => {
    dispatch(authActions.regExp(values)).then((res) => {
      if (res.payload.status === 200) {
        handleShow();
      }
    });
  };

  useEffect(() => {
    if (sferaList.length === 0) {
      dispatch(authActions.getExpertCategories());
    }
    if (countries.length === 0) {
      dispatch(authActions.getCountries());
    }
  }, []);

  const expRSchema = Yup.object().shape({
    firstName: Yup.string().required(translate('Valid.req')).min(3, translate('Valid.min3')),
    lastName: Yup.string().required(translate('Valid.req')).min(3, translate('Valid.min3')),
    country: Yup.number().required(translate('Valid.req')),
    city: Yup.number().required(translate('Valid.req')),
    companyCategories: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(translate('Valid.req')),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      firstName: firstName || userFirstName || '',
      lastName: lastName || userLastName || '',
      country: userCountryId || 1,
      city: userCityId || '',
      expertCategories: [{ id: 0, uid: uuidv4() }],
    },
    validationSchema: expRSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    if (formik.values.expertCategories.length > 0 && formik.values.expertCategories[0].id !== 0) {
      setHaveCat(true);
    }
  }, [formik.values.expertCategories]);

  const citiesRef = useRef();

  let sitiesFilter = countriesList.filter((item) => item.id === formik.values.country);
  if (sitiesFilter.length) {
    sitiesFilter = sitiesFilter[0].cities.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }

  useEffect(() => {
    if (citiesRef.current) {
      citiesRef.current.clearValue();
    }
  }, [formik.values.country]);

  return (
    <>
      <div className="auth__wrap">
        <div className="container">
          <form className="auth__form auth__form--bb" id="bRegForm" onSubmit={formik.handleSubmit}>
            <Link to="/" className="auth__form__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#close`} />
              </svg>
            </Link>
            <FormikProvider value={formik}>
              {waiter ? (
                <Loader />
              ) : (
                <>
                  <div className="auth__form--bb__wrap" hidden={nextStep}>
                    <div className="auth__form__title">{translate('Auth.expreg')}</div>

                    <FieldArray
                      name="expertCategories"
                      render={() => (
                        <>
                          {formik.values.expertCategories.map((item, index) => (
                            <div className="input__row" key={item.uid}>
                              <Select
                                options={sferaList}
                                name={`expertCategories[${index}].id`}
                                classNamePrefix="react-select"
                                className="react-select"
                                isSearchable={false}
                                setValue={item.id}
                                placeholder={translate('Sfera.zeroOption')}
                                onChange={(e) =>
                                  formik.setFieldValue(`expertCategories[${index}].id`, e.value)
                                }
                              />
                            </div>
                          ))}
                        </>
                      )}
                    />
                    {formik.values.expertCategories.length === 3 && (
                      <div className="its-all">{translate('Valid.catLimit')}</div>
                    )}

                    <div className="auth-bot-btns">
                      {haveCompany ? (
                        <Link to="/profile/" className="btn prev-btn">
                          {translate('back')}
                        </Link>
                      ) : (
                        <Link to="/auth/choose-role" className="btn prev-btn">
                          {translate('back')}
                        </Link>
                      )}

                      <button
                        type="button"
                        className="btn next-btn btn-primary"
                        onClick={() => setNextStep(true)}
                        disabled={!haveCat}
                      >
                        {translate('continue')}
                      </button>
                    </div>
                  </div>

                  <div className="auth__form--bb__wrap" hidden={!nextStep}>
                    <div className="auth__form__title">{translate('Auth.expreg')}</div>

                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="input__row">
                          <input
                            name="firstName"
                            placeholder={translate('Auth.firstName')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            className={
                              formik.errors.firstName && formik.touched.firstName && 'is-invalid'
                            }
                          />
                          <div className="invalid-feedback">
                            {formik.errors.firstName &&
                              formik.touched.firstName &&
                              formik.errors.firstName}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="input__row">
                          <input
                            name="lastName"
                            placeholder={translate('Auth.lastName')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            className={
                              formik.errors.lastName && formik.touched.lastName && 'is-invalid'
                            }
                          />
                          <div className="invalid-feedback">
                            {formik.errors.lastName &&
                              formik.touched.lastName &&
                              formik.errors.lastName}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="input-search-select">
                      <div
                        className={formik.errors.country && formik.touched.country && 'is-invalid'}
                      >
                        <Select
                          options={countries}
                          classNamePrefix="react-select"
                          isDisabled
                          className="react-select select-disabled"
                          placeholder={translate('Auth.country')}
                          onChange={(e) => formik.setFieldValue(`country`, e.value)}
                          value={countries.filter(
                            (option) => option.value === formik.values.country,
                          )}
                        />
                      </div>
                      <div className="invalid-feedback">
                        {formik.errors.country && formik.touched.country && formik.errors.country}
                      </div>
                    </div>

                    {formik.values.country && sitiesFilter && (
                      <div className="input-search-select">
                        <div className={formik.errors.city && formik.touched.city && 'is-invalid'}>
                          <Select
                            ref={citiesRef}
                            options={sitiesFilter}
                            classNamePrefix="react-select"
                            className="react-select"
                            placeholder={translate('Auth.city')}
                            onChange={(e) => {
                              if (e) {
                                formik.setFieldValue(`city`, e.value);
                              }
                            }}
                            value={sitiesFilter.filter(
                              (option) => option.value === formik.values.city,
                            )}
                          />
                        </div>
                        <div className="invalid-feedback">
                          {formik.errors.city && formik.touched.city && formik.errors.city}
                        </div>
                      </div>
                    )}
                    <div className="auth-bot-btns">
                      <button
                        type="button"
                        className="btn prev-btn"
                        onClick={() => setNextStep(false)}
                      >
                        {translate('back')}
                      </button>
                      <button
                        type="submit"
                        className="btn next-btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#modalSenk"
                      >
                        {translate('continue')}
                      </button>
                    </div>
                  </div>
                  {!company && (
                    <div className="auth__form__text  auth__form__text--right">
                      <Link to="/auth/b-reg">{translate('Auth.regComp')}</Link>
                    </div>
                  )}
                </>
              )}
            </FormikProvider>
          </form>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <div className="modal-content modal__wrap">
          <Link to="/user/about/" className="auth__form__close modal-close">
            <div>
              <svg className="icon">
                <use xlinkHref={`${sprite}#close`} />
              </svg>
            </div>
          </Link>
          <div className="modal__title">{translate('Auth.bregSank')}</div>
          <div className="modal__text">{translate('Auth.bregSankText')}</div>
          <Link to="/user/about/" className="btn btn-auth btn-primary">
            {translate('Auth.bregSankBtn')}
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default Breg;
