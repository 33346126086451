import './style.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FixLoader, Footer, Header, Loader, Sidebar, Status, Subheader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { expertProjectActions } from 'src/store/actions';

import { Item, Search } from './components';

const CurrentProjects = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const fixWaiter = useSelector((state) => state.expertProjectReducer.fixWaiter);
  const waiter = useSelector((state) => state.expertProjectReducer.myOffersWaiter);
  const waiterMore = useSelector((state) => state.expertProjectReducer.myOffersWaiterMore);
  const myCurrentProjects = useSelector((state) => state.expertProjectReducer.myCurrentProjects);
  const myCurrentProjectsPag = useSelector(
    (state) => state.expertProjectReducer.myCurrentProjectsPag,
  );
  const myCurrentProjectsComplete = useSelector(
    (state) => state.expertProjectReducer.myCurrentProjectsComplete,
  );
  const myCurrentProjectsCompletePag = useSelector(
    (state) => state.expertProjectReducer.myCurrentProjectsCompletePag,
  );

  // пошук текущих
  const [timer1, setTimer1] = useState(null);
  const [searchText1, setSearchText1] = useState('');
  const handleSearch1 = (newValue) => {
    clearTimeout(timer1);
    setSearchText1(newValue);

    const newTimer = setTimeout(() => {
      dispatch(expertProjectActions.getMyCurrent({ page: 1, name: newValue }));
    }, 1000);

    setTimer1(newTimer);
  };

  // пошук текущих
  const [timer2, setTimer2] = useState(null);
  const [searchText2, setSearchText2] = useState('');
  const handleSearch2 = (newValue) => {
    clearTimeout(timer2);
    setSearchText2(newValue);

    const newTimer = setTimeout(() => {
      dispatch(expertProjectActions.getMyCurrentComplete({ page: 1, name: newValue }));
    }, 1000);

    setTimer2(newTimer);
  };

  const [tab, setTab] = useState(1);

  useEffect(() => {
    if (tab === 1) {
      dispatch(expertProjectActions.getMyCurrent({ page: 1, name: searchText1 }));
    }
    if (tab === 2) {
      dispatch(expertProjectActions.getMyCurrentComplete({ page: 1, name: searchText2 }));
    }
  }, [tab]);

  const loadMore = () => {
    dispatch(
      expertProjectActions.getMyCurrentMore({
        page: myCurrentProjectsPag.current_page + 1,
        name: searchText1,
      }),
    );
  };

  const loadMoreComplete = () => {
    dispatch(
      expertProjectActions.getMyCurrentCompleteMore({
        page: myCurrentProjectsCompletePag.current_page + 1,
        name: searchText2,
      }),
    );
  };

  return (
    <>
      {fixWaiter && <FixLoader />}

      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            <Status />
            <div className="exp-content exp-content--company">
              <Sidebar />
              <div className="exp-content__blocks">
                <div className="exp-content__edit">
                  <div className="exp-content__top">{translate('CurrentProjects.title')}</div>
                  <div className="proekt__tabs tabs-nav" data-tabs="#zayv-tabs">
                    <ul>
                      <li>
                        <button
                          type="button"
                          className={`${tab === 1 ? 'active' : ''}`}
                          onClick={() => setTab(1)}
                        >
                          {translate('CurrentProjects.tab1')}{' '}
                          {myCurrentProjectsPag.total > 0 ? `(${myCurrentProjectsPag.total})` : ''}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className={`${tab === 2 ? 'active' : ''}`}
                          onClick={() => setTab(2)}
                        >
                          {translate('CurrentProjects.tab2')}{' '}
                          {myCurrentProjectsCompletePag.total > 0
                            ? `(${myCurrentProjectsCompletePag.total})`
                            : ''}
                        </button>
                      </li>
                    </ul>
                  </div>
                  {tab === 1 ? (
                    <Search searchText={searchText1} handleSearch={handleSearch1} />
                  ) : (
                    <Search searchText={searchText2} handleSearch={handleSearch2} />
                  )}

                  {waiter ? (
                    <Loader />
                  ) : (
                    <>
                      {tab === 1 && (
                        <div className="current-project">
                          {myCurrentProjects.length > 0 ? (
                            <>
                              {myCurrentProjects.map((item) => (
                                <Item projectId={item.id} key={item.id} tab={tab} />
                              ))}
                            </>
                          ) : (
                            <>{translate('CurrentProjects.empty')}</>
                          )}
                        </div>
                      )}

                      {tab === 2 && (
                        <div className="current-project">
                          {myCurrentProjectsComplete.length > 0 ? (
                            <>
                              {myCurrentProjectsComplete.map((item) => (
                                <Item projectId={item.id} key={item.id} tab={tab} />
                              ))}
                            </>
                          ) : (
                            <>{translate('CurrentProjects.empty2')}</>
                          )}
                        </div>
                      )}
                    </>
                  )}

                  {waiterMore ? (
                    <Loader />
                  ) : (
                    <>
                      {tab === 1 ? (
                        <>
                          {myCurrentProjectsPag.current_page !== myCurrentProjectsPag.last_page && (
                            <div className="proekt__more">
                              <button type="button" className="btn btn-primary" onClick={loadMore}>
                                {translate('more')}
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {myCurrentProjectsCompletePag.current_page !==
                            myCurrentProjectsCompletePag.last_page && (
                            <div className="proekt__more">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={loadMoreComplete}
                              >
                                {translate('more')}
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};
export default CurrentProjects;
