import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { expertProjectActions } from 'src/store/actions';

import Item from './item';

const List = ({ tab }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const waiterMore = useSelector((state) => state.expertProjectReducer.myOffersWaiterMore);
  let list = [];
  let pag = {};

  switch (tab) {
    case 1:
      list = useSelector((state) => state.expertProjectReducer.myRequests);
      break;
    case 2:
      list = useSelector((state) => state.expertProjectReducer.myRejectedRequests);
      break;
    case 3:
      list = useSelector((state) => state.expertProjectReducer.myInvitations);
      break;
    case 4:
      list = useSelector((state) => state.expertProjectReducer.myRejectedInvitations);
      break;
    default:
      break;
  }

  switch (tab) {
    case 1:
      pag = useSelector((state) => state.expertProjectReducer.myRequestsPag);
      break;
    case 2:
      pag = useSelector((state) => state.expertProjectReducer.myRejectedRequestsPag);
      break;
    case 3:
      pag = useSelector((state) => state.expertProjectReducer.myInvitationsPag);
      break;
    case 4:
      pag = useSelector((state) => state.expertProjectReducer.myRejectedInvitationsPag);
      break;
    default:
      break;
  }

  const loadMoreMyRequests = () => {
    switch (tab) {
      case 1:
        dispatch(expertProjectActions.getMyRequestsMore(pag.current_page + 1));
        break;
      case 2:
        dispatch(expertProjectActions.getMyRejectedRequestsMore(pag.current_page + 1));
        break;
      case 3:
        dispatch(expertProjectActions.getMyinvitationsMore(pag.current_page + 1));
        break;
      case 4:
        dispatch(expertProjectActions.getMyRejectedinvitationsMore(pag.current_page + 1));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="proekt__content__wrap">
        {list.length ? (
          <>
            {list.map((item, index) => (
              <Item index={index} tab={tab} key={item.id} />
            ))}
          </>
        ) : (
          <>{translate('MyOffers.empty')}</>
        )}
      </div>
      {waiterMore ? (
        <Loader />
      ) : (
        <>
          {pag.current_page !== pag.last_page && (
            <div className="proekt__more">
              <button type="button" className="btn btn-primary" onClick={loadMoreMyRequests}>
                {translate('more')}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};
List.propTypes = {
  tab: PropTypes.number.isRequired,
};
export default List;
