import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

import CurrentProject from './current-project';
import Reviews from './reviews';

const Info = () => {
  const { translate } = useTranslation();
  const sferaList = useSelector((state) => state.authReducer.user.company.categories);
  const description = useSelector((state) => state.authReducer.user.company.description);
  return (
    <div className="exp-profile__tabs__content active">
      <div className="exp-profile__row">
        <div className="profile-sfera">
          <div className="exp-content__title">
            <h3>{translate('Profile.compDes')}</h3>
          </div>

          <div className="skills__text">
            <p>{description}</p>
          </div>
          <div className="exp-profile__bot-btn">
            <Link to="/user/about" className="btn btn-primary">
              {translate('edit')}
            </Link>
          </div>
        </div>
      </div>
      {sferaList && (
        <div className="exp-profile__row">
          <div className="exp-content__title">
            <h3>{translate('Profile.Business')}</h3>
          </div>
          <div className="tags mb-5">
            {sferaList.map((item) => (
              <div key={item.id} className="tags__item">
                {item.name}
              </div>
            ))}
          </div>
          <div className="exp-profile__bot-btn">
            <Link to="/user/sfera/" className="btn btn-primary">
              {translate('edit')}
            </Link>
          </div>
        </div>
      )}

      <CurrentProject />

      <Reviews />
    </div>
  );
};

export default Info;
