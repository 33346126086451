import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const Activate = ({ hanbleDispatch }) => {
  const dispatch = useDispatch();
  const expertId = useSelector((state) => state.adminReducer.user.expert.id);
  const packagesArr = useSelector((state) =>
    state.adminReducer.expertPaidPackages && state.adminReducer.expertFeatures.packages
      ? state.adminReducer.expertFeatures.packages
      : [],
  );
  const packages = packagesArr.map((item) => ({ ...item, check: false }));

  const servicesArr = useSelector((state) =>
    state.adminReducer.expertFeatures && state.adminReducer.expertFeatures.services
      ? state.adminReducer.expertFeatures.services
      : [],
  );

  const services = servicesArr.map((item) => ({
    ...item,
    check: false,
    count: item.multiply ? 1 : 0,
  }));

  const [isSub, setIsSub] = useState(false);

  const handleSubmit = async (values) => {
    const data = {
      expert: values.expert,
      packages: values.packages
        .filter((item) => item.check)
        .map((item) => ({ id: item.packageId })),
      services: values.services
        .filter((item) => item.check)
        .map((item) => {
          if (item.count) {
            return { id: item.serviceId, count: item.count };
          }
          return { id: item.serviceId };
        }),
    };
    if (!data.packages.length) {
      delete data.packages;
    }
    if (!data.services.length) {
      delete data.services;
    }

    await dispatch(adminActions.expertFeaturesActivate(data));
    hanbleDispatch(expertId);
    setIsSub(true);
    setTimeout(() => {
      setIsSub(false);
    }, 5000);
  };

  const formik = useFormik({
    initialValues: {
      expert: expertId,
      packages,
      services,
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  const handlePlus = (item, index) => {
    const max = item.max || 0;
    const position = item.position || 0;
    formik.setFieldValue(`services[${index}].check`, true);
    if (max && item.count < max) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    } else if (position && item.count < position) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    } else if (!max && !position) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    }
  };

  const handleMinus = (item, index) => {
    formik.setFieldValue(`services[${index}].check`, true);
    if (item.count > 1) {
      formik.setFieldValue(`services[${index}].count`, item.count - 1);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {formik.values.packages.length > 0 && (
        <div className="tarif">
          {formik.values.packages.map((item, index) => (
            <div
              className={`tarif__item ${item.marker ? `tarif__item--popular` : ''}`}
              key={item.packageId}
            >
              {item.marker && <div className="tarif__popular">{item.marker}</div>}

              <div className="tarif__name">
                {item.title}
                <button
                  type="button"
                  data-tooltip-id={`tarif-tooltip${item.packageId}`}
                  data-tooltip-content={item.tooltip}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <use xlinkHref={`${sprite}#paymentLabel`} />
                  </svg>
                </button>
                <Tooltip id={`tarif-tooltip${item.packageId}`} />
              </div>
              <div className="tarif__des" dangerouslySetInnerHTML={{ __html: item.description }} />
              <div className="tarif__price">
                <div className="tarif__price__numb">{item.price} грн</div>
                <div className="tarif__price__type">{item.per}</div>
              </div>
              <div className="tarif__btn">
                {item.check ? (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => formik.setFieldValue(`packages[${index}].check`, false)}
                  >
                    Відмінити
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => formik.setFieldValue(`packages[${index}].check`, true)}
                  >
                    Обрати
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="payment__dop">Додаткові функції: </div>
      {formik.values.services.length > 0 && (
        <div className="payment__list">
          {formik.values.services.length &&
            formik.values.services.map((item, index) => (
              <div
                className={`payment__row ${item.disabled ? 'disabled' : ''}`}
                key={item.serviceId}
              >
                <label
                  className="form-check form-check--payment"
                  htmlFor={`flexCheckDefault${item.serviceId}`}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name={`services[${index}].check`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id={`flexCheckDefault${item.serviceId}`}
                    checked={item.check}
                  />
                </label>
                <div className="payment__name">
                  {item.title}
                  <button
                    type="button"
                    data-tooltip-id={`payment-tooltip${item.serviceId}`}
                    data-tooltip-content={item.tooltip}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use xlinkHref={`${sprite}#paymentLabel`} />
                    </svg>
                  </button>
                  <Tooltip id={`payment-tooltip${item.serviceId}`} />

                  <span className="payment__name__position">
                    {item.positionText}
                    {item.position}
                  </span>
                </div>

                <div className="payment__count">
                  {item.multiply && (
                    <>
                      <div className="payment__count__flex">
                        <button
                          type="button"
                          className="minus"
                          onClick={() => handleMinus(item, index)}
                        >
                          -
                        </button>
                        <div className="payment__count__numb">{item.count}</div>
                        <button
                          type="button"
                          className="plus"
                          onClick={() => handlePlus(item, index)}
                        >
                          +
                        </button>
                      </div>
                      <div className="payment__count__text">{item.choosingLine}</div>
                    </>
                  )}
                </div>

                <div className="payment__price">
                  <div className="payment__price__left">{item.priceFormatted}</div>
                  <div className="payment__price__right">{item.per}</div>
                </div>
              </div>
            ))}
        </div>
      )}

      <div className="payment__total__bot">
        <button type="submit" className="btn btn-primary">
          Активувати
        </button>
      </div>
      {isSub && (
        <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
          <svg
            className="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Success:"
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          <div>Платні фукнції активовано</div>
        </div>
      )}
    </form>
  );
};
Activate.propTypes = {
  hanbleDispatch: PropTypes.func.isRequired,
};
export default Activate;
