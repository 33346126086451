import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'src/hooks';

const Resume = () => {
  const { translate } = useTranslation();
  const { resume } = useSelector((state) => state.authReducer.user.expert);

  return (
    <>
      {resume && (
        <div className="exp-profile__row">
          <div className="exp-content__title">
            <h3>{translate('Profile.resume')}</h3>
          </div>

          <div className="pdfVeiw">
            <iframe
              title="This is a unique title"
              className="embed-responsive-item"
              src={resume.path}
            />
            <a
              href={resume.path}
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary pdfVeiw__link"
            >
              {translate('Resume.view')}
            </a>
          </div>

          <div className="exp-profile__bot-btn">
            <Link to="/user/resume" className="btn btn-primary">
              {translate('Profile.editResume')}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Resume;
