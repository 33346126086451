import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const FavoriteExpertModal = ({
  handleFavoriteModal,
  favoriteModalXY,
  setHandleFavoriteModal,
  isFavorite,
  dispatch,
}) => {
  const { translate } = useTranslation();
  const maxWidth = favoriteModalXY.pageX > 462;

  const handleDispach = () => {
    dispatch(handleFavoriteModal);
    setHandleFavoriteModal(0);
  };

  useEffect(() => {
    function handleResize() {
      setHandleFavoriteModal(0);
    }
    function handleClickOnDoc(e) {
      if (!e.target.closest('.actionModal')) {
        setHandleFavoriteModal(0);
      }
    }
    document.addEventListener('mouseup', handleClickOnDoc);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mouseup', handleClickOnDoc);
    };
  }, []);

  return (
    <div
      className={`actionModal ${!maxWidth ? 'mob' : ''}`}
      style={{
        left: `${maxWidth ? favoriteModalXY.pageX - 20 : 30}px`,
        top: `${favoriteModalXY.pageY + 20}px`,
      }}
    >
      <button
        type="button"
        className="actionModal__close"
        onClick={() => setHandleFavoriteModal(0)}
      >
        <svg width="12" height="12">
          <use xlinkHref={`${sprite}#handleClose`} />
        </svg>
      </button>
      <div className="actionModal__text">
        {isFavorite ? translate('ExpertFavorite.title2') : translate('ExpertFavorite.title')}
      </div>
      <div className="d-flex justify-content-center">
        <button type="button" className="btn btn-primary" onClick={handleDispach}>
          {isFavorite ? translate('ExpertFavorite.btn2') : translate('ExpertFavorite.btn')}
        </button>
      </div>
    </div>
  );
};

FavoriteExpertModal.propTypes = {
  handleFavoriteModal: PropTypes.number.isRequired,
  favoriteModalXY: PropTypes.shape({
    pageX: PropTypes.number.isRequired,
    pageY: PropTypes.number.isRequired,
  }).isRequired,
  setHandleFavoriteModal: PropTypes.func.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};
export default FavoriteExpertModal;
