import 'src/style/App.scss';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AlertsSocket, BlockUser, FixLoader, MessageSoket } from 'src/components';
import { useTranslation } from 'src/hooks';
import {
  AboutUs,
  Admin,
  Alerts,
  Auth,
  Banned,
  Base,
  CompanyView,
  Contacts,
  CurrentProjects,
  Experts,
  ExpertView,
  ForNotActive,
  Help,
  Main,
  Messages,
  MyOffers,
  MyProjects,
  NotAccess,
  NotAuth,
  NotFound,
  Otbor,
  Payment,
  Policy,
  PolicyUser,
  Profile,
  Projects,
  Publication,
  Stat,
  User,
  Verify,
} from 'src/pages';
import { authActions, projectActions } from 'src/store/actions';
import { addMessCount } from 'src/store/reducers/chat.reducer';

import { socket, SocketContext } from './socketContext';

const App = () => {
  const { language } = useTranslation();
  window.HelpCrunch('setPhraseList', language);
  setTimeout(() => {
    window.HelpCrunch('showChatWidget');
  }, 15000);

  const dispatch = useDispatch();
  const location = useLocation();
  const firstUrlPath = location.pathname.split('/')[1];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [firstUrlPath]);

  const user = useSelector((state) => state.authReducer.user);
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const blocked = useSelector((state) => state.authReducer.user.blocked);
  const isAdmin = useSelector((state) => state.authReducer.user.isAdmin);

  let ifRole = !!useSelector((state) => !!state.authReducer.user.role);
  if (user.role === 1 && !user.expert) {
    ifRole = false;
  }
  if (user.role === 2 && !user.company) {
    ifRole = false;
  }

  const isActiveExpert =
    (ifRole && user.role === 1 && user.expert && user.expert.profile_status.code === 'AC') || false;

  const mainWaiter = useSelector((state) => state.authReducer.mainWaiter);
  const authFixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const projectMainWaiter = useSelector((state) => state.projectReducer.projectMainWaiter);

  useEffect(() => {
    dispatch(projectActions.getExpertCategoriesFull());
    if (ifAuth) {
      dispatch(projectActions.getExpertCategoriesFull());
      dispatch(authActions.getProfile()).then((res) => {
        if (res.payload.data) {
          if (res.payload.data.role === 1) {
            if (res.payload.data.unreadByExpert) {
              dispatch(addMessCount(res.payload.data.unreadByExpert));
            }
          } else if (res.payload.data.unreadByCompany) {
            dispatch(addMessCount(res.payload.data.unreadByCompany));
          }
        }
      });
    }
  }, []);

  return (
    <>
      {authFixWaiter && <FixLoader />}
      <SocketContext.Provider value={socket}>
        <BlockUser />
        <AlertsSocket />
        <MessageSoket />
        {mainWaiter || projectMainWaiter ? (
          <FixLoader />
        ) : (
          <Routes>
            <Route path="admin/*" element={<Admin />} />
            <Route path="auth/*" element={<Auth />} />
            <Route path="/" element={<Main />} />
            <Route path="/baza-znan/*" element={<Base />} />
            <Route path="/policy/*" element={<Policy />} />
            <Route path="/policy-user/*" element={<PolicyUser />} />
            <Route path="/about-us/*" element={<AboutUs />} />
            <Route path="/contacts/*" element={<Contacts />} />

            {!ifAuth ? (
              <>
                <Route path="/projects/*" element={<Projects />} />
                <Route path="/projects/:cLevel1Slug/" element={<Projects />} />
                <Route path="/projects/:cLevel1Slug/:cLevel2Slug/" element={<Projects />} />
                <Route
                  path="/projects/:cLevel1Slug/:cLevel2Slug/:cLevel3Slug/"
                  element={<Projects />}
                />
              </>
            ) : (
              <>
                {ifAuth && user.role === 1 ? (
                  <>
                    <Route path="/projects/*" element={<Projects />} />
                    <Route path="/projects/:cLevel1Slug/" element={<Projects />} />
                    <Route path="/projects/:cLevel1Slug/:cLevel2Slug/" element={<Projects />} />
                    <Route
                      path="/projects/:cLevel1Slug/:cLevel2Slug/:cLevel3Slug/"
                      element={<Projects />}
                    />
                  </>
                ) : (
                  <Route path="/projects/*" element={<NotAccess />} />
                )}
              </>
            )}

            {!ifAuth ? (
              <>
                <Route path="/freelancers/*" element={<Experts />} />
                <Route path="/freelancers/:cLevel1Slug/" element={<Experts />} />
                <Route path="/freelancers/:cLevel1Slug/:cLevel2Slug/" element={<Experts />} />
                <Route
                  path="/freelancers/:cLevel1Slug/:cLevel2Slug/:cLevel3Slug/"
                  element={<Experts />}
                />
              </>
            ) : (
              <>
                {ifAuth && user.role === 2 ? (
                  <>
                    <Route path="/freelancers/*" element={<Experts />} />
                    <Route path="/freelancers/:cLevel1Slug/" element={<Experts />} />
                    <Route path="/freelancers/:cLevel1Slug/:cLevel2Slug/" element={<Experts />} />
                    <Route
                      path="/freelancers/:cLevel1Slug/:cLevel2Slug/:cLevel3Slug/"
                      element={<Experts />}
                    />
                  </>
                ) : (
                  <Route path="/freelancers/*" element={<NotAccess />} />
                )}
              </>
            )}

            {!blocked && (
              <>
                {ifAuth && ifRole && (
                  <>
                    <Route path="/user/*" element={<User />} />
                    <Route path="/profile/*" element={<Profile />} />
                    <Route path="/messages/*" element={<Messages />} />

                    <Route path="/alerts/*" element={<Alerts />} />
                    <Route path="/help/*" element={<Help />} />

                    <Route path="/payment/*" element={<Payment />} />
                    <Route path="/verify/*" element={<Verify />} />

                    <Route path="/expert/:slug" element={<ExpertView />} />

                    <Route path="/banned/*" element={<Banned />} />

                    {user.role === 1 ? (
                      <>
                        {isActiveExpert ? (
                          <>
                            <Route path="/current-projects/*" element={<CurrentProjects />} />
                            <Route path="/my-offers/*" element={<MyOffers />} />
                          </>
                        ) : (
                          <>
                            <Route path="/current-projects/*" element={<ForNotActive />} />
                            <Route path="/my-offers/*" element={<ForNotActive />} />
                          </>
                        )}

                        <Route path="/stat/*" element={<Stat />} />
                        <Route path="/otbor/*" element={<Otbor />} />

                        <Route path="/employers/:slug" element={<CompanyView />} />
                      </>
                    ) : (
                      <>
                        <Route path="/my-projects/*" element={<MyProjects />} />
                        <Route path="/publication/*" element={<Publication />} />
                      </>
                    )}
                    {isAdmin && <Route path="/employers/:slug" element={<CompanyView />} />}
                    <Route path="/*" element={<NotFound />} />
                  </>
                )}
                <Route path="/not-auth" element={<NotAuth />} />
                <Route path="/*" element={<NotAuth />} />
              </>
            )}
          </Routes>
        )}
      </SocketContext.Provider>
    </>
  );
};

export default App;
