import './style.scss';

import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { FixLoader, Footer, Header, Sidebar, Status, Subheader } from 'src/components';
import { useTranslation } from 'src/hooks';

import { OtborMain, OtborMainDop, OtborResult, OtborTest, OtborTestDop } from './components';

const Otbor = () => {
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.otborReducer.fixWaiter);
  const statusCode = useSelector((state) => state.authReducer.user.expert.profile_status.code);

  return (
    <>
      {fixWaiter && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            <Status />
            <div className="exp-content exp-content--otbor">
              <Sidebar />
              <div className="exp-content__edit ">
                <div className="exp-content__top exp-content__top__mess">
                  {translate('otbor.title')}
                </div>
                <Routes>
                  <Route
                    path="/"
                    element={statusCode === 'AC' ? <OtborMainDop /> : <OtborMain />}
                  />

                  <Route path="/test" element={statusCode !== 'AC' && <OtborTest />} />
                  <Route path="/test-am" element={statusCode === 'AC' && <OtborTestDop />} />
                  <Route path="/result" element={<OtborResult />} />
                </Routes>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Otbor;
