import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PfdLabel from 'src/assets/img/resume/2.png';
import { Fancybox, Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { customActions } from 'src/store/actions';

const Portfolio = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();

  const portfolioLatestList = useSelector((state) => state.authReducer.user.expert.latestPortfolio);
  const portfolioList = useSelector((state) => state.customReducer.portfolio);

  const waiter = useSelector((state) => state.customReducer.waiter);
  const [openNew, setOpenNew] = useState(false);
  const [limit, setLimit] = useState(true);

  useEffect(() => {
    dispatch(customActions.getAllPortfolio());
  }, []);

  return (
    <>
      {portfolioList.length > 0 && (
        <div className="exp-profile__row">
          <div className="exp-content__title">
            <h3>{translate('PortfolioList.title')}</h3>
          </div>
          <div className="exp-profile__portfolio tabs-nav" data-tabs="#portfolio-tabs">
            <div className="exp-profile__portfolio__top">
              <button
                type="button"
                className={`btn btn-reset ${!openNew ? 'active' : ''}`}
                onClick={() => setOpenNew(false)}
              >
                {translate('PortfolioList.all')}
              </button>

              {portfolioLatestList.length > 0 && (
                <button
                  type="button"
                  className={`btn btn-reset ${openNew ? 'active' : ''}`}
                  onClick={() => setOpenNew(true)}
                >
                  {translate('PortfolioList.new')}
                </button>
              )}
            </div>
            <div className="tabs" id="portfolio-tabs">
              {!openNew ? (
                <div className="tabs__item active">
                  <div className="portfolio-p">
                    {portfolioList.length > 0 &&
                      portfolioList.map((item, index) => {
                        if (limit) {
                          if (index < 9) {
                            return (
                              <Fancybox key={item.id}>
                                <a
                                  className="portfolio-p__item"
                                  href={item.file.path}
                                  data-fancybox
                                >
                                  <div className="portfolio-p__wrap">
                                    <div className="portfolio-p__title">{item.name}</div>
                                    <div
                                      className="portfolio-p__img"
                                      style={{
                                        backgroundImage: `url(${PfdLabel})`,
                                        backgroundSize: '50px',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                      }}
                                    />
                                  </div>
                                </a>
                              </Fancybox>
                            );
                          }
                        } else {
                          return (
                            <Fancybox key={item.id}>
                              <a className="portfolio-p__item" href={item.file.path} data-fancybox>
                                <div className="portfolio-p__wrap">
                                  <div className="portfolio-p__title">{item.name}</div>
                                  <div
                                    className="portfolio-p__img"
                                    style={{
                                      backgroundImage: `url(${PfdLabel})`,
                                      backgroundSize: '50px',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundPosition: 'center',
                                    }}
                                  />
                                </div>
                              </a>
                            </Fancybox>
                          );
                        }
                        return false;
                      })}
                  </div>

                  {waiter ? (
                    <Loader />
                  ) : (
                    <>
                      {portfolioList.length > 9 && (
                        <>
                          {!limit ? (
                            <div className="exp-profile__bot-btn exp-profile__bot-btn--center">
                              <button
                                href="/"
                                className="btn btn-primary"
                                type="button"
                                onClick={() => setLimit(true)}
                              >
                                {translate('hideMore')}
                              </button>
                            </div>
                          ) : (
                            <div className="exp-profile__bot-btn exp-profile__bot-btn--center">
                              <button
                                href="/"
                                className="btn btn-primary"
                                type="button"
                                onClick={() => setLimit(false)}
                              >
                                {translate('showMore')}
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="tabs__item active">
                  <div className="portfolio-p">
                    {portfolioLatestList &&
                      portfolioLatestList.map((item) => (
                        <Fancybox>
                          <a
                            className="portfolio-p__item"
                            key={item.id}
                            href={item.file.path}
                            data-fancybox
                          >
                            <div className="portfolio-p__wrap">
                              <div className="portfolio-p__title">{item.name}</div>
                              <div
                                className="portfolio-p__img"
                                style={{
                                  backgroundImage: `url(${PfdLabel})`,
                                  backgroundSize: '50px',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center',
                                }}
                              />
                            </div>
                          </a>
                        </Fancybox>
                      ))}
                  </div>
                </div>
              )}
              <div className="exp-profile__bot-btn">
                <Link to="/user/portfolio" className="btn btn-primary">
                  {translate('edit')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Portfolio;
