import { useSelector } from 'react-redux';
import { useTranslation } from 'src/hooks';

const Status = () => {
  const { translate } = useTranslation();
  const project = useSelector((state) => state.projectReducer.myProjectDetail);

  return (
    <div className="proekt__detail__status">
      <div className={`proekt__detail__status__item ${project.status === 1 ? 'active' : ''}`}>
        {translate('Status2.t1')}
      </div>
      <div className={`proekt__detail__status__item ${project.status === 2 ? 'active' : ''}`}>
        {translate('Status2.t2')}
      </div>
      <div className={`proekt__detail__status__item ${project.status === 3 ? 'active' : ''}`}>
        {translate('Status2.t3')}
      </div>
      <div className={`proekt__detail__status__item ${project.status === 4 ? 'active' : ''}`}>
        {translate('Status2.t4')}
      </div>
      <div className={`proekt__detail__status__item ${project.status === 5 ? 'active' : ''}`}>
        {translate('Status2.t5')}
      </div>
      <div className={`proekt__detail__status__item ${project.status === 6 ? 'active' : ''}`}>
        {translate('Status2.t6')}
      </div>
      <div className={`proekt__detail__status__item ${project.status === 7 ? 'active' : ''}`}>
        {translate('Status2.t7')}
      </div>
    </div>
  );
};

export default Status;
