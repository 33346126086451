import { useSelector } from 'react-redux';

const Spec = () => {
  const { categories, secondCategories, thirdCategories } = useSelector(
    (state) => state.adminReducer.user.expert,
  );

  return (
    <>
      <div className="exp-content__top">Cпеціалізації</div>
      <div className="exp-content__wrap">
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Категорії експерта 1 рівень</div>
          </div>
          <div className="exp-content__row__right">
            <div className="proekt__content__tags">
              {categories &&
                categories.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Категорії експерта 2 рівень</div>
          </div>
          <div className="exp-content__row__right">
            <div className="proekt__content__tags">
              {secondCategories &&
                secondCategories.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Категорії експерта 3 рівень</div>
          </div>
          <div className="exp-content__row__right">
            <div className="proekt__content__tags">
              {thirdCategories &&
                thirdCategories.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Spec;
