import { api } from 'src/api';
import { getHeaders } from 'src/helpers/';

export const getMyRequests = async (page) => {
  const result = await api.get(
    `/profile/expert/my-requests/requests-all?page=${page}`,
    getHeaders(),
  );

  return result;
};

export const getMyRejectedRequests = async (page) => {
  const result = await api.get(
    `/profile/expert/my-requests/rejected-requests?page=${page}`,
    getHeaders(),
  );

  return result;
};

export const getMyinvitations = async (page) => {
  const result = await api.get(
    `/profile/expert/my-requests/invitations-all?page=${page}`,
    getHeaders(),
  );

  return result;
};

export const getMyRejectedinvitations = async (page) => {
  const result = await api.get(
    `/profile/expert/my-requests/rejected-invitations?page=${page}`,
    getHeaders(),
  );

  return result;
};

export const getMyCurrent = async (data) => {
  const result = await api.get(
    `/profile/expert/my-projects/current?page=${data.page}&name=${data.name}`,
    getHeaders(),
  );

  return result;
};

export const startProject = async (data) => {
  const result = await api.post(`/profile/expert/my-projects/accept`, data, getHeaders());

  return result;
};

export const declineProjectInCurrent = async (data) => {
  const result = await api.post(`/profile/expert/my-projects/decline`, data, getHeaders());

  return result;
};

export const reviewProject = async (data) => {
  const result = await api.post(
    `/profile/expert/my-projects/submit-project-for-review`,
    data,
    getHeaders(),
  );

  return result;
};

export const sendDataForPayment = async (data) => {
  const result = await api.post(
    `/profile/expert/my-projects/send-data-for-payment`,
    data,
    getHeaders(),
  );

  return result;
};

export const changeDataForPayment = async (data) => {
  const result = await api.post(`/profile/expert/my-projects/change-card`, data, getHeaders());

  return result;
};

export const getMyCurrentComplete = async (data) => {
  const result = await api.get(
    `/profile/expert/my-projects/complete?page=${data.page}&name=${data.name}`,
    getHeaders(),
  );

  return result;
};

export const reviewProjectInfo = async (data) => {
  const result = await api.get(
    `/profile/expert/review-project-info?project=${data.project}`,
    getHeaders(),
  );

  return result;
};

export const reviewProjectExpert = async (data) => {
  const result = await api.post(`/profile/expert/review`, data, getHeaders());

  return result;
};

export const declineProject = async (data) => {
  const result = await api.post(
    `profile/expert/my-requests/decline-invitation`,
    data,
    getHeaders(),
  );

  return result;
};

export const acceptProject = async (data) => {
  const result = await api.post(`profile/expert/my-requests/accept-invitation`, data, getHeaders());

  return result;
};

export const acceptPay = async (data) => {
  const result = await api.post(`profile/expert/my-projects/accept-pay`, data, getHeaders());

  return result;
};
