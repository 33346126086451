import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sprite2 from 'src/assets/img/old-img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import * as Yup from 'yup';

const Password = ({ handleClose }) => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const [passOldShow, setPassOldShow] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const [passConfirmationShow, setPassConfirmationShow] = useState(false);
  const errors = useSelector((state) => state.authReducer.errors);
  const [isSub, setIsSub] = useState(false);

  const passwordSchema = Yup.object().shape({
    old_password: Yup.string()
      .required(translate('Valid.req'))
      .min(8, translate('Valid.pass'))
      .minLowercase(1, translate('Valid.minLow'))
      .minUppercase(1, translate('Valid.minUp'))
      .minNumbers(1, translate('Valid.minCifr')),
    password: Yup.string()
      .required(translate('Valid.req'))
      .min(8, translate('Valid.pass'))
      .minLowercase(1, translate('Valid.minLow'))
      .minUppercase(1, translate('Valid.minUp'))
      .minNumbers(1, translate('Valid.minCifr')),
    password_confirmation: Yup.string()
      .required(translate('Valid.req'))
      .min(8, translate('Valid.pass'))
      .max(14, translate('Valid.max14'))
      .minLowercase(1, translate('Valid.minLow'))
      .minUppercase(1, translate('Valid.minUp'))
      .minNumbers(1, translate('Valid.minCifr'))
      .oneOf([Yup.ref('password'), null], translate('Valid.passCompare')),
  });

  const handleSubmit = async (values) => {
    dispatch(authActions.changePassword(values)).then((res) => {
      if (res.payload.status === 200) {
        setIsSub(true);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      old_password: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: passwordSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <>
      {!isSub ? (
        <form onSubmit={formik.handleSubmit}>
          <div className="sett-modal-body">
            <div className="sett-modal__title">{translate('sett.passTitle')}</div>
            <div className="sett-modal__pass">
              <div className="sett-modal__top">
                <div className="sett-modal__pass__t">
                  {translate('sett.passOld')} <span>*</span>
                </div>
                <div className="sett-modal__pass__input input__row--pass">
                  <input
                    type={passOldShow ? 'text' : 'password'}
                    placeholder={translate('sett.passPl')}
                    name="old_password"
                    className={`input ${
                      formik.errors.password && formik.touched.old_password && 'is-invalid'
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="invalid-feedback">
                    {formik.errors.old_password &&
                      formik.touched.old_password &&
                      formik.errors.old_password}
                  </div>
                  <button
                    type="button"
                    onClick={() => setPassOldShow(!passOldShow)}
                    className={passOldShow ? 'active' : null}
                  >
                    <svg className="hide" width="24" height="24">
                      <use xlinkHref={`${sprite2}#pass-show`} />
                    </svg>
                    <svg className="show" width="24" height="24">
                      <use xlinkHref={`${sprite2}#pass-hide`} />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="sett-modal__pass__t">
                {translate('sett.passNew')} <span>*</span>
              </div>
              <div className="sett-modal__pass__input input__row--pass">
                <input
                  type={passShow ? 'text' : 'password'}
                  placeholder={translate('sett.passPl')}
                  name="password"
                  className={`input ${
                    formik.errors.password && formik.touched.password && 'is-invalid'
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="invalid-feedback">
                  {formik.errors.password && formik.touched.password && formik.errors.password}
                </div>
                <button
                  type="button"
                  onClick={() => setPassShow(!passShow)}
                  className={passShow ? 'active' : null}
                >
                  <svg className="hide" width="24" height="24">
                    <use xlinkHref={`${sprite2}#pass-show`} />
                  </svg>
                  <svg className="show" width="24" height="24">
                    <use xlinkHref={`${sprite2}#pass-hide`} />
                  </svg>
                </button>
              </div>

              <div className="sett-modal__pass__input input__row--pass">
                <input
                  type={passConfirmationShow ? 'text' : 'password'}
                  placeholder={translate('sett.passPlNew')}
                  name="password_confirmation"
                  className={`input ${
                    formik.errors.password_confirmation &&
                    formik.touched.password_confirmation &&
                    'is-invalid'
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="invalid-feedback">
                  {formik.errors.password_confirmation &&
                    formik.touched.password_confirmation &&
                    formik.errors.password_confirmation}
                </div>
                <button
                  type="button"
                  onClick={() => setPassConfirmationShow(!passConfirmationShow)}
                  className={passConfirmationShow ? 'active' : null}
                >
                  <svg className="hide" width="24" height="24">
                    <use xlinkHref={`${sprite2}#pass-show`} />
                  </svg>
                  <svg className="show" width="24" height="24">
                    <use xlinkHref={`${sprite2}#pass-hide`} />
                  </svg>
                </button>
              </div>

              {errors && (
                <>
                  <div className="is-invalid" />
                  <div className="invalid-feedback">{errors.message}</div>
                </>
              )}
            </div>
          </div>
          <div className="exp-modal-footer">
            <div className="upload-wrap__btns">
              <button type="submit" className="btn btn-primary">
                {translate('save')}
              </button>
              <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
                {translate('cancel')}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <>
          <div className="sett-modal-body">
            <div className="sett-modal__title">{translate('sett.passSub')}</div>
          </div>
          <div className="exp-modal-footer">
            <div className="upload-wrap__btns">
              <button type="button" className="btn btn-primary" onClick={handleClose}>
                {translate('close')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

Password.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default Password;
