import './style.scss';

import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { FixLoader } from 'src/components';

import {
  Baza,
  BazaLogs,
  Header,
  Login,
  Payment,
  Projects,
  ProjectsLogs,
  Users,
  UsersLogs,
} from './components';

const Admin = () => {
  const isAdmin = useSelector((state) => state.authReducer.user.isAdmin);
  const fixWaiter = useSelector((state) => state.adminReducer.fixWaiter);
  return (
    <div className={`m_app m_app--admin ${!isAdmin ? 'm_app--login' : ''}`}>
      {isAdmin && <Header />}
      {fixWaiter && <FixLoader />}
      <Routes>
        {isAdmin ? (
          <>
            <Route path="/*" element={<Users />} />
            <Route path="/users-logs/*" element={<UsersLogs />} />
            <Route path="/projects/*" element={<Projects />} />
            <Route path="/projects-logs/*" element={<ProjectsLogs />} />
            <Route path="/payment/*" element={<Payment />} />
            <Route path="/baza/*" element={<Baza />} />
            <Route path="/baza-logs/*" element={<BazaLogs />} />
          </>
        ) : (
          <Route path="/*" element={<Login />} />
        )}
      </Routes>
    </div>
  );
};

export default Admin;
