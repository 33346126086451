import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { adminActions } from 'src/store/actions';

const Deactivate = ({ hanbleDispatch }) => {
  const dispatch = useDispatch();
  const companyFeaturesWaiter = useSelector((state) => state.adminReducer.companyFeaturesWaiter);
  const paidProjectFeaturesObj = useSelector((state) => state.adminReducer.paidProjectFeatures);
  const paidProjectFeatures = paidProjectFeaturesObj
    ? paidProjectFeaturesObj.map((item) => ({
        ...item,
        packages: item.packages.map((item2, index) => ({
          ...item2,
          check: false,
          uuid: index,
        })),
        services: item.services.map((item2, index) => ({
          ...item2,
          check: false,
          uuid: index,
        })),
      }))
    : [];

  const servicesDopArr = useSelector((state) => state.adminReducer.servicesDop);
  const servicesDop = servicesDopArr.map((item, index) => ({
    ...item,
    check: false,
    uuid: index,
  }));

  const [isSub, setIsSub] = useState(false);

  const handleSubmit = async (values) => {
    let packages = [];
    let services = [];
    if (values.paidProjectFeatures) {
      values.paidProjectFeatures.map((item) => {
        const itemPack = item.packages;
        packages = [
          ...packages,
          ...itemPack
            .filter((item2) => item2.check)
            .map((item2) => ({ recordId: item2.id, id: item2.packageId })),
        ];
        return false;
      });
      values.paidProjectFeatures.map((item) => {
        const itemPack = item.services;
        services = [
          ...services,
          ...itemPack
            .filter((item2) => item2.check)
            .map((item2) => ({ recordId: item2.id, id: item2.serviceId })),
        ];
        return false;
      });
    }

    let data = {
      packages,
      services,
    };
    const servicesDopValues = values.servicesDop
      .filter((item) => item.check)
      .map((item) => ({ recordId: item.id, id: item.serviceId }));
    if (servicesDopValues) {
      data = { ...data, services: [...data.services, ...servicesDopValues] };
    }
    if (!data.packages.length) {
      delete data.packages;
    }
    if (!data.services.length) {
      delete data.services;
    }

    await dispatch(adminActions.сompanyFeaturesDeactivate(data));
    hanbleDispatch();
    setIsSub(true);
    setTimeout(() => {
      setIsSub(false);
    }, 5000);
  };

  const formik = useFormik({
    initialValues: {
      paidProjectFeatures,
      servicesDop,
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <>
      {isSub && (
        <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
          <svg
            className="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Success:"
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          <div>Платні фукнції деактивовано</div>
        </div>
      )}
      {companyFeaturesWaiter ? (
        <Loader />
      ) : (
        <>
          {formik.values.paidProjectFeatures.length || formik.values.servicesDop.length ? (
            <form className="complited__page" onSubmit={formik.handleSubmit}>
              {formik.values.paidProjectFeatures &&
                formik.values.paidProjectFeatures.map((item, index) => (
                  <div className="mb-5" key={item.id}>
                    <div className="payment__dop">{item.name}</div>
                    {item.packages.length > 0 && (
                      <div className="tarif">
                        {item.packages.map((item2, index2) => (
                          <div
                            className={`tarif__item ${item2.marker ? `tarif__item--popular` : ''}`}
                            key={item2.uuid}
                          >
                            {item2.marker && <div className="tarif__popular">{item2.marker}</div>}

                            <div className="tarif__name">
                              {item2.name}
                              <button
                                type="button"
                                data-tooltip-id={`tarif-tooltip${item2.packageId}`}
                                data-tooltip-content={item2.tooltip}
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <use xlinkHref={`${sprite}#paymentLabel`} />
                                </svg>
                              </button>
                              <Tooltip id={`tarif-tooltip${item2.packageId}`} />
                            </div>
                            <div
                              className="tarif__des"
                              dangerouslySetInnerHTML={{ __html: item2.description }}
                            />
                            <div className="tarif__price">
                              <div className="tarif__price__numb">{item2.price} грн</div>
                              <div className="tarif__price__type">{item2.per}</div>
                            </div>
                            <div className="tarif__btn">
                              {item2.check ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  onClick={() =>
                                    formik.setFieldValue(
                                      `paidProjectFeatures[${index}].packages[${index2}].check`,
                                      false,
                                    )
                                  }
                                >
                                  Відмінити
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() =>
                                    formik.setFieldValue(
                                      `paidProjectFeatures[${index}].packages[${index2}].check`,
                                      true,
                                    )
                                  }
                                >
                                  Деактивувати
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="payment__dop">Додаткові функції:</div>
                    {item.services.length > 0 &&
                      item.services.map((item2, index2) => (
                        <div className="complited complited--admin" key={item2.uuid}>
                          <label
                            className="form-check form-check--payment"
                            htmlFor={`flexCheckDefault${item2.uuid}`}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name={`paidProjectFeatures[${index}].services[${index2}].check`}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              id={`flexCheckDefault${item2.uuid}`}
                              checked={item2.check}
                            />
                          </label>
                          <div className="complited__name">{item2.title}</div>
                          <div className="complited__name2">
                            {item2.remainTitle} <b>{item2.remainText || item2.remainValue}</b>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}

              <div className="payment__dop">Додаткові категорії бізнесу:</div>
              {formik.values.servicesDop.length > 0 &&
                formik.values.servicesDop.map((item, index) => (
                  <div className="complited complited--admin" key={item.uuid}>
                    <label
                      className="form-check form-check--payment"
                      htmlFor={`flexCheckDefault${item.uuid}`}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={`servicesDop[${index}].check`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id={`flexCheckDefault${item.uuid}`}
                        checked={item.check}
                      />
                    </label>
                    <div className="complited__name">{item.title}</div>
                    <div className="complited__name2">
                      {item.remainTitle} <b>{item.remainText || item.remainValue}</b>
                    </div>
                  </div>
                ))}
              <div className="payment__total__bot">
                <button type="submit" className="btn btn-primary">
                  Деактивувати
                </button>
              </div>
            </form>
          ) : (
            <div className="mb-5">Немає платних функцій</div>
          )}
        </>
      )}
    </>
  );
};

Deactivate.propTypes = {
  hanbleDispatch: PropTypes.func.isRequired,
};

export default Deactivate;
