import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { subMenuCompany, subMenuExpert } from 'src/constants';
import { useTranslation } from 'src/hooks';

const Subheader = () => {
  const { language } = useTranslation();
  const role = useSelector((state) => state.authReducer.user.role);
  const menuList = role === 1 ? subMenuExpert[language] : subMenuCompany[language];
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  return (
    <>
      {ifAuth ? (
        <div className="sub-header">
          <div className="wrap">
            <nav className="sub-header__nav">
              <ul>
                {menuList &&
                  menuList.map((item) => (
                    <li key={item.id}>
                      <NavLink to={item.to} activeclassname="active">
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        <div className="mt-4" />
      )}
    </>
  );
};

export default Subheader;
