import { Link } from 'react-router-dom';
import { Footer, HeaderContentPage } from 'src/components';
import { useTranslation } from 'src/hooks';

const Contacts = () => {
  const { translate } = useTranslation();

  return (
    <>
      <div className="app base">
        <HeaderContentPage />
        <main className="main">
          <div className="wrap">
            <div className="base__main mt-3">
              <div className="proekt__search" />
              <nav className="prpb__breadcrumb">
                <ul>
                  <li>
                    <Link to="/">{translate('main')}</Link>
                  </li>
                  <li>
                    <span>{translate('contactsPage.title')}</span>
                  </li>
                </ul>
              </nav>
              <div className="exp-content">
                <div className="exp-content__edit">
                  <div className="exp-content__top exp-content__top__mess">
                    {translate('contactsPage.title2')}
                  </div>
                  <div
                    className="base__detail"
                    dangerouslySetInnerHTML={{ __html: translate('contactsPage.text') }}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Contacts;
