import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { alertsActions } from 'src/store/actions';

const getNewData = (data) => {
  const date = new Date(data);
  const time = date.toLocaleTimeString([], {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${date.toISOString().slice(0, 10)} - ${time}`;
};

const chatApi = process.env.REACT_APP_API_CHAT;

const NotRead = () => {
  const { translate } = useTranslation();
  const socket = io(chatApi, {
    extraHeaders: {
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.authReducer.user.role);

  const id = useSelector((state) =>
    role === 1 ? state.authReducer.user.expert.id : state.authReducer.user.company.id,
  );
  const alerts = useSelector((state) => state.alertsReducer.alerts);
  const waiter = useSelector((state) => state.alertsReducer.waiter);

  useEffect(() => {
    dispatch(alertsActions.getNotifications(role));
  }, []);

  const handleRefresh = () => {
    dispatch(alertsActions.getNotificationsRefresh(role));
  };

  useEffect(() => {
    socket.on('connect', () => {});
    if (role === 1) {
      socket.emit('subscribe', {
        expertNotification: id,
      });

      socket.on(`expertNotification.${id}`, (data) => {
        if (data) {
          handleRefresh();
        }
      });
    } else {
      socket.emit('subscribe', {
        companyNotification: id,
      });

      socket.on(`companyNotification.${id}`, (data) => {
        if (data) {
          handleRefresh();
        }
      });
    }

    return () => {
      socket.off('connect');
    };
  }, []);

  const readNot = (notId) => {
    dispatch(alertsActions.readNot({ role, notification: notId }));
  };
  const openDetail = async (link, notId) => {
    await dispatch(alertsActions.readNot({ role, notification: notId }));
    navigate(link);
  };

  return (
    <>
      {waiter ? (
        <Loader />
      ) : (
        <div className="alerts">
          {alerts.length > 0 ? (
            alerts.map((item) => (
              <div className="alerts__item fadeIn" key={item.id}>
                <div className="alerts__top">
                  <div className="alerts__name">{item.text}</div>
                </div>
                <div className="alerts__body">
                  <div className="alerts__text">{getNewData(item.createdAt)}</div>
                  <div className="alerts__btns">
                    {item.url && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => openDetail(item.url, item.id)}
                      >
                        {translate('detail')}
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => readNot(item.id)}
                    >
                      {translate('Alerts.readBtn')}
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>{translate('empty')}</>
          )}
        </div>
      )}
    </>
  );
};

export default NotRead;
