import { useSelector } from 'react-redux';

import CompanyPersonalInfo from './company-personal-info';
import ExpertPersonalInfo from './expert-personal-info';

const PersonalInfo = () => {
  const { role } = useSelector((state) => state.authReducer.user);

  return <>{role === 1 ? <ExpertPersonalInfo /> : <CompanyPersonalInfo />}</>;
};

export default PersonalInfo;
