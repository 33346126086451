import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'src/hooks';

const Spec = () => {
  const { translate } = useTranslation();

  const categoriesTree = useSelector((state) => state.authReducer.user.expert.categoriesTree || []);

  return (
    <>
      {categoriesTree.length > 0 && (
        <div className="exp-profile__row">
          <div className="profile-sfera">
            <div className="exp-content__title">
              <h3>{translate('Profile.SpecTitle')}</h3>
            </div>

            <div className="profile__spec">
              {categoriesTree.map((level1) => (
                <div className="profile__spec__level1" key={level1.id}>
                  <div className="profile__spec__title">{level1.name}</div>

                  {level1.child.map((level2) => (
                    <div className="profile__spec__level2" key={level2.id}>
                      <div className="profile__spec__title2">{level2.name}</div>
                      {level2.child.length > 0 && (
                        <div className="profile__spec__level3">
                          {level2.child.map((level3) => (
                            <div className="profile__spec__level3__item" key={level3.id}>
                              {level3.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className="exp-profile__bot-btn">
              <Link to="/user/spec" className="btn btn-primary">
                {translate('edit')}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Spec;
