import { useFormik } from 'formik';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const Status = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.adminReducer.user.expert);
  const { code } = useSelector((state) => state.adminReducer.user.expert.profileStatus);
  let status = 1;

  if (code === 'NA') {
    status = 1;
  } else if (code === 'AC') {
    status = 2;
  }

  const handleSubmit = (values) => {
    dispatch(adminActions.expertUpdateStatus(values));
  };

  const formik = useFormik({
    initialValues: {
      id,
      status: status || '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <form>
      <div className="exp-content__title">
        <h3>Статус профіля</h3>
      </div>
      <div className="exp-content__wrap">
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <select
              className="form-select"
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
            >
              <option value="1">Не активний</option>
              <option value="2">Активний</option>
            </select>
          </div>
          <div className="exp-content__row__right">
            <button className="btn btn-primary mt-2" type="button" onClick={handleShow}>
              Зберегти
            </button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="not-auth__title">Ви дійсно хочете змінити статус профіля?</div>
            <div className="not-auth__btn">
              <button
                type="submit"
                className="btn btn-primary btn--new"
                onClick={() => {
                  formik.handleSubmit();
                  handleClose();
                }}
              >
                Так
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={() => {
                  formik.handleReset();
                  handleClose();
                }}
              >
                Ні
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
};

export default Status;
