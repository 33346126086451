import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { alertsActions } from 'src/store/actions';

const getNewData = (data) => {
  const date = new Date(data);
  const time = date.toLocaleTimeString([], {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${date.toISOString().slice(0, 10)} - ${time}`;
};

const NotRead = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.authReducer.user.role);
  const alertsRead = useSelector((state) => state.alertsReducer.alertsRead);
  const waiter = useSelector((state) => state.alertsReducer.waiter);

  useEffect(() => {
    dispatch(alertsActions.getReadNotifications(role));
  }, []);

  return (
    <>
      {waiter ? (
        <Loader />
      ) : (
        <div className="alerts">
          {alertsRead.length > 0 ? (
            alertsRead.map((item) => (
              <div className="alerts__item fadeIn" key={item.id}>
                <div className="alerts__top">
                  <div className="alerts__name">{item.text}</div>
                </div>
                <div className="alerts__body">
                  <div className="alerts__text">{getNewData(item.createdAt)}</div>
                  <div className="alerts__btns">
                    {item.url && (
                      <Link to={item.url} className="btn btn-primary">
                        {translate('detail')}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>{translate('empty')}</>
          )}
        </div>
      )}
    </>
  );
};

export default NotRead;
