import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/old-img/sprite.svg';
import { useTranslation } from 'src/hooks';

const Thank = () => {
  const { translate } = useTranslation();

  return (
    <div className="auth__wrap">
      <div className="container">
        <h1>{translate('Auth.fogotTitle')}</h1>

        <div className="auth__form auth__form--fogot">
          <Link to="/auth" className="auth__form__close">
            <div>
              <svg className="icon">
                <use xlinkHref={`${sprite}#close`} />
              </svg>
            </div>
          </Link>
          <div className="auth__form__title text-center">{translate('Auth.FoggotThank')}</div>
          <br />
          <div className="d-flex justify-content-center">
            <Link className="btn btn-primary btn-fogot" to="/auth/">
              {translate('Auth.FoggotThankBtn')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thank;
