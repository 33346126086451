import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const Deactivate = ({ hanbleDispatch }) => {
  const dispatch = useDispatch();
  const expertId = useSelector((state) => state.adminReducer.user.expert.id);
  const packagesArr = useSelector((state) => state.adminReducer.expertPaidPackages);
  const packages = packagesArr.map((item, index) => ({ ...item, check: false, uuid: index }));

  const servicesArr = useSelector((state) => state.adminReducer.expertPaidServices);

  const services = servicesArr.map((item, index) => ({
    ...item,
    check: false,
    uuid: index,
  }));

  const [isSub, setIsSub] = useState(false);

  const handleSubmit = async (values) => {
    const data = {
      expert: values.expert,
      packages: values.packages
        .filter((item) => item.check)
        .map((item) => ({ recordId: item.id, id: item.packageId })),
      services: values.services
        .filter((item) => item.check)
        .map((item) => ({ recordId: item.id, id: item.serviceId })),
    };
    if (!data.packages.length) {
      delete data.packages;
    }
    if (!data.services.length) {
      delete data.services;
    }

    await dispatch(adminActions.expertFeaturesDeactivate(data));
    hanbleDispatch(expertId);
    setIsSub(true);
    setTimeout(() => {
      setIsSub(false);
    }, 5000);
  };

  const formik = useFormik({
    initialValues: {
      expert: expertId,
      packages,
      services,
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <>
      {formik.values.packages.length > 0 || formik.values.services.length > 0 ? (
        <form className="complited__page" onSubmit={formik.handleSubmit}>
          {formik.values.packages.length > 0 && (
            <div className="tarif">
              {formik.values.packages.map((item, index) => (
                <div
                  className={`tarif__item ${item.marker ? `tarif__item--popular` : ''}`}
                  key={item.uuid}
                >
                  {item.marker && <div className="tarif__popular">{item.marker}</div>}

                  <div className="tarif__name">
                    {item.name}
                    <button
                      type="button"
                      data-tooltip-id={`tarif-tooltip${item.packageId}`}
                      data-tooltip-content={item.tooltip}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use xlinkHref={`${sprite}#paymentLabel`} />
                      </svg>
                    </button>
                    <Tooltip id={`tarif-tooltip${item.packageId}`} />
                  </div>
                  <div
                    className="tarif__des"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <div className="tarif__price">
                    <div className="tarif__price__numb">{item.price} грн</div>
                    <div className="tarif__price__type">{item.per}</div>
                  </div>
                  <div className="tarif__btn">
                    {item.check ? (
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => formik.setFieldValue(`packages[${index}].check`, false)}
                      >
                        Відмінити
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => formik.setFieldValue(`packages[${index}].check`, true)}
                      >
                        Деактивувати
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="payment__dop">Додаткові функції:</div>
          {formik.values.services.length > 0 &&
            formik.values.services.map((item, index) => (
              <div className="complited complited--admin" key={item.uuid}>
                <label
                  className="form-check form-check--payment"
                  htmlFor={`flexCheckDefault${item.uuid}`}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name={`services[${index}].check`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id={`flexCheckDefault${item.uuid}`}
                    checked={item.check}
                  />
                </label>
                <div className="complited__name">{item.title}</div>
                <div className="complited__name2">
                  {item.remainTitle} <b>{item.remainText || item.remainValue}</b>
                </div>
              </div>
            ))}
          <div className="payment__total__bot">
            <button type="submit" className="btn btn-primary">
              Деактивувати
            </button>
          </div>
          {isSub && (
            <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
              <svg
                className="bi flex-shrink-0 me-2"
                width="24"
                height="24"
                role="img"
                aria-label="Success:"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <div>Платні фукнції деактивовано</div>
            </div>
          )}
        </form>
      ) : (
        <div className="mb-5">Немає платних функцій</div>
      )}
    </>
  );
};

Deactivate.propTypes = {
  hanbleDispatch: PropTypes.func.isRequired,
};

export default Deactivate;
