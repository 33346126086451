/* eslint-disable react/prop-types */

import '@fancyapps/ui/dist/fancybox.css';

import { Fancybox as NativeFancybox } from '@fancyapps/ui/dist/fancybox.esm';
import React, { useEffect } from 'react';

const Fancybox = ({ delegate, children, options }) => {
  const delegateData = delegate || '[data-fancybox]';

  useEffect(() => {
    const opts = options || {};

    NativeFancybox.bind(delegateData, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, []);

  return <>{children}</>;
};

export default Fancybox;
