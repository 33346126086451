import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation, useWindowDimensions } from 'src/hooks';

const NotAccess = () => {
  const { width } = useWindowDimensions();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/');
  };

  return (
    <Modal show onHide={handleClose} centered={width < 992}>
      <Modal.Body>
        <div className="custom-modal-big custom-modal-big--not-auth">
          <button type="button" className="proekt-modal__close" onClick={handleClose}>
            <svg className="icon" width="20px" height="20px">
              <use xlinkHref={`${sprite}#close`} />
            </svg>
          </button>
          <div className="not-auth__title">{translate('notAccess')}</div>

          <div className="not-auth__btn">
            <Link to="/" className="btn btn-outline-primary btn--new">
              {translate('back')}
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotAccess;
