import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const PassResset = () => {
  const dispatch = useDispatch();
  const { id, phone } = useSelector((state) => state.adminReducer.user);
  const [isSub, setIsSub] = useState(false);
  const handleResset = async () => {
    await dispatch(adminActions.expertUpdateRessetPass(id));
    setIsSub(true);
    setTimeout(() => {
      setIsSub(false);
    }, 10000);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="exp-content__title" id="education">
        <h3>Зміна паролю</h3>
      </div>
      <div className="exp-content__wrap">
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <button type="button" className="btn btn-primary" onClick={handleShow}>
              Скинути пароль
            </button>
          </div>
        </div>
      </div>

      {isSub && (
        <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
          <svg
            className="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Success:"
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          <div>Пароль відправленно на номер телефону {phone}</div>
        </div>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="not-auth__title">Бажаєте скинути пароль та надіслати новий?</div>
            <div className="not-auth__btn">
              <button
                type="submit"
                className="btn btn-primary btn--new"
                onClick={() => {
                  handleResset();
                  handleClose();
                }}
              >
                Так
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={() => {
                  handleClose();
                }}
              >
                Ні
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PassResset;
