import './style.scss';

import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions, customActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

import Step2 from './step2';
import Success from './success';

const DeleteUserModal = ({ show, handleClose, handleShow }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const { deleteReason } = useSelector((state) => state.customReducer);
  const { waiter } = useSelector((state) => state.customReducer);

  // Другий крок
  const [showStep2, setShowStep2] = useState(false);
  const handleCloseStep2 = () => setShowStep2(false);
  const handleShowStep2 = () => setShowStep2(true);

  // Отримання причин видалення
  useEffect(() => {
    if (deleteReason.length === 0) {
      dispatch(customActions.getDeleteReason());
    }
  }, []);

  useEffect(() => () => dispatch(clearErrors()), []);

  const formik = useFormik({
    initialValues: {
      deleteReason: [],
      deleteReasonText: '',
      code: '',
    },
  });

  // успішне видалення
  const [showSuc, setShowSuc] = useState(false);
  const handleShowSuc = () => setShowSuc(true);

  const handleDelete = async (e) => {
    e.preventDefault();
    dispatch(authActions.accountDelete(formik.values)).then((res) => {
      if (!res.payload.errors) {
        handleCloseStep2();
        handleShowSuc();
      }
    });
  };

  const setFormikCode = (code) => {
    formik.setFieldValue('code', code);
  };

  return (
    <>
      {!showStep2 && (
        <Modal show={show} onHide={handleClose} className="spec-modal">
          <Modal.Body>
            <div className="exp-modal ">
              <div className="modal-header">
                <h5 className="modal-title">{translate('DeleteModal.title')}</h5>
                <button type="button" onClick={handleClose} className="spec-modal__close">
                  <svg width="20" height="20">
                    <use xlinkHref={`${sprite}#modalCloseWhite`} />
                  </svg>
                </button>
              </div>

              <div>
                {waiter ? (
                  <Loader />
                ) : (
                  <div className="deleteModal__checkboxes">
                    {deleteReason.length > 0 &&
                      deleteReason.map((item) => (
                        <label className="form-check" key={item.id} htmlFor={`reason${item.id}`}>
                          <input
                            className="form-check-input form-check-input--checkbox"
                            type="checkbox"
                            name="deleteReason"
                            id={`reason${item.id}`}
                            onChange={formik.handleChange}
                            value={item.id}
                            onBlur={formik.handleBlur}
                            checked={formik.values.deleteReason.includes(String(item.id))}
                          />
                          <div className="form-check-label" htmlFor="flexCheckDefault">
                            {item.text}
                          </div>
                        </label>
                      ))}
                    {formik.values.deleteReason.includes('8') && (
                      <div
                        id="form-check-input__another"
                        className="textarea"
                        style={{ display: 'block' }}
                      >
                        <textarea
                          placeholder="Опишите причину..."
                          name="deleteReasonText"
                          value={formik.values.deleteReasonText}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.deleteReasonText && formik.errors.deleteReasonText
                              ? 'error'
                              : ''
                          }
                        />

                        {formik.values.deleteReason.includes('8') &&
                          formik.touched.deleteReasonText &&
                          formik.values.deleteReasonText.length < 3 && (
                            <div className="error-text mt-2">
                              {translate('Valid.req')} {translate('Valid.min3')}
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )}

                <div className="deleteModal__checkboxes__bot">
                  <div className="upload-wrap__btns">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleShowStep2();
                      }}
                      disabled={
                        formik.values.deleteReason.length < 1 ||
                        (formik.values.deleteReason.includes('8') &&
                          formik.values.deleteReasonText.length < 3)
                      }
                    >
                      {translate('continue')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                    >
                      {translate('cancel')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {showStep2 && (
        <Step2
          showStep2={showStep2}
          handleCloseStep2={handleCloseStep2}
          handleShow={handleShow}
          setFormikCode={setFormikCode}
          handleDelete={handleDelete}
        />
      )}

      {showSuc && <Success showSuc={showSuc} />}
    </>
  );
};

DeleteUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
};

export default DeleteUserModal;
