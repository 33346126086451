import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import * as Yup from 'yup';

const Description = () => {
  const role = useSelector((state) => state.authReducer.user.role);
  const navigate = useNavigate();
  const [isSub, setIsSub] = useState(false);
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const description = useSelector((state) => state.authReducer.user.company.description);
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);

  const handleSubmit = (data) => {
    dispatch(authActions.setDes({ data, role })).then((res) => {
      if (res.payload.status === 200) {
        setIsSub(true);
        setTimeout(() => {
          setIsSub(false);
        }, 5000);

        if (firstSignUp) {
          navigate('/user/contacts/');
        }
      }
    });
  };

  const desSchema = Yup.object().shape({
    description: Yup.string().required(translate('Valid.req')),
  });

  const formik = useFormik({
    initialValues: {
      description: description || '',
    },
    validationSchema: desSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <>
      <div className="exp-content__title" id="description">
        <h3>{translate('About.des')}</h3>
      </div>
      <form className="exp-content__wrap exp-content__wrap--big" onSubmit={formik.handleSubmit}>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">
              {translate('About.desSub')} <span>*</span>
            </div>
          </div>
          <div className="exp-content__row__right">
            <div className="textarea">
              <textarea
                name="description"
                placeholder={translate('About.desPlaceholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                className={formik.errors.description ? 'error' : ''}
                maxLength={190}
              />

              {formik.errors.description ? (
                <div className="error-text mt-2">{formik.errors.description}</div>
              ) : (
                <div className="textarea__maxlength">{translate('About.ulugiLength')}</div>
              )}
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left" />
          <div className="exp-content__row__right">
            <button type="submit" className="btn btn-primary">
              {firstSignUp ? translate('next') : translate('save')}
            </button>
          </div>
        </div>

        {isSub && (
          <div className="exp-content__row">
            <div className="exp-content__row__left" />
            <div className="exp-content__row__right">
              <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
                <svg
                  className="bi flex-shrink-0 me-2"
                  width="24"
                  height="24"
                  role="img"
                  aria-label="Success:"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
                <div>{translate('Contacts.success')}</div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default Description;
