import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader, StarRate } from 'src/components';
import { useTranslation } from 'src/hooks';
import { customActions } from 'src/store/actions';

const Reviews = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.customReducer.reviewsWaiter);
  const waiterMore = useSelector((state) => state.customReducer.reviewsWaiterMore);
  const reviews = useSelector((state) => state.customReducer.reviews);
  const reviewsPag = useSelector((state) => state.customReducer.reviewsPag);
  const companyId = useSelector((state) => state.authReducer.user.company.id);

  useEffect(() => {
    dispatch(customActions.getReviewsCompany({ company: companyId, page: 1 }));
  }, []);

  const handleMore = () => {
    dispatch(
      customActions.getReviewsCompanyMore({
        company: companyId,
        page: reviewsPag.current_page + 1,
      }),
    );
  };

  return (
    <div className="exp-profile__row">
      <div className="exp-profile__reviews">
        <div className="exp-content__title">
          <h3>{translate('Reviews.title')}</h3>
        </div>
        {waiter ? (
          <Loader />
        ) : (
          <div className="exp-profile__reviews__wrap">
            {reviews.length > 0 ? (
              reviews.map((item) => (
                <div className="exp-profile__reviews__item" key={item.id}>
                  <div className="exp-profile__reviews__top">
                    <div className="exp-profile__reviews__name">{item.projectName}</div>
                    <div className="exp-profile__reviews__price">
                      {item.date}
                      <span>
                        {item.projectBudget} {translate('grn')}
                      </span>
                    </div>
                  </div>
                  <div className="exp-profile__reviews__rate">
                    <StarRate rate={item.rating} />
                  </div>
                  <div className="exp-profile__reviews__text">{item.text}</div>
                  <Link
                    to={`/expert/${item.author.slug}`}
                    href="/"
                    className="exp-profile__reviews__author"
                  >
                    {item.author.profileName}
                  </Link>
                </div>
              ))
            ) : (
              <>{translate('Reviews.empty')}</>
            )}
          </div>
        )}
        {waiterMore ? (
          <Loader />
        ) : (
          <>
            {reviewsPag.current_page !== reviewsPag.last_page && (
              <div className="exp-profile__bot-btn exp-profile__bot-btn--center">
                <button type="button" className="btn btn-primary" onClick={handleMore}>
                  {translate('showMore')}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Reviews;
