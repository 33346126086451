import { createAsyncThunk } from '@reduxjs/toolkit';
import { otborService } from 'src/services';

export const getExamCategories = createAsyncThunk(
  'otbor/getExamCategories',
  async (data, { rejectWithValue }) => {
    try {
      const result = await otborService.getExamCategories();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExamQuestions = createAsyncThunk(
  'otbor/getExamQuestions',
  async (data, { rejectWithValue }) => {
    try {
      const result = await otborService.getExamQuestions(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendAnswers = createAsyncThunk(
  'otbor/sendAnswers',
  async (data, { rejectWithValue }) => {
    try {
      const result = await otborService.sendAnswers(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getResults = createAsyncThunk(
  'otbor/getResults',
  async (data, { rejectWithValue }) => {
    try {
      const result = await otborService.getResults();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAnalyticalexam = createAsyncThunk(
  'otbor/getAnalyticalexam',
  async (data, { rejectWithValue }) => {
    try {
      const result = await otborService.getAnalyticalexam();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const analyticalSendAnswer = createAsyncThunk(
  'otbor/analyticalSendAnswer',
  async (data, { rejectWithValue }) => {
    try {
      const result = await otborService.analyticalSendAnswer(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
