import { createSlice } from '@reduxjs/toolkit';

import { otborActions } from '../actions';

const otborSlice = createSlice({
  name: 'otbor',
  initialState: {
    waiter: false,
    cat: [],
    questions: [],
    result: {
      categories: [],
      examEnd: '',
    },
    analyticalQa: null,
    analyticalResult: null,
    fixWaiter: false,
    errors: false,
    fromResult: false,
  },
  reducers: {
    setFromResult(state, action) {
      state.fromResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // getExamCategories
      .addCase(otborActions.getExamCategories.pending, (state) => {
        state.cat = [];
        state.waiter = true;
        state.errors = false;
      })
      .addCase(otborActions.getExamCategories.fulfilled, (state, action) => {
        state.cat = action.payload.data;
        state.waiter = false;
        state.errors = false;
      })
      .addCase(otborActions.getExamCategories.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // getExamQuestions
      .addCase(otborActions.getExamQuestions.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(otborActions.getExamQuestions.fulfilled, (state, action) => {
        state.questions = [...state.questions, action.payload.data];
        state.waiter = false;
        state.errors = false;
      })
      .addCase(otborActions.getExamQuestions.rejected, (state) => {
        state.waiter = false;
      })

      // sendAnswers
      .addCase(otborActions.sendAnswers.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(otborActions.sendAnswers.fulfilled, (state) => {
        state.fixWaiter = false;
      })
      .addCase(otborActions.sendAnswers.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getResults
      .addCase(otborActions.getResults.pending, (state) => {
        state.waiter = true;
        state.questions = [];
      })
      .addCase(otborActions.getResults.fulfilled, (state, action) => {
        state.waiter = false;
        state.result = action.payload.data;
      })
      .addCase(otborActions.getResults.rejected, (state) => {
        state.waiter = false;
      })

      // getAnalyticalexam
      .addCase(otborActions.getAnalyticalexam.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(otborActions.getAnalyticalexam.fulfilled, (state, action) => {
        state.waiter = false;
        state.analyticalQa = action.payload.data;
        state.errors = false;
      })
      .addCase(otborActions.getAnalyticalexam.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // analyticalSendAnswer
      .addCase(otborActions.analyticalSendAnswer.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(otborActions.analyticalSendAnswer.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.analyticalResult = action.payload.data;
        state.analyticalQa.examEnd = action.payload.data.examEnd;
      })
      .addCase(otborActions.analyticalSendAnswer.rejected, (state) => {
        state.fixWaiter = false;
      });
  },
});
export const { setFromResult } = otborSlice.actions;
export default otborSlice.reducer;
