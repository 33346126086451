import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { FixLoader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { adminActions } from 'src/store/actions';

import KnowledgeTable from './KnowledgeTable';
import Search from './Search';

const IndexPage = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();

  const meta = useSelector((state) => state.adminReducer.knowledgeMeta);
  const fixWaiter = useSelector((state) => state.adminReducer.fixWaiter);

  const [name, setName] = useState('');

  const handleSearch = (data) => {
    setName(data);

    dispatch(adminActions.getKnowledgeBase({ search: data }));
  };

  const handleChangePage = (nextPage) => {
    if (name) {
      dispatch(adminActions.getKnowledgeBase({ page: nextPage.selected + 1, search: name }));
    } else {
      dispatch(adminActions.getKnowledgeBase({ page: nextPage.selected + 1 }));
    }
  };

  const getBase = () => {
    dispatch(adminActions.getKnowledgeBase());
  };
  useEffect(() => {
    getBase();
  }, []);

  return (
    <>
      {fixWaiter && <FixLoader />}
      <div className="admin_knowledge_header">{translate('knowledge_base.name')}</div>
      <Search handleSearch={handleSearch} SearchName={name} />
      <KnowledgeTable getBase={getBase} />
      {fixWaiter ? (
        ''
      ) : (
        <>
          {meta.total > 0 && (
            <div className="proekt__pagination">
              <ReactPaginate
                breakLabel="....."
                onPageChange={(nextPage) => handleChangePage(nextPage)}
                pageCount={meta.last_page}
                forcePage={meta.current_page - 1}
                activeClassName="active"
                pageRangeDisplayed="2"
                marginPagesDisplayed="1"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default IndexPage;
