import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const VerifyPhone = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const phone = query.get('phone');
  const resend = query.get('resend');
  const ref = query.get('ref');
  const [code, setCode] = useState('');
  const [seconds, setSeconds] = useState(25);
  const [mailSended, setMailSended] = useState(true);
  const errors = useSelector((state) => state.authReducer.errors);

  useEffect(() => {
    const sendTimer = setTimeout(
      () =>
        setSeconds((prev) => {
          if (seconds > 0 && mailSended) {
            return prev - 1;
          }
          setMailSended(false);
          return 0;
        }),
      1000,
    );

    return () => {
      clearTimeout(sendTimer);
    };
  }, [seconds, mailSended]);

  useEffect(() => {
    const pinCodeInputs = document.querySelectorAll('.pincode__input');
    let fullVal = '';
    pinCodeInputs.forEach((el, index) =>
      el.addEventListener('input', ({ data }) => {
        pinCodeInputs[index].value = data;
        if (data === null) {
          pinCodeInputs[index].value = '';
          if (index > 0) {
            pinCodeInputs[index - 1].focus();
          }
        } else if (data === data.replace(/[0-9]/, '')) {
          pinCodeInputs[index].value = '';
        } else if (pinCodeInputs[index + 1]) {
          pinCodeInputs[index + 1].focus();
        }

        fullVal = '';
        pinCodeInputs.forEach((el2) => {
          fullVal += el2.value;
        });

        setCode(fullVal);
        return false;
      }),
    );
  }, []);

  const handleConfirm = (e) => {
    e.preventDefault();
    dispatch(authActions.confirmPhone({ phone, code, ref })).then((res) => {
      if (res.payload.status === 200) {
        navigate(`/auth/choose-role/`);
      }
      const pinCodeInputs = document.querySelectorAll('.pincode__input');
      const inputsArray = Array.from(pinCodeInputs);
      for (let i = 0; i < inputsArray.length; i += 1) {
        inputsArray[i].value = '';
      }
    });
  };

  // useEffect(() => {
  //   const pinkodeLast = document.getElementById('pinkodeLast');
  //   pinkodeLast.addEventListener('keyup', (e) => {
  //     if (e.key === 'Enter') {
  //       console.log(code);
  //       handleConfirm();
  //     }
  //   });
  // }, []);

  const handleResend = async () => {
    const splitPhone = phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
    const pinCodeInputs = document.querySelectorAll('.pincode__input');

    for (let i = 0; i < pinCodeInputs.length; i += 1) {
      pinCodeInputs[i].value = '';
    }

    setCode('');

    await dispatch(authActions.phoneUpdate({ phone: splitPhone }));

    setMailSended(true);
    setSeconds(25);
  };

  useEffect(() => {
    if (resend) {
      handleResend();
    }

    return () => dispatch(clearErrors());
  }, []);

  return (
    <div className="auth__wrap">
      <div className="container">
        <h1>{translate('Auth.verPhone')}</h1>
        <div className="auth__desc">{'  '}</div>
        <form className="auth__form auth__form--big" id="verifyEmailForm" onSubmit={handleConfirm}>
          <div className="auth__form__title">{translate('Auth.verCode2')}</div>

          <div className="pincode">
            <input type="number" className="pincode__input" maxLength={1} />
            <input type="number" className="pincode__input" maxLength={1} />
            <input type="number" className="pincode__input" maxLength={1} />
            <input type="number" className="pincode__input" maxLength={1} id="pinkodeLast" />
          </div>
          {errors && (
            <>
              <strong className="text-danger">{errors.message}</strong>
              <br />
            </>
          )}

          <div className="resend-code">
            {seconds > 0 ? (
              <button type="button" className="btn-reset resend-code__time">
                {translate('Auth.resendCode')} {seconds} {translate('Auth.resendCodeSec')}
              </button>
            ) : (
              <button type="button" className="btn-reset resend-code__time" onClick={handleResend}>
                {translate('Auth.resendCode')}
              </button>
            )}
            <Link to="/auth/update-phone" className="resend-code__change">
              {translate('Auth.changePhone')}
            </Link>
          </div>
          <div className="auth-bot-btns">
            <Link to="/auth/sign-up/" className="prev-btn">
              {translate('back')}
            </Link>
            <button type="submit" className="btn-reset next-btn">
              {translate('confirm')}
            </button>
          </div>
        </form>
        <div className="ver-form-center">
          <div className="auth__form__text">
            {translate('Auth.haveAkk')}
            <Link to="/auth/sign-in/">{translate('Auth.enter')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;
