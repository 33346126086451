import 'react-tooltip/dist/react-tooltip.css';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { paymentActions } from 'src/store/actions';

const CompanyComplited = () => {
  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.paymentReducer.waiter);
  const waiterPaided = useSelector((state) => state.paymentReducer.waiterPaided);
  const paidServicesPosition = useSelector((state) => state.paymentReducer.paidServicesPosition);
  const services = useSelector((state) => state.paymentReducer.paidServices);
  const paidServicesDop = useSelector((state) => state.paymentReducer.paidServicesDop);
  const { translate } = useTranslation();

  const getPayment = async () => {
    await dispatch(paymentActions.getPaidServicesCompanyPositionOfProject());
    await dispatch(paymentActions.getPaidServicesCompany());
    dispatch(paymentActions.paidBusinessCategories());
  };

  useEffect(() => {
    getPayment();
  }, []);

  return (
    <>
      {waiter || waiterPaided ? (
        <Loader />
      ) : (
        <div className="complited__page">
          {paidServicesDop && (
            <div className="complited__top">
              <div className="complited">
                <div className="complited__name">{paidServicesDop.title}</div>
                <div className="complited__name2">
                  {paidServicesDop.remainTitle} <b>{paidServicesDop.count}</b>
                </div>
              </div>
            </div>
          )}

          {services.length > 0 ? (
            services.map((item) => (
              <div key={item.id}>
                <div className="complited__title">{item.name}</div>
                {item.packages.length > 0 && (
                  <div className="tarif">
                    {item.packages.map((pack) => (
                      <div
                        className={`tarif__item ${pack.marker ? `tarif__item--popular` : ''}`}
                        key={pack.packageId}
                      >
                        {item.marker && <div className="tarif__popular">{pack.marker}</div>}

                        <div className="tarif__name">
                          {pack.name}
                          <button
                            type="button"
                            data-tooltip-id={`pak-tooltip${pack.packageId}`}
                            data-tooltip-content={pack.tooltip}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <use xlinkHref={`${sprite}#paymentLabel`} />
                            </svg>
                          </button>
                          <Tooltip id={`pak-tooltip${pack.packageId}`} />
                        </div>
                        <div
                          className="tarif__des"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                        <div className="tarif__price">
                          <div className="tarif__price__numb">
                            {pack.price} {translate('grn')}
                          </div>
                          <div className="tarif__price__type">{pack.per}</div>
                        </div>
                        <div className="tarif__timer">{pack.remainText}</div>
                        <div className="tarif__btn">
                          <Link
                            to={`/payment/paid?packageId=${pack.packageId}&projectId=${item.id}`}
                            className="btn btn-primary"
                          >
                            {translate('Payment.extend')}
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {item.services.length > 0 &&
                  item.services.map((ser) => (
                    <div className="complited" key={ser.serviceId}>
                      <div className="complited__name">{ser.title}</div>
                      <div className="complited__name2">
                        {ser.serviceId === 2 ? (
                          <>
                            {ser.remainTitle}{' '}
                            <b>
                              {
                                paidServicesPosition.filter((item2) => item2.id === item.id)[0]
                                  .position
                              }
                            </b>
                          </>
                        ) : (
                          <>
                            {ser.remainTitle} <b>{ser.remainText || ser.count}</b>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <>{translate('Payment.prPaidEmpty')}</>
          )}
        </div>
      )}
    </>
  );
};

export default CompanyComplited;
