import './style.scss';

import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation, useWindowDimensions } from 'src/hooks';

const NotAuth = () => {
  const { width } = useWindowDimensions();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/');
  };

  return (
    <Modal show onHide={handleClose} centered={width < 992}>
      <Modal.Body>
        <div className="custom-modal-big custom-modal-big--not-auth">
          <button type="button" className="proekt-modal__close" onClick={handleClose}>
            <svg className="icon" width="20px" height="20px">
              <use xlinkHref={`${sprite}#close`} />
            </svg>
          </button>
          <div className="not-auth__title">{translate('NotAuth.title')}</div>

          <div className="not-auth__btn">
            <Link to="/auth/sign-up/" className="btn btn-primary btn--new">
              {translate('NotAuth.reg')}
            </Link>
            <Link to="/" className="btn btn-outline-primary btn--new">
              {translate('back')}
            </Link>
          </div>
          <div className="not-auth__bot">
            {translate('NotAuth.haveAkk')} <Link to="/auth">{translate('Auth.enter')}</Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotAuth;
