import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';

import ChangePhone from './change-phone';

const PhoneVer = ({ setNeedPhone }) => {
  const { translate } = useTranslation();

  const dispatch = useDispatch();
  const query = useQuery();
  const phone = query.get('phone');
  const resend = query.get('resend');
  const [code, setCode] = useState('');
  const [seconds, setSeconds] = useState(25);
  const [mailSended, setMailSended] = useState(true);
  const errors = useSelector((state) => state.authReducer.errors);
  const [changePhone, setChangePhone] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const sendTimer = setTimeout(
      () =>
        setSeconds((prev) => {
          if (seconds > 0 && mailSended) {
            return prev - 1;
          }
          setMailSended(false);
          return 0;
        }),
      1000,
    );

    return () => {
      clearTimeout(sendTimer);
    };
  }, [seconds, mailSended]);

  useEffect(() => {
    const pinCodeInputs = document.querySelectorAll('.pincode__input');
    let fullVal = '';
    pinCodeInputs.forEach((el, index) =>
      el.addEventListener('input', ({ data }) => {
        pinCodeInputs[index].value = data;
        if (data === null) {
          pinCodeInputs[index].value = '';
          if (index > 0) {
            pinCodeInputs[index - 1].focus();
          }
        } else if (data === data.replace(/[0-9]/, '')) {
          pinCodeInputs[index].value = '';
        } else if (pinCodeInputs[index + 1]) {
          pinCodeInputs[index + 1].focus();
        }

        fullVal = '';
        pinCodeInputs.forEach((el2) => {
          fullVal += el2.value;
        });

        setCode(fullVal);
        return false;
      }),
    );
  }, [changePhone]);

  const handleConfirm = (e) => {
    e.preventDefault();
    dispatch(authActions.phoneVerify({ phone, code })).then((res) => {
      if (res.payload.status === 200) {
        setNeedPhone(false);
      }
    });
  };

  const handleResend = async () => {
    const pinCodeInputs = document.querySelectorAll('.pincode__input');

    for (let i = 0; i < pinCodeInputs.length; i += 1) {
      pinCodeInputs[i].value = '';
    }

    setCode('');
    await dispatch(authActions.phoneUpdate({ phone }));
    setMailSended(true);
    setSeconds(25);
  };

  useEffect(() => {
    handleResend();
  }, [phone]);

  useEffect(() => {
    if (resend) {
      handleResend();
    }
    return () => dispatch(clearErrors());
  }, []);

  return (
    <>
      {changePhone ? (
        <ChangePhone setChangePhone={setChangePhone} setSearchParams={setSearchParams} />
      ) : (
        <div className="auth__wrap">
          <div className="container">
            <h1>Верификация номера телефона</h1>
            <div className="auth__desc" />
            <form
              className="auth__form auth__form--big"
              id="verifyEmailForm"
              onSubmit={handleConfirm}
            >
              <div className="auth__form__title">{translate('Auth.verCode2')}</div>

              <div className="pincode">
                <input type="number" className="pincode__input" maxLength={1} />
                <input type="number" className="pincode__input" maxLength={1} />
                <input type="number" className="pincode__input" maxLength={1} />
                <input type="number" className="pincode__input" maxLength={1} />
              </div>
              {errors && (
                <>
                  <strong className="text-danger">{errors.message}</strong>
                  <br />
                </>
              )}
              {errors && errors.code && (
                <>
                  <strong className="text-danger">{errors.code[0]}</strong>
                  <br />
                </>
              )}

              <div className="resend-code">
                {seconds > 0 ? (
                  <button type="button" className="btn-reset resend-code__time">
                    {translate('Auth.resendCode')} {seconds} {translate('Auth.resendCodeSec')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-reset resend-code__time"
                    onClick={handleResend}
                  >
                    {translate('Auth.resendCode')}
                  </button>
                )}
                <button
                  type="button"
                  className="resend-code__change"
                  onClick={() => setChangePhone(true)}
                >
                  {translate('Auth.changePhone')}
                </button>
              </div>
              <div className="auth-bot-btns">
                <Link to="/user/contacts/" className="prev-btn">
                  {translate('back')}
                </Link>
                <button type="submit" className="btn-reset next-btn">
                  {translate('confirm')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

PhoneVer.propTypes = {
  setNeedPhone: PropTypes.func.isRequired,
};

export default PhoneVer;
