import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions, paymentActions } from 'src/store/actions';

const ExpertBalance = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const referralBalance = useSelector((state) => state.authReducer.user?.referralBalance);
  const referralUrl = useSelector((state) => state.paymentReducer.referralUrl);
  const authWaiter = useSelector((state) => state.authReducer.waiter);
  const waiter = useSelector((state) => state.paymentReducer.waiter);
  const [isCopy, setIsCopy] = useState(false);

  const handleCopyText = () => {
    const copyText = document.getElementById('myInput');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);

    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 5000);
  };

  useEffect(() => {
    dispatch(authActions.getProfileRefresh());
    if (!referralUrl) {
      dispatch(paymentActions.getReferralUrl());
    }
  }, []);

  return (
    <>
      {waiter || authWaiter ? (
        <Loader />
      ) : (
        <>
          <div className="p-balance">
            <div className="p-balance__info">
              {translate('referal.text')} <b>{translate('referal.text2')}</b>
            </div>
            {translate('referal.balance')}
            <span className="balance__total">
              {referralBalance} {translate('bonusov')}
            </span>
          </div>
          <div className="payment__ref">
            <div className="payment__ref__left">
              <div className="payment__ref__title">{translate('referal.ref')}</div>
              <div className="payment__ref__des">{translate('referal.refText')}</div>
              <div className="payment__ref__price">
                <div className="left">100 {translate('bonusov')}</div>
                <div className="right">{translate('referal.refType')}</div>
              </div>
            </div>
            <div className="payment__ref__right">
              <input type="text" defaultValue={referralUrl} id="myInput" hidden />
              <button type="button" className="btn btn-outline-primary" onClick={handleCopyText}>
                {translate('referal.friend')}
              </button>
            </div>
          </div>

          {isCopy && (
            <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
              <svg
                className="bi flex-shrink-0 me-2"
                width="24"
                height="24"
                role="img"
                aria-label="Success:"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <div>
                {translate('referal.link')} {referralUrl}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ExpertBalance;
