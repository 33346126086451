import './style.scss';

import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { FixLoader, Footer, Header, Sidebar, Subheader } from 'src/components';
import { useTranslation } from 'src/hooks';

import { Detail, List } from './components';

const MyProjects = () => {
  const projectFixWaiter = useSelector((state) => state.projectReducer.fixWaiter);
  const { translate } = useTranslation();
  return (
    <>
      {projectFixWaiter && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            <div className="exp-content">
              <Sidebar />
              <div className="exp-content__blocks">
                <div className="exp-content__edit">
                  <div className="exp-content__top">{translate('My-project.title')}</div>
                  <Routes>
                    <Route path="/" element={<List />} />
                    <Route path="/:detail" element={<Detail />} />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyProjects;
