import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PfdLabel from 'src/assets/img/resume/2.png';
import sprite from 'src/assets/img/sprite.svg';
import { Fancybox, Loader } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { adminActions } from 'src/store/actions/';

import FormEdit from './edit-form';

const Portfolio = () => {
  const { width } = useWindowDimensions();
  const { portfolio } = useSelector((state) => state.adminReducer.user.expert);
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);
  const [portfolioId, setPortfolioId] = useState(null);
  const [isSub, setIsSub] = useState(false);

  // Модалка видалення
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const handleDeletePortFolio = async () => {
    handleCloseDelete();
    await dispatch(adminActions.deletePortFolio(portfolioId));
  };

  // Cписок портфоліо

  const waiter = useSelector((state) => state.customReducer.waiter);

  // модалка
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  return (
    <>
      <div className="exp-content__top">Портфоліо</div>

      <div className="exp-content__skills mt-0">
        {isSub && (
          <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
            <svg
              className="bi flex-shrink-0 me-2"
              width="24"
              height="24"
              role="img"
              aria-label="Success:"
              viewBox="0 0 16 16"
              fill="currentColor"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            <div>{translate('Contacts.success')}</div>
          </div>
        )}
        {portfolio.length > 0 ? (
          <div className="portfolio__list-wrap">
            {waiter ? (
              <Loader />
            ) : (
              <div className="portfolio__list">
                {portfolio &&
                  portfolio.map((item) => (
                    <div className="portfolio__list__item" key={item.id}>
                      <div className="portfolio__list__name">{item.name}</div>
                      <div className="portfolio__list__data">{item.date}</div>
                      <Fancybox key={item.id}>
                        <Link
                          to={item.file.path}
                          data-fancybox
                          className="portfolio__list__img"
                          style={{
                            backgroundImage: `url(${PfdLabel})`,
                          }}
                        />
                      </Fancybox>

                      <div className="portfolio__list__edit">
                        <button
                          type="button"
                          className="portfolio__list__btnLeft"
                          onClick={() => {
                            setPortfolioId(item.id);
                            handleShowEdit();
                          }}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <use xlinkHref={`${sprite}#portEdit`} />
                          </svg>
                        </button>
                        <button type="button" className="portfolio__list__btnRight">
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 19 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                              setPortfolioId(item.id);
                              handleShowDelete();
                            }}
                          >
                            <use xlinkHref={`${sprite}#portDelete`} />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ) : (
          <>Немає портфоліо</>
        )}
        {!waiter && (
          <div className="exp-content__wrap__addPortfilio">
            {firstSignUp && (
              <div className="addPortfilio__btn">
                <Link to="/user/business/" className="btn btn-primary">
                  {translate('next')}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>

      {showEdit && (
        <FormEdit
          show={showEdit}
          handleClose={handleCloseEdit}
          setIsSub={setIsSub}
          portfolioId={portfolioId}
        />
      )}

      <Modal
        show={showDelete}
        onHide={handleCloseDelete}
        centered={width < 768}
        className="del-portfolio-modal__overlay"
      >
        <Modal.Body>
          <div className="exp-modal del-portfolio-modal">
            <button type="button" className="del-portfolio-modal__btn" onClick={handleCloseDelete}>
              <svg width="20" height="20" viewBox="0 0 20 20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="del-portfolio-modal__title">{translate('Portfolio.deleteTitle')}</div>
            <div className="del-portfolio-modal__btns">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleDeletePortFolio()}
              >
                {translate('Portfolio.deleteBtn')}
              </button>
              <button type="button" className="btn btn-outline-primary" onClick={handleCloseDelete}>
                {translate('Portfolio.deleteBtn2')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Portfolio;
