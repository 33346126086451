import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions, otborActions } from 'src/store/actions';
import { setFromResult } from 'src/store/reducers/otbor.reducer';

import Timer from './timer';

const OtborResult = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.otborReducer.waiter);
  const result = useSelector((state) => state.otborReducer.result);
  const [blockBtn, setBlockBtn] = useState(true);
  const { categories, examEnd } = result;
  const [time, setTime] = useState(86400);

  useEffect(() => {
    const timeLeft = Math.floor(
      new Date(examEnd).getTime() / 1000 + 86400 - new Date().getTime() / 1000,
    );
    setTime(timeLeft);
  }, [examEnd]);

  useEffect(() => {
    if (time < 1) {
      setBlockBtn(false);
    }
  }, [time]);

  window.scrollTo({
    top: 60,
    behavior: 'instant',
  });

  useEffect(() => {
    dispatch(otborActions.getResults()).then(() => {
      dispatch(authActions.getProfileRefresh());
    });
  }, []);

  useEffect(() => () => {
    dispatch(setFromResult(true));
  });

  return (
    <>
      {waiter ? (
        <Loader />
      ) : (
        <>
          {categories.length > 0 ? (
            <>
              <div className="otbor">
                <div className="otbor__title">{translate('otbor.result1')}</div>
                <div className="otbor__text">
                  <span>
                    {translate('otbor.resText')}:{' '}
                    {categories.map(
                      (item, index) => `${item}${index === categories.length - 1 ? '.' : ', '}`,
                    )}
                  </span>
                  <br />
                  <span>{translate('otbor.resText2')}</span>
                </div>
                <div className="otbor__btn">
                  <Link to="/otbor" className={`btn btn-primary ${blockBtn ? 'disabled' : ''}`}>
                    {translate('otbor.time')}
                  </Link>
                </div>
              </div>
              {time && (
                <Timer hours={0} minutes={0} seconds={time} action={() => setBlockBtn(false)} />
              )}
            </>
          ) : (
            <div className="otbor">
              <div className="otbor__title">{translate('otbor.result2')}</div>
              <div className="otbor__text" />
              <div className="otbor__btn">
                <Link to="/projects" className="btn btn-primary">
                  {translate('otbor.link')}
                </Link>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OtborResult;
