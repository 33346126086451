import { useSelector } from 'react-redux';
import { useTranslation } from 'src/hooks';

const Spec = () => {
  const { translate } = useTranslation();

  const businessCategories = useSelector(
    (state) =>
      (state.projectReducer.expertDetail && state.projectReducer.expertDetail.businessCategories) ||
      [],
  );
  const categoriesTree = useSelector(
    (state) =>
      (state.projectReducer.expertDetail && state.projectReducer.expertDetail.categoriesTree) || [],
  );

  return (
    <div className="exp-profile__row">
      <div className="exp-content__title">
        {categoriesTree.length > 0 && (
          <>
            <h3>{translate('Profile.SpecTitle')}</h3>
            <div className="profile__spec mb-5">
              {categoriesTree.map((level1) => (
                <div className="profile__spec__level1" key={level1.id}>
                  <div className="profile__spec__title">{level1.name}</div>

                  {level1.child.map((level2) => (
                    <div className="profile__spec__level2" key={level2.id}>
                      <div className="profile__spec__title2">{level2.name}</div>
                      {level2.child.length > 0 && (
                        <div className="profile__spec__level3">
                          {level2.child.map((level3) => (
                            <div className="profile__spec__level3__item" key={level3.id}>
                              {level3.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
        {businessCategories.length > 0 && (
          <>
            <div className="exp-content__title">
              <h3>{translate('Profile.Business')}</h3>
            </div>
            <div className="tags mb-5">
              {businessCategories &&
                businessCategories.map((item) => (
                  <div key={item.id} className="tags__item">
                    {item.name}
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Spec;
