import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'src/hooks';

const Service = () => {
  const { translate } = useTranslation();
  const { services, costPerHour, costPerProject } = useSelector(
    (state) => state.authReducer.user.expert,
  );
  return (
    <div className="exp-profile__row">
      <div className="exp-profile__uslugi">
        <div className="exp-profile__uslugi__item">
          <div className="exp-content__title">
            <h3>{translate('Profile.service')}</h3>
          </div>
          <div className="exp-profile__uslugi__wrap">
            <div className="exp-profile__uslugi__wrap2">
              <div className="exp-profile__uslugi__text">{services && services}</div>
              <div className="exp-profile__uslugi__tags mt-3">
                {costPerHour && (
                  <div className="exp-profile__uslugi__tags__item">
                    {costPerHour} грн/{translate('Profile.serHour')}
                  </div>
                )}
                {costPerProject && (
                  <div className="exp-profile__uslugi__tags__item">
                    {translate('Profile.from')} {costPerProject} грн/
                    {translate('Profile.serProekt')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="exp-profile__bot-btn">
        <Link to="/user/about/" className="btn btn-primary">
          {translate('Profile.addService')}
        </Link>
      </div>
    </div>
  );
};

export default Service;
