import { useState } from 'react';
import { useSelector } from 'react-redux';
import { InviteModal } from 'src/components';
import { useTranslation } from 'src/hooks';

import Done from './done';
import Info from './info';
import ProfileHead from './profile-head';

const ExpertProfile = () => {
  const { translate } = useTranslation();
  const [tab, setTab] = useState(1);
  // invite
  const expertId = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.id,
  );
  const [showInvite, setShowInvite] = useState(false);
  const handleCloseInvite = () => setShowInvite(false);
  const handleShowInvite = () => setShowInvite(true);

  return (
    <>
      <div className="exp-content">
        <div className="exp-profile">
          <ProfileHead handleShowInvite={handleShowInvite} />
          <div className="exp-profile__tabs">
            <div className="exp-profile__tabs__nav">
              <button type="button" className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
                {translate('Profile.info')}
              </button>
              <button type="button" className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
                {translate('Profile.done')}
              </button>
            </div>
            <div className="exp-profile__tabs__wrap">
              {tab === 1 && <Info />}
              {tab === 2 && <Done />}
            </div>
          </div>
        </div>
      </div>
      {showInvite && (
        <InviteModal show={showInvite} expert={expertId} handleClose={handleCloseInvite} />
      )}
    </>
  );
};

export default ExpertProfile;
