import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { Loader, StarRate } from 'src/components';
import { useTranslation } from 'src/hooks';
import { customActions } from 'src/store/actions';

const Done = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.customReducer.waiter);
  const waiterMore = useSelector((state) => state.customReducer.waiterMore);
  const doneList = useSelector((state) => state.customReducer.expDone);
  const expDonePag = useSelector((state) => state.customReducer.expDonePag);
  const id = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.id,
  );
  useEffect(() => {
    dispatch(customActions.getDoneProjectExpert({ id, page: 1 }));
  }, []);

  const getMore = () => {
    dispatch(customActions.getDoneProjectExpertMore({ page: expDonePag.current_page + 1, id }));
  };

  return (
    <div className="exp-profile__tabs__content active">
      <div className="doneList__wrap">
        {waiter ? (
          <Loader />
        ) : (
          <>
            <div className="doneList">
              {doneList.length > 0 ? (
                doneList.map((item) => (
                  <div className="doneList__row" key={item.id}>
                    <div className="doneList__item">
                      <div className="doneList__top">
                        <div className="doneList__name">{item.name}</div>
                        {item.completedDate && (
                          <div className="doneList__data">{item.completedDate}</div>
                        )}
                      </div>
                      <div className="proekt__tags">
                        {item.bid ? (
                          <div className="proekt__tags__item">
                            <span className="tags-point">{item.bid} грн/проект</span>
                          </div>
                        ) : (
                          <>
                            {item.budget && (
                              <div className="proekt__tags__item">
                                <span className="tags-point">{item.budget} грн/проект</span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {item.companyLeftReview && (
                        <div className="proekt__rating">
                          <StarRate rate={item.companyReview.rating} />
                        </div>
                      )}

                      {item.company.country && (
                        <div className="proekt__location">
                          <svg width="14" height="20">
                            <use xlinkHref={`${sprite}#location-current`} />
                          </svg>
                          {item.company.country.name}, {item.company.city.name}
                        </div>
                      )}

                      <div className="doneList__des">{item.description}</div>
                      <div className="proekt__content__tags">
                        {item.businessCategories &&
                          item.businessCategories.map((cat) => (
                            <div className="proekt__content__tags__item" key={cat.id}>
                              {cat.name}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-3">{translate('Projects.emptyDoneProjects')}</div>
              )}
            </div>
            {waiterMore ? (
              <Loader />
            ) : (
              <>
                {expDonePag.current_page !== expDonePag.last_page && (
                  <div className="exp-profile__bot-btn exp-profile__bot-btn--center">
                    <button type="button" className="btn btn-primary" onClick={getMore}>
                      {translate('showMore')}
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Done;
