import './style.scss';

import { Route, Routes } from 'react-router-dom';

import { IndexPage } from './components/index';
import { DetailUser } from './detail-page';

const Users = () => (
  <div className="admin-wrap">
    <div className="wrap">
      <Routes>
        <Route path="/*" element={<IndexPage />} />
        <Route path="/user/:id/*" element={<DetailUser />} />
      </Routes>
    </div>
  </div>
);

export default Users;
