import './style.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import avatarDefault from 'src/assets/img/avatar-default.png';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import logo from 'src/assets/img/header/logo.svg';
import sprite from 'src/assets/img/sprite.svg';
import { AlertsInHeader, DeleteUserModal, MessInHeader, SettingModal } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { addMessCount } from 'src/store/reducers/chat.reducer';

import Search from './search';

const Header = () => {
  const { width } = useWindowDimensions();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openM, setOpenM] = useState(false);
  const [openMobSearch, setOpenMobSearch] = useState(false);
  const role = useSelector((state) => state.authReducer.user.role);
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const avatar = useSelector((state) =>
    role === 1
      ? state.authReducer.user.expert && state.authReducer.user.expert.avatar
      : state.authReducer.user.company && state.authReducer.user.company.avatar,
  );
  const haveCompany = !!useSelector((state) => state.authReducer.user.company);
  const haveExpert = !!useSelector((state) => state.authReducer.user.expert);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.person')) {
        setOpenM(false);
      }
    });
  }, []);

  const handleLogOut = async () => {
    await dispatch(authActions.logout());
    navigate('/');
  };

  const handleChangeRole = async () => {
    const newRole = role === 1 ? 2 : 1;
    if (role === 1 && haveCompany) {
      const res = await dispatch(authActions.changleRole({ role: newRole }));

      setOpenM(false);
      dispatch(addMessCount(res.payload.data.user.unreadByCompany));
      navigate('/profile/');
      return false;
    }
    if (role === 2 && haveExpert) {
      const res = await dispatch(authActions.changleRole({ role: newRole }));

      setOpenM(false);
      dispatch(addMessCount(res.payload.data.user.unreadByExpert));
      navigate('/profile/');
      return false;
    }
    if (role === 1) {
      navigate('/auth/b-reg/');
    } else {
      navigate('/auth/exp-reg/');
    }
    return false;
  };

  // видалення аккаунту
  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  // Налаштування
  const [showSett, setShowSett] = useState(false);
  const handleCloseSett = () => setShowSett(false);
  const handleOpenSett = () => setShowSett(true);

  return (
    <>
      <header className="header">
        <div className="wrap">
          <div className="header__wrap">
            <div className="header__left">
              <Link to="/" className="header__logo">
                <img alt="img" src={logo} />
                ExpertLand
              </Link>
              <Search openMobSearch={openMobSearch} />
            </div>

            <div className="header__right">
              {ifAuth && (
                <>
                  <button
                    type="button"
                    className={`header__right__btn header__right__btn--search ${
                      openMobSearch ? 'active' : ''
                    }`}
                    id="open-search"
                    onClick={() => setOpenMobSearch(!openMobSearch)}
                  >
                    <svg width="20px" height="20px" className="open">
                      <use xlinkHref={`${sprite}#search`} />
                    </svg>
                    <svg width="20px" height="20px" className="close">
                      <use xlinkHref={`${sprite}#close`} />
                    </svg>
                  </button>

                  {width > 767 && <MessInHeader />}
                  {width > 767 && <AlertsInHeader />}

                  <button
                    type="button"
                    className="header__right__btn header__right__btn--onlyds"
                    onClick={handleOpenSett}
                  >
                    <svg width="20px" height="20px">
                      <use xlinkHref={`${sprite}#settings`} />
                    </svg>
                  </button>
                  <div className={`person ${openM && 'open'}`}>
                    <button className="person__menu" type="button" onClick={() => setOpenM(!openM)}>
                      <div className="avatar">
                        <img
                          alt="img"
                          width="48px"
                          height="48px"
                          src={avatar || (role === 1 ? avatarDefault : avatarDefaultComp)}
                        />
                      </div>
                      <div className="person__menu__v">
                        <svg width="24px" height="24px">
                          <use xlinkHref={`${sprite}#v`} />
                        </svg>
                      </div>
                    </button>
                    <nav className="person__nav">
                      <div className="person__nav__onlymb">
                        <div className="person__nav__action">
                          {width < 768 && <MessInHeader />}
                          {width < 768 && <AlertsInHeader />}
                          <button
                            type="button"
                            className="header__right__btn"
                            onClick={handleOpenSett}
                          >
                            <svg width="20px" height="20px">
                              <use xlinkHref={`${sprite}#settings`} />
                            </svg>
                          </button>
                        </div>
                        <div className="person__nav__line" />
                      </div>
                      <ul>
                        <li>
                          <Link to="/user/about/">{translate('Header.editProf')}</Link>
                        </li>

                        <li>
                          <button
                            type="button"
                            className="btn-reset text-left"
                            onClick={() => handleChangeRole()}
                          >
                            {role === 1 ? translate('Header.accCom') : translate('Header.accExp')}
                          </button>
                        </li>
                      </ul>
                      <div className="person__nav__line" />
                      <ul>
                        <li>
                          <Link to="/baza-znan/" className="btn-reset">
                            {translate('Header.help')}
                          </Link>
                        </li>
                        <li>
                          <button type="button" className="btn-reset" onClick={handleLogOut}>
                            {translate('Header.exit')}
                          </button>
                        </li>
                        <li>
                          <button type="button" className="btn-reset" onClick={handleShowDelete}>
                            {translate('Header.delete')}
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </header>

      <DeleteUserModal
        show={showDelete}
        handleClose={handleCloseDelete}
        handleShow={handleShowDelete}
      />

      <SettingModal show={showSett} handleClose={handleCloseSett} />
    </>
  );
};

export default Header;
