import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { getNewData } from 'src/helpers';
import { adminActions } from 'src/store/actions';

const UsersTable = () => {
  const users = useSelector((state) => state.adminReducer.users);
  const fixWaiter = useSelector((state) => state.adminReducer.fixWaiter);

  const dispatch = useDispatch();

  const [userId, setUserId] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUserBlock = () => {
    handleClose();
    dispatch(adminActions.userBlock({ user: userId }));
  };

  return (
    <>
      <div className="user_table_warp table-responsive">
        {users.length > 0 ? (
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th className="text-nowrap">ID</th>
                <th className="text-nowrap">Ім&apos;я</th>
                <th className="text-nowrap">Прізвище</th>
                <th className="text-nowrap">Телефон</th>
                <th>Тимчасовий телефон</th>
                <th className="text-nowrap">Email</th>
                <th className="text-nowrap">Тимчасовий email</th>
                <th className="text-nowrap">Профіль експерта</th>
                <th className="text-nowrap">Спеціалізація</th>
                <th className="text-nowrap">Дата реєстрації експерта</th>
                <th className="text-nowrap">Остання активність експерта</th>
                <th className="text-nowrap">Профіль компанії</th>
                <th className="text-nowrap">Дата реєстрації компанії</th>
                <th className="text-nowrap">Остання активність компанії</th>
                <th className="text-nowrap">Статус експерта</th>
                <th className="text-nowrap">Статус компанії</th>
                <th className="text-nowrap">Платні функції експерт</th>
                <th className="text-nowrap">Платні функції замовник</th>
                <th className="text-nowrap">Редагування</th>
                <th className="text-nowrap">Блокування</th>
              </tr>
            </thead>
            <tbody className="admin_user_action_btn">
              {users &&
                users.map((item) => (
                  <tr key={item.id}>
                    <td className="text-nowrap">{item.id}</td>
                    <td className="text-nowrap">{item.firstName}</td>
                    <td className="text-nowrap">{item.lastName}</td>
                    <td className="text-nowrap">{item.phone}</td>
                    <td className="text-nowrap">
                      {item.temporaryPhone ? item.temporaryPhone : '-'}
                    </td>
                    <td className="text-nowrap">{item.email}</td>
                    <td className="text-nowrap">
                      {item.temporaryEmail ? item.temporaryEmail : '-'}
                    </td>
                    <td className="text-nowrap">
                      {item.expert ? (
                        <a target="_blank" href={`/expert/${item.expert.slug}`} rel="noreferrer">
                          {item.expert.profileName}
                        </a>
                      ) : (
                        'Немає'
                      )}
                    </td>
                    <td className="text-nowrap">
                      {item?.expert?.categories.length > 0
                        ? item.expert.categories[0].name
                        : 'Немає'}
                    </td>
                    <td className="text-nowrap">
                      {item.expert ? getNewData(item.expert.createdAt) : 'Немає'}
                    </td>
                    <td className="text-nowrap">
                      {item.expert ? getNewData(item.expert.lastActivity) : 'Немає'}
                    </td>
                    <td className="text-nowrap">
                      {item.company ? (
                        <a
                          target="_blank"
                          href={`/employers/${item.company.slug}`}
                          rel="noreferrer"
                        >
                          {item.company.companyName}
                        </a>
                      ) : (
                        'Немає'
                      )}
                    </td>
                    <td className="text-nowrap">
                      {item.company ? getNewData(item.company.createdAt) : 'Немає'}
                    </td>
                    <td className="text-nowrap">
                      {item.company ? getNewData(item.company.lastActivity) : 'Немає'}
                    </td>
                    <td className="text-nowrap">
                      {item.expert ? item.expert.profileStatus.text : 'Не активний'}
                    </td>
                    <td className="text-nowrap">
                      {item.company?.profileStatus ? 'Активний' : 'Не активний'}
                    </td>
                    <td className="text-nowrap">
                      <b>{item.expert?.hasPaidServices ? '+' : '-'}</b>
                    </td>
                    <td className="text-nowrap">
                      <b>{item.company?.hasPaidServices ? '+' : '-'}</b>
                    </td>
                    <td>
                      <Link
                        to={`/admin/user/${item.id}/info`}
                        type="button"
                        className="btn btn-primary"
                      >
                        Редагувати
                      </Link>
                    </td>
                    <td>
                      {item.blocked ? (
                        'Заблоковано'
                      ) : (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setUserId(item.id);
                            handleShow();
                          }}
                        >
                          Заблокувати
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <>{!fixWaiter && <div>Нічого не знайдено</div>}</>
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close" onClick={handleClose}>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="not-auth__title">Заблокувати користувача?</div>
            <div className="not-auth__btn">
              <button type="submit" className="btn btn-primary btn--new" onClick={handleUserBlock}>
                Так
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={handleClose}
              >
                Ні
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UsersTable;
