import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';

const AlertsInHeader = () => {
  const alertsCount = useSelector((state) => state.alertsReducer.alertsCount);

  return (
    <Link to="/alerts/" className={`header__right__btn  ${alertsCount ? 'have-mess' : ''}`}>
      <svg width="20px" height="20px">
        <use xlinkHref={`${sprite}#${alertsCount ? 'notific-active' : 'notific'} `} />
      </svg>
      {alertsCount > 0 && (
        <div className="have-mess__text">{alertsCount < 100 ? `+${alertsCount}` : '+99'}</div>
      )}
    </Link>
  );
};
export default AlertsInHeader;
