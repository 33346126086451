import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import { ResponseModalCompany } from 'src/components';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

import Status from '../status';

const StepWidthPerformer = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector((state) => state.projectReducer.myProjectDetail);
  const { executor } = project;
  const [showDetail, setShowDetail] = useState(false);

  // Модалка відміни виконавця
  const [showCancel, setShowCancel] = useState(false);
  const handleCloseCancel = () => setShowCancel(false);
  const handleShowCancel = () => setShowCancel(true);
  const handleCancel = () => {
    dispatch(projectActions.cancelExecutor({ project: project.id }));
  };

  // handleRework
  const [showRework, setShowRework] = useState(false);
  const handleCloseRework = () => setShowRework(false);
  const handleShowRework = () => setShowRework(true);
  const handleRework = () => {
    dispatch(projectActions.projectRework({ project: project.id }));
  };

  // handleSumbmit
  const [showSumbmit, setShowSumbmit] = useState(false);
  const handleCloseSumbmit = () => setShowSumbmit(false);
  const handleShowSumbmit = () => setShowSumbmit(true);
  const handleSumbmit = () => {
    dispatch(projectActions.projectSubmit({ project: project.id }));
  };

  const handlePay = async () => {
    dispatch(projectActions.projectSendPay({ project: project.id }));
  };

  // Модалка додавання відгуку
  const [showResponse, setShowResponse] = useState(false);
  const handleCloseResponse = () => setShowResponse(false);
  const handleShowResponse = () => setShowResponse(true);

  return (
    <>
      <div className="proekt__detail--my-project" key={project.id}>
        <Status />
        <div className="proekt__detail__flex">
          <div className="left">
            <div className="proekt__content__top">
              <div className="proekt__content__left">
                <div className="proekt__content__person proekt__content__person--list">
                  <div
                    className="proekt__content__avatar"
                    style={{
                      backgroundImage: `url(${project.company.avatar || avatarDefaultComp})`,
                    }}
                  />
                  <div className="proekt__content__person__right">
                    <div className="proekt__content__person__name">{project.name}</div>
                    <div className="proekt__tags">
                      <div className="proekt__tags__item">
                        <span className="">{translate('My-project.publisher')}</span>
                        <span className="tags-point">{project.company.companyName}</span>
                      </div>
                      <div className="proekt__tags__item">
                        <span className="">{translate('My-project.pay')}</span>
                        <span className="tags-point">{project.budget} грн</span>
                      </div>
                      {project.businessCategories &&
                        project.businessCategories.map((itemCat) => (
                          <div className="proekt__tags__item" key={itemCat.id}>
                            <span className="tags-point">{itemCat.name}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                {project.description}
                <br />
                <br />
              </div>
            </div>
          </div>
          <div className="right">
            <div className="proekt__stat ">
              <div className="proekt__stat__status">{translate('My-project.dataPublic')}</div>
              <div className="proekt__stat__item">
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#timeSvg`} />
                </svg>
                {project.created}
              </div>
              <div className="proekt__stat__item">
                <svg width="22" height="16">
                  <use xlinkHref={`${sprite}#viewSvg`} />
                </svg>
                {project.views}
              </div>
            </div>
            {project.actualChatId && (
              <Link
                to={`/messages/${project.actualChatId}?expert=${executor.expert.id}&project=${project.id}`}
                className="btn btn-primary mb-5 mt-5"
              >
                {translate('StepWidthPerformer.chat')}
              </Link>
            )}
          </div>
        </div>

        {executor.expert && (
          <div className="my-performer">
            <div className="my-performer__title">{translate('StepWidthPerformer.performer')}</div>

            <div className="proekt__list__item">
              <div className="proekt__detail__flex">
                <div className="left">
                  <div className="proekt__content__top">
                    <div className="proekt__content__left">
                      <div className="proekt__content__person proekt__content__person--list">
                        <Link
                          to={`/expert/${executor.expert.slug}`}
                          className="proekt__content__avatar"
                          style={{
                            backgroundImage: `url(${executor.expert.avatar || avatarDefaultComp})`,
                          }}
                        />
                        <div className="proekt__content__person__right">
                          <Link
                            to={`/expert/${executor.expert.slug}`}
                            className="proekt__content__person__name"
                          >
                            {executor.expert.profileName}
                          </Link>
                          <div className="proekt__tags">
                            {executor.expert.businessCategories.map((item) => (
                              <div className="proekt__tags__item" key={item.id}>
                                <span className="tags-point">{item.name}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="proekt__content__text">{executor.expert.services}</div>
                </div>
                <div className="right" />
              </div>
              <div className="proekt__content__tags">
                {executor.expert.secondCategories.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
                {executor.expert.thirdCategories.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
              </div>
              {executor.transmittalLetter && (
                <div className="my-performer__letter">
                  <div className="my-performer__letter__title">
                    {translate('StepWidthPerformer.latter')}
                  </div>
                  <div className="my-performer__letter__text">{executor.transmittalLetter}</div>
                </div>
              )}

              {showDetail && (
                <>
                  {executor.screeningQuestions && (
                    <div className="my-performer__answers">
                      <div className="my-performer__answers__title">
                        {translate('StepWidthPerformer.asks')}
                      </div>
                      <div className="my-performer__answers__list">
                        {executor.screeningQuestions.map((item) => (
                          <div className="my-performer__answers__item" key={item.id}>
                            <div className="title">{item.text}</div>
                            <div className="sub-title">Ваш ответ</div>
                            <div className="answer">{item.pivot.answer}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {executor.bid && (
                    <div className="my-performer__stavka">
                      {translate('StepWidthPerformer.stavka')}
                      <div className="proekt__tags">
                        <div className="proekt__tags__item">
                          <span className="tags-point">
                            {executor.bid} {translate('grnPr')}
                          </span>
                        </div>
                        <div className="proekt__tags__item">
                          <span className="tags-point">
                            {executor.completionTime} {translate('days')}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {showDetail ? (
                <button
                  className="proekt__chat proekt__detail__showLess"
                  type="button"
                  onClick={() => setShowDetail(false)}
                >
                  <svg width="44" height="21">
                    <use xlinkHref={`${sprite}#detail`} />
                  </svg>
                  {translate('showLess')}
                </button>
              ) : (
                <button
                  className="proekt__chat proekt__detail__showMore"
                  type="button"
                  onClick={() => setShowDetail(true)}
                >
                  {translate('show')}
                  <svg width="44" height="21">
                    <use xlinkHref={`${sprite}#detail`} />
                  </svg>
                </button>
              )}
            </div>
          </div>
        )}

        <div className="proekt__detail__control mt-4">
          <div className="proekt__detail__control__flex">
            {project.canCancelExecutor && (
              <button type="button" className="btn btn-primary" onClick={handleShowCancel}>
                {translate('StepWidthPerformer.cancel')}
              </button>
            )}

            {project.status === 5 && (
              <>
                <button type="button" className="btn btn-primary" onClick={handleShowSumbmit}>
                  {translate('StepWidthPerformer.done')}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleShowRework}
                >
                  {translate('StepWidthPerformer.rework')}
                </button>
              </>
            )}
            {project.status === 6 && (
              <>
                {project.isCompanySentPay ? (
                  <div>{translate('StepWidthPerformer.expertPayWait')}</div>
                ) : (
                  <button type="button" className="btn btn-primary" onClick={handlePay}>
                    {translate('StepWidthPerformer.pay')}
                  </button>
                )}
              </>
            )}
            {project.status === 7 && (
              <div className="company-status-7">
                <div className="company-status-7__item">
                  {translate('StepWidthPerformer.status')}
                  <span>{translate('StepWidthPerformer.statusDone')}</span>
                </div>
                {!project.companyLeftReview && (
                  <button type="button" className="btn btn-primary" onClick={handleShowResponse}>
                    {translate('StepWidthPerformer.reviews')}
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="proekt__detail__control__flex">
            <button
              type="button"
              className="btn btn-support"
              onClick={() => {
                window.HelpCrunch('openChat');
              }}
            >
              {translate('StepWidthPerformer.support')}
            </button>
          </div>
        </div>
      </div>

      <Modal show={showCancel} onHide={handleCloseCancel}>
        <Modal.Body>
          <div className="custom-modal-big">
            <button type="button" onClick={handleCloseCancel} className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="choose__expert-modal">
              <div className="choose__expert-modal__title">
                {translate('StepWidthPerformer.cancel')}?
              </div>

              <div className="choose__expert-modal__info">
                {translate('StepWidthPerformer.modalText')}{' '}
                <Link to="/my-projects/">{translate('StepWidthPerformer.modalBtn')}</Link>
              </div>
              <div className="choose__expert-modal__bot">
                <button
                  type="button"
                  className="btn btn-primary btn--new"
                  onClick={() => {
                    handleCancel();
                    handleCloseCancel();
                  }}
                >
                  {translate('yes')}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary btn--new"
                  onClick={handleCloseCancel}
                >
                  {translate('no')}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showRework} onHide={handleCloseRework}>
        <Modal.Body>
          <div className="custom-modal-big">
            <button type="button" onClick={handleCloseRework} className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="choose__expert-modal">
              <div className="choose__expert-modal__title">
                {translate('StepWidthPerformer.rework')}?
              </div>

              <div className="choose__expert-modal__info">
                {translate('StepWidthPerformer.modalText')}{' '}
                <Link to="/my-projects/">{translate('StepWidthPerformer.modalBtn')}</Link>
              </div>
              <div className="choose__expert-modal__bot">
                <button
                  type="button"
                  className="btn btn-primary btn--new"
                  onClick={() => {
                    handleRework();
                    handleCloseRework();
                  }}
                >
                  {translate('send')}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary btn--new"
                  onClick={handleCloseRework}
                >
                  {translate('back')}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showSumbmit} onHide={handleCloseSumbmit}>
        <Modal.Body>
          <div className="custom-modal-big">
            <button type="button" onClick={handleCloseSumbmit} className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="choose__expert-modal">
              <div className="choose__expert-modal__title">
                {translate('StepWidthPerformer.done')}?
              </div>

              <div className="choose__expert-modal__info">
                {translate('StepWidthPerformer.modalText')}{' '}
                <Link to="/my-projects/">{translate('StepWidthPerformer.modalBtn')}</Link>
              </div>
              <div className="choose__expert-modal__bot">
                <button
                  type="button"
                  className="btn btn-primary btn--new"
                  onClick={() => {
                    handleSumbmit();
                    handleCloseSumbmit();
                  }}
                >
                  {translate('yes')}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary btn--new"
                  onClick={handleCloseRework}
                >
                  {translate('back')}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showResponse && (
        <ResponseModalCompany
          handleClose={handleCloseResponse}
          show={showResponse}
          projectId={project.id}
          projectName={project.executor.expert.profileName}
        />
      )}
    </>
  );
};
export default StepWidthPerformer;
