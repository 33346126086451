import { useSelector } from 'react-redux';

const Bcat = () => {
  const { categories } = useSelector((state) => state.adminReducer.user.company);

  return (
    <>
      <div className="exp-content__top">Cпеціалізації компанії</div>
      <div className="exp-content__wrap">
        {categories.length ? (
          <div className="proekt__content__tags">
            {categories.map((item) => (
              <div className="proekt__content__tags__item" key={item.id}>
                {item.name}
              </div>
            ))}
          </div>
        ) : (
          <>Немає спеціалізацій</>
        )}
      </div>
    </>
  );
};
export default Bcat;
