import { useSelector } from 'react-redux';
import { useTranslation } from 'src/hooks';

const Langs = () => {
  const { translate } = useTranslation();
  const languages = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.languages,
  );

  return (
    <>
      {languages && languages.length > 0 && (
        <div className="exp-profile__row">
          <div className="exp-content__title">
            <h3>{translate('Profile.langs')}</h3>
          </div>
          <div className="skills__list">
            <ul>
              {languages.map((item) => (
                <li key={item.id}>
                  <span>{item.language.name}</span>
                  {item.level.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Langs;
