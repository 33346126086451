import { PropTypes } from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import sprite from 'src/assets/img/sprite.svg';
import { localeUa } from 'src/constants';

const CustomButton = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className={`datapiker form-select ${value ? 'selected' : ''}`}
    onClick={onClick}
    ref={ref}
  >
    {value || 'Дата'}
  </button>
));

const Filter = ({ setDate, setPage }) => {
  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function handleDateClear() {
    setSelectedDate(null);
    setPage(1);
    setDate('');
  }

  useEffect(() => {
    if (selectedDate) {
      const currentDate = new Date(selectedDate);
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      setPage(1);
      setDate(formattedDate);
    }
  }, [selectedDate]);

  return (
    <div>
      <form className="admin_search mt-3 d-flex justify-content-end">
        <div className="admin-filter">
          <div className="admin-calendar">
            <DatePicker
              locale={localeUa}
              calendarClassName="custom-datepicker"
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="yyyy/MM/dd"
              customInput={<CustomButton />}
            />
            {selectedDate && (
              <button className="admin-calendar__clear" type="button" onClick={handleDateClear}>
                <svg width="17.5px" height="17.5px">
                  <use xlinkHref={`${sprite}#close`} />
                </svg>
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
CustomButton.propTypes = null;
Filter.propTypes = {
  setDate: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default Filter;
