import { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import avatarDefault from 'src/assets/img/proekt/avatar-default.jpg';
import sprite from 'src/assets/img/sprite.svg';
import {
  FavoriteExpertModal,
  InviteModal,
  Loader,
  RejectExpertModal,
  StarRate,
} from 'src/components';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

const Rejects = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const waiter = useSelector((state) => state.projectReducer.rejectedWaiter);
  const experts = useSelector((state) => state.projectReducer.rejectedExp);
  const expertsPag = useSelector((state) => state.projectReducer.rejectedPag);
  const refList = useRef();
  const { search } = useLocation();
  const page = new URLSearchParams(search).get('page');
  const pageId = page || '1';

  useEffect(() => {
    dispatch(projectActions.getRejectedExperts(pageId));
  }, []);

  const handleChangePage = (nextPage) => {
    navigate(`/freelancers/rejects?page=${nextPage.selected + 1}`);
    dispatch(projectActions.getRejectedExperts(nextPage.selected + 1));
    if (refList.current) {
      window.scrollTo({
        top: refList.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
  };

  // reject and favorite
  const [handleRejectModal, setHandleRejectModal] = useState(0);
  const [isReject, setIsReject] = useState(false);
  const [rejectModalXY, setRejectModalXY] = useState({ pageX: 0, pageY: 0 });

  const [handleFavoriteModal, setHandleFavoriteModal] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteModalXY, setFavoriteModalXY] = useState({ pageX: 0, pageY: 0 });

  const handleReject = (id) => {
    dispatch(projectActions.expertRejectInRejectedPage({ expert: id }));
  };

  const handleFavorite = (id) => {
    dispatch(projectActions.expertFavoriteInRejectedPage({ expert: id }));
  };

  // invite
  const [inviteExpertId, setInviteExpertId] = useState('0');
  const [showInvite, setShowInvite] = useState(false);
  const handleCloseInvite = () => setShowInvite(false);
  const handleShowInvite = () => setShowInvite(true);

  return (
    <>
      {waiter ? (
        <Loader />
      ) : (
        <>
          <div className="proekt__content" ref={refList}>
            {experts.length > 0 ? (
              experts.map((item) => (
                <div className="proekt__list__item" key={item.id}>
                  <div className="proekt__content__top">
                    <div className="proekt__content__left">
                      <div className="proekt__content__person proekt__content__person--list">
                        <Link to={`/expert/${item.slug}`} className="proekt__pro-expert">
                          <div
                            className="proekt__content__avatar"
                            style={{
                              backgroundImage: `url(${item.avatar || avatarDefault})`,
                            }}
                          >
                            {' '}
                          </div>
                          {item.premium && (
                            <svg width="30" height="30" viewBox="0 0 64 65" className="pro-label">
                              <use xlinkHref={`${sprite}#proExpert`} />
                            </svg>
                          )}
                        </Link>
                        <div className="proekt__content__person__right">
                          <Link
                            to={`/expert/${item.slug}`}
                            className="proekt__content__person__name"
                          >
                            {item.profileName}
                            {item.pinned && (
                              <svg width="32" height="32" viewBox="0 0 32 32">
                                <use xlinkHref={`${sprite}#pinExpert`} />
                              </svg>
                            )}
                          </Link>
                          <div className="proekt__tags">
                            {item.costPerProject && (
                              <div className="proekt__tags__item">
                                <span className="tags-point">{item.costPerProject} грн/проект</span>
                              </div>
                            )}
                            {item.costPerProject && (
                              <div className="proekt__tags__item">
                                <span className="tags-point">{item.costPerHour} грн/час</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="proekt__rating">
                        <StarRate rate={item.avgReviewsRate} />

                        <div className="proekt__rating__count">{item.reviewsCount} отзывов</div>
                      </div>
                      <div className="proekt__location">
                        <svg width="14" height="20">
                          <use xlinkHref={`${sprite}#location-current`} />
                        </svg>
                        {item.country && item.country.name}
                        {item.city && `, ${item.city.name}`}
                      </div>
                    </div>
                    <div className="proekt__content__right">
                      <div className="proekt__actios">
                        <button
                          type="button"
                          className={item.rejected ? 'active' : ''}
                          onClick={(e) => {
                            setRejectModalXY({ pageX: e.pageX, pageY: e.pageY });
                            setIsReject(item.rejected);
                            setHandleRejectModal(item.id);
                          }}
                        >
                          <svg width="13.2px" height="13.2px">
                            <use xlinkHref={`${sprite}#dislike`} />
                          </svg>
                          <svg width="13.2px" height="13.2px" className="active-svg">
                            <use xlinkHref={`${sprite}#dislike-active`} />
                          </svg>
                        </button>
                        <button
                          type="button"
                          className={item.favorite ? 'active' : ''}
                          onClick={(e) => {
                            setFavoriteModalXY({
                              pageX: e.pageX,
                              pageY: e.pageY,
                            });
                            setIsFavorite(item.favorite);
                            setHandleFavoriteModal(item.id);
                          }}
                        >
                          <svg width="13.2px" height="13.2px">
                            <use xlinkHref={`${sprite}#like`} />
                          </svg>
                          <svg width="13.2px" height="13.2px" className="active-svg">
                            <use xlinkHref={`${sprite}#like-active`} />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="proekt__content__text">{item.services}</div>
                  <div className="proekt__content__tags">
                    {item.categories.length > 0 &&
                      item.categories.map((cat) => (
                        <div className="proekt__content__tags__item" key={cat.id}>
                          {cat.name}
                        </div>
                      ))}
                    {item.secondCategories.length > 0 &&
                      item.secondCategories.map((cat) => (
                        <div className="proekt__content__tags__item" key={cat.id}>
                          {cat.name}
                        </div>
                      ))}
                    {item.thirdCategories.map((cat) => (
                      <div className="proekt__content__tags__item" key={cat.id}>
                        {cat.name}
                      </div>
                    ))}
                  </div>
                  <div className="proekt__content__bot__new">
                    <Link to={`/expert/${item.slug}`} className="proekt__chat">
                      {translate('Experts.detail')}

                      <svg width="15" height="10">
                        <use xlinkHref={`${sprite}#new-arr`} />
                      </svg>
                    </Link>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        setInviteExpertId(item.id);
                        handleShowInvite();
                      }}
                    >
                      {translate('Experts.join')}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="mt-5 mb-5">{translate('Projects.empty')}</div>
            )}
          </div>

          {experts.length > 5 && (
            <div className="proekt__pagination">
              <ReactPaginate
                breakLabel="....."
                onPageChange={(nextPage) => handleChangePage(nextPage)}
                pageCount={expertsPag.last_page}
                forcePage={expertsPag.current_page - 1}
                activeClassName="active"
                pageRangeDisplayed="2"
                marginPagesDisplayed="1"
              />
            </div>
          )}
        </>
      )}

      {showInvite && (
        <InviteModal show={showInvite} handleClose={handleCloseInvite} expert={inviteExpertId} />
      )}

      {handleRejectModal !== 0 && (
        <RejectExpertModal
          handleRejectModal={handleRejectModal}
          rejectModalXY={rejectModalXY}
          setHandleRejectModal={setHandleRejectModal}
          dispatch={handleReject}
          isReject={isReject}
        />
      )}

      {handleFavoriteModal !== 0 && (
        <FavoriteExpertModal
          handleFavoriteModal={handleFavoriteModal}
          favoriteModalXY={favoriteModalXY}
          setHandleFavoriteModal={setHandleFavoriteModal}
          dispatch={handleFavorite}
          isFavorite={isFavorite}
        />
      )}
    </>
  );
};

export default Rejects;
