import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SubHeader = ({ tab, setTab }) => {
  const user = useSelector((state) => state.adminReducer.user);

  return (
    <div className="proekt__tabs tabs-nav proekt__tabs--admin">
      <ul>
        <li>
          <Link
            to="info"
            className={tab === 1 ? 'active' : ''}
            onClick={() => setTab(1)}
            disabled={tab === 1}
          >
            Експерт
          </Link>
        </li>
        <li>
          <Link
            to="info"
            className={tab === 2 ? 'active' : ''}
            onClick={() => setTab(2)}
            disabled={tab === 2}
          >
            Замовник
          </Link>
        </li>
      </ul>
    </div>
  );
};

SubHeader.propTypes = {
  tab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
};

export default SubHeader;
