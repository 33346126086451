import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import {
  Footer,
  Header,
  Loader,
  RateInfo,
  Sidebar,
  StarRate,
  Status,
  Subheader,
} from 'src/components';
import { useTranslation } from 'src/hooks';
import { customActions } from 'src/store/actions';

const Stat = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const rating = useSelector((state) => state.customReducer.rating);
  const waiter = useSelector((state) => state.customReducer.waiter);

  useEffect(() => {
    dispatch(customActions.getRatingInfo());
  }, []);

  let procent = Math.round((rating.rating / rating.max) * 100);
  procent = procent <= 100 ? procent : 100;

  const [showRateInfo, setShowRateInfo] = useState(false);
  const handleShowRateInfo = () => setShowRateInfo(true);
  const handleCloseRateInfo = () => setShowRateInfo(false);

  return (
    <div className="app">
      <Header />
      <main className="main">
        <Subheader />
        <div className="wrap">
          <Status />
          <div className="exp-content exp-content--company">
            <Sidebar />
            <div className="exp-content__edit">
              <div className="exp-content__top">{translate('Stat.t1')}</div>
              {waiter ? (
                <Loader />
              ) : (
                <div className="stat">
                  <div className="stat__title">{translate('Stat.t2')}</div>

                  <div className="stat__blocks">
                    <div className="stat__rate stat__rate--line">
                      <div className="stat__rate__text">
                        {translate('Stat.r')} <span>{rating.rating}</span>
                      </div>
                      <div className="stat__rate__line">
                        <div className="stat__rate__line2" style={{ width: `${procent}%` }}>
                          <span>{rating.rating > 0 && rating.rating}</span>
                        </div>
                        <button
                          type="button"
                          className="stat__info btn-reset"
                          onClick={handleShowRateInfo}
                        >
                          {translate('Stat.r2')}
                        </button>
                      </div>
                    </div>
                    <div className="stat__content">
                      <div className="stat__chart">
                        <div className="stat__chart__item tooltips-wrap">
                          {translate('Stat.t3')}
                          <div className="stat__chart__cifra">{rating.profileRating}</div>
                          <div
                            className="tooltips"
                            onClick={handleShowRateInfo}
                            onKeyPress={handleShowRateInfo}
                            role="button"
                            tabIndex="0"
                          >
                            {translate('Stat.r3')}
                          </div>
                        </div>
                        <div className="stat__chart__item tooltips-wrap">
                          {translate('Stat.t4')}
                          <div className="stat__chart__cifra">{rating.portfolioRating}</div>
                          <div
                            className="tooltips"
                            onClick={handleShowRateInfo}
                            onKeyPress={handleShowRateInfo}
                            role="button"
                            tabIndex="0"
                          >
                            {translate('Stat.r4')}
                          </div>
                        </div>
                        <div className="stat__chart__item tooltips-wrap">
                          {translate('Stat.t5')}
                          <div className="stat__chart__cifra">{rating.otherRating}</div>
                          <div
                            className="tooltips"
                            onClick={handleShowRateInfo}
                            onKeyPress={handleShowRateInfo}
                            role="button"
                            tabIndex="0"
                          >
                            {translate('Stat.r4')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="stat__detail">
                      <button type="button" onClick={handleShowRateInfo}>
                        {translate('Stat.d')}
                        <svg width="44" height="16">
                          <use xlinkHref={`${sprite}#detail`} />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="stat__blocks">
                    <div className="stat__rate">
                      <div className="stat__rate__text">
                        {translate('Stat.reviews')} <span>{rating.avgReviewsRate}</span>
                      </div>
                      <div className="stat__rate__star">
                        <StarRate rate={rating.avgReviewsRate || 0} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <RateInfo show={showRateInfo} handleClose={handleCloseRateInfo} />
    </div>
  );
};

export default Stat;
