import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PfdLabel from 'src/assets/img/resume/2.png';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';

const Resume = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);
  const [type, setType] = useState(false);
  const [file, setFile] = useState(false);
  const [maxsize, setMaxsize] = useState(false);
  const [isSub, setIsSub] = useState(false);

  const docRef = useRef();
  const hasResume = useSelector((state) => state.authReducer.user.expert.resume);

  const clear = () => {
    if (docRef.current) {
      docRef.current.value = null;
    }

    setFile(false);
    setType(false);
  };

  const onPdf = (event) => {
    const res = event.target.files[0];
    if (res.size < 10485760) {
      setFile(res);
      setType('doc');
      setMaxsize(false);
    } else {
      clear();
      setMaxsize(true);
    }
  };

  const handleDispatchSet = async () => {
    await dispatch(authActions.setResume({ file, type }));
    setIsSub(true);
    setTimeout(() => {
      setIsSub(false);
    }, 5000);

    if (firstSignUp) {
      navigate('/user/portfolio/');
    }
  };

  const handleDispatchDelete = async () => {
    if (hasResume) {
      await dispatch(authActions.deleteResume(hasResume.id));
      clear();
      setIsSub(true);
      setTimeout(() => {
        setIsSub(false);
      }, 5000);
    }
  };

  return (
    <div className="exp-content__edit">
      <div className="exp-content__top">{translate('Resume.title')}</div>

      <div className="exp-content__margin">
        <div className="exp-content__title">
          <h3>{translate('Resume.subTitle')}</h3>
        </div>
        <div className="exp-content__wrap exp-content__wrap--resume">
          <div className="upload-wrap">
            {hasResume ? (
              <div className="upload-wrap__col upload">
                <a href={hasResume.path} target="_blank" rel="noreferrer">
                  {' '}
                </a>
                <img src={PfdLabel} alt="" />
                <div className="upload-wrap__text">{translate('Resume.downloadImg')}</div>
                <div className="upload-wrap__text upload-wrap__text--file">{hasResume.name}</div>
                <button
                  className="upload-wrap__col__button"
                  type="button"
                  onClick={handleDispatchDelete}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3936 7.59497L7.60156 12.387"
                      stroke="#200E32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.3976 12.3898L7.60156 7.59277"
                      stroke="#200E32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.334 0.750244H5.665C2.644 0.750244 0.75 2.88924 0.75 5.91624V14.0842C0.75 17.1112 2.635 19.2502 5.665 19.2502H14.333C17.364 19.2502 19.25 17.1112 19.25 14.0842V5.91624C19.25 2.88924 17.364 0.750244 14.334 0.750244Z"
                      stroke="#200E32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <>
                {type !== 'img' && (
                  <label
                    className={`upload-wrap__col ${file ? 'upload' : ''}`}
                    htmlFor="inputFilePdf"
                  >
                    <img src={PfdLabel} alt="" />
                    <div className="upload-wrap__text">{translate('Resume.downloadPdf')}</div>
                    <div className="upload-wrap__text upload-wrap__text--file">
                      {file && file.name}
                    </div>
                    <input
                      type="file"
                      id="inputFilePdf"
                      onChange={onPdf}
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      ref={docRef}
                    />
                    <button className="upload-wrap__col__button" type="button" onClick={clear}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.3936 7.59497L7.60156 12.387"
                          stroke="#200E32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.3976 12.3898L7.60156 7.59277"
                          stroke="#200E32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.334 0.750244H5.665C2.644 0.750244 0.75 2.88924 0.75 5.91624V14.0842C0.75 17.1112 2.635 19.2502 5.665 19.2502H14.333C17.364 19.2502 19.25 17.1112 19.25 14.0842V5.91624C19.25 2.88924 17.364 0.750244 14.334 0.750244Z"
                          stroke="#200E32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </label>
                )}
              </>
            )}
          </div>
          {maxsize && <div className="error-text mb-3">{translate('Resume.maxsize')}</div>}
          <div className="upload-wrap__btns">
            {!hasResume && (
              <>
                {firstSignUp ? (
                  <>
                    {!file ? (
                      <Link to="/user/portfolio/" className="btn btn-primary">
                        {translate('next')}
                      </Link>
                    ) : (
                      <button type="button" className="btn btn-primary" onClick={handleDispatchSet}>
                        {translate('next')}
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleDispatchSet}
                    disabled={!file}
                  >
                    {translate('save')}
                  </button>
                )}
              </>
            )}
          </div>
          {isSub && (
            <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
              <svg
                className="bi flex-shrink-0 me-2"
                width="24"
                height="24"
                role="img"
                aria-label="Success:"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <div>{translate('Contacts.success')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Resume;
