import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

const ChangeEmail = ({ setChangeEmail, setSearchParams }) => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.authReducer.errors);
  const query = useQuery();

  const phone = query.get('phone');
  useEffect(() => () => dispatch(clearErrors()), []);

  const handleSubmit = async (values) => {
    setSearchParams({ phone, email: values.email });
    setChangeEmail(false);
  };
  const SignUpdateSchema = Yup.object().shape({
    email: Yup.string().email(translate('Valid.email')).required(translate('Valid.req')).nullable(),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: SignUpdateSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <div className="auth__wrap">
      <div className="container">
        <h1>{translate('Verify.emailChange')}</h1>
        <div className="auth__desc" />
        <form className="auth__form" id="signInForm" onSubmit={formik.handleSubmit}>
          <div className="auth__form__title">{translate('Verify.enterEmail')} </div>

          {error && error.email && <strong className="text-danger">{error.email}</strong>}

          <div className="input__row">
            <input
              type="email"
              className={formik.errors.email && formik.touched.email && 'is-invalid'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              placeholder="E-mail"
            />
            <div className="invalid-feedback">
              {formik.errors.email && formik.touched.email && formik.errors.email}
            </div>
          </div>

          <button className="auth__submit" type="submit">
            {translate('change')}
          </button>
        </form>
      </div>
    </div>
  );
};

ChangeEmail.propTypes = {
  setChangeEmail: PropTypes.func.isRequired,
  setSearchParams: PropTypes.func.isRequired,
};

export default ChangeEmail;
