import 'react-datepicker/dist/react-datepicker.css';

import { PropTypes } from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import sprite from 'src/assets/img/sprite.svg';
import { localeRu, localeUa } from 'src/constants';
import { useTranslation } from 'src/hooks';

const CustomButton = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className={`datapiker form-select ${value ? 'selected' : ''}`}
    onClick={onClick}
    ref={ref}
  >
    {value || 'Дата'}
  </button>
));

const Search = ({ searchText, handleSearch }) => {
  const { translate, language } = useTranslation();

  const [text, setText] = useState(searchText);
  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeText = (newText) => {
    setText(newText);
  };

  function handleDateClear() {
    setSelectedDate(null);
  }

  useEffect(() => {
    if (text || selectedDate) {
      handleSearch(text, selectedDate);
    }
  }, [text, selectedDate]);

  return (
    <div className="my-proekts-filter">
      <div className="my-proekts-filter__search">
        <input
          type="text"
          value={text}
          placeholder={translate('My-project.search')}
          onChange={(e) => handleChangeText(e.target.value)}
        />
        <button type="button">
          <svg width="17.5px" height="17.5px">
            <use xlinkHref={`${sprite}#search`} />
          </svg>
        </button>
        {text.length > 0 && (
          <button type="button" className="my-proekts-filter__clear" onClick={() => setText('')}>
            <svg width="17.5px" height="17.5px">
              <use xlinkHref={`${sprite}#close`} />
            </svg>
          </button>
        )}
      </div>
      <div className="my-proekts-filter__wrap">
        <div className="my-proekts-filter__item">
          {translate('My-project.filter')}

          <DatePicker
            locale={language === 'ua' ? localeUa : localeRu}
            calendarClassName="custom-datepicker"
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            customInput={<CustomButton />}
          />
          {selectedDate && (
            <button className="clearCalendar" type="button" onClick={handleDateClear}>
              <svg width="17.5px" height="17.5px">
                <use xlinkHref={`${sprite}#close`} />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Search.propTypes = {
  searchText: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
};
CustomButton.propTypes = null;
export default Search;
