import './style.scss';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Footer, Header, Sidebar, Status, Subheader } from 'src/components';
import { chatActions } from 'src/store/actions';

import { ChatCompany, ChatExpert, ListCompany, ListExpert } from './components';

const Messages = () => {
  const { role } = useSelector((state) => state.authReducer.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (role === 1) {
      dispatch(chatActions.getChatListExpert());
    } else {
      dispatch(chatActions.getChatListCompany());
    }
  }, []);

  return (
    <div className="app">
      <Header />
      <main className="main">
        <Subheader />
        <div className="wrap">
          {role && role === 1 && <Status />}
          <div className="exp-content exp-content--company exp-content--chat">
            <Sidebar />
            <div className="exp-content__edit">
              <Routes>
                <Route path="/" element={role === 1 ? <ListExpert /> : <ListCompany />} />
                <Route path="/:id" element={role === 1 ? <ChatExpert /> : <ChatCompany />} />
              </Routes>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Messages;
