/* eslint-disable */

import 'react-quill/dist/quill.snow.css';
import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from 'src/store/actions';
import { useParams, useNavigate } from 'react-router-dom';

const EditPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState(false);
  const { id } = useParams();
  const knowledgeBaseDetail = useSelector((state) => state.adminReducer.knowledgeBaseDetail);
  const fixLoader = useSelector((state) => state.adminReducer.fixLoader);

  useEffect(() => {
    dispatch(adminActions.getKnowledgeBaseDetail(id));
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  const SignInSchema = Yup.object().shape({
    name_ua: Yup.string().required('Назва українською це обоязкове поле'),
    name: Yup.string().required('Назва російською це обоязкове поле'),
    text_ua: Yup.string().required('Контент українською це обовязкове поле'),
    text: Yup.string().required('Контент російською це обовязкове поле'),
  });

  const handleSubmit = async (values) => {
    await dispatch(adminActions.updateKnowledge(values));
    navigate('/admin/baza/');
  };

  const formik = useFormik({
    initialValues: {
      id: knowledgeBaseDetail.id || '',
      name_ua: knowledgeBaseDetail.name_ua || '',
      name: knowledgeBaseDetail.name || '',
      text_ua: knowledgeBaseDetail.text_ua || '',
      text: knowledgeBaseDetail.text || '',
    },
    enableReinitialize: true,
    validationSchema: SignInSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleChangeUa = (value) => {
    formik.setFieldValue('text_ua', value);
  };
  const handleChangeRu = (value) => {
    formik.setFieldValue('text', value);
  };

  return (
    <>
      {fixLoader ? (
        <Loader />
      ) : (
        <>
          <div className="admin_knowledge_header">
            {tab ? knowledgeBaseDetail.name : knowledgeBaseDetail.name_ua}
          </div>
          <div className="proekt__tabs tabs-nav proekt__tabs--payment mt-0">
            <ul>
              <li>
                <button
                  type="button"
                  className={!tab ? 'active' : ''}
                  onClick={() => setTab(false)}
                >
                  UA
                </button>
              </li>
              <li>
                <button type="button" className={tab ? 'active' : ''} onClick={() => setTab(true)}>
                  RU
                </button>
              </li>
            </ul>
          </div>
          <form onSubmit={formik.handleSubmit}>
            {!tab && (
              <div className="baza__create">
                <div className="baza__create__row">
                  <div className="baza__create__title">Назва українською</div>
                  <input
                    className={`input ${
                      formik.errors.name_ua && formik.touched.name_ua && 'error'
                    }`}
                    type="text"
                    placeholder="Назва ..."
                    name="name_ua"
                    value={formik.values.name_ua}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="baza__create__row">
                  <div className="baza__create__title">Контент українською</div>
                  <ReactQuill
                    value={formik.values.text_ua}
                    modules={modules}
                    formats={formats}
                    onChange={handleChangeUa}
                  />
                </div>
              </div>
            )}
            {tab && (
              <div className="baza__create">
                <div className="baza__create__row">
                  <div className="baza__create__title">Назва російською</div>
                  <input
                    className={`input ${formik.errors.name && formik.touched.name && 'error'}`}
                    type="text"
                    placeholder="Назва ..."
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="baza__create__row">
                  <div className="baza__create__title">Контент російською</div>
                  <ReactQuill
                    value={formik.values.text}
                    modules={modules}
                    formats={formats}
                    onChange={handleChangeRu}
                  />
                </div>
              </div>
            )}
            <div className="prpb__error">
              {formik.errors.name_ua && formik.touched.name_ua && (
                <div className="prpb__error__item mt-2">{formik.errors.name_ua}</div>
              )}
              {formik.errors.text_ua && (
                <div className="prpb__error__item mt-2">{formik.errors.text_ua}</div>
              )}
              {formik.errors.name && formik.touched.name && (
                <div className="prpb__error__item mt-2">{formik.errors.name}</div>
              )}
              {formik.errors.text && (
                <div className="prpb__error__item mt-2">{formik.errors.text}</div>
              )}
            </div>
            <div className="baza__create__row">
              <button className="btn btn-primary" type="submit">
                Зберегти
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default EditPage;
