import { PropTypes } from 'prop-types';
import { useTranslation } from 'src/hooks';

const Status = ({ status }) => {
  const { translate } = useTranslation();
  return (
    <div className="proekt__detail__status">
      <div className={`proekt__detail__status__item ${status === 2 ? 'active' : ''}`}>
        <span>{translate('Statuses.t1')}</span>
      </div>
      <div className={`proekt__detail__status__item ${status === 3 ? 'active' : ''}`}>
        <span>{translate('Statuses.t2')}</span>
      </div>
      <div className={`proekt__detail__status__item ${status === 4 ? 'active' : ''}`}>
        <span>{translate('Statuses.t3')}</span>
      </div>
      <div className={`proekt__detail__status__item ${status === 5 ? 'active' : ''}`}>
        <span>{translate('Statuses.t4')}</span>
      </div>
      <div className={`proekt__detail__status__item ${status === 6 ? 'active' : ''}`}>
        <span>{translate('Statuses.t5')}</span>
      </div>
      <div className={`proekt__detail__status__item ${status === 7 ? 'active' : ''}`}>
        <span>{translate('Statuses.t6')}</span>
      </div>
    </div>
  );
};
Status.propTypes = {
  status: PropTypes.number.isRequired,
};
export default Status;
