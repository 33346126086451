import './style.scss';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { FixLoader, Footer, HeaderContentPage } from 'src/components';
import { useTranslation } from 'src/hooks';

import Detail from './detail';
import List from './list';
import Search from './search';

const Base = () => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const authWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const location = useLocation();
  const firstUrlPath = location.pathname;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [firstUrlPath]);

  const [detailName, setDetailname] = useState('');
  const [search, setSearch] = useState('');
  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/baza-znan/search/${search}`);
  };

  return (
    <>
      {authWaiter && <FixLoader />}
      <div className="app base">
        <HeaderContentPage />
        <main className="main">
          <div className="wrap">
            <div className="base__main mt-3">
              <div className="proekt__search">
                <form className="proekt__search__wrap" onSubmit={handleSearch}>
                  <div className="selectize-search">
                    <svg width="17.5px" height="17.5px" className="top-search__icon">
                      <use xlinkHref={`${sprite}#search`} />
                    </svg>
                    <input
                      className="input"
                      type="text"
                      placeholder={translate('baza.searchPl')}
                      minLength={3}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary top-search__btn">
                    {translate('baza.searchBtn')}
                  </button>
                </form>
              </div>
              <nav className="prpb__breadcrumb">
                <ul>
                  <li>
                    <a href="/">{translate('baza.main')}</a>
                  </li>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <li>
                          <span>{translate('baza.title')}</span>
                        </li>
                      }
                    />
                    <Route
                      path="/search/:search"
                      element={
                        <>
                          <li>
                            <Link to="/baza-znan/">{translate('baza.title')}</Link>
                          </li>
                          <li>
                            <span>{translate('baza.search')}</span>
                          </li>
                        </>
                      }
                    />
                    <Route
                      path="/:slug"
                      element={
                        <>
                          <li>
                            <Link to="/baza-znan/">{translate('baza.title')}</Link>
                          </li>

                          <li>
                            <span>{detailName}</span>
                          </li>
                        </>
                      }
                    />
                  </Routes>
                </ul>
              </nav>
              <div className="exp-content">
                <div className="exp-content__edit">
                  <div className="exp-content__top exp-content__top__mess">
                    📚 {translate('baza.title')}
                  </div>
                  <Routes>
                    <Route path="/" element={<List />} />
                    <Route path="/search/:search" element={<Search />} />
                    <Route path="/:slug" element={<Detail setDetailname={setDetailname} />} />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Base;
