import { useSelector } from 'react-redux';
import { NavLink, Route, Routes } from 'react-router-dom';

import { Bcat, Contacts, Info, Payment, Portfolio, Resume, Spec } from './components';

const Expert = () => {
  const { expert } = useSelector((state) => state.adminReducer.user);

  return (
    <>
      {expert ? (
        <div className="admin-user-detail">
          <ul className="admin-user-detail__nav">
            <li>
              <NavLink to="info">Особиста інформація </NavLink>
            </li>
            <li>
              <NavLink to="contacts">Контактна інформація </NavLink>
            </li>
            <li>
              <NavLink to="portfolio">Портфоліо </NavLink>
            </li>
            <li>
              <NavLink to="resume">Резюме</NavLink>
            </li>
            <li>
              <NavLink to="b-cat">Категорії бізнесу </NavLink>
            </li>
            <li>
              <NavLink to="spec">Спеціалізації </NavLink>
            </li>
            <li>
              <NavLink to="payment">Платні функції </NavLink>
            </li>
          </ul>
          <div className="admin-user-detail__content">
            <Routes>
              <Route path="/info" element={<Info />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/resume" element={<Resume />} />
              <Route path="/b-cat" element={<Bcat />} />
              <Route path="/spec" element={<Spec />} />
              <Route path="/payment" element={<Payment />} />
            </Routes>
          </div>
        </div>
      ) : (
        <div>Цей профіль не зареєстровано</div>
      )}
    </>
  );
};

export default Expert;
