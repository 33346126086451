import './style.scss';

import { Route, Routes } from 'react-router-dom';

import { IndexPage } from './components';
import Edit from './edit-project';

const Projects = () => (
  <div className="admin-wrap">
    <div className="wrap">
      <Routes>
        <>
          <Route path="/" element={<IndexPage />} />
          <Route path="/:id/*" element={<Edit />} />
        </>
      </Routes>
    </div>
  </div>
);

export default Projects;
