import { useSelector } from 'react-redux';
import defaultLogo from 'src/assets/img/proekt/avatar-default.jpg';
import { FixLoader, Footer, Header, Sidebar, Status, Subheader } from 'src/components';
import { useTranslation } from 'src/hooks';

const Help = () => {
  const { translate } = useTranslation();
  const authWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const { role } = useSelector((state) => state.authReducer.user);
  return (
    <>
      {authWaiter && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            {role && role === 1 && <Status />}
            <div className="exp-content exp-content--company">
              <Sidebar />
              <div className="exp-content__edit">
                <div className="exp-content__top exp-content__top__mess">
                  {translate('Help.title')}
                </div>
                <div className="proekt__content proekt__content__app">
                  <div className="app__messList">
                    <div className="app__item">
                      <div className="app__mess">
                        <div className="app__mess__avatar">
                          <div
                            className="proekt__content__avatar"
                            style={{ backgroundImage: `url(${defaultLogo})` }}
                          />
                        </div>
                        <div className="app__mess__right">
                          <div className="proekt__content__top">
                            <div className="proekt__content__left">
                              <div className="proekt__content__person">
                                <div className="proekt__content__person__name">
                                  Какой то администратор
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="proekt__content__text">
                            Adipiscing diam donec adipiscing tristique. Feugiat nisl pretium fusce
                            id velit ut. Fringilla urna porttitor rhoncus dolor purus non enim. Est
                            ullamcorper eget nulla facilisi etiam dignissim. Phasellus egestas
                            tellus rutrum tellus pellentesque. Blandit turpis cursus in hac. Id
                            neque aliquam vestibulum morbi blandit cursus risus at ultrices. Mollis
                            nunc sed id semper risus in. Mauris in aliquam sem fringilla ut morbi
                            tincidunt augue.
                          </div>
                          <div className="app__item__data">05.05.2021 12:55</div>
                        </div>
                      </div>
                    </div>

                    <div className="app__messAction">
                      <form className="app__sendMess">
                        <div className="textarea">
                          <textarea placeholder="Напишите сообщение" />
                        </div>
                        <div className="app__sendMess__bot">
                          <button className="btn btn-primary" type="submit">
                            {translate('send')}
                          </button>
                          <label className="app__file" htmlFor="inputfile">
                            <input type="file" id="inputfile" />
                            <div className="app__file__wrap">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g opacity="0.5">
                                  <path
                                    d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                                    stroke="#3C64B1"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                                    stroke="#3C64B1"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M21 15L16 10L5 21"
                                    stroke="#3C64B1"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                              </svg>

                              <div className="filename" id="filename" />
                              <div className="filePlaceholder">{translate('Help.file')}</div>
                            </div>
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Help;
