import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';

const CoverChoose = ({ show, handleClose }) => {
  const { translate } = useTranslation();
  const { role } = useSelector((state) => state.authReducer.user);
  const { covers } = useSelector((state) => state.authReducer);
  const { waiter } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (covers.length === 0) {
      dispatch(authActions.getCovers());
    }
  }, []);

  const handleSub = (data) => {
    dispatch(authActions.chooseCover({ data, role }));
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      template: (covers[0] && covers[0].id) || 1,
    },
    onSubmit: (values) => handleSub(values),
  });
  return (
    <Modal show={show} onHide={handleClose} className="spec-modal">
      <Modal.Body>
        <form className="exp-modal exp-modal-pic" onSubmit={formik.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title">{translate('About.addShablon')}</h5>
            <button type="button" onClick={handleClose} className="spec-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalCloseWhite`} />
              </svg>
            </button>
          </div>
          {waiter ? (
            <Loader />
          ) : (
            <div className="exp-modal__addProect exp-modal__addProect--about">
              <div className="shablon-flex">
                {covers &&
                  covers.map((cover) => (
                    <label
                      className="shablon-flex__item"
                      htmlFor={`input${cover.id}`}
                      key={cover.id}
                    >
                      <input
                        type="radio"
                        name="template"
                        id={`input${cover.id}`}
                        value={cover.id}
                        checked={cover.id === Number(formik.values.template)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div
                        className="shablon-flex__item__inner"
                        style={{ backgroundImage: `url(${cover.path})` }}
                      />
                    </label>
                  ))}
              </div>
            </div>
          )}

          <div className="exp-modal-footer">
            <div className="upload-wrap__btns">
              <button type="submit" className="btn btn-primary">
                {translate('save')}
              </button>
              <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
                {translate('cancel')}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
CoverChoose.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default CoverChoose;
