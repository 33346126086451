import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'src/hooks';

const ProjectNav = () => {
  const { cLevel1Slug } = useParams();
  const { translate } = useTranslation();
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);

  return (
    <nav className="proekt__nav">
      <ul>
        <li>
          <NavLink
            to="/projects/"
            end
            className={() =>
              cLevel1Slug !== 'favorites' && cLevel1Slug !== 'rejects' ? 'active' : undefined
            }
          >
            {translate('projectNav.projects')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={ifAuth ? '/projects/favorites/' : '/not-auth/'}
            className={({ isActive }) => (isActive ? 'active' : undefined)}
          >
            {translate('projectNav.favorites')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={ifAuth ? '/projects/rejects/' : '/not-auth/'}
            className={({ isActive }) => (isActive ? 'active' : undefined)}
          >
            {translate('projectNav.rejects')}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default ProjectNav;
