import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const RateInfo = ({ show, handleClose }) => {
  const { translate } = useTranslation();
  return (
    <Modal show={show} onHide={handleClose} className="invite">
      <Modal.Body>
        <div className="exp-modal info-modal">
          <div className="modal-header">
            <h5 className="modal-title">{translate('statInfo.title')}</h5>
            <button type="button" className="modal__close" onClick={handleClose}>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalCloseWhite`} />
              </svg>
            </button>
          </div>

          <div
            className="modal-text"
            dangerouslySetInnerHTML={{ __html: translate('statInfo.text') }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
RateInfo.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default RateInfo;
