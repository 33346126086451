import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

const ExpertPersonalInfo = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.authReducer.user);
  const { profileName, slug, website, facebook, linkedin } = useSelector(
    (state) => state.authReducer.user.expert,
  );
  const { firstName, lastName } = useSelector((state) => state.authReducer.user);
  const errors = useSelector((state) => state.authReducer.errors);

  const expLink = `${window.location.origin}/expert/${slug}`;
  const [isSub, setIsSub] = useState(false);
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);

  useEffect(() => () => dispatch(clearErrors()), []);

  const personalInfoSchema = Yup.object().shape({
    profileName: Yup.string().required(translate('Valid.req')).min(3, translate('Valid.min3')),
    firstName: Yup.string().required(translate('Valid.req')).min(3, translate('Valid.min3')),
    lastName: Yup.string().required(translate('Valid.req')).min(3, translate('Valid.min3')),
  });

  const handleSubmit = (data) => {
    dispatch(authActions.personalInfo({ data, role })).then((res) => {
      if (res.payload.status === 200) {
        setIsSub(true);
        setTimeout(() => {
          setIsSub(false);
        }, 5000);

        if (firstSignUp) {
          window.scrollTo({
            top: document.getElementById('education').offsetTop - 80,
          });
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      profileName,
      firstName,
      lastName,
      website: website || '',
      facebook: facebook || '',
      linkedin: linkedin || '',
    },
    validationSchema: personalInfoSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <form className="exp-content__wrap" id="formPersonalInfo" onSubmit={formik.handleSubmit}>
      <div className="exp-content__row">
        <div className="exp-content__row__left">
          <div className="label">
            {translate('About.profileName')} <span>*</span>
          </div>
        </div>
        <div className="exp-content__row__right">
          <input
            className={`input ${
              errors.profileName || (formik.errors.profileName && formik.touched.profileName)
                ? 'error'
                : ''
            }`}
            type="text"
            name="profileName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.profileName}
            placeholder={translate('About.fullNamePlaceholder')}
            maxLength={50}
          />
          {errors.profileName && (
            <div className="error-text">{translate('About.errorFullName')}</div>
          )}
          {formik.errors.profileName && formik.touched.profileName && (
            <div className="error-text">{formik.errors.profileName}</div>
          )}
          <a href={expLink} className="exp-link">
            {expLink}
          </a>
        </div>
      </div>

      <div className="exp-content__row mb-5">
        <div className="exp-content__row__left">
          <div className="label">
            {translate('About.name')} <span>*</span>
          </div>
        </div>
        <div className="exp-content__row__right">
          <div className="row">
            <div className="col-12 col-md-6">
              <input
                className={`input ${
                  formik.errors.firstName && formik.touched.firstName ? 'error' : ''
                }`}
                type="text"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                placeholder={translate('About.firstName')}
                maxLength={50}
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <div className="error-text">{formik.errors.firstName}</div>
              )}
            </div>
            <div className="col-12 col-md-6">
              <input
                className={`input ${
                  formik.errors.lastName && formik.touched.lastName ? 'error' : ''
                }`}
                type="text"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                placeholder={translate('About.lastName')}
                maxLength={50}
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <div className="error-text">{formik.errors.lastName}</div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="exp-content__row">
        <div className="exp-content__row__left">
          <div className="label">{translate('About.website')}</div>
        </div>
        <div className="exp-content__row__right">
          <input
            className="input"
            type="text"
            name="website"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.website}
            maxLength={50}
          />
        </div>
      </div>

      <div className="exp-content__row">
        <div className="exp-content__row__left">
          <div className="label">{translate('About.fb')}</div>
        </div>
        <div className="exp-content__row__right">
          <input
            className="input"
            type="text"
            name="facebook"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.facebook}
            maxLength={50}
          />
        </div>
      </div>

      <div className="exp-content__row">
        <div className="exp-content__row__left">
          <div className="label">{translate('About.lkdn')}</div>
        </div>
        <div className="exp-content__row__right">
          <input
            className="input"
            type="text"
            name="linkedin"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.linkedin}
            maxLength={50}
          />
          <button type="submit" className="btn btn-primary">
            {firstSignUp ? translate('next') : translate('save')}
          </button>
        </div>
      </div>
      {isSub && (
        <div
          className={`alert alert-success d-flex align-items-center mt-3 ${
            firstSignUp ? 'fix' : ''
          }`}
          role="alert"
        >
          <svg
            className="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Success:"
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          <div>{translate('Contacts.success')}</div>
        </div>
      )}
    </form>
  );
};

export default ExpertPersonalInfo;
