import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const KnowledgeTable = ({ getBase }) => {
  const dispatch = useDispatch();
  const knowledgeBase = useSelector((state) => state.adminReducer.knowledgeBase);

  const [deleteId, setDeleteId] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    await dispatch(adminActions.deleteKnowledge(deleteId));
    getBase();
  };

  return (
    <div className="knowledge_table_warp">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Назва</th>
            <th>Дія</th>
          </tr>
        </thead>
        <tbody className="admin_knowledge_action_btn">
          {knowledgeBase &&
            knowledgeBase.map((item) => (
              <tr key={item.id}>
                <td>
                  <a href={`/baza-znan/${item.slug}`} target="_blank" rel="noreferrer">
                    {item.name}
                  </a>
                </td>
                <td style={{ width: 300 }}>
                  <Link
                    to={`/admin/baza/edit/${item.id}`}
                    type="button"
                    className="btn btn-primary"
                  >
                    Редагувати
                  </Link>
                  <button
                    type="button"
                    onClick={() => handleShow(item.id)}
                    className="btn btn-danger"
                  >
                    Видалити
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="not-auth__title">Ви дійсно хочете видалити статю?</div>
            <div className="not-auth__btn">
              <button
                type="submit"
                className="btn btn-primary btn--new"
                onClick={() => {
                  handleDelete();
                  handleClose();
                }}
              >
                Так
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={() => {
                  handleClose();
                }}
              >
                Ні
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
KnowledgeTable.propTypes = {
  getBase: PropTypes.func.isRequired,
};
export default KnowledgeTable;
