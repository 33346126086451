import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';

const PaymentStatus = () => {
  const query = useQuery();
  const { translate } = useTranslation();
  const refMess = query.get('refMess');
  // Скролл на початок компонента
  const pageRef = useRef();

  const srollToTop = () => {
    window.scrollTo({
      top: 80,
    });
  };
  useEffect(() => {
    srollToTop();
  }, [pageRef]);

  return (
    <div className="paymentStatus" ref={pageRef}>
      <div className="paymentStatus__text">{refMess || translate('Payment.status')}</div>

      <Link to="/payment/paid-complited" className="btn btn-primary">
        Ок
      </Link>
    </div>
  );
};

export default PaymentStatus;
