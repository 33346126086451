import { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { SocketContext } from 'src/socketContext';
import { authActions } from 'src/store/actions';

const BlockUser = () => {
  const socket = useContext(SocketContext);
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const dispatch = useDispatch();
  const navigate = useNavigate('/');

  const [show, setShow] = useState(false);

  const role = useSelector((state) => state.authReducer.user.role);
  const expertId = useSelector((state) =>
    state.authReducer.user.expert ? state.authReducer.user.expert.id : 0,
  );
  const companyId = useSelector((state) =>
    state.authReducer.user.company ? state.authReducer.user.company.id : 0,
  );

  const handleBlock = async () => {
    await dispatch(authActions.logout());
    setShow(true);
  };

  useEffect(() => {
    if (ifAuth) {
      socket.on('connect', () => {});

      if (role === 1) {
        socket.emit('subscribe', {
          expertModal: expertId,
        });

        socket.on(`expertModal.${expertId}`, (data) => {
          if (data) {
            handleBlock();
          }
        });
      } else {
        socket.emit('subscribe', {
          companyModal: companyId,
        });

        socket.on(`companyModal.${companyId}`, (data) => {
          if (data) {
            handleBlock();
          }
        });
      }
    }
  }, [role, ifAuth, socket]);

  const handleClose = () => {
    setShow(false);
    navigate('/auth/');
  };

  return (
    <Modal show={show} onHide={handleClose} className="invite">
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" className="proekt-modal__close" onClick={handleClose}>
            <svg className="icon" width="20px" height="20px">
              <use xlinkHref={`${sprite}#close`} />
            </svg>
          </button>
          <div className="not-auth__title">Ваш аккаунт заблокирован</div>
          <div className="custom-modal__subt custom-modal__subt--public">
            Для /?........... Вы можете обратиться в{' '}
            <Link
              to="/"
              onClick={() => {
                window.HelpCrunch('openChat');
              }}
            >
              службу поддержки
            </Link>
          </div>
          <div className="not-auth__btn">
            <button type="button" className="btn btn-primary btn--new" onClick={handleClose}>
              Назад
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BlockUser;
