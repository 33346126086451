import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import * as Yup from 'yup';

const Step4 = ({ setValue, setShowAllLinks }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const currentProject = useSelector((state) => state.projectReducer.currentProject);

  const handleSubmit = ({ budget, doNotShowPayment }) => {
    setShowAllLinks(true);
    setValue('budget', budget);
    setValue('doNotShowPayment', doNotShowPayment);
    navigate('/publication/preview/');
  };

  const Step4Schema = Yup.object().shape({
    budget: Yup.number()
      .integer('Має бути цілим числом')
      .min(1, translate('Publication.budgetTextMinError'))
      .required(translate('Valid.req')),
    otherwise: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      budget: currentProject.budget,
      doNotShowPayment: currentProject.doNotShowPayment,
    },
    validationSchema: Step4Schema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleChange = (val, name) => {
    const newVal = val
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/^0[^.]/, '0');
    formik.setFieldValue(name, newVal);
  };

  return (
    <form className="prpb__wrap" onSubmit={formik.handleSubmit}>
      <div className="prpb__name">{translate('Publication.budget')}</div>
      <div className="prpb__body">
        <div className="prpb__label">{translate('Publication.budgetText')}</div>
        <div className="prpb__budget">
          <input
            placeholder="0"
            className="input"
            type="text"
            name="budget"
            value={formik.values.budget}
            onChange={(e) => handleChange(e.target.value, 'budget')}
            maxLength={50}
          />
          <div className="prpb__budget__currency">{translate('Publication.budgetVal')}</div>
        </div>
        <div className="prpb__error">
          {formik.touched.budget && formik.errors.budget && (
            <div className="prpb__error__item">{formik.errors.budget}</div>
          )}
        </div>

        <div className="prpb__radios">
          <label className="prpb__radios__item" htmlFor="doNotShowPayment">
            <input
              type="checkbox"
              name="doNotShowPayment"
              id="doNotShowPayment"
              defaultChecked={formik.values.doNotShowPayment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="prpb__radios__input" />
            <div className="prpb__radios__text">{translate('Publication.budgetInfo')} </div>
          </label>
        </div>
      </div>
      <div className="prpb__footer">
        <button className="btn btn-primary" type="submit">
          {translate('next')}
        </button>
        <Link className="btn btn-outline-primary" to="/publication/step3/sub-step5/">
          {translate('cancel')}
        </Link>
      </div>
    </form>
  );
};

Step4.propTypes = {
  setValue: PropTypes.func.isRequired,
  setShowAllLinks: PropTypes.func.isRequired,
};

export default Step4;
