import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'src/hooks';

const Business = () => {
  const { translate } = useTranslation();
  const { businessCategories } = useSelector((state) => state.authReducer.user.expert);
  return (
    <>
      {businessCategories.length > 0 && (
        <div className="exp-profile__row">
          <div className="exp-content__title">
            <h3>{translate('Profile.Business')}</h3>
          </div>
          <div className="tags mb-5">
            {businessCategories &&
              businessCategories.map((item) => (
                <div key={item.id} className="tags__item">
                  {item.name}
                </div>
              ))}
          </div>
          <div className="exp-profile__bot-btn">
            <Link to="/user/business/" className="btn btn-primary">
              {translate('edit')}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Business;
