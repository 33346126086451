import './style.scss';

import { useSelector } from 'react-redux';
import { NavLink, Route, Routes } from 'react-router-dom';
import { FixLoader, Footer, Header, Sidebar, Status, Subheader } from 'src/components';
import { useTranslation } from 'src/hooks';

import { Balance, History, Paid, PaidComplited, PaymentStatus } from './components';

const Payment = () => {
  const { translate } = useTranslation();

  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const { role } = useSelector((state) => state.authReducer.user);

  return (
    <>
      {fixWaiter && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            {role && role === 1 && <Status />}
            <div className="exp-content exp-content--company">
              <Sidebar />
              <div className="exp-content__edit">
                <div className="exp-content__top">{translate('Payment.title')}</div>
                <div className="proekt__tabs tabs-nav proekt__tabs--payment">
                  <ul>
                    <li>
                      <NavLink to="/payment" end>
                        {translate('Payment.balance')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="paid">{translate('Payment.paid')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="paid-complited">{translate('Payment.paidComp')}</NavLink>
                    </li>
                    {role === 2 && (
                      <li>
                        <NavLink to="history">{translate('Payment.history')}</NavLink>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="proekt__content proekt__content__payment">
                  <Routes>
                    <Route path="/*" element={<Balance />} />
                    <Route path="/paid" element={<Paid />} />
                    <Route path="/paid-complited" element={<PaidComplited />} />
                    {role === 2 && <Route path="/history" element={<History />} />}
                    <Route path="/status" element={<PaymentStatus />} />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Payment;
