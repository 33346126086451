import { useSelector } from 'react-redux';
import { Loader } from 'src/components';

import Education from './education';
import Langs from './langs';
import Portfolio from './portfolio';
import Resume from './resume';
import Reviews from './reviews';
import Service from './service';
import Spec from './spec';

const Info = () => {
  const waiter = useSelector((state) => state.projectReducer.waiter);

  return (
    <div className="exp-profile__tabs__content active">
      {waiter ? (
        <Loader />
      ) : (
        <>
          <Service />
          <Spec />
          <Education />
          <Langs />
          <Resume />
          <Portfolio />
          <Reviews />
        </>
      )}
    </div>
  );
};

export default Info;
