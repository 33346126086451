import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/old-img/sprite.svg';
import { useTranslation } from 'src/hooks';

const Thank = () => {
  const { translate } = useTranslation();
  return (
    <div className="auth__wrap pt-5">
      <div className="container">
        <div className="auth__desc" />
        <div className="auth__form">
          <Link to="/user/contacts/" className="auth__form__close">
            <div>
              <svg className="icon">
                <use xlinkHref={`${sprite}#close`} />
              </svg>
            </div>
          </Link>
          <div className="auth__form__title text-center">{translate('Verify.thankT')}</div>
          <Link to="/user/contacts/" className="auth__submit" type="submit">
            {translate('Verify.thankBtn')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Thank;
