import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const Status = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const project = useSelector((state) => state.adminReducer.project);
  const [status, setStatus] = useState();
  const { id, hidden } = project;

  useEffect(() => {
    setStatus(project.status);
  }, [project]);

  const handleSub = () => {
    dispatch(adminActions.updateProjectStatus({ id, status }));
  };

  const handleSubBlock = async () => {
    await dispatch(adminActions.hideProject({ id }));
    navigate('/admin/projects');
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  return (
    <>
      <div className="exp-content__title">
        <h3>Статут проекта</h3>
      </div>
      <div className="exp-content__wrap">
        <div className="exp-content__row">
          <div className="content__row__left">
            <label className="admin_input_label" htmlFor="status">
              <select
                id="status"
                name="status"
                className="form-select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="1">Опубілкован</option>
                <option value="2">Прийом заявок</option>
                <option value="3">Затвердження умов</option>
                <option value="4">Виконання проекту</option>
                <option value="5">Перевірка роботи замовником</option>
                <option value="6">Оплата</option>
                <option value="7">Відгуки</option>
              </select>
            </label>
          </div>
        </div>
        <button type="button" onClick={handleShow} className="btn btn-primary">
          Зберегти
        </button>

        <div className="exp-content__row mt-5">
          {hidden ? (
            'Проект приховано'
          ) : (
            <button type="button" onClick={handleShow2} className="btn btn-danger">
              Приховати проєкт
            </button>
          )}
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="not-auth__title">Ви дійсно хочете внести зміни?</div>
            <div className="not-auth__btn">
              <button
                type="submit"
                onClick={() => {
                  handleSub();
                  handleClose();
                }}
                className="btn btn-primary btn--new"
              >
                Так
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={() => {
                  handleClose();
                }}
              >
                Ні
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="not-auth__title">Ви дійсно хочете приховати проєкт?</div>
            <div className="not-auth__btn">
              <button
                type="submit"
                onClick={() => {
                  handleSubBlock();
                  handleClose2();
                }}
                className="btn btn-primary btn--new"
              >
                Так
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={() => {
                  handleClose2();
                }}
              >
                Ні
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Status;
