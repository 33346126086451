import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { adminActions } from 'src/store/actions';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const Education = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const { education, id } = useSelector((state) => state.adminReducer.user.expert);
  const years = [];
  for (let i = 1970; i <= new Date().getFullYear(); i += 1) {
    years.push({ value: i, label: i });
  }

  const handleSubmit = (data) => {
    dispatch(adminActions.expertUpdateEducation(data));
  };

  const educationSchema = Yup.object().shape({
    education: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(translate('Valid.req')),
        from: Yup.string().required(translate('Valid.req')),
        to: Yup.string().required(translate('Valid.req')),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      id,
      education,
    },
    validationSchema: educationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const addRow = () => {
    formik.setFieldValue('education', [
      ...formik.values.education,
      { id: uuidv4(), name: '', from: '', to: '' },
    ]);
  };

  const removeRow = () => {
    const length = formik.values.education.length - 1;

    formik.setFieldValue(
      'education',
      formik.values.education.filter((item, index) => index !== length),
    );
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="exp-content__title" id="education">
        <h3>Освіта</h3>
      </div>
      <form id="educationForm1" className="exp-content__wrap" onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <FieldArray
            name="education"
            render={() => (
              <div>
                {formik.values.education.map((item, index) => (
                  <div className="expirience-block expirience-block--education" key={item.id}>
                    <div className="exp-content__row">
                      <div className="exp-content__row__left">
                        <div className="label">
                          Навчальний заклад <span>*</span>
                        </div>
                      </div>
                      <div className="exp-content__row__right">
                        <input
                          className={`input ${
                            formik.errors.education &&
                            formik.errors.education[index] &&
                            formik.errors.education[index].name
                              ? 'error'
                              : ''
                          }`}
                          type="text"
                          name={`education[${index}].name`}
                          value={formik.values.education[index].name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          maxLength={50}
                        />
                        {formik.errors.education &&
                          formik.errors.education[index] &&
                          formik.errors.education[index].name && (
                            <div className="error-text">
                              {formik.errors.education &&
                                formik.errors.education[index] &&
                                formik.errors.education[index].name}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="exp-content__row">
                      <div className="exp-content__row__left">
                        <div className="label">
                          Період навчання <span>*</span>
                        </div>
                      </div>
                      <div className="exp-content__row__right">
                        <div className="period">
                          <div className="period-wrap">
                            {translate('About.from')}
                            <Select
                              options={years}
                              placeholder="рік"
                              classNamePrefix="react-select"
                              className={`react-select react-select--border ${
                                formik.errors.education &&
                                formik.errors.education[index] &&
                                formik.errors.education[index].from
                                  ? 'error'
                                  : ''
                              }`}
                              name={`education[${index}].from`}
                              value={
                                years.filter(
                                  (option) => option.value === formik.values.education[index].from,
                                )[0]
                              }
                              onChange={(e) =>
                                formik.setFieldValue(`education[${index}].from`, e.value)
                              }
                            />
                          </div>
                          <div className="period-wrap">
                            {translate('About.to')}
                            <Select
                              options={years}
                              placeholder="рік"
                              classNamePrefix="react-select"
                              className={`react-select react-select--border ${
                                formik.errors.education &&
                                formik.errors.education[index] &&
                                formik.errors.education[index].to
                                  ? 'error'
                                  : ''
                              }`}
                              name={`education[${index}].to`}
                              value={
                                years.filter(
                                  (option) => option.value === formik.values.education[index].to,
                                )[0]
                              }
                              onChange={(e) =>
                                formik.setFieldValue(`education[${index}].to`, e.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          />

          <div className="exp-content__row">
            <div className="exp-content__row__left" />
            <div className="exp-content__row__right">
              <button type="button" className="btn btn-primary" onClick={handleShow}>
                Зберегти
              </button>

              <button type="button" className="btn btn-outline-primary" onClick={addRow}>
                {translate('addMore')}
              </button>
              {formik.values.education.length > 0 && (
                <button type="button" className="btn btn-danger" onClick={removeRow}>
                  {translate('delete')}
                </button>
              )}
            </div>
          </div>
        </FormikProvider>

        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <div className="custom-modal-big custom-modal-big--admin">
              <button type="button" className="custom-modal__close">
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#modalClose`} />
                </svg>
              </button>
              <div className="not-auth__title">Ви дійсно хочете внести зміни?</div>
              <div className="not-auth__btn">
                <button
                  type="submit"
                  className="btn btn-primary btn--new"
                  onClick={() => {
                    formik.handleSubmit();
                    handleClose();
                  }}
                >
                  Так
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary btn--new"
                  onClick={() => {
                    formik.handleReset();
                    handleClose();
                  }}
                >
                  Ні
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </form>
    </>
  );
};

export default Education;
