import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import * as Yup from 'yup';

const Step2 = ({ setValue }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const currentProject = useSelector((state) => state.projectReducer.currentProject);

  const handleSubmit = async ({ description }) => {
    setValue('description', description);
    navigate('/publication/step3/');
  };

  const step2Schema = Yup.object().shape({
    description: Yup.string().min(50, translate('Valid.min50')).required(translate('Valid.req')),
  });

  const formik = useFormik({
    initialValues: {
      description: currentProject.description,
    },
    validationSchema: step2Schema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <form className="prpb__wrap" onSubmit={formik.handleSubmit}>
      <div className="prpb__name">{translate('Publication.des')}</div>
      <div className="prpb__body">
        <div className="prpb__label">{translate('Publication.subDes')}</div>
        <ul className="prbp__ul">
          <li>{translate('Publication.subDes1')}</li>
          <li>{translate('Publication.subDes2')}</li>
          <li>{translate('Publication.subDes3')}</li>
        </ul>

        <div className="prpb__textarea">
          <textarea
            defaultValue={formik.values.description}
            maxLength="5000"
            minLength="50"
            name="description"
            placeholder={translate('Publication.desPlaceholder')}
            id="prpb-textarea"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.description && formik.errors.description && (
            <div className="prpb__error">
              <div className="prpb__error__item">{formik.errors.description}</div>
            </div>
          )}

          <div className="prpb__textarea__counter">
            <span id="prpb-textarea-count">{formik.values.description.length}</span>/5000{' '}
            {translate('Publication.symbol')} ({translate('Publication.minimum')} 50)
          </div>
        </div>
      </div>
      <div className="prpb__footer">
        <button className="btn btn-primary" type="submit">
          {translate('next')}
        </button>
        <Link to="/publication/" className="btn btn-outline-primary">
          {translate('cancel')}
        </Link>
      </div>
    </form>
  );
};

Step2.propTypes = {
  setValue: PropTypes.func.isRequired,
};

export default Step2;
