import { useTranslation } from 'src/hooks';

import Description from './description';
import PersonalInfo from './personal-info';
import PhotoBlock from './photo-block';

const CompAbout = () => {
  const { translate } = useTranslation();
  return (
    <>
      <PhotoBlock />

      <div className="exp-content__title">
        <h3>{translate('About.subtitleComp')}</h3>
      </div>

      <PersonalInfo />
      <Description />
    </>
  );
};

export default CompAbout;
