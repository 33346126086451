import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const Education = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const education = useSelector((state) => state.authReducer.user.expert.education);
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);
  const [isSub, setIsSub] = useState(false);

  const years = [];
  for (let i = 1970; i <= new Date().getFullYear(); i += 1) {
    years.push({ value: i, label: i });
  }

  const educationSchema = Yup.object().shape({
    education: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(translate('Valid.req')),
        from: Yup.string().required(translate('Valid.req')),
        to: Yup.string().required(translate('Valid.req')),
      }),
    ),
  });

  const handleSubmit = (data) => {
    dispatch(authActions.education(data)).then((res) => {
      if (res.payload.status === 200) {
        setIsSub(true);
        setTimeout(() => {
          setIsSub(false);
        }, 5000);

        if (firstSignUp) {
          window.scrollTo({
            top: document.getElementById('langs').offsetTop - 80,
          });
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      education,
    },
    validationSchema: educationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const addRow = () => {
    formik.setFieldValue('education', [
      ...formik.values.education,
      { id: uuidv4(), name: '', from: '', to: '' },
    ]);
  };

  const removeRow = () => {
    const length = formik.values.education.length - 1;

    formik.setFieldValue(
      'education',
      formik.values.education.filter((item, index) => index !== length),
    );
  };

  return (
    <>
      <div className="exp-content__title" id="education">
        <h3>{translate('About.educate')}</h3>
      </div>
      <form id="educationForm1" className="exp-content__wrap" onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <FieldArray
            name="education"
            render={() => (
              <div>
                {formik.values.education.map((item, index) => (
                  <div className="expirience-block expirience-block--education" key={item.id}>
                    <div className="exp-content__row">
                      <div className="exp-content__row__left">
                        <div className="label">
                          {translate('About.educatePlace')} <span>*</span>
                        </div>
                      </div>
                      <div className="exp-content__row__right">
                        <input
                          className={`input ${
                            formik.errors.education &&
                            formik.errors.education[index] &&
                            formik.errors.education[index].name
                              ? 'error'
                              : ''
                          }`}
                          type="text"
                          name={`education[${index}].name`}
                          value={formik.values.education[index].name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          maxLength={50}
                        />
                        {formik.errors.education &&
                          formik.errors.education[index] &&
                          formik.errors.education[index].name && (
                            <div className="error-text">
                              {formik.errors.education &&
                                formik.errors.education[index] &&
                                formik.errors.education[index].name}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="exp-content__row">
                      <div className="exp-content__row__left">
                        <div className="label">
                          {translate('About.period')} <span>*</span>
                        </div>
                      </div>
                      <div className="exp-content__row__right">
                        <div className="period">
                          <div className="period-wrap">
                            {translate('About.from')}
                            <Select
                              options={years}
                              placeholder={translate('About.year')}
                              classNamePrefix="react-select"
                              className={`react-select react-select--border ${
                                formik.errors.education &&
                                formik.errors.education[index] &&
                                formik.errors.education[index].from
                                  ? 'error'
                                  : ''
                              }`}
                              name={`education[${index}].from`}
                              value={
                                years.filter(
                                  (option) => option.value === formik.values.education[index].from,
                                )[0]
                              }
                              onChange={(e) =>
                                formik.setFieldValue(`education[${index}].from`, e.value)
                              }
                            />
                          </div>
                          <div className="period-wrap">
                            {translate('About.to')}
                            <Select
                              options={years}
                              placeholder={translate('About.year')}
                              classNamePrefix="react-select"
                              className={`react-select react-select--border ${
                                formik.errors.education &&
                                formik.errors.education[index] &&
                                formik.errors.education[index].to
                                  ? 'error'
                                  : ''
                              }`}
                              name={`education[${index}].to`}
                              value={
                                years.filter(
                                  (option) => option.value === formik.values.education[index].to,
                                )[0]
                              }
                              onChange={(e) =>
                                formik.setFieldValue(`education[${index}].to`, e.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          />

          <div className="exp-content__row">
            <div className="exp-content__row__left" />
            <div className="exp-content__row__right">
              <button type="submit" className="btn btn-primary">
                {firstSignUp ? translate('next') : translate('save')}
              </button>

              <button type="button" className="btn btn-outline-primary" onClick={addRow}>
                {translate('addMore')}
              </button>
              {formik.values.education.length > 0 && (
                <button type="button" className="btn btn-danger" onClick={removeRow}>
                  {translate('delete')}
                </button>
              )}
            </div>
          </div>
        </FormikProvider>

        {isSub && (
          <div className="exp-content__row">
            <div className="exp-content__row__left" />
            <div className="exp-content__row__right">
              <div
                className={`alert alert-success d-flex align-items-center mt-3 ${
                  firstSignUp ? 'fix' : ''
                }`}
                role="alert"
              >
                <svg
                  className="bi flex-shrink-0 me-2"
                  width="24"
                  height="24"
                  role="img"
                  aria-label="Success:"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
                <div>{translate('Contacts.success')}</div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default Education;
