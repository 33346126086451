import { PropTypes } from 'prop-types';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const Search = ({ searchText, handleSearch }) => {
  const { translate } = useTranslation();

  return (
    <div className="my-proekts-filter">
      <div className="my-proekts-filter__search">
        <input
          type="text"
          value={searchText}
          placeholder={translate('My-project.search')}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <button type="button">
          <svg width="17.5px" height="17.5px">
            <use xlinkHref={`${sprite}#search`} />
          </svg>
        </button>
        {searchText.length > 0 && (
          <button
            type="button"
            className="my-proekts-filter__clear"
            onClick={() => handleSearch('')}
          >
            <svg width="17.5px" height="17.5px">
              <use xlinkHref={`${sprite}#close`} />
            </svg>
          </button>
        )}
      </div>
      <div className="my-proekts-filter__wrap" />
    </div>
  );
};

Search.propTypes = {
  searchText: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default Search;
