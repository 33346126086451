import { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import {
  FavoriteProjectModal,
  FixLoader,
  Footer,
  Header,
  Loader,
  NotAuth,
  RejectProjectModal,
  StarRate,
  Subheader,
} from 'src/components';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

import {
  Detail,
  DetailOffer,
  Favorites,
  Filter,
  ProjectNav,
  Rejects,
  TopSearch,
} from './components';
import NotActiveModal from './components/not-active-modal';

const Projects = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const query = useQuery();
  const navigate = useNavigate();
  const refList = useRef();

  const authWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const projectWaiter = useSelector((state) => state.projectReducer.fixWaiter);
  const projects = useSelector((state) => state.projectReducer.projects);

  const projectsPag = useSelector((state) => state.projectReducer.projectsPag);
  const catWaiter = useSelector((state) => state.projectReducer.waiter);
  const waiter = useSelector((state) => state.projectReducer.getProjectsWaiter);
  const waiterMore = useSelector((state) => state.projectReducer.getProjectsMoreWaiter);

  const { search } = useLocation();
  const page = new URLSearchParams(search).get('page');
  const pageId = page || '1';

  // Категорії
  const { cLevel1Slug, cLevel2Slug, cLevel3Slug } = useParams();
  const isProjectPage = cLevel1Slug !== 'favorites' && cLevel1Slug !== 'rejects';

  const projectExpertCategories = useSelector(
    (state) => state.projectReducer.projectExpertCategories,
  );

  let defLevel1 = '';
  let defLevel2 = '';
  let defLevel3 = '';

  projectExpertCategories.forEach((item) => {
    if (item.slug === cLevel1Slug) {
      defLevel1 = String(item.id);
    }
    return false;
  });
  if (defLevel1) {
    projectExpertCategories
      .filter((item) => item.slug === cLevel1Slug)[0]
      .child.forEach((item2) => {
        if (item2.slug === cLevel2Slug) {
          defLevel2 = String(item2.id);
        }
        return false;
      });
  }
  if (defLevel2) {
    projectExpertCategories
      .filter((item) => item.slug === cLevel1Slug)[0]
      .child.filter((item2) => item2.slug === cLevel2Slug)[0]
      .child.forEach((item3) => {
        if (item3.slug === cLevel3Slug) {
          defLevel3 = String(item3.id);
        }
        return false;
      });
  }

  // query параметри
  const companyCategory = query.get('companyCategory') || '';
  const budget = query.getAll('budget[]') || [];

  // параметри фільтрації
  const [filter, setFilter] = useState({
    expertCategory: defLevel1,
    expertCategorySecond: defLevel2,
    expertCategoryThird: defLevel3,
    companyCategory: '',
    budget: [],
  });

  const queryName = query.get('name') || '';
  const [name, setName] = useState(queryName);

  useEffect(() => {
    setName(queryName);
  }, [queryName]);

  // Детальна сторінка
  const projectId = query.get('detail');
  const projectIdOffer = query.get('detailOffer');

  useEffect(() => {
    const data = {
      page: pageId,
      name,
      ...filter,
    };

    if (isProjectPage) {
      dispatch(projectActions.getProjects(data));
    }
  }, [pageId, filter, name, isProjectPage]);

  const handleSetFilter = (data) => {
    setFilter(data);

    if (refList.current) {
      window.scrollTo({
        top: refList.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
  };

  const showMore = () => {
    const data = {
      page: projectsPag.current_page + 1,
      name,
      ...filter,
    };

    dispatch(projectActions.getProjectsMore(data));
  };

  const handleChangePage = (nextPage) => {
    let params = '';
    if (companyCategory) {
      params = `${companyCategory ? `companyCategory=${companyCategory}&` : ''}`;
    }
    if (budget.length) {
      budget.forEach((item) => {
        params += `budget[]=${item}&`;
      });
    }
    if (name) {
      params += `name=${name}&`;
    }
    navigate(`${window.location.pathname}?${params}page=${nextPage.selected + 1}`);
    if (refList.current) {
      window.scrollTo({
        top: refList.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
  };

  const handleSearch = (data) => {
    setName(data);
    let params = '';
    if (data) {
      params += `name=${data}&`;
    }

    navigate(`${window.location.pathname}?${params}`);

    if (refList.current) {
      window.scrollTo({
        top: refList.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
  };

  // reject and favorite
  const [handleRejectModal, setHandleRejectModal] = useState(0);
  const [isReject, setIsReject] = useState(false);
  const [rejectModalXY, setRejectModalXY] = useState({ pageX: 0, pageY: 0 });

  const [handleFavoriteModal, setHandleFavoriteModal] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteModalXY, setFavoriteModalXY] = useState({ pageX: 0, pageY: 0 });

  const handleReject = (id) => {
    dispatch(projectActions.projectReject({ project: id }));
  };

  const handleFavorite = (id) => {
    dispatch(projectActions.projectFavorite({ project: id }));
  };

  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const [notAuth, setNotAuth] = useState(false);
  const showNotAuth = () => setNotAuth(true);
  const hideNotAuth = () => setNotAuth(false);

  const ifActive = !!useSelector(
    (state) =>
      state.authReducer.user.expert && state.authReducer.user.expert.profile_status.code !== 'NA',
  );
  const [notActive, setNotActive] = useState(false);
  const showNotActive = () => setNotActive(true);
  const hideNotActive = () => setNotActive(false);

  return (
    <>
      {authWaiter && <FixLoader />}
      {projectWaiter && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            <TopSearch handleSearch={handleSearch} SearchName={name} />

            <div className="proekt">
              <Filter setFilter={handleSetFilter} filter={filter} />
              {ifAuth && (projectId || projectIdOffer) ? (
                <>
                  {projectId ? (
                    <Detail handleReject={handleReject} handleFavorite={handleFavorite} />
                  ) : (
                    <DetailOffer />
                  )}
                </>
              ) : (
                <div className="proekt__right" ref={refList}>
                  <div className="proekt__right__newTitle">{translate('Projects.catalog')}</div>

                  <div className="proekt__list proekt__list__new">
                    <ProjectNav />
                    {catWaiter || waiter ? (
                      <Loader />
                    ) : (
                      <>
                        {cLevel1Slug === 'favorites' && <Favorites />}
                        {cLevel1Slug === 'rejects' && <Rejects />}
                        {isProjectPage && (
                          <>
                            <div className="proekt__content">
                              {projects.length > 0 ? (
                                projects.map((item) => (
                                  <div
                                    className={`proekt__list__item ${
                                      item.hot ? 'proekt__list__item--hot' : ''
                                    }`}
                                    key={item.id}
                                  >
                                    <div className="proekt__content__top">
                                      <div className="proekt__content__left">
                                        <div className="proekt__content__person proekt__content__person--list">
                                          <Link
                                            to={`/employers/${item.company.slug}`}
                                            className="proekt__content__avatar"
                                            style={{
                                              backgroundImage: `url(${
                                                item.company.avatar || avatarDefaultComp
                                              })`,
                                            }}
                                          >
                                            {' '}
                                          </Link>
                                          <div className="proekt__content__person__right">
                                            <Link
                                              to={
                                                ifAuth
                                                  ? `/projects?detail=${item.id}`
                                                  : '/not-auth/'
                                              }
                                              className="proekt__content__person__name"
                                            >
                                              {item.name}
                                              {item.pinned && (
                                                <svg width="32" height="32" viewBox="0 0 32 32">
                                                  <use xlinkHref={`${sprite}#pinExpert`} />
                                                </svg>
                                              )}
                                            </Link>
                                            <div className="proekt__tags">
                                              {item.premium && (
                                                <div className="proekt__tags__item proekt__tags__item--premium">
                                                  <svg width="22" height="32">
                                                    <use xlinkHref={`${sprite}#premium`} />
                                                  </svg>
                                                  Premium
                                                </div>
                                              )}
                                              {!item.hidden && (
                                                <div className="proekt__tags__item">
                                                  <span className="tags-point">
                                                    {item.budget} {translate('Projects.pay')}
                                                  </span>
                                                </div>
                                              )}

                                              {item.businessCategories.map((cat) => (
                                                <div className="proekt__tags__item" key={cat.id}>
                                                  <span className="tags-point">{cat.name}</span>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="proekt__rating">
                                          <StarRate rate={item.company.avgReviewsRate} />

                                          <div className="proekt__rating__count">
                                            {item.company.reviewsCount} {translate('reviews')}
                                          </div>
                                        </div>
                                        <div className="proekt__location">
                                          <svg width="14" height="20">
                                            <use xlinkHref={`${sprite}#location-current`} />
                                          </svg>
                                          {item.company.country && item.company.country.name}
                                          {item.company.city && `, ${item.company.city.name}`}
                                        </div>
                                      </div>
                                      <div className="proekt__content__right">
                                        <div className="proekt__actios">
                                          {item.hot && (
                                            <div className="hot-project">
                                              <svg width="32" height="32">
                                                <use xlinkHref={`${sprite}#hotProject`} />
                                              </svg>
                                            </div>
                                          )}

                                          <button
                                            type="button"
                                            className={item.rejected ? 'active' : ''}
                                            onClick={(e) => {
                                              if (ifAuth) {
                                                setRejectModalXY({
                                                  pageX: e.pageX,
                                                  pageY: e.pageY,
                                                });
                                                setIsReject(item.rejected);
                                                setHandleRejectModal(item.id);
                                              } else {
                                                showNotAuth();
                                              }
                                            }}
                                          >
                                            <svg width="13.2px" height="13.2px">
                                              <use xlinkHref={`${sprite}#dislike`} />
                                            </svg>
                                            <svg
                                              width="13.2px"
                                              height="13.2px"
                                              className="active-svg"
                                            >
                                              <use xlinkHref={`${sprite}#dislike-active`} />
                                            </svg>
                                          </button>

                                          <button
                                            type="button"
                                            className={item.favorite ? 'active' : ''}
                                            onClick={(e) => {
                                              if (ifAuth) {
                                                setFavoriteModalXY({
                                                  pageX: e.pageX,
                                                  pageY: e.pageY,
                                                });
                                                setIsFavorite(item.favorite);
                                                setHandleFavoriteModal(item.id);
                                              } else {
                                                showNotAuth();
                                              }
                                            }}
                                          >
                                            <svg width="13.2px" height="13.2px">
                                              <use xlinkHref={`${sprite}#like`} />
                                            </svg>
                                            <svg
                                              width="13.2px"
                                              height="13.2px"
                                              className="active-svg"
                                            >
                                              <use xlinkHref={`${sprite}#like-active`} />
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="proekt__content__text">{item.description}</div>
                                    <div className="proekt__content__tags">
                                      {item.expertCategories &&
                                        item.expertCategories.map((expCat) => (
                                          <div
                                            className="proekt__content__tags__item"
                                            key={expCat.id}
                                          >
                                            {expCat.name}
                                          </div>
                                        ))}
                                    </div>

                                    <div className="proekt__content__bot__new">
                                      <Link
                                        to={ifAuth ? `/projects?detail=${item.id}` : '/not-auth/'}
                                        className="proekt__chat"
                                      >
                                        {translate('Projects.detail')}
                                        <svg width="15" height="10">
                                          <use xlinkHref={`${sprite}#new-arr`} />
                                        </svg>
                                      </Link>
                                      {item.isCandidate || item.isInvited ? (
                                        <button type="button" className="btn btn-primary" disabled>
                                          {item.isCandidate
                                            ? translate('Projects.joinComp')
                                            : translate('Projects.joinComp2')}
                                        </button>
                                      ) : (
                                        <>
                                          {ifActive ? (
                                            <Link
                                              type="button"
                                              className="btn btn-primary"
                                              to={
                                                ifAuth
                                                  ? `/projects?detailOffer=${item.id}`
                                                  : '/not-auth/'
                                              }
                                            >
                                              {translate('Projects.join')}
                                            </Link>
                                          ) : (
                                            <>
                                              {!ifAuth ? (
                                                <Link
                                                  to={
                                                    ifAuth
                                                      ? `/projects?detail=${item.id}`
                                                      : '/not-auth/'
                                                  }
                                                  className="btn btn-primary"
                                                >
                                                  {translate('Projects.join')}
                                                </Link>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                  onClick={showNotActive}
                                                >
                                                  {translate('Projects.join')}
                                                </button>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="mt-5 mb-5">{translate('Projects.empty')}</div>
                              )}
                            </div>
                            {waiterMore ? (
                              <div className="proekt__more">
                                <Loader />
                              </div>
                            ) : (
                              <>
                                {projectsPag.last_page > 1 && (
                                  <>
                                    {projectsPag.current_page !== projectsPag.last_page && (
                                      <div className="proekt__more">
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => showMore()}
                                        >
                                          {translate('Projects.more')}
                                        </button>
                                      </div>
                                    )}

                                    <div className="proekt__pagination">
                                      <ReactPaginate
                                        breakLabel="....."
                                        onPageChange={(nextPage) => handleChangePage(nextPage)}
                                        pageCount={projectsPag.last_page}
                                        forcePage={projectsPag.current_page - 1}
                                        activeClassName="active"
                                        pageRangeDisplayed="2"
                                        marginPagesDisplayed="1"
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
        <Footer />
      </div>

      {handleRejectModal !== 0 && (
        <RejectProjectModal
          handleRejectModal={handleRejectModal}
          rejectModalXY={rejectModalXY}
          setHandleRejectModal={setHandleRejectModal}
          dispatch={handleReject}
          isReject={isReject}
        />
      )}

      {handleFavoriteModal !== 0 && (
        <FavoriteProjectModal
          handleFavoriteModal={handleFavoriteModal}
          favoriteModalXY={favoriteModalXY}
          setHandleFavoriteModal={setHandleFavoriteModal}
          dispatch={handleFavorite}
          isFavorite={isFavorite}
        />
      )}
      {notActive && <NotActiveModal show={notActive} handleClose={hideNotActive} />}
      {notAuth && <NotAuth show={notAuth} handleClose={hideNotAuth} />}
    </>
  );
};

export default Projects;
