import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';

const Alerts = ({ handleClose }) => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.authReducer.user.role);
  const emailNotifications = useSelector((state) =>
    role === 1
      ? state.authReducer.user.expert.emailNotifications
      : state.authReducer.user.company.emailNotifications,
  );

  const handleSubmit = async (values) => {
    if (role === 1) {
      dispatch(authActions.notificationSettingsExpert(values));
    } else {
      dispatch(authActions.notificationSettingsCompany(values));
    }
  };

  const formik = useFormik({
    initialValues: {
      email_notifications: emailNotifications,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="sett-modal-body">
        <div className="sett-modal__title">{translate('sett.alertsTitle')}</div>
        <label className="sett-modal__checkbox" htmlFor="mch1">
          <input
            type="checkbox"
            id="mch1"
            name="email_notifications"
            checked={formik.values.email_notifications}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="sett-modal__checkbox__check">
            <svg width="18" height="13">
              <use xlinkHref={`${sprite}#modalCheckbox`} />
            </svg>
          </div>
          <span className="paid-modal__checkbox__text">{translate('sett.alertsLabel')}</span>
        </label>
      </div>
      <div className="exp-modal-footer">
        <div className="upload-wrap__btns">
          <button type="submit" className="btn btn-primary">
            {translate('save')}
          </button>
          <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
            {translate('cancel')}
          </button>
        </div>
      </div>
    </form>
  );
};

Alerts.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default Alerts;
