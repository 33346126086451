import { PropTypes } from 'prop-types';
import { forwardRef } from 'react';

const CustomButton = forwardRef(({ value, onClick }, ref) => (
  <button
    type="button"
    className={`datapiker form-select ${value ? 'selected' : ''}`}
    onClick={onClick}
    ref={ref}
  >
    {value || 'Дата'}
  </button>
));

const Filter = ({ setStatus }) => (
  <div>
    <form className="admin_search mt-3 d-flex justify-content-end">
      <div className="admin-filter">
        <select name="status" className="form-select" onChange={(e) => setStatus(e.target.value)}>
          <option value="">Статус</option>
          <option value="1">Опубілкований</option>
          <option value="2">Прийом заявок</option>
          <option value="3">Затвердження умов</option>
          <option value="4">Виконання проекту</option>
          <option value="5">Перевірка роботи замовником</option>
          <option value="6">Оплата</option>
          <option value="7">Відгуки</option>
        </select>
      </div>
    </form>
  </div>
);
CustomButton.propTypes = null;
Filter.propTypes = {
  setStatus: PropTypes.func.isRequired,
};

export default Filter;
