import './style.scss';

import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const Banned = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/');
  };

  return (
    <Modal show onHide={handleClose}>
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" className="proekt-modal__close" onClick={handleClose}>
            <svg className="icon" width="20px" height="20px">
              <use xlinkHref={`${sprite}#close`} />
            </svg>
          </button>
          <div className="ban__title">{translate('translate.title')}</div>
          <div className="ban__text">
            {translate('translate.text')} <a href="/">{translate('translate.btn')}</a>
          </div>
          <div className="ban__btn">
            <Link to="/" className="btn btn-primary btn--new">
              {translate('back')}
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Banned;
