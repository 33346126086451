import Business from './business';
import Education from './education';
import Langs from './langs';
import Portfolio from './portfolio';
import Resume from './resume';
import Reviews from './reviews';
import Service from './service';
import Spec from './spec';

const Info = () => (
  <div className="exp-profile__tabs__content active">
    <Service />
    <Spec />
    <Business />
    <Education />
    <Langs />
    <Resume />
    <Portfolio />
    <Reviews />
  </div>
);

export default Info;
