import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const Delete = ({ handleClose, show, handleDelete }) => {
  const { translate } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose} className="invite">
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" onClick={handleClose} className="custom-modal__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#modalClose`} />
            </svg>
          </button>

          <div className="custom-modal__title custom-modal__title--public">
            {translate('My-project.deleteModal')}
          </div>
          <div className="custom-modal__subt custom-modal__subt--public">
            {translate('My-project.deleteText')}
            <button
              className="support-btn-link"
              type="button"
              onClick={() => window.HelpCrunch('openChat')}
            >
              {translate('My-project.deletLink')}
            </button>
          </div>
          <div className="custom-modal__btns">
            <button type="button" className="btn btn-primary" onClick={handleDelete}>
              {translate('My-project.deleteBtn')}
            </button>

            <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
              {translate('cancel')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

Delete.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default Delete;
