import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const getHeaders = () => {
  const lang = localStorage.getItem('language').replace(/"/g, '') || 'ua';

  const headers = {
    headers: {
      'Accept-Language': `${lang}`,
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  };

  return headers;
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const getNewData = (data) => {
  const date = new Date(data);

  const time = date.toLocaleTimeString([], {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${date.toISOString().slice(0, 10)} - ${time}`;
};
