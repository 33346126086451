import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const MessInHeader = () => {
  const { translate } = useTranslation();
  const messageCount = useSelector((state) => state.chatReducer.messageCount);

  return (
    <Link to="/messages/" className="header__right__btn have-mess tooltips-wrap">
      {messageCount > 0 ? (
        <svg width="20px" height="20px">
          <use xlinkHref={`${sprite}#mess-active`} />
        </svg>
      ) : (
        <svg width="20px" height="20px">
          <use xlinkHref={`${sprite}#mess`} />
        </svg>
      )}

      {messageCount > 0 && (
        <div className="have-mess__text">+{messageCount < 99 ? messageCount : 99}</div>
      )}
      {messageCount > 0 && (
        <div className="tooltips">
          {messageCount} {translate('Header.newMess')}
        </div>
      )}
    </Link>
  );
};
export default MessInHeader;
