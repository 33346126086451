import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { v4 as uuidv4 } from 'uuid';

const Langs = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const langs = useSelector((state) => state.authReducer.langs);
  const langsLevels = useSelector((state) => state.authReducer.langsLevels);
  const langsLevelsOptions = langsLevels.map((item) => ({ value: item.id, label: item.name }));
  const languages = useSelector((state) =>
    state.authReducer.user.expert.languages
      ? state.authReducer.user.expert.languages.map((item) => ({
          level: item.levelId,
          id: item.id,
          name: item.languageId,
        }))
      : [],
  );
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);
  const { waiter } = useSelector((state) => state.authReducer);
  const [isSub, setIsSub] = useState(false);

  useEffect(() => {
    if (langs.length === 0) {
      dispatch(authActions.getLangs());
    }
    if (langsLevels.length === 0) {
      dispatch(authActions.getLangsLevels());
    }
  }, []);

  const handleSubmit = (data) => {
    dispatch(authActions.setLangs(data)).then((res) => {
      if (res.payload.status === 200) {
        setIsSub(true);
        setTimeout(() => {
          setIsSub(false);
        }, 5000);

        if (firstSignUp) {
          window.scrollTo({
            top: document.getElementById('uslugi').offsetTop - 80,
          });
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      languages,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const addRow = () => {
    formik.setFieldValue('languages', [
      ...formik.values.languages,
      { id: uuidv4(), name: 0, level: 1 },
    ]);
  };

  const removeRow = () => {
    const length = formik.values.languages.length - 1;

    formik.setFieldValue(
      'languages',
      formik.values.languages.filter((item, index) => index !== length),
    );
  };

  const getOptions = (itemIndex) => {
    const arrId = formik.values.languages
      .filter((item, index) => index !== itemIndex)
      .map((item) => Number(item.name));
    let langsFiltred = langs.filter((item) => !arrId.includes(item.id));

    langsFiltred = langsFiltred.map((item) => ({ value: item.id, label: item.name }));
    return langsFiltred;
  };

  return (
    <>
      <div className="exp-content__title" id="langs">
        <h3>{translate('About.langs')}</h3>
      </div>
      {waiter ? (
        <Loader />
      ) : (
        <form id="langForm1" className="exp-content__wrap" onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <div>
              <FieldArray
                name="languages"
                render={() => (
                  <>
                    {formik.values.languages &&
                      formik.values.languages.map((item, index) => (
                        <div className="expirience-block expirience-block--lang" key={item.id}>
                          <div className="exp-content__row">
                            <div className="exp-content__row__left">
                              <div className="label">
                                {translate('About.langName')} <span>*</span>
                              </div>
                            </div>
                            <div className="exp-content__row__right">
                              <Select
                                classNamePrefix="react-select"
                                className="react-select react-select--border"
                                options={getOptions(index)}
                                name={`languages[${index}].name`}
                                onChange={(e) =>
                                  formik.setFieldValue(`languages[${index}].name`, e.value)
                                }
                                placeholder={translate('About.chooseLangPl')}
                                value={
                                  getOptions(index).filter(
                                    (option) =>
                                      option.value === formik.values.languages[index].name,
                                  )[0]
                                }
                                required
                              />
                            </div>
                          </div>

                          <div className="exp-content__row">
                            <div className="exp-content__row__left">
                              <div className="label">{translate('About.langLevel')}</div>
                            </div>
                            <div className="exp-content__row__right">
                              <div className="period period--langs">
                                <div className="period-wrap">
                                  <Select
                                    classNamePrefix="react-select"
                                    className="react-select react-select--border"
                                    options={langsLevelsOptions}
                                    name={`languages[${index}].level`}
                                    onChange={(e) =>
                                      formik.setFieldValue(`languages[${index}].level`, e.value)
                                    }
                                    placeholder={translate('About.chooseLangPl')}
                                    value={
                                      langsLevelsOptions.filter(
                                        (option) =>
                                          option.value === formik.values.languages[index].level,
                                      )[0]
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              />
            </div>
            <div className="exp-content__row">
              <div className="exp-content__row__left" />
              <div className="exp-content__row__right">
                <button type="submit" className="btn btn-primary">
                  {firstSignUp ? translate('next') : translate('save')}
                </button>

                <button type="button" className="btn btn-outline-primary" onClick={() => addRow()}>
                  {translate('addMore')}
                </button>
                {formik.values.languages.length > 0 && (
                  <button type="button" className="btn btn-danger" onClick={() => removeRow()}>
                    {translate('delete')}
                  </button>
                )}
              </div>
            </div>
          </FormikProvider>
          {isSub && (
            <div className="exp-content__row">
              <div className="exp-content__row__left" />
              <div className="exp-content__row__right">
                <div
                  className={`alert alert-success d-flex align-items-center mt-3 ${
                    firstSignUp ? 'fix' : ''
                  }`}
                  role="alert"
                >
                  <svg
                    className="bi flex-shrink-0 me-2"
                    width="24"
                    height="24"
                    role="img"
                    aria-label="Success:"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                  <div>{translate('Contacts.success')}</div>
                </div>
              </div>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default Langs;
