import 'react-tooltip/dist/react-tooltip.css';

import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import sprite from 'src/assets/img/sprite.svg';
import { FixLoader, Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions, paymentActions } from 'src/store/actions';

const ExpertPaid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const waiter = useSelector((state) => state.paymentReducer.waiter);
  const waiterServices = useSelector((state) => state.paymentReducer.waiterServices);
  const fixWaiter = useSelector((state) => state.paymentReducer.fixWaiter);
  const authWaiter = useSelector((state) => state.authReducer.waiter);
  const packagesArr = useSelector((state) => state.paymentReducer.packages);
  const packages = packagesArr.map((item) => ({ ...item, check: false }));
  const servicesArr = useSelector((state) => state.paymentReducer.services);
  const services = servicesArr.map((item) => ({
    ...item,
    check: false,
    count: item.multiply ? 1 : 0,
  }));

  const refBalance = useSelector((state) => state.authReducer.user?.referralBalance);
  const referralBalance = useSelector((state) => state.authReducer.user?.referralBalance);

  useEffect(() => {
    dispatch(authActions.getProfileRefresh());
    dispatch(paymentActions.getServices());
    dispatch(paymentActions.getPackages());
  }, []);

  const handleSubmit = (values) => {
    const data = {
      packages: values.packages
        .filter((item) => item.check)
        .map((item) => ({ id: item.packageId })),
      services: values.services
        .filter((item) => item.check)
        .map((item) => {
          if (item.count) {
            return { id: item.serviceId, count: item.count };
          }
          return { id: item.serviceId };
        }),
      amount: values.total,
      referralBalance: values.refBalance ? values.refBalanceUse : 0,
    };

    dispatch(paymentActions.postPayments(data)).then((res) => {
      if (res.payload.status === 200) {
        if (data.amount === 0) {
          navigate('/payment/status?status=2&refMess=Успішно оплачено за бонуси');
        } else {
          window.open(res.payload.data.checkout_url, '_self');
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      packages,
      services,
      total: 0,
      refBalance: false,
      refBalanceUse: 0,
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    let total = 0;
    let refBalanceUse = 0;
    if (formik.values.packages) {
      formik.values.packages.map((item) => {
        if (item.check) {
          total += item.price;
        }
        return false;
      });
    }

    formik.values.services.map((item) => {
      if (item.check) {
        if (item.count) {
          total += item.price * item.count;
        } else {
          total += item.price;
        }
      }
      return false;
    });

    if (formik.values.refBalance) {
      total -= refBalance;

      if (total <= 0) {
        refBalanceUse = refBalance + total;
        total = 0;
      } else {
        refBalanceUse = refBalance;
      }
    }

    formik.setFieldValue('refBalanceUse', refBalanceUse);
    formik.setFieldValue('total', total);
  }, [formik.values.services, formik.values.packages, formik.values.refBalance]);

  const handlePlus = (item, index) => {
    const max = item.max || 0;
    const position = item.position || 0;
    formik.setFieldValue(`services[${index}].check`, true);
    if (max && item.count < max) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    } else if (position && item.count < position) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    } else if (!max && !position) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    }
  };

  const handleMinus = (item, index) => {
    formik.setFieldValue(`services[${index}].check`, true);
    if (item.count > 1) {
      formik.setFieldValue(`services[${index}].count`, item.count - 1);
    }
  };

  return (
    <>
      {fixWaiter && <FixLoader />}
      {waiter || waiterServices || authWaiter ? (
        <Loader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          {formik.values.packages.length > 0 && (
            <div className="tarif">
              {formik.values.packages.map((item, index) => (
                <div
                  className={`tarif__item ${item.marker ? `tarif__item--popular` : ''}`}
                  key={item.packageId}
                >
                  {item.marker && <div className="tarif__popular">{item.marker}</div>}

                  <div className="tarif__name">
                    {item.title}
                    <button
                      type="button"
                      data-tooltip-id={`tarif-tooltip${item.packageId}`}
                      data-tooltip-content={item.tooltip}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use xlinkHref={`${sprite}#paymentLabel`} />
                      </svg>
                    </button>
                    <Tooltip id={`tarif-tooltip${item.packageId}`} />
                  </div>
                  <div
                    className="tarif__des"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <div className="tarif__price">
                    <div className="tarif__price__numb">{item.price} грн</div>
                    <div className="tarif__price__type">{item.per}</div>
                  </div>
                  <div className="tarif__btn">
                    {item.check ? (
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => formik.setFieldValue(`packages[${index}].check`, false)}
                      >
                        {translate('cancel')}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => formik.setFieldValue(`packages[${index}].check`, true)}
                      >
                        {translate('choose')}
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="payment__dop">{translate('Payment.dop')}</div>
          {formik.values.services.length > 0 && (
            <div className="payment__list">
              {formik.values.services.length &&
                formik.values.services.map((item, index) => (
                  <div
                    className={`payment__row ${item.disabled ? 'disabled' : ''}`}
                    key={item.serviceId}
                  >
                    <label
                      className="form-check form-check--payment"
                      htmlFor={`flexCheckDefault${item.serviceId}`}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={`services[${index}].check`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id={`flexCheckDefault${item.serviceId}`}
                        checked={item.check}
                      />
                    </label>
                    <div className="payment__name">
                      {item.title}
                      <button
                        type="button"
                        data-tooltip-id={`payment-tooltip${item.serviceId}`}
                        data-tooltip-content={item.tooltip}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref={`${sprite}#paymentLabel`} />
                        </svg>
                      </button>
                      <Tooltip id={`payment-tooltip${item.serviceId}`} />

                      <span className="payment__name__position">
                        {item.positionText}
                        {item.position}
                      </span>
                    </div>

                    <div className="payment__count">
                      {item.multiply && (
                        <>
                          <div className="payment__count__flex">
                            <button
                              type="button"
                              className="minus"
                              onClick={() => handleMinus(item, index)}
                            >
                              -
                            </button>
                            <div className="payment__count__numb">{item.count}</div>
                            <button
                              type="button"
                              className="plus"
                              onClick={() => handlePlus(item, index)}
                            >
                              +
                            </button>
                          </div>
                          <div className="payment__count__text">{item.choosingLine}</div>
                        </>
                      )}
                    </div>

                    <div className="payment__price">
                      <div className="payment__price__left">{item.priceFormatted}</div>
                      <div className="payment__price__right">{item.per}</div>
                    </div>
                  </div>
                ))}
            </div>
          )}

          <div className="payment__total">
            <div className="payment__total__right">
              <div className="payment__total__title">
                {translate('Payment.total')} {formik.values.total}
                {translate('grn')}
              </div>
              {referralBalance > 0 ? (
                <div className="payment__total__des">
                  <label className="form-check form-check--payment" htmlFor="refBalance">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="refBalance"
                      name="refBalance"
                      onChange={formik.handleChange}
                      defaultChecked={false}
                    />
                  </label>
                  {translate('Payment.useRef')} {referralBalance} {translate('grn')}
                </div>
              ) : (
                <div className="payment__total__des">
                  {translate('Payment.refBalance')} {referralBalance} {translate('grn')}
                </div>
              )}
            </div>
          </div>
          <div className="payment__total__bot">
            <button type="submit" className="btn btn-primary">
              {translate('Payment.payBtn')}
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default ExpertPaid;
