import { useSelector } from 'react-redux';

const More = () => {
  const project = useSelector((state) => state.adminReducer.project);

  return (
    <>
      <div className="exp-content__title">
        <h3>Інша інформація</h3>
      </div>
      <div className="exp-content__wrap">
        {project.executor && (
          <div className="exp-content__row">
            <div className="exp-content__row__left">
              <div className="label">Виконавець</div>
            </div>
            <div className="exp-content__row__right">
              <div className="mt-2">
                <b>
                  <a href={`/expert/${project.executor.slug}`} target="_blank" rel="noreferrer">
                    {project.executor.profileName}
                  </a>
                </b>
              </div>
            </div>
          </div>
        )}

        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Мови</div>
          </div>
          <div className="exp-content__row__right">
            <div className="proekt__content__tags">
              {project.languages &&
                project.languages.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Категорії бізнесу</div>
          </div>
          <div className="exp-content__row__right">
            <div className="proekt__content__tags">
              {project.businessCategories &&
                project.businessCategories.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Категорії експерта 1 рівень</div>
          </div>
          <div className="exp-content__row__right">
            <div className="proekt__content__tags">
              {project.expertCategories &&
                project.expertCategories.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Категорії експерта 2 рівень</div>
          </div>
          <div className="exp-content__row__right">
            <div className="proekt__content__tags">
              {project.expertTwoCategories &&
                project.expertTwoCategories.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Категорії експерта 3 рівень</div>
          </div>
          <div className="exp-content__row__right">
            <div className="proekt__content__tags">
              {project.expertThreeCategories &&
                project.expertThreeCategories.map((item) => (
                  <div className="proekt__content__tags__item" key={item.id}>
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
        {project.projectType && (
          <div className="exp-content__row">
            <div className="exp-content__row__left">
              <div className="label">Тип проекту</div>
            </div>
            <div className="exp-content__row__right">
              <div className="mt-2">
                <b>{project.projectType.data.title}</b>
              </div>
            </div>
          </div>
        )}
        {project.projectDuration && (
          <div className="exp-content__row">
            <div className="exp-content__row__left">
              <div className="label">Тривалість проекту</div>
            </div>
            <div className="exp-content__row__right">
              <div className="mt-2">
                <b>{project.projectDuration.text}</b>
              </div>
            </div>
          </div>
        )}

        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Перегляди</div>
          </div>
          <div className="exp-content__row__right">
            <div className="mt-2">
              <b>{project.views}</b>
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Дата створення</div>
          </div>
          <div className="exp-content__row__right">
            <div className="mt-2">
              <b>{project.date}</b>
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Дата виконання</div>
          </div>
          <div className="exp-content__row__right">
            <div className="mt-2">
              <b>{project.completedDate}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default More;
