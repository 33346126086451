import { useSelector } from 'react-redux';

const Contacts = () => {
  const { phone, email } = useSelector((state) => state.adminReducer.user);
  const {
    country,
    city,
    website,
    facebook,
    linkedin,
    whatsapp,
    viber,
    facebookMessenger,
    telegram,
  } = useSelector((state) => state.adminReducer.user.company);

  return (
    <>
      <div className="exp-content__top">Контактна інформація</div>
      <div className="exp-content__wrap">
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Номер телефону</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={phone || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Email</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={email || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Країна</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={country.name || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Місто</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={city.name || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">WhatsApp</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={whatsapp || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Telegram</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={telegram || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Viber </div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={viber || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label"> Facebook Messenger</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={facebookMessenger || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Website</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={website || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Linkedin</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={linkedin || ''} />
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">facebook</div>
          </div>
          <div className="exp-content__row__right">
            <input type="text" className="input " readOnly value={facebook || ''} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Contacts;
