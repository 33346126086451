import './style.scss';

import { Route, Routes } from 'react-router-dom';

import { CreateNew, EditPage, IndexPage } from './components';

const Baza = () => (
  <div className="admin-wrap">
    <div className="wrap">
      <Routes>
        <>
          <Route path="/" element={<IndexPage />} />
          <Route path="/create/*" element={<CreateNew />} />
          <Route path="/edit/:id" element={<EditPage />} />
        </>
      </Routes>
    </div>
  </div>
);

export default Baza;
