import 'react-tooltip/dist/react-tooltip.css';

import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import avatarDefault from 'src/assets/img/proekt/avatar-default.jpg';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';
import { availableProjectRequestsMinus } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

import NotActiveModal from './not-active-modal';

const DetailOffer = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const projectId = query.get('detailOffer');
  const project = useSelector((state) => state.projectReducer.projectDetail);
  const detailRef = useRef();
  const costPerProject = useSelector(
    (state) => state.authReducer.user.expert && state.authReducer.user.expert.costPerProject,
  );
  const countHiddenBids = useSelector(
    (state) => state.authReducer.user.expert && state.authReducer.user.expert.countHiddenBids,
  );
  const [showDet, setShowDet] = useState(true);
  const [hideBidErr, setHideBidErr] = useState(false);
  const userCanSend = useSelector(
    (state) =>
      state.authReducer.user.expert && state.authReducer.user.expert.availableProjectRequests > 0,
  );
  const ifActive = !!useSelector(
    (state) =>
      state.authReducer.user.expert && state.authReducer.user.expert.profile_status.code !== 'NA',
  );

  const [showJoin, setShowJoin] = useState(false);
  const handleCloseJoin = () => setShowJoin(false);
  const handleShowJoin = () => setShowJoin(true);

  useEffect(() => {
    if (detailRef.current) {
      window.scrollTo({
        top: detailRef.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
    dispatch(projectActions.getProjectsById(projectId));
  }, [projectId]);

  const schema = Yup.object().shape({
    bid: Yup.number().min(1).required(translate('Valid.req')),
    completionTime: Yup.number().min(1).max(365).required(translate('Valid.req')),
    transmittalLetter:
      project && project.transmittalLetter ? Yup.string().required() : Yup.string(),
    answers: Yup.array().of(
      Yup.object({
        text: Yup.string().required(translate('Valid.req')),
      }),
    ),
  });

  const [err, setErr] = useState(false);
  const formikHandleInvite = async (values) => {
    setErr(false);
    const res = await dispatch(projectActions.projectSend(values));

    if (res.payload.status === 200) {
      dispatch(availableProjectRequestsMinus());
      handleShowJoin();
      dispatch(projectActions.getProjectsById(projectId));

      window.scrollTo({
        top: 80,
        behavior: 'instant',
      });
    } else {
      handleShowJoin();
      setErr(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      project: projectId,
      bid: (!project?.hidden && project?.budget) || '0',
      hideBid: false,
      completionTime: '1',
      transmittalLetter: '',
      answers:
        project?.screeningQuestions.map((item) => ({ id: item.id, text: '', name: item.text })) ||
        [],
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => formikHandleInvite(values),
  });

  const handleHideBid = () => {
    if (countHiddenBids > 0) {
      formik.setFieldValue('hideBid', !formik.values.hideBid);
    } else {
      setHideBidErr(true);
    }
  };

  const [notActive, setNotActive] = useState(false);
  const showNotActive = () => setNotActive(true);
  const hideNotActive = () => setNotActive(false);

  return (
    <>
      <div className="proekt__right proekt__right--detail" ref={detailRef}>
        {!project ? (
          <Loader />
        ) : (
          <div className="proekt__detail proekt__detail--new">
            <div className="proekt__detail__top">
              <div className="exp-content__top">{translate('projectsOffer.title')}</div>
            </div>
            <div className="proekt__content">
              <div className="proekt__content__top">
                <div className="proekt__content__left">
                  <div className="proekt__content__title">{project.name}</div>
                  <div className="proekt__content__status">{translate('Projects.detailComp')}</div>
                  <div className="proekt__content__person">
                    <Link
                      to={`/employers/${project.company.slug}`}
                      className="proekt__content__avatar"
                      style={{ backgroundImage: `url(${project.company.avatar || avatarDefault}` }}
                    />
                    <Link
                      to={`/employers/${project.company.slug}`}
                      className="proekt__content__person__name"
                    >
                      {project.company.companyName}
                    </Link>
                    <div className="proekt__location">
                      <svg width="14" height="20">
                        <use xlinkHref={`${sprite}#location`} />
                      </svg>
                      {project.company.country && project.company.country.name}
                      {project.company.city && `, ${project.company.city.name}`}
                    </div>
                  </div>
                  <div className="proekt__tags">
                    {!project.hidden && (
                      <div className="proekt__tags__item">
                        <span className="tags-point">
                          {project.budget} {translate('projectsOffer.grnPr')}
                        </span>
                      </div>
                    )}

                    <div className="proekt__tags__item">
                      <span className="tags-point">{project.projectType.data.title}</span>
                    </div>
                    {project.businessCategories.map((item) => (
                      <div className="proekt__tags__item" key={item.id}>
                        <span className="tags-point">{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="proekt__content__right">
                  <div className="proekt__stat">
                    <div className="proekt__stat__status">{translate('projectsOffer.public')}</div>
                    {project.created && (
                      <div className="proekt__stat__item">
                        <svg width="20" height="20">
                          <use xlinkHref={`${sprite}#timeSvg`} />
                        </svg>

                        {project.created}
                      </div>
                    )}
                    {project.views && (
                      <div className="proekt__stat__item">
                        <svg width="22" height="16">
                          <use xlinkHref={`${sprite}#viewSvg`} />
                        </svg>
                        {project.views}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="proekt-detail__level1">
                {project.expertCategories.map((item) => (
                  <div className="item" key={item.id}>
                    {item.name}
                  </div>
                ))}
                {project.expertTwoCategories.map((item) => (
                  <div className="item" key={item.id}>
                    {item.name}
                  </div>
                ))}
              </div>
              <div className="proekt-detail__level2">
                {project.expertThreeCategories.map((item) => (
                  <div className="item" key={item.id}>
                    {item.name}
                  </div>
                ))}
              </div>
              <div className="proekt__content__text">{project.description}</div>
              <div className="proekt-detail__lang">
                {project.languages.map((item) => (
                  <div className="item" key={item.id}>
                    {item.name}
                  </div>
                ))}
              </div>
              {project.screeningQuestions.length > 0 && (
                <div className="new-check">
                  <svg width="14" height="20">
                    <use xlinkHref={`${sprite}#new-check`} />
                  </svg>
                  {translate('Projects.haveAsk')}
                </div>
              )}

              {project.transmittalLetter && (
                <div className="new-check">
                  <svg width="14" height="20">
                    <use xlinkHref={`${sprite}#new-check`} />
                  </svg>
                  {translate('Projects.haveLatter')}
                </div>
              )}
              <div className="proekt-detail__duration">
                <div className="duration1">{translate('Projects.duration')}</div>
                <div className="duration2">{project.projectDuration.text}</div>
              </div>

              <form onSubmit={formik.handleSubmit}>
                {!project.isCandidate && (
                  <div className="proekt__moreInfo">
                    {!showDet ? (
                      <button
                        type="button"
                        className="proekt__moreInfo__btn"
                        onClick={() => setShowDet(true)}
                      >
                        {translate('show')}
                        <svg width="15" height="10">
                          <use xlinkHref={`${sprite}#new-arr`} />
                        </svg>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="proekt__moreInfo__btn"
                        onClick={() => setShowDet(false)}
                      >
                        {translate('hideMore')}
                        <svg width="15" height="10">
                          <use xlinkHref={`${sprite}#new-arr`} />
                        </svg>
                      </button>
                    )}

                    {showDet && (
                      <div className="proekt__body">
                        <div className="proekt__body__title">
                          {translate('projectsOffer.conditions')}
                        </div>
                        <div className="proekt__body__text">
                          {translate('projectsOffer.stavka')}
                        </div>
                        <div className="proekt__body__stavka__wrap">
                          <div className="proekt__body__stavka">
                            <div className="left">
                              <div className="light-text mb-3">
                                {costPerProject && (
                                  <>
                                    {translate('projectsOffer.stavkaInProfile')} {costPerProject}{' '}
                                    {translate('projectsOffer.perProject')}
                                  </>
                                )}
                              </div>
                              <div className="bold-text">
                                {translate('projectsOffer.stavkaPerProject')}
                              </div>
                              <div className="light-text mb-2">
                                {translate('projectsOffer.youStavka')}
                              </div>
                              {hideBidErr ? (
                                <>
                                  <label className="form-check" htmlFor="stavka">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value="1"
                                      id="stavka"
                                      name="hideBid"
                                      checked={formik.values.hideBid || false}
                                      onChange={handleHideBid}
                                    />
                                    <div className="form-check-label err">
                                      {translate('projectsOffer.hideStavka')}
                                    </div>
                                    <button
                                      type="button"
                                      data-tooltip-id="my-tooltip1"
                                      data-tooltip-content="Підказка"
                                    >
                                      <svg width="24" height="24">
                                        <use xlinkHref={`${sprite}#stavkaErr`} />
                                      </svg>
                                    </button>
                                    <Tooltip id="my-tooltip1" />
                                  </label>
                                  <div className="form-check__err-text">
                                    {translate('Projects.hideOfferErr')}{' '}
                                    <Link to="/payment/paid">
                                      {translate('Projects.hideOfferErrLink')}
                                    </Link>
                                  </div>
                                </>
                              ) : (
                                <label className="form-check" htmlFor="stavka">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="1"
                                    id="stavka"
                                    name="hideBid"
                                    checked={formik.values.hideBid || false}
                                    onChange={handleHideBid}
                                  />
                                  <div className="form-check-label">
                                    {translate('projectsOffer.hideStavka')}
                                  </div>
                                  <button
                                    type="button"
                                    data-tooltip-id="my-tooltip2"
                                    data-tooltip-content="Підказка"
                                  >
                                    <svg width="24" height="24">
                                      <use xlinkHref={`${sprite}#stavka`} />
                                    </svg>
                                  </button>
                                  <Tooltip id="my-tooltip2" />
                                </label>
                              )}
                            </div>
                            <div className="right">
                              <div className="light-text mb-3">
                                {project.hidden ? (
                                  <>
                                    {translate('projectsOffer.companyBudget')}{' '}
                                    {translate('projectsOffer.projectHide')}
                                  </>
                                ) : (
                                  <>
                                    {translate('projectsOffer.companyBudget')} {project?.budget}{' '}
                                    {translate('projectsOffer.perProject')}
                                  </>
                                )}
                              </div>
                              <div className="stavla__input">
                                <input
                                  type="text"
                                  name="bid"
                                  pattern="\d*"
                                  value={formik.values.bid}
                                  className={`input ${
                                    formik.errors.bid && formik.touched.bid ? `error` : ''
                                  }`}
                                  maxLength="10"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                <div className="stavka__label">{translate('grn')}</div>
                                <div className="stavka__for__proekt">
                                  / {translate('projectsOffer.project')}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="proekt__body__stavka">
                            <div className="left">
                              <div className="bold-text">{translate('projectsOffer.srok')}</div>
                              <div className="light-text mb-2">
                                {translate('projectsOffer.srokText')}
                              </div>
                            </div>
                            <div className="right">
                              <div className="stavla__input">
                                <input
                                  type="number"
                                  defaultValue={formik.values.completionTime}
                                  className={`input ${
                                    formik.errors.completionTime && formik.touched.completionTime
                                      ? 'error'
                                      : ''
                                  }`}
                                  name="completionTime"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                <div className="stavka__label">
                                  {translate('projectsOffer.days')}
                                </div>
                                <div className="stavka__for__proekt">
                                  / {translate('projectsOffer.project')}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="proekt__body__stavka">
                            <div className="stavka__textarea">
                              <div className="bold-text mb-2">
                                {translate('projectsOffer.letter')}
                              </div>
                              <textarea
                                name="transmittalLetter"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={
                                  formik.errors.transmittalLetter &&
                                  formik.touched.transmittalLetter &&
                                  'error'
                                }
                                maxLength={500}
                              />
                              <div className="prpb__textarea__counter">
                                <span id="prpb-textarea-count">
                                  {formik.values.transmittalLetter.length}
                                </span>
                                /500 {translate('Publication.symbol')}
                              </div>
                              {formik.errors.transmittalLetter &&
                                formik.touched.transmittalLetter && (
                                  <div className="invalid-feedback">
                                    <svg width="24" height="24" viewBox="0 0 24 24">
                                      <use xlinkHref={`${sprite}#projectError`} />
                                    </svg>
                                    {translate('projectsOffer.letterError')}
                                  </div>
                                )}
                            </div>
                          </div>

                          {formik.values.answers.length > 0 && (
                            <div className="proekt__body__stavka">
                              <div className="stavka__textarea">
                                <div className="bold-text mb-2">
                                  {translate('projectsOffer.answerTitle')}
                                </div>
                                {formik.values.answers.map((item, index) => (
                                  <div className="mb-2" key={item.id}>
                                    <div className="light-text mb-2">{item.name}</div>
                                    <textarea
                                      name={`answers[${index}].text`}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      placeholder={translate('projectsOffer.answerPlaceholder')}
                                      className={
                                        formik.errors.answers &&
                                        formik.errors.answers[index] &&
                                        formik.errors.answers[index].text &&
                                        formik.touched.answers &&
                                        formik.touched.answers[index] &&
                                        formik.touched.answers[index].text &&
                                        'error'
                                      }
                                      maxLength={265}
                                    />
                                    <div className="prpb__textarea__counter">
                                      <span id="prpb-textarea-count">
                                        {formik.values.answers[index].text.length}
                                      </span>
                                      /265 {translate('Publication.symbol')}
                                    </div>
                                    {formik.errors.answers &&
                                      formik.errors.answers[index] &&
                                      formik.errors.answers[index].text &&
                                      formik.touched.answers &&
                                      formik.touched.answers[index] &&
                                      formik.touched.answers[index].text && (
                                        <div className="invalid-feedback">
                                          <svg width="24" height="24" viewBox="0 0 24 24">
                                            <use xlinkHref={`${sprite}#projectError`} />
                                          </svg>
                                          {formik.errors?.answers[index].text}
                                        </div>
                                      )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="proekt__more">
                  {project.isCandidate || project.isInvited ? (
                    <button type="button" className="btn btn-primary" disabled>
                      {project.isCandidate
                        ? translate('Projects.joinComp')
                        : translate('Projects.joinComp2')}
                    </button>
                  ) : (
                    <>
                      {!ifActive || !userCanSend ? (
                        <>
                          {ifActive ? (
                            <button type="button" className="btn btn-primary" disabled>
                              {translate('Projects.detailJoinBtn')}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={showNotActive}
                            >
                              {translate('Projects.join')}
                            </button>
                          )}
                        </>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          {translate('Projects.detailJoinBtn')}
                        </button>
                      )}
                    </>
                  )}

                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => navigate('/projects')}
                  >
                    {translate('Projects.backTolist')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Modal show={showJoin} onHide={handleCloseJoin}>
        <Modal.Body>
          <div className="proekt-modal">
            <button type="button" className="proekt-modal__close" onClick={handleCloseJoin}>
              <svg className="icon" width="20px" height="20px">
                <use xlinkHref={`${sprite}#close`} />
              </svg>
            </button>
            <div className="proekt-modal__title">
              {err ? translate('Projects.joinModalErr') : translate('Projects.joinModal')}
            </div>
            <div className="proekt-modal__titleSub">
              {err
                ? translate('Projects.joinModalSubTextErr')
                : translate('Projects.joinModalSubText')}
            </div>
            <div className="proekt-modal__row">
              {translate('Projects.joinModalText2')}{' '}
              <Link to="/my-offers/">{translate('Projects.joinModalLink')}</Link>
            </div>
            <div className="proekt-modal__bot">
              <Link to="/my-offers/" className="btn btn-primary">
                {translate('Projects.joinModalBtn1')}
              </Link>
              <Link to="/projects/" className="btn btn-outline-primary" onClick={handleCloseJoin}>
                {translate('Projects.joinModalBtn2')}
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {notActive && <NotActiveModal show={notActive} handleClose={hideNotActive} />}
    </>
  );
};

export default DetailOffer;
