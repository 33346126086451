import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions, customActions } from 'src/store/actions';
import { v4 as uuidv4 } from 'uuid';

const Sfera = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { waiter } = useSelector((state) => state.customReducer);
  const { categories } = useSelector((state) => state.authReducer.user.company);
  const { сompanySfera } = useSelector((state) => state.customReducer);
  const [isSub, setIsSub] = useState(false);
  const maxBusinessCategories = useSelector(
    (state) => state.authReducer.user.company.maxBusinessCategories,
  );
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);

  useEffect(() => {
    if (сompanySfera.length === 0) {
      dispatch(customActions.getСompanyСategories());
    }
  }, []);

  const handleSubmit = async (values) => {
    await dispatch(authActions.editCompanyCategories(values));
    setIsSub(true);
    if (firstSignUp) {
      navigate('/profile');
    } else {
      setTimeout(() => {
        setIsSub(false);
      }, 5000);
    }
  };

  const formik = useFormik({
    initialValues: {
      companyCategories: categories,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const companyCategoriesFiltered = formik.values.companyCategories.filter(
    (item) => item.id !== '0',
  );

  const getOptions = (index) => {
    let arrId = formik.values.companyCategories.map((item) => Number(item.id));
    arrId = arrId.filter((item, itemIndex) => itemIndex !== index);
    let сompanySferaFiltered = сompanySfera.filter((option) => !arrId.includes(option.id));
    сompanySferaFiltered = сompanySferaFiltered.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    return сompanySferaFiltered;
  };

  return (
    <div className="exp-content__edit">
      <div className="exp-content__top">{translate('Sfera.title')}</div>
      <form className="exp-content__margin" onSubmit={formik.handleSubmit}>
        <div className="exp-content__title">
          <h3>{translate('Sfera.subTitle')}</h3>
        </div>
        {waiter ? (
          <Loader />
        ) : (
          <FormikProvider value={formik}>
            <div className="exp-content__wrap exp-content__wrap--sfera">
              <FieldArray
                name="companyCategories"
                render={(helpers) => (
                  <>
                    <div className="exp-content__wrap--sfera__selects">
                      {formik.values.companyCategories.map((item, index) => (
                        <Select
                          classNamePrefix="react-select"
                          className="react-select"
                          options={getOptions(index)}
                          name={`companyCategories[${index}].id`}
                          key={uuidv4()}
                          onChange={(e) =>
                            formik.setFieldValue(`companyCategories[${index}].id`, e.value)
                          }
                          placeholder={translate('Sfera.zeroOption')}
                          value={
                            getOptions(index).filter(
                              (option) =>
                                option.value === formik.values.companyCategories[index].id,
                            )[0]
                          }
                        />
                      ))}
                    </div>
                    {formik.values.companyCategories.length < maxBusinessCategories && (
                      <div className="button--add-employer button--add-employer--sfera">
                        <button type="button" onClick={() => helpers.push({ id: 0 })}>
                          {translate('Sfera.addMore')}
                        </button>
                      </div>
                    )}
                  </>
                )}
              />

              {formik.values.companyCategories.length === maxBusinessCategories && (
                <div className="sfera__limit">
                  {translate('Sfera.ask1')} {maxBusinessCategories} {translate('Sfera.ask2')}{' '}
                  <Link to="/payment/paid/">{translate('Sfera.link')}</Link>
                </div>
              )}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!companyCategoriesFiltered.length}
              >
                {translate('save')}
              </button>
              {isSub && (
                <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
                  <svg
                    className="bi flex-shrink-0 me-2"
                    width="24"
                    height="24"
                    role="img"
                    aria-label="Success:"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                  <div>{translate('Contacts.success')}</div>
                </div>
              )}
            </div>
          </FormikProvider>
        )}
      </form>
    </div>
  );
};

export default Sfera;
