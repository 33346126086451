import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import avatarDefault from 'src/assets/img/proekt/avatar-default.jpg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { chatActions } from 'src/store/actions';

const ListCompany = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const messageRefresh = useSelector((state) => state.chatReducer.messageRefresh);
  const waiter = useSelector((state) => state.chatReducer.waiter);
  const chatList = useSelector((state) => state.chatReducer.chatList);
  const [limit, setLimit] = useState(5);
  const chatListFiltred = chatList.filter((item, index) => index < limit);
  const [afterRender, setAfterRender] = useState(false);

  useEffect(() => {
    if (afterRender) {
      dispatch(chatActions.getChatListCompanyRefresh());
    }
    setAfterRender(true);
  }, [messageRefresh]);

  return (
    <>
      <div className="exp-content__top">{translate('Chat.title')}</div>
      <div className="proekt__content__app">
        {chatListFiltred.length > 0 ? (
          <div className="chatList">
            {chatListFiltred.map((item) => (
              <div className="chatList__item" key={item.id}>
                <div className="chatList__head">
                  <Link
                    to={`/messages/${item.id}?expert=${item.expert.id}&project=${item.project.id}`}
                    className="chatList__avatar"
                    style={{
                      backgroundImage: `url(${item.expert.avatar || avatarDefault})`,
                    }}
                  >
                    {item.unreadByCompanyCount > 0 && (
                      <>
                        {item.unreadByCompanyCount > 9 ? (
                          <div className="chatList__unread">9+</div>
                        ) : (
                          <div className="chatList__unread">{item.unreadByCompanyCount}</div>
                        )}
                      </>
                    )}
                  </Link>
                  <Link
                    to={`/messages/${item.id}?expert=${item.expert.id}&project=${item.project.id}`}
                    className="chatList__name"
                  >
                    {item.expert.profileName} ({item.project.name})
                  </Link>
                </div>
                <div className="chatList__body">
                  <div className="chatList__btn">
                    <Link
                      to={`/messages/${item.id}?expert=${item.expert.id}&project=${item.project.id}`}
                      type="button"
                      className="btn btn-primary"
                    >
                      {translate('Chat.openChat')}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>{!waiter && <div className="chatList__empty">{translate('Chat.empty')}</div>}</>
        )}

        {waiter ? (
          <div className="chatList__empty">
            <Loader />
          </div>
        ) : (
          <>
            {chatList.length > 0 && chatList.length > limit && (
              <div className="chatList__more">
                <button
                  type="button"
                  onClick={() => setLimit(limit + 5)}
                  className="btn btn-primary"
                >
                  {translate('showMore')}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ListCompany;
