import { createAsyncThunk } from '@reduxjs/toolkit';
import { expertProjectService } from 'src/services';

export const getMyRequests = createAsyncThunk(
  'custom/getMyRequests',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyRequests(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyRequestsMore = createAsyncThunk(
  'custom/getMyRequestsMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyRequests(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyRejectedRequests = createAsyncThunk(
  'custom/getMyRejectedRequests',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyRejectedRequests(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyRejectedRequestsMore = createAsyncThunk(
  'custom/getMyRejectedRequestsMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyRejectedRequests(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyinvitations = createAsyncThunk(
  'custom/getMyinvitations',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyinvitations(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyinvitationsMore = createAsyncThunk(
  'custom/getMyinvitationsMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyinvitations(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyRejectedinvitations = createAsyncThunk(
  'custom/getMyRejectedinvitations',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyRejectedinvitations(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyRejectedinvitationsMore = createAsyncThunk(
  'custom/getMyRejectedinvitationsMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyRejectedinvitations(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyCurrent = createAsyncThunk(
  'custom/getMyCurrent',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyCurrent(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyCurrentMore = createAsyncThunk(
  'custom/getMyCurrentMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyCurrent(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const startProject = createAsyncThunk(
  'custom/startProject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.startProject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const declineProjectInCurrent = createAsyncThunk(
  'custom/declineProjectInCurrent',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.declineProjectInCurrent(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const reviewProject = createAsyncThunk(
  'custom/reviewProject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.reviewProject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendDataForPayment = createAsyncThunk(
  'custom/sendDataForPayment',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.sendDataForPayment(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const changeDataForPayment = createAsyncThunk(
  'custom/changeDataForPayment',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.changeDataForPayment(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyCurrentComplete = createAsyncThunk(
  'custom/getMyCurrentComplete',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyCurrentComplete(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getMyCurrentCompleteMore = createAsyncThunk(
  'custom/getMyCurrentCompleteMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.getMyCurrentComplete(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const reviewProjectInfo = createAsyncThunk(
  'custom/reviewProjectInfo',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.reviewProjectInfo(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const reviewProjectExpert = createAsyncThunk(
  'custom/reviewProjectExpert',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.reviewProjectExpert(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const declineProject = createAsyncThunk(
  'custom/declineProject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.declineProject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const acceptProject = createAsyncThunk(
  'custom/acceptProject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await expertProjectService.acceptProject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const acceptPay = createAsyncThunk('custom/acceptPay', async (data, { rejectWithValue }) => {
  try {
    const result = await expertProjectService.acceptPay(data);

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
