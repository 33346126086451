import './style.scss';

import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

import Alerts from './alerts';
import Password from './password';

const SettingModal = ({ show, handleClose }) => {
  const { translate } = useTranslation();
  const [tab, setTab] = useState(true);
  return (
    <Modal show={show} onHide={handleClose} className="sett-modal">
      <Modal.Body>
        <div className="exp-modal">
          <div className="modal-header">
            <h5 className="modal-title">{translate('sett.title')}</h5>
            <button type="button" onClick={handleClose} className="sett-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalCloseWhite`} />
              </svg>
            </button>
          </div>
          <div className="sett-modal__nav">
            <button
              type="button"
              className={`btn ${tab ? 'active' : ''}`}
              onClick={() => setTab(!tab)}
            >
              {translate('sett.tab1')}
            </button>
            <button
              type="button"
              className={`btn ${!tab ? 'active' : ''}`}
              onClick={() => setTab(!tab)}
            >
              {translate('sett.tab2')}
            </button>
          </div>
          {tab ? <Password handleClose={handleClose} /> : <Alerts handleClose={handleClose} />}
        </div>
      </Modal.Body>
    </Modal>
  );
};

SettingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SettingModal;
