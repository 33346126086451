import { api } from 'src/api';
import { getHeaders } from 'src/helpers';

export const getServices = async () => {
  const result = await api.get(`/profile/expert/services`, getHeaders());

  return result;
};

export const getServicesCompany = async (id) => {
  const result = await api.get(
    `/profile/company/services${id !== '0' ? `?project=${id}` : ''}`,
    getHeaders(),
  );

  return result;
};

export const businessСategoriesService = async () => {
  const result = await api.get(`/profile/company/business-categories-service`, getHeaders());

  return result;
};

export const getPaidServices = async () => {
  const result = await api.get(`/profile/expert/paid-services`, getHeaders());

  return result;
};

export const getPaidServicesCompany = async () => {
  const result = await api.get(`/profile/company/paid-services`, getHeaders());

  return result;
};

export const getPaidServicesCompanyPositionOfProject = async () => {
  const result = await api.get(`/profile/company/position-of-project`, getHeaders());

  return result;
};

export const paidBusinessCategories = async () => {
  const result = await api.get(`/profile/company/paid-business-categories`, getHeaders());

  return result;
};

export const getPackages = async () => {
  const result = await api.get(`/profile/expert/packages`, getHeaders());

  return result;
};

export const getPackagesCompany = async (id) => {
  const result = await api.get(
    `/profile/company/packages${id !== '0' ? `?project=${id}` : ''}`,
    getHeaders(),
  );

  return result;
};

export const getPaidPackages = async () => {
  const result = await api.get(`/profile/expert/paid-packages`, getHeaders());

  return result;
};

export const getPaidPackagesCompany = async () => {
  const result = await api.get(`/profile/company/paid-packages`, getHeaders());

  return result;
};

export const getReferralUrl = async () => {
  const result = await api.post(`/get-referral-url`, null, getHeaders());

  return result;
};

export const postPayments = async (data) => {
  const result = await api.post(`/profile/expert/payments`, data, getHeaders());

  return result;
};

export const postPaymentsCompany = async (data) => {
  const result = await api.post(`/profile/company/payments`, data, getHeaders());

  return result;
};

export const paymentsHistory = async (data) => {
  const result = await api.get(`/profile/company/payments/history`, getHeaders());

  return result;
};
