import { useSelector } from 'react-redux';
import coverDefault from 'src/assets/img/about/about-fon.png';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import { StarRate } from 'src/components';

const ProfileHead = () => {
  const profileCover = useSelector(
    (state) =>
      state.projectReducer.companyDetail && state.projectReducer.companyDetail.profileCover,
  );
  const avatar = useSelector(
    (state) => state.projectReducer.companyDetail && state.projectReducer.companyDetail.avatar,
  );
  const fullName = useSelector(
    (state) => state.projectReducer.companyDetail && state.projectReducer.companyDetail.companyName,
  );

  const country = useSelector(
    (state) => state.projectReducer.companyDetail && state.projectReducer.companyDetail.country,
  );
  const city = useSelector(
    (state) => state.projectReducer.companyDetail && state.projectReducer.companyDetail.city,
  );

  const avgReviewsRate = useSelector((state) =>
    state.projectReducer.companyDetail ? state.projectReducer.companyDetail.avgReviewsRate : 0,
  );

  return (
    <>
      <div
        className="exp-profile__top"
        style={{ backgroundImage: `url(${profileCover || coverDefault})` }}
      >
        <div
          className="exp-profile__avatar"
          style={{
            backgroundImage: `url(${avatar || avatarDefaultComp})`,
          }}
        />
      </div>

      <div className="exp-profile__info">
        <div className="exp-profile__col" />

        <div className="exp-profile__person">
          <div className="exp-profile__person__name">{fullName}</div>

          <div className="exp-profile__person__location">
            {city && `${city.name} , `} {country && country.name}
          </div>
          <div className="exp-profile__person__rate">
            <StarRate rate={avgReviewsRate} />
          </div>
        </div>

        <div className="exp-profile__col exp-profile__col--right" />
      </div>
    </>
  );
};
export default ProfileHead;
