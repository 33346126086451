import { NavLink, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'src/hooks';

import ProfileHead from '../profile-head';
import Done from './done';
import Info from './info';

const ExpertProfile = () => {
  const { translate } = useTranslation();

  return (
    <div className="exp-content">
      <div className="exp-profile">
        <ProfileHead />
        <div className="exp-profile__tabs">
          <div className="exp-profile__tabs__nav">
            <NavLink to="/profile/" end activeclassname="active">
              {translate('Profile.info')}
            </NavLink>
            <NavLink to="/profile/done/" activeclassname="active">
              {translate('Profile.done')}
            </NavLink>
          </div>
          <div className="exp-profile__tabs__wrap">
            <Routes>
              <Route path="/*" element={<Info />} />
              <Route path="/done" element={<Done />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertProfile;
