import { api } from 'src/api';
import { getHeaders } from 'src/helpers/';

export const getNotificationsExpert = async () => {
  const result = await api.get(`/profile/expert/notification/active`, getHeaders());

  return result;
};

export const getNotificationsCompany = async () => {
  const result = await api.get(`/profile/company/notification/active`, getHeaders());

  return result;
};

export const getReadNotificationsExpert = async () => {
  const result = await api.get(`/profile/expert/notification/read`, getHeaders());

  return result;
};

export const getReadNotificationsCompany = async () => {
  const result = await api.get(`/profile/company/notification/read`, getHeaders());

  return result;
};

export const readNotExpert = async (data) => {
  const result = await api.post(`/profile/expert/notification/read`, data, getHeaders());

  return result;
};

export const readNotCompany = async (data) => {
  const result = await api.post(`/profile/company/notification/read`, data, getHeaders());

  return result;
};

export const activeCountExpert = async () => {
  const result = await api.get(`/profile/expert/notification/active-count`, getHeaders());

  return result;
};

export const activeCountCompany = async () => {
  const result = await api.get(`/profile/company/notification/active-count`, getHeaders());

  return result;
};
