import { useFormik } from 'formik';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

const UpdatePhone = () => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.authReducer.errors);

  useEffect(() => () => dispatch(clearErrors()), []);

  const handleSubmit = async (values) => {
    const splitPhone = values.phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
    dispatch(authActions.phoneUpdate({ phone: splitPhone })).then((res) => {
      if (res.payload.status === 200) {
        navigate(`/auth/verify-phone/?phone=${values.phone}`);
      }
    });
  };
  const SignUpdateSchema = Yup.object().shape({
    phone: Yup.string()
      .test('len', translate('Valid.min12'), (phone) => {
        if (phone) {
          const phoneNumb = phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
          return phoneNumb.length >= 12;
        }
        return false;
      })
      .nullable(),
  });
  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: SignUpdateSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <div className="auth__wrap">
      <div className="container">
        <h1>{translate('Auth.verTitle')}</h1>
        <div className="auth__desc">{translate('Auth.changePhone')}</div>
        <form className="auth__form" id="signInForm" onSubmit={formik.handleSubmit}>
          <div className="auth__form__title">{translate('Auth.enterPhone')}</div>

          {error && <strong className="text-danger">{error}</strong>}

          <div className="input__row">
            <InputMask
              mask="+380-99-999-99-99"
              placeholder="+380"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phone"
              className={formik.errors.phone && formik.touched.phone && 'is-invalid'}
            />
            <div className="invalid-feedback">
              {formik.errors.phone && formik.touched.phone && formik.errors.phone}
            </div>
          </div>

          <button className="auth__submit" type="submit">
            {translate('change')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdatePhone;
