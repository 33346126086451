import { useFormik } from 'formik';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const InfoMain = () => {
  const dispatch = useDispatch();
  const { id, profileName, services, costPerHour, costPerProject, country, city } = useSelector(
    (state) => state.adminReducer.user.expert,
  );
  const userErrors = useSelector((state) => state.adminReducer.userErrors);

  const handleSubmit = (values) => {
    dispatch(adminActions.expertUpdateInfo(values));
  };

  const formik = useFormik({
    initialValues: {
      id,
      profileName: profileName || '',
      services: services || '',
      costPerHour: costPerHour || '',
      costPerProject: costPerProject || '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <form className="admin-info-main" onSubmit={formik.handleSubmit}>
      <div className="exp-content__title">
        <h3>Інформація про експерта</h3>
      </div>
      <div className="exp-content__wrap">
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Експерт id: {id}</div>
            <div className="label">
              {country.name}, {city.name}
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">
              Ім&apos;я профіля <span>*</span>
            </div>
          </div>
          <div className="exp-content__row__right">
            <input
              className={`input ${userErrors.profileName ? 'error' : ''}`}
              type="text"
              name="profileName"
              placeholder="Олександр Александров"
              maxLength="50"
              value={formik.values.profileName}
              required
              onChange={formik.handleChange}
            />
            {userErrors.profileName && <div className="error-text">{userErrors.profileName}</div>}
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Послуги, що надаються</div>
          </div>
          <div className="exp-content__row__right">
            <div className="textarea">
              <textarea
                value={formik.values.services}
                name="services"
                placeholder="Опишіть послуги, що надаються"
                className=""
                maxLength="190"
                onChange={formik.handleChange}
              />
              <div className="textarea__maxlength">190 символів максимум</div>
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Вартість послуг</div>
          </div>
          <div className="exp-content__row__right">
            <div className="from-to">
              <div className="from-to__line">
                <div className="from-to__col1 mr-8px" />
                <div className="from-to__col2 ml-3px">
                  <input
                    type="text"
                    className="input  "
                    name="costPerHour"
                    maxLength="10"
                    value={formik.values.costPerHour}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="from-to__col3">грн за годину</div>
              </div>
              <div className="from-to__line">
                <div className="from-to__col1 mr-8px">Від</div>
                <div className="from-to__col2">
                  <input
                    type="text"
                    className="input "
                    name="costPerProject"
                    maxLength="10"
                    value={formik.values.costPerProject}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="from-to__col3">грн за проєкт</div>
              </div>
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left" />
          <div className="exp-content__row__right">
            <button type="button" className="btn btn-primary" onClick={handleShow}>
              Зберегти
            </button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="not-auth__title">Ви дійсно хочете внести зміни?</div>
            <div className="not-auth__btn">
              <button
                type="submit"
                className="btn btn-primary btn--new"
                onClick={() => {
                  formik.handleSubmit();
                  handleClose();
                }}
              >
                Так
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={() => {
                  formik.handleReset();
                  handleClose();
                }}
              >
                Ні
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
};

export default InfoMain;
