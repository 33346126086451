import './style.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FixLoader, Footer, Header, Loader, Sidebar, Status, Subheader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { expertProjectActions } from 'src/store/actions';

import List from './list';

const MyOffers = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.expertProjectReducer.myOffersWaiter);
  const myOffersFixWaiter = useSelector((state) => state.expertProjectReducer.myOffersFixWaiter);

  const [tab, setTab] = useState(1);
  const { myRequestsPag, myRejectedRequestsPag, myInvitationsPag, myRejectedInvitationsPag } =
    useSelector((state) => state.expertProjectReducer);

  useEffect(() => {
    if (tab === 1) {
      dispatch(expertProjectActions.getMyRequests(1));
    } else if (tab === 2) {
      dispatch(expertProjectActions.getMyRejectedRequests(1));
    } else if (tab === 3) {
      dispatch(expertProjectActions.getMyinvitations(1));
    } else if (tab === 4) {
      dispatch(expertProjectActions.getMyRejectedinvitations(1));
    }
  }, [tab]);

  return (
    <>
      {myOffersFixWaiter && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            <Status />
            <div className="exp-content exp-content--company">
              <Sidebar />
              <div className="exp-content__blocks">
                <div className="exp-content__edit">
                  <div className="exp-content__top">{translate('MyOffers.title')}</div>

                  <div
                    className="proekt__tabs tabs-nav proekt__tabs--offers"
                    data-tabs="#zayv-tabs"
                  >
                    <ul>
                      <li>
                        <button
                          type="button"
                          className={`${tab === 1 ? 'active' : ''}`}
                          onClick={() => setTab(1)}
                        >
                          {translate('MyOffers.tab1')}{' '}
                          {myRequestsPag.total > 0 ? `(${myRequestsPag.total})` : ''}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className={`${tab === 2 ? 'active' : ''}`}
                          onClick={() => setTab(2)}
                        >
                          {translate('MyOffers.tab2')}{' '}
                          {myRejectedRequestsPag.total > 0
                            ? `(${myRejectedRequestsPag.total})`
                            : ''}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className={`${tab === 3 ? 'active' : ''}`}
                          onClick={() => setTab(3)}
                        >
                          {translate('MyOffers.tab3')}{' '}
                          {myInvitationsPag.total > 0 ? `(${myInvitationsPag.total})` : ''}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className={`${tab === 4 ? 'active' : ''}`}
                          onClick={() => setTab(4)}
                        >
                          {translate('MyOffers.tab4')}{' '}
                          {myRejectedInvitationsPag.total > 0
                            ? `(${myRejectedInvitationsPag.total})`
                            : ''}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="my-proekts-list proekt__list">
                    <div className="proekt__content">
                      {waiter ? <Loader /> : <List tab={tab} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MyOffers;
