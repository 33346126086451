import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useTranslation } from 'src/hooks';
import * as Yup from 'yup';

const Step3SubStep3 = ({ setValue }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const currentProject = useSelector((state) => state.projectReducer.currentProject);
  const [showSelect, setShowSelect] = useState(false);
  const langs = useSelector((state) => state.projectReducer.langs);

  const [chooseLangs] = useState(langs.filter((item, index) => index < 5));

  const [filteredLangs, setFilteredLangs] = useState(
    chooseLangs.filter((item) => !currentProject.languages.includes(item.id)),
  );

  const chooseLangsId = chooseLangs.map((item) => item.id);
  const langsList = langs
    .filter((item) => !chooseLangsId.includes(item.id))
    .map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const handleSubmit = async ({ languages }) => {
    setValue('languages', languages);
    navigate('/publication/step3/sub-step4/');
  };

  const formik = useFormik({
    initialValues: {
      languages: langs.filter((item) => currentProject.languages.includes(item.id)) || [],
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const addLanguages = (id) => {
    const formikLangs = formik.values.languages;
    const newLang = langs.filter((item) => item.id === id);

    const filtered = filteredLangs.filter((obj) => obj.id !== id);

    setFilteredLangs(filtered);

    if (formikLangs.length > 0) {
      if (!formikLangs.includes(newLang[0])) {
        formik.setFieldValue('languages', [...formikLangs, newLang[0]]);
      }
    } else {
      formik.setFieldValue('languages', newLang);
    }
  };

  const addLanguagesFromSelect = (lang) => {
    const formikLangs = formik.values.languages;
    const formikLangsId = formikLangs.map((item) => item.id);
    const newLang = [{ id: lang.value, name: lang.label }];

    if (formikLangs.length > 0) {
      if (!formikLangsId.includes(newLang[0].id)) {
        formik.setFieldValue('languages', [...formikLangs, newLang[0]]);
      }
    } else {
      formik.setFieldValue('languages', newLang);
    }
  };

  const removeLang = (id) => {
    const lang = chooseLangs.filter((item) => item.id === id);

    filteredLangs.push(...lang);
    setFilteredLangs(filteredLangs);

    const newFormikLangs = formik.values.languages.filter((item) => item.id !== id);

    formik.setFieldValue('languages', newFormikLangs);
  };

  return (
    <form className="prpb__wrap" onSubmit={formik.handleSubmit}>
      <div className="prpb__name">{translate('Publication.detail')}</div>
      <div className="prpb__body">
        <div className="prpb__label">{translate('Publication.langs')}</div>
        <div className="choose__lang">
          {formik.values.languages.length > 0 &&
            formik.values.languages.map((item) => (
              <div className="choose__lang__item" key={item.id}>
                {item.name}
                <button type="button" onClick={() => removeLang(item.id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="7"
                    viewBox="0 0 7 7"
                    fill="none"
                  >
                    <path
                      d="M5.792 1.00195L1 5.79395"
                      stroke="#2D9CDB"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.796 5.797L1 1"
                      stroke="#2D9CDB"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            ))}
        </div>

        <div className="propose__lang">
          {filteredLangs.length > 0 &&
            filteredLangs.map((item) => (
              <div
                className="propose__lang__item"
                key={item.id}
                tabIndex="0"
                role="button"
                onClick={() => addLanguages(item.id)}
                onKeyPress={() => addLanguages(item.id)}
              >
                + {item.name}
              </div>
            ))}
        </div>

        <div className={`prpb__add-lang ${showSelect ? 'active' : ''}`}>
          {showSelect ? (
            <button
              type="button"
              className="prpb__btn hide-lang"
              onClick={() => setShowSelect(false)}
            >
              {translate('Publication.langsHide')}
            </button>
          ) : (
            <button
              type="button"
              className="prpb__btn show-lang"
              onClick={() => setShowSelect(true)}
            >
              {translate('Publication.langsShow')}
            </button>
          )}
          <div className="prpb__add-lang__wrap">
            <div className="prpb__label">{translate('Publication.langsLabel')}</div>
            <Select
              options={langsList}
              classNamePrefix="react-select"
              className="react-select"
              placeholder={translate('Publication.langSearch')}
              onChange={(lang) => addLanguagesFromSelect(lang)}
            />
          </div>
        </div>
      </div>
      <div className="prpb__error">
        {formik.touched.languages && formik.errors.languages && (
          <div className="prpb__error__item">{formik.errors.languages}</div>
        )}
      </div>
      <div className="prpb__footer">
        <button className="btn btn-primary" type="submit">
          {translate('next')}
        </button>
        <Link className="btn btn-outline-primary" to="/publication/step3/sub-step2/">
          {translate('cancel')}
        </Link>
      </div>
    </form>
  );
};

Step3SubStep3.propTypes = {
  setValue: PropTypes.func.isRequired,
};

export default Step3SubStep3;
