import { api } from 'src/api';
import { getHeaders } from 'src/helpers/';

export const getExamCategories = async () => {
  const result = await api.get(`/expert-exam/categories`, getHeaders());

  return result;
};

export const getExamQuestions = async (id) => {
  const result = await api.get(`/expert-exam/questions?category=${id}`, getHeaders());

  return result;
};

export const sendAnswers = async (data) => {
  const result = await api.post(`/expert-exam/send-answers`, data, getHeaders());

  return result;
};

export const getResults = async () => {
  const result = await api.get(`/expert-exam/results`, getHeaders());

  return result;
};

export const getAnalyticalexam = async () => {
  const result = await api.get(`/analytical-exam/exam`, getHeaders());

  return result;
};

export const analyticalSendAnswer = async (data) => {
  const result = await api.post(`/analytical-exam/send-answers`, data, getHeaders());

  return result;
};
