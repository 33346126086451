import { createAsyncThunk } from '@reduxjs/toolkit';
import { customService } from 'src/services';

export const getDeleteReason = createAsyncThunk(
  'custom/getDeleteReason',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getDeleteReason();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getСompanyСategories = createAsyncThunk(
  'custom/getСompanyСategories',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getСompanyСategories();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDoneProject = createAsyncThunk(
  'custom/getDoneProject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getDoneProject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDoneProjectExpert = createAsyncThunk(
  'custom/getDoneProjectExpert',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getDoneProjectExpert(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDoneProjectMore = createAsyncThunk(
  'custom/getDoneProjectMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getDoneProject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDoneProjectExpertMore = createAsyncThunk(
  'custom/getDoneProjectExpertMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getDoneProjectExpert(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDoneProjectCompany = createAsyncThunk(
  'custom/getDoneProjectCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getDoneProjectCompany();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExpertPortfolio = createAsyncThunk(
  'custom/getExpertPortfolio',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getExpertPortfolio(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExpertPortfolioMore = createAsyncThunk(
  'custom/getExpertPortfolioMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getExpertPortfolio(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAllPortfolio = createAsyncThunk(
  'custom/getAllPortfolio',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getAllPortfolio();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deletePortFolio = createAsyncThunk(
  'custom/deletePortFolio',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.deletePortFolio(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const setPortfolio = createAsyncThunk(
  'user/setPortfolio',
  async ({ values, file }, { rejectWithValue }) => {
    try {
      const formData = new FormData();

      formData.append('document', file);
      formData.append('name', values.name);

      const result = await customService.setPortfolio(formData);
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editPortfolio = createAsyncThunk(
  'user/editPortfolio',
  async ({ values, file, portfolioId }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      if (file) {
        formData.append('document', file);
      }

      formData.append('name', values.name);

      const result = await customService.editPortfolio(formData, portfolioId);
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const knowledgeBase = createAsyncThunk(
  'custom/knowledgeBase',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.knowledgeBase(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const knowledgeBaseSearch = createAsyncThunk(
  'custom/knowledgeBaseSearch',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.knowledgeBaseSearch(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getRatingInfo = createAsyncThunk(
  'custom/getRatingInfo',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getRatingInfo();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getReviewsExpert = createAsyncThunk(
  'custom/getReviewsExpert',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getReviewsExpert(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getReviewsExpertMore = createAsyncThunk(
  'custom/getReviewsExpertMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getReviewsExpert(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getReviewsCompany = createAsyncThunk(
  'custom/getReviewsCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getReviewsCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getReviewsCompanyMore = createAsyncThunk(
  'custom/getReviewsCompanyMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getReviewsCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCountOnMainPage = createAsyncThunk(
  'custom/getCountOnMainPage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await customService.getCountOnMainPage(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
