import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';
import { setShowChooseExpertModal } from 'src/store/reducers/project.reducer';
import * as Yup from 'yup';

const ChooseModal = ({ show }) => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const [submitChoose, setSubmitChoose] = useState(false);
  const project = useSelector((state) => state.projectReducer.myProjectDetail);
  const expertCurrent = useSelector((state) => state.projectReducer.expertCurrent);
  const [disabled, setDisabled] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [changeCond, setChangeCond] = useState(false);

  const handleChooseExecutor = async () => {
    await dispatch(
      projectActions.chooseExecutor({ project: project.id, expert: expertCurrent.expert.id }),
    );
    setSubmitChoose(true);
  };

  const handleChangeCond = async (values) => {
    await dispatch(projectActions.proposeOtherConditions(values));
    setChangeCond(true);
    setSubmitChoose(true);
  };

  const schema = Yup.object().shape({
    bid: Yup.number().required(translate('Valid.req')),
    completionTime: Yup.number().required(translate('Valid.req')),
  });

  const formik = useFormik({
    initialValues: {
      project: project.id,
      requestId: expertCurrent.id,
      requestType: expertCurrent.isInvited ? 'invitation' : 'request',
      bid: '',
      completionTime: '',
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => handleChangeCond(values),
  });

  const handleChange = (val, name) => {
    const newVal = val
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/^0[^.]/, '0');
    formik.setFieldValue(name, newVal);
  };

  const handleClose = () => {
    dispatch(setShowChooseExpertModal(false));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" onClick={handleClose} className="custom-modal__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#modalClose`} />
            </svg>
          </button>
          {submitChoose ? (
            <div className="choose__expert-modal">
              <div className="choose__expert-modal__title">{translate('ChooseModal.t1')}</div>
              <div className="choose__expert-modal__title2">
                {translate('prName')}: {project.name}
              </div>
              <div className="choose__expert-modal__title2">
                {changeCond ? (
                  <>
                    {translate('ChooseModal.budget')} {formik.values.bid} {translate('grnPr')}
                  </>
                ) : (
                  <>
                    {expertCurrent.isInvited && !changeCond
                      ? translate('ChooseModal.stavkaExp')
                      : translate('ChooseModal.budget')}{' '}
                    {expertCurrent.isInvited && !changeCond ? (
                      <>
                        {project.budget} {translate('grnPr')}
                      </>
                    ) : (
                      <>
                        {expertCurrent.bid} {translate('grnPr')}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="choose__expert-modal__title2">
                {translate('ChooseModal.duration')}:{' '}
                {changeCond ? (
                  <>
                    {formik.values.completionTime} {translate('days')}
                  </>
                ) : (
                  <>
                    {expertCurrent.isInvited && !changeCond ? (
                      project.projectDuration.text
                    ) : (
                      <>
                        {expertCurrent.completionTime} {translate('days')}
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="choose__expert-modal__info">
                {translate('ChooseModal.text')}{' '}
                <Link to="/my-projects/">{translate('ChooseModal.btn1')}</Link>
              </div>
              <div className="choose__expert-modal__bot">
                <Link to="/my-projects/" className="btn btn-primary btn--new">
                  {translate('ChooseModal.myPr')}
                </Link>
                <Link to="/freelancers/" type="button" className="btn btn-outline-primary btn--new">
                  {translate('ChooseModal.backBtn')}
                </Link>
              </div>
            </div>
          ) : (
            <>
              {expertCurrent && (
                <div className="choose__expert-modal">
                  <div className="choose__expert-modal__title">
                    {translate('ChooseModal.title2')}
                  </div>

                  <div className="choose__expert-modal__title2">
                    {translate('prName')}: {project.name}
                  </div>
                  <div className="choose__expert-modal__title2">
                    {expertCurrent.isInvited
                      ? translate('ChooseModal.stavkaExp')
                      : translate('ChooseModal.budget')}{' '}
                    {expertCurrent.isInvited ? (
                      <>
                        {project.budget} {translate('grnPr')}
                      </>
                    ) : (
                      <>
                        {expertCurrent.bid} {translate('grnPr')}
                      </>
                    )}
                  </div>
                  <div className="choose__expert-modal__title2">
                    {translate('ChooseModal.duration')}:{' '}
                    {expertCurrent.isInvited ? (
                      project.projectDuration.text
                    ) : (
                      <>
                        {expertCurrent.completionTime} {translate('days')}
                      </>
                    )}
                  </div>

                  <div className="choose__expert-modal__info">
                    <div className="choose__expert-modal__info__row">
                      {translate('ChooseModal.text2')}{' '}
                      {expertCurrent.isInvited ? project.budget : expertCurrent.bid}{' '}
                      {translate('grnPr')}
                    </div>
                    <div className="choose__expert-modal__info__row">
                      {translate('ChooseModal.text3')}
                    </div>
                  </div>
                  {showForm ? (
                    <div className="choose__expert-modal__bot--new">
                      <div className="change__cond">
                        <div className="change__cond__t">{translate('ChooseModal.newCond')}</div>
                        <form className="change__cond__form" onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="change__cond__field">
                                {translate('ChooseModal.total')}
                                <input
                                  className={`input ${formik.errors.bid ? 'error' : ''}`}
                                  type="tel"
                                  name="bid"
                                  value={formik.values.bid}
                                  onChange={(e) => handleChange(e.target.value, 'bid')}
                                  maxLength={30}
                                />
                                Грн
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="change__cond__field">
                                {translate('ChooseModal.duration')}
                                <input
                                  className={`input ${formik.errors.completionTime ? 'error' : ''}`}
                                  type="tel"
                                  name="completionTime"
                                  value={formik.values.completionTime}
                                  onChange={(e) => handleChange(e.target.value, 'completionTime')}
                                  maxLength={30}
                                />
                                {translate('ChooseModal.days')}
                              </div>
                            </div>
                          </div>
                          <div className="change__cond__actions">
                            <button type="submit" className="btn btn-primary btn--new">
                              {translate('ChooseModal.choose')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-primary btn--new"
                              onClick={() => setShowForm(false)}
                            >
                              {translate('cancel')}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <div className="choose__expert-modal__bot--new">
                      <div className="change__cond">
                        {disabled && (
                          <>
                            <div className="change__cond__t">{translate('ChooseModal.text4')}</div>
                            <div className="change__cond__wrap">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-new"
                                onClick={() => setDisabled(false)}
                              >
                                {translate('ChooseModal.btn2')}
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-new"
                                onClick={() => setShowForm(true)}
                              >
                                {translate('ChooseModal.newCond')}
                              </button>
                            </div>
                          </>
                        )}
                        <div className="change__cond__actions">
                          <button
                            type="button"
                            className="btn btn-primary btn--new"
                            onClick={handleChooseExecutor}
                            disabled={disabled}
                          >
                            {translate('ChooseModal.choose')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary btn--new"
                            onClick={handleClose}
                          >
                            {translate('cancel')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
ChooseModal.propTypes = {
  show: PropTypes.bool.isRequired,
};
export default ChooseModal;
