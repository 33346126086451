import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';

const Step3SubStep5 = ({ setValue }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const currentProject = useSelector((state) => state.projectReducer.currentProject);
  const projectDurations = useSelector((state) => state.projectReducer.projectDurations);

  const size = Object.keys(projectDurations).length;
  const projectDurationsList = [];
  for (let i = 0; i < size; i += 1) {
    projectDurationsList[i] = projectDurations[i + 1];
  }

  const handleSubmit = async ({ rateCheck, projectDuration }) => {
    setValue('rateCheck', rateCheck);
    setValue('projectDuration', projectDuration);
    navigate('/publication/step4/');
  };

  const formik = useFormik({
    initialValues: {
      projectDuration: currentProject.projectDuration,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <form className="prpb__wrap" onSubmit={formik.handleSubmit}>
      <div className="prpb__name">{translate('Publication.detail')}</div>
      <div className="prpb__body">
        <div className="prpb__label">{translate('Publication.rateDuration')}</div>
        <div className="prpb__radios">
          {projectDurationsList.length > 0 &&
            projectDurationsList.map((item) => (
              <label className="prpb__radios__item" htmlFor={item.code} key={item.id}>
                <input
                  type="radio"
                  value={item.id}
                  name="projectDuration"
                  id={item.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultChecked={Number(formik.values.projectDuration) === item.id}
                />
                <div className="prpb__radios__input" />
                <div className="prpb__radios__text">{item.data}</div>
              </label>
            ))}
        </div>
      </div>
      <div className="prpb__footer">
        <button className="btn btn-primary" type="submit">
          {translate('next')}
        </button>
        <Link className="btn btn-outline-primary" to="/publication/step3/sub-step4/">
          {translate('cancel')}
        </Link>
      </div>
    </form>
  );
};

Step3SubStep5.propTypes = {
  setValue: PropTypes.func.isRequired,
};

export default Step3SubStep5;
