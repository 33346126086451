import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import coverDefault from 'src/assets/img/about/about-fon.png';
import avatarDefault from 'src/assets/img/avatar-default.png';
import sprite from 'src/assets/img/sprite.svg';
import { FavoriteExpertModal, RejectExpertModal, StarRate } from 'src/components';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

const ProfileHead = ({ handleShowInvite }) => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.authReducer.user.role);
  const expertId = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.id,
  );

  const rejected = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.rejected,
  );

  const favorite = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.favorite,
  );

  const profileCover = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.profileCover,
  );
  const avatar = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.avatar,
  );
  const fullName = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.profileName,
  );

  const country = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.country,
  );
  const city = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.city,
  );

  const premium = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.premium,
  );

  const rating = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.rating,
  );

  const avgReviewsRate = useSelector((state) =>
    state.projectReducer.expertDetail ? state.projectReducer.expertDetail.avgReviewsRate : 0,
  );

  // reject and favorite
  const [handleRejectModal, setHandleRejectModal] = useState(0);
  const [isReject, setIsReject] = useState(false);
  const [rejectModalXY, setRejectModalXY] = useState({ pageX: 0, pageY: 0 });

  const [handleFavoriteModal, setHandleFavoriteModal] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteModalXY, setFavoriteModalXY] = useState({ pageX: 0, pageY: 0 });

  const handleReject = () => {
    dispatch(projectActions.expertRejectDetail({ expert: expertId }));
  };

  const handleFavorite = () => {
    dispatch(projectActions.expertFavoriteDetail({ expert: expertId }));
  };

  return (
    <>
      <div
        className="exp-profile__top"
        style={{ backgroundImage: `url(${profileCover || coverDefault})` }}
      >
        <div
          className={`exp-profile__avatar ${premium ? `exp-profile__avatar--topExpert` : ''}`}
          style={{
            backgroundImage: `url(${avatar || avatarDefault})`,
          }}
        />
      </div>

      <div className="exp-profile__info">
        <div className="exp-profile__col">
          <div className="exp-profile__rate tooltips-wrap">
            <svg width="20px" height="20px">
              <use xlinkHref={`${sprite}#star-white`} />
            </svg>
            {rating || 0}
            <div className="tooltips">
              {translate('expertViews.tooltip1')}
              <Link to="/">{translate('expertViews.tooltip2')}</Link>
            </div>
          </div>
        </div>

        <div className="exp-profile__person">
          {premium && (
            <div className="profile__topExpert">
              Pro Expert
              <svg width="64" height="64" viewBox="0 0 64 65" className="pro-label">
                <use xlinkHref={`${sprite}#proExpert`} />
              </svg>
            </div>
          )}
          <div className="exp-profile__person__name">{fullName}</div>
          <div className="exp-profile__person__location">
            {country && country.name}
            {city && `, ${city.name}`}
          </div>
          <div className="exp-profile__person__rate">
            <StarRate rate={avgReviewsRate} />
          </div>
        </div>

        <div className="exp-profile__col exp-profile__col--right">
          {role === 2 && (
            <div className="exp-prof__action">
              <div className="proekt__actios">
                <button
                  type="button"
                  className={rejected ? 'active' : ''}
                  onClick={(e) => {
                    setRejectModalXY({ pageX: e.pageX, pageY: e.pageY });
                    setIsReject(rejected);
                    setHandleRejectModal(Number(expertId));
                  }}
                >
                  <svg width="13.2px" height="13.2px">
                    <use xlinkHref={`${sprite}#dislike`} />
                  </svg>
                  <svg width="13.2px" height="13.2px" className="active-svg">
                    <use xlinkHref={`${sprite}#dislike-active`} />
                  </svg>
                </button>
                <button
                  type="button"
                  className={favorite ? 'active' : ''}
                  onClick={(e) => {
                    setFavoriteModalXY({
                      pageX: e.pageX,
                      pageY: e.pageY,
                    });
                    setIsFavorite(favorite);
                    setHandleFavoriteModal(Number(expertId));
                  }}
                >
                  <svg width="13.2px" height="13.2px">
                    <use xlinkHref={`${sprite}#like`} />
                  </svg>
                  <svg width="13.2px" height="13.2px" className="active-svg">
                    <use xlinkHref={`${sprite}#like-active`} />
                  </svg>
                </button>
              </div>
              <button className="btn btn-primary" type="button" onClick={handleShowInvite}>
                {translate('Experts.join')}
              </button>
            </div>
          )}
        </div>
      </div>

      {handleRejectModal !== 0 && (
        <RejectExpertModal
          handleRejectModal={handleRejectModal}
          rejectModalXY={rejectModalXY}
          setHandleRejectModal={setHandleRejectModal}
          dispatch={handleReject}
          isReject={isReject}
        />
      )}

      {handleFavoriteModal !== 0 && (
        <FavoriteExpertModal
          handleFavoriteModal={handleFavoriteModal}
          favoriteModalXY={favoriteModalXY}
          setHandleFavoriteModal={setHandleFavoriteModal}
          dispatch={handleFavorite}
          isFavorite={isFavorite}
        />
      )}
    </>
  );
};
ProfileHead.propTypes = {
  handleShowInvite: PropTypes.func.isRequired,
};
export default ProfileHead;
