import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import coverDefault from 'src/assets/img/about/about-fon.png';
import avatarDefault from 'src/assets/img/avatar-default.png';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import { RateInfo, StarRate } from 'src/components';
import { useTranslation } from 'src/hooks';

const ProfileHead = () => {
  const { translate } = useTranslation();
  const role = useSelector((state) => state.authReducer.user.role);
  const { avatar, profileCover } = useSelector((state) =>
    role === 1 ? state.authReducer.user.expert : state.authReducer.user.company,
  );

  const fullName = useSelector((state) =>
    role === 1
      ? state.authReducer.user.expert.profileName
      : state.authReducer.user.company.companyName,
  );
  const { country, city } = useSelector((state) =>
    role === 1 ? state.authReducer.user.expert : state.authReducer.user.company,
  );

  const premium = useSelector(
    (state) => (role === 1 && state.authReducer.user.expert.premium) || false,
  );

  const rating = useSelector((state) => role === 1 && state.authReducer.user.expert.rating);

  const { avgReviewsRate } = useSelector((state) =>
    role === 1 ? state.authReducer.user.expert : state.authReducer.user.company,
  );

  const [showRateInfo, setShowRateInfo] = useState(false);
  const handleShowRateInfo = () => setShowRateInfo(true);
  const handleCloseRateInfo = () => setShowRateInfo(false);

  return (
    <>
      <div
        className="exp-profile__top"
        style={{ backgroundImage: `url(${profileCover || coverDefault})` }}
      >
        <div
          className={`exp-profile__avatar ${premium ? `exp-profile__avatar--topExpert` : ''}`}
          style={{
            backgroundImage: `url(${avatar || (role === 1 ? avatarDefault : avatarDefaultComp)})`,
          }}
        />
      </div>

      <div className="exp-profile__info">
        <div className="exp-profile__col">
          {role === 1 && (
            <div className="exp-profile__rate tooltips-wrap">
              <svg width="20px" height="20px">
                <use xlinkHref={`${sprite}#star-white`} />
              </svg>
              {rating || 0}
              <div className="tooltips">
                {translate('Profile.rate')}
                <Link to="/profile" onClick={handleShowRateInfo}>
                  {translate('Profile.rateLink')}
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className="exp-profile__person">
          {premium && (
            <div className="profile__topExpert">
              Pro Expert
              <svg width="64" height="64" viewBox="0 0 64 65" className="pro-label">
                <use xlinkHref={`${sprite}#proExpert`} />
              </svg>
            </div>
          )}

          <div className="exp-profile__person__name">{fullName}</div>

          <div className="exp-profile__person__location">
            {city && `${city.name},`} {country && country.name}
          </div>
          <div className="exp-profile__person__rate">
            <StarRate rate={avgReviewsRate} />
          </div>
        </div>

        <div className="exp-profile__col exp-profile__col--right" />
      </div>

      <RateInfo show={showRateInfo} handleClose={handleCloseRateInfo} />
    </>
  );
};
export default ProfileHead;
