import './style.scss';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import mastercard from 'src/assets/img/cards/mastercard.svg';
import visa from 'src/assets/img/cards/visa.svg';
import flogo from 'src/assets/img/header/logo-f.svg';
import sprite from 'src/assets/img/sprite.svg';
import { footerCompanyMenu, footerSocMenu, footerTermsMenu } from 'src/constants';
import { useTranslation } from 'src/hooks';

const Footer = () => {
  const { translate, language } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  };

  return (
    <footer className="footer">
      <div className="wrap">
        <div className="footer__wrap">
          <div className="footer__left">
            <Link to="/" onClick={scrollToTop}>
              <LazyLoadImage alt="footerlogo" height={34} src={flogo} width={147} />
            </Link>
          </div>
          <div className="footer__right">
            <nav className="footer__right__nav">
              <div className="f-col">
                <div className="title">{translate('Footer.terms')}</div>
                <ul>
                  {footerTermsMenu &&
                    footerTermsMenu[language].map((item) => (
                      <li key={item.id}>
                        <Link to={item.to}>{item.name}</Link>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="f-col">
                <div className="title">{translate('Footer.company')}</div>
                <ul>
                  {footerCompanyMenu &&
                    footerCompanyMenu[language].map((item) => (
                      <li key={item.id}>
                        <Link to={item.to}>{item.name}</Link>
                      </li>
                    ))}
                  <li className="last-li">
                    <button
                      type="button"
                      onClick={() => {
                        window.HelpCrunch('openChat');
                      }}
                    >
                      {translate('Footer.support')}
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="footer__wrap__bot">
          <div className="footer__copy">
            {translate('Footer.copi')} <br />
            {translate('Footer.copi2')}
            <LazyLoadImage alt="mastercard" height={30} src={mastercard} width={51} />
            <LazyLoadImage alt="visa" height={30} src={visa} width={51} />
          </div>

          <div className="footer__soc">
            <div className="footer__soc__text">{translate('Footer.soc')}</div>
            <ul>
              <li>
                <a href={footerSocMenu.in.to}>
                  <svg width="25px" height="25px">
                    <use xlinkHref={`${sprite}#linkedin`} />
                  </svg>
                </a>
              </li>
              <li>
                <a href={footerSocMenu.fb.to}>
                  <svg width="35px" height="35px" style={{ marginTop: '10px' }}>
                    <use xlinkHref={`${sprite}#fb`} />
                  </svg>
                </a>
              </li>
              <li>
                <a href={footerSocMenu.inst.to}>
                  <svg width="35px" height="35px">
                    <use xlinkHref={`${sprite}#inst`} />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
