import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FixLoader, Footer, Header, Subheader } from 'src/components';
import { projectActions } from 'src/store/actions';

import { ExpertProfile } from './components';

const ExpertView = () => {
  const dispatch = useDispatch();

  const authWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const projectWaiter = useSelector((state) => state.projectReducer.fixWaiter);
  const { slug } = useParams();

  useEffect(() => {
    dispatch(projectActions.getExpert(slug));
  }, []);

  return (
    <>
      {(projectWaiter || authWaiter) && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            <ExpertProfile />
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ExpertView;
