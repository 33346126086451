import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const RejectExpertModal = ({
  handleRejectModal,
  rejectModalXY,
  setHandleRejectModal,
  isReject,
  dispatch,
}) => {
  const { translate } = useTranslation();
  const maxWidth = rejectModalXY.pageX > 462;

  const handleDispach = () => {
    dispatch(handleRejectModal);
    setHandleRejectModal(0);
  };

  useEffect(() => {
    function handleResize() {
      setHandleRejectModal(0);
    }
    function handleClickOnDoc(e) {
      if (!e.target.closest('.actionModal')) {
        setHandleRejectModal(0);
      }
    }
    document.addEventListener('mouseup', handleClickOnDoc);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mouseup', handleClickOnDoc);
    };
  }, []);

  return (
    <div
      className={`actionModal ${!maxWidth ? 'mob' : ''}`}
      style={{
        left: `${maxWidth ? rejectModalXY.pageX - 20 : 30}px`,
        top: `${rejectModalXY.pageY + 20}px`,
      }}
    >
      <button type="button" className="actionModal__close" onClick={() => setHandleRejectModal(0)}>
        <svg width="12" height="12">
          <use xlinkHref={`${sprite}#handleClose`} />
        </svg>
      </button>
      <div className="actionModal__text">
        {isReject ? translate('ExpertReject.title2') : translate('ExpertReject.title')}
      </div>
      <div className="d-flex justify-content-center">
        <button type="button" className="btn btn-primary" onClick={handleDispach}>
          {isReject ? translate('ExpertReject.btn2') : translate('ExpertReject.btn')}
        </button>
      </div>
    </div>
  );
};

RejectExpertModal.propTypes = {
  handleRejectModal: PropTypes.number.isRequired,
  rejectModalXY: PropTypes.shape({
    pageX: PropTypes.number.isRequired,
    pageY: PropTypes.number.isRequired,
  }).isRequired,
  setHandleRejectModal: PropTypes.func.isRequired,
  isReject: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};
export default RejectExpertModal;
