import './style.scss';

import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { FixLoader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

const InviteModal = ({ show, handleClose, expert }) => {
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const waiter = useSelector((state) => state.projectReducer.waiter);
  const activeProject = useSelector((state) => state.projectReducer.activeProject);
  const { translate } = useTranslation();

  useEffect(() => {
    dispatch(projectActions.getActiveProjects());
  }, []);

  const handleSubmit = async (values) => {
    await dispatch(projectActions.projectInvite(values));
    setSubmit(true);
  };

  const formik = useFormik({
    initialValues: {
      projects: '',
      experts: [expert],
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <>
      {waiter ? (
        <FixLoader />
      ) : (
        <Modal show={show} onHide={handleClose} className="invite">
          <Modal.Body>
            <div className="invite__body custom-modal-big">
              <button type="button" onClick={handleClose} className="custom-modal__close">
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#modalClose`} />
                </svg>
              </button>

              {activeProject.length > 0 ? (
                <>
                  {!submit ? (
                    <form onSubmit={formik.handleSubmit}>
                      <div className="invite__title">{translate('inviteModal.title')}</div>
                      <div className="paid-modal__body">
                        {activeProject.map((item) => (
                          <label
                            className="paid-modal__checkbox"
                            htmlFor={`mch${item.id}`}
                            key={item.id}
                          >
                            <input
                              type="checkbox"
                              name="projects"
                              id={`mch${item.id}`}
                              value={item.id}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              checked={formik.values.projects.includes(String(item.id))}
                            />
                            <div className="paid-modal__checkbox__check">
                              <svg width="18" height="13">
                                <use xlinkHref={`${sprite}#modalCheckbox`} />
                              </svg>
                            </div>
                            <span className="paid-modal__checkbox__text">
                              {item.name}{' '}
                              <b>
                                ({item.budget} {translate('grn')})
                              </b>
                            </span>
                          </label>
                        ))}

                        <div className="paid-modal__action">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!formik.values.projects}
                          >
                            {translate('next')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={handleClose}
                          >
                            {translate('inviteModal.btnBack')}
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <>
                      <div className="invite__title invite__title--pt">
                        {translate('inviteModal.titleSub')}
                      </div>
                      <div className="invite__subTitle">{translate('inviteModal.textSub')}</div>
                      <div className="invite__submit">
                        {translate('inviteModal.textSub2')}{' '}
                        <Link to="/my-projects/">{translate('inviteModal.textLink')}</Link>
                      </div>
                      <div className="paid-modal__body paid-modal__body--submit">
                        <div className="paid-modal__action">
                          <Link to="/my-projects/" className="btn btn-primary">
                            {translate('inviteModal.btnMyProject')}
                          </Link>

                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={handleClose}
                          >
                            {translate('inviteModal.btnBack')}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {!waiter && (
                    <>
                      <div className="invite__title invite__title--pt2">
                        {translate('inviteModal.title2')}
                      </div>
                      <div className="invite__btns">
                        <Link to="/publication/" className="btn btn-primary">
                          {translate('inviteModal.btnPublic')}
                        </Link>

                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={handleClose}
                        >
                          {translate('inviteModal.btnBack')}
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

InviteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  expert: PropTypes.number.isRequired,
};
export default InviteModal;
