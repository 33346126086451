import { api } from 'src/api';
import { getHeaders } from 'src/helpers/';

export const getChatListCompany = async () => {
  const result = await api.get(`/chat-company/list`, getHeaders());

  return result;
};

export const getChatCompany = async (id) => {
  const result = await api.get(`/chat-company/messages?chatId=${id}`, getHeaders());

  return result;
};

export const sendMessCompany = async (data) => {
  const result = await api.post(`/chat-company/message`, data, getHeaders());

  return result;
};

export const getChatListExpert = async () => {
  const result = await api.get(`/chat-expert/list`, getHeaders());

  return result;
};

export const getChatExpert = async (id) => {
  const result = await api.get(`/chat-expert/messages?chatId=${id}`, getHeaders());

  return result;
};

export const sendMessExpert = async (data) => {
  const result = await api.post(`/chat-expert/message`, data, getHeaders());

  return result;
};

export const expertReadMess = async (chatId) => {
  const result = await api.post(`/chat-expert/mark-messages-as-read`, { chatId }, getHeaders());

  return result;
};

export const companyReadMess = async (chatId) => {
  const result = await api.post(`/chat-company/mark-messages-as-read`, { chatId }, getHeaders());

  return result;
};
