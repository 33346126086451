export const expertSidebarMenu = {
  ru: [
    {
      name: 'Личная информация',
      to: '/user/about/',
      id: 1,
    },
    {
      name: 'Контактная информация',
      to: '/user/contacts/',
      id: 2,
    },
    {
      name: 'Специализации',
      to: '/user/spec/',
      id: 5,
    },
    {
      name: 'Резюме',
      to: '/user/resume/',
      id: 3,
    },
    {
      name: 'Портфолио',
      to: '/user/portfolio/',
      id: 4,
    },

    {
      name: 'Категории бизнеса',
      to: '/user/business/',
      id: 6,
    },
  ],
  ua: [
    {
      name: 'Особиста інформація',
      to: '/user/about/',
      id: 1,
    },
    {
      name: 'Контактна інформація',
      to: '/user/contacts/',
      id: 2,
    },
    {
      name: 'Спеціалізації',
      to: '/user/spec/',
      id: 5,
    },
    {
      name: 'Резюме',
      to: '/user/resume/',
      id: 3,
    },
    {
      name: 'Портфоліо',
      to: '/user/portfolio/',
      id: 4,
    },

    {
      name: 'Категорії бізнесу',
      to: '/user/business/',
      id: 6,
    },
  ],
};

export const compSidebarMenu = {
  ru: [
    {
      name: 'Информация о компании',
      to: '/user/about/',
      id: 1,
    },
    {
      name: 'Контактная информация',
      to: '/user/contacts/',
      id: 2,
    },
    {
      name: 'Специализация компании',
      to: '/user/sfera/',
      id: 3,
    },
  ],
  ua: [
    {
      name: 'Інформація про компанію',
      to: '/user/about/',
      id: 1,
    },
    {
      name: 'Контактна інформація',
      to: '/user/contacts/',
      id: 2,
    },
    {
      name: 'Спеціалізація компанії',
      to: '/user/sfera/',
      id: 3,
    },
  ],
};

export const sidebarTopMenuExpert = {
  ua: [
    {
      name: 'Перегляд профілю',
      to: '/profile/',
      id: 1,
    },
    {
      name: 'Пройти відбір',
      to: '/otbor',
      id: 2,
    },
  ],
  ru: [
    {
      name: 'Просмотр профиля',
      to: '/profile/',
      id: 1,
    },
    {
      name: 'Пройти отбор',
      to: '/otbor',
      id: 2,
    },
  ],
};

export const sidebarTopMenuCompany = {
  ua: [
    {
      name: 'Перегляд профілю',
      to: '/profile/',
      id: 1,
    },
  ],
  ru: [
    {
      name: 'Просмотр профиля',
      to: '/profile/',
      id: 1,
    },
  ],
};

export const sidebarTopMenuPublication = {
  ua: [
    {
      name: 'Назва',
      to: '/publication',
      id: 1,
    },
    {
      name: 'Опис',
      to: '/publication/step2/',
      id: 2,
    },
    {
      name: 'Деталі',
      to: '/publication/step3/',
      id: 3,
    },
    {
      name: 'Бюджет',
      to: '/publication/step4/',
      id: 4,
    },
    {
      name: 'Перегляд',
      to: '/publication/preview/',
      id: 5,
    },
  ],
  ru: [
    {
      name: 'Название',
      to: '/publication',
      id: 1,
    },
    {
      name: 'Описание',
      to: '/publication/step2/',
      id: 2,
    },
    {
      name: 'Детали',
      to: '/publication/step3/',
      id: 3,
    },
    {
      name: 'Бюджет',
      to: '/publication/step4/',
      id: 4,
    },
    {
      name: 'Просмотр',
      to: '/publication/preview/',
      id: 5,
    },
  ],
};

export const footerTermsMenu = {
  ru: [
    {
      name: 'Порядок обработки персональных данных',
      to: '/policy',
      id: 2,
    },
    {
      name: 'Пользовательское соглашение',
      to: '/policy-user',
      id: 3,
    },
    {
      name: 'Помощь',
      to: '/baza-znan',
      id: 4,
    },
  ],
  ua: [
    {
      name: 'Порядок обробки персональних даних',
      to: '/policy',
      id: 2,
    },
    {
      name: 'Угода користувача',
      to: '/policy-user',
      id: 3,
    },
    {
      name: 'Допомога',
      to: '/baza-znan',
      id: 4,
    },
  ],
};

export const footerCompanyMenu = {
  ru: [
    {
      name: 'О нас',
      to: '/about-us',
      id: 1,
    },
    {
      name: 'Контакты',
      to: '/contacts',
      id: 3,
    },
  ],
  ua: [
    {
      name: 'Про нас',
      to: '/about-us',
      id: 1,
    },
    {
      name: 'Контакти',
      to: '/contacts',
      id: 3,
    },
  ],
};

export const footerSocMenu = {
  in: { to: 'https://www.linkedin.com/company/theexpert/', id: 1 },
  fb: { to: 'https://www.facebook.com/ExpertLand.ua', id: 2 },
  inst: { to: 'https://instagram.com/expertland.ua?igshid=YmMyMTA2M2Y=', id: 3 },
};

export const subMenuExpert = {
  ru: [
    { name: 'Найти проект', id: 0, to: '/projects/' },
    { name: 'Статистика', id: 1, to: '/stat/' },
    { name: 'Оплата', id: 2, to: '/payment' },
    { name: 'Текущие проекты', id: 3, to: '/current-projects/' },
    { name: 'Мои заявки', id: 4, to: '/my-offers/' },
  ],
  ua: [
    { name: 'Знайти проєкт', id: 0, to: '/projects/' },
    { name: 'Статистика', id: 1, to: '/stat/' },
    { name: 'Оплата', id: 2, to: '/payment' },
    { name: 'Поточні проєкти', id: 3, to: '/current-projects/' },
    { name: 'Мої заявки', id: 4, to: '/my-offers/' },
  ],
};

export const subMenuCompany = {
  ru: [
    { name: 'Найти эксперта', id: 0, to: '/freelancers/' },
    { name: 'Мои проекты', id: 1, to: '/my-projects/' },
    { name: 'Оплата', id: 4, to: '/payment' },
  ],
  ua: [
    { name: 'Знайти експерта', id: 0, to: '/freelancers/' },
    { name: 'Мої проєкти', id: 1, to: '/my-projects' },
    { name: 'Оплата', id: 4, to: '/payment' },
  ],
};

export const navMenuAdminUser = {
  ru: [
    { id: 1, name: 'Личная информация', to: '/admin/user/edit/' },
    { id: 2, name: 'Контактная информация', to: '/admin/user/edit/сontact-info' },
    { id: 3, name: 'Резюме', to: '/admin/user/edit/resume' },
    { id: 4, name: 'Портфолио', to: '/admin/user/edit/portfolio' },
    { id: 5, name: 'Категории бизнеса', to: '/admin/user/edit/business-categories' },
    { id: 6, name: 'Специализации', to: '/admin/user/edit/specialties' },
    { id: 7, name: 'Платные функции', to: '/admin/user/edit/paid-features' },
  ],
  ua: [
    { id: 1, name: 'Особиста інформація', to: '/admin/user/edit' },
    { id: 2, name: 'Контактна інформація', to: '/admin/user/edit/сontact-info' },
    { id: 3, name: 'Резюме', to: '/admin/user/edit/resume' },
    { id: 4, name: 'Портфоліо', to: '/admin/user/edit/portfolio' },
    { id: 5, name: 'Категорії бізнесу', to: '/admin/user/edit/business-categories' },
    { id: 6, name: 'Спеціалізації', to: '/admin/user/edit/specialties' },
    { id: 7, name: 'Платні функції', to: '/admin/user/edit/paid-features' },
  ],
};

// dataPicker locale
const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'];
const months = {
  ua: [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень',
  ],
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};

export const localeUa = {
  localize: {
    day: (n) => days[n],
    month: (n) => months.ua[n],
  },
  formatLong: {
    date: () => 'mm/dd/yyyy',
  },
};

export const localeRu = {
  localize: {
    day: (n) => days[n],
    month: (n) => months.ru[n],
  },
  formatLong: {
    date: () => 'mm/dd/yyyy',
  },
};
