import { Link } from 'react-router-dom';
import { Footer, HeaderContentPage } from 'src/components';
import { useTranslation } from 'src/hooks';

const Policy = () => {
  const { translate } = useTranslation();

  return (
    <>
      <div className="app base">
        <HeaderContentPage />
        <main className="main">
          <div className="wrap">
            <div className="base__main mt-3">
              <div className="proekt__search" />
              <nav className="prpb__breadcrumb">
                <ul>
                  <li>
                    <Link to="/">{translate('main')}</Link>
                  </li>
                  <li>
                    <span>{translate('policy.title')}</span>
                  </li>
                </ul>
              </nav>
              <div className="exp-content">
                <div className="exp-content__edit">
                  <div className="exp-content__top exp-content__top__mess">
                    {translate('policy.title')}
                  </div>
                  <div className="base__detail">
                    <b>1. Загальні положення </b>
                    <p>
                      1.1. Цей документ визначає порядок обробки та процедури щодо захисту
                      персональних даних (далі за текстом – &ldquo;Порядок&ldquo;), які здійснюються
                      ТОВ «ЕКСПЕРТЛЕНД» при наданні своїх послуг на веб-сайті та/або мобільному
                      додатку, та/або інших сервісах прикладного програмного інтерфейсу Товариства
                      (розташованих, зокрема, але не виключно за адресою в мережі Інтернет:{' '}
                      <a href="https://expertland.ua/">https://expertland.ua/</a>), які надалі
                      іменуються - &ldquo;Платформою&ldquo;.
                    </p>
                    <p>
                      1.2. Володільцем персональних даних є ТОВ «ЕКСПЕРТЛЕНД» (надалі –
                      &ldquo;Товариство&ldquo; та/або &ldquo;Володілець персональних даних&ldquo;),
                      яке зареєстроване та діє відповідно до діючого законодавства України з
                      місцезнаходженням: 03134, м. Київ, вул. Булгакова, буд. 12-А, кв. 146 (код
                      ЄДРПОУ – 44975380).
                    </p>
                    <p>
                      1.3. Порядок визначає мету, підстави та умови обробки персональних даних, які
                      Товариство отримує від фізичних осіб – користувачів (надалі –
                      &ldquo;Користувачі&ldquo;) Платформи, що дозволяють Користувачам
                      реєструватися, отримувати послуги Платформи та вступати у договірні відносини
                      з іншими її користувачами.
                    </p>
                    <p>
                      1.4. Порядок також визначає умови, механізми, вимоги до Товариства, як
                      володільця персональних даних, до забезпечення захисту персональних даних
                      суб’єктів персональних даних на всіх етапах їх обробки, у тому числі за
                      допомогою організаційних і технічних заходів, а також пояснює, які дані та з
                      якою метою Товариство може збирати, використовувати, зберігати та іншим чином
                      обробляти.
                    </p>
                    <p>
                      1.5. Порядок обробки персональних даних розповсюджується на невизначене коло
                      Користувачів, які вступають або можуть вступати у будь–які відносини з
                      Товариством та/або реєструються на Платформі. Уважно ознайомтеся з положеннями
                      цього повідомлення, оскільки використання певних сервісів Товариства та/або
                      вчинення Вами певних дій, можливе лише після прийняття Вами її умов під час
                      реєстрації на сайті. Порядок публікується у вільному доступі на веб-сайті
                      Товариства{' '}
                      <a href="https://expertland.ua/policy">https://expertland.ua/policy</a> в
                      мережі Інтернет з метою ознайомлення суб’єктів персональних даних, дані яких
                      обробляються Володільцем персональних даних під час здійснення ним своєї
                      статутної (господарської) діяльності.
                    </p>
                    <p>
                      1.6. На Платформі можуть міститися посилання на інші веб-сайти, наприклад, при
                      здійсненні оплати послуг, та/або на веб-сайти (сторінки) інших учасників
                      Платформи (в інформаційних цілях). При переході за посиланням на інші
                      веб-сайти дія цього Порядку на такі веб-сайти не поширюватиметься, оскільки
                      Товариство не є власником таких веб-сайтів і не може їх контролювати. У
                      зв&apos;язку з цим, Товариство рекомендує ознайомитися з порядком обробки і
                      захистом персональних даних/політикою у сфері конфіденційності та персональних
                      даних кожного веб-сайту перш ніж передавати персональні дані, за якими ви
                      можете бути ідентифіковані.
                    </p>
                    <b>
                      2. Сфера застосування, мета обробки і обсяг Персональних даних, які можуть
                      оброблятися Товариством
                    </b>
                    <p>
                      2.1. Для надання своїх послуг та забезпечення функціоналу, для якого була
                      створена Платформа (як «місце зустрічі» бізнесу та експертів), Товариство
                      використовує та/або може використовувати інформацію з персональними даними,
                      які збираються, зберігаються та обробляються для таких цілей (мета обробки):
                    </p>
                    <ul>
                      <li>
                        забезпечення реалізації цивільно-правових, господарських та податкових
                        відносин, здійснення покладених на Товариство функцій, повноважень та
                        обов’язків у відповідності до вимог діючого законодавства України;
                      </li>
                      <li>
                        реєстрація облікового запису Користувача, ідентифікація особи, яка заходить
                        на Платформу як користувач Платформи, для обробки платежів, проведення
                        розрахункових операцій, надання звітності, ведення бухгалтерського та
                        управлінського обліку;
                      </li>
                      <li>
                        забезпечення обслуговування клієнтів, в тому числі для створення і
                        управління обліковими записами Користувачів, вирішення технічних труднощів і
                        доступу до різних функцій;
                      </li>
                      <li>
                        впровадження послуг і надання Користувачам можливості повноцінно та безпечно
                        використовувати Платформу, маркетингу власних послуг і продуктів Товариства
                        (Платформи), статистичних чи дослідницьких дій, а також для зв’язку з
                        Користувачем в тому числі при наданні послуг, для направлення розсилок,
                        комерційних пропозицій, повідомлень, програми лояльності, повідомлень щодо
                        функціонування Платформи поштою, електронною поштою, за номером телефону,
                        шляхом надсилання інформаційних повідомлень та оповіщень в мобільному
                        додатку, тощо;
                      </li>
                      <li>
                        для забезпечення безпеки послуг у т.ч. іншим учасникам (користувачам)
                        Платформи, включаючи необхідність дотримання правил та протидії шахрайству,
                        іншим зловживанням; для розгляду можливих скарг, ведення медіаційних,
                        арбітражних або судових процесів;
                      </li>
                      <li>
                        з метою виконання інших обов&apos;язків, покладених законом на Володільця
                        персональними даними, для захисту законних інтересів Володільця персональних
                        даних або третьої особи, якій передаються персональні дані.
                      </li>
                    </ul>
                    <p>
                      2.2. Тобто, в залежності від мети обробки законними підставами для обробки
                      Ваших персональних даних, можуть бути: (1) необхідність укладення та виконання
                      договору та/або іншого правочину, стороною якого є фізична особа, або який
                      укладається на користь фізичної особи, (2) для виконання Товариством
                      обов’язків, які визначені законом та/або здійснення повноважень, передбачених
                      законом, (3) законні інтереси Товариства, (4) згода суб’єкта персональних
                      даних на обробку його персональних даних.
                    </p>
                    <p>
                      2.3. Товариство збирає та зберігає персональні дані лише в рамках
                      вищезазначених цілей і не використовуватиме персональні дані для інших
                      несумісних цілей, за винятком випадків прямо передбачених діючим
                      законодавством України, або у разі, якщо Користувач надав згоду на подальше
                      використання після того, як Платформа поінформувала його про це.
                    </p>
                    <p>
                      Платформа також може надавати персональні дані, включаючи анкетні дані
                      Користувача, іншим учасникам Платформи, якщо це доцільно з точки зору
                      прискорення завантаження коректних даних для договору, платіжних документів,
                      тощо.
                    </p>
                    <p>
                      2.4. Ми отримуємо ваші персональні дані, як правило, безпосередньо від вас,
                      зокрема, під час реєстрації на Платформі або укладення договірних відносин
                      (договору, платіжних документів, реквізитів) з іншими учасниками Платформи, а
                      також коли ви підписуєтеся на розсилку новин, берете участь в опитуванні або
                      надаєте відгуки про послуги Товариства, тощо. При створенні Користувачем
                      облікового запису на Платформі та/або укладенні договірних відносин (із
                      замовниками, з експертами та/або Товариством), Товариство може вимагати
                      інформацію, наведену в п. 2.5 цього Порядку.
                    </p>
                    <p>
                      2.5. В залежності від мети та підстав обробки, обсяг персональних даних
                      Користувача, який обробляє Товариство як володілець персональних даних,
                      включає та/або може включати таку інформацію про Вас: прізвище, власне ім’я,
                      по батькові; реєстраційний номер облікової картки платників податків з
                      Державного реєстру фізичних осіб-платників податків (ідентифікаційний номер);
                    </p>
                    <ul>
                      <li>паспортні дані;</li>
                      <li>платіжні реквізити (номер карткового рахунку, у т.ч. у форматі IBAN);</li>
                      <li>місце проживання та/або реєстрації;</li>
                      <li>дата та місце народження;</li>
                      <li>номери контактних телефонів;</li>
                      <li>адреси електронної пошти;</li>
                      <li>стать</li>
                      <li> IP-адреса;</li>
                      <li> час доступу на Платформу.</li>
                    </ul>
                    <p>
                      2.6. Товариство, як правило, не обробляє персональні дані про расове або
                      етнічне походження, політичні, релігійні або світоглядні переконання, членство
                      в політичних партіях та професійних спілках, біометричні або генетичні дані,
                      іншу подібну інформацію, якщо тільки така інформація не була самостійно надана
                      (оприлюднена) Користувачем, зокрема, на власній сторінці (акаунті), тощо.
                      Товариство не здійснює цілеспрямовану обробку цих та/або інших чутливих даних
                      про фізичну особу.
                    </p>
                    <p>
                      2.7. Персональні дані, що обробляються Володільцем персональних даних,
                      відносяться до конфіденційної інформації. Товариство здійснює обробку
                      персональних даних будь-якими способами в рамках вимог Закону України «Про
                      захист персональних даних» та/або іншого законодавства України, яким
                      врегульовано вказані питання.
                    </p>
                    <b>3. Захист персональних даних та права Користувачів</b>
                    <p>
                      3.1 Обробка персональних даних, як правило, обмежується збором мінімального
                      обсягу обов’язкової інформації, необхідного виключно для виконання цілей, які
                      зазначені в п. 2.1. цього Порядку.
                    </p>
                    <p>
                      Коли Товариством (на Платформі, додатках) запитуватиметься не обов’язкова до
                      надання інформація, Користувач про це повідомляється в момент збору такої
                      інформації. Не обов’язкова до надання інформація надається Користувачем за
                      власним бажанням та за власним волевиявленням. Товариство може використовувати
                      таку інформацію для цілей вдосконалення Платформи, своїх послуг, пропонування
                      Користувачу пропозицій, залежно від його вподобань та виходячи з наданої
                      Користувачем не обов’язкової інформації.
                    </p>
                    <p>
                      3.2. Строки обробки та зберігання даних визначаються, виходячи із цілей
                      обробки даних, а також виходячи із умов, вказаних в договорах, укладених з
                      Користувачами у відповідності до вимог діючого законодавства України.
                      Персональні дані обробляються та зберігаються настільки довго, наскільки це
                      необхідно для досягнення цілей, заради яких вони були зібрані, в тому числі
                      для виконання будь-яких юридичних, бухгалтерських вимог, або вимог щодо
                      звітності, або до моменту видалення даних Користувачем або за запитом
                      Користувача. Тобто, Товариство може зберігати інформацію, яку воно збирає і
                      отримує при наданні послуг стільки, скільки буде потрібно для виконання
                      вищевказаних господарських та/або податкових цілей (як правило не більше 1095
                      днів від дати останньої транзакції, здійсненої Користувачем та/або 365 днів з
                      дати останнього користування Платформою, в залежності від того, яка із
                      зазначених подій настає пізніше).
                    </p>
                    <p>
                      3.3. Обробка персональних даних здійснюється у дата-центрах, де розміщується
                      обладнання, що забезпечує функціонування сервісів. Володілець персональних
                      даних приймає усі передбачені законодавством заходи для захисту даних суб’єкта
                      персональних даних, зокрема, обробка даних здійснюється на обладнанні, що
                      міститься у охоронюваних приміщеннях з обмеженим доступом.
                    </p>
                    <p>
                      3.4. Товариство вживає заходів щодо забезпечення захисту персональних даних на
                      всіх етапах їх обробки за допомогою організаційних (персональні дані в межах
                      їх обробки Товариством є інформацією з обмежених доступом, яка опрацьовується
                      лише уповноваженими співробітниками) та технічних ( у т.ч. з використанням
                      захищених сховищах даних) заходів, спрямованих на запобігання їх випадковим
                      втратам або знищенню, незаконній обробці, у тому числі незаконному знищенню чи
                      доступу до даних.
                    </p>
                    <p>
                      Вся інформація захищена технічними засобами ( у т.ч. з використанням захищених
                      сховищах даних) і процедурами забезпечення безпеки з метою запобігання
                      несанкціонованому доступу або використання даних. Афілійовані з Товариством
                      особи (у т.ч. правонаступники Товариства) та/або незалежні постачальники
                      послуг (у т.ч. банківські та фінансові установи, які забезпечують проведення
                      транзакцій, та/або розсилку інформації Користувачам) зобов&apos;язуються
                      використовувати отриману від Товариства інформацію відповідно до вимог діючого
                      законодавства та з врахуванням вимог цього Порядку та власних розроблених умов
                      (політик) обробки персональних даних.
                    </p>
                    <p>
                      3.5. Користувач як суб’єкт персональних даних має всі права щодо захисту його
                      даних, які передбачені чинним законодавством України, а саме статтею 8 Закону
                      України «Про захист персональних даних». Так, суб’єкт персональних даних може
                      в будь-який момент змінити (оновити, доповнити) надані їм дані або їх частину.
                      У випадку неможливості самостійно видалити/змінити дані, Користувач може
                      звернутися для цього до служби підтримки Платформи.
                    </p>
                    <p>
                      Користувач несе відповідальність за точність наданих даних або повідомлень при
                      користуванні послугами Платформи, сервісами й додатками.
                    </p>
                    <p>
                      3.6. Товариство може видалити або припинити обробку персональних даних
                      Користувача в разі отримання письмового повідомлення Користувача про
                      відкликання згоди на обробку персональних даних (крім випадків, які
                      передбачені в п. 3.2., 4.4., 4.5. цього Порядку та/або діючим законодавством
                      України). Так, Товариство реалізує ваше право на видалення тією мірою, у якій
                      це дозволено законом. Якщо існує законне зобов’язання, що переважає ваш запит,
                      Товариство буде дотримуватись вимог закону (це означає, що ми, наприклад, не
                      зможемо задовольнити ваш запит, якщо ми обробляємо ваші персональні дані на
                      підставі правочину або закону).
                    </p>
                    <p>
                      3.7. Відповідно до вимог діючого законодавства України Користувач має право:
                    </p>
                    <ul>
                      <li>
                        знати про джерела збирання, місцезнаходження своїх персональних даних, мету
                        їх обробки, місцезнаходження або місце перебування Володільця чи
                        розпорядника персональних даних або дати відповідне доручення щодо отримання
                        цієї інформації уповноваженим ним особам, крім випадків, встановлених
                        законом;
                      </li>
                      <li>
                        отримувати інформацію про умови надання доступу до персональних даних,
                        зокрема інформацію про третіх осіб, яким передаються його персональні дані;
                      </li>
                      <li>на доступ до своїх персональних даних;</li>
                      <li>
                        отримувати не пізніш як за тридцять календарних днів з дня надходження
                        запиту, крім випадків, передбачених законом, відповідь про те, чи
                        обробляються його персональні дані, а також отримувати зміст таких
                        персональних даних;
                      </li>
                      <li>
                        на захист своїх персональних даних від незаконної обробки та випадкової
                        втрати, знищення, пошкодження у зв&apos;язку з умисним приховуванням,
                        ненаданням чи несвоєчасним їх наданням, а також на захист від надання
                        відомостей, що є недостовірними чи ганьблять честь, гідність та ділову
                        репутацію фізичної особи;
                      </li>
                      <li>
                        застосовувати засоби правового захисту в разі порушення законодавства про
                        захист персональних даних;
                      </li>
                      <li>
                        користуватися іншими правами, які передбачені діючим законодавством України.
                      </li>
                    </ul>
                    <b>4. Обмін інформацією з третіми особами</b>
                    <p>
                      4.1. Товариство може використовувати сторонніх постачальників послуг для
                      надання деяких складових своїх послуг. В таких випадках постачальники не мають
                      повноважень використовувати отримані в результаті надання послуг або за
                      допомогою сервісів Товариства персональні дані інакше, ніж для надання послуг
                      Товариству або Користувачам, а самі персональні дані є предметом цього
                      Порядку. Постачальники послуг, які можуть обробляти персональні дані
                      Користувачів, є довіреними партнерами Товариства, які надають послуги сервера,
                      хостингу, розсилки, маркетингу та обслуговування клієнтів, бухгалтерського
                      обліку, тощо.
                    </p>
                    <p>
                      4.2. Товариство може здійснювати обмін даними з афілійованими особами
                      Товариства (компанії, які діють на підставі спільної власності), які можуть
                      обробляти та використовувати дані з метою, визначеною цим Порядком.
                    </p>
                    <p>
                      4.3. Товариство зобов’язане відповідно до вимог законодавства надавати
                      інформацію державним органам в наступних цілях:
                    </p>
                    <ul>
                      <li>боротьба з шахрайством та іншими злочинами;</li>
                      <li>
                        знаходженням особи в санкційних списках (у т.ч. у зв’язку з обмеженнями, які
                        пов’язані з військовою агресією проти України, та/або фінансовим
                        моніторингом, тощо);
                      </li>
                      <li>
                        розслідування порушень чинного законодавства України або боротьби з
                        будь-якими іншими порушеннями положень Правил надання послуг.
                      </li>
                    </ul>
                    <p>
                      4.4. У випадках, прямо передбачених кримінально-процесуальним законодавством
                      України та/або податковим законодавством (питання у сфері оподаткування),
                      Товариство може надавати певні персональні дані та/або іншу інформацію про
                      Користувачів (наприклад, про вартість отриманої протягом звітного періоду
                      винагороди, сплачених податків, якщо операції здійснювались Товариством, як
                      податковим агентом) на запити компетентних державних органів, оформлених
                      відповідно до вимог чинного законодавства.
                    </p>
                    <p>
                      4.5. Товариство може передавати певну знеособлену інформацію, а також надавати
                      дозвіл на збір такої інформації безпосередньо на Платформі за допомогою
                      відповідних технологій (дані, які не дозволяють ідентифікувати Користувачів
                      окремо) стороннім постачальникам послуг, довіреним партнерам, статистичним
                      службам або авторизованим дослідникам з метою проведення маркетингових
                      досліджень, аналітичної діяльності, статистичної інформації, поліпшення
                      загальної якості та ефективності роботи Платформи, тощо.
                    </p>
                    <b>5. Внесення змін до цього Порядку</b>
                    <p>
                      5.1. Товариство може час від часу змінювати або оновлювати цей Порядок, при
                      цьому нова редакція Порядку вступає в силу з моменту її розміщення в мережі
                      Інтернет на веб-сайті Платформи, додатках (сервісах), і буде обов’язковою для
                      Товариства та суб’єктів персональних даних з дати такої публікації. Чинна
                      редакція Порядку обробки персональних даних знаходиться на сторінці за адресою{' '}
                      <a href="https://expertland.ua/policy">https://expertland.ua/policy</a>.
                    </p>
                    <p>
                      5.2. Факт продовження користування послугами Товариства та його Платформою є
                      підтвердженням згоди і прийняття Користувачем відповідної редакції Порядку
                      обробки персональних даних.
                    </p>
                    <p>
                      5.3. Пункт 5.1. та 5.2. Порядку в частині автоматичної обов’язковості умов для
                      Користувачів не застосовується у разі зміни визначеної мети обробки
                      персональних даних на нову мету, яка є несумісною з попередньою. У такому
                      випадку для подальшої обробки персональних даних Товариство додатково отримає
                      згоду усіх суб’єктів персональних даних на обробку їх персональних даних
                      відповідно до зміненої мети.
                    </p>
                    <p>
                      5.4. Володілець персональних даних не несе відповідальності за шкоду або
                      збитки, яких зазнав суб’єкт персональних даних або треті особи в результаті
                      помилкового розуміння або нерозуміння умов цього Порядку.
                    </p>
                    <p>
                      5.5. Якщо у вас виникнуть інші запитання щодо обробки ваших персональних
                      даних, ви можете звернутися до нас за будь-яким каналом зв’язку, який
                      зазначено на офіційному сайті Товариства (Платформи).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Policy;
