import { createSlice } from '@reduxjs/toolkit';

import { projectActions } from '../actions';

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    projectMainWaiter: true,
    projectTypes: [],
    projectDurations: [],
    projectExpertCategories: [],
    projectCompanyCategories: [],
    langs: [],
    expertList: [],
    waiter: false,
    waiterPag: false,
    errors: false,
    fixLoader: false,
    currentProject: {
      name: '',
      businessCategories: [],
      description: '',
      languages: [],
      expertThreeCategories: [],
      expertThreeLevel2: [],
      expertThreeLevel1: [],
      invitations: [],
      projectDuration: 1,
      projectType: 0,
      screeningQuestions: [''],
      transmittalLetter: 0,
      budget: '',
      doNotShowPayment: '',
    },
    expertCurrent: null,
    showChooseExpertModal: false,
    myProjectCompany: [],
    myProjectCompanyPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    myProjectCompanyDone: [],
    myProjectCompanyDonePag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    myProjectCompanyWaiter: false,
    myProjectCompanyWaiterMore: false,
    myProjectCompanyDoneWaiter: false,
    myProjectCompanyDoneWaiterMore: false,
    myProjectDetail: null,
    myProjectDetailWaiter: false,
    myProjectDetailWaiterMore: false,
    invitedExperts: [],
    invitedExpertsPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    projectCandidates: [],
    projectCandidatesPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    projectFavoriteCandidates: [],
    projectFavoriteCandidatesPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    projectRejectedCandidates: [],
    projectRejectedCandidatesPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    projects: [],
    projectsPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    getProjectsWaiter: false,
    getProjectsMoreWaiter: false,
    projectDetail: null,
    expertDetail: null,
    experts: [],
    expertsPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    getExpertsWaiter: false,
    getExpertsMoreWaiter: false,
    lowestCategoryNotFound: false,
    fixWaiter: false,
    companyDetail: null,
    favoritesExp: [],
    favoritesProjects: [],
    favoritesWaiter: false,
    favoritesPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    rejectedExp: [],
    rejectedProjects: [],
    rejectedWaiter: false,
    rejectedPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    activeProject: [],
    reviewInfo: false,
    reviewInfoWaiter: false,
  },
  reducers: {
    addCurrentProject(state, action) {
      state.currentProject = action.payload;
    },
    setExpertCurrent(state, action) {
      state.expertCurrent = action.payload;
    },
    setShowChooseExpertModal(state, action) {
      state.showChooseExpertModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // getProjectTypes
      .addCase(projectActions.getProjectTypes.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getProjectTypes.fulfilled, (state, action) => {
        state.waiter = false;
        state.projectTypes = action.payload.data;
        state.errors = false;
      })
      .addCase(projectActions.getProjectTypes.rejected, (state) => {
        state.waiter = false;
      })

      // getExpertCategoriesFull
      .addCase(projectActions.getExpertCategoriesFull.pending, (state) => {
        state.projectMainWaiter = true;
      })
      .addCase(projectActions.getExpertCategoriesFull.fulfilled, (state, action) => {
        state.projectExpertCategories = action.payload.data;
        state.projectMainWaiter = false;
      })
      .addCase(projectActions.getExpertCategoriesFull.rejected, (state) => {
        state.projectMainWaiter = false;
      })

      // getProjectDurations
      .addCase(projectActions.getProjectDurations.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getProjectDurations.fulfilled, (state, action) => {
        state.waiter = false;
        state.projectDurations = action.payload.data;
        state.errors = false;
      })
      .addCase(projectActions.getProjectDurations.rejected, (state) => {
        state.waiter = false;
      })

      // getCompanyCategories
      .addCase(projectActions.getCompanyCategories.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getCompanyCategories.fulfilled, (state, action) => {
        state.waiter = false;
        state.projectCompanyCategories = action.payload.data;
        state.errors = false;
      })
      .addCase(projectActions.getCompanyCategories.rejected, (state) => {
        state.waiter = false;
      })

      // getLangs
      .addCase(projectActions.getLangs.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getLangs.fulfilled, (state, action) => {
        state.waiter = false;
        state.langs = action.payload.data;
        state.errors = false;
      })
      .addCase(projectActions.getLangs.rejected, (state) => {
        state.waiter = false;
      })

      // getExpertList
      .addCase(projectActions.getExpertList.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getExpertList.fulfilled, (state, action) => {
        state.waiter = false;
        state.expertList = action.payload.data;
        state.errors = false;
      })
      .addCase(projectActions.getExpertList.rejected, (state) => {
        state.waiter = false;
      })

      // createProject
      .addCase(projectActions.createProject.pending, (state) => {
        state.fixLoader = true;
        state.errors = false;
      })
      .addCase(projectActions.createProject.fulfilled, (state) => {
        state.fixLoader = false;
        state.errors = false;
      })
      .addCase(projectActions.createProject.rejected, (state) => {
        state.fixLoader = false;
      })

      // currentProjectsList
      .addCase(projectActions.currentProjectsList.pending, (state) => {
        state.myProjectCompanyWaiter = true;
        state.myProjectCompany = [];
        state.errors = false;
      })
      .addCase(projectActions.currentProjectsList.fulfilled, (state, action) => {
        state.myProjectCompany = action.payload.data.data;
        state.myProjectCompanyPag = action.payload.data.meta;
        state.myProjectCompanyWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.currentProjectsList.rejected, (state) => {
        state.myProjectCompanyWaiter = false;
      })

      // currentProjectsListMore
      .addCase(projectActions.currentProjectsListMore.pending, (state) => {
        state.myProjectCompanyWaiterMore = true;
        state.errors = false;
      })
      .addCase(projectActions.currentProjectsListMore.fulfilled, (state, action) => {
        state.myProjectCompany = [...state.myProjectCompany, ...action.payload.data.data];
        state.myProjectCompanyPag = action.payload.data.meta;
        state.myProjectCompanyWaiterMore = false;
        state.errors = false;
      })
      .addCase(projectActions.currentProjectsListMore.rejected, (state) => {
        state.myProjectCompanyWaiterMore = false;
      })

      // currentProjectsListCompany
      .addCase(projectActions.currentProjectsListCompany.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(projectActions.currentProjectsListCompany.fulfilled, (state, action) => {
        const current =
          action.payload.data.meta.current_page !== 1
            ? [...state.myProjectCompany, ...action.payload.data.data]
            : [...action.payload.data.data];

        state.waiter = false;
        state.errors = false;
        state.myProjectCompany = current;
        state.myProjectCompanyPag = action.payload.data.meta;
      })
      .addCase(projectActions.currentProjectsListCompany.rejected, (state) => {
        state.waiter = false;
      })

      // currentProjectsListCompanyMore
      .addCase(projectActions.currentProjectsListCompanyMore.pending, (state) => {
        state.waiterPag = true;
        state.errors = false;
      })
      .addCase(projectActions.currentProjectsListCompanyMore.fulfilled, (state, action) => {
        const current = [...state.myProjectCompany, ...action.payload.data.data];

        state.waiterPag = false;
        state.errors = false;
        state.myProjectCompany = current;
        state.myProjectCompanyPag = action.payload.data.meta;
      })
      .addCase(projectActions.currentProjectsListCompanyMore.rejected, (state) => {
        state.waiterPag = false;
      })

      // completedProjectsList
      .addCase(projectActions.completedProjectsList.pending, (state) => {
        state.myProjectCompanyDoneWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.completedProjectsList.fulfilled, (state, action) => {
        const current =
          action.payload.data.meta.current_page !== 1
            ? [...state.myProjectCompanyDone, ...action.payload.data.data]
            : [...action.payload.data.data];

        state.myProjectCompanyDoneWaiter = false;
        state.errors = false;
        state.myProjectCompanyDone = current;
        state.myProjectCompanyDonePag = action.payload.data.meta;
      })
      .addCase(projectActions.completedProjectsList.rejected, (state) => {
        state.myProjectCompanyDoneWaiter = false;
      })

      // completedProjectsListMore
      .addCase(projectActions.completedProjectsListMore.pending, (state) => {
        state.myProjectCompanyDoneWaiterMore = true;
        state.errors = false;
      })
      .addCase(projectActions.completedProjectsListMore.fulfilled, (state, action) => {
        const current = [...state.myProjectCompanyDone, ...action.payload.data.data];

        state.errors = false;
        state.myProjectCompanyDone = current;
        state.myProjectCompanyDonePag = action.payload.data.meta;
        state.myProjectCompanyDoneWaiterMore = false;
      })
      .addCase(projectActions.completedProjectsListMore.rejected, (state) => {
        state.myProjectCompanyDoneWaiterMore = false;
      })

      // getProjects
      .addCase(projectActions.getProjects.pending, (state) => {
        state.getProjectsWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getProjects.fulfilled, (state, action) => {
        state.projects = action.payload.data.data;
        state.projectsPag = action.payload.data.meta;
        state.getProjectsWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.getProjects.rejected, (state) => {
        state.getProjectsWaiter = false;
      })

      // getProjectsMore
      .addCase(projectActions.getProjectsMore.pending, (state) => {
        state.getProjectsMoreWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getProjectsMore.fulfilled, (state, action) => {
        state.getProjectsMoreWaiter = false;
        state.errors = false;

        state.projects = [...state.projects, ...action.payload.data.data];
        state.projectsPag = action.payload.data.meta;
      })
      .addCase(projectActions.getProjectsMore.rejected, (state) => {
        state.getProjectsMoreWaiter = false;
      })

      // projectReject
      .addCase(projectActions.projectReject.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.projectReject.fulfilled, (state, action) => {
        state.projects = state.projects.filter((item) => item.id !== action.meta.arg.project);
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.projectReject.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectRejectInFavoritePage
      .addCase(projectActions.projectRejectInFavoritePage.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.projectRejectInFavoritePage.fulfilled, (state, action) => {
        state.favoritesProjects = state.favoritesProjects.filter(
          (item) => item.id !== action.meta.arg.project,
        );
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.projectRejectInFavoritePage.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectRejectInRejectedPage
      .addCase(projectActions.projectRejectInRejectedPage.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.projectRejectInRejectedPage.fulfilled, (state, action) => {
        state.rejectedProjects = state.rejectedProjects.filter(
          (item) => item.id !== action.meta.arg.project,
        );
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.projectRejectInRejectedPage.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectRejectInCompany
      .addCase(projectActions.projectRejectInCompany.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.projectRejectInCompany.fulfilled, (state, action) => {
        state.myProjectCompany = state.myProjectCompany.map((item) => {
          if (item.id === action.meta.arg.project) {
            return { ...item, rejected: !item.rejected, favorite: false };
          }
          return item;
        });
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.projectRejectInCompany.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectFavorite
      .addCase(projectActions.projectFavorite.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.projectFavorite.fulfilled, (state, action) => {
        state.projects = state.projects.map((item) => {
          if (item.id === action.meta.arg.project) {
            return { ...item, favorite: !item.favorite, rejected: false };
          }
          return item;
        });
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.projectFavorite.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectFavoriteInFavoritePage
      .addCase(projectActions.projectFavoriteInFavoritePage.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.projectFavoriteInFavoritePage.fulfilled, (state, action) => {
        state.favoritesProjects = state.favoritesProjects.filter(
          (item) => item.id !== action.meta.arg.project,
        );
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.projectFavoriteInFavoritePage.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectFavoriteInRejectedPage
      .addCase(projectActions.projectFavoriteInRejectedPage.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.projectFavoriteInRejectedPage.fulfilled, (state, action) => {
        state.rejectedProjects = state.rejectedProjects.filter(
          (item) => item.id !== action.meta.arg.project,
        );
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.projectFavoriteInRejectedPage.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectFavoriteInCompany
      .addCase(projectActions.projectFavoriteInCompany.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.projectFavoriteInCompany.fulfilled, (state, action) => {
        state.myProjectCompany = state.myProjectCompany.map((item) => {
          if (item.id === action.meta.arg.project) {
            return { ...item, favorite: !item.favorite, rejected: false };
          }
          return item;
        });
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.projectFavoriteInCompany.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getProjectsById
      .addCase(projectActions.getProjectsById.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
        state.projectDetail = null;
      })
      .addCase(projectActions.getProjectsById.fulfilled, (state, action) => {
        state.projectDetail = action.payload.data;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.getProjectsById.rejected, (state) => {
        state.projectDetail = null;
        state.fixWaiter = false;
      })

      // projectSend
      .addCase(projectActions.projectSend.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.projectSend.fulfilled, (state, action) => {
        state.projects = state.projects.map((item) => {
          if (item.id === Number(action.meta.arg.project)) {
            return { ...item, isCandidate: true };
          }
          return item;
        });
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.projectSend.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getProjects
      .addCase(projectActions.getExperts.pending, (state) => {
        state.getExpertsWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getExperts.fulfilled, (state, action) => {
        state.experts = action.payload.data.data;
        state.expertsPag = action.payload.data.meta;
        state.lowestCategoryNotFound = !!action.payload.data.lowestCategoryNotFound;
        state.getExpertsWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.getExperts.rejected, (state) => {
        state.getExpertsWaiter = false;
      })

      // getProjectsMore
      .addCase(projectActions.getExpertsMore.pending, (state) => {
        state.getExpertsMoreWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getExpertsMore.fulfilled, (state, action) => {
        state.experts = [...state.experts, ...action.payload.data.data];
        state.expertsPag = action.payload.data.meta;
        state.getExpertsMoreWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.getExpertsMore.rejected, (state) => {
        state.getExpertsMoreWaiter = false;
      })

      // expertReject
      .addCase(projectActions.expertReject.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.expertReject.fulfilled, (state, action) => {
        state.experts = state.experts.filter((item) => item.id !== action.meta.arg.expert);
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.expertReject.rejected, (state) => {
        state.fixWaiter = false;
      })

      // expertRejectDetail
      .addCase(projectActions.expertRejectDetail.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.expertRejectDetail.fulfilled, (state) => {
        state.expertDetail.rejected = !state.expertDetail.rejected;
        state.expertDetail.favorite = false;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.expertRejectDetail.rejected, (state) => {
        state.fixWaiter = false;
      })

      // expertFavorite
      .addCase(projectActions.expertFavorite.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.expertFavorite.fulfilled, (state, action) => {
        state.experts = state.experts.map((item) => {
          if (item.id === action.meta.arg.expert) {
            return { ...item, favorite: !item.favorite, rejected: false };
          }
          return item;
        });
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.expertFavorite.rejected, (state) => {
        state.fixWaiter = false;
      })

      // expertFavoriteDetail
      .addCase(projectActions.expertFavoriteDetail.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.expertFavoriteDetail.fulfilled, (state) => {
        state.expertDetail.favorite = !state.expertDetail.favorite;
        state.expertDetail.rejected = false;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.expertFavoriteDetail.rejected, (state) => {
        state.fixWaiter = false;
      })

      // expertRejectInRejectedPage
      .addCase(projectActions.expertRejectInRejectedPage.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.expertRejectInRejectedPage.fulfilled, (state, action) => {
        state.rejectedExp = state.rejectedExp.filter((item) => item.id !== action.meta.arg.expert);
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.expertRejectInRejectedPage.rejected, (state) => {
        state.fixWaiter = false;
      })

      // expertFavoriteInRejectedPage
      .addCase(projectActions.expertFavoriteInRejectedPage.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.expertFavoriteInRejectedPage.fulfilled, (state, action) => {
        state.rejectedExp = state.rejectedExp.filter((item) => item.id !== action.meta.arg.expert);
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.expertFavoriteInRejectedPage.rejected, (state) => {
        state.fixWaiter = false;
      })

      // expertRejectInFavoritePage
      .addCase(projectActions.expertRejectInFavoritePage.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.expertRejectInFavoritePage.fulfilled, (state, action) => {
        state.favoritesExp = state.favoritesExp.filter(
          (item) => item.id !== action.meta.arg.expert,
        );
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.expertRejectInFavoritePage.rejected, (state) => {
        state.fixWaiter = false;
      })

      // expertFavoriteInFavoritePage
      .addCase(projectActions.expertFavoriteInFavoritePage.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(projectActions.expertFavoriteInFavoritePage.fulfilled, (state, action) => {
        state.favoritesExp = state.favoritesExp.filter(
          (item) => item.id !== action.meta.arg.expert,
        );
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(projectActions.expertFavoriteInFavoritePage.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getExpertsById
      .addCase(projectActions.getExpertsById.pending, (state) => {
        state.waiter = true;
        state.errors = false;
        state.expertDetail = null;
      })
      .addCase(projectActions.getExpertsById.fulfilled, (state, action) => {
        state.expertDetail = action.payload.data;
        state.waiter = false;
        state.errors = false;
      })
      .addCase(projectActions.getExpertsById.rejected, (state) => {
        state.waiter = false;
      })

      // getActiveProjects
      .addCase(projectActions.getActiveProjects.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getActiveProjects.fulfilled, (state, action) => {
        state.activeProject = action.payload.data;
        state.waiter = false;
        state.errors = false;
      })
      .addCase(projectActions.getActiveProjects.rejected, (state) => {
        state.waiter = false;
      })

      // getCompany
      .addCase(projectActions.getCompany.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(projectActions.getCompany.fulfilled, (state, action) => {
        state.companyDetail = action.payload.data;
        state.waiter = false;
        state.errors = false;
      })
      .addCase(projectActions.getCompany.rejected, (state) => {
        state.waiter = false;
      })

      // getExpert
      .addCase(projectActions.getExpert.pending, (state) => {
        state.waiter = true;
        state.errors = false;
        state.expertDetail = null;
      })
      .addCase(projectActions.getExpert.fulfilled, (state, action) => {
        state.expertDetail = action.payload.data;
        state.waiter = false;
        state.errors = false;
      })
      .addCase(projectActions.getExpert.rejected, (state) => {
        state.waiter = false;
      })

      // getFavorites
      .addCase(projectActions.getFavorites.pending, (state) => {
        state.favoritesWaiter = true;
      })
      .addCase(projectActions.getFavorites.fulfilled, (state, action) => {
        state.favoritesProjects = action.payload.data.data;
        state.favoritesPag = action.payload.data.meta;
        state.favoritesWaiter = false;
      })
      .addCase(projectActions.getFavorites.rejected, (state) => {
        state.favoritesWaiter = false;
      })

      // getRejected
      .addCase(projectActions.getRejected.pending, (state) => {
        state.rejectedWaiter = true;
      })
      .addCase(projectActions.getRejected.fulfilled, (state, action) => {
        state.rejectedProjects = action.payload.data.data;
        state.rejectedPag = action.payload.data.meta;
        state.rejectedWaiter = false;
      })
      .addCase(projectActions.getRejected.rejected, (state) => {
        state.rejectedWaiter = false;
      })

      // getFavoritesExperts
      .addCase(projectActions.getFavoritesExperts.pending, (state) => {
        state.favoritesWaiter = true;
      })
      .addCase(projectActions.getFavoritesExperts.fulfilled, (state, action) => {
        state.favoritesExp = action.payload.data.data;
        state.favoritesPag = action.payload.data.meta;
        state.favoritesWaiter = false;
      })
      .addCase(projectActions.getFavoritesExperts.rejected, (state) => {
        state.favoritesWaiter = false;
      })

      // getRejectedExperts
      .addCase(projectActions.getRejectedExperts.pending, (state) => {
        state.rejectedWaiter = true;
      })
      .addCase(projectActions.getRejectedExperts.fulfilled, (state, action) => {
        state.rejectedExp = action.payload.data.data;
        state.rejectedPag = action.payload.data.meta;
        state.rejectedWaiter = false;
      })
      .addCase(projectActions.getRejectedExperts.rejected, (state) => {
        state.rejectedWaiter = false;
      })

      // projectInvite
      .addCase(projectActions.projectInvite.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.projectInvite.fulfilled, (state) => {
        state.fixWaiter = false;
      })
      .addCase(projectActions.projectInvite.rejected, (state) => {
        state.fixWaiter = false;
      })

      // deleteProject
      .addCase(projectActions.deleteProject.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.deleteProject.fulfilled, (state) => {
        state.fixWaiter = false;
      })
      .addCase(projectActions.deleteProject.rejected, (state) => {
        state.fixWaiter = false;
      })

      // deleteProject
      .addCase(projectActions.getProject.pending, (state) => {
        state.waiter = true;
      })
      .addCase(projectActions.getProject.fulfilled, (state, action) => {
        state.myProjectDetail = action.payload.data;
        state.waiter = false;
      })
      .addCase(projectActions.getProject.rejected, (state) => {
        state.waiter = false;
      })

      // getInvitedExperts
      .addCase(projectActions.getInvitedExperts.pending, (state) => {
        state.myProjectDetailWaiter = true;
        state.invitedExperts = [];
      })
      .addCase(projectActions.getInvitedExperts.fulfilled, (state, action) => {
        state.invitedExperts = action.payload.data.data;
        state.invitedExpertsPag = action.payload.data.meta;
        state.myProjectDetailWaiter = false;
      })
      .addCase(projectActions.getInvitedExperts.rejected, (state) => {
        state.myProjectDetailWaiter = false;
      })

      // getInvitedExpertsMore
      .addCase(projectActions.getInvitedExpertsMore.pending, (state) => {
        state.myProjectDetailWaiterMore = true;
      })
      .addCase(projectActions.getInvitedExpertsMore.fulfilled, (state, action) => {
        state.invitedExperts = [...state.invitedExperts, ...action.payload.data.data];
        state.invitedExpertsPag = action.payload.data.meta;
        state.myProjectDetailWaiterMore = false;
      })
      .addCase(projectActions.getInvitedExpertsMore.rejected, (state) => {
        state.myProjectDetailWaiterMore = false;
      })

      // getProjectCandidates
      .addCase(projectActions.getProjectCandidates.pending, (state) => {
        state.myProjectDetailWaiter = true;
      })
      .addCase(projectActions.getProjectCandidates.fulfilled, (state, action) => {
        state.projectCandidates = action.payload.data.data;
        state.projectCandidatesPag = action.payload.data.meta;
        state.myProjectDetailWaiter = false;
      })
      .addCase(projectActions.getProjectCandidates.rejected, (state) => {
        state.myProjectDetailWaiter = false;
      })

      // getProjectCandidatesMore
      .addCase(projectActions.getProjectCandidatesMore.pending, (state) => {
        state.myProjectDetailWaiterMore = true;
      })
      .addCase(projectActions.getProjectCandidatesMore.fulfilled, (state, action) => {
        state.projectCandidates = [...state.projectCandidates, ...action.payload.data.data];
        state.projectCandidatesPag = action.payload.data.meta;
        state.myProjectDetailWaiterMore = false;
      })
      .addCase(projectActions.getProjectCandidatesMore.rejected, (state) => {
        state.myProjectDetailWaiterMore = false;
      })

      // getProjectFavoriteCandidates
      .addCase(projectActions.getProjectFavoriteCandidates.pending, (state) => {
        state.myProjectDetailWaiter = true;
      })
      .addCase(projectActions.getProjectFavoriteCandidates.fulfilled, (state, action) => {
        state.projectFavoriteCandidates = action.payload.data.data;
        state.projectFavoriteCandidatesPag = action.payload.data.meta;
        state.myProjectDetailWaiter = false;
      })
      .addCase(projectActions.getProjectFavoriteCandidates.rejected, (state) => {
        state.myProjectDetailWaiter = false;
      })

      // getProjectFavoriteCandidatesMore
      .addCase(projectActions.getProjectFavoriteCandidatesMore.pending, (state) => {
        state.myProjectDetailWaiter = true;
      })
      .addCase(projectActions.getProjectFavoriteCandidatesMore.fulfilled, (state, action) => {
        state.projectFavoriteCandidates = [
          ...state.projectFavoriteCandidates,
          ...action.payload.data.data,
        ];
        state.projectFavoriteCandidatesPag = action.payload.data.meta;
        state.myProjectDetailWaiter = false;
      })
      .addCase(projectActions.getProjectFavoriteCandidatesMore.rejected, (state) => {
        state.myProjectDetailWaiter = false;
      })

      // getProjectRejectedCandidates
      .addCase(projectActions.getProjectRejectedCandidates.pending, (state) => {
        state.myProjectDetailWaiter = true;
      })
      .addCase(projectActions.getProjectRejectedCandidates.fulfilled, (state, action) => {
        state.projectRejectedCandidates = action.payload.data.data;
        state.projectRejectedCandidatesPag = action.payload.data.meta;
        state.myProjectDetailWaiter = false;
      })
      .addCase(projectActions.getProjectRejectedCandidates.rejected, (state) => {
        state.myProjectDetailWaiter = false;
      })

      // getProjectRejectedCandidatesMore
      .addCase(projectActions.getProjectRejectedCandidatesMore.pending, (state) => {
        state.myProjectDetailWaiter = true;
      })
      .addCase(projectActions.getProjectRejectedCandidatesMore.fulfilled, (state, action) => {
        state.projectRejectedCandidates = [
          ...state.projectRejectedCandidates,
          ...action.payload.data.data,
        ];
        state.projectRejectedCandidatesPag = action.payload.data.meta;
        state.myProjectDetailWaiter = false;
      })
      .addCase(projectActions.getProjectRejectedCandidatesMore.rejected, (state) => {
        state.myProjectDetailWaiter = false;
      })

      // chooseExecutor
      .addCase(projectActions.chooseExecutor.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.chooseExecutor.fulfilled, (state, action) => {
        state.myProjectDetail = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(projectActions.chooseExecutor.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectDetailRejectExpert
      .addCase(projectActions.projectDetailRejectExpert.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.projectDetailRejectExpert.fulfilled, (state, action) => {
        const { tab } = action.meta.arg;
        const expertId = action.meta.arg.expert;
        switch (tab) {
          case 1:
            state.projectCandidates = state.projectCandidates.filter(
              (item) => item.expert.id !== expertId,
            );
            break;

          case 2:
            state.projectFavoriteCandidates = state.projectFavoriteCandidates.filter(
              (item) => item.expert.id !== expertId,
            );
            break;
          case 3:
            state.projectRejectedCandidates = state.projectRejectedCandidates.filter(
              (item) => item.expert.id !== expertId,
            );
            break;
          case 4:
            state.invitedExperts = state.invitedExperts.filter(
              (item) => item.expert.id !== expertId,
            );
            break;
          default:
            break;
        }
        state.fixWaiter = false;
      })
      .addCase(projectActions.projectDetailRejectExpert.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectDetailFavoriteExpert
      .addCase(projectActions.projectDetailFavoriteExpert.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.projectDetailFavoriteExpert.fulfilled, (state, action) => {
        const { tab } = action.meta.arg;
        const expertId = action.meta.arg.expert;
        switch (tab) {
          case 1:
            state.projectCandidates = state.projectCandidates.map((item) => {
              if (item.expert.id === expertId) {
                return { ...item, isLiked: !item.isLiked };
              }
              return item;
            });
            break;

          case 2:
            state.projectFavoriteCandidates = state.projectFavoriteCandidates.map((item) => {
              if (item.expert.id === expertId) {
                return { ...item, isLiked: !item.isLiked };
              }
              return item;
            });
            break;
          case 3:
            state.projectRejectedCandidates = state.projectRejectedCandidates.map((item) => {
              if (item.expert.id === expertId) {
                return { ...item, isLiked: !item.isLiked };
              }
              return item;
            });
            break;
          case 4:
            state.invitedExperts = state.invitedExperts.map((item) => {
              if (item.expert.id === expertId) {
                return { ...item, isLiked: !item.isLiked };
              }
              return item;
            });
            break;
          default:
            break;
        }
        state.fixWaiter = false;
      })
      .addCase(projectActions.projectDetailFavoriteExpert.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectRework
      .addCase(projectActions.projectRework.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.projectRework.fulfilled, (state) => {
        state.myProjectDetail.status = 4;
        state.fixWaiter = false;
      })
      .addCase(projectActions.projectRework.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectSubmit
      .addCase(projectActions.projectSubmit.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.projectSubmit.fulfilled, (state) => {
        state.myProjectDetail.status = 6;
        state.fixWaiter = false;
      })
      .addCase(projectActions.projectSubmit.rejected, (state) => {
        state.fixWaiter = false;
      })

      // cancelExecutor
      .addCase(projectActions.cancelExecutor.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.cancelExecutor.fulfilled, (state) => {
        state.myProjectDetail.status = 2;
        state.fixWaiter = false;
      })
      .addCase(projectActions.cancelExecutor.rejected, (state) => {
        state.fixWaiter = false;
      })

      // projectSendPay
      .addCase(projectActions.projectSendPay.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.projectSendPay.fulfilled, (state) => {
        state.myProjectDetail.isCompanySentPay = true;
        state.fixWaiter = false;
      })
      .addCase(projectActions.projectSendPay.rejected, (state) => {
        state.fixWaiter = false;
      })

      // reviewProjectInfo
      .addCase(projectActions.reviewProjectInfo.pending, (state) => {
        state.reviewInfoWaiter = true;
        state.reviewInfo = false;
      })
      .addCase(projectActions.reviewProjectInfo.fulfilled, (state, action) => {
        state.reviewInfoWaiter = false;
        state.reviewInfo = action.payload.data;
      })
      .addCase(projectActions.reviewProjectInfo.rejected, (state) => {
        state.reviewInfoWaiter = false;
        state.reviewInfo = false;
      })

      // reviewProjectCompany
      .addCase(projectActions.reviewProjectCompany.pending, (state) => {
        state.reviewInfoWaiter = true;
      })
      .addCase(projectActions.reviewProjectCompany.fulfilled, (state) => {
        state.myProjectDetail.companyLeftReview = true;
        if (state.myProjectDetail.expertLeftReview) {
          state.myProjectDetail.status = 8;
        }

        state.reviewInfoWaiter = false;
      })
      .addCase(projectActions.reviewProjectCompany.rejected, (state) => {
        state.reviewInfoWaiter = false;
      })

      // createChat
      .addCase(projectActions.createChat.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.createChat.fulfilled, (state) => {
        state.fixWaiter = false;
      })
      .addCase(projectActions.createChat.rejected, (state) => {
        state.fixWaiter = false;
      })

      // proposeOtherConditions
      .addCase(projectActions.proposeOtherConditions.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(projectActions.proposeOtherConditions.fulfilled, (state, action) => {
        state.myProjectDetail = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(projectActions.proposeOtherConditions.rejected, (state) => {
        state.fixWaiter = false;
      });
  },
});

export const { addCurrentProject, setExpertCurrent, setShowChooseExpertModal } =
  projectSlice.actions;
export default projectSlice.reducer;
