import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { getNewData } from 'src/helpers';

const ProjectsTable = ({ projects }) => (
  <div className="knowledge_table_warp">
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>Назва</th>
          <th>Автор</th>
          <th>Статус</th>
          <th>Чат</th>
          <th>Дата</th>
          <th>Схований</th>
          <th>Відгук від експерта</th>
          <th>Відгук від компанії</th>
          <th>Дії</th>
        </tr>
      </thead>
      <tbody className="admin_knowledge_action_btn">
        {projects &&
          projects.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.executor ? item.executor.profileName : 'Немає акаунту експерта'}</td>
              <td>{item.status}</td>
              <td>{item.chatIsActive ? 'Активный' : 'Неактивный'}</td>
              <td>{getNewData(item.createdAt)}</td>
              <td>{item.hidden ? 'Да' : 'Нет'}</td>
              <td>{item.expertLeftReview ? 'Есть' : 'Нет'}</td>
              <td>{item.companyLeftReview ? 'Есть' : 'Нет'}</td>
              <td>
                <Link
                  to={`/admin/projects/${item.id}/info`}
                  type="button"
                  className="btn btn-primary"
                >
                  Редагувати
                </Link>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

ProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default ProjectsTable;
