import { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import phoneDefault from 'src/assets/img/about/about-fon.png';
import avatarDefault from 'src/assets/img/avatar-default.png';
import { AvatarUpload, CoverChoose, CoverUpload } from 'src/components';
import { useTranslation } from 'src/hooks';

const PhotoBlock = () => {
  const { translate } = useTranslation();
  const { role } = useSelector((state) => state.authReducer.user);

  const { avatar, profileCover } = useSelector((state) =>
    role === 1 ? state.authReducer.user.expert : state.authReducer.user.company,
  );
  const actionsPhoto = [
    {
      value: 0,
      label: translate('About.shablon'),
    },
    {
      value: 1,
      label: translate('About.uploadImg'),
    },
  ];

  const [showAvatar, setShowAvatar] = useState(false);
  const handleCloseAvatar = () => setShowAvatar(false);
  const handleShowAvatar = () => setShowAvatar(true);

  const [showCover, setShowCover] = useState(false);
  const handleCloseCover = () => setShowCover(false);
  const handleShowCover = () => setShowCover(true);

  const [showCoverShablon, setShowCoverShablon] = useState(false);
  const handleCloseCoverShablon = () => setShowCoverShablon(false);
  const handleShowCoverShablon = () => setShowCoverShablon(true);

  const coverChoose = (val) => {
    if (val) {
      handleShowCover();
    } else {
      handleShowCoverShablon();
    }
  };

  return (
    <>
      <div
        className="exp__about"
        style={{ backgroundImage: `url('${profileCover || phoneDefault}')` }}
      >
        <div
          className="exp__about__img"
          style={{ backgroundImage: `url(${avatar || avatarDefault})` }}
        />
        <button type="button" className="btn btn-primary" onClick={handleShowAvatar}>
          {role === 1 ? translate('About.addLogoExp') : translate('About.addLogoComp')}
        </button>
      </div>
      <div className="about__sett">
        <div className="about__sett__left" />
        <div className="about__sett__right">
          <div className="custom-select--about">
            <Select
              options={actionsPhoto}
              classNamePrefix="react-select"
              className="react-select react-select--no-shadow"
              isSearchable={false}
              placeholder={translate('About.editFon')}
              onChange={(e) => coverChoose(e.value)}
            />
          </div>
        </div>
      </div>

      <AvatarUpload show={showAvatar} handleClose={handleCloseAvatar} />
      <CoverUpload show={showCover} handleClose={handleCloseCover} />
      <CoverChoose show={showCoverShablon} handleClose={handleCloseCoverShablon} />
    </>
  );
};

export default PhotoBlock;
