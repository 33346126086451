import { useSelector } from 'react-redux';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

import Status from '../status';
import Offers from './offers';

const StepWithOffers = () => {
  const { translate } = useTranslation();
  const project = useSelector((state) => state.projectReducer.myProjectDetail);

  return (
    <>
      <div className="proekt__detail--my-project">
        <Status />
        <div className="proekt__detail__flex">
          <div className="left">
            <div className="proekt__content__top">
              <div className="proekt__content__left">
                <div className="proekt__content__person proekt__content__person--list">
                  <div
                    className="proekt__content__avatar"
                    style={{
                      backgroundImage: `url(${project.company.avatar || avatarDefaultComp})`,
                    }}
                  />
                  <div className="proekt__content__person__right">
                    <div className="proekt__content__person__name">{project.name}</div>
                    <div className="proekt__tags">
                      <div className="proekt__tags__item">
                        <span className="">{translate('My-project.publisher')}</span>
                        <span className="tags-point">{project.company.companyName}</span>
                      </div>
                      <div className="proekt__tags__item">
                        <span className="">{translate('My-project.pay')}</span>
                        <span className="tags-point">{project.budget} грн</span>
                      </div>
                      {project.businessCategories &&
                        project.businessCategories.map((itemCat) => (
                          <div className="proekt__tags__item" key={itemCat.id}>
                            <span className="tags-point">{itemCat.name}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="proekt__content__desc">{project.description}</div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="proekt__stat">
              <div className="proekt__stat__status">{translate('My-project.dataPublic')}</div>
              <div className="proekt__stat__item">
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#timeSvg`} />
                </svg>
                {project.created}
              </div>
              <div className="proekt__stat__item">
                <svg width="22" height="16">
                  <use xlinkHref={`${sprite}#viewSvg`} />
                </svg>
                {project.views}
              </div>
            </div>
          </div>
        </div>

        <div className="proekt__content__tags">
          {project.languages.length > 0 &&
            project.languages.map((expCat) => (
              <div className="proekt__content__tags__item" key={expCat.id}>
                {expCat.name}
              </div>
            ))}
        </div>
        <div className="new-check">
          <svg width="14" height="20">
            <use xlinkHref={`${sprite}#new-check`} />
          </svg>
          {translate('Projects.haveAsk')}
        </div>
        <div className="new-check">
          <svg width="14" height="20">
            <use xlinkHref={`${sprite}#new-check`} />
          </svg>
          {translate('Projects.haveLatter')}
        </div>
        <div className="proekt-detail__duration">
          <div className="duration1">{translate('Projects.duration')}</div>
          <div className="duration2">{project.projectDuration.text}</div>
        </div>
      </div>

      <Offers />
    </>
  );
};
export default StepWithOffers;
