import { useSelector } from 'react-redux';
import { FixLoader, Footer, Header, Subheader } from 'src/components';

import { CompanyProfile, ExpertProfile } from './components';

const Profile = () => {
  const authWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const { role } = useSelector((state) => state.authReducer.user);
  return (
    <>
      {authWaiter && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">{role === 1 ? <ExpertProfile /> : <CompanyProfile />}</div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
