import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { Loader, StarRate } from 'src/components';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

const CurrentProject = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.authReducer.user.company.id);
  const avgReviewsRate = useSelector((state) => state.authReducer.user.company.avgReviewsRate);
  const reviewsCount = useSelector((state) => state.authReducer.user.company.reviewsCount);
  const waiter = useSelector((state) => state.projectReducer.myProjectCompanyWaiter);
  const myProjectCompany = useSelector((state) => state.projectReducer.myProjectCompany);
  const projectListPag = useSelector((state) => state.projectReducer.myProjectCompanyPag);
  const waiterPag = useSelector((state) => state.projectReducer.myProjectCompanyWaiterMore);
  const country = useSelector((state) => state.authReducer.user.company.country);
  const city = useSelector((state) => state.authReducer.user.company.city);
  const categories = useSelector((state) => state.authReducer.user.company.categories);

  useEffect(() => {
    dispatch(projectActions.currentProjectsList({ page: 1, companyId: id }));
  }, []);

  const showMore = () => {
    dispatch(
      projectActions.currentProjectsListMore({
        page: projectListPag.current_page + 1,
        companyId: id,
      }),
    );
  };

  return (
    <div className="exp-profile__row">
      <div className="exp-content__title">
        <h3>{translate('Profile.currentProject')}</h3>
      </div>
      {waiter ? (
        <Loader />
      ) : (
        <>
          {myProjectCompany.length ? (
            <div className="company__project-list">
              {myProjectCompany.map((item) => (
                <div className="proekt__list__item" key={item.id}>
                  <div className="proekt__content__top">
                    <div className="proekt__content__left">
                      <div className="proekt__content__person proekt__content__person--list">
                        <div className="proekt__content__person__right">
                          <Link
                            className="proekt__content__person__name"
                            to={`/my-projects/${item.id}`}
                          >
                            {item.name}
                          </Link>
                          <div className="proekt__tags">
                            <div className="proekt__tags__item">
                              <span className="tags-point">
                                {item.budget} {translate('grnPr')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="proekt__rating">
                        <StarRate rate={avgReviewsRate} />

                        <div className="proekt__rating__count">
                          {reviewsCount} {translate('reviews')}
                        </div>
                      </div>

                      <div className="proekt__location">
                        <svg width="14" height="20">
                          <use xlinkHref={`${sprite}#location-current`} />
                        </svg>
                        {`${country && country.name}, `}
                        {city && city.name}
                      </div>
                    </div>
                    <div className="proekt__content__right" />
                  </div>
                  <div className="proekt__content__text">{item.description}</div>
                  <div className="proekt__content__tags">
                    {categories.map((cat) => (
                      <div className="proekt__content__tags__item" key={cat.id}>
                        {cat.name}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="bold">{translate('Profile.currentProjectEmpty')}</div>
          )}
        </>
      )}

      <div className="exp-profile__bot-btn">
        <Link to="/publication" className="btn btn-primary">
          {translate('Profile.currentProjectPublic')}
        </Link>
      </div>
      {!waiter && (
        <>
          {waiterPag ? (
            <Loader />
          ) : (
            <>
              {projectListPag.current_page !== projectListPag.last_page && (
                <div className="d-flex justify-content-center mt-5">
                  <button type="button" className="btn btn-primary" onClick={showMore}>
                    {translate('showMore')}
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CurrentProject;
