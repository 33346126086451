import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { setFromResult } from 'src/store/reducers/otbor.reducer';

const OtborMain = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const waiter = useSelector((state) => state.otborReducer.waiter);
  const errors = useSelector((state) => state.otborReducer.errors);
  const expertCat = useSelector((state) => state.authReducer.user.expert.categoriesTree);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const haveEmail = !!useSelector((state) => state.authReducer.user.email);
  let level2Count = 0;
  expertCat.map((item) => {
    if (item.child) {
      level2Count += item.child.length;
    }
    return false;
  });

  useEffect(() => {
    dispatch(setFromResult(false));
  }, []);

  return (
    <>
      <div className="otbor">
        {waiter ? (
          <Loader />
        ) : (
          <>
            <div className="otbor__title">{translate('otbor.title2')}</div>
            <div className="otbor__text">
              {expertCat.length > 0 && expertCat[0].id === 3 ? (
                <span>{translate('otbor.text2')}</span>
              ) : (
                <span>
                  {translate('otbor.infoMain')}{' '}
                  <button type="button" onClick={handleShow}>
                    {translate('otbor.infoBtn')}
                  </button>
                  {translate('otbor.infoMain2')}
                </span>
              )}
            </div>
            <div className="otbor__btn">
              {expertCat.length > 0 && expertCat[0].id === 3 ? (
                <Link to="/user/portfolio" className="btn btn-primary">
                  {translate('otbor.portBtn')}
                </Link>
              ) : (
                <>
                  {level2Count && haveEmail ? (
                    <Link to="/otbor/test" className="btn btn-primary">
                      {translate('otbor.btn')}
                    </Link>
                  ) : (
                    <div className="otbor-notSpec">
                      {!level2Count && (
                        <Link to="/user/spec/" className="otbor-notSpec__text">
                          {translate('otbor.emptySpec')}
                        </Link>
                      )}
                      {!haveEmail && (
                        <Link to="/user/contacts" className="otbor-notSpec__text">
                          {translate('otbor.emptySpec2')}
                        </Link>
                      )}
                      <div title={errors ? errors.message : translate('otbor.tooltip')}>
                        <button type="button" className="btn btn-primary" disabled>
                          {translate('otbor.btn')}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Modal show={show} onHide={handleClose} className="invite">
        <Modal.Body>
          <div className="exp-modal info-modal">
            <div className="modal-header">
              <h5 className="modal-title">{translate('otbor.rules')}</h5>
              <button type="button" className="modal__close" onClick={handleClose}>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#modalCloseWhite`} />
                </svg>
              </button>
            </div>

            <div
              className="modal-text"
              dangerouslySetInnerHTML={{ __html: translate('otbor.rulesText2') }}
            />
            <div className="modal__otbor-bot">
              {level2Count && haveEmail ? (
                <Link to="/otbor/test" className="btn btn-primary">
                  {translate('otbor.btn2')}
                </Link>
              ) : (
                <div
                  title={`${!level2Count ? translate('otbor.emptySpec') : ''} ${
                    !haveEmail ? translate('otbor.emptySpec2') : ''
                  }`}
                >
                  <button type="button" className="btn btn-primary" disabled>
                    {translate('otbor.btn2')}
                  </button>
                </div>
              )}
              <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
                {translate('otbor.btn2Latter')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtborMain;
