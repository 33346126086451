import { PropTypes } from 'prop-types';
import sprite from 'src/assets/img/sprite.svg';

const StarRate = ({ rate }) => {
  const showRateClass = (min, max, rateVal) => {
    if (rateVal <= min) {
      return 'star-notactive';
    }
    if (rateVal < max) {
      return 'star-half';
    }

    return 'star-active';
  };
  return (
    <>
      <svg width="20px" height="20px">
        <use xlinkHref={`${sprite}#${showRateClass(0, 1, rate)}`} />
      </svg>
      <svg width="20px" height="20px">
        <use xlinkHref={`${sprite}#${showRateClass(1, 2, rate)}`} />
      </svg>
      <svg width="20px" height="20px">
        <use xlinkHref={`${sprite}#${showRateClass(2, 3, rate)}`} />
      </svg>
      <svg width="20px" height="20px">
        <use xlinkHref={`${sprite}#${showRateClass(3, 4, rate)}`} />
      </svg>
      <svg width="20px" height="20px">
        <use xlinkHref={`${sprite}#${showRateClass(4, 5, rate)}`} />
      </svg>
    </>
  );
};
StarRate.propTypes = {
  rate: PropTypes.number.isRequired,
};
export default StarRate;
