import { useFormik } from 'formik';
import { gapi } from 'gapi-script';
import { useEffect, useRef, useState } from 'react';
import GoogleLogin from 'react-google-login';
import ReCAPTCHA from 'react-google-recaptcha';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/old-img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

const capchaId = process.env.REACT_APP_RECAPHA_SITE_KEY;
const googleID = process.env.REACT_APP_GOOGLE_SITE_KEY;

const SignIn = () => {
  const navigate = useNavigate();
  const [passShow, setPassShow] = useState(false);
  const { translate } = useTranslation();
  const errors = useSelector((state) => state.authReducer.errors);
  const recaptchaRef = useRef();

  const dispatch = useDispatch();
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);

  useEffect(() => () => dispatch(clearErrors()), []);

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: googleID });
    });
  });

  const handleSubmit = async (values) => {
    await dispatch(authActions.signIn(values)).then((res) => {
      if (res.payload.status === 200) {
        if (!res.payload.data.user.phone_verified_at) {
          navigate(`/auth/verify-phone/?phone=${values.phone}&resend=1`);
        } else if (!res.payload.data.user.role) {
          navigate(`/auth/choose-role/`);
        } else {
          navigate(`/profile/`);
        }
      }
    });

    recaptchaRef.current?.reset();
  };

  const SignInSchema = Yup.object().shape({
    phone: Yup.string().test(
      'len',
      `${translate('Valid.minimum')} 12 ${translate('Valid.cifr')}`,
      (phone) => {
        if (phone) {
          const phoneNumb = phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
          return phoneNumb.length >= 12;
        }
        return false;
      },
    ),
    password: Yup.string()
      .required(translate('Valid.req'))
      .min(8, `${translate('Valid.pass')}`),
    recaptcha: Yup.string().required(translate('Valid.req')),
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
      recaptcha: '',
    },
    validationSchema: SignInSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const signInGoogle = (response) => {
    if (response.accessToken) {
      dispatch(authActions.signInGoogle({ accessToken: response.accessToken })).then((res) => {
        if (res.payload.status === 200) {
          if (!res.payload.data.user.phone_verified_at) {
            navigate(`/auth/add-phone`);
          } else if (!res.payload.data.user.role) {
            navigate(`/auth/choose-role/`);
          } else if (res.payload.data.user.role === 1 && !res.payload.data.user.expert) {
            navigate('/auth/exp-reg/');
          } else if (res.payload.data.user.role === 2 && !res.payload.data.user.company) {
            navigate('/auth/b-reg/');
          } else {
            navigate(`/profile/`);
          }
        }
      });
    }
  };

  return (
    <div className="auth__wrap">
      <div className="container">
        <h1>{translate('Auth.authTitle')}</h1>
        <div className="auth__desc">{translate('Auth.authSubTitle')}</div>
        <form className="auth__form" id="signInForm" onSubmit={formik.handleSubmit}>
          <Link to="/" className="auth__form__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#close`} />
            </svg>
          </Link>
          <div className="auth__form__title">{translate('Auth.authForm')}</div>
          {errors && errors.phone && <strong className="text-danger">{errors.phone}</strong>}
          {errors && errors.google && <strong className="text-danger">{errors.google}</strong>}
          <div className="input__row">
            <InputMask
              mask="+380-99-999-99-99"
              placeholder="+380"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phone"
              className={formik.errors.phone && formik.touched.phone && 'is-invalid'}
            />
            <div className="invalid-feedback">
              {formik.errors.phone && formik.touched.phone && formik.errors.phone}
            </div>
          </div>
          <div className="input__row input__row--pass">
            <input
              type={passShow ? 'text' : 'password'}
              placeholder={translate('Auth.password')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
              className={formik.errors.password && formik.touched.password && 'is-invalid'}
            />
            <div className="invalid-feedback">
              {formik.errors.password && formik.touched.password && formik.errors.password}
            </div>
            <button
              type="button"
              onClick={() => setPassShow(!passShow)}
              className={passShow ? 'active' : null}
            >
              <svg className="hide">
                <use xlinkHref={`${sprite}#pass-show`} />
              </svg>
              <svg className="show">
                <use xlinkHref={`${sprite}#pass-hide`} />
              </svg>
            </button>
          </div>
          <div className="recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={capchaId}
              onChange={(e) => {
                if (e) {
                  formik.setFieldValue('recaptcha', e);
                } else {
                  formik.setFieldValue('recaptcha', '');
                }
              }}
            />
          </div>
          <br />

          <div className="checkbox">
            <div>
              {translate('Auth.fogotPass')}{' '}
              <Link to="/auth/fogotpass/"> {translate('Auth.restor')} </Link>{' '}
              {translate('Auth.pass')}.
            </div>
          </div>
          <button className="auth__submit" type="submit" disabled={fixWaiter}>
            {translate('Auth.enter')}
          </button>
          <div className="auth__form__text auth__form__text--right">
            {translate('Auth.havntAkk')} <Link to="/auth/sign-up/">{translate('Auth.reg')}</Link>
          </div>
          <div className="auth__reg-width">
            <GoogleLogin
              clientId={googleID}
              buttonText="Login"
              render={(renderProps) => (
                <button
                  type="button"
                  className="auth__reg-width__google"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <div className="img">
                    <svg
                      id="google"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use xlinkHref={`${sprite}#google`} />
                    </svg>
                  </div>
                  {translate('Auth.google')}
                </button>
              )}
              onSuccess={signInGoogle}
              onFailure={signInGoogle}
              cookiePolicy="single_host_origin"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
