import './style.scss';

import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { v4 as uuidv4 } from 'uuid';

const Spec = () => {
  const { translate } = useTranslation();
  const errors = useSelector((state) => state.authReducer.errors);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { waiter } = useSelector((state) => state.projectReducer);
  const [isSub, setIsSub] = useState(false);
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);
  const categories = useSelector((state) => state.projectReducer.projectExpertCategories);
  const categoriesOptions = categories.map((item) => ({ value: item.id, label: item.name }));

  const maxExpertCategoriesSecondLevel = useSelector(
    (state) => state.authReducer.user.expert.maxExpertCategoriesSecondLevel,
  );
  const expertCategory = useSelector(
    (state) =>
      state.authReducer.user.expert.categories.length &&
      String(state.authReducer.user.expert.categories[0].id),
  );
  const expertCategoryLevel2Id = useSelector((state) =>
    state.authReducer.user.expert.secondCategories.map((item) => item.id),
  );
  const expertCategoryLevel3Id = useSelector((state) =>
    state.authReducer.user.expert.thirdCategories.map((item) => item.id),
  );

  let catFiltered =
    expertCategory && categories.length
      ? categories.filter((item) => String(item.id) === expertCategory)[0]
      : [];

  if (catFiltered.child) {
    catFiltered = {
      ...catFiltered,
      child: catFiltered.child.filter((item) => expertCategoryLevel2Id.includes(item.id)),
    };
    catFiltered.child = catFiltered.child.map((item) => ({
      ...item,
      child: item.child.filter((child) => expertCategoryLevel3Id.includes(child.id)),
    }));
  }

  const expertCategorySecond = catFiltered.child
    ? catFiltered.child.map((item) => ({
        id: item.id,
        child: item.child.map((child) => child.id),
      }))
    : [];

  const [ableLevel1, setAbleLevel1] = useState(false);
  const [level1Save, setLevel1Save] = useState(false);

  // Модалка
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (values) => {
    await dispatch(authActions.saveExpertCatLevel2and3(values.expertCategorySecond));

    setIsSub(true);
    setTimeout(() => {
      setIsSub(false);
    }, 10000);

    if (firstSignUp) {
      navigate('/user/resume/');
    }
  };

  const formik = useFormik({
    initialValues: {
      expertCategory,
      expertCategorySecond,
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  const [addCatLevel2, setAddCatLevel2] = useState(false);

  const changeLevel2 = (val, index) => {
    setAddCatLevel2(false);
    formik.setFieldValue(`expertCategorySecond[${index}].id`, val > 0 ? val : uuidv4());
    formik.setFieldValue(`expertCategorySecond[${index}].child`, []);

    if (val > 0) {
      setAddCatLevel2(true);
      setTimeout(() => {
        setAddCatLevel2(false);
      }, 3000);
    }
  };

  const catLevel2 = categories.filter(
    (item) => String(item.id) === formik.values.expertCategory,
  )[0];

  const arrLevel2Id = formik.values.expertCategorySecond.map((item) => String(item.id));
  let catLevel3 = [];
  if (catLevel2) {
    catLevel3 = catLevel2.child.filter((item) => arrLevel2Id.includes(String(item.id)));
  }

  const handleSaveLevel1 = async () => {
    await dispatch(authActions.saveExpertCatLevel1(formik.values.expertCategory));

    setLevel1Save(true);
    setAbleLevel1(false);
    setTimeout(() => {
      setLevel1Save(false);
    }, 5000);
  };

  const getOptionsLevel2 = (index) => {
    let catLevel2Filtered = [];
    const catLevel2FilteredZero = { value: '', label: translate('Spec.level2Placeholder') };
    if (catLevel2) {
      let arrId = formik.values.expertCategorySecond.map((item) => Number(item.id));
      arrId = arrId.filter((item, itemIndex) => itemIndex !== index);
      catLevel2Filtered = catLevel2.child.filter((option) => !arrId.includes(option.id));
      catLevel2Filtered = catLevel2Filtered.map((item) => ({ value: item.id, label: item.name }));
      catLevel2Filtered = [catLevel2FilteredZero, ...catLevel2Filtered];
    }

    return catLevel2Filtered;
  };

  const getOptionsLevel3 = (index) => {
    const arrId = formik.values.expertCategorySecond[index].child.map((item) => String(item));
    const catLevel3FilteredZero = { value: '', label: translate('Spec.level3Placeholder') };
    let catLevel3Filtered = catLevel3[index].child.filter(
      (option) => !arrId.includes(String(option.id)),
    );

    catLevel3Filtered = catLevel3Filtered.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    catLevel3Filtered = [catLevel3FilteredZero, ...catLevel3Filtered];

    return catLevel3Filtered;
  };

  return (
    <>
      <div className="exp-content__edit exp-content__edit--spec spec-selectors">
        <div className="exp-content__top">{translate('Spec.title')}</div>
        {waiter && !categories.length ? (
          <Loader />
        ) : (
          <form className="exp-content__margin" onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <div className="exp-content__title">
                <h3>{translate('Spec.titleLevel1')}</h3>
              </div>
              <div className="exp-content__wrap exp-content__wrap--sfera exp-content__wrap--spec">
                <div className="spec__level1">
                  <Select
                    isDisabled={!ableLevel1}
                    classNamePrefix="react-select"
                    className="react-select react-select--border"
                    options={categoriesOptions}
                    name="expertCategory"
                    onChange={(e) => formik.setFieldValue(`expertCategory`, e.value)}
                    placeholder={translate('About.chooseLangPl')}
                    value={
                      categoriesOptions.filter(
                        (option) => String(option.value) === String(formik.values.expertCategory),
                      )[0]
                    }
                  />
                  {ableLevel1 ? (
                    <button type="button" className="btn btn-primary" onClick={handleSaveLevel1}>
                      {translate('save')}
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary" onClick={handleShow}>
                      {translate('change')}
                    </button>
                  )}
                </div>
              </div>
              {level1Save && (
                <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
                  <svg
                    className="bi flex-shrink-0 me-2"
                    width="24"
                    height="24"
                    role="img"
                    aria-label="Success:"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                  <div>{translate('Contacts.success')}</div>
                </div>
              )}

              {!ableLevel1 && formik.values.expertCategory !== '0' && (
                <>
                  <div className="exp-content__title">
                    <h3>{translate('Spec.titleLevel2')}</h3>
                  </div>
                  <div className="exp-content__wrap exp-content__wrap--sfera exp-content__wrap--spec">
                    <FieldArray
                      name="expertCategorySecond"
                      render={(arrayHelpers) => (
                        <>
                          <div className="spec__level2">
                            {formik.values.expertCategorySecond &&
                              formik.values.expertCategorySecond.length > 0 &&
                              formik.values.expertCategorySecond.map((item, index) => (
                                <Select
                                  key={item.id || uuidv4()}
                                  classNamePrefix="react-select"
                                  className={`react-select react-select--border ${
                                    formik.values.expertCategorySecond[index].id === ''
                                      ? 'zero'
                                      : ''
                                  }`}
                                  options={getOptionsLevel2(index)}
                                  name={`expertCategorySecond[${index}].id`}
                                  onChange={(e) => changeLevel2(e.value, index)}
                                  placeholder={translate('Spec.level2Placeholder')}
                                  value={
                                    getOptionsLevel2(index).filter(
                                      (option) =>
                                        String(option.value) ===
                                        String(formik.values.expertCategorySecond[index].id),
                                    )[0]
                                  }
                                />
                              ))}
                          </div>
                          {formik.values.expertCategorySecond.length <
                          maxExpertCategoriesSecondLevel ? (
                            <div className="button--add-employer button--add-employer--sfera">
                              <button
                                type="button"
                                onClick={() => arrayHelpers.push({ id: 0, child: [] })}
                              >
                                {translate('Spec.addOneMore')}
                              </button>
                            </div>
                          ) : (
                            <div className="sfera__limit">
                              {translate('Spec.limitLevel1')} {maxExpertCategoriesSecondLevel}{' '}
                              {translate('Spec.limitLevel2')}{' '}
                              <Link to="/payment/paid/">{translate('Spec.payFunc')}</Link>
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className="exp-content__title">
                    <h3>{translate('Spec.titleLevel3')}</h3>
                  </div>
                  {catLevel3.length > 0 ? (
                    <div className="exp-content__wrap exp-content__wrap--sfera exp-content__wrap--spec">
                      <div className="spec__level3__wrap">
                        {catLevel3.map((item, index) => (
                          <div className="spec__level3__block fadeIn" key={item.id}>
                            <div className="spec__level3__title">{item.name}</div>
                            <div className="spec__level3">
                              <Select
                                options={getOptionsLevel3(index)}
                                classNamePrefix="react-select"
                                className={`react-select react-select--border ${
                                  formik.values.expertCategorySecond[index].child[0] === ''
                                    ? 'zero'
                                    : ''
                                }`}
                                name={`expertCategorySecond[${index}].child[0]`}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    `expertCategorySecond[${index}].child[0]`,
                                    e.value,
                                  )
                                }
                                placeholder={translate('Spec.level3Placeholder')}
                                value={item.child.map((option) =>
                                  String(option.id) ===
                                  String(formik.values.expertCategorySecond[index].child[0])
                                    ? { value: option.id, label: option.name }
                                    : null,
                                )}
                              />
                              <Select
                                options={getOptionsLevel3(index)}
                                classNamePrefix="react-select"
                                className={`react-select react-select--border ${
                                  formik.values.expertCategorySecond[index].child[1] === ''
                                    ? 'zero'
                                    : ''
                                }`}
                                name={`expertCategorySecond[${index}].child[1]`}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    `expertCategorySecond[${index}].child[1]`,
                                    e.value,
                                  )
                                }
                                placeholder={translate('Spec.level3Placeholder')}
                                value={item.child.map((option) =>
                                  String(option.id) ===
                                  String(formik.values.expertCategorySecond[index].child[1])
                                    ? { value: option.id, label: option.name }
                                    : null,
                                )}
                              />
                              <Select
                                options={getOptionsLevel3(index)}
                                classNamePrefix="react-select"
                                className={`react-select react-select--border ${
                                  formik.values.expertCategorySecond[index].child[2] === ''
                                    ? 'zero'
                                    : ''
                                }`}
                                name={`expertCategorySecond[${index}].child[2]`}
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    `expertCategorySecond[${index}].child[2]`,
                                    e.value,
                                  )
                                }
                                placeholder={translate('Spec.level3Placeholder')}
                                value={item.child.map((option) =>
                                  String(option.id) ===
                                  String(formik.values.expertCategorySecond[index].child[2])
                                    ? { value: option.id, label: option.name }
                                    : null,
                                )}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <button type="submit" className="btn btn-primary">
                        {firstSignUp ? translate('next') : translate('save')}
                      </button>
                    </div>
                  ) : (
                    <div className="exp-content__wrap exp-content__wrap--sfera exp-content__wrap--spec">
                      <div className="spec__level3__wrap">
                        <div className="spec__level3__block">
                          <div className="spec__level3__title">{translate('Spec.lastText')}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </FormikProvider>
            {isSub && (
              <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
                {!errors && (
                  <svg
                    className="bi flex-shrink-0 me-2"
                    width="24"
                    height="24"
                    role="img"
                    aria-label="Success:"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                )}

                <div>{errors ? errors.message : translate('Contacts.success')}</div>
              </div>
            )}
          </form>
        )}
      </div>
      {addCatLevel2 && (
        <div className="alert alert-success d-flex align-items-center mt-2 fix" role="alert">
          <svg
            className="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Success:"
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          <div>{translate('Spec.alertAddLevel2')}</div>
        </div>
      )}

      <Modal show={show} onHide={handleClose} className="spec-modal">
        <Modal.Body>
          <div className="exp-modal ">
            <div className="modal-header">
              <h5 className="modal-title">{translate('Spec.modalTitle')}</h5>
              <button type="button" onClick={handleClose} className="spec-modal__close">
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#modalCloseWhite`} />
                </svg>
              </button>
            </div>
            <div className="spec-modal-body">
              <div className="spec-modal__sub">{translate('Spec.modalText')}</div>
              <div className="spec-modal__btns">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    handleClose();
                    setAbleLevel1(true);
                  }}
                >
                  {translate('Spec.changeSpec')}
                </button>
                <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
                  {translate('cancel')}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Spec;
