import axios from 'axios';
import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import avatarDefault from 'src/assets/img/proekt/avatar-default.jpg';
import PfdLabel from 'src/assets/img/resume/2.png';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const getNewData = (data) => {
  const date = new Date(data);
  const time = date.toLocaleTimeString([], {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${date.toISOString().slice(0, 10)} - ${time}`;
};
const Chat = () => {
  const dispatch = useDispatch();

  const chats = useSelector((state) => state.adminReducer.chats);
  const messages = useSelector((state) => state.adminReducer.messages);
  const id = useSelector((state) => state.adminReducer.project.id);

  const [expert, setExpert] = useState({});
  const [company, setCompany] = useState({});

  useEffect(() => {
    dispatch(adminActions.getProjectChats({ id }));
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handelShowChat = (chatId, chatExpert, chatCompany) => {
    dispatch(adminActions.getChatMessages({ id: chatId }));
    setExpert(chatExpert);
    setCompany(chatCompany);
    handleShow();
  };

  const handleDownload = (url, fileName) => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data]);
      saveAs(blob, fileName || 'filename.null');
    });
  };

  return (
    <>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Дата початку листування</th>
            <th>Дата останього повідомлення</th>
            <th>Переглянути чат</th>
          </tr>
        </thead>
        <tbody className="admin_knowledge_action_btn">
          {chats &&
            chats.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.created}</td>
                <td>{item.lastMessageDate}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handelShowChat(item.id, item.expert, item.company)}
                  >
                    Переглянути чат
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close" onClick={handleClose}>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="admin-chat">
              <div className="admin-chat__flex">
                <div className="admin-chat__item" to="/">
                  Компанія:
                  <a href={`/employers/${company.slug}`} target="_blank" rel="noreferrer">
                    <img src={company.avatar || avatarDefault} width={50} height={50} alt="" />
                    {company.companyName}
                  </a>
                </div>

                <div className="admin-chat__item" to="/">
                  Експерт:
                  <a href={`/expert/${company.slug}`} target="_blank" rel="noreferrer">
                    <img src={expert.avatar || avatarDefault} width={50} height={50} alt="" />
                    {expert.profileName}
                  </a>
                </div>
              </div>
              <div className="chatDetail__body">
                {messages.length > 0 &&
                  messages.map((item) => (
                    <div
                      key={item.id}
                      className={`chatDetail__body__item ${
                        item.userId === expert.userId ? 'chatDetail__body__item--my' : ''
                      }`}
                    >
                      {item.userId === expert.userId ? (
                        <div
                          className="chatDetail__avatar"
                          style={{
                            backgroundImage: `url(${expert.avatar || avatarDefault})`,
                          }}
                        />
                      ) : (
                        <div
                          className="chatDetail__avatar"
                          style={{
                            backgroundImage: `url(${company.avatar || avatarDefault})`,
                          }}
                        />
                      )}

                      <div className="chatDetail__mess">
                        {item.body && <div className="chatDetail__mess__text">{item.body}</div>}

                        {item.fileUrl && (
                          <button
                            type="button"
                            className="chatDetail__mess__file"
                            onClick={() => handleDownload(item.fileUrl, item.fileName)}
                          >
                            <img src={PfdLabel} alt="img" />
                            <svg width="16" height="16">
                              <use xlinkHref={`${sprite}#chatDownload`} />
                            </svg>
                          </button>
                        )}

                        <div className="chatDetail__mess__data">{getNewData(item.created)}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Chat;
