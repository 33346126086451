import { api } from 'src/api';
import { getHeaders } from 'src/helpers/';

export const signIn = async (data) => {
  const result = await api.post(`auth/login`, data, getHeaders());

  return result;
};

export const signInGoogle = async (data) => {
  const result = await api.post(`/auth/google`, data, getHeaders());

  return result;
};

export const signUp = async (data) => {
  const result = await api.post(`auth/register`, data, getHeaders());

  return result;
};

export const signUpGoogle = async (data) => {
  const result = await api.post(`auth/register-google`, data, getHeaders());

  return result;
};

export const confirmPhone = async (data) => {
  const result = await api.post(`auth/phone-verify`, data, getHeaders());

  return result;
};

export const signUpdate = async (data) => {
  const result = await api.post(`auth/register-update`, data, getHeaders());

  return result;
};

export const forgotPass = async (data) => {
  const result = await api.post(`auth/forgot-password`, data, getHeaders());

  return result;
};

export const confirmPasswordCode = async (data) => {
  const result = await api.post(`auth/confirm-password-code`, data, getHeaders());

  return result;
};

export const resendPasswordCode = async (data) => {
  const result = await api.post(`auth/resend-password-code `, data, getHeaders());

  return result;
};

export const resetPass = async (data) => {
  const result = await api.post(`auth/reset-password`, data, getHeaders());

  return result;
};

export const getCompanyCategories = async () => {
  const result = await api.get(`get-company-categories`, getHeaders());

  return result;
};

export const getExpertCategories = async () => {
  const result = await api.get(`get-expert-categories`, getHeaders());

  return result;
};

export const getCountries = async () => {
  const result = await api.get(`countries`, getHeaders());

  return result;
};

export const getCities = async () => {
  const result = await api.get(`cities`, getHeaders());

  return result;
};

export const regComp = async (data) => {
  const result = await api.post(`/auth/register-company`, data, getHeaders());

  return result;
};

export const regExp = async (data) => {
  const result = await api.post(`/auth/register-expert`, data, getHeaders());

  return result;
};

export const getProfile = async () => {
  const result = await api.get(`profile`, getHeaders());

  return result;
};

export const refresh = async () => {
  const result = await api.post(`auth/refresh`, null, getHeaders());

  return result;
};

export const logout = async () => {
  const result = await api.post(`auth/logout`, null, getHeaders());

  return result;
};

export const changleRole = async (data) => {
  const result = await api.post(`auth/role-update`, data, getHeaders());

  return result;
};

export const contactsChangeExpert = async (data) => {
  const result = await api.put(`profile/expert/contact-information`, data, getHeaders());

  return result;
};

export const contactsChangeCompany = async (data) => {
  const result = await api.put(`profile/company/contact-information`, data, getHeaders());

  return result;
};

export const personalInfoExpert = async (data) => {
  const result = await api.put(`profile/expert/personal-information`, data, getHeaders());

  return result;
};

export const personalInfoCompany = async (data) => {
  const result = await api.put(`profile/company/personal-information`, data, getHeaders());

  return result;
};

export const education = async (data) => {
  const result = await api.put(`profile/expert/education`, data, getHeaders());

  return result;
};

export const getLangs = async () => {
  const result = await api.get(`languages`, getHeaders());

  return result;
};

export const getLangsLevels = async () => {
  const result = await api.get(`language-levels`, getHeaders());

  return result;
};

export const setLangs = async (data) => {
  const result = await api.put(`profile/expert/languages`, data, getHeaders());

  return result;
};

export const setDesExpert = async (data) => {
  const result = await api.put(`profile/expert/services`, data, getHeaders());

  return result;
};

export const setDesCompany = async (data) => {
  const result = await api.put(`profile/company/description`, data, getHeaders());

  return result;
};

export const setCost = async (data) => {
  const result = await api.put(`profile/expert/services-cost`, data, getHeaders());

  return result;
};

export const uploadAvatarExpert = async (data) => {
  const result = await api.post(`profile/expert/avatar`, data, getHeaders());

  return result;
};

export const uploadAvatarCompany = async (data) => {
  const result = await api.post(`profile/company/avatar`, data, getHeaders());

  return result;
};

export const uploadCoverExpert = async (data) => {
  const result = await api.post(`profile/expert/profile-cover/image`, data, getHeaders());

  return result;
};

export const uploadCoverCompany = async (data) => {
  const result = await api.post(`profile/company/profile-cover/image`, data, getHeaders());

  return result;
};

export const getCovers = async (data) => {
  const result = await api.get(`profile-cover-templates`, data, getHeaders());

  return result;
};

export const chooseCoverExpert = async (data) => {
  const result = await api.post(`profile/expert/profile-cover/template`, data, getHeaders());

  return result;
};

export const chooseCoverCompany = async (data) => {
  const result = await api.post(`profile/company/profile-cover/template`, data, getHeaders());

  return result;
};

export const editCompanyCategories = async (data) => {
  const result = await api.put(`profile/company/edit-company-categories`, data, getHeaders());

  return result;
};

export const setResume = async (data) => {
  const result = await api.post(`profile/expert/resume/update`, data, getHeaders());

  return result;
};

export const deleteResume = async (id) => {
  const result = await api.delete(`profile/expert/resume/${id}`, getHeaders());

  return result;
};

export const emailUpdate = async (data) => {
  const result = await api.post(`auth/email-update`, data, getHeaders());

  return result;
};

export const emailVerify = async (data) => {
  const result = await api.post(`auth/email-verify`, data, getHeaders());

  return result;
};

export const phoneUpdate = async (data) => {
  const result = await api.post(`auth/phone-update`, data, getHeaders());

  return result;
};

export const phoneVerify = async (data) => {
  const result = await api.post(`auth/phone-verify`, data, getHeaders());

  return result;
};

export const accountDeleteCode = async () => {
  const result = await api.post(`auth/account-delete-code`, null, getHeaders());

  return result;
};

export const accountDelete = async (data) => {
  const result = await api.post(`auth/account-delete`, data, getHeaders());

  return result;
};

export const putBusinessCategories = async (data) => {
  const result = await api.put(`profile/expert/business-categories`, data, getHeaders());

  return result;
};

export const saveExpertCatLevel1 = async (data) => {
  const result = await api.put(`profile/expert/expert-category-first-level`, data, getHeaders());

  return result;
};

export const saveExpertCatLevel2and3 = async (data) => {
  const result = await api.put(
    `profile/expert/expert-category-second-and-third-level`,
    data,
    getHeaders(),
  );

  return result;
};

export const changePassword = async (data) => {
  const result = await api.post(`/auth/change-password`, data, getHeaders());

  return result;
};

export const notificationSettingsExpert = async (data) => {
  const result = await api.post(`/profile/expert/notification-settings`, data, getHeaders());

  return result;
};

export const notificationSettingsCompany = async (data) => {
  const result = await api.post(`/profile/company/notification-settings`, data, getHeaders());

  return result;
};

export const adminLogin = async (data) => {
  const result = await api.post(`/admin/login`, data, getHeaders());

  return result;
};
