import './style.scss';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { FixLoader, Footer, Header, Sidebar, Status, Subheader } from 'src/components';

import { About, Business, Contacts, Portfolio, Resume, Sfera, Spec } from './components';

const User = () => {
  const authWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const { role } = useSelector((state) => state.authReducer.user);
  const { pathname } = useLocation();
  const windowWidth = window.innerWidth;

  const srollToTop = () => {
    if (windowWidth > 767) {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    }
  };
  useEffect(() => {
    srollToTop();
  }, [pathname]);

  return (
    <>
      {authWaiter && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            {role && role === 1 && <Status />}
            <div className="exp-content exp-content--company">
              <Sidebar />
              <Routes>
                <Route path="/" element={<About />} />
                <Route path="about" element={<About />} />
                <Route path="contacts" element={<Contacts />} />

                {role && role === 1 ? (
                  <>
                    <Route path="resume" element={<Resume />} />
                    <Route path="business" element={<Business />} />
                    <Route path="spec" element={<Spec />} />
                    <Route path="portfolio" element={<Portfolio />} />
                  </>
                ) : (
                  <Route path="sfera" element={<Sfera />} />
                )}
              </Routes>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default User;
