import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

const EnterPhone = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const navigage = useNavigate();
  const error = useSelector((state) => state.authReducer.errors);

  const schema = Yup.object().shape({
    phone: Yup.string().test(
      'len',
      `${translate('Valid.minimum')} 12 ${translate('Valid.cifr')}`,
      (phone) => {
        if (phone) {
          const phoneNumb = phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
          return phoneNumb.length >= 12;
        }
        return false;
      },
    ),
  });

  useEffect(() => () => dispatch(clearErrors()), []);

  const handleSubmit = (values) => {
    dispatch(authActions.forgotPass(values)).then(({ payload }) => {
      if (payload.status === 200) {
        navigage(`/auth/fogotpass/enter-code?phone=${values.phone}`);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: schema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <div className="auth__wrap">
      <div className="container">
        <h1>{translate('Auth.fogotTitle')}</h1>

        <form className="auth__form auth__form--fogot" onSubmit={formik.handleSubmit}>
          <div className="auth__form__title text-center">{translate('Auth.fogotForm')}</div>
          {error && error.phone && <strong className="text-danger">{error.phone[0]}</strong>}
          <div className="input__row">
            <InputMask
              mask="+380-99-999-99-99"
              placeholder="+380"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phone"
              className={formik.errors.phone && formik.touched.phone && 'is-invalid'}
            />

            <div className="invalid-feedback">
              {formik.errors.phone && formik.touched.phone && formik.errors.phone}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button className="btn btn-primary btn-fogot" type="submit">
              {translate('Auth.fogotBtn')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnterPhone;
