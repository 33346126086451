import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import * as Yup from 'yup';

const Step3SubStep1 = ({ setValue }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { projectExpertCategories } = useSelector((state) => state.projectReducer);
  const currentProject = useSelector((state) => state.projectReducer.currentProject);

  const [level1List, setLevel1List] = useState(currentProject.expertThreeLevel1);
  const [level2List, setLevel2List] = useState(currentProject.expertThreeLevel2);
  const [level3List, setLevel3List] = useState(currentProject.expertThreeCategories);
  const [categoriesLimit, setCategoriesLimit] = useState(0);
  const [categoriesLimitError, setCategoriesLimitError] = useState('');

  const openLevel1 = (e, id) => {
    e.currentTarget.classList.toggle('active');
    e.currentTarget
      .closest('.prpb__acordion__row')
      .querySelector('.prpb__acordion__body')
      .classList.toggle('active');

    if (!level1List.includes(id)) {
      setLevel1List([...level1List, id]);
    } else {
      setLevel1List(level1List.filter((item) => item !== id));
    }
  };

  const addLevel2 = (id) => {
    if (!level2List.includes(id)) {
      setLevel2List([...level2List, id]);
    } else {
      setLevel2List(level2List.filter((item) => item !== id));
    }
  };

  const addLevel3 = (id) => {
    if (level3List.includes(id)) {
      setCategoriesLimitError('');
      setCategoriesLimit(categoriesLimit - 1);
      setLevel3List(level3List.filter((item) => item !== id));
    } else if (categoriesLimit < 9) {
      setCategoriesLimit(categoriesLimit + 1);
      setLevel3List([...level3List, id]);
    } else {
      setCategoriesLimitError(translate('Publication.categoriesLimitError'));
    }
  };

  const handleSubmit = async ({ expertThreeCategories, expertThreeLevel2, expertThreeLevel1 }) => {
    setValue('expertThreeCategories', expertThreeCategories);
    setValue('expertThreeLevel2', expertThreeLevel2);
    setValue('expertThreeLevel1', expertThreeLevel1);
    navigate('/publication/step3/sub-step2/');
  };

  const step3Schema = Yup.object().shape({
    expertThreeCategories: Yup.array()
      .min(1, translate('Publication.minOneSkill'))
      .required(translate('Valid.req')),
  });

  const formik = useFormik({
    initialValues: {
      expertThreeCategories: currentProject.expertThreeCategories,
      expertThreeLevel2: currentProject.expertThreeLevel2,
      expertThreeLevel1: currentProject.expertThreeLevel1,
    },
    validationSchema: step3Schema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    let list3 = [];
    projectExpertCategories.forEach((level1) => {
      level1.child.forEach((level2) => {
        if (level2List.includes(level2.id)) {
          level2.child.forEach((level3) => {
            if (level3List.includes(level3.id)) {
              list3 = [...list3, level3.id];
            }
          });
        }
      });
    });

    formik.setFieldValue('expertThreeCategories', list3);

    let list2 = [];
    projectExpertCategories.forEach((level1) => {
      level1.child.forEach((level2) => {
        if (level2List.includes(level2.id)) {
          list2 = [...list2, level2.id];
        }
      });
    });

    formik.setFieldValue('expertThreeLevel2', list2);

    let list1 = [];
    projectExpertCategories.forEach((level1) => {
      if (level1List.includes(level1.id)) {
        list1 = [...list1, level1.id];
      }
    });

    formik.setFieldValue('expertThreeLevel1', list1);
  }, [level1List, level2List, level3List]);

  return (
    <form className="prpb__wrap" onSubmit={formik.handleSubmit}>
      <div className="prpb__name">{translate('Publication.detail')}</div>
      <div className="prpb__body">
        <div className="prpb__label">{translate('Publication.detailSpec')}</div>
        <div className="prpb__detail__text">{translate('Publication.detailSpecText')}</div>

        <div className="prpb__detail__wrap">
          <div className="prpb__detail__col">
            <div className="prpb__acordion">
              {projectExpertCategories.length > 0 &&
                projectExpertCategories.map((item) => (
                  <div className="prpb__acordion__row" key={item.id}>
                    <div
                      className={`prpb__acordion__head ${
                        level1List.includes(item.id) ? 'active' : ''
                      }`}
                      onClick={(e) => openLevel1(e, item.id)}
                      onKeyPress={(e) => openLevel1(e, item.id)}
                      role="treegrid"
                      tabIndex="0"
                    >
                      {item.name}
                    </div>
                    <div
                      className={`prpb__acordion__body ${
                        level1List.includes(item.id) ? 'active' : ''
                      }`}
                    >
                      <ul>
                        {item.child &&
                          item.child.map((child) => (
                            <li
                              key={child.id}
                              role="treeitem"
                              aria-selected
                              className={level2List.includes(child.id) ? 'active' : ''}
                            >
                              <div
                                onClick={() => addLevel2(child.id)}
                                onKeyPress={() => addLevel2(child.id)}
                                tabIndex="0"
                                role="button"
                              >
                                {child.name}
                              </div>

                              <ul className="prpb__acordion__mob-level3">
                                {child.child.map((child2) => (
                                  <li
                                    key={child2.id}
                                    className={
                                      level3List.includes(child2.id)
                                        ? 'active font__size__13px'
                                        : 'font__size__13px'
                                    }
                                    role="treeitem"
                                    aria-selected
                                    onClick={() => addLevel3(child2.id)}
                                    onKeyPress={() => addLevel3(child2.id)}
                                    tabIndex="0"
                                  >
                                    {child2.name}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="prpb__detail__col prpb__detail__col--second">
            <ul className="prbp__detail__wich">
              {projectExpertCategories.length > 0 &&
                projectExpertCategories.map((level1) =>
                  level1.child.map((level2) => {
                    if (level2List.includes(level2.id)) {
                      return level2.child.map((level3) => (
                        <li
                          key={level3.id}
                          role="treeitem"
                          aria-selected
                          onClick={() => addLevel3(level3.id)}
                          onKeyPress={() => addLevel3(level3.id)}
                          tabIndex="0"
                          className={level3List.includes(level3.id) ? 'active' : ''}
                        >
                          {level3.name}
                        </li>
                      ));
                    }
                    return false;
                  }),
                )}
            </ul>
          </div>
          <div className="prpb__detail__col">
            <div className="prbp__detail__tags">
              <div className="prbp__detail__tags__title">
                {translate('Publication.step3Skills')}
              </div>
              {projectExpertCategories.length > 0 &&
                projectExpertCategories.map((level1) =>
                  level1.child.map((level2) => {
                    if (level2List.includes(level2.id)) {
                      return level2.child.map((level3) => {
                        if (level3List.includes(level3.id)) {
                          return (
                            <div className="prbp__detail__tags__item" key={level3.id}>
                              {level3.name}
                              <button type="button" onClick={() => addLevel3(level3.id)}>
                                <svg width="14px" height="14px">
                                  <use xlinkHref={`${sprite}#close2`} />
                                </svg>
                              </button>
                            </div>
                          );
                        }
                        return false;
                      });
                    }
                    return false;
                  }),
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="prpb__error">
        {formik.touched.expertThreeCategories && formik.errors.expertThreeCategories && (
          <div className="prpb__error__item">{formik.errors.expertThreeCategories}</div>
        )}
        {categoriesLimitError !== '' && (
          <div className="prpb__error__item">{categoriesLimitError}</div>
        )}
      </div>
      <div className="prpb__footer">
        <button className="btn btn-primary" type="submit">
          {translate('next')}
        </button>
        <Link className="btn btn-outline-primary" to="/publication/step2/">
          {translate('cancel')}
        </Link>
      </div>
    </form>
  );
};

Step3SubStep1.propTypes = {
  setValue: PropTypes.func.isRequired,
};

export default Step3SubStep1;
