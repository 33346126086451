import { useSelector } from 'react-redux';
import { NavLink, Route, Routes } from 'react-router-dom';

import { Bcat, Contacts, Info, Payment } from './components';

const Company = () => {
  const { company } = useSelector((state) => state.adminReducer.user);

  return (
    <>
      {company ? (
        <div className="admin-user-detail">
          <ul className="admin-user-detail__nav">
            <li>
              <NavLink to="info">Інформація про компанію</NavLink>
            </li>
            <li>
              <NavLink to="contacts">Контактна інформація </NavLink>
            </li>

            <li>
              <NavLink to="b-cat">Спеціалізації компанії </NavLink>
            </li>

            <li>
              <NavLink to="payment">Платні функції </NavLink>
            </li>
          </ul>
          <div className="admin-user-detail__content">
            <Routes>
              <Route path="/info" element={<Info />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/b-cat" element={<Bcat />} />
              <Route path="/payment" element={<Payment />} />
            </Routes>
          </div>
        </div>
      ) : (
        <div>Цей профіль не зареєстровано</div>
      )}
    </>
  );
};

export default Company;
