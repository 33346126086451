import { useSelector } from 'react-redux';

import CompanyPaid from './company-paid';
import ExpertPaid from './expert-paid';

const Paid = () => {
  const role = useSelector((state) => state.authReducer.user.role);
  return <>{role === 1 ? <ExpertPaid /> : <CompanyPaid />}</>;
};

export default Paid;
