import { useSelector } from 'react-redux';
import { useTranslation } from 'src/hooks';

const Education = () => {
  const { translate } = useTranslation();
  const education = useSelector(
    (state) => state.projectReducer.expertDetail && state.projectReducer.expertDetail.education,
  );
  return (
    <>
      {education && education.length > 0 && (
        <div className="exp-profile__row">
          <div className="exp-content__title">
            <h3>{translate('Profile.education')}</h3>
          </div>
          <div className="skills__list">
            <ul>
              {education.map((item) => (
                <li key={item.id}>
                  <span> {item.name}</span>
                  {item.from}-{item.to}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Education;
