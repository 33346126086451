import { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import avatarDefault from 'src/assets/img/proekt/avatar-default.jpg';
import sprite from 'src/assets/img/sprite.svg';
import {
  FavoriteExpertModal,
  FixLoader,
  Footer,
  Header,
  InviteModal,
  Loader,
  NotAuth,
  RejectExpertModal,
  StarRate,
  Subheader,
} from 'src/components';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

import { Favorites, Filter, ProjectNav, Rejects, TopSearch } from './components';

const Experts = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const refList = useRef();
  const lowestCategoryNotFound = useSelector(
    (state) => state.projectReducer.lowestCategoryNotFound,
  );
  const catWaiter = useSelector((state) => state.projectReducer.waiter);
  const authWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const expertWaiter = useSelector((state) => state.projectReducer.fixWaiter);
  const experts = useSelector((state) => state.projectReducer.experts);
  const expertsPag = useSelector((state) => state.projectReducer.expertsPag);
  const waiter = useSelector((state) => state.projectReducer.getExpertsWaiter);
  const waiterMore = useSelector((state) => state.projectReducer.getExpertsMoreWaiter);
  const page = query.get('page');
  const pageId = page || '1';

  // Категорії
  const { cLevel1Slug, cLevel2Slug, cLevel3Slug } = useParams();

  const isProjectPage = cLevel1Slug !== 'favorites' && cLevel1Slug !== 'rejects';

  const projectExpertCategories = useSelector(
    (state) => state.projectReducer.projectExpertCategories,
  );

  let defLevel1 = '';
  let defLevel2 = '';
  let defLevel3 = '';

  projectExpertCategories.forEach((item) => {
    if (item.slug === cLevel1Slug) {
      defLevel1 = String(item.id);
    }
    return false;
  });
  if (defLevel1) {
    projectExpertCategories
      .filter((item) => item.slug === cLevel1Slug)[0]
      .child.forEach((item2) => {
        if (item2.slug === cLevel2Slug) {
          defLevel2 = String(item2.id);
        }
        return false;
      });
  }
  if (defLevel2) {
    projectExpertCategories
      .filter((item) => item.slug === cLevel1Slug)[0]
      .child.filter((item2) => item2.slug === cLevel2Slug)[0]
      .child.forEach((item3) => {
        if (item3.slug === cLevel3Slug) {
          defLevel3 = String(item3.id);
        }
        return false;
      });
  }

  // query параметри
  const businessCategory = query.get('businessCategory') || '';
  const costPerProject = query.getAll('costPerProject[]') || [];
  const costPerHour = query.getAll('costPerHour[]') || [];

  // параметри фільтрації
  const [filter, setFilter] = useState({
    expertCategory: String(defLevel1),
    expertCategorySecond: String(defLevel2),
    expertCategoryThird: String(defLevel3),
    businessCategory: '',
    costPerProject: [],
    costPerHour: [],
  });

  const queryName = query.get('name') || '';
  const [name, setName] = useState(queryName);

  useEffect(() => {
    setName(queryName);
  }, [queryName]);

  useEffect(() => {
    const data = {
      page: pageId,
      name,
      ...filter,
    };

    if (isProjectPage) {
      dispatch(projectActions.getExperts(data));
    }
  }, [pageId, filter, name, isProjectPage]);

  const handleSetFilter = (data) => {
    setFilter(data);

    if (refList.current) {
      window.scrollTo({
        top: refList.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
  };

  const showMore = () => {
    const data = {
      page: expertsPag.current_page + 1,
      name,
      ...filter,
    };

    dispatch(projectActions.getExpertsMore(data));
  };

  const handleChangePage = (nextPage) => {
    let params = '';
    if (businessCategory) {
      params = `${businessCategory ? `businessCategory=${businessCategory}&` : ''}`;
    }
    if (costPerProject.length) {
      costPerProject.forEach((item) => {
        params += `costPerProject[]=${item}&`;
      });
    }
    if (costPerHour.length) {
      costPerHour.forEach((item) => {
        params += `costPerHour[]=${item}&`;
      });
    }
    if (name) {
      params += `name=${name}&`;
    }
    navigate(`${window.location.pathname}?${params}page=${nextPage.selected + 1}`);
    if (refList.current) {
      window.scrollTo({
        top: refList.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
  };

  const handleSearch = (data) => {
    setName(data);
    let params = '';
    if (data) {
      params += `name=${data}&`;
    }

    navigate(`${window.location.pathname}?${params}`);

    if (refList.current) {
      window.scrollTo({
        top: refList.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
  };

  // reject and favorite
  const [handleRejectModal, setHandleRejectModal] = useState(0);
  const [isReject, setIsReject] = useState(false);
  const [rejectModalXY, setRejectModalXY] = useState({ pageX: 0, pageY: 0 });

  const [handleFavoriteModal, setHandleFavoriteModal] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteModalXY, setFavoriteModalXY] = useState({ pageX: 0, pageY: 0 });

  const handleReject = (id) => {
    dispatch(projectActions.expertReject({ expert: id }));
  };

  const handleFavorite = (id) => {
    dispatch(projectActions.expertFavorite({ expert: id }));
  };

  // invite
  const [inviteExpertId, setInviteExpertId] = useState('0');
  const [showInvite, setShowInvite] = useState(false);
  const handleCloseInvite = () => setShowInvite(false);
  const handleShowInvite = () => setShowInvite(true);

  const ifAuth = !!useSelector((state) => state.authReducer.access_token);

  const [notAuth, setNotAuth] = useState(false);
  const showNotAuth = () => setNotAuth(true);
  const hideNotAuth = () => setNotAuth(false);

  return (
    <>
      {authWaiter && <FixLoader />}
      {expertWaiter && <FixLoader />}

      <div className="app">
        <Header />
        <main className="main">
          <Subheader />

          <div className="wrap">
            <TopSearch handleSearch={handleSearch} SearchName={name} />

            <div className="proekt">
              <Filter setFilter={handleSetFilter} filter={filter} />

              <div className="proekt__right" ref={refList}>
                <div className="proekt__right__newTitle">{translate('Experts.title')}</div>

                <div className="proekt__list proekt__list__new">
                  <ProjectNav />
                  {catWaiter || waiter ? (
                    <Loader />
                  ) : (
                    <>
                      {cLevel1Slug === 'favorites' && <Favorites />}
                      {cLevel1Slug === 'rejects' && <Rejects />}
                      {isProjectPage && (
                        <>
                          {lowestCategoryNotFound && (
                            <div className="filter-relevant mt-4 mb-4">
                              {translate('Experts.empty')}
                              <div>
                                <h2>{translate('Experts.expertSearch')}: </h2>
                              </div>
                            </div>
                          )}
                          <div className="proekt__content">
                            {experts.length > 0 ? (
                              experts.map((item) => (
                                <div className="proekt__list__item" key={item.id}>
                                  <div className="proekt__content__top">
                                    <div className="proekt__content__left">
                                      <div className="proekt__content__person proekt__content__person--list">
                                        <Link
                                          to={`/expert/${item.slug}`}
                                          className="proekt__pro-expert"
                                        >
                                          <div
                                            className="proekt__content__avatar"
                                            style={{
                                              backgroundImage: `url(${
                                                item.avatar || avatarDefault
                                              })`,
                                            }}
                                          >
                                            {' '}
                                          </div>
                                          {item.premium && (
                                            <svg
                                              width="30"
                                              height="30"
                                              viewBox="0 0 64 65"
                                              className="pro-label"
                                            >
                                              <use xlinkHref={`${sprite}#proExpert`} />
                                            </svg>
                                          )}
                                        </Link>
                                        <div className="proekt__content__person__right">
                                          <Link
                                            to={`/expert/${item.slug}`}
                                            className="proekt__content__person__name"
                                          >
                                            {item.profileName}
                                            {item.pinned && (
                                              <svg width="32" height="32" viewBox="0 0 32 32">
                                                <use xlinkHref={`${sprite}#pinExpert`} />
                                              </svg>
                                            )}
                                          </Link>
                                          <div className="proekt__tags">
                                            {item.costPerProject && (
                                              <div className="proekt__tags__item">
                                                <span className="tags-point">
                                                  {item.costPerProject} {translate('grnPr')}
                                                </span>
                                              </div>
                                            )}
                                            {item.costPerProject && (
                                              <div className="proekt__tags__item">
                                                <span className="tags-point">
                                                  {item.costPerHour} {translate('grnHour')}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="proekt__rating">
                                        <StarRate rate={item.avgReviewsRate} />

                                        <div className="proekt__rating__count">
                                          {item.reviewsCount} {translate('reviews')}
                                        </div>
                                      </div>
                                      <div className="proekt__location">
                                        <svg width="14" height="20">
                                          <use xlinkHref={`${sprite}#location-current`} />
                                        </svg>
                                        {item.country && item.country.name}
                                        {item.city && `, ${item.city.name}`}
                                      </div>
                                    </div>
                                    <div className="proekt__content__right">
                                      <div className="proekt__actios">
                                        <button
                                          type="button"
                                          className={item.rejected ? 'active' : ''}
                                          onClick={(e) => {
                                            if (ifAuth) {
                                              setRejectModalXY({
                                                pageX: e.pageX,
                                                pageY: e.pageY,
                                              });
                                              setIsReject(item.rejected);
                                              setHandleRejectModal(item.id);
                                            } else {
                                              showNotAuth();
                                            }
                                          }}
                                        >
                                          <svg width="13.2px" height="13.2px">
                                            <use xlinkHref={`${sprite}#dislike`} />
                                          </svg>
                                          <svg
                                            width="13.2px"
                                            height="13.2px"
                                            className="active-svg"
                                          >
                                            <use xlinkHref={`${sprite}#dislike-active`} />
                                          </svg>
                                        </button>
                                        <button
                                          type="button"
                                          className={item.favorite ? 'active' : ''}
                                          onClick={(e) => {
                                            if (ifAuth) {
                                              setFavoriteModalXY({
                                                pageX: e.pageX,
                                                pageY: e.pageY,
                                              });
                                              setIsFavorite(item.favorite);
                                              setHandleFavoriteModal(item.id);
                                            } else {
                                              showNotAuth();
                                            }
                                          }}
                                        >
                                          <svg width="13.2px" height="13.2px">
                                            <use xlinkHref={`${sprite}#like`} />
                                          </svg>
                                          <svg
                                            width="13.2px"
                                            height="13.2px"
                                            className="active-svg"
                                          >
                                            <use xlinkHref={`${sprite}#like-active`} />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="proekt__content__text">{item.services}</div>
                                  <div className="proekt__content__tags">
                                    {item.categories.map((cat) => (
                                      <div className="proekt__content__tags__item" key={cat.id}>
                                        {cat.name}
                                      </div>
                                    ))}
                                    {item.secondCategories.map((cat) => (
                                      <div className="proekt__content__tags__item" key={cat.id}>
                                        {cat.name}
                                      </div>
                                    ))}
                                    {item.thirdCategories.map((cat) => (
                                      <div className="proekt__content__tags__item" key={cat.id}>
                                        {cat.name}
                                      </div>
                                    ))}
                                  </div>
                                  <div className="proekt__content__bot__new">
                                    <Link to={`/expert/${item.slug}`} className="proekt__chat">
                                      {translate('Experts.detail')}

                                      <svg width="15" height="10">
                                        <use xlinkHref={`${sprite}#new-arr`} />
                                      </svg>
                                    </Link>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => {
                                        if (ifAuth) {
                                          setInviteExpertId(item.id);
                                          handleShowInvite();
                                        } else {
                                          showNotAuth();
                                        }
                                      }}
                                    >
                                      {translate('Experts.join')}
                                    </button>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="mt-5 mb-5">{translate('Experts.empty')}</div>
                            )}
                          </div>
                          {waiterMore ? (
                            <div className="proekt__more">
                              <Loader />
                            </div>
                          ) : (
                            <>
                              {expertsPag.last_page > 1 && (
                                <>
                                  {expertsPag.current_page !== expertsPag.last_page && (
                                    <div className="proekt__more">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => showMore()}
                                      >
                                        {translate('Projects.more')}
                                      </button>
                                    </div>
                                  )}
                                  <div className="proekt__pagination">
                                    <ReactPaginate
                                      breakLabel="....."
                                      onPageChange={(nextPage) => handleChangePage(nextPage)}
                                      pageCount={expertsPag.last_page}
                                      forcePage={expertsPag.current_page - 1}
                                      activeClassName="active"
                                      pageRangeDisplayed="2"
                                      marginPagesDisplayed="1"
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
      {showInvite && (
        <InviteModal show={showInvite} handleClose={handleCloseInvite} expert={inviteExpertId} />
      )}

      {handleRejectModal !== 0 && (
        <RejectExpertModal
          handleRejectModal={handleRejectModal}
          rejectModalXY={rejectModalXY}
          setHandleRejectModal={setHandleRejectModal}
          dispatch={handleReject}
          isReject={isReject}
        />
      )}

      {handleFavoriteModal !== 0 && (
        <FavoriteExpertModal
          handleFavoriteModal={handleFavoriteModal}
          favoriteModalXY={favoriteModalXY}
          setHandleFavoriteModal={setHandleFavoriteModal}
          dispatch={handleFavorite}
          isFavorite={isFavorite}
        />
      )}

      {notAuth && <NotAuth show={notAuth} handleClose={hideNotAuth} />}
    </>
  );
};

export default Experts;
