import { createSlice } from '@reduxjs/toolkit';

import { adminActions } from '../actions';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    knowledgeBase: [],
    knowledgeBaseDetail: {},
    payments: [],
    projects: [],
    project: [],
    projectsLogs: [],
    projectsLogsMeta: [],
    projectFixWaiter: false,
    chats: [],
    messages: [],
    projectsMeta: [],
    users: [],
    user: [],
    userFixWaiter: false,
    userErrors: false,
    usersMeta: [],
    knowledgeMeta: [],
    paymentsMeta: [],
    waiter: false,
    fixWaiter: false,
    paginationWaiter: false,
    expertFeatures: [],
    expertPaidPackages: [],
    expertPaidServices: [],
    expertFeaturesWaiter: false,
    companyProjects: [],
    companyFeatures: false,
    paidProjectFeatures: false,
    servicesDop: [],
    companyFeaturesWaiter: false,
    companyFeaturesWaiterFix: false,
    usersLogs: [],
    usersLogsMeta: [],
    bazaLogs: [],
    bazaLogsMeta: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getKnowledgeBase
      .addCase(adminActions.getKnowledgeBase.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getKnowledgeBase.fulfilled, (state, action) => {
        state.knowledgeBase = action.payload.data.data;
        state.knowledgeMeta = action.payload.data.meta;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getKnowledgeBase.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getKnowledgeBaseDetail
      .addCase(adminActions.getKnowledgeBaseDetail.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getKnowledgeBaseDetail.fulfilled, (state, action) => {
        state.knowledgeBaseDetail = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getKnowledgeBaseDetail.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getPayments
      .addCase(adminActions.getPayments.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getPayments.fulfilled, (state, action) => {
        state.payments = action.payload.data.data;
        state.paymentsMeta = action.payload.data.meta;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getPayments.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getAllKnowledgeBaseWithPagination
      .addCase(adminActions.getAllKnowledgeBaseWithPagination.pending, (state) => {
        state.paginationWaiter = true;
      })
      .addCase(adminActions.getAllKnowledgeBaseWithPagination.fulfilled, (state, action) => {
        state.knowledgeBase = action.payload.data.data;
        state.knowledgeMeta = action.payload.data.meta;
        state.paginationWaiter = false;
      })

      // getAllUsers
      .addCase(adminActions.getAllUsers.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getAllUsers.fulfilled, (state, action) => {
        state.users = action.payload.data.data;
        state.usersMeta = action.payload.data.meta;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getAllUsers.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getProjects
      .addCase(adminActions.getProjects.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getProjects.fulfilled, (state, action) => {
        state.projects = action.payload.data.data;
        state.projectsMeta = action.payload.data.meta;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getProjects.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getProject
      .addCase(adminActions.getProject.pending, (state) => {
        state.projectFixWaiter = true;
      })
      .addCase(adminActions.getProject.fulfilled, (state, action) => {
        state.project = action.payload.data;
        state.projectFixWaiter = false;
      })
      .addCase(adminActions.getProject.rejected, (state) => {
        state.projectFixWaiter = false;
      })

      // updateProjectStatus
      .addCase(adminActions.updateProjectStatus.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.updateProjectStatus.fulfilled, (state, action) => {
        state.project = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(adminActions.updateProjectStatus.rejected, (state) => {
        state.fixWaiter = false;
      })

      // updateProject
      .addCase(adminActions.updateProject.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.updateProject.fulfilled, (state, action) => {
        state.project = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(adminActions.updateProject.rejected, (state) => {
        state.fixWaiter = false;
      })

      // hideProject
      .addCase(adminActions.hideProject.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.hideProject.fulfilled, (state) => {
        state.project.hidden = true;
        state.fixWaiter = false;
      })
      .addCase(adminActions.hideProject.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getProjectChats
      .addCase(adminActions.getProjectChats.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getProjectChats.fulfilled, (state, action) => {
        state.chats = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getProjectChats.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getChatMessages
      .addCase(adminActions.getChatMessages.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getChatMessages.fulfilled, (state, action) => {
        state.messages = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getChatMessages.rejected, (state) => {
        state.fixWaiter = false;
      })

      // updateReviewsText
      .addCase(adminActions.updateReviewsText.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.updateReviewsText.fulfilled, (state, action) => {
        if (action.payload.data.id === state.project.expertReview.id) {
          state.project.expertReview = action.payload.data;
        }
        if (action.payload.data.id === state.project.companyReview.id) {
          state.project.companyReview = action.payload.data;
        }
        state.fixWaiter = false;
      })
      .addCase(adminActions.updateReviewsText.rejected, (state) => {
        state.fixWaiter = false;
      })

      // createKnowledge
      .addCase(adminActions.createKnowledge.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.createKnowledge.fulfilled, (state) => {
        state.fixWaiter = false;
      })
      .addCase(adminActions.createKnowledge.rejected, (state) => {
        state.fixWaiter = false;
      })

      // deleteKnowledge
      .addCase(adminActions.deleteKnowledge.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.deleteKnowledge.fulfilled, (state, action) => {
        state.fixWaiter = false;
      })
      .addCase(adminActions.deleteKnowledge.rejected, (state) => {
        state.fixWaiter = false;
      })

      // updateKnowledge
      .addCase(adminActions.updateKnowledge.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.updateKnowledge.fulfilled, (state) => {
        state.fixWaiter = false;
      })
      .addCase(adminActions.updateKnowledge.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getUser
      .addCase(adminActions.getUser.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getUser.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
      })
      .addCase(adminActions.getUser.rejected, (state) => {
        state.fixWaiter = false;
      })

      // expertUpdateAvatar
      .addCase(adminActions.expertUpdateAvatar.pending, (state) => {
        state.userFixWaiter = true;
      })
      .addCase(adminActions.expertUpdateAvatar.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.userFixWaiter = false;
      })
      .addCase(adminActions.expertUpdateAvatar.rejected, (state) => {
        state.userFixWaiter = false;
      })

      // companyUpdateAvatar
      .addCase(adminActions.companyUpdateAvatar.pending, (state) => {
        state.userFixWaiter = true;
      })
      .addCase(adminActions.companyUpdateAvatar.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.userFixWaiter = false;
      })
      .addCase(adminActions.companyUpdateAvatar.rejected, (state) => {
        state.userFixWaiter = false;
      })

      // expertUpdateCover
      .addCase(adminActions.expertUpdateCover.pending, (state) => {
        state.userFixWaiter = true;
      })
      .addCase(adminActions.expertUpdateCover.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.userFixWaiter = false;
      })
      .addCase(adminActions.expertUpdateCover.rejected, (state) => {
        state.userFixWaiter = false;
      })

      // companyUpdateCover
      .addCase(adminActions.companyUpdateCover.pending, (state) => {
        state.userFixWaiter = true;
      })
      .addCase(adminActions.companyUpdateCover.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.userFixWaiter = false;
      })
      .addCase(adminActions.companyUpdateCover.rejected, (state) => {
        state.userFixWaiter = false;
      })

      // expertUpdateInfo
      .addCase(adminActions.expertUpdateInfo.pending, (state) => {
        state.userErrors = false;
        state.userFixWaiter = true;
      })
      .addCase(adminActions.expertUpdateInfo.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.userErrors = false;
        state.userFixWaiter = false;
      })
      .addCase(adminActions.expertUpdateInfo.rejected, (state, action) => {
        state.userErrors = action.payload.errors;
        state.userFixWaiter = false;
      })

      // companyUpdateInfo
      .addCase(adminActions.companyUpdateInfo.pending, (state) => {
        state.userErrors = false;
        state.userFixWaiter = true;
      })
      .addCase(adminActions.companyUpdateInfo.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.userErrors = false;
        state.userFixWaiter = false;
      })
      .addCase(adminActions.companyUpdateInfo.rejected, (state, action) => {
        state.userErrors = action.payload.errors;
        state.userFixWaiter = false;
      })

      // expertUpdateStatus
      .addCase(adminActions.expertUpdateStatus.pending, (state) => {
        state.userErrors = false;
        state.userFixWaiter = true;
      })
      .addCase(adminActions.expertUpdateStatus.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.userErrors = false;
        state.userFixWaiter = false;
      })
      .addCase(adminActions.expertUpdateStatus.rejected, (state, action) => {
        state.userErrors = action.payload.errors;
        state.userFixWaiter = false;
      })

      // expertUpdateEducation
      .addCase(adminActions.expertUpdateEducation.pending, (state) => {
        state.userFixWaiter = true;
      })
      .addCase(adminActions.expertUpdateEducation.fulfilled, (state) => {
        state.userFixWaiter = false;
      })
      .addCase(adminActions.expertUpdateEducation.rejected, (state) => {
        state.userFixWaiter = false;
      })

      // expertUpdateRessetPass
      .addCase(adminActions.expertUpdateRessetPass.pending, (state) => {
        state.userFixWaiter = true;
      })
      .addCase(adminActions.expertUpdateRessetPass.fulfilled, (state) => {
        state.userFixWaiter = false;
      })
      .addCase(adminActions.expertUpdateRessetPass.rejected, (state) => {
        state.userFixWaiter = false;
      })

      // deletePortFolio
      .addCase(adminActions.deletePortFolio.pending, (state) => {
        state.userFixWaiter = true;
      })
      .addCase(adminActions.deletePortFolio.fulfilled, (state, action) => {
        state.user.expert.portfolio = state.user.expert.portfolio.filter(
          (item) => item.id !== action.meta.arg,
        );
        state.userFixWaiter = false;
      })
      .addCase(adminActions.deletePortFolio.rejected, (state) => {
        state.userFixWaiter = false;
      })

      // deletePortFolio
      .addCase(adminActions.deleteResume.pending, (state) => {
        state.userFixWaiter = true;
      })
      .addCase(adminActions.deleteResume.fulfilled, (state) => {
        state.user.expert.resume = null;
        state.userFixWaiter = false;
      })
      .addCase(adminActions.deleteResume.rejected, (state) => {
        state.userFixWaiter = false;
      })

      // editPortfolio
      .addCase(adminActions.editPortfolio.pending, (state) => {
        state.userFixWaiter = true;
      })
      .addCase(adminActions.editPortfolio.fulfilled, (state, action) => {
        state.user.expert.portfolio = state.user.expert.portfolio.map((item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data;
          }
          return item;
        });
        state.userFixWaiter = false;
      })
      .addCase(adminActions.editPortfolio.rejected, (state) => {
        state.userFixWaiter = false;
      })

      // expertFeatures
      .addCase(adminActions.expertFeatures.pending, (state) => {
        state.expertFeaturesWaiter = true;
      })
      .addCase(adminActions.expertFeatures.fulfilled, (state, action) => {
        state.expertFeatures = action.payload.data;
      })
      .addCase(adminActions.expertFeatures.rejected, (state) => {
        state.expertFeaturesWaiter = false;
      })

      // expertPaidPackages
      .addCase(adminActions.expertPaidPackages.pending, (state) => {
        state.expertFeaturesWaiter = true;
      })
      .addCase(adminActions.expertPaidPackages.fulfilled, (state, action) => {
        state.expertPaidPackages = action.payload.data;
        state.expertFeaturesWaiter = false;
      })
      .addCase(adminActions.expertPaidPackages.rejected, (state) => {
        state.expertFeaturesWaiter = false;
      })

      // expertPaidServices
      .addCase(adminActions.expertPaidServices.pending, (state) => {
        state.expertFeaturesWaiter = true;
      })
      .addCase(adminActions.expertPaidServices.fulfilled, (state, action) => {
        state.expertPaidServices = action.payload.data;
        state.expertFeaturesWaiter = false;
      })
      .addCase(adminActions.expertPaidServices.rejected, (state) => {
        state.expertFeaturesWaiter = false;
      })

      // expertFeaturesActivate
      .addCase(adminActions.expertFeaturesActivate.pending, (state) => {
        state.expertFeaturesWaiter = true;
      })
      .addCase(adminActions.expertFeaturesActivate.fulfilled, (state) => {
        state.expertFeaturesWaiter = false;
      })
      .addCase(adminActions.expertFeaturesActivate.rejected, (state) => {
        state.expertFeaturesWaiter = false;
      })

      // expertFeaturesDeactivate
      .addCase(adminActions.expertFeaturesDeactivate.pending, (state) => {
        state.expertFeaturesWaiter = true;
      })
      .addCase(adminActions.expertFeaturesDeactivate.fulfilled, (state) => {
        state.expertFeaturesWaiter = false;
      })
      .addCase(adminActions.expertFeaturesDeactivate.rejected, (state) => {
        state.expertFeaturesWaiter = false;
      })

      // companyUpdateStatus
      .addCase(adminActions.companyUpdateStatus.pending, (state) => {
        state.userErrors = false;
        state.userFixWaiter = true;
      })
      .addCase(adminActions.companyUpdateStatus.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.userErrors = false;
        state.userFixWaiter = false;
      })
      .addCase(adminActions.companyUpdateStatus.rejected, (state, action) => {
        state.userErrors = action.payload.errors;
        state.userFixWaiter = false;
      })

      // getProjectsLogs
      .addCase(adminActions.getProjectsLogs.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getProjectsLogs.fulfilled, (state, action) => {
        state.projectsLogs = action.payload.data.data;
        state.projectsLogsMeta = action.payload.data.meta;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getProjectsLogs.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getCompanyProjects
      .addCase(adminActions.getCompanyProjects.pending, (state) => {
        state.companyFeaturesWaiter = true;
      })
      .addCase(adminActions.getCompanyProjects.fulfilled, (state, action) => {
        state.companyProjects = action.payload.data;
        state.companyFeaturesWaiter = false;
      })
      .addCase(adminActions.getCompanyProjects.rejected, (state) => {
        state.companyFeaturesWaiter = false;
      })

      // getPaidBusinessCategories
      .addCase(adminActions.getPaidBusinessCategories.pending, (state) => {
        state.companyFeaturesWaiter = true;
      })
      .addCase(adminActions.getPaidBusinessCategories.fulfilled, (state, action) => {
        state.servicesDop = action.payload.data;
        state.companyFeaturesWaiter = false;
      })
      .addCase(adminActions.getPaidBusinessCategories.rejected, (state) => {
        state.companyFeaturesWaiter = false;
      })

      // getPaidProjectFeatures
      .addCase(adminActions.getPaidProjectFeatures.pending, (state) => {
        state.companyFeaturesWaiter = true;
      })
      .addCase(adminActions.getPaidProjectFeatures.fulfilled, (state, action) => {
        state.paidProjectFeatures = action.payload.data;
        state.companyFeaturesWaiter = false;
      })
      .addCase(adminActions.getPaidProjectFeatures.rejected, (state) => {
        state.companyFeaturesWaiter = false;
      })

      // getCompanyFeatures
      .addCase(adminActions.getCompanyFeatures.pending, (state) => {
        state.companyFeaturesWaiter = true;
      })
      .addCase(adminActions.getCompanyFeatures.fulfilled, (state, action) => {
        state.companyFeatures = action.payload.data;
        state.companyFeaturesWaiter = false;
      })
      .addCase(adminActions.getCompanyFeatures.rejected, (state) => {
        state.companyFeaturesWaiter = false;
      })

      // сompanyFeaturesActivate
      .addCase(adminActions.сompanyFeaturesActivate.pending, (state) => {
        state.companyFeaturesWaiterFix = true;
      })
      .addCase(adminActions.сompanyFeaturesActivate.fulfilled, (state, action) => {
        state.companyFeaturesWaiterFix = action.payload.data;
      })
      .addCase(adminActions.сompanyFeaturesActivate.rejected, (state) => {
        state.companyFeaturesWaiterFix = false;
      })

      // сompanyFeaturesDeactivate
      .addCase(adminActions.сompanyFeaturesDeactivate.pending, (state) => {
        state.companyFeaturesWaiterFix = true;
      })
      .addCase(adminActions.сompanyFeaturesDeactivate.fulfilled, (state, action) => {
        state.companyFeaturesWaiterFix = action.payload.data;
      })
      .addCase(adminActions.сompanyFeaturesDeactivate.rejected, (state) => {
        state.companyFeaturesWaiterFix = false;
      })

      // getUsersLogs
      .addCase(adminActions.getUsersLogs.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getUsersLogs.fulfilled, (state, action) => {
        state.usersLogs = action.payload.data.data;
        state.usersLogsMeta = action.payload.data.meta;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getUsersLogs.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getBazaLogs
      .addCase(adminActions.getBazaLogs.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.getBazaLogs.fulfilled, (state, action) => {
        state.bazaLogs = action.payload.data.data;
        state.bazaLogsMeta = action.payload.data.meta;
        state.fixWaiter = false;
      })
      .addCase(adminActions.getBazaLogs.rejected, (state) => {
        state.fixWaiter = false;
      })

      // userBlock
      .addCase(adminActions.userBlock.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(adminActions.userBlock.fulfilled, (state, action) => {
        state.users = state.users.map((item) => {
          if (item.id === action.meta.arg.user) {
            return { ...item, blocked: true };
          }
          return item;
        });
        state.fixWaiter = false;
      })
      .addCase(adminActions.userBlock.rejected, (state, action) => {
        state.fixWaiter = false;
      });
  },
});

export default adminSlice.reducer;
