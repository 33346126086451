import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import More from './more';
import Status from './status';

const Info = () => {
  const dispatch = useDispatch();

  const project = useSelector((state) => state.adminReducer.project);

  const handleSubmit = (values) => {
    dispatch(adminActions.updateProject(values));
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Обов'язкове поле"),
    description: Yup.string().required("Обов'язкове поле"),
    budget: Yup.number()
      .integer('Має бути цілим числом')
      .min(1, 'Бюджет має бути більше 0')
      .required("Обов'язкове поле"),
    screeningQuestions: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required("Обов'язкове поле"),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      id: project.id,
      name: project.name || '',
      description: project.description || '',
      screeningQuestions: project.screeningQuestions?.length ? project.screeningQuestions : [],
      budget: project.budget || 1,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => handleSubmit(values),
  });

  const addNewRow = () => {
    const formikScLength = formik.values.screeningQuestions.length;

    const newCert = [
      ...formik.values.screeningQuestions,
      {
        id: project.screeningQuestions[formikScLength]
          ? project.screeningQuestions[formikScLength].id
          : uuidv4(),
        text: '',
      },
    ];
    formik.setFieldValue('screeningQuestions', newCert);
  };

  const removeRow = () => {
    formik.setFieldValue('screeningQuestions', formik.values.screeningQuestions.slice(0, -1));
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="exp-content__top">Інформація про проект</div>
      <form onSubmit={formik.handleSubmit}>
        <div className="exp-content__wrap">
          <div className="exp-content__row">
            <div className="exp-content__row__left">
              <div className="label">
                Назва проекту <span>*</span>
              </div>
            </div>
            <div className="exp-content__row__right">
              <input
                className={`input ${formik.errors.name ? 'error' : ''}`}
                type="text"
                name="name"
                maxLength="50"
                value={formik.values.name}
                required
                onChange={formik.handleChange}
              />
              {formik.errors.name && formik.touched.name ? (
                <div className="error-text">{formik.errors.name}</div>
              ) : null}
            </div>
          </div>
          <div className="exp-content__row">
            <div className="exp-content__row__left">
              <div className="label">
                Опис проєкту <span>*</span>
              </div>
            </div>
            <div className="exp-content__row__right">
              <div className="textarea">
                <textarea
                  className={`input ${formik.errors.description ? 'error' : ''}`}
                  type="text"
                  name="description"
                  value={formik.values.description}
                  required
                  onChange={formik.handleChange}
                />
                {formik.errors.description && formik.touched.description ? (
                  <div className="error-text">{formik.errors.description}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="exp-content__row">
            <div className="exp-content__row__left">
              <div className="label">Скринінгові запитання</div>
            </div>
            <div className="exp-content__row__right">
              <FormikProvider value={formik}>
                <FieldArray
                  name="screeningQuestions"
                  render={() => (
                    <div className="create__blocks">
                      {formik.values.screeningQuestions.map((item, index) => (
                        <div className="create__block" key={item.id}>
                          <input
                            className={`input ${
                              formik.errors.screeningQuestions &&
                              formik.errors.screeningQuestions[index] &&
                              formik.errors.screeningQuestions[index].text
                                ? 'error'
                                : ''
                            }`}
                            type="text"
                            name={`screeningQuestions[${index}].text`}
                            value={formik.values.screeningQuestions[index].text}
                            required
                            onChange={formik.handleChange}
                          />
                          {formik.errors.screeningQuestions &&
                            formik.errors.screeningQuestions[index] &&
                            formik.errors.screeningQuestions[index].text && (
                              <div className="error-text">
                                {formik.errors.screeningQuestions &&
                                  formik.errors.screeningQuestions[index] &&
                                  formik.errors.screeningQuestions[index].text}
                              </div>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                />
                {formik.values.screeningQuestions.length < 5 && (
                  <button type="button" className="btn btn-primary" onClick={addNewRow}>
                    Додати
                  </button>
                )}
                {formik.values.screeningQuestions.length !== 0 && (
                  <button type="button" className="btn btn-danger" onClick={removeRow}>
                    Видалити
                  </button>
                )}
              </FormikProvider>
            </div>
          </div>

          <div className="exp-content__row mt-5">
            <div className="exp-content__row__left">
              <div className="label">
                Бюджет проекту <span>*</span>
              </div>
            </div>
            <div className="exp-content__row__right">
              <input
                id="budget"
                name="budget"
                placeholder="Бюджет проекту"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.budget}
                className={`${formik.errors.budget && formik.touched.budget ? 'error' : ''} input`}
              />
              <div className="error-text">
                {formik.errors.budget && formik.touched.budget && formik.errors.budget}
              </div>
              <button type="button" onClick={handleShow} className="btn next-btn btn-primary">
                Зберегти
              </button>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <div className="custom-modal-big custom-modal-big--admin">
              <button type="button" className="custom-modal__close">
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#modalClose`} />
                </svg>
              </button>
              <div className="not-auth__title">Ви дійсно хочете внести зміни?</div>
              <div className="not-auth__btn">
                <button
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                    handleClose();
                  }}
                  className="btn btn-primary btn--new"
                >
                  Так
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary btn--new"
                  onClick={() => {
                    formik.handleReset();
                    handleClose();
                  }}
                >
                  Ні
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </form>

      <Status />
      <More />
    </>
  );
};

export default Info;
