import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';

const OtborMainDop = () => {
  const { translate } = useTranslation();

  const waiter = useSelector((state) => state.otborReducer.waiter);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="otbor">
        {waiter ? (
          <Loader />
        ) : (
          <>
            <div className="otbor__title">{translate('otbor.modalTitle')}</div>
            <div className="otbor__text">
              <span>
                {translate('otbor.info')}{' '}
                <button type="button" onClick={handleShow}>
                  {translate('otbor.infoBtn')}
                </button>
              </span>
            </div>
            <div className="otbor__btn">
              <Link to="/otbor/test-am" className="btn btn-primary">
                {translate('otbor.dopTest')}
              </Link>

              <Link to="/projects/" className="btn btn-primary">
                {translate('back')}
              </Link>
            </div>
          </>
        )}
      </div>
      <Modal show={show} onHide={handleClose} className="invite">
        <Modal.Body>
          <div className="exp-modal info-modal">
            <div className="modal-header">
              <h5 className="modal-title">{translate('otbor.rules')}</h5>
              <button type="button" className="modal__close" onClick={handleClose}>
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#modalCloseWhite`} />
                </svg>
              </button>
            </div>

            <div
              className="modal-text"
              dangerouslySetInnerHTML={{ __html: translate('otbor.rulesText') }}
            />
            <div className="modal__otbor-bot">
              <Link to="/otbor/test" className="btn btn-primary">
                {translate('otbor.dopTest')}
              </Link>
              <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
                {translate('otbor.testLater')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtborMainDop;
