import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FixLoader } from 'src/components';
import { adminActions } from 'src/store/actions';

import { Company, Expert, SubHeader } from './components/index';

const DetailUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const userFixWaiter = useSelector((state) => state.adminReducer.userFixWaiter);
  const fixWaiter = useSelector((state) => state.adminReducer.fixWaiter);
  const user = useSelector((state) => state.adminReducer.user);
  const [tab, setTab] = useState(1);

  useEffect(() => {
    dispatch(adminActions.getUser(id));
  }, []);

  return (
    <>
      {userFixWaiter && <FixLoader />}
      {!fixWaiter && user && (
        <>
          <div className="admin_knowledge_header">
            {user.firstName} {user.lastName}
          </div>
          <div className="admin-user__contacts">
            <div>User ID: {user.id}</div>
            <div>Телефон: {user.phone}</div>
            <div>Email: {user.email}</div>
          </div>
          <SubHeader tab={tab} setTab={setTab} />
          {tab === 1 ? <Expert /> : <Company />}
        </>
      )}
    </>
  );
};

export default DetailUser;
