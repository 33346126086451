import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';

const SubMenu = () => {
  const categories = useSelector((state) => state.projectReducer.projectExpertCategories);
  const role = useSelector((state) => state.authReducer.user.role);

  return (
    <ul className="header__sub__menu">
      {categories &&
        categories.map((item) => (
          <li className="header__sub__menu__item" key={item.id}>
            <Link to={`${role === 2 ? '/freelancers/' : '/projects/'}${item.slug}`}>
              {item.name}
            </Link>

            {item.child && (
              <>
                <button className="header__sub__menu__item__m_btn" type="button">
                  <svg width="24" height="24">
                    <use xlinkHref={`${sprite}#mainArr`} />
                  </svg>
                </button>

                <ul className="header__sub__menu__level2">
                  {item.child.map((child) => (
                    <li key={child.id}>
                      <Link
                        to={`${role === 2 ? '/freelancers/' : '/projects/'}${item.slug}/${
                          child.slug
                        }`}
                      >
                        {child.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
    </ul>
  );
};

export default SubMenu;
