import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const DisabledModal = ({ handleClose, show }) => {
  const { translate } = useTranslation();
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" onClick={handleClose} className="custom-modal__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#modalClose`} />
            </svg>
          </button>

          <div className="choose__expert-modal">
            <div className="choose__expert-modal__title">{translate('MyOffers.modalTitle')}</div>
            <div className="choose__expert-modal__title2">{translate('MyOffers.modalText')}</div>

            <div className="choose__expert-modal__bot">
              <button type="button" className="btn btn-primary btn--new" onClick={handleClose}>
                {translate('close')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
DisabledModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default DisabledModal;
