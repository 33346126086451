import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'src/hooks';

const Timer = ({ hours = 0, minutes = 0, seconds = 0, action }) => {
  const { translate } = useTranslation();
  const [remainingTime, setRemainingTime] = useState(hours * 3600 + minutes * 60 + seconds);

  useEffect(() => {
    if (remainingTime === 0) {
      action();
    }
  }, [remainingTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  const formatTime = (time) => (time < 10 ? `0${time}` : time);

  const hoursLeft = Math.floor(remainingTime / 3600);
  const minutesLeft = Math.floor((remainingTime % 3600) / 60);
  const secondsLeft = remainingTime % 60;

  return (
    <div className="otbor__timer">
      <div className="otbor__timer__title">{translate('otbor.haveTimeToStart')}</div>
      <div className="otbor__timer__flex">
        <div className="otbor__timer__item">
          {formatTime(hoursLeft)}
          <div className="otbor__timer__type">{translate('otbor.hours')}</div>
        </div>
        <div className="otbor__timer__item">
          {formatTime(minutesLeft)}
          <div className="otbor__timer__type">{translate('otbor.min')}</div>
        </div>
        <div className="otbor__timer__item">
          {formatTime(secondsLeft)}
          <div className="otbor__timer__type">{translate('otbor.sec')}</div>
        </div>
      </div>
    </div>
  );
};

Timer.propTypes = {
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
};

export default Timer;
