import './style.scss';

import { IndexPage } from './components';

const Payment = () => (
  <div className="admin-wrap">
    <div className="wrap">
      <IndexPage />
    </div>
  </div>
);

export default Payment;
