import { Link } from 'react-router-dom';
import { Footer, HeaderContentPage } from 'src/components';
import { useTranslation } from 'src/hooks';

const AboutUs = () => {
  const { translate } = useTranslation();

  return (
    <>
      <div className="app base">
        <HeaderContentPage />
        <main className="main">
          <div className="wrap">
            <div className="base__main mt-3">
              <div className="proekt__search" />
              <nav className="prpb__breadcrumb">
                <ul>
                  <li>
                    <Link to="/">{translate('contentPage.main')}</Link>
                  </li>
                  <li>
                    <span>{translate('contentPage.about')}</span>
                  </li>
                </ul>
              </nav>
              <div className="exp-content">
                <div className="exp-content__edit">
                  <div className="exp-content__top exp-content__top__mess">
                    {translate('AboutUs.title')}
                  </div>
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: translate('AboutUs.content') }} />
                    <div className="d-flex justify-content-center mt-2 mb-5">
                      <Link to="/auth" className="btn btn-primary">
                        {translate('AboutUs.btn')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
