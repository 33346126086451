import { useSelector } from 'react-redux';
import { Link, Route, Routes } from 'react-router-dom';
import oldLogo from 'src/assets/img/old-img/logo-mobile.svg';
import { FixLoader } from 'src/components';

import {
  AddPhone,
  Breg,
  ChooseRole,
  Expreg,
  FogotPass,
  ResetPass,
  SignIn,
  SignUp,
  UpdatePhone,
  VerifyPhone,
} from './components';

const Auth = () => {
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);

  return (
    <>
      {fixWaiter && <FixLoader />}

      <div className="auth">
        <div className="wrapper">
          <div className="content">
            <header className="auth__header">
              <div className="container container--auth">
                <Link to="/" className="auth__logo">
                  <img src={oldLogo} alt="" />
                  ExpertLand
                </Link>
              </div>
            </header>
            <Routes>
              <Route path="/*" element={<SignIn />} />
              <Route path="sign-in" element={<SignIn />} />
              <Route path="sign-up" element={<SignUp />} />
              <Route path="add-phone" element={<AddPhone />} />
              <Route path="update-phone" element={<UpdatePhone />} />
              <Route path="fogotpass/*" element={<FogotPass />} />
              <Route path="reset-password/:token/:email" element={<ResetPass />} />
              <Route path="verify-phone" element={<VerifyPhone />} />

              <Route path="choose-role" element={<ChooseRole />} />
              <Route path="b-reg" element={<Breg />} />
              <Route path="exp-reg" element={<Expreg />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
