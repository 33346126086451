import './style.scss';

import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';

const NotAuth = ({ show, handleClose }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Body>
      <div className="custom-modal-big">
        <button type="button" className="proekt-modal__close" onClick={handleClose}>
          <svg className="icon" width="20px" height="20px">
            <use xlinkHref={`${sprite}#close`} />
          </svg>
        </button>
        <div className="not-auth__title">
          Для того, чтобы продолжить работу с платформой, необходимо зарегистрироваться
        </div>

        <div className="not-auth__btn">
          <Link to="/auth/sign-up/" className="btn btn-primary btn--new">
            Зарегистрироваться
          </Link>
          <button type="button" onClick={handleClose} className="btn btn-outline-primary btn--new">
            Назад
          </button>
        </div>
        <div className="not-auth__bot">
          Уже есть аккаунт? <Link to="/auth">Войти</Link>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

NotAuth.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NotAuth;
