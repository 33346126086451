import { createSlice } from '@reduxjs/toolkit';

import { chatActions } from '../actions';

const customSlice = createSlice({
  name: 'chat',
  initialState: {
    chatList: [],
    chat: [],
    messageCount: 0,
    messageRefresh: false,
    waiter: false,
    waiterChat: false,
    fixWaiter: false,
    errors: false,
  },
  reducers: {
    addMess(state, action) {
      state.chat = [...state.chat, action.payload];
    },
    addMessCount(state, action) {
      state.messageCount = action.payload;
      state.messageRefresh = !state.messageRefresh;
    },
    removeMessCount(state) {
      state.messageCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      // expertReadMess
      .addCase(chatActions.expertReadMess.fulfilled, () => {})

      // companyReadMess
      .addCase(chatActions.companyReadMess.fulfilled, () => {})

      // getChatListExpert
      .addCase(chatActions.getChatListExpert.pending, (state) => {
        state.waiter = true;
      })
      .addCase(chatActions.getChatListExpert.fulfilled, (state, action) => {
        state.chatList = action.payload.data;
        state.waiter = false;
      })
      .addCase(chatActions.getChatListExpert.rejected, (state) => {
        state.waiter = false;
      })

      // getChatListExpertRefresh
      .addCase(chatActions.getChatListExpertRefresh.fulfilled, (state, action) => {
        state.chatList = action.payload.data;
      })

      // getChatExpert
      .addCase(chatActions.getChatExpert.pending, (state) => {
        state.waiter = true;
      })
      .addCase(chatActions.getChatExpert.fulfilled, (state, action) => {
        state.chat = action.payload.data;
        state.waiter = false;
      })
      .addCase(chatActions.getChatExpert.rejected, (state) => {
        state.waiter = false;
      })

      // sendMessExpert
      .addCase(chatActions.sendMessExpert.pending, (state) => {
        state.waiterChat = true;
      })
      .addCase(chatActions.sendMessExpert.fulfilled, (state) => {
        state.waiterChat = false;
      })
      .addCase(chatActions.sendMessExpert.rejected, (state) => {
        state.waiterChat = false;
      })

      // getChatListCompany
      .addCase(chatActions.getChatListCompany.pending, (state) => {
        state.waiter = true;
      })
      .addCase(chatActions.getChatListCompany.fulfilled, (state, action) => {
        state.chatList = action.payload.data;
        state.waiter = false;
      })
      .addCase(chatActions.getChatListCompany.rejected, (state) => {
        state.waiter = false;
      })

      // getChatListCompanyRefresh
      .addCase(chatActions.getChatListCompanyRefresh.fulfilled, (state, action) => {
        state.chatList = action.payload.data;
      })

      // getChatCompany
      .addCase(chatActions.getChatCompany.pending, (state) => {
        state.waiter = true;
      })
      .addCase(chatActions.getChatCompany.fulfilled, (state, action) => {
        state.chat = action.payload.data;
        state.waiter = false;
      })
      .addCase(chatActions.getChatCompany.rejected, (state) => {
        state.waiter = false;
      })

      // sendMessCompany
      .addCase(chatActions.sendMessCompany.pending, (state) => {
        state.waiterChat = true;
      })
      .addCase(chatActions.sendMessCompany.fulfilled, (state) => {
        state.waiterChat = false;
      })
      .addCase(chatActions.sendMessCompany.rejected, (state) => {
        state.waiterChat = false;
      });
  },
});

export const { addMess, addMessCount, removeMessCount } = customSlice.actions;
export default customSlice.reducer;
