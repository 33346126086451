import { createSlice } from '@reduxjs/toolkit';

import { customActions } from '../actions';

const customSlice = createSlice({
  name: 'custom',
  initialState: {
    deleteReason: [],
    сompanySfera: [],
    compDone: [],
    compDonePag: null,
    expDone: [],
    expDonePag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    waiter: false,
    waiterMore: false,
    fixWaiter: false,
    errors: false,
    portfolio: [],
    knowledgeBase: [],
    reviewInfo: false,
    rating: {
      rating: 0,
      max: 2250,
      profileRating: 0,
      portfolioRating: 0,
      otherRating: 0,
    },
    reviews: [],
    reviewsPag: [],
    reviewsWaiter: false,
    reviewsWaiterMore: false,
    count: 0,
  },
  reducers: {
    clearErrors(state) {
      state.errors = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getDeleteReason
      .addCase(customActions.getDeleteReason.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(customActions.getDeleteReason.fulfilled, (state, action) => {
        state.waiter = false;
        state.deleteReason = action.payload.data;
        state.errors = false;
      })
      .addCase(customActions.getDeleteReason.rejected, (state) => {
        state.waiter = false;
      })

      // getСompanyСategories
      .addCase(customActions.getСompanyСategories.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(customActions.getСompanyСategories.fulfilled, (state, action) => {
        state.waiter = false;
        state.сompanySfera = action.payload.data;
        state.errors = false;
      })
      .addCase(customActions.getСompanyСategories.rejected, (state) => {
        state.waiter = false;
      })

      // getDoneProject
      .addCase(customActions.getDoneProject.pending, (state) => {
        state.waiter = true;
      })
      .addCase(customActions.getDoneProject.fulfilled, (state, action) => {
        state.expDone = action.payload.data.data;
        state.expDonePag = action.payload.data.meta;
        state.waiter = false;
      })
      .addCase(customActions.getDoneProject.rejected, (state) => {
        state.waiter = false;
      })

      // getDoneProjectMore
      .addCase(customActions.getDoneProjectMore.pending, (state) => {
        state.waiterMore = true;
      })
      .addCase(customActions.getDoneProjectMore.fulfilled, (state, action) => {
        state.expDone = [...state.expDone, ...action.payload.data.data];
        state.expDonePag = action.payload.data.meta;
        state.waiterMore = false;
      })
      .addCase(customActions.getDoneProjectMore.rejected, (state) => {
        state.waiterMore = false;
      })

      // getDoneProject
      .addCase(customActions.getDoneProjectExpert.pending, (state) => {
        state.waiter = true;
      })
      .addCase(customActions.getDoneProjectExpert.fulfilled, (state, action) => {
        state.expDone = action.payload.data.data;
        state.expDonePag = action.payload.data.meta;
        state.waiter = false;
      })
      .addCase(customActions.getDoneProjectExpert.rejected, (state) => {
        state.waiter = false;
      })

      // getDoneProjectMore
      .addCase(customActions.getDoneProjectExpertMore.pending, (state) => {
        state.waiterMore = true;
      })
      .addCase(customActions.getDoneProjectExpertMore.fulfilled, (state, action) => {
        state.expDone = [...state.expDone, ...action.payload.data.data];
        state.expDonePag = action.payload.data.meta;
        state.waiterMore = false;
      })
      .addCase(customActions.getDoneProjectExpertMore.rejected, (state) => {
        state.waiterMore = false;
      })

      // getDoneProjectCompany
      .addCase(customActions.getDoneProjectCompany.pending, (state) => {
        state.waiter = true;
      })
      .addCase(customActions.getDoneProjectCompany.fulfilled, (state, action) => {
        state.compDone = action.payload.data.data;
        state.compDonePag = action.payload.data.meta;
        state.waiter = false;
      })
      .addCase(customActions.getDoneProjectCompany.rejected, (state) => {
        state.waiter = false;
      })

      // getExpertPortfolio
      .addCase(customActions.getExpertPortfolio.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(customActions.getExpertPortfolio.fulfilled, (state, action) => {
        state.waiter = false;
        state.errors = false;
        state.portfolio = action.payload.data;
      })
      .addCase(customActions.getExpertPortfolio.rejected, (state) => {
        state.waiter = false;
      })

      // getExpertPortfolioMore
      .addCase(customActions.getExpertPortfolioMore.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(customActions.getExpertPortfolioMore.fulfilled, (state, action) => {
        state.waiter = false;
        state.errors = false;
        state.portfolio = action.payload.data;
      })
      .addCase(customActions.getExpertPortfolioMore.rejected, (state) => {
        state.waiter = false;
      })

      // getAllPortfolio
      .addCase(customActions.getAllPortfolio.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(customActions.getAllPortfolio.fulfilled, (state, action) => {
        state.portfolio = action.payload.data;
        state.waiter = false;
        state.errors = false;
      })
      .addCase(customActions.getAllPortfolio.rejected, (state, action) => {
        state.waiter = false;
        state.errors = action.payload.errors;
      })

      // deletePortFolio
      .addCase(customActions.deletePortFolio.pending, (state) => {
        state.waiter = true;
        state.errors = false;
        state.expertDetail = null;
      })
      .addCase(customActions.deletePortFolio.fulfilled, (state) => {
        state.waiter = false;
        state.errors = false;
      })
      .addCase(customActions.deletePortFolio.rejected, (state) => {
        state.waiter = false;
      })

      // setPortfolio
      .addCase(customActions.setPortfolio.pending, (state) => {
        state.waiter = true;
      })
      .addCase(customActions.setPortfolio.fulfilled, (state) => {
        state.waiter = false;
      })
      .addCase(customActions.setPortfolio.rejected, (state) => {
        state.waiter = false;
      })

      // editPortfolio
      .addCase(customActions.editPortfolio.pending, (state) => {
        state.waiter = true;
      })
      .addCase(customActions.editPortfolio.fulfilled, (state) => {
        state.waiter = false;
      })
      .addCase(customActions.editPortfolio.rejected, (state) => {
        state.waiter = false;
      })

      // knowledgeBase
      .addCase(customActions.knowledgeBase.pending, (state) => {
        state.waiter = true;
      })
      .addCase(customActions.knowledgeBase.fulfilled, (state, action) => {
        state.waiter = false;
        state.knowledgeBase = action.payload.data;
      })
      .addCase(customActions.knowledgeBase.rejected, (state) => {
        state.waiter = false;
      })

      // knowledgeBaseSearch
      .addCase(customActions.knowledgeBaseSearch.pending, (state) => {
        state.waiter = true;
        state.knowledgeBase = [];
      })
      .addCase(customActions.knowledgeBaseSearch.fulfilled, (state, action) => {
        state.waiter = false;
        state.knowledgeBase = action.payload.data;
      })
      .addCase(customActions.knowledgeBaseSearch.rejected, (state) => {
        state.waiter = false;
      })

      // getRatingInfo
      .addCase(customActions.getRatingInfo.pending, (state) => {
        state.waiter = true;
      })
      .addCase(customActions.getRatingInfo.fulfilled, (state, action) => {
        state.waiter = false;
        state.rating = action.payload.data;
      })
      .addCase(customActions.getRatingInfo.rejected, (state) => {
        state.waiter = false;
      })

      // getReviewsExpert
      .addCase(customActions.getReviewsExpert.pending, (state) => {
        state.reviewsWaiter = true;
      })
      .addCase(customActions.getReviewsExpert.fulfilled, (state, action) => {
        state.reviews = action.payload.data.data;
        state.reviewsPag = action.payload.data.meta;
        state.reviewsWaiter = false;
      })
      .addCase(customActions.getReviewsExpert.rejected, (state) => {
        state.reviewsWaiter = false;
      })

      // getReviewsExpertMore
      .addCase(customActions.getReviewsExpertMore.pending, (state) => {
        state.reviewsWaiterMore = true;
      })
      .addCase(customActions.getReviewsExpertMore.fulfilled, (state, action) => {
        state.reviews = [...state.reviews, ...action.payload.data.data];
        state.reviewsPag = action.payload.data.meta;
        state.reviewsWaiterMore = false;
      })
      .addCase(customActions.getReviewsExpertMore.rejected, (state) => {
        state.reviewsWaiterMore = false;
      })

      // getReviewsCompany
      .addCase(customActions.getReviewsCompany.pending, (state) => {
        state.reviewsWaiter = true;
      })
      .addCase(customActions.getReviewsCompany.fulfilled, (state, action) => {
        state.reviews = action.payload.data.data;
        state.reviewsPag = action.payload.data.meta;
        state.reviewsWaiter = false;
      })
      .addCase(customActions.getReviewsCompany.rejected, (state) => {
        state.reviewsWaiter = false;
      })

      // getReviewsCompanyMore
      .addCase(customActions.getReviewsCompanyMore.pending, (state) => {
        state.reviewsWaiterMore = true;
      })
      .addCase(customActions.getReviewsCompanyMore.fulfilled, (state, action) => {
        state.reviews = [...state.reviews, ...action.payload.data.data];
        state.reviewsPag = action.payload.data.meta;
        state.reviewsWaiterMore = false;
      })
      .addCase(customActions.getReviewsCompanyMore.rejected, (state) => {
        state.reviewsWaiterMore = false;
      })

      // getCountOnMainPage
      .addCase(customActions.getCountOnMainPage.pending, (state) => {
        state.reviewsWaiterMore = true;
      })
      .addCase(customActions.getCountOnMainPage.fulfilled, (state, action) => {
        state.count = action.payload.data;
        state.reviewsWaiterMore = false;
      })
      .addCase(customActions.getCountOnMainPage.rejected, (state) => {
        state.reviewsWaiterMore = false;
      });
  },
});

export const { clearErrors } = customSlice.actions;
export default customSlice.reducer;
