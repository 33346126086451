import { useSelector } from 'react-redux';

const Bcat = () => {
  const { businessCategories } = useSelector((state) => state.adminReducer.user.expert);

  return (
    <>
      <div className="exp-content__top">Категорії бізнесу</div>
      <div className="exp-content__wrap">
        {businessCategories.length ? (
          <div className="proekt__content__tags">
            {businessCategories.map((item) => (
              <div className="proekt__content__tags__item" key={item.id}>
                {item.name}
              </div>
            ))}
          </div>
        ) : (
          <>Немає категорій бізнесу</>
        )}
      </div>
    </>
  );
};
export default Bcat;
