import { useDispatch, useSelector } from 'react-redux';
import PfdLabel from 'src/assets/img/resume/2.png';
import { useTranslation } from 'src/hooks';
import { adminActions } from 'src/store/actions';

const Resume = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();

  const hasResume = useSelector((state) => state.adminReducer.user.expert.resume);

  const handleDispatchDelete = async () => {
    if (hasResume) {
      await dispatch(adminActions.deleteResume(hasResume.id));
    }
  };
  return (
    <>
      <div className="exp-content__top">Редагування резюме</div>
      {hasResume ? (
        <div className="exp-content__wrap exp-content__wrap--resume">
          <div className="upload-wrap">
            <div className="upload-wrap__col upload">
              <a href={hasResume.path} target="_blank" rel="noreferrer">
                {' '}
              </a>
              <img src={PfdLabel} alt="" />
              <div className="upload-wrap__text">{translate('Resume.downloadImg')}</div>
              <div className="upload-wrap__text upload-wrap__text--file">{hasResume.name}</div>
              <button
                className="upload-wrap__col__button"
                type="button"
                onClick={handleDispatchDelete}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3936 7.59497L7.60156 12.387"
                    stroke="#200E32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.3976 12.3898L7.60156 7.59277"
                    stroke="#200E32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.334 0.750244H5.665C2.644 0.750244 0.75 2.88924 0.75 5.91624V14.0842C0.75 17.1112 2.635 19.2502 5.665 19.2502H14.333C17.364 19.2502 19.25 17.1112 19.25 14.0842V5.91624C19.25 2.88924 17.364 0.750244 14.334 0.750244Z"
                    stroke="#200E32"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>Немає резюме</>
      )}
    </>
  );
};
export default Resume;
