import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ch1 from 'src/assets/img/old-img/auth/ch1.jpg';
import ch2 from 'src/assets/img/old-img/auth/ch2.jpg';
import { useTranslation } from 'src/hooks';

const ChooseRole = () => {
  const { translate } = useTranslation();
  const { expert, company } = useSelector((state) => state.authReducer.user);

  return (
    <div className="auth__wrap">
      <div className="container">
        <h1>{translate('Auth.chooseStatus')}</h1>
        <div className="auth-choose">
          {!company && (
            <div className="auth-choose__col">
              <Link to="/auth/b-reg/" className="btn-reset auth-choose__item">
                <div className="auth-choose__img" style={{ backgroundImage: `url(${ch1})` }} />
                <div className="auth-choose__wrap">
                  <div className="auth-choose__t">{translate('Auth.chooseStatus1')}</div>
                  <div className="auth-choose__t2">{translate('Auth.chooseStatus1Info')}</div>
                  <div className="auth-choose__t3">{translate('Auth.chooseStatus1Info2')}</div>
                </div>
              </Link>
              <div className="auth-choose__bot">
                <Link to="/auth/b-reg/" className="btn-reset btn-auth btn btn-primary">
                  {translate('Auth.chooseStatusBtn')}
                </Link>
              </div>
            </div>
          )}

          {!expert && (
            <div className="auth-choose__col">
              <Link to="/auth/exp-reg/" className="btn-reset auth-choose__item">
                <div className="auth-choose__img" style={{ backgroundImage: `url(${ch2})` }} />
                <div className="auth-choose__wrap">
                  <div className="auth-choose__t">{translate('Auth.chooseStatus2')}</div>
                  <div className="auth-choose__t2">{translate('Auth.chooseStatus2Info')}</div>
                  <div className="auth-choose__t3">{translate('Auth.chooseStatus2Info2')}</div>
                </div>
              </Link>
              <Link to="/auth/exp-reg/" className="auth-choose__bot">
                <button type="button" className="btn-reset btn-auth btn btn-primary">
                  {translate('Auth.chooseStatusBtn')}
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChooseRole;
