import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { adminActions } from 'src/store/actions';

const Activate = ({ hanbleDispatch }) => {
  const dispatch = useDispatch();
  const companyProjects = useSelector((state) => state.adminReducer.companyProjects);
  const companyFeaturesWaiter = useSelector((state) => state.adminReducer.companyFeaturesWaiter);
  const [modalProjectId, setModalProjectId] = useState('0');
  const [projectId, setProjectId] = useState('0');
  const companyId = useSelector((state) => state.adminReducer.user.company.id);
  const [isSub, setIsSub] = useState(false);
  const packagesArr = useSelector((state) =>
    state.adminReducer.companyFeatures ? state.adminReducer.companyFeatures.packages : [],
  );
  const packages = packagesArr.map((item) => ({
    ...item,
    check: false,
  }));

  const servicesArr = useSelector((state) =>
    state.adminReducer.companyFeatures ? state.adminReducer.companyFeatures.services : [],
  );
  const services = servicesArr.map((item) => ({
    ...item,
    check: false,
    count: item.multiply ? 1 : 0,
  }));

  const handleDisptchForProjects = () => {
    dispatch(adminActions.getCompanyFeatures(projectId));
  };

  useEffect(() => {
    handleDisptchForProjects();
  }, [projectId]);

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      packages: values.packages
        .filter((item) => item.check)
        .map((item) => ({ id: item.packageId })),
      services: values.services
        .filter((item) => item.check)
        .map((item) => {
          if (item.count) {
            return { id: item.serviceId, count: item.count };
          }
          return { id: item.serviceId };
        }),
    };
    if (values.project === '0') {
      delete data.project;
    }

    await dispatch(adminActions.сompanyFeaturesActivate(data));
    await hanbleDispatch();
    await handleDisptchForProjects();
    setIsSub(true);
    setTimeout(() => {
      setIsSub(false);
    }, 5000);
  };

  const formik = useFormik({
    initialValues: {
      company: companyId,
      project: projectId,
      packages,
      services,
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  // вибір проекту
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const chooseProject = companyProjects.filter((item) => item.id === Number(formik.values.project));

  const handlePlus = (item, index) => {
    const max = item.max || 0;
    const position = item.position || 0;
    formik.setFieldValue(`services[${index}].check`, true);
    if (max && item.count < max) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    } else if (position && item.count < position) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    } else if (!max && !position) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    }
  };

  const handleMinus = (item, index) => {
    formik.setFieldValue(`services[${index}].check`, true);
    if (item.count > 1) {
      formik.setFieldValue(`services[${index}].count`, item.count - 1);
    }
  };

  return (
    <>
      {isSub && (
        <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
          <svg
            className="bi flex-shrink-0 me-2"
            width="24"
            height="24"
            role="img"
            aria-label="Success:"
            viewBox="0 0 16 16"
            fill="currentColor"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
          <div>Платні фукнції активовано</div>
        </div>
      )}
      {companyFeaturesWaiter ? (
        <Loader />
      ) : (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className="choose-pr">Оберіть проєкт</div>
            <div className="choose-pr__list">
              <div className="choose-pr__item">
                <span>
                  {chooseProject.length > 0 && chooseProject[0].name}
                  {formik.values.project === '0' && (
                    <>
                      {companyProjects.length > 0 ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            handleShow();
                          }}
                        >
                          Обрати
                        </button>
                      ) : (
                        <>Немає проєктів</>
                      )}
                    </>
                  )}
                </span>
                {formik.values.project !== '0' && (
                  <button type="button" className="btn btn-primary" onClick={handleShow}>
                    Змінити
                  </button>
                )}
              </div>
            </div>

            {formik.values.packages.length > 0 && (
              <div className="tarif">
                {formik.values.packages.map((item, index) => (
                  <div
                    className={`tarif__item ${item.marker ? `tarif__item--popular` : ''} ${
                      item.disabled ? `tarif__item--disabled` : ''
                    }`}
                    key={item.packageId}
                  >
                    {item.marker && <div className="tarif__popular">{item.marker}</div>}

                    <div className="tarif__name">
                      {item.title}
                      <button
                        type="button"
                        data-tooltip-id={`tarif-tooltip${item.packageId}`}
                        data-tooltip-content={item.tooltip}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref={`${sprite}#paymentLabel`} />
                        </svg>
                      </button>
                      <Tooltip id={`tarif-tooltip${item.packageId}`} />
                    </div>
                    <div
                      className="tarif__des"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <div className="tarif__price mb-5">
                      <div className="tarif__price__numb">{item.price} грн</div>
                      <div className="tarif__price__type">{item.per}</div>
                    </div>
                    <div className="tarif__btn">
                      {item.check ? (
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => formik.setFieldValue(`packages[${index}].check`, false)}
                        >
                          Відмінити
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => formik.setFieldValue(`packages[${index}].check`, true)}
                        >
                          Обрати
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="payment__dop" />

            {formik.values.services.length > 0 && (
              <div className="payment__list">
                {formik.values.services.length &&
                  formik.values.services.map((item, index) => (
                    <div
                      className={`payment__row ${item.disabled ? 'disabled' : ''}`}
                      key={item.serviceId}
                    >
                      <label
                        className="form-check form-check--payment"
                        htmlFor={`flexCheckDefault${item.serviceId}`}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name={`services[${index}].check`}
                          onChange={formik.handleChange}
                          id={`flexCheckDefault${item.serviceId}`}
                          checked={item.check}
                        />
                      </label>
                      <div className="payment__name">
                        {item.title}
                        <button
                          type="button"
                          data-tooltip-id={`payment-tooltip${item.serviceId}`}
                          data-tooltip-content={item.tooltip}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <use xlinkHref={`${sprite}#paymentLabel`} />
                          </svg>
                        </button>
                        <Tooltip id={`payment-tooltip${item.serviceId}`} />

                        <span className="payment__name__position">
                          {item.positionText}
                          {item.position}
                        </span>
                      </div>

                      <div className="payment__count">
                        {item.multiply && (
                          <>
                            <div className="payment__count__flex">
                              <button
                                type="button"
                                className="minus"
                                onClick={() => handleMinus(item, index)}
                              >
                                -
                              </button>
                              <div className="payment__count__numb">{item.count}</div>
                              <button
                                type="button"
                                className="plus"
                                onClick={() => handlePlus(item, index)}
                              >
                                +
                              </button>
                            </div>
                            <div className="payment__count__text">{item.choosingLine}</div>
                          </>
                        )}
                      </div>

                      <div className="payment__price">
                        <div className="payment__price__left">{item.priceFormatted}</div>
                        <div className="payment__price__right">{item.per}</div>
                      </div>
                    </div>
                  ))}
              </div>
            )}

            <div className="payment__total__bot">
              <button type="submit" className="btn btn-primary">
                Застосувати
              </button>
            </div>
          </form>

          {companyProjects.length > 0 && (
            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                <div className="exp-modal paid-modal">
                  <button type="button" className="del-portfolio-modal__btn" onClick={handleClose}>
                    <svg width="20" height="20" viewBox="0 0 20 20">
                      <use xlinkHref={`${sprite}#modalClose`} />
                    </svg>
                  </button>
                  <div className="paid-modal__title">Вибір проєкту</div>
                  <div className="paid-modal__body">
                    {companyProjects.map((item) => (
                      <label
                        className="paid-modal__checkbox"
                        htmlFor={`mch${item.id}`}
                        key={item.id}
                      >
                        <input
                          type="radio"
                          defaultChecked={formik.values.project === String(item.id)}
                          id={`mch${item.id}`}
                          name="project"
                          value={item.id}
                          onChange={(e) => setModalProjectId(e.target.value)}
                        />
                        <div className="paid-modal__checkbox__check">
                          <svg width="18" height="13">
                            <use xlinkHref={`${sprite}#modalCheckbox`} />
                          </svg>
                        </div>
                        <span className="paid-modal__checkbox__text">{item.name}</span>
                      </label>
                    ))}

                    <div className="paid-modal__action">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setProjectId(modalProjectId);
                          handleClose();
                        }}
                      >
                        Далі
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={handleClose}
                      >
                        Назад
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </>
      )}
    </>
  );
};
Activate.propTypes = {
  hanbleDispatch: PropTypes.func.isRequired,
};
export default Activate;
