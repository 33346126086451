import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { expertProjectActions } from 'src/store/actions';

const DeclineModal = ({ handleClose, show, id }) => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const handleDecline = async () => {
    await dispatch(expertProjectActions.declineProject({ invitation: id }));
    handleClose();
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" onClick={handleClose} className="custom-modal__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#modalClose`} />
            </svg>
          </button>

          <div className="choose__expert-modal">
            <div className="choose__expert-modal__title">{translate('DeclineModal.title1')}</div>
            <div className="choose__expert-modal__info text-center">
              {translate('DeclineModal.text1')}{' '}
              <Link to="/my-offers/" onClick={handleClose}>
                {translate('DeclineModal.btn1')}
              </Link>
            </div>
            <div className="choose__expert-modal__bot">
              <button type="button" className="btn btn-primary btn--new" onClick={handleDecline}>
                {translate('DeclineModal.yes')}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={handleClose}
              >
                {translate('DeclineModal.no')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
DeclineModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
export default DeclineModal;
