import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import * as Yup from 'yup';

const Contacts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const [isSub, setIsSub] = useState(false);
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);
  const waiter = useSelector((state) => state.authReducer.waiter);
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const role = useSelector((state) => state.authReducer.user.role);
  const errors = useSelector((state) => state.authReducer.errors);
  const { phone, email } = useSelector((state) => state.authReducer.user);

  const countries = useSelector((state) =>
    state.authReducer.countries.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  );

  const countriesList = useSelector((state) => state.authReducer.countries);
  const country = useSelector((state) =>
    role === 1 ? state.authReducer.user.expert.countryId : state.authReducer.user.company.countryId,
  );

  const city = useSelector((state) =>
    role === 1 ? state.authReducer.user.expert.cityId : state.authReducer.user.company.cityId,
  );
  const { telegram, whatsapp, viber } = useSelector((state) =>
    role === 1 ? state.authReducer.user.expert : state.authReducer.user.company,
  );
  const facebookMessenger = useSelector((state) =>
    role === 1
      ? state.authReducer.user.expert.facebook_messenger
      : state.authReducer.user.company.facebook_messenger,
  );

  const sities = useSelector((state) =>
    state.authReducer.sities.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  );

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(authActions.getCountries());
    }
    if (sities.length === 0) {
      dispatch(authActions.getCities());
    }
    dispatch(authActions.getProfileRefresh());
  }, []);

  const contactSchema = Yup.object().shape({
    phone: Yup.string()
      .test('len1', `${translate('Valid.minimum')} 12 ${translate('Valid.cifr')}`, (tel) => {
        if (tel) {
          const phoneNumb = tel.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
          return phoneNumb.length >= 12;
        }
        return false;
      })
      .test('validTel1', `${translate('Valid.notValidTel')}`, (tel) => {
        if (tel) {
          const phoneNumb = tel.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
          return phoneNumb.split('0').length - 1 < 9;
        }
        return false;
      }),
    whatsapp: Yup.string()
      .test(
        'len2',
        `${translate('Valid.minimum')} 12 ${translate('Valid.cifr')}`,
        (tel, context) => {
          if (context.parent.whatsappCh.length === 0) {
            return true;
          }
          if (tel) {
            const phoneNumb = tel.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
            return phoneNumb.length >= 12;
          }
          return false;
        },
      )
      .test('validTel2', `${translate('Valid.notValidTel')}`, (tel, context) => {
        if (context.parent.whatsappCh.length === 0) {
          return true;
        }
        if (tel) {
          const phoneNumb = tel.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
          return phoneNumb.split('0').length - 1 < 9;
        }
        return false;
      })
      .nullable(),
    viber: Yup.string()
      .test(
        'len3',
        `${translate('Valid.minimum')} 12 ${translate('Valid.cifr')}`,
        (tel, context) => {
          if (context.parent.viberCh.length === 0) {
            return true;
          }
          if (tel) {
            const phoneNumb = tel.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
            return phoneNumb.length >= 12;
          }
          return false;
        },
      )
      .test('validTel3', `${translate('Valid.notValidTel')}`, (tel, context) => {
        if (context.parent.viberCh.length === 0) {
          return true;
        }
        if (tel) {
          const phoneNumb = tel.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
          return phoneNumb.split('0').length - 1 < 9;
        }
        return false;
      })
      .nullable(),
    facebookMessenger: Yup.string()
      .test(
        'len4',
        `${translate('Valid.minimum')} 12 ${translate('Valid.cifr')}`,
        (tel, context) => {
          if (context.parent.facebookMessengerCh.length === 0) {
            return true;
          }
          if (tel) {
            const phoneNumb = tel.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
            return phoneNumb.length >= 12;
          }
          return false;
        },
      )
      .test('validTel4', `${translate('Valid.notValidTel')}`, (tel, context) => {
        if (context.parent.facebookMessengerCh.length === 0) {
          return true;
        }
        if (tel) {
          const phoneNumb = tel.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
          return phoneNumb.split('0').length - 1 < 9;
        }
        return false;
      })
      .nullable(),
    city: Yup.string().required(translate('Valid.req')).nullable(),
    email: Yup.string().email(translate('Valid.email')).required(translate('Valid.req')).nullable(),
    country: Yup.string().required(translate('Valid.req')).nullable(),
    telegram: Yup.string()
      .test('len', `${translate('Valid.req')}`, (val, context) => {
        if (context.parent.telegramCh.length === 0) {
          return true;
        }
        if (val) {
          return val.length >= 1;
        }
        return false;
      })
      .nullable(),
  });

  const checkNeedVer = (data, payload) => {
    let newPhone;
    let newEmail;
    if (data.phone !== payload.data.phone) {
      newPhone = data.phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
    }
    if (data.email !== payload.data.email) {
      newEmail = data.email;
    }
    if (newPhone || newEmail) {
      navigate(`/verify/?phone=${newPhone || ''}&email=${newEmail || ''}`);
    } else if (firstSignUp) {
      if (role === 1) {
        navigate('/user/spec/');
      } else {
        navigate('/user/sfera/');
      }
    }
  };

  const handleSubmit = async (data) => {
    dispatch(authActions.contactsChange({ data, role })).then(({ payload }) => {
      if (payload.status === 200) {
        setIsSub(true);
        setTimeout(() => {
          setIsSub(false);
        }, 5000);
        checkNeedVer(data, payload);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      phone,
      email,
      country,
      city,
      whatsappCh: whatsapp ? ['1'] : [],
      viberCh: viber ? ['1'] : [],
      facebookMessengerCh: facebookMessenger ? ['1'] : [],
      telegramCh: telegram ? ['1'] : [],
      whatsapp: whatsapp || '',
      viber: viber || '',
      facebookMessenger: facebookMessenger || '',
      telegram: telegram || '',
    },
    enableReinitialize: true,
    validationSchema: contactSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const citiesRef = useRef();

  let sitiesFilter = countriesList.filter((item) => item.id === formik.values.country);
  if (sitiesFilter.length) {
    sitiesFilter = sitiesFilter[0].cities.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }

  useEffect(() => {
    if (citiesRef.current) {
      citiesRef.current.clearValue();
    }
  }, [formik.values.country]);

  return (
    <div className="exp-content__edit">
      <div className="exp-content__top">{translate('Contacts.title')}</div>

      <div className="exp-content__margin">
        <div className="exp-content__title">
          <h3>{translate('Contacts.subtitle')}</h3>
        </div>
        {waiter ? (
          <Loader />
        ) : (
          <form className="exp-content__wrap" onSubmit={formik.handleSubmit}>
            <div className="exp-content__row exp-content__row--phone">
              <div className="exp-content__row__left">
                <div className="label">
                  {translate('Contacts.tel')} <span>*</span>
                </div>
              </div>
              <div className="exp-content__row__right">
                <InputMask
                  mask="+380-99-999-99-99"
                  placeholder="+380"
                  name="phone"
                  value={formik.values.phone || ''}
                  className={`input ${
                    (errors.phone && formik.touched.phone) ||
                    (formik.errors.phone && formik.touched.phone)
                      ? 'error'
                      : ''
                  }`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <div className="invalid-feedback">{formik.errors.phone}</div>
                )}

                {errors.phone && formik.touched.phone && (
                  <div className="invalid-feedback">{errors.phone[0]}</div>
                )}
              </div>
            </div>
            <div className="exp-content__row">
              <div className="exp-content__row__left">
                <div className="label">
                  {translate('Contacts.emailField')} <span>*</span>
                </div>
              </div>
              <div className="exp-content__row__right">
                <input
                  className={`input ${
                    (errors.email && formik.touched.email) ||
                    (formik.errors.email && formik.touched.email)
                      ? 'error'
                      : ''
                  }`}
                  name="email"
                  type="email"
                  value={formik.values.email || ''}
                  placeholder={translate('Contacts.email')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
                {errors.email && formik.touched.email && (
                  <div className="invalid-feedback">{errors.email[0]}</div>
                )}
              </div>
            </div>
            <div className="exp-content__row">
              <div className="exp-content__row__left">
                <div className="label">{translate('Contacts.country')}</div>
              </div>
              <div className="exp-content__row__right">
                <Select
                  options={countries}
                  disabled
                  classNamePrefix="react-select"
                  isDisabled
                  className="react-select react-select--no-shadow select-disabled"
                  placeholder={translate('Auth.country')}
                  value={countries.filter(({ value }) => value === formik.values.country)}
                  onChange={(e) => formik.setFieldValue(`country`, e.value)}
                />
              </div>
            </div>
            {formik.values.country && sitiesFilter && (
              <div className="exp-content__row">
                <div className="exp-content__row__left">
                  <div className="label">{translate('Contacts.city')}</div>
                </div>
                <div className="exp-content__row__right">
                  <Select
                    ref={citiesRef}
                    options={sitiesFilter}
                    classNamePrefix="react-select"
                    className="react-select react-select--no-shadow"
                    placeholder={translate('Auth.city')}
                    value={sitiesFilter.filter(({ value }) => value === formik.values.city)}
                    onChange={(e) => {
                      if (e) {
                        formik.setFieldValue(`city`, e.value);
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div className="exp-content__row">
              <div className="exp-content__row__left">
                <div className="label">{translate('Contacts.mess')}</div>
              </div>
              <div className="exp-content__row__right">
                <label htmlFor="whatsapp" className="contact__checkbox">
                  <input
                    type="checkbox"
                    id="whatsapp"
                    name="whatsappCh"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={!!formik.values.whatsappCh.length}
                    value="1"
                  />
                  <div className="contact__checkbox__input">
                    <svg width="18" height="13">
                      <use xlinkHref={`${sprite}#modalCheckbox`} />
                    </svg>
                  </div>
                  <div className="contact__checkbox__right">
                    <div className="contact__checkbox__img">
                      <svg width="28" height="28">
                        <use xlinkHref={`${sprite}#whatsapp`} />
                      </svg>
                    </div>
                    WhatsApp
                  </div>
                </label>
                <label htmlFor="telegram" className="contact__checkbox">
                  <input
                    type="checkbox"
                    id="telegram"
                    name="telegramCh"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value="1"
                    checked={!!formik.values.telegramCh.length}
                    maxLength={50}
                  />
                  <div className="contact__checkbox__input">
                    <svg width="18" height="13">
                      <use xlinkHref={`${sprite}#modalCheckbox`} />
                    </svg>
                  </div>
                  <div className="contact__checkbox__right">
                    <div className="contact__checkbox__img">
                      <svg width="26" height="24">
                        <use xlinkHref={`${sprite}#telegram`} />
                      </svg>
                    </div>
                    Telegram
                  </div>
                </label>
                <label htmlFor="viber" className="contact__checkbox">
                  <input
                    type="checkbox"
                    id="viber"
                    name="viberCh"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value="1"
                    checked={!!formik.values.viberCh.length}
                  />
                  <div className="contact__checkbox__input">
                    <svg width="18" height="13">
                      <use xlinkHref={`${sprite}#modalCheckbox`} />
                    </svg>
                  </div>
                  <div className="contact__checkbox__right">
                    <div className="contact__checkbox__img">
                      <svg width="25" height="26">
                        <use xlinkHref={`${sprite}#viber`} />
                      </svg>
                    </div>
                    viber
                  </div>
                </label>
                <label htmlFor="facebook" className="contact__checkbox">
                  <input
                    type="checkbox"
                    id="facebook"
                    name="facebookMessengerCh"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value="1"
                    checked={!!formik.values.facebookMessengerCh.length}
                  />
                  <div className="contact__checkbox__input">
                    <svg width="18" height="13">
                      <use xlinkHref={`${sprite}#modalCheckbox`} />
                    </svg>
                  </div>
                  <div className="contact__checkbox__right">
                    <div className="contact__checkbox__img">
                      <svg width="27" height="27">
                        <use xlinkHref={`${sprite}#facebook`} />
                      </svg>
                    </div>
                    Facebook Messenger
                  </div>
                </label>
              </div>
            </div>

            {formik.values.whatsappCh.length > 0 && (
              <div className="exp-content__row">
                <div className="exp-content__row__left">
                  <div className="label">
                    {translate('Contacts.phone')} WhatsApp <span>*</span>
                  </div>
                </div>
                <div className="exp-content__row__right">
                  <InputMask
                    mask="+380-99-999-99-99"
                    placeholder="+380"
                    name="whatsapp"
                    className={`input ${
                      formik.errors.whatsapp && formik.touched.whatsapp ? 'error' : ''
                    }`}
                    defaultValue={formik.values.whatsapp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="invalid-feedback">
                    {formik.errors.whatsapp && formik.touched.whatsapp && formik.errors.whatsapp}
                  </div>
                </div>
              </div>
            )}

            {formik.values.telegramCh.length > 0 && (
              <div className="exp-content__row">
                <div className="exp-content__row__left">
                  <div className="label">
                    {translate('Contacts.name')} <span>*</span>
                  </div>
                </div>
                <div className="exp-content__row__right">
                  <input
                    className={`input ${
                      (errors.telegram && formik.touched.telegram) ||
                      (formik.errors.telegram && formik.touched.telegram)
                        ? 'error'
                        : ''
                    }`}
                    name="telegram"
                    defaultValue={formik.values.telegram}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={50}
                  />
                  {formik.errors.telegram && formik.touched.telegram && (
                    <div className="invalid-feedback">{formik.errors.telegram}</div>
                  )}

                  {errors.phone && formik.touched.telegram && (
                    <div className="invalid-feedback">{errors.telegram[0]}</div>
                  )}
                </div>
              </div>
            )}

            {formik.values.viberCh.length > 0 && (
              <div className="exp-content__row">
                <div className="exp-content__row__left">
                  <div className="label">
                    {translate('Contacts.phone')} Viber <span>*</span>
                  </div>
                </div>
                <div className="exp-content__row__right">
                  <InputMask
                    mask="+380-99-999-99-99"
                    placeholder="+380"
                    name="viber"
                    defaultValue={formik.values.viber}
                    className={`input ${
                      formik.errors.viber && formik.touched.viber ? 'error' : ''
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="invalid-feedback">
                    {formik.errors.viber && formik.touched.viber && formik.errors.viber}
                  </div>
                </div>
              </div>
            )}

            {formik.values.facebookMessengerCh.length > 0 && (
              <div className="exp-content__row">
                <div className="exp-content__row__left">
                  <div className="label">
                    {translate('Contacts.phone')} Facebook Messenger <span>*</span>
                  </div>
                </div>
                <div className="exp-content__row__right">
                  <InputMask
                    mask="+380-99-999-99-99"
                    placeholder="+380"
                    name="facebookMessenger"
                    defaultValue={formik.values.facebookMessenger}
                    className={`input ${
                      formik.errors.facebookMessenger && formik.touched.facebookMessenger
                        ? 'error'
                        : ''
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="invalid-feedback">
                    {formik.errors.facebookMessenger &&
                      formik.touched.facebookMessenger &&
                      formik.errors.facebookMessenger}
                  </div>
                </div>
              </div>
            )}

            <div className="exp-content__row">
              <div className="exp-content__row__left" />
              <div className="exp-content__row__right">
                <button className="btn btn-primary" type="submit">
                  {firstSignUp ? translate('next') : translate('save')}
                </button>
                {isSub && !errors && !fixWaiter && (
                  <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
                    <svg
                      className="bi flex-shrink-0 me-2"
                      width="24"
                      height="24"
                      role="img"
                      aria-label="Success:"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    <div>{translate('Contacts.success')}</div>
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Contacts;
