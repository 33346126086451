import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const Search = ({ openMobSearch }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const role = useSelector((state) => state.authReducer.user.role);
  const [name, setName] = useState('');

  const handleSub = (e) => {
    e.preventDefault();
    if (role === 1) {
      navigate(`/projects?name=${name}`);
    } else {
      navigate(`/freelancers?name=${name}`);
    }
  };
  return (
    <form className={`header__search ${openMobSearch ? 'active' : ''}`} onSubmit={handleSub}>
      <input
        type="text"
        placeholder={role === 1 ? translate('Header.searchExp') : translate('Header.searchComp')}
        defaultValue={name}
        onChange={(e) => setName(e.target.value)}
      />
      <button type="button">
        <svg width="17.5px" height="17.5px">
          <use xlinkHref={`${sprite}#search`} />
        </svg>
      </button>
    </form>
  );
};
Search.propTypes = {
  openMobSearch: PropTypes.bool.isRequired,
};
export default Search;
