import { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import phoneDefault from 'src/assets/img/about/about-fon.png';
import avatarDefault from 'src/assets/img/avatar-default.png';

import AvatarUpload from './avatar-upload';
import CoverUpload from './cover-upload';

const ExpAbout = () => {
  const { avatar, profileCover } = useSelector((state) => state.adminReducer.user.company);

  const [showAvatar, setShowAvatar] = useState(false);
  const handleCloseAvatar = () => setShowAvatar(false);
  const handleShowAvatar = () => setShowAvatar(true);

  const [showCover, setShowCover] = useState(false);
  const handleCloseCover = () => setShowCover(false);
  const handleShowCover = () => setShowCover(true);

  return (
    <>
      <div
        className="exp__about"
        style={{ backgroundImage: `url('${profileCover || phoneDefault}')` }}
      >
        <div
          className="exp__about__img"
          style={{ backgroundImage: `url(${avatar || avatarDefault})` }}
        />
        <button type="button" className="btn btn-primary" onClick={handleShowAvatar}>
          Змінити логотип
        </button>
      </div>
      <div className="about__sett">
        <div className="about__sett__left" />
        <div className="about__sett__right">
          <div className="custom-select--about">
            <button type="button" className="btn btn-primary" onClick={handleShowCover}>
              Змінити обкладинку
            </button>
          </div>
        </div>
      </div>

      <AvatarUpload show={showAvatar} handleClose={handleCloseAvatar} />
      <CoverUpload show={showCover} handleClose={handleCloseCover} />
    </>
  );
};

export default ExpAbout;
