import { useSelector } from 'react-redux';
import { useTranslation } from 'src/hooks';

import CompAbout from './components/comp-about';
import ExpAbout from './components/exp-about';

const About = () => {
  const { translate } = useTranslation();
  const { role } = useSelector((state) => state.authReducer.user);

  return (
    <div className="exp-content__edit">
      <div className="exp-content__top">{translate('About.title')}</div>

      {role === 1 ? <ExpAbout /> : <CompAbout />}
    </div>
  );
};

export default About;
