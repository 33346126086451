import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from 'src/socketContext';
import { addMessCount } from 'src/store/reducers/chat.reducer';

const MessageSoket = () => {
  const socket = useContext(SocketContext);
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.authReducer.user.role);
  const expertId = useSelector((state) =>
    state.authReducer.user.expert ? state.authReducer.user.expert.id : 0,
  );
  const companyId = useSelector((state) =>
    state.authReducer.user.company ? state.authReducer.user.company.id : 0,
  );

  const handleRefreshMess = (data) => {
    if (ifAuth && role) {
      dispatch(addMessCount(data));
    }
  };

  useEffect(() => {
    socket.on('connect', () => {});
    if (ifAuth) {
      if (role === 1) {
        socket.emit('subscribe', {
          expertId,
        });

        socket.on(`expertChatList.${expertId}`, (data) => {
          handleRefreshMess(data);
        });
      } else if (companyId) {
        socket.emit('subscribe', {
          companyId,
        });

        socket.on(`companyChatList.${companyId}`, (data) => {
          handleRefreshMess(data);
        });
      }
    }
  }, [role, ifAuth, socket]);

  return false;
};
export default MessageSoket;
