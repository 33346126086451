import { PropTypes } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import avatarDefault from 'src/assets/img/proekt/avatar-default.jpg';
import sprite from 'src/assets/img/sprite.svg';
import { FavoriteProjectModal, Loader, RejectProjectModal } from 'src/components';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

import NotActiveModal from './not-active-modal';

const Detail = ({ handleReject, handleFavorite }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const projectId = query.get('detail');
  const project = useSelector((state) => state.projectReducer.projectDetail);
  const candidates = useSelector((state) => state.projectReducer.projectDetail?.candidates);

  const detailRef = useRef();
  const rejectRef = useRef();
  const favRef = useRef();

  const userCanSend = useSelector(
    (state) =>
      state.authReducer.user.expert && state.authReducer.user.expert.availableProjectRequests > 0,
  );

  useEffect(() => {
    if (detailRef.current) {
      window.scrollTo({
        top: detailRef.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
    dispatch(projectActions.getProjectsById(projectId));
  }, [projectId]);

  // reject and favorite
  const [handleRejectModal, setHandleRejectModal] = useState(0);
  const [isReject, setIsReject] = useState(false);
  const [rejectModalXY, setRejectModalXY] = useState({ pageX: 0, pageY: 0 });

  const [handleFavoriteModal, setHandleFavoriteModal] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteModalXY, setFavoriteModalXY] = useState({ pageX: 0, pageY: 0 });

  const favorite = (id) => {
    handleFavorite(id);
    favRef.current.classList.toggle('active');
    rejectRef.current.classList.remove('active');
  };

  const reject = (id) => {
    handleReject(id);
    rejectRef.current.classList.toggle('active');
    favRef.current.classList.remove('active');
  };

  const ifActive = !!useSelector(
    (state) =>
      state.authReducer.user.expert && state.authReducer.user.expert.profile_status.code !== 'NA',
  );
  const [notActive, setNotActive] = useState(false);
  const showNotActive = () => setNotActive(true);
  const hideNotActive = () => setNotActive(false);

  return (
    <>
      <div className="proekt__right proekt__right--detail" ref={detailRef}>
        {!project ? (
          <Loader />
        ) : (
          <div className="proekt__detail">
            <div className="proekt__name">
              {project.name}
              <div className="proekt__actios">
                <button
                  type="button"
                  className={project.rejected ? 'active' : ''}
                  onClick={(e) => {
                    setRejectModalXY({ pageX: e.pageX, pageY: e.pageY });
                    setIsReject(project.rejected);
                    setHandleRejectModal(project.id);
                  }}
                  ref={rejectRef}
                >
                  <svg width="13.2px" height="13.2px">
                    <use xlinkHref={`${sprite}#dislike`} />
                  </svg>
                  <svg width="13.2px" height="13.2px" className="active-svg">
                    <use xlinkHref={`${sprite}#dislike-active`} />
                  </svg>
                </button>
                <button
                  type="button"
                  className={project.favorite ? 'active' : ''}
                  onClick={(e) => {
                    setFavoriteModalXY({
                      pageX: e.pageX,
                      pageY: e.pageY,
                    });
                    setIsFavorite(project.favorite);
                    setHandleFavoriteModal(project.id);
                  }}
                  ref={favRef}
                >
                  <svg width="13.2px" height="13.2px">
                    <use xlinkHref={`${sprite}#like`} />
                  </svg>
                  <svg width="13.2px" height="13.2px" className="active-svg">
                    <use xlinkHref={`${sprite}#like-active`} />
                  </svg>
                </button>
              </div>
            </div>

            <div className="proekt__content">
              <div className="proekt__content__top">
                <div className="proekt__content__left">
                  <div className="proekt__content__status">{translate('Projects.detailComp')}</div>
                  <div className="proekt__content__person">
                    <Link
                      to={`/employers/${project.company.slug}`}
                      className="proekt__content__avatar"
                      style={{ backgroundImage: `url(${project.company.avatar || avatarDefault}` }}
                    />
                    <Link
                      to={`/employers/${project.company.slug}`}
                      className="proekt__content__person__name"
                    >
                      {project.company.companyName}
                    </Link>
                    <div className="proekt__location">
                      <svg width="14" height="20">
                        <use xlinkHref={`${sprite}#location`} />
                      </svg>
                      {project.company.country && project.company.country.name}
                      {project.company.city && `, ${project.company.city.name}`}
                    </div>
                  </div>
                  <div className="proekt__tags">
                    {!project.hidden && (
                      <div className="proekt__tags__item">
                        <span className="tags-point">
                          {project.budget} {translate('projectsOffer.grnPr')}
                        </span>
                      </div>
                    )}

                    <div className="proekt__tags__item">
                      <span className="tags-point">{project.projectType.data.title}</span>
                    </div>
                    {project.businessCategories.map((item) => (
                      <div className="proekt__tags__item" key={item.id}>
                        <span className="tags-point">{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="proekt__content__right">
                  <div className="proekt__stat">
                    <div className="proekt__stat__status">{translate('projectsOffer.public')}</div>
                    {project.created && (
                      <div className="proekt__stat__item">
                        <svg width="20" height="20">
                          <use xlinkHref={`${sprite}#timeSvg`} />
                        </svg>

                        {project.created}
                      </div>
                    )}
                    {project.views && (
                      <div className="proekt__stat__item">
                        <svg width="22" height="16">
                          <use xlinkHref={`${sprite}#viewSvg`} />
                        </svg>
                        {project.views}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="proekt-detail__level1">
                {project.expertCategories.map((item) => (
                  <div className="item" key={item.id}>
                    {item.name}
                  </div>
                ))}
                {project.expertTwoCategories.map((item) => (
                  <div className="item" key={item.id}>
                    {item.name}
                  </div>
                ))}
              </div>
              <div className="proekt-detail__level2">
                {project.expertThreeCategories.map((item) => (
                  <div className="item" key={item.id}>
                    {item.name}
                  </div>
                ))}
              </div>
              <div className="proekt__content__text">{project.description}</div>
              <div className="proekt-detail__lang">
                {project.languages.map((item) => (
                  <div className="item" key={item.id}>
                    {item.name}
                  </div>
                ))}
              </div>
              {project.screeningQuestions.length > 0 && (
                <div className="new-check">
                  <svg width="14" height="20">
                    <use xlinkHref={`${sprite}#new-check`} />
                  </svg>
                  {translate('Projects.haveAsk')}
                </div>
              )}

              {project.transmittalLetter && (
                <div className="new-check">
                  <svg width="14" height="20">
                    <use xlinkHref={`${sprite}#new-check`} />
                  </svg>
                  {translate('Projects.haveLatter')}
                </div>
              )}
              <div className="proekt-detail__duration">
                <div className="duration1">{translate('Projects.duration')}</div>
                <div className="duration2">{project.projectDuration.text}</div>
              </div>
              <div className="proekt-detail__bot">
                {project.isCandidate || !userCanSend || project.isInvited ? (
                  <button type="button" className="btn btn-primary" disabled>
                    {project.isCandidate
                      ? translate('Projects.joinComp')
                      : translate('Projects.joinComp2')}
                  </button>
                ) : (
                  <>
                    {ifActive ? (
                      <Link
                        type="button"
                        className="btn btn-primary"
                        to={`/projects?detailOffer=${project.id}`}
                      >
                        {translate('Projects.join')}
                      </Link>
                    ) : (
                      <button type="button" className="btn btn-primary" onClick={showNotActive}>
                        {translate('Projects.join')}
                      </button>
                    )}
                  </>
                )}
              </div>
              <div className="proekt__more__hr" />
              {candidates.length > 0 && (
                <div className="project__candidates">
                  {candidates.map((item) => (
                    <div className="project__candidates__item" key={item.id}>
                      <div className="proekt__content__top">
                        <div className="proekt__content__left">
                          <div className="proekt__content__person">
                            <Link
                              to={`/expert/${item.expert.slug}`}
                              className="proekt__content__avatar"
                              style={{
                                backgroundImage: `url(${item.expert.avatar || avatarDefault}`,
                              }}
                            />

                            <Link
                              className="proekt__content__person__name"
                              to={`/expert/${item.expert.slug}`}
                            >
                              {item.expert.profileName}
                            </Link>
                            {item.expert.country && (
                              <div className="proekt__location">
                                <svg width="14" height="20">
                                  <use xlinkHref={`${sprite}#location`} />
                                </svg>
                                {item.expert.country && item.expert.country.name}
                                {item.expert.city && `, ${item.expert.city.name}`}
                              </div>
                            )}
                          </div>
                          <div className="proekt__tags">
                            {item.hideBid ? (
                              <div className="proekt__tags__item">
                                <span className="tags-point">{translate('candidates.hide')}</span>
                              </div>
                            ) : (
                              <div className="proekt__tags__item">
                                <span className="tags-point">
                                  {item.bid} {translate('candidates.grnProject')}
                                </span>
                              </div>
                            )}

                            {item.completionTime && !item.hideBid && (
                              <div className="proekt__tags__item">
                                <span className="tags-point">
                                  {item.completionTime} {translate('candidates.days')}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="proekt__more">
                <Link to={`/employers/${project.company.slug}`} className="btn btn-outline-primary">
                  {translate('Projects.allProjects')}
                </Link>
                <button type="button" className="btn btn-primary" onClick={() => navigate(-1)}>
                  {translate('Projects.back')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {notActive && <NotActiveModal show={notActive} handleClose={hideNotActive} />}
      {handleRejectModal !== 0 && (
        <RejectProjectModal
          handleRejectModal={handleRejectModal}
          rejectModalXY={rejectModalXY}
          setHandleRejectModal={setHandleRejectModal}
          dispatch={reject}
          isReject={isReject}
        />
      )}

      {handleFavoriteModal !== 0 && (
        <FavoriteProjectModal
          handleFavoriteModal={handleFavoriteModal}
          favoriteModalXY={favoriteModalXY}
          setHandleFavoriteModal={setHandleFavoriteModal}
          dispatch={favorite}
          isFavorite={isFavorite}
        />
      )}
    </>
  );
};

Detail.propTypes = {
  handleReject: PropTypes.func.isRequired,
  handleFavorite: PropTypes.func.isRequired,
};

export default Detail;
