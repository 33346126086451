import { useSelector } from 'react-redux';
import { getNewData } from 'src/helpers';

const PaymentTable = () => {
  const payments = useSelector((state) => state.adminReducer.payments);
  const fixWaiter = useSelector((state) => state.adminReducer.fixWaiter);
  return (
    <div className="knowledge_table_warp">
      {payments.length > 0 ? (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Час оплати</th>
              <th>Користувач</th>
              <th>Значення платежу</th>
              <th>Сумма</th>
              <th>Статус</th>
              <th>Статус Fondy</th>
            </tr>
          </thead>
          <tbody className="admin_knowledge_action_btn">
            {payments.length > 0 &&
              payments.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{getNewData(item.createdAt)}</td>
                  {item.author ? (
                    <>
                      {item.authorIsExpert ? (
                        <td>
                          <a target="_blank" href={`/expert/${item.author.slug}`} rel="noreferrer">
                            {item.author.profileName}
                          </a>
                        </td>
                      ) : (
                        <td>
                          <a
                            target="_blank"
                            href={`/employers/${item.author.slug}`}
                            rel="noreferrer"
                          >
                            {item.author.companyName}
                          </a>
                        </td>
                      )}
                    </>
                  ) : (
                    <td>-</td>
                  )}

                  <td>{item.paidServices[0]?.serviceName}</td>
                  <td>
                    {item.amount} {item.currency}
                  </td>
                  <td>{item.status}</td>
                  <td>{item.orderStatus}</td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <>{!fixWaiter && <div>Нічого не знайдено</div>}</>
      )}
    </div>
  );
};

export default PaymentTable;
