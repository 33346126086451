import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { otborActions } from 'src/store/actions';

import TimerForTest from './timer-for-test';

const OtborTest = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fromResult = useSelector((state) => state.otborReducer.fromResult);
  const errors = useSelector((state) => state.otborReducer.errors);
  const waiter = useSelector((state) => state.otborReducer.waiter);
  const cat = useSelector((state) => state.otborReducer.cat);
  const questions = useSelector((state) => state.otborReducer.questions);
  const [step, setStep] = useState(0);
  const [resetCount, setResetCount] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (!errors) {
      window.scrollTo({
        top: 60,
        behavior: 'instant',
      });
    }
  }, [step]);

  useEffect(() => {
    dispatch(otborActions.getExamCategories()).then((res) => {
      if (res.payload.errors) {
        if (fromResult) {
          navigate('/otbor/');
        } else {
          navigate('/otbor/result');
        }
      }
    });
  }, []);

  useEffect(() => {
    cat.map(async (item) => {
      await dispatch(otborActions.getExamQuestions(item.id));
    });
  }, [cat]);

  const handleSubmit = async (values) => {
    setIsPaused(true);

    const data = values.questions.map((item) => {
      const questionList = { question: {} };
      item.questions.map((item2) => {
        questionList.question[item2.id] = item2.answer;
        return false;
      });
      return questionList;
    })[0];

    await dispatch(otborActions.sendAnswers(data));
    navigate('/otbor/result');
  };

  const questionsList = questions
    .filter((category, index) => index === step)
    .map((category) => ({
      ...category,
      questions: category.questions.map((question) => ({
        ...question,
        answer: '',
      })),
    }));

  const formik = useFormik({
    initialValues: {
      questions: questionsList,
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  const nextStep = async () => {
    setIsPaused(true);

    const data = formik.values.questions.map((item) => {
      const questionList = { question: {} };
      item.questions.map((item2) => {
        questionList.question[item2.id] = item2.answer;
        return false;
      });
      return questionList;
    })[0];

    await dispatch(otborActions.sendAnswers(data));

    setStep((prevCount) => prevCount + 1);
    setResetCount((prevCount) => prevCount + 1);
    setIsPaused(false);
  };

  const timerOut = async () => {
    if (step < questions.length - 1) {
      nextStep();
    } else {
      setIsPaused(false);
      handleSubmit(formik.values);
    }
  };

  return (
    <>
      {waiter ? (
        <Loader />
      ) : (
        <>
          {!errors && (
            <div className="timer--small">
              <TimerForTest
                minutes={6}
                seconds={0}
                action={timerOut}
                key={resetCount}
                isPaused={isPaused}
              />
            </div>
          )}
          <div className="otbor mb-0">
            <form onSubmit={formik.handleSubmit}>
              {!errors &&
                questionsList &&
                questionsList.map((item, index) => (
                  <div className="otbor__cat" key={item.id}>
                    <div className="otbor__cat__name">{item.name}</div>
                    <div className="otbor__list">
                      {item.questions.map((qa, qaIndex) => (
                        <div className="otbor__qa" key={qa.id}>
                          <div className="otbor__qa__text">
                            {qaIndex + 1}. <div dangerouslySetInnerHTML={{ __html: qa.text }} />
                          </div>
                          <div className="otbor__qa__list">
                            {qa.answers.map((answer, answerIndex) => (
                              <label
                                key={answer.id}
                                htmlFor={`questions[${index}].questions[${qaIndex}][${answerIndex}]`}
                              >
                                <input
                                  id={`questions[${index}].questions[${qaIndex}][${answerIndex}]`}
                                  type="radio"
                                  name={`questions[${index}].questions[${qaIndex}].answer`}
                                  value={answer.id}
                                  onChange={formik.handleChange}
                                />
                                <span />
                                {answer.text}
                              </label>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="otbor__btnqa">
                      {questions.length - 1 !== step ? (
                        <button type="button" className="btn btn-primary" onClick={nextStep}>
                          {translate('next')}
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          {translate('otbor.end')}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default OtborTest;
