import { api } from 'src/api';
import { getHeaders } from 'src/helpers/';

export const getProjectTypes = async () => {
  const result = await api.get(`get-project-types`, getHeaders());

  return result;
};

export const getExpertCategoriesFull = async () => {
  const result = await api.get(`get-expert-categories-full`, getHeaders());

  return result;
};

export const getProjectDurations = async () => {
  const result = await api.get(`get-project-durations`, getHeaders());

  return result;
};

export const getCompanyCategories = async () => {
  const result = await api.get(`get-company-categories`, getHeaders());

  return result;
};

export const getLangs = async () => {
  const result = await api.get(`languages`, getHeaders());

  return result;
};

export const getExpertList = async () => {
  const result = await api.get(`get-expert-list`, getHeaders());

  return result;
};

export const createProject = async (values) => {
  const result = await api.post(`/profile/company/projects`, values, getHeaders());

  return result;
};

export const currentProjectsList = async (data) => {
  const result = await api.get(
    `profile/company/get-current-projects?page=${data.page}&name=${data.name ? data.name : ''}&${
      data.date ? `date=${data.date}` : ''
    }`,
    getHeaders(),
  );

  return result;
};

export const currentProjectsListCompany = async (data) => {
  const result = await api.get(
    `company/${data.companyId}/get-published-projects?page=${data.page}`,
    getHeaders(),
  );

  return result;
};

export const currentProjectsListMore = async (data) => {
  const result = await api.get(`profile/company/get-current-projects`, data, getHeaders());

  return result;
};

export const completedProjectsList = async (data) => {
  const result = await api.get(
    `/profile/company/completed-projects-list?page=${data.page}&name=${data.name}&${
      data.date ? `date=${data.date}` : ''
    }`,
    getHeaders(),
  );

  return result;
};

export const completedProjectsListMore = async (data) => {
  const result = await api.get(
    `/profile/company/completed-projects-list?page=${data.page}`,
    getHeaders(),
  );

  return result;
};

export const getProjects = async (data) => {
  const result = await api.get(`/profile/expert/projects`, {
    params: data,
    headers: {
      'Accept-Language': localStorage.getItem('language') || 'en',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return result;
};

export const getProjectsById = async (data) => {
  const result = await api.get(`/profile/expert/projects/${data}`, getHeaders());

  return result;
};

export const projectReject = async (data) => {
  const result = await api.post(`/profile/expert/rejected`, data, getHeaders());

  return result;
};

export const projectFavorite = async (data) => {
  const result = await api.post(`/profile/expert/favorite`, data, getHeaders());

  return result;
};

export const projectSend = async (data) => {
  const result = await api.post(`/profile/expert/project/send`, data, getHeaders());

  return result;
};

// список експертів
export const getExperts = async (data) => {
  const result = await api.get(`/experts`, {
    params: data,
    headers: {
      'Accept-Language': localStorage.getItem('language') || 'en',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  return result;
};

export const expertReject = async (data) => {
  const result = await api.post(`/profile/company/rejected`, data, getHeaders());

  return result;
};

export const expertFavorite = async (data) => {
  const result = await api.post(`/profile/company/favorite`, data, getHeaders());

  return result;
};

export const getExpertsById = async (data) => {
  const result = await api.get(`/experts/${data}`, getHeaders());

  return result;
};

export const getActiveProjects = async () => {
  const result = await api.get(`/profile/company/get-active-projects`, getHeaders());

  return result;
};

export const getCompany = async (slug) => {
  const result = await api.get(`/get-company/${slug}`, getHeaders());

  return result;
};

export const getExpert = async (slug) => {
  const result = await api.get(`/expert/${slug}`, getHeaders());

  return result;
};

export const getFavorites = async (pageId) => {
  const result = await api.get(
    `/profile/expert/my-projects/favorites?page=${pageId}`,
    getHeaders(),
  );

  return result;
};

export const getRejected = async (pageId) => {
  const result = await api.get(`/profile/expert/my-projects/rejected?page=${pageId}`, getHeaders());

  return result;
};

export const getFavoritesExperts = async (pageId) => {
  const result = await api.get(
    `/profile/company/get-favorite-experts?page=${pageId}`,
    getHeaders(),
  );

  return result;
};

export const getRejectedExperts = async (pageId) => {
  const result = await api.get(
    `/profile/company/get-rejected-experts?page=${pageId}`,
    getHeaders(),
  );

  return result;
};

export const projectInvite = async (data) => {
  const result = await api.post(`/profile/company/invite`, data, getHeaders());

  return result;
};

export const deleteProject = async (id) => {
  const result = await api.delete(`/profile/company/projects/${id}`, getHeaders());

  return result;
};

export const getProject = async (id) => {
  const result = await api.get(`/profile/company/projects/${id}`, getHeaders());

  return result;
};

export const getInvitedExperts = async (data) => {
  const result = await api.get(
    `/profile/company/project/get-invited-experts?project=${data.project}&page=${data.page}`,
    getHeaders(),
  );

  return result;
};

export const getProjectFavoriteCandidates = async (data) => {
  const result = await api.get(
    `/profile/company/project/get-favorite-experts?project=${data.project}&page=${data.page}`,
    getHeaders(),
  );

  return result;
};

export const getProjectRejectedCandidates = async (data) => {
  const result = await api.get(
    `/profile/company/project/get-rejected-experts?project=${data.project}&page=${data.page}`,
    getHeaders(),
  );

  return result;
};

export const getProjectCandidates = async (data) => {
  const result = await api.get(
    `/profile/company/project/get-candidates?project=${data.project}&page=${data.page}`,
    getHeaders(),
  );

  return result;
};

export const projectDetailRejectExpert = async (data) => {
  const result = await api.post(`/profile/company/project/reject-expert`, data, getHeaders());

  return result;
};

export const projectDetailFavoriteExpert = async (data) => {
  const result = await api.post(`/profile/company/project/favorite-expert`, data, getHeaders());

  return result;
};

export const chooseExecutor = async (data) => {
  const result = await api.post(`/profile/company/project/choose-executor`, data, getHeaders());

  return result;
};

export const projectRework = async (data) => {
  const result = await api.post(`/profile/company/project/submit-for-rework`, data, getHeaders());

  return result;
};

export const projectSubmit = async (data) => {
  const result = await api.post(`/profile/company/project/submit-approval`, data, getHeaders());

  return result;
};

export const cancelExecutor = async (data) => {
  const result = await api.post(`/profile/company/project/cancel-executor`, data, getHeaders());

  return result;
};

export const projectSendPay = async (data) => {
  const result = await api.post(`/profile/company/project/accept-pay`, data, getHeaders());

  return result;
};

export const reviewProjectInfo = async (data) => {
  const result = await api.get(
    `/profile/company/review-project-info?project=${data.project}`,
    getHeaders(),
  );

  return result;
};

export const reviewProjectCompany = async (data) => {
  const result = await api.post(`/profile/company/review`, data, getHeaders());

  return result;
};

export const createChat = async (data) => {
  const result = await api.post(`/chat-company/chat`, data, getHeaders());

  return result;
};

export const proposeOtherConditions = async (data) => {
  const result = await api.post(
    `profile/company/project/propose-other-conditions`,
    data,
    getHeaders(),
  );

  return result;
};
