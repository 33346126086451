import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { paymentActions } from 'src/store/actions';

const History = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const paymentsHistory = useSelector((state) => state.paymentReducer.paymentsHistory);
  const waiter = useSelector((state) => state.paymentReducer.waiter);

  useEffect(() => {
    dispatch(paymentActions.paymentsHistory());
  }, []);

  const getClassName = (id) => {
    switch (id) {
      case 1:
        return 'pending';
      case 2:
        return 'success';
      case 3:
        return 'notcomplied';
      case 4:
        return 'error';
      default:
        return '';
    }
  };

  return (
    <>
      {waiter ? (
        <Loader />
      ) : (
        <>
          {paymentsHistory.length > 0 ? (
            <div className="payHistory">
              {paymentsHistory.map((item) => (
                <div className="payHistory__item" key={item.id}>
                  <div className="data">
                    {translate('historyPayment.data')} {item.date}
                  </div>
                  <div className="payHistory__row">
                    <div className="left">{translate('historyPayment.project')}</div>
                    <div className="right">
                      <b>{item.projectName}</b>
                    </div>
                  </div>
                  <div className="payHistory__row">
                    <div className="left">{translate('historyPayment.status')}</div>
                    <div className="right">
                      <b className={getClassName(item.statusId)}>{item.status}</b>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>{translate('historyPayment.empty')}</>
          )}
        </>
      )}
    </>
  );
};
export default History;
