import './style.scss';

import { Link } from 'react-router-dom';
import img from 'src/assets/img/not-found.png';
import { Header } from 'src/components';
import { useTranslation } from 'src/hooks';

const NotFound = () => {
  const { translate } = useTranslation();
  return (
    <div className="app">
      <Header />
      <main className="main">
        <div className="wrap">
          <div className="notFound">
            <div className="notFound__left">
              <div className="notFound__title">404</div>
              <div className="notFound__text">{translate('notFound.text')}</div>
              <Link to="/" className="btn btn-primary">
                {translate('back')}
              </Link>
            </div>

            <img src={img} alt="notfound" />
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFound;
