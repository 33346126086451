import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/old-img/sprite.svg';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup);

const ResetPass = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.authReducer.errors);

  const [passShow, setPassShow] = useState(false);
  const [passShow2, setPassShow2] = useState(false);

  const phone = query.get('phone');
  const code = query.get('code');
  const token = query.get('token');

  useEffect(() => () => dispatch(clearErrors()), []);

  const handleSubmit = async (values) => {
    await dispatch(authActions.resetPass(values));
    navigate('/auth/fogotpass/thank/');
  };

  const SignUpdateSchema = Yup.object().shape({
    password: Yup.string()
      .required(translate('Valid.req'))
      .min(8, translate('Valid.pass'))
      .max(14, translate('Valid.max14'))
      .minLowercase(1, translate('Valid.minLow'))
      .minUppercase(1, translate('Valid.minUp'))
      .minNumbers(1, translate('Valid.minCifr')),
    password_confirmation: Yup.string()
      .required(translate('Valid.req'))
      .min(8, translate('Valid.pass'))
      .max(14, translate('Valid.max14'))
      .minLowercase(1, translate('Valid.minLow'))
      .minUppercase(1, translate('Valid.minUp'))
      .minNumbers(1, translate('Valid.minCifr'))
      .oneOf([Yup.ref('password'), null], translate('Valid.passCompare')),
  });

  const formik = useFormik({
    initialValues: {
      phone: phone || '',
      token: token || '',
      code: code || '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: SignUpdateSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <div className="auth__wrap">
      <div className="container">
        <h1>{translate('Auth.fogotTitle')}</h1>
        <div className="auth__desc">{translate('Auth.newPass')}</div>
        <form className="auth__form" onSubmit={formik.handleSubmit}>
          <Link to="/auth" className="auth__form__close">
            <div>
              <svg className="icon">
                <use xlinkHref={`${sprite}#close`} />
              </svg>
            </div>
          </Link>
          <div className="auth__form__title">{translate('Auth.newPass2')}</div>

          <div className="input__row input__row--pass">
            <input
              type={passShow ? 'text' : 'password'}
              placeholder={translate('Auth.password')}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.errors.password && formik.touched.password && 'is-invalid'}
              autoComplete="new-password"
            />
            <div className="invalid-feedback">
              {formik.errors.password && formik.touched.password && formik.errors.password}
            </div>
            <button
              type="button"
              onClick={() => setPassShow(!passShow)}
              className={passShow ? 'active' : null}
            >
              <svg className="hide">
                <use xlinkHref={`${sprite}#pass-show`} />
              </svg>
              <svg className="show">
                <use xlinkHref={`${sprite}#pass-hide`} />
              </svg>
            </button>
          </div>

          <div className="input__row input__row--pass">
            <input
              type={passShow2 ? 'text' : 'password'}
              placeholder={translate('Auth.password2')}
              name="password_confirmation"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                formik.errors.password_confirmation &&
                formik.touched.password_confirmation &&
                'is-invalid'
              }
              autoComplete="new-password"
            />
            <div className="invalid-feedback">
              {formik.errors.password_confirmation &&
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation}
            </div>
            <button
              type="button"
              onClick={() => setPassShow2(!passShow2)}
              className={passShow2 ? 'active' : null}
            >
              <svg className="hide">
                <use xlinkHref={`${sprite}#pass-show`} />
              </svg>
              <svg className="show">
                <use xlinkHref={`${sprite}#pass-hide`} />
              </svg>
            </button>
          </div>

          <div className="auth-bot-btns">
            <button type="button" className="prev-btn" onClick={() => navigate(-1)}>
              {translate('back')}
            </button>
            <button type="submit" className="btn-reset next-btn">
              {translate('confirm')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPass;
