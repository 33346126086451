import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FixLoader } from 'src/components';
import { adminActions } from 'src/store/actions';

import Activate from './activate';
import Deactivate from './deactivate';

const Payment = () => {
  const dispatch = useDispatch();

  const expertFeaturesWaiter = useSelector((state) => state.adminReducer.expertFeaturesWaiter);
  const expertId = useSelector((state) => state.adminReducer.user.expert.id);

  const hanbleDispatch = async (id) => {
    await dispatch(adminActions.expertFeatures(id));
    await dispatch(adminActions.expertPaidPackages(id));
    await dispatch(adminActions.expertPaidServices(id));
  };

  useEffect(() => {
    hanbleDispatch(expertId);
  }, []);

  const [tab, setTab] = useState(0);

  return (
    <>
      {expertFeaturesWaiter && <FixLoader />}
      <div className="exp-content__top">Платні функції</div>

      <div className="proekt__tabs tabs-nav proekt__tabs--payment">
        <ul>
          <li>
            <button
              type="button"
              className={`${tab === 0 ? 'active' : ''}`}
              onClick={() => setTab(0)}
            >
              Активація
            </button>
          </li>
          <li>
            <button
              type="button"
              className={`${tab === 1 ? 'active' : ''}`}
              onClick={() => setTab(1)}
            >
              Деактивація
            </button>
          </li>
        </ul>
      </div>
      {tab === 0 ? (
        <Activate hanbleDispatch={hanbleDispatch} />
      ) : (
        <Deactivate hanbleDispatch={hanbleDispatch} />
      )}
    </>
  );
};
export default Payment;
