import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

import ChooseModal from './choose-modal';
import StepWithOffers from './step-width-offers';
import StepWidthPerforer from './step-width-performer';

const Detail = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.detail;
  const waiter = useSelector((state) => state.projectReducer.waiter);
  const project = useSelector((state) => state.projectReducer.myProjectDetail);
  const showChooseExpertModal = useSelector((state) => state.projectReducer.showChooseExpertModal);
  window.scrollTo({
    top: 80,
    behavior: 'instant',
  });

  useEffect(() => {
    dispatch(projectActions.getProject(projectId));
  }, []);

  return (
    <>
      <Link className="back-url" to="/my-projects">
        <svg width="44" height="21">
          <use xlinkHref={`${sprite}#detail`} />
        </svg>
        {translate('My-project.title')}
      </Link>
      {waiter ? (
        <Loader />
      ) : (
        <>{project && <>{project.status <= 2 ? <StepWithOffers /> : <StepWidthPerforer />}</>}</>
      )}
      {showChooseExpertModal && <ChooseModal show={showChooseExpertModal} />}
    </>
  );
};
export default Detail;
