import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

import { Delete, Search } from './components';

const List = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();

  const companyId = useSelector((state) => state.authReducer.user.company.id);
  const waiter = useSelector((state) => state.projectReducer.myProjectCompanyWaiter);
  const waiterPag = useSelector((state) => state.projectReducer.myProjectCompanyWaiterMore);
  const waiterDone = useSelector((state) => state.projectReducer.myProjectCompanyDoneWaiter);
  const waiterDonePag = useSelector((state) => state.projectReducer.myProjectCompanyDoneWaiterMore);
  const projectList = useSelector((state) => state.projectReducer.myProjectCompany);
  const projectListPag = useSelector((state) => state.projectReducer.myProjectCompanyPag);
  const projectListDone = useSelector((state) => state.projectReducer.myProjectCompanyDone);
  const projectListDonePag = useSelector((state) => state.projectReducer.myProjectCompanyDonePag);
  const [tab, setTab] = useState(1);

  // delete modal
  const [chooseId, setChooseId] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => setShowDelete(false);
  const handleDelete = async () => {
    await dispatch(projectActions.deleteProject(chooseId));
    handleCloseDelete();

    const data = {
      page: 1,
      companyId,
      name: '',
      date: '',
    };

    dispatch(projectActions.currentProjectsList(data));
  };

  useEffect(() => {
    const data = {
      page: 1,
      companyId,
      name: '',
      date: '',
    };
    if (tab === 1) {
      dispatch(projectActions.currentProjectsList(data));
    } else {
      dispatch(projectActions.completedProjectsList(data));
    }
  }, [tab]);

  const [timer1, setTimer1] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [date, setDate] = useState(null);

  const handleSearch = (newValue, newDate) => {
    clearTimeout(timer1);
    setSearchText(newValue);
    setDate(newDate);

    const newTimer = setTimeout(() => {
      const data = {
        page: 1,
        name: newValue,
        date: newDate,
      };
      if (tab === 1) {
        dispatch(projectActions.currentProjectsList(data));
      } else {
        dispatch(projectActions.completedProjectsList(data));
      }
    }, 1000);

    setTimer1(newTimer);
  };

  const showMore = () => {
    const data = {
      page: projectListPag.current_page + 1,
      name: searchText,
      companyId,
      date,
    };

    dispatch(projectActions.currentProjectsListMore(data));
  };

  const showMoreDone = () => {
    const data = {
      page: projectListDonePag.current_page + 1,
      name: searchText,
      date,
    };
    dispatch(projectActions.completedProjectsListMore(data));
  };

  return (
    <>
      <div className="puclic-project">
        <Link to="/publication" className="btn btn-primary">
          {translate('My-project.public')}
        </Link>
      </div>
      <div className="proekt__tabs tabs-nav" data-tabs="#proect-tabs">
        <ul>
          <li>
            <button type="button" className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
              {translate('My-project.current')}{' '}
              {projectListPag.total ? `(${projectListPag.total})` : ''}
            </button>
          </li>
          <li>
            <button type="button" className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
              {translate('My-project.done')}{' '}
              {projectListDonePag.total ? `(${projectListDonePag.total})` : ''}
            </button>
          </li>
        </ul>
      </div>

      <Search searchText={searchText} handleSearch={handleSearch} key={1} />

      <div className="my-proekts-list proekt__list">
        {waiter || waiterDone ? (
          <Loader />
        ) : (
          <div className="tabs" id="proect-tabs">
            <div className={`tabs__item ${tab === 1 ? 'active' : ''}`}>
              <div className="proekt__content__wrap">
                {projectList.length > 0 ? (
                  projectList.map((item) => (
                    <div className="proekt__list__item" key={item.id}>
                      <div className="proekt__detail__flex">
                        <div className="left">
                          <div className="proekt__content__top">
                            <div className="proekt__content__left">
                              <div className="proekt__content__person proekt__content__person--list">
                                <Link
                                  to={`${item.id}`}
                                  className="proekt__content__avatar"
                                  style={{
                                    backgroundImage: `url(${item.avatar || avatarDefaultComp})`,
                                  }}
                                />
                                <div className="proekt__content__person__right">
                                  <Link to={`${item.id}`} className="proekt__content__person__name">
                                    {item.name}
                                  </Link>
                                  <div className="proekt__tags">
                                    <div className="proekt__tags__item">
                                      <span className="">{translate('My-project.pay')}</span>
                                      <span className="tags-point">
                                        {item.budget} {translate('grn')}
                                      </span>
                                    </div>
                                    {item.businessCategories &&
                                      item.businessCategories.map((itemCat) => (
                                        <div className="proekt__tags__item" key={itemCat.id}>
                                          <span className="tags-point">{itemCat.name}</span>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="proekt__content__text">{item.description}</div>
                        </div>
                        <div className="right">
                          <div className="proekt__stat">
                            <div className="proekt__stat__status">
                              {translate('My-project.dataPublic')}
                            </div>
                            <div className="proekt__stat__item">
                              <svg width="20" height="20">
                                <use xlinkHref={`${sprite}#timeSvg`} />
                              </svg>
                              {item.created}
                            </div>
                            <div className="proekt__stat__item">
                              <svg width="22" height="16">
                                <use xlinkHref={`${sprite}#viewSvg`} />
                              </svg>
                              {item.views}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="proekt__content__bot">
                        {item.status === 1 && (
                          <button
                            type="button"
                            className="my-proekts-list__btn-offers my-proekts-list--delete"
                            onClick={() => {
                              setChooseId(item.id);
                              handleShowDelete();
                            }}
                          >
                            {translate('My-project.deleteBtn')}
                          </button>
                        )}
                        {item.status === 2 && (
                          <Link
                            to={`/my-projects/${item.id}`}
                            className="btn btn-primary my-proekts-list__btn-offers"
                          >
                            {translate('My-project.reviewBtn')}
                          </Link>
                        )}
                        {item.state !== 1 && item.status !== 2 && <div />}
                        <div className="proekt__content__actions">
                          <Link to={`${item.id}`} className="proekt__chat">
                            {translate('My-project.detail')}
                            <svg width="44" height="21">
                              <use xlinkHref={`${sprite}#detail`} />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="proekt__list__item">
                    <div className="now-proekt__empty now-proekt__empty--public">
                      {translate('My-project.empty')}
                      <div className="now-proekt__empty--public__wrap">
                        <Link to="/publication" className="btn btn-primary">
                          {translate('My-project.public')}
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="proekt__more">
                {waiterPag ? (
                  <Loader />
                ) : (
                  <>
                    {projectListPag.current_page !== projectListPag.last_page && (
                      <button type="button" className="btn btn-primary" onClick={showMore}>
                        {translate('My-project.addMore')}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={`tabs__item ${tab === 2 ? 'active' : ''}`}>
              <div className="proekt__content__wrap">
                {projectListDone.length > 0 ? (
                  projectListDone.map((item) => (
                    <div className="proekt__list__item" key={item.id}>
                      <div className="proekt__detail__flex">
                        <div className="left">
                          <div className="proekt__content__top">
                            <div className="proekt__content__left">
                              <div className="proekt__content__person proekt__content__person--list">
                                <Link
                                  to={`${item.id}`}
                                  className="proekt__content__avatar"
                                  style={{
                                    backgroundImage: `url(${
                                      item.company.avatar || avatarDefaultComp
                                    })`,
                                  }}
                                />
                                <div className="proekt__content__person__right">
                                  <Link to={`${item.id}`} className="proekt__content__person__name">
                                    {item.name}
                                  </Link>
                                  <div className="proekt__tags">
                                    <div className="proekt__tags__item">
                                      <span className="">{translate('My-project.pay')}</span>
                                      <span className="tags-point">
                                        {item.budget} {translate('grn')}
                                      </span>
                                    </div>
                                    {item.businessCategories &&
                                      item.businessCategories.map((itemCat) => (
                                        <div className="proekt__tags__item" key={itemCat.id}>
                                          <span className="tags-point">{itemCat.name}</span>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="proekt__content__text">{item.description}</div>
                        </div>
                        <div className="right">
                          <div className="proekt__stat">
                            <div className="proekt__stat__status">
                              {translate('My-project.dataPublic')}
                            </div>
                            <div className="proekt__stat__item">
                              <svg width="20" height="20">
                                <use xlinkHref={`${sprite}#timeSvg`} />
                              </svg>
                              {item.created}
                            </div>
                            <div className="proekt__stat__item">
                              <svg width="22" height="16">
                                <use xlinkHref={`${sprite}#viewSvg`} />
                              </svg>
                              {item.views}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="proekt__content__tags">
                        {item.expertCategories &&
                          item.expertCategories.map((expCat) => (
                            <div className="proekt__content__tags__item" key={expCat.id}>
                              {expCat.name}
                            </div>
                          ))}
                      </div>
                      <div className="proekt__content__bot">
                        <div className="proekt__location">
                          <svg width="14" height="20">
                            <use xlinkHref={`${sprite}#location`} />
                          </svg>
                          {item.company.country && item.company.country.name}
                          {item.company.city && `, ${item.company.city.name}`}
                        </div>
                        <div className="proekt__content__actions">
                          <Link to={`${item.id}`} className="proekt__chat">
                            {translate('My-project.detail')}
                            <svg width="44" height="21">
                              <use xlinkHref={`${sprite}#detail`} />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="proekt__list__item">
                    <div className="now-proekt__empty now-proekt__empty--public">
                      {translate('My-project.empty2')}
                      <div className="now-proekt__empty--public__wrap">
                        <Link to="/publication" className="btn btn-primary">
                          {translate('My-project.public')}
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="proekt__more">
                {waiterDonePag ? (
                  <Loader />
                ) : (
                  <>
                    {projectListDonePag.current_page !== projectListDonePag.last_page && (
                      <button type="button" className="btn btn-primary" onClick={showMoreDone}>
                        {translate('My-project.addMore')}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {showDelete && (
        <Delete handleDelete={handleDelete} handleClose={handleCloseDelete} show={showDelete} />
      )}
    </>
  );
};
export default List;
