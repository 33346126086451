import { createAsyncThunk } from '@reduxjs/toolkit';
import { chatService } from 'src/services';

export const getChatListExpert = createAsyncThunk(
  'chat/getChatListExpert',
  async (data, { rejectWithValue }) => {
    try {
      const result = await chatService.getChatListExpert();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getChatListExpertRefresh = createAsyncThunk(
  'chat/getChatListExpertRefresh',
  async (data, { rejectWithValue }) => {
    try {
      const result = await chatService.getChatListExpert();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getChatExpert = createAsyncThunk(
  'chat/getChatExpert',
  async (data, { rejectWithValue }) => {
    try {
      const result = await chatService.getChatExpert(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendMessExpert = createAsyncThunk(
  'chat/sendMessExpert',
  async ({ values, file }, { rejectWithValue }) => {
    try {
      const formData = new FormData();

      formData.append('body', values.body);
      formData.append('expertId', values.expertId);
      formData.append('projectId', values.projectId);

      if (file) {
        formData.append('file', file);
      }

      const result = await chatService.sendMessExpert(formData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getChatListCompany = createAsyncThunk(
  'chat/getChatListCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await chatService.getChatListCompany();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getChatListCompanyRefresh = createAsyncThunk(
  'chat/getChatListCompanyRefresh',
  async (data, { rejectWithValue }) => {
    try {
      const result = await chatService.getChatListCompany();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getChatCompany = createAsyncThunk(
  'chat/getChatCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await chatService.getChatCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendMessCompany = createAsyncThunk(
  'chat/sendMessCompany',
  async ({ values, file }, { rejectWithValue }) => {
    try {
      const formData = new FormData();

      formData.append('body', values.body);
      formData.append('expertId', values.expertId);
      formData.append('projectId', values.projectId);

      if (file) {
        formData.append('file', file);
      }
      const result = await chatService.sendMessCompany(formData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertReadMess = createAsyncThunk(
  'chat/expertReadMess',
  async (data, { rejectWithValue }) => {
    try {
      const result = await chatService.expertReadMess(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const companyReadMess = createAsyncThunk(
  'chat/companyReadMess',
  async (data, { rejectWithValue }) => {
    try {
      const result = await chatService.companyReadMess(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
