import { api } from 'src/api';
import { getHeaders } from 'src/helpers/';

export const getDeleteReason = async () => {
  const result = await api.get(`get-account-delete-reasons`, getHeaders());

  return result;
};

export const getСompanyСategories = async () => {
  const result = await api.get(`get-company-categories`, getHeaders());

  return result;
};

export const getDoneProject = async (page) => {
  const result = await api.get(`/profile/expert/my-projects/complete?page=${page}`, getHeaders());

  return result;
};

export const getDoneProjectExpert = async (data) => {
  const result = await api.get(
    `/expert/${data.id}/get-completed-projects?page=${data.page}`,
    getHeaders(),
  );

  return result;
};

export const getDoneProjectCompany = async () => {
  const result = await api.get(`/profile/company/completed-projects-list`, getHeaders());

  return result;
};

export const getExpertPortfolio = async (data) => {
  const result = await api.get(`/expert-portfolio/${data.id}`, getHeaders());

  return result;
};

export const getAllPortfolio = async () => {
  const result = await api.get(`profile/expert/portfolio/`, getHeaders());

  return result;
};

export const deletePortFolio = async (id) => {
  const result = await api.delete(`/profile/expert/portfolio/${id}`, getHeaders());

  return result;
};

export const setPortfolio = async (data) => {
  const result = await api.post(`profile/expert/portfolio/`, data, getHeaders());

  return result;
};

export const editPortfolio = async (data, id) => {
  const result = await api.post(`profile/expert/portfolio/${id}`, data, getHeaders());

  return result;
};

export const knowledgeBase = async () => {
  const result = await api.get(`/knowledge-base`, getHeaders());

  return result;
};

export const knowledgeBaseSearch = async (data) => {
  const result = await api.get(`/knowledge-base-search/?search=${data}`, getHeaders());

  return result;
};

export const getRatingInfo = async () => {
  const result = await api.get(`/profile/expert/rating-info`, getHeaders());

  return result;
};

export const getReviewsExpert = async (data) => {
  const result = await api.get(
    `/reviews/get-for-expert?expert=${data.expert}&page=${data.page}`,
    getHeaders(),
  );

  return result;
};

export const getReviewsCompany = async (data) => {
  const result = await api.get(
    `/reviews/get-for-company?company=${data.company}&page=${data.page}`,
    getHeaders(),
  );

  return result;
};

export const getCountOnMainPage = async () => {
  const result = await api.get('/counters', getHeaders());

  return result;
};
