import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import avatarDefault from 'src/assets/img/avatar-default.png';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import {
  compSidebarMenu,
  expertSidebarMenu,
  sidebarTopMenuCompany,
  sidebarTopMenuExpert,
} from 'src/constants';
import { useTranslation, useWindowDimensions } from 'src/hooks';

import Referal from './referal';

const Sidebar = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const role = useSelector((state) => state.authReducer.user.role);
  const { avatar } = useSelector((state) =>
    role === 1 ? state.authReducer.user.expert : state.authReducer.user.company,
  );

  const sidebarTopMenu = role === 1 ? sidebarTopMenuExpert : sidebarTopMenuCompany;
  const menu = role === 1 ? expertSidebarMenu : compSidebarMenu;
  const fullName = useSelector((state) =>
    role === 1
      ? state.authReducer.user.expert.profileName
      : state.authReducer.user.company.companyName,
  );
  const { language } = useTranslation();
  const location = useLocation();

  const { width } = useWindowDimensions();

  const handleChangePage = (to) => {
    navigate(to);
  };

  const getActiveLink = () => {
    let activeLink = '/profile/';

    if (sidebarTopMenu) {
      sidebarTopMenu[language].forEach((item) => {
        if (window.location.pathname === item.to) {
          activeLink = item.to;
        }
      });
    }
    menu[language].forEach((item) => {
      if (window.location.pathname === item.to) {
        activeLink = item.to;
      }
    });
    return activeLink;
  };
  const activeLink = getActiveLink();

  return (
    <div className="exp-content__sidebar">
      <div className="sidebar">
        <div className="sidebar__top">
          <Link
            to="/profile/"
            className="sidebar__img"
            style={{
              backgroundImage: `url(${avatar || (role === 1 ? avatarDefault : avatarDefaultComp)})`,
            }}
          />
          <div className="sidebar__name">
            <Link to="/profile/">{fullName}</Link>
          </div>
        </div>

        {width < 768 ? (
          <div className="sidebar__mobSelect">
            <select
              className="form-select"
              onChange={(e) => handleChangePage(e.target.value)}
              defaultValue={activeLink}
            >
              {sidebarTopMenu &&
                sidebarTopMenu[language].map((item) => (
                  <option value={item.to} key={item.id}>
                    {item.name}
                  </option>
                ))}

              {menu[language].map((item) => (
                <option value={item.to} key={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <nav className="sidebar__nav">
            <ul className="sidebar__nav__menu1">
              {sidebarTopMenu &&
                sidebarTopMenu[language].map((item) => (
                  <li key={item.id} className={location.pathname === item.to ? 'active' : ''}>
                    <Link to={item.to}>
                      {item.name}
                      {location.pathname === item.to && (
                        <svg width="24px" height="24px" className="open">
                          <use xlinkHref={`${sprite}#edit`} />
                        </svg>
                      )}
                    </Link>
                  </li>
                ))}
            </ul>
            <ul>
              <li>
                <span>{translate('editPr')}</span>
              </li>
              {menu[language].map((item) => (
                <li key={item.id} className={location.pathname === item.to ? 'active' : ''}>
                  <Link to={item.to}>
                    {item.name}
                    {location.pathname === item.to && (
                      <svg width="24px" height="24px" className="open">
                        <use xlinkHref={`${sprite}#edit`} />
                      </svg>
                    )}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
      <Referal />
    </div>
  );
};

export default Sidebar;
