import { PropTypes } from 'prop-types';
import { useState } from 'react';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const Search = ({ handleSearch, SearchName }) => {
  const waiter = false;

  const { translate } = useTranslation();
  const [name, setName] = useState(SearchName);

  const handelSub = (event) => {
    event.preventDefault();
    handleSearch(name);
  };
  return (
    <div>
      <form className="admin_search" onSubmit={handelSub}>
        <div className="proekt__search__wrap">
          <div className="selectize-search">
            <svg width="17.5px" height="17.5px" className="top-search__icon">
              <use xlinkHref={`${sprite}#search`} />
            </svg>
            <input
              type="text"
              className="input"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={translate('knowledge_base.search')}
            />
          </div>

          <button type="submit" className="btn btn-primary top-search__btn" disabled={waiter}>
            {translate('Projects.seachBtn')}
          </button>
        </div>
      </form>
    </div>
  );
};

Search.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  SearchName: PropTypes.string.isRequired,
};

export default Search;
