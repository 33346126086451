import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const SubModal = ({ show, frompayment }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(`/my-projects/`);
  };
  return (
    <Modal show={show} onHide={handleClose} className="invite">
      <Modal.Body>
        <div className="custom-modal-big">
          {frompayment ? (
            <Link to="/payment/paid/" className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </Link>
          ) : (
            <button type="button" onClick={handleClose} className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
          )}

          <div className="custom-modal__title custom-modal__title--public">
            {translate('Publication.submitModalTitle')}
          </div>
          <div className="custom-modal__subt custom-modal__subt--public">
            {/* {translate('Publication.submitModalText1')}{' '}
            <Link to="/payment/">{translate('Publication.submitModalLink')}</Link>{' '}
            {translate('Publication.submitModalText2')} */}
          </div>
          <div className="custom-modal__btns">
            {frompayment ? (
              <Link to="/payment/paid/" className="btn btn-primary">
                {translate('Publication.submitModalLink3')}
              </Link>
            ) : (
              <Link to="/freelancers/" className="btn btn-primary">
                {translate('Publication.submitModalLink2')}
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

SubModal.propTypes = {
  frompayment: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
};
export default SubModal;
