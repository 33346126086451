import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { customActions } from 'src/store/actions';

const Search = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.customReducer.waiter);
  const { search } = useParams();

  const knowledgeBase = useSelector((state) => state.customReducer.knowledgeBase);

  useEffect(() => {
    dispatch(customActions.knowledgeBaseSearch(search));
  }, [search]);

  const getNewData = (data) => new Date(data).toISOString().substring(0, 10);

  return (
    <>
      <h3>{translate('baza.search')}</h3>
      {waiter ? (
        <Loader />
      ) : (
        <>
          {knowledgeBase.length > 0 ? (
            <div className="base__wrap">
              {knowledgeBase.map((item) => (
                <Link className="category-item " to={`/baza-znan/${item.slug}`} key={item.id}>
                  <div className="category-content ">
                    <div className="category-item__title" title={item.name}>
                      {item.name}
                    </div>

                    <p title={item.subtitle}>{item.subtitle}</p>
                  </div>

                  <div className="author-block">
                    <div className="text-wrapper">
                      <div className="article-update ellipsis" title={item.created}>
                        {translate('baza.created')}: {getNewData(item.created)}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div>{translate('baza.empty')}</div>
          )}
        </>
      )}
    </>
  );
};

export default Search;
