import { createAsyncThunk } from '@reduxjs/toolkit';
import { alertsService } from 'src/services';

export const getNotifications = createAsyncThunk(
  'alerts/getNotifications',
  async (data, { rejectWithValue }) => {
    try {
      let result;

      if (data === 1) {
        result = await alertsService.getNotificationsExpert();
      } else {
        result = await alertsService.getNotificationsCompany();
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getNotificationsRefresh = createAsyncThunk(
  'alerts/getNotificationsRefresh',
  async (data, { rejectWithValue }) => {
    try {
      let result;

      if (data === 1) {
        result = await alertsService.getNotificationsExpert();
      } else {
        result = await alertsService.getNotificationsCompany();
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getReadNotifications = createAsyncThunk(
  'alerts/getReadNotifications',
  async (data, { rejectWithValue }) => {
    try {
      let result;

      if (data === 1) {
        result = await alertsService.getReadNotificationsExpert();
      } else {
        result = await alertsService.getReadNotificationsCompany();
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const readNot = createAsyncThunk('alerts/readNot', async (data, { rejectWithValue }) => {
  try {
    let result;

    if (data.role === 1) {
      result = await alertsService.readNotExpert(data);
    } else {
      result = await alertsService.readNotCompany(data);
    }

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const activeCount = createAsyncThunk(
  'alerts/activeCount',
  async (data, { rejectWithValue }) => {
    try {
      let result;

      if (data === 1) {
        result = await alertsService.activeCountExpert(data);
      } else {
        result = await alertsService.activeCountCompany(data);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
