import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { customActions } from 'src/store/actions';

const List = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.customReducer.waiter);
  const knowledgeBase = useSelector((state) => state.customReducer.knowledgeBase);
  useEffect(() => {
    dispatch(customActions.knowledgeBase());
  }, []);
  const getNewData = (data) => new Date(data).toISOString().substring(0, 10);

  return (
    <>
      {waiter ? (
        <Loader />
      ) : (
        <div className="base__wrap">
          {knowledgeBase.length > 0 &&
            knowledgeBase.map((item) => (
              <Link className="category-item " to={`/baza-znan/${item.slug}`} key={item.id}>
                <div className="category-content ">
                  <div className="category-item__title" title={item.name}>
                    {item.name}
                  </div>

                  <p title={item.subtitle}>{item.subtitle}</p>
                </div>

                <div className="author-block">
                  <div className="text-wrapper">
                    <div className="article-update ellipsis" title={item.created}>
                      {translate('baza.created')}: {getNewData(item.created)}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      )}
    </>
  );
};

export default List;
