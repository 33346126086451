import { createAsyncThunk } from '@reduxjs/toolkit';
import { projectService } from 'src/services/';

export const getProjectTypes = createAsyncThunk(
  'project/getProjectTypes',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProjectTypes();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExpertCategoriesFull = createAsyncThunk(
  'project/getExpertCategoriesFull',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getExpertCategoriesFull();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectDurations = createAsyncThunk(
  'project/getProjectDurations',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProjectDurations();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCompanyCategories = createAsyncThunk(
  'project/getCompanyCategories',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getCompanyCategories();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getLangs = createAsyncThunk('project/getLangs', async (data, { rejectWithValue }) => {
  try {
    const result = await projectService.getLangs();

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getExpertList = createAsyncThunk(
  'project/getExpertList',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getExpertList();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createProject = createAsyncThunk(
  'project/createProject',
  async (values, { rejectWithValue }) => {
    try {
      const newValues = {
        ...values,
        screeningQuestions: values.screeningQuestions.filter((item) => item.length),
      };
      const result = await projectService.createProject(newValues);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const currentProjectsList = createAsyncThunk(
  'project/currentProjectsList',
  async (data, { rejectWithValue }) => {
    try {
      let formattedDate = '';
      if (data.date) {
        const year = data.date.getFullYear();
        const month = data.date.getMonth() + 1;
        const day = data.date.getDate();

        formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`;
      }

      const newData = { ...data, date: formattedDate };

      const result = await projectService.currentProjectsList(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const currentProjectsListCompany = createAsyncThunk(
  'project/currentProjectsListCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.currentProjectsListCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const currentProjectsListCompanyMore = createAsyncThunk(
  'project/currentProjectsListCompanyMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.currentProjectsListCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const currentProjectsListMore = createAsyncThunk(
  'project/currentProjectsListMore',
  async (data, { rejectWithValue }) => {
    try {
      let formattedDate = '';
      if (data.date) {
        const year = data.date.getFullYear();
        const month = data.date.getMonth() + 1;
        const day = data.date.getDate();

        formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`;
      }

      const newData = { ...data, date: formattedDate };
      const result = await projectService.currentProjectsList(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const completedProjectsList = createAsyncThunk(
  'project/completedProjectsList',
  async (data, { rejectWithValue }) => {
    try {
      let formattedDate = '';
      if (data.date) {
        const year = data.date.getFullYear();
        const month = data.date.getMonth() + 1;
        const day = data.date.getDate();

        formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`;
      }

      const newData = { ...data, date: formattedDate };
      const result = await projectService.completedProjectsList(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const completedProjectsListMore = createAsyncThunk(
  'project/completedProjectsListMore',
  async (data, { rejectWithValue }) => {
    try {
      let formattedDate = '';
      if (data.date) {
        const year = data.date.getFullYear();
        const month = data.date.getMonth() + 1;
        const day = data.date.getDate();

        formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`;
      }

      const newData = { ...data, date: formattedDate };
      const result = await projectService.completedProjectsList(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjects = createAsyncThunk(
  'project/getProjects',
  async (data, { rejectWithValue }) => {
    try {
      const newData = data;
      if (!newData.companyCategory) {
        delete newData.companyCategory;
      }
      if (!newData.country) {
        delete newData.country;
      }
      if (!newData.expertCategory) {
        delete newData.expertCategory;
      }
      if (!newData.expertCategorySecond) {
        delete newData.expertCategorySecond;
      }
      if (!newData.expertCategoryThird) {
        delete newData.expertCategoryThird;
      }
      if (!newData.name) {
        delete newData.name;
      }

      const result = await projectService.getProjects(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectsMore = createAsyncThunk(
  'project/getProjectsMore',
  async (data, { rejectWithValue }) => {
    try {
      const newData = data;
      if (!newData.companyCategory) {
        delete newData.companyCategory;
      }
      if (!newData.country) {
        delete newData.country;
      }
      if (!newData.expertCategory) {
        delete newData.expertCategory;
      }
      if (!newData.expertCategorySecond) {
        delete newData.expertCategorySecond;
      }
      if (!newData.expertCategoryThird) {
        delete newData.expertCategoryThird;
      }
      if (!newData.name) {
        delete newData.name;
      }

      const result = await projectService.getProjects(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectsById = createAsyncThunk(
  'project/getProjectsById',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProjectsById(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectReject = createAsyncThunk(
  'project/projectReject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectReject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectRejectInFavoritePage = createAsyncThunk(
  'project/projectRejectInFavoritePage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectReject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectRejectInRejectedPage = createAsyncThunk(
  'project/projectRejectInRejectedPage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectReject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectFavorite = createAsyncThunk(
  'project/projectFavorite',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectFavorite(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectFavoriteInFavoritePage = createAsyncThunk(
  'project/projectFavoriteInFavoritePage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectFavorite(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectFavoriteInRejectedPage = createAsyncThunk(
  'project/projectFavoriteInRejectedPage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectFavorite(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectFavoriteInCompany = createAsyncThunk(
  'project/projectFavoriteInCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectFavorite(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectSend = createAsyncThunk(
  'project/projectSend',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data, hideBid: Number(data.hideBid) };
      if (!newData.transmittalLetter) {
        delete newData.transmittalLetter;
      }
      const result = await projectService.projectSend(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExperts = createAsyncThunk(
  'project/getExperts',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      if (!newData.businessCategory) {
        delete newData.businessCategory;
      }
      if (!newData.country) {
        delete newData.country;
      }
      if (!newData.expertCategory) {
        delete newData.expertCategory;
      }
      if (!newData.expertCategorySecond) {
        delete newData.expertCategorySecond;
      }
      if (!newData.expertCategoryThird) {
        delete newData.expertCategoryThird;
      }
      if (!newData.name) {
        delete newData.name;
      }

      const result = await projectService.getExperts(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExpertsMore = createAsyncThunk(
  'project/getExpertsMore',
  async (data, { rejectWithValue }) => {
    try {
      const newData = { ...data };
      if (!newData.businessCategory) {
        delete newData.businessCategory;
      }
      if (!newData.country) {
        delete newData.country;
      }
      if (!newData.expertCategory) {
        delete newData.expertCategory;
      }
      if (!newData.expertCategorySecond) {
        delete newData.expertCategorySecond;
      }
      if (!newData.expertCategoryThird) {
        delete newData.expertCategoryThird;
      }
      if (!newData.name) {
        delete newData.name;
      }

      const result = await projectService.getExperts(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertReject = createAsyncThunk(
  'project/expertReject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.expertReject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertRejectDetail = createAsyncThunk(
  'project/expertRejectDetail',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.expertReject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertFavorite = createAsyncThunk(
  'project/expertFavorite',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.expertFavorite(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertFavoriteDetail = createAsyncThunk(
  'project/expertFavoriteDetail',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.expertFavorite(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertRejectInRejectedPage = createAsyncThunk(
  'project/expertRejectInRejectedPage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.expertReject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertFavoriteInRejectedPage = createAsyncThunk(
  'project/expertFavoriteInRejectedPage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.expertFavorite(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertRejectInFavoritePage = createAsyncThunk(
  'project/expertRejectInFavoritePage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.expertReject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertFavoriteInFavoritePage = createAsyncThunk(
  'project/expertFavoriteInFavoritePage',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.expertFavorite(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExpertsById = createAsyncThunk(
  'project/getExpertsById',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getExpertsById(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getActiveProjects = createAsyncThunk(
  'project/getActiveProjects',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getActiveProjects();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCompany = createAsyncThunk(
  'project/getCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExpert = createAsyncThunk(
  'project/getExpert',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getExpert(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectRejectInCompany = createAsyncThunk(
  'project/projectRejectInCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectReject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getFavorites = createAsyncThunk(
  'project/getFavorites',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getFavorites(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getRejected = createAsyncThunk(
  'project/getRejected',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getRejected(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getFavoritesExperts = createAsyncThunk(
  'project/getFavoritesExperts',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getFavoritesExperts(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getRejectedExperts = createAsyncThunk(
  'project/getRejectedExperts',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getRejectedExperts(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectInvite = createAsyncThunk(
  'project/projectInvite',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectInvite(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteProject = createAsyncThunk(
  'project/deleteProject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.deleteProject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProject = createAsyncThunk(
  'project/getProject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProject(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getInvitedExperts = createAsyncThunk(
  'project/getInvitedExperts',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getInvitedExperts(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getInvitedExpertsMore = createAsyncThunk(
  'project/getInvitedExpertsMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getInvitedExperts(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectCandidates = createAsyncThunk(
  'project/getProjectCandidates',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProjectCandidates(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectCandidatesMore = createAsyncThunk(
  'project/getProjectCandidatesMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProjectCandidates(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectFavoriteCandidates = createAsyncThunk(
  'project/getProjectFavoriteCandidates',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProjectFavoriteCandidates(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectFavoriteCandidatesMore = createAsyncThunk(
  'project/getProjectFavoriteCandidatesMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProjectFavoriteCandidates(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectRejectedCandidates = createAsyncThunk(
  'project/getProjectRejectedCandidates',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProjectRejectedCandidates(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectRejectedCandidatesMore = createAsyncThunk(
  'project/getProjectRejectedCandidatesMore',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.getProjectRejectedCandidates(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const chooseExecutor = createAsyncThunk(
  'project/chooseExecutor',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.chooseExecutor(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectDetailRejectExpert = createAsyncThunk(
  'project/projectDetailRejectExpert',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectDetailRejectExpert(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectDetailFavoriteExpert = createAsyncThunk(
  'project/projectDetailFavoriteExpert',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectDetailFavoriteExpert(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectRework = createAsyncThunk(
  'project/projectRework',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectRework(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectSubmit = createAsyncThunk(
  'project/projectSubmit',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectSubmit(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const cancelExecutor = createAsyncThunk(
  'project/cancelExecutor',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.cancelExecutor(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const projectSendPay = createAsyncThunk(
  'project/projectSendPay',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.projectSendPay(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const reviewProjectInfo = createAsyncThunk(
  'custom/reviewProjectInfo',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.reviewProjectInfo(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const reviewProjectCompany = createAsyncThunk(
  'custom/reviewProjectCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.reviewProjectCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createChat = createAsyncThunk(
  'project/createChat',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.createChat(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const proposeOtherConditions = createAsyncThunk(
  'project/proposeOtherConditions',
  async (data, { rejectWithValue }) => {
    try {
      const result = await projectService.proposeOtherConditions(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
