import { createAsyncThunk } from '@reduxjs/toolkit';
import { paymentService } from 'src/services';

export const getServices = createAsyncThunk(
  'payment/getServices',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getServices();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getServicesCompany = createAsyncThunk(
  'payment/getServicesCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getServicesCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const businessСategoriesService = createAsyncThunk(
  'payment/businessСategoriesService',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.businessСategoriesService(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPaidServices = createAsyncThunk(
  'payment/getPaidServices',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getPaidServices();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPaidServicesCompany = createAsyncThunk(
  'payment/getPaidServicesCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getPaidServicesCompany();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPaidServicesCompanyPositionOfProject = createAsyncThunk(
  'payment/getPaidServicesCompanyPositionOfProject',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getPaidServicesCompanyPositionOfProject();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const paidBusinessCategories = createAsyncThunk(
  'payment/paidBusinessCategories',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.paidBusinessCategories();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPackages = createAsyncThunk(
  'payment/getPackages',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getPackages();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPackagesCompany = createAsyncThunk(
  'payment/getPackagesCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getPackagesCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPaidPackages = createAsyncThunk(
  'payment/getPaidPackages',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getPaidPackages();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPaidPackagesCompany = createAsyncThunk(
  'payment/getPaidPackagesCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getPaidPackagesCompany();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getReferralUrl = createAsyncThunk(
  'payment/getReferralUrl',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.getReferralUrl();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const postPayments = createAsyncThunk(
  'payment/postPayments',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.postPayments(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const postPaymentsCompany = createAsyncThunk(
  'payment/postPaymentsCompany',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.postPaymentsCompany(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const paymentsHistory = createAsyncThunk(
  'payment/paymentsHistory',
  async (data, { rejectWithValue }) => {
    try {
      const result = await paymentService.paymentsHistory(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
