import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import avatarDefault from 'src/assets/img/proekt/avatar-default.jpg';
import sprite from 'src/assets/img/sprite.svg';
import { StarRate } from 'src/components';
import { useTranslation } from 'src/hooks';

import AcceptModal from './accept-modal';
import DeclineModal from './decline-modal';
import DisabledModal from './disabled-modal';

const Item = ({ tab, index }) => {
  const { translate } = useTranslation();
  let item = false;
  const [showDetail, setShowDetail] = useState(false);
  const myRequests = useSelector((state) => state.expertProjectReducer.myRequests);
  const myRejectedRequests = useSelector((state) => state.expertProjectReducer.myRejectedRequests);
  const myInvitations = useSelector((state) => state.expertProjectReducer.myInvitations);
  const myRejectedInvitations = useSelector(
    (state) => state.expertProjectReducer.myRejectedInvitations,
  );

  switch (tab) {
    case 1:
      item = myRequests[index];
      break;
    case 2:
      item = myRejectedRequests[index];
      break;
    case 3:
      item = myInvitations[index];
      break;
    case 4:
      item = myRejectedInvitations[index];
      break;
    default:
      break;
  }

  // Модалка проект не доступний
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Модалка отменить проект
  const [showDecline, setShowDecline] = useState(false);
  const handleCloseDecline = () => setShowDecline(false);
  const handleShowDecline = () => setShowDecline(true);

  // Модалка принять проект
  const [showAccept, setShowAccept] = useState(false);
  const handleCloseAccept = () => setShowAccept(false);
  const handleShowAccept = () => setShowAccept(true);

  return (
    <>
      {item && (
        <div
          className={`proekt__list__item ${
            item.project.executor || item.isDeclined ? 'disabled' : ''
          }`}
          title={item.project.executor || item.isDeclined ? 'Проект больше не доступен' : ''}
          key={item.id}
        >
          <div className="proekt__content__top">
            <div className="proekt__content__left">
              <div className="proekt__content__person proekt__content__person--list">
                <Link
                  to={`/employers/${item.project.company.slug}`}
                  className="proekt__content__avatar"
                  style={{
                    backgroundImage: `url(${item.project.company.avatar || avatarDefault})`,
                  }}
                />
                <div className="proekt__content__person__right">
                  <Link
                    to={`/projects?detail=${item.project.id}`}
                    className="proekt__content__person__name"
                  >
                    {item.project.name}
                  </Link>

                  <div className="proekt__tags">
                    {!item.project.hidden && (
                      <div className="proekt__tags__item">
                        <span className="tags-point">
                          {item.project.budget} {translate('grnPr')}
                        </span>
                      </div>
                    )}

                    {item.project.businessCategories.map((cat) => (
                      <div className="proekt__tags__item" key={cat.id}>
                        <span className="tags-point">{cat.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="proekt__content__right mb-2">
              {item.chatId && (
                <Link
                  to={`/messages/${item.chatId}?company=${item.project.company.id}&project=${item.project.id}`}
                  className="btn btn-primary"
                >
                  {translate('inMess')}
                </Link>
              )}
            </div>
          </div>
          <div className="my-offers--projectinfo">
            <div className="proekt__rating">
              <StarRate rate={item.project.company.avgReviewsRate} />

              <div className="proekt__rating__count">
                {item.project.company.reviewsCount} {translate('reviews')}
              </div>
            </div>
            <div className="proekt__location">
              <svg width="14" height="20">
                <use xlinkHref={`${sprite}#location`} />
              </svg>
              {item.project.company.country.name}, {item.project.company.city.name}
            </div>
            <div className="proekt__content__text mt-2">{item.project.description}</div>
            <div className="proekt__content__tags">
              {item.project.expertTwoCategories.map((cat) => (
                <div className="proekt__content__tags__item" key={cat.id}>
                  {cat.name}
                </div>
              ))}
              {item.project.expertThreeCategories.map((cat) => (
                <div className="proekt__content__tags__item" key={cat.id}>
                  {cat.name}
                </div>
              ))}
            </div>
          </div>
          {showDetail ? (
            <div className="my-offers__detail">
              <div className="proekt-detail__lang">
                {item.project.languages.map((lang) => (
                  <div className="item" key={lang.id}>
                    {lang.name}
                  </div>
                ))}
              </div>
              {item.project.screeningQuestions.length > 0 && (
                <div className="new-check">
                  <svg width="14" height="20">
                    <use xlinkHref={`${sprite}#new-check`} />
                  </svg>
                  {translate('MyOffers.haveScrn')}
                </div>
              )}
              {item.project.transmittalLetter && (
                <div className="new-check">
                  <svg width="14" height="20">
                    <use xlinkHref={`${sprite}#new-check`} />
                  </svg>
                  {translate('MyOffers.haveLatter')}
                </div>
              )}
              {item.project.projectDuration && (
                <div className="proekt-detail__duration">
                  <div className="duration1">{translate('MyOffers.during')}</div>
                  <div className="duration2">{item.project.projectDuration.text}</div>
                </div>
              )}
              <div className="my-offers__bot">
                {item.transmittalLetter && (
                  <>
                    <div className="my-offers__bot__title">{translate('MyOffers.latter')}</div>
                    <div className="my-offers__bot__text">{item.transmittalLetter}</div>
                  </>
                )}

                {item.screeningQuestions && (
                  <>
                    <div className="my-offers__bot__title">{translate('MyOffers.srcns')}</div>
                    {item.screeningQuestions.map((scr) => (
                      <div className="my-offers__src" key={scr.id}>
                        <div className="my-offers__src__title">{scr.text}</div>
                        <div className="my-offers__src__label">{translate('MyOffers.asnwer')}</div>
                        <div className="my-offers__bot__text">{scr.pivot.answer}</div>
                      </div>
                    ))}
                  </>
                )}

                {item.bid && (
                  <div className="my-offers__stavka">
                    <div className="my-offers__stavka__title">{translate('MyOffers.stavka')}</div>
                    <div className="proekt__tags">
                      <div className="proekt__tags__item">
                        <span className="tags-point">
                          {item.bid} {translate('grnPr')}
                        </span>
                      </div>
                      <div className="proekt__tags__item">
                        <span className="tags-point">
                          {item.completionTime} {translate('days')}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="my-offers__bot-flex">
                  <div className="proekt__content__actions">
                    <button
                      type="button"
                      className="proekt__chat"
                      onClick={() => setShowDetail(false)}
                    >
                      {translate('showLess')}
                      <svg width="44" height="21">
                        <use xlinkHref={`${sprite}#detail`} />
                      </svg>
                    </button>
                  </div>

                  <Link
                    to={`/employers/${item.project.company.slug}`}
                    className="btn btn-outline-primary"
                  >
                    {translate('MyOffers.allPr')}
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="proekt__content__bot">
              <div className="proekt__location" />
              <div className="proekt__content__actions">
                <div className="proekt__content__right--invations">
                  {!item.isAccepted && !item.isDeclined && tab === 3 && (
                    <>
                      <button type="button" className="btn btn-danger" onClick={handleShowDecline}>
                        {translate('decline')}
                      </button>
                      <button type="button" className="btn btn-primary" onClick={handleShowAccept}>
                        {translate('accept')}
                      </button>
                    </>
                  )}
                </div>
                {item.project.executor ? (
                  <button type="button" className="proekt__chat" onClick={() => handleShow(true)}>
                    {translate('detail')}
                    <svg width="44" height="21">
                      <use xlinkHref={`${sprite}#detail`} />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="proekt__chat"
                    onClick={() => setShowDetail(true)}
                  >
                    {translate('detail')}
                    <svg width="44" height="21">
                      <use xlinkHref={`${sprite}#detail`} />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <DisabledModal handleClose={handleClose} show={show} />
      <DeclineModal handleClose={handleCloseDecline} show={showDecline} id={item.id} />
      <AcceptModal handleClose={handleCloseAccept} show={showAccept} id={item.id} />
    </>
  );
};

Item.propTypes = {
  tab: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default Item;
