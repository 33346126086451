import { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import { FavoriteProjectModal, Loader, RejectProjectModal, StarRate } from 'src/components';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';

import NotActiveModal from './not-active-modal';

const Rejects = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const waiter = useSelector((state) => state.projectReducer.rejectedWaiter);
  const projects = useSelector((state) => state.projectReducer.rejectedProjects);
  const projectsPag = useSelector((state) => state.projectReducer.rejectedPag);
  const refList = useRef();
  const { search } = useLocation();
  const page = new URLSearchParams(search).get('page');
  const pageId = page || '1';

  useEffect(() => {
    dispatch(projectActions.getRejected(pageId));
  }, []);

  const handleChangePage = (nextPage) => {
    navigate(`/projects/rejects?page=${nextPage.selected + 1}`);
    dispatch(projectActions.getRejected(nextPage.selected + 1));
    if (refList.current) {
      window.scrollTo({
        top: refList.current.offsetTop - 80,
        behavior: 'instant',
      });
    }
  };

  // reject and favorite
  const [handleRejectModal, setHandleRejectModal] = useState(0);
  const [isReject, setIsReject] = useState(false);
  const [rejectModalXY, setRejectModalXY] = useState({ pageX: 0, pageY: 0 });

  const [handleFavoriteModal, setHandleFavoriteModal] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteModalXY, setFavoriteModalXY] = useState({ pageX: 0, pageY: 0 });

  const handleReject = (id) => {
    dispatch(projectActions.projectRejectInRejectedPage({ project: id }));
  };

  const handleFavorite = (id) => {
    dispatch(projectActions.projectFavoriteInRejectedPage({ project: id }));
  };

  const ifActive = !!useSelector(
    (state) =>
      state.authReducer.user.expert && state.authReducer.user.expert.profile_status.code !== 'NA',
  );
  const [notActive, setNotActive] = useState(false);
  const showNotActive = () => setNotActive(true);
  const hideNotActive = () => setNotActive(false);

  return (
    <>
      {waiter ? (
        <Loader />
      ) : (
        <>
          <div className="proekt__content">
            {projects.length > 0 ? (
              projects.map((item) => (
                <div
                  className={`proekt__list__item ${item.hot ? 'proekt__list__item--hot' : ''}`}
                  key={item.id}
                >
                  <div className="proekt__content__top">
                    <div className="proekt__content__left">
                      <div className="proekt__content__person proekt__content__person--list">
                        <Link
                          to={`/company/${item.company.id}`}
                          className="proekt__content__avatar"
                          style={{
                            backgroundImage: `url(${item.company.avatar || avatarDefaultComp})`,
                          }}
                        >
                          {' '}
                        </Link>
                        <div className="proekt__content__person__right">
                          <Link
                            to={`/projects?detail=${item.id}`}
                            className="proekt__content__person__name"
                          >
                            {item.name}
                            {item.pinned && (
                              <svg width="32" height="32" viewBox="0 0 32 32">
                                <use xlinkHref={`${sprite}#pinExpert`} />
                              </svg>
                            )}
                          </Link>
                          <div className="proekt__tags">
                            {item.premium && (
                              <div className="proekt__tags__item proekt__tags__item--premium">
                                <svg width="22" height="32">
                                  <use xlinkHref={`${sprite}#premium`} />
                                </svg>
                                Premium
                              </div>
                            )}
                            {!item.hidden && (
                              <div className="proekt__tags__item">
                                <span className="tags-point">
                                  {item.budget} {translate('Projects.pay')}
                                </span>
                              </div>
                            )}

                            {item.businessCategories.map((cat) => (
                              <div className="proekt__tags__item" key={cat.id}>
                                <span className="tags-point">{cat.name}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="proekt__rating">
                        <div className="proekt__rating">
                          <StarRate rate={item.company.avgReviewsRate} />

                          <div className="proekt__rating__count">
                            {item.company.reviewsCount} {translate('reviews')}
                          </div>
                        </div>
                      </div>
                      <div className="proekt__location">
                        <svg width="14" height="20">
                          <use xlinkHref={`${sprite}#location-current`} />
                        </svg>
                        {item.company.country && item.company.country.name}
                        {item.company.city && `, ${item.company.city.name}`}
                      </div>
                    </div>
                    <div className="proekt__content__right">
                      <div className="proekt__actios">
                        {item.hot && (
                          <div className="hot-project">
                            <svg width="32" height="32">
                              <use xlinkHref={`${sprite}#hotProject`} />
                            </svg>
                          </div>
                        )}

                        <button
                          type="button"
                          className={item.rejected ? 'active' : ''}
                          onClick={(e) => {
                            setRejectModalXY({ pageX: e.pageX, pageY: e.pageY });
                            setIsReject(item.rejected);
                            setHandleRejectModal(item.id);
                          }}
                        >
                          <svg width="13.2px" height="13.2px">
                            <use xlinkHref={`${sprite}#dislike`} />
                          </svg>
                          <svg width="13.2px" height="13.2px" className="active-svg">
                            <use xlinkHref={`${sprite}#dislike-active`} />
                          </svg>
                        </button>

                        <button
                          type="button"
                          className={item.favorite ? 'active' : ''}
                          onClick={(e) => {
                            setFavoriteModalXY({
                              pageX: e.pageX,
                              pageY: e.pageY,
                            });
                            setIsFavorite(item.favorite);
                            setHandleFavoriteModal(item.id);
                          }}
                        >
                          <svg width="13.2px" height="13.2px">
                            <use xlinkHref={`${sprite}#like`} />
                          </svg>
                          <svg width="13.2px" height="13.2px" className="active-svg">
                            <use xlinkHref={`${sprite}#like-active`} />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="proekt__content__text">{item.description}</div>
                  <div className="proekt__content__tags">
                    {item.expertCategories &&
                      item.expertCategories.map((expCat) => (
                        <div className="proekt__content__tags__item" key={expCat.id}>
                          {expCat.name}
                        </div>
                      ))}
                  </div>

                  <div className="proekt__content__bot__new">
                    <Link to={`/projects?detail=${item.id}`} className="proekt__chat">
                      {translate('Projects.detail')}
                      <svg width="15" height="10">
                        <use xlinkHref={`${sprite}#new-arr`} />
                      </svg>
                    </Link>
                    {item.isCandidate || item.isInvited ? (
                      <button type="button" className="btn btn-primary" disabled>
                        {item.isCandidate
                          ? translate('Projects.joinComp')
                          : translate('Projects.joinComp2')}
                      </button>
                    ) : (
                      <>
                        {ifActive ? (
                          <Link
                            type="button"
                            className="btn btn-primary"
                            to={`/projects?detailOffer=${item.id}`}
                          >
                            {translate('Projects.join')}
                          </Link>
                        ) : (
                          <button type="button" className="btn btn-primary" onClick={showNotActive}>
                            {translate('Projects.join')}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="mt-5 mb-5">{translate('Projects.empty')}</div>
            )}
          </div>

          {projects.length > 5 && (
            <div className="proekt__pagination">
              <ReactPaginate
                breakLabel="....."
                onPageChange={(nextPage) => handleChangePage(nextPage)}
                pageCount={projectsPag.last_page}
                forcePage={projectsPag.current_page - 1}
                activeClassName="active"
                pageRangeDisplayed="2"
                marginPagesDisplayed="1"
              />
            </div>
          )}
        </>
      )}
      {notActive && <NotActiveModal show={notActive} handleClose={hideNotActive} />}
      {handleRejectModal !== 0 && (
        <RejectProjectModal
          handleRejectModal={handleRejectModal}
          rejectModalXY={rejectModalXY}
          setHandleRejectModal={setHandleRejectModal}
          dispatch={handleReject}
          isReject={isReject}
        />
      )}

      {handleFavoriteModal !== 0 && (
        <FavoriteProjectModal
          handleFavoriteModal={handleFavoriteModal}
          favoriteModalXY={favoriteModalXY}
          setHandleFavoriteModal={setHandleFavoriteModal}
          dispatch={handleFavorite}
          isFavorite={isFavorite}
        />
      )}
    </>
  );
};

export default Rejects;
