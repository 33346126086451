import 'react-tooltip/dist/react-tooltip.css';

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import sprite from 'src/assets/img/sprite.svg';
import { FixLoader, Loader } from 'src/components';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { authActions, paymentActions, projectActions } from 'src/store/actions';

const CompanyPaid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const query = useQuery();

  const packageIdParam = query.get('packageId');
  const projectIdParam = query.get('projectId');
  const hideBudget = query.get('hideBudget');

  const errors = useSelector((state) => state.paymentReducer.errors);
  const waiter = useSelector((state) => state.paymentReducer.waiter);
  const waiterProject = useSelector((state) => state.projectReducer.waiter);
  const fixWaiter = useSelector((state) => state.paymentReducer.fixWaiter);
  const authWaiter = useSelector((state) => state.authReducer.waiter);

  const myProjectCompany = useSelector((state) => state.projectReducer.activeProject);
  const packagesArr = useSelector((state) => state.paymentReducer.packages);
  const packages = packagesArr.map((item) => ({
    ...item,
    check: String(item.packageId) === String(packageIdParam),
  }));
  const servicesDop = useSelector((state) => state.paymentReducer.servicesDop);
  const [modalProjectId, setModalProjectId] = useState('0');
  const [projectId, setProjectId] = useState(projectIdParam || '0');

  // вибір проекту
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Немає проекту
  const [showEmpty, setShowEmpty] = useState(false);
  const handleCloseEmpty = () => setShowEmpty(false);
  const handleShowEmpty = () => setShowEmpty(true);

  const servicesArr = useSelector((state) => state.paymentReducer.services);
  const services = servicesArr.map((item) => ({
    ...item,
    check: !!(item.serviceId === 3 && !!hideBudget),
    count: item.multiply ? 1 : 0,
  }));

  const refBalance = useSelector((state) => state.authReducer.user?.referralBalance);
  const referralBalance = useSelector((state) => state.authReducer.user?.referralBalance);

  useEffect(() => {
    dispatch(projectActions.getActiveProjects());
    dispatch(authActions.getProfileRefresh());
    dispatch(paymentActions.getPackagesCompany(projectId));
    dispatch(paymentActions.businessСategoriesService());
  }, []);

  const handleSubmit = (values) => {
    const data = {
      packages: values.packages
        .filter((item) => item.check)
        .map((item) => ({ id: item.packageId })),
      services: values.services
        .filter((item) => item.check)
        .map((item) => {
          if (item.count) {
            return { id: item.serviceId, count: item.count };
          }
          return { id: item.serviceId };
        }),

      amount: values.total,
      project: values.project,
      referralBalance: values.refBalance ? values.refBalanceUse : 0,
    };
    if (values.servicesDop.check) {
      data.services.push({ ...values.servicesDop, id: values.servicesDop.serviceId });
    }
    if (data.project === '0') {
      delete data.project;
    }
    dispatch(paymentActions.postPaymentsCompany(data)).then((res) => {
      if (res.payload.status === 200) {
        if (data.amount === 0) {
          navigate('/payment/status?status=2&refMess=Успішно оплачено за бонуси');
        } else {
          window.open(res.payload.data.checkout_url, '_blank').focus();
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      project: projectId,
      packages,
      services,
      servicesDop: { ...servicesDop, check: false, count: 1 },
      total: 0,
      refBalance: false,
      refBalanceUse: 0,
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    dispatch(paymentActions.getPackagesCompany(formik.values.project));
    dispatch(paymentActions.getServicesCompany(formik.values.project));
  }, [formik.values.project]);

  useEffect(() => {
    let total = 0;
    let refBalanceUse = 0;
    if (formik.values.packages) {
      formik.values.packages.map((item) => {
        if (item.check) {
          total += item.price;
        }
        return false;
      });
    }

    formik.values.services.map((item) => {
      if (item.check) {
        if (item.count) {
          total += item.price * item.count;
        } else {
          total += item.price;
        }
      }
      return false;
    });

    if (formik.values.servicesDop.check) {
      total += formik.values.servicesDop.price * formik.values.servicesDop.count;
    }

    if (formik.values.refBalance) {
      total -= refBalance;

      if (total <= 0) {
        refBalanceUse = refBalance + total;
        total = 0;
      } else {
        refBalanceUse = refBalance;
      }
    }

    formik.setFieldValue('refBalanceUse', refBalanceUse);
    formik.setFieldValue('total', total);
  }, [
    formik.values.services,
    formik.values.packages,
    formik.values.refBalance,
    formik.values.servicesDop,
  ]);

  const chooseProject = myProjectCompany.filter(
    (item) => item.id === Number(formik.values.project),
  );

  const handlePlus = (item, index) => {
    const max = item.max || 0;
    const position = item.position || 0;
    formik.setFieldValue(`services[${index}].check`, true);
    if (max && item.count < max) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    } else if (position && item.count < position - 1) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    } else if (!max && !position) {
      formik.setFieldValue(`services[${index}].count`, item.count + 1);
    }
  };

  const handlePlusDop = (item) => {
    const max = item.max || 0;
    formik.setFieldValue(`servicesDop.check`, true);
    if (max && item.count < max) {
      formik.setFieldValue(`servicesDop.count`, item.count + 1);
    }
  };

  const handleMinus = (item, index) => {
    formik.setFieldValue(`services[${index}].check`, true);
    if (item.count > 1) {
      formik.setFieldValue(`services[${index}].count`, item.count - 1);
    }
  };

  const handleMinusDop = (item) => {
    formik.setFieldValue(`servicesDop.check`, true);
    if (item.count > 1) {
      formik.setFieldValue(`servicesDop.count`, item.count - 1);
    }
  };

  return (
    <>
      {fixWaiter && <FixLoader />}
      {waiter || authWaiter || waiterProject ? (
        <Loader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="choose-pr">{translate('Payment.choosePr')}</div>
          <div className="choose-pr__list">
            <div className="choose-pr__item">
              <span>
                {chooseProject.length > 0 && chooseProject[0].name}
                {formik.values.project === '0' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      if (myProjectCompany.length > 0) {
                        handleShow();
                      } else {
                        handleShowEmpty();
                      }
                    }}
                  >
                    {translate('choose')}
                  </button>
                )}
              </span>
              {formik.values.project !== '0' && (
                <button type="button" className="btn btn-primary" onClick={handleShow}>
                  {translate('change')}
                </button>
              )}
            </div>
          </div>

          {formik.values.packages.length > 0 && (
            <div className="tarif">
              {formik.values.packages.map((item, index) => (
                <div
                  className={`tarif__item ${item.marker ? `tarif__item--popular` : ''} ${
                    item.disabled ? `tarif__item--disabled` : ''
                  }`}
                  key={item.packageId}
                >
                  {item.marker && <div className="tarif__popular">{item.marker}</div>}

                  <div className="tarif__name">
                    {item.title}
                    <button
                      type="button"
                      data-tooltip-id={`tarif-tooltip${item.packageId}`}
                      data-tooltip-content={item.tooltip}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use xlinkHref={`${sprite}#paymentLabel`} />
                      </svg>
                    </button>
                    <Tooltip id={`tarif-tooltip${item.packageId}`} />
                  </div>
                  <div
                    className="tarif__des"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <div className="tarif__price mb-5">
                    <div className="tarif__price__numb">{item.price} грн</div>
                    <div className="tarif__price__type">{item.per}</div>
                  </div>
                  <div className="tarif__btn">
                    {item.check ? (
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => formik.setFieldValue(`packages[${index}].check`, false)}
                      >
                        {translate('cancel')}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => formik.setFieldValue(`packages[${index}].check`, true)}
                      >
                        {translate('choose')}
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="payment__dop" />

          {formik.values.services.length > 0 && (
            <div className="payment__list">
              {formik.values.services.length &&
                formik.values.services.map((item, index) => (
                  <div
                    className={`payment__row ${item.disabled ? 'disabled' : ''}`}
                    key={item.serviceId}
                  >
                    <label
                      className="form-check form-check--payment"
                      htmlFor={`flexCheckDefault${item.serviceId}`}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={`services[${index}].check`}
                        onChange={formik.handleChange}
                        id={`flexCheckDefault${item.serviceId}`}
                        checked={item.check}
                      />
                    </label>
                    <div className="payment__name">
                      {item.title}
                      <button
                        type="button"
                        data-tooltip-id={`payment-tooltip${item.serviceId}`}
                        data-tooltip-content={item.tooltip}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <use xlinkHref={`${sprite}#paymentLabel`} />
                        </svg>
                      </button>
                      <Tooltip id={`payment-tooltip${item.serviceId}`} />

                      <span className="payment__name__position">
                        {item.positionText}
                        {item.position}
                      </span>
                    </div>

                    <div className="payment__count">
                      {item.multiply && (
                        <>
                          <div className="payment__count__flex">
                            <button
                              type="button"
                              className="minus"
                              onClick={() => handleMinus(item, index)}
                            >
                              -
                            </button>
                            <div className="payment__count__numb">{item.count}</div>
                            <button
                              type="button"
                              className="plus"
                              onClick={() => handlePlus(item, index)}
                            >
                              +
                            </button>
                          </div>
                          <div className="payment__count__text">{item.choosingLine}</div>
                        </>
                      )}
                    </div>

                    <div className="payment__price">
                      <div className="payment__price__left">{item.priceFormatted}</div>
                      <div className="payment__price__right">{item.per}</div>
                    </div>
                  </div>
                ))}
            </div>
          )}

          <div className="payment__dop">{translate('Payment.dop')}</div>
          {formik.values.servicesDop && (
            <div className="payment__list">
              <div className="payment__row">
                <label
                  className="form-check form-check--payment"
                  htmlFor={`flexCheckDefault${formik.values.servicesDop.serviceId}`}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="servicesDop.check"
                    onChange={formik.handleChange}
                    id={`flexCheckDefault${formik.values.servicesDop.serviceId}`}
                    checked={formik.values.servicesDop.check}
                  />
                </label>
                <div className="payment__name">
                  {formik.values.servicesDop.title}
                  <button
                    type="button"
                    data-tooltip-id={`dop-tooltip${formik.values.servicesDop.serviceId}`}
                    data-tooltip-content={formik.values.servicesDop.tooltip}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use xlinkHref={`${sprite}#paymentLabel`} />
                    </svg>
                  </button>
                  <Tooltip id={`dop-tooltip${formik.values.servicesDop.serviceId}`} />

                  <span className="payment__name__position">
                    {formik.values.servicesDop.positionText}
                    {formik.values.servicesDop.position}
                  </span>
                </div>

                <div className="payment__count">
                  {formik.values.servicesDop.multiply && (
                    <>
                      <div className="payment__count__flex">
                        <button
                          type="button"
                          className="minus"
                          onClick={() => handleMinusDop(formik.values.servicesDop)}
                        >
                          -
                        </button>
                        <div className="payment__count__numb">
                          {formik.values.servicesDop.count}
                        </div>
                        <button
                          type="button"
                          className="plus"
                          onClick={() => handlePlusDop(formik.values.servicesDop)}
                        >
                          +
                        </button>
                      </div>
                      <div className="payment__count__text">
                        {formik.values.servicesDop.choosingLine}
                      </div>
                    </>
                  )}
                </div>

                <div className="payment__price">
                  <div className="payment__price__left">
                    {formik.values.servicesDop.priceFormatted}
                  </div>
                  <div className="payment__price__right">{formik.values.servicesDop.per}</div>
                </div>
              </div>
            </div>
          )}

          <div className="payment__total">
            <div className="payment__total__right">
              <div className="payment__total__title">
                {translate('Payment.total')} {formik.values.total}
                {translate('grn')}
              </div>
              {referralBalance > 0 ? (
                <div className="payment__total__des">
                  <label className="form-check form-check--payment" htmlFor="refBalance">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="refBalance"
                      name="refBalance"
                      onChange={formik.handleChange}
                      defaultChecked={false}
                    />
                  </label>
                  {translate('Payment.useRef')} {referralBalance} {translate('grn')}
                </div>
              ) : (
                <div className="payment__total__des">
                  {translate('Payment.refBalance')} {referralBalance} {translate('grn')}
                </div>
              )}
            </div>
          </div>
          <div className="payment__total__bot">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={formik.values.total === 0 && formik.values.refBalanceUse === 0}
            >
              {translate('Payment.payBtn')}
            </button>
            {errors && errors.project && (
              <div className="payment__err">{translate('Payment.choosePrModal')}</div>
            )}
          </div>
        </form>
      )}

      <Modal show={showEmpty} onHide={handleCloseEmpty}>
        <Modal.Body>
          <div className="custom-modal-big">
            <button type="button" className="custom-modal__close" onClick={handleCloseEmpty}>
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="custom-modal__title custom-modal__title--public">
              {translate('Payment.emptyPr')}
            </div>
            <div className="custom-modal__subt custom-modal__subt--public" />
            <div className="custom-modal__btns">
              <Link to="/publication?frompayment=1" className="btn btn-primary">
                {translate('Payment.emptyPrBtn')}
              </Link>
              <Link to="/freelancers/" className="btn btn-outline-primary">
                {translate('Payment.backToPay2')}
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {myProjectCompany.length > 0 && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <div className="exp-modal paid-modal">
              <button type="button" className="del-portfolio-modal__btn" onClick={handleClose}>
                <svg width="20" height="20" viewBox="0 0 20 20">
                  <use xlinkHref={`${sprite}#modalClose`} />
                </svg>
              </button>
              <div className="paid-modal__title">{translate('Payment.choosePrModal')}</div>
              <div className="paid-modal__body">
                {myProjectCompany.map((item) => (
                  <label className="paid-modal__checkbox" htmlFor={`mch${item.id}`} key={item.id}>
                    <input
                      type="radio"
                      defaultChecked={formik.values.project === String(item.id)}
                      id={`mch${item.id}`}
                      name="project"
                      value={item.id}
                      onChange={(e) => setModalProjectId(e.target.value)}
                    />
                    <div className="paid-modal__checkbox__check">
                      <svg width="18" height="13">
                        <use xlinkHref={`${sprite}#modalCheckbox`} />
                      </svg>
                    </div>
                    <span className="paid-modal__checkbox__text">{item.name}</span>
                  </label>
                ))}

                <div className="paid-modal__action">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setProjectId(modalProjectId);
                      handleClose();
                    }}
                  >
                    {translate('next')}
                  </button>
                  <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
                    {translate('Payment.backToPay')}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default CompanyPaid;
