import { createSlice } from '@reduxjs/toolkit';

import { alertsActions } from '../actions';

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    alerts: [],
    alertsRead: [],
    alertsCount: 0,
    waiter: false,
    fixWaiter: false,
    errors: false,
  },
  reducers: {
    addMess(state, action) {
      state.chat = [...state.chat, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // getNotifications
      .addCase(alertsActions.getNotifications.pending, (state) => {
        state.waiter = true;
      })
      .addCase(alertsActions.getNotifications.fulfilled, (state, action) => {
        state.alerts = action.payload.data;
        state.waiter = false;
      })
      .addCase(alertsActions.getNotifications.rejected, (state) => {
        state.waiter = false;
      })

      // getNotificationsRefresh
      .addCase(alertsActions.getNotificationsRefresh.fulfilled, (state, action) => {
        state.alerts = action.payload.data;
      })

      // getReadNotifications
      .addCase(alertsActions.getReadNotifications.pending, (state) => {
        state.waiter = true;
      })
      .addCase(alertsActions.getReadNotifications.fulfilled, (state, action) => {
        state.alertsRead = action.payload.data;
        state.waiter = false;
      })
      .addCase(alertsActions.getReadNotifications.rejected, (state) => {
        state.waiter = false;
      })

      // readNot
      .addCase(alertsActions.readNot.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(alertsActions.readNot.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter((item) => item.id !== action.meta.arg.notification);
        state.alertsCount = state.alerts.length;
        state.fixWaiter = false;
      })
      .addCase(alertsActions.readNot.rejected, (state) => {
        state.fixWaiter = false;
      })

      // activeCount
      .addCase(alertsActions.activeCount.fulfilled, (state, action) => {
        state.alertsCount = action.payload.data;
        state.fixWaiter = false;
      });
  },
});

export default alertsSlice.reducer;
