import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { paymentActions } from 'src/store/actions';

const Referal = () => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const referralUrl = useSelector((state) => state.paymentReducer.referralUrl);

  const [isCopy, setIsCopy] = useState(false);

  const handleCopyText = () => {
    const copyText = document.getElementById('myInput');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);

    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 5000);
  };

  useEffect(() => {
    if (!referralUrl) {
      dispatch(paymentActions.getReferralUrl());
    }
  }, []);

  return (
    <>
      {referralUrl && (
        <div className="payment__ref--sidebar">
          <div className="payment__ref">
            <div className="payment__ref__left">
              <div className="payment__ref__title">{translate('referal.ref')}</div>
              <div className="payment__ref__des">
                {translate('referal.refText')} <br /> <b>{translate('referal.text2')}</b>
              </div>
              <div className="payment__ref__price">
                <div className="left">100 {translate('bonusov')} </div>
                <div className="right">{translate('referal.refType')}</div>
              </div>
            </div>
            <div className="payment__ref__right">
              <input type="text" defaultValue={referralUrl} id="myInput" hidden />
              <button type="button" className="btn btn-outline-primary" onClick={handleCopyText}>
                {translate('referal.friend')}
              </button>
            </div>
          </div>

          {isCopy && (
            <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
              <svg
                className="bi flex-shrink-0 me-2"
                width="24"
                height="24"
                role="img"
                aria-label="Success:"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <div>{translate('referal.link')}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Referal;
