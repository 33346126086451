import { useFormik } from 'formik';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import * as Yup from 'yup';

const ServicesCost = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const firstSignUp = useSelector((state) => state.authReducer.firstSignUp);
  const [isSub, setIsSub] = useState(false);
  const dispatch = useDispatch();
  const { costPerHour, costPerProject } = useSelector((state) => state.authReducer.user.expert);
  const handleSubmit = (data) => {
    dispatch(authActions.setCost(data)).then((res) => {
      if (res.payload.status === 200) {
        setIsSub(true);
        setTimeout(() => {
          setIsSub(false);
        }, 5000);

        if (firstSignUp) {
          navigate('/user/contacts/');
        }
      }
    });
  };

  const costSchema = Yup.object().shape({
    costPerHour: Yup.number().required(translate('Valid.req')),
    costPerProject: Yup.number().required(translate('Valid.req')),
  });

  const formik = useFormik({
    initialValues: {
      costPerHour: costPerHour || '',
      costPerProject: costPerProject || '',
    },
    validationSchema: costSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleChange = (val, name) => {
    const newVal = val
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/^0[^.]/, '0');
    formik.setFieldValue(name, newVal);
  };

  return (
    <>
      <div className="exp-content__title" id="cost">
        <h3>{translate('About.cost')}</h3>
      </div>
      <form className="exp-content__wrap" onSubmit={formik.handleSubmit}>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">{translate('About.costSub')}</div>
          </div>
          <div className="exp-content__row__right">
            <div className="from-to">
              <div className="from-to__line">
                <div className="from-to__col1 mr-8px" />
                <div className="from-to__col2 ml-3px">
                  <input
                    type="text"
                    className={`input ${formik.errors.costPerHour ? 'error' : ''} `}
                    name="costPerHour"
                    value={formik.values.costPerHour}
                    onChange={(e) => handleChange(e.target.value, 'costPerHour')}
                    maxLength={10}
                  />
                </div>
                <div className="from-to__col3">
                  {translate('About.dolarH')} <span>*</span>
                </div>
              </div>
              <div className="from-to__line">
                <div className="from-to__col1 mr-8px">{translate('About.from')}</div>
                <div className="from-to__col2">
                  <input
                    type="text"
                    className={`input ${formik.errors.costPerProject ? 'error' : ''}`}
                    name="costPerProject"
                    value={formik.values.costPerProject}
                    onChange={(e) => handleChange(e.target.value, 'costPerProject')}
                    maxLength={10}
                  />
                </div>
                <div className="from-to__col3">
                  {translate('About.dolarP')} <span>*</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left" />
          <div className="exp-content__row__right">
            <button type="submit" className="btn btn-primary">
              {firstSignUp ? translate('next') : translate('save')}
            </button>
          </div>
        </div>
        {isSub && (
          <div className="exp-content__row">
            <div className="exp-content__row__left" />
            <div className="exp-content__row__right">
              <div className="alert alert-success d-flex align-items-center mt-3" role="alert">
                <svg
                  className="bi flex-shrink-0 me-2"
                  width="24"
                  height="24"
                  role="img"
                  aria-label="Success:"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
                <div>{translate('Contacts.success')}</div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default ServicesCost;
