import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { FixLoader, Footer, Header, Loader, SidebarPublication, Subheader } from 'src/components';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';
import { addCurrentProject } from 'src/store/reducers/project.reducer';

import HideBudgetModal from './hide-budget-modal';
import Preview from './preview';
import Step1 from './step1';
import Step2 from './step2';
import Step3SubStep1 from './step3-sub-step1';
import Step3SubStep2 from './step3-sub-step2';
import Step3SubStep3 from './step3-sub-step3';
import Step3SubStep4 from './step3-sub-step4';
import Step3SubStep5 from './step3-sub-step5';
import Step4 from './step4';
import SubModal from './sub-modal';

const Publication = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const fromaymentParam = query.get('frompayment') || '';
  const [frompayment] = useState(fromaymentParam);
  const [showAllLinks, setShowAllLinks] = useState(false);
  const { translate } = useTranslation();
  const waiter = useSelector((state) => state.projectReducer.waiter);
  const fixLoader = useSelector((state) => state.projectReducer.fixLoader);
  const dispatch = useDispatch();
  const pageRef = useRef();

  // submite modal
  const [showSub, setShowSub] = useState(false);
  const handleShowSub = () => setShowSub(true);

  // HideBudgetModal
  const [showHideBudget, setShowHideBudget] = useState(false);
  const handleShowHideBudget = () => setShowHideBudget(true);
  const [newProjectId, setNewProjectId] = useState(0);

  useEffect(() => {
    dispatch(projectActions.getCompanyCategories());
    dispatch(projectActions.getLangs());
    dispatch(projectActions.getProjectDurations());
    dispatch(projectActions.getExpertList());
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      businessCategories: [],
      description: '',
      languages: [],
      expertThreeCategories: [],
      expertThreeLevel2: [],
      expertThreeLevel1: [],
      invitations: [],
      projectDuration: 1,
      projectType: 1,
      screeningQuestions: [''],
      transmittalLetter: 0,
      budget: '',
      doNotShowPayment: 0,
    },
  });

  useEffect(() => {
    if (!formik.values.name) {
      navigate('/publication');
    }
  }, [formik.values.name]);

  const handleSubmit = async () => {
    const data = formik.values;
    const values = {
      name: data.name,
      description: data.description,
      budget: data.budget,
      doNotShowPayment: data.doNotShowPayment.length ? 1 : 0,
      businessCategories: data.businessCategories,
      languages: data.languages.map((item) => item.id),
      expertThreeCategories: data.expertThreeCategories.map((item) => item),
      expertThreeLevel2: data.expertThreeLevel2.map((item) => item),
      expertThreeLevel1: data.expertThreeLevel1.map((item) => item),
      invitations: data.invitations.map((item) => item),
      projectDuration: data.projectDuration,
      projectType: data.projectType,
      rateCheck: data.rateCheck ? 1 : 0,
      screeningQuestions: data.screeningQuestions.map((item) => item.name || ''),
      transmittalLetter: data.transmittalLetter.length ? 1 : 0,
    };

    const res = await dispatch(projectActions.createProject(values));
    if (res.payload.status === 200) {
      if (values.doNotShowPayment) {
        setNewProjectId(res.payload.data.id);
        handleShowHideBudget();
      } else {
        handleShowSub();
      }
    }
  };

  useEffect(() => {
    const data = formik.values;

    const values = {
      name: data.name,
      description: data.description,
      budget: data.budget,
      doNotShowPayment: data.doNotShowPayment.length ? 1 : 0,
      businessCategories: data.businessCategories,
      languages: data.languages.map((item) => item.id),
      expertThreeCategories: data.expertThreeCategories.map((item) => item),
      expertThreeLevel2: data.expertThreeLevel2.map((item) => item),
      expertThreeLevel1: data.expertThreeLevel1.map((item) => item),
      invitations: data.invitations.map((item) => item),
      projectDuration: data.projectDuration,
      projectType: data.projectType,
      rateCheck: data.rateCheck ? 1 : 0,
      screeningQuestions: data.screeningQuestions.map((item) => item.name),
      transmittalLetter: data.transmittalLetter.length ? 1 : 0,
    };
    dispatch(addCurrentProject(values));
  }, [formik.values]);

  // Скролл на початок компонента
  const { pathname } = useLocation();
  const srollToTop = () => {
    window.scrollTo({
      top: pageRef.current.offsetTop - 80,
      behavior: 'instant',
    });
  };
  useEffect(() => {
    srollToTop();
  }, [pathname]);

  return (
    <>
      {fixLoader && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            <nav className="prpb__breadcrumb">
              <ul>
                <li>
                  <Link to="/">{translate('Publication.main')}</Link>
                </li>
                <li>
                  <Link to="/my-projects">{translate('Publication.myPr')}</Link>
                </li>
                <li>
                  <span>{translate('Publication.pbPr')}</span>
                </li>
              </ul>
            </nav>
            <div className="exp-content exp-content--prbp">
              <SidebarPublication showAllLinks={showAllLinks} />

              <div className="exp-content__edit" ref={pageRef}>
                <div className="prpb">
                  <div className="exp-content__top">{translate('Publication.pbPr')}</div>
                  {waiter ? (
                    <Loader />
                  ) : (
                    <Routes>
                      <Route path="/" element={<Step1 setValue={formik.setFieldValue} />} />
                      {formik.values.name && (
                        <>
                          <Route
                            path="/step2/"
                            element={<Step2 setValue={formik.setFieldValue} />}
                          />
                          <Route
                            path="/step3/"
                            element={<Step3SubStep1 setValue={formik.setFieldValue} />}
                          />
                          <Route
                            path="/step3/sub-step2/"
                            element={<Step3SubStep2 setValue={formik.setFieldValue} />}
                          />
                          <Route
                            path="/step3/sub-step3/"
                            element={<Step3SubStep3 setValue={formik.setFieldValue} />}
                          />
                          <Route
                            path="/step3/sub-step4/"
                            element={<Step3SubStep4 setValue={formik.setFieldValue} />}
                          />
                          <Route
                            path="/step3/sub-step5/"
                            element={<Step3SubStep5 setValue={formik.setFieldValue} />}
                          />
                          <Route
                            path="/step4/"
                            element={
                              <Step4
                                setValue={formik.setFieldValue}
                                setShowAllLinks={setShowAllLinks}
                              />
                            }
                          />
                          <Route path="/preview/" element={<Preview submitForm={handleSubmit} />} />
                        </>
                      )}
                    </Routes>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
      {showSub && <SubModal show={showSub} frompayment={!!frompayment} />}
      {showHideBudget && <HideBudgetModal show={showHideBudget} newProjectId={newProjectId} />}
    </>
  );
};
export default Publication;
