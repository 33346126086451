import { Route, Routes } from 'react-router-dom';

import EnterCode from './enter-code';
import EnterPhone from './enter-phone';
import ResetPass from './resset-pass';
import Thank from './thank';

const FogotPass = () => (
  <Routes>
    <Route path="/*" element={<EnterPhone />} />
    <Route path="/enter-code/*" element={<EnterCode />} />
    <Route path="/enter-code/*" element={<EnterCode />} />
    <Route path="/resset-pass/*" element={<ResetPass />} />
    <Route path="/thank/*" element={<Thank />} />
  </Routes>
);

export default FogotPass;
