import { FieldArray, FormikProvider, useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import draftProject from 'src/assets/img/svg/draft-project.svg';
import fulltimeProject from 'src/assets/img/svg/fulltime-job.svg';
import { useTranslation } from 'src/hooks';
import { v4 as uuidv4 } from 'uuid';

const Step3SubStep2 = ({ setValue }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const currentProject = useSelector((state) => state.projectReducer.currentProject);

  const handleSubmit = async ({ projectType, transmittalLetter, screeningQuestions }) => {
    const screeningQuestionsFiltred = screeningQuestions.filter((item) => item.name);
    setValue('projectType', projectType);
    setValue('transmittalLetter', transmittalLetter);
    setValue('screeningQuestions', screeningQuestionsFiltred);
    navigate('/publication/step3/sub-step3/');
  };

  const formik = useFormik({
    initialValues: {
      projectType: String(currentProject.projectType),
      transmittalLetter: currentProject.transmittalLetter || 0,
      screeningQuestions: currentProject.screeningQuestions.length
        ? currentProject.screeningQuestions.map((item) => ({ id: uuidv4(), name: item }))
        : [],
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseAndClear = () => {
    setShow(false);
    formik.setFieldValue('screeningQuestions', [{ id: uuidv4(), name: '' }]);
  };

  return (
    <form className="prpb__wrap" onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <div className="prpb__name">{translate('Publication.detail')}</div>
        <div className="prpb__body" role="group" aria-labelledby="my-radio-group">
          <div className="prpb__label">{translate('Publication.proektType')}</div>
          <div className="prpb__type">
            <label className="prpb__type__item" htmlFor="label1">
              <input
                type="radio"
                name="projectType"
                value="1"
                id="label1"
                onChange={formik.handleChange}
                checked={formik.values.projectType === '1'}
              />
              <div className="prpb__type__checkbox" />
              <img
                className="prpb__type__img"
                src={draftProject}
                width="40px"
                height="40px"
                alt=""
              />
              <div className="prpb__type__name">{translate('Publication.type1')}</div>
              <div className="prpb__type__text">{translate('Publication.type1Text')}</div>
              <div className="prpb__type__text">{translate('Publication.type1Text2')}</div>
            </label>
            <label className="prpb__type__item" htmlFor="label2">
              <input
                type="radio"
                name="projectType"
                value="2"
                id="label2"
                onChange={formik.handleChange}
                checked={formik.values.projectType === '2'}
              />
              <div className="prpb__type__checkbox" />
              <img
                className="prpb__type__img"
                src={fulltimeProject}
                width="40px"
                height="40px"
                alt=""
              />
              <div className="prpb__type__name">{translate('Publication.type2')}</div>
              <div className="prpb__type__text">{translate('Publication.type2Text')}</div>
              <div className="prpb__type__text">{translate('Publication.type2Text2')}</div>
            </label>
          </div>
          <div className="prpb__hr" />
          <div className="prpb__label">{translate('Publication.qa')}</div>

          <div className="prpb__just-text">{translate('Publication.qa2')}</div>
          <button type="button" className="prpb__btn" onClick={handleShow}>
            {translate('Publication.addQa')}
          </button>
          <div className="mt-2">
            {formik.values.screeningQuestions.map((item, index) => {
              if (item.name) {
                return (
                  <div className="prpb__just-text mb-2" key={item.id}>
                    {index + 1}. {item.name}
                  </div>
                );
              }
              return false;
            })}
          </div>

          <div className="prpb__hr" />
          <div className="prpb__label"> {translate('Publication.letter')}</div>
          <div className="prpb__just-text">{translate('Publication.letter2')}</div>
          <label className="prpb__blue-check" htmlFor="check">
            <input
              type="checkbox"
              id="check"
              name="transmittalLetter"
              value="1"
              defaultChecked={formik.values.transmittalLetter}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="prpb__blue-check__input" />
            <div className="prpb__blue-check__text">{translate('Publication.letterCheck')}</div>
          </label>
        </div>
        <div className="prpb__footer">
          <button className="btn btn-primary" type="submit">
            {translate('next')}
          </button>
          <Link className="btn btn-outline-primary" to="/publication/step3/">
            {translate('cancel')}
          </Link>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <div className="questions-modal">
              <div className="questions-modal__head">
                <div className="questions-modal__title">{translate('Publication.addQa2')}</div>

                <button type="button" onClick={handleClose} className="questions-modal__close">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3936 7.59497L7.60156 12.387"
                      stroke="#200E32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.3976 12.3898L7.60156 7.59277"
                      stroke="#200E32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.334 0.750244H5.665C2.644 0.750244 0.75 2.88924 0.75 5.91624V14.0842C0.75 17.1112 2.635 19.2502 5.665 19.2502H14.333C17.364 19.2502 19.25 17.1112 19.25 14.0842V5.91624C19.25 2.88924 17.364 0.750244 14.334 0.750244Z"
                      stroke="#200E32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="questions-modal__body">
                <FieldArray
                  name="screeningQuestions"
                  render={(helpers) => (
                    <>
                      {formik.values.screeningQuestions.length < 5 && (
                        <button
                          type="button"
                          className="prpb__btn"
                          onClick={() => helpers.push({ id: uuidv4(), name: '' })}
                        >
                          {translate('Publication.addQa')}
                        </button>
                      )}

                      <div className="questions-modal__list">
                        {formik.values.screeningQuestions.map((item, index) => (
                          <div className="questions-modal__list__item" key={item.id}>
                            <div className="prpb__label">{translate('Publication.enterQa')}</div>
                            <div className="questions-modal__list__input">
                              <input
                                className="input"
                                maxLength="265"
                                defaultValue={formik.values.screeningQuestions[index].name}
                                name={`screeningQuestions[${index}].name`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder={translate('Publication.qaPl')}
                              />
                              <div className="questions-modal__list__limit">
                                {translate('Publication.qaLimit')}
                              </div>
                              <button
                                className="questions-modal__delete"
                                type="button"
                                onClick={() => helpers.remove(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="7"
                                  height="7"
                                  viewBox="0 0 7 7"
                                  fill="none"
                                >
                                  <path
                                    d="M5.792 1.00195L1 5.79395"
                                    stroke="#2D9CDB"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M5.796 5.797L1 1"
                                    stroke="#2D9CDB"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                />
              </div>
              <div className="questions-modal__footer">
                <div className="questions-modal__footer__wrap">
                  <div className="text">
                    {translate('Publication.qaText1')} {formik.values.screeningQuestions.length}{' '}
                    {translate('Publication.qaText2')}
                  </div>
                  <div className="questions-modal__footer__btns">
                    <button className="btn btn-primary" type="button" onClick={handleClose}>
                      {translate('next')}
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={handleCloseAndClear}
                    >
                      {translate('cancel')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </FormikProvider>
    </form>
  );
};

Step3SubStep2.propTypes = {
  setValue: PropTypes.func.isRequired,
};

export default Step3SubStep2;
