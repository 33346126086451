import 'react-tooltip/dist/react-tooltip.css';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { paymentActions } from 'src/store/actions';

const ExpertComplited = () => {
  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.paymentReducer.waiter);
  const waiterServices = useSelector((state) => state.paymentReducer.waiterServices);
  const services = useSelector((state) => state.paymentReducer.paidServices);
  const packages = useSelector((state) => state.paymentReducer.paidPackages);
  const { translate } = useTranslation();

  useEffect(() => {
    dispatch(paymentActions.getPaidServices());
    dispatch(paymentActions.getPaidPackages());
  }, []);

  return (
    <>
      {waiter || waiterServices ? (
        <Loader />
      ) : (
        <div className="complited__page">
          {packages.length > 0 && (
            <div className="tarif">
              {packages.map((item) => (
                <div
                  className={`tarif__item ${item.marker ? `tarif__item--popular` : ''}`}
                  key={item.packageId}
                >
                  {item.marker && <div className="tarif__popular">{item.marker}</div>}

                  <div className="tarif__name">
                    {item.name}
                    <button
                      type="button"
                      data-tooltip-id={`tarif-tooltip${item.packageId}`}
                      data-tooltip-content={item.tooltip}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <use xlinkHref={`${sprite}#paymentLabel`} />
                      </svg>
                    </button>
                    <Tooltip id={`tarif-tooltip${item.packageId}`} />
                  </div>
                  <div
                    className="tarif__des"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <div className="tarif__price">
                    <div className="tarif__price__numb">
                      {item.price} {translate('grn')}
                    </div>
                    <div className="tarif__price__type">{item.per}</div>
                  </div>
                  <div className="tarif__timer">{item.remainText}</div>
                  <div className="tarif__btn">
                    <Link to="/payment/paid" className="btn btn-primary">
                      {translate('Payment.extend')}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="payment__dop">{translate('Payment.dop')}</div>
          {services.length > 0 &&
            services.map((item) => (
              <div className="complited" key={item.serviceId}>
                <div className="complited__name">{item.title}</div>
                <div className="complited__name2">
                  {item.remainTitle}{' '}
                  <b>
                    {item.remainText} {item.remainValue}
                  </b>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default ExpertComplited;
