import { Link } from 'react-router-dom';
import { Footer, HeaderContentPage } from 'src/components';
import { useTranslation } from 'src/hooks';

const PolicyUser = () => {
  const { translate } = useTranslation();

  return (
    <>
      <div className="app base">
        <HeaderContentPage />
        <main className="main">
          <div className="wrap">
            <div className="base__main mt-3">
              <div className="proekt__search" />
              <nav className="prpb__breadcrumb">
                <ul>
                  <li>
                    <Link to="/">{translate('main')}</Link>
                  </li>
                  <li>
                    <span>{translate('policyUser.title')}</span>
                  </li>
                </ul>
              </nav>
              <div className="exp-content">
                <div className="exp-content__edit">
                  <div className="exp-content__top exp-content__top__mess">
                    <div>
                      <div>{translate('policyUser.title')}</div>
                      <div>{translate('policyUser.title2')}</div>
                    </div>
                  </div>
                  <div className="base__detail">
                    <b>1. Визначення понять</b>
                    <ul>
                      <li>
                        Терміни і визначення, які вживаються в цьому та інших документах, які
                        розміщені на ресурсах Платформи мають наступні значення:{' '}
                      </li>
                      <li>
                        Сервіс <b>&ldquo;ExpertLand.ua&ldquo; / Платформа</b> - веб-платформа, яка
                        об’єднує програмні та апаратні засоби для забезпечення взаємодії між
                        зареєстрованими користувачами та/або між користувачами та Товариством;
                      </li>
                      <li>
                        <b>Товариство</b> - ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ ТОВ
                        «ЕКСПЕРТЛЕНД» (надалі – &ldquo;Товариство&ldquo;), юридична особа, яка
                        зареєстрована та діє відповідно до законодавства України, ідентифікаційний
                        код / код ЄДРПОУ – 44975380, місцезнаходження: 03134, м. Київ, вул.
                        Булгакова, буд. 12-А, кв. 146 (код ЄДРПОУ – 44975380) та є власником /
                        адміністратором Платформи;
                      </li>
                      <li>
                        <b>Користувач</b> – фізична особа, яка досягла 18-літнього віку або юридична
                        особа, зареєстрована на Платформі відповідно до цієї Угоди. Не можуть бути
                        Користувачами та не мають права використовувати Сервіс з жодною метою
                        резиденти Російської Федерації та Республіки Білорусь;
                      </li>
                      <li>
                        <b>Угода користувача</b> та/або <b>Оферта</b>, та/або <b>Договір</b> (в
                        залежності від контексту) – цей документ та всі додатки, які становлять його
                        невід’ємну частину;
                      </li>
                      <li>
                        <b>Проєкт</b> – завдання, яке публікується у Сервісі Замовником (фізичною
                        та/або юридичною особою) та виконується Виконавцем (Експертом);
                      </li>
                      <li>
                        <b>Обліковий запис</b> та/або <b>Профіль</b> – унікальне ім’я користувача
                        (логін) та пароль, які використовують для доступу до персональних сторінок
                        користувача в рамках Сервісу;
                      </li>
                      <li>
                        <b>Замовник</b> – користувач, який виконує пошук виконавців для проєкту в
                        рамках Платформи;
                      </li>
                      <li>
                        <b>Виконавець, Фрилансер, Експерт</b> - користувач, який надає послуги
                        та/або виконує роботи по Проєкту (завданню) Замовника на підставі договірних
                        відносин з останнім. Виконавець не є співробітником Товариства (Сервісу) і
                        не вступає з ним у трудові відносини;
                      </li>
                      <li>
                        <b>Контактні дані</b> – інформація, яка містить номери телефонів, адреси
                        електронної пошти, посилання на облікові записи в соціальних мережах або
                        месенджерах, а також посилання на веб-сайти, якщо їхнє основне призначення -
                        передача особистої інформації або відображення персональних даних
                        користувача при переході на дані посилання. Частина (вся або значна)
                        контактних даних може відноситися до персональних даних, обробка яких
                        здійснюється Товариством відповідно до{' '}
                        <a href="https://expertland.ua/policy">
                          Порядку обробки персональних даних (Повідомлення про порядок обробки та
                          захисту персональних даних)
                        </a>
                        ;
                      </li>
                      <li>
                        <b>Підозріла активність</b> - набір певних факторів, які визначаються
                        адміністрацією Сервісу і вказують на нетипову поведінку Користувача,
                        наприклад: часті повідомлення в Direct (спам), передача шаблонних
                        повідомлень, додавання подібних коментарів, тощо;
                      </li>
                      <li>
                        <b>Платіжні дані</b> - унікальні номери платіжних (банківських) карт та
                        електронних гаманців, які можуть бути використані для взаєморозрахунків між
                        Користувачами;
                      </li>
                      <li>
                        <b>Платіжний агрегатор</b> - фінансова компанія, яка забезпечує платежі в
                        рамках роботи Сервісу.
                      </li>
                      <li>
                        Інші терміни та поняття, які не визначені в цьому розділі, трактуються
                        відповідно до чинного законодавства України, а у випадку відсутності такого,
                        – відповідно до господарської практики (ділових звичаїв). У разі виявлення в
                        цій Угоді користувача та/або інших документах Товариства, які викладені на
                        Сервісі, двозначностей або розбіжностей з цієї Угодою, адміністрація Сервісу
                        надасть необхідні роз&apos;яснення або інструкції.
                      </li>
                    </ul>
                    <b>2. Загальні положення</b>
                    <ul>
                      <li>
                        Ця Оферта є відкритою пропозицією ТОВАРИСТВА З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ
                        ТОВ «ЕКСПЕРТЛЕНД» щодо укладення договірних відносин та отримання послуг
                        інформаційного та інформаційно-рекламного характеру на викладених в цій
                        Оферті умовах, включаючи її додатки та/або документи, на які є посилання у
                        цьому документі.
                        <br />
                        Угодою користувача встановлено основні умови та правила використання
                        Сервісом, незалежно від того яким чином на нього заходить Користувач: через
                        веб-сайт, та/або мобільний додаток, та/або інший сервіс прикладного
                        програмного інтерфейсу.
                      </li>
                      <li>
                        Ця Оферта є публічною і адресована необмеженому колу фізичних і юридичних
                        осіб (крім осіб, обмеження вступу у взаємовідносини з якими обмежено законом
                        або чинними рішеннями державних органів), шляхом повного і безумовного
                        прийняття її умов, а також інших правил та політик, посилання на які є
                        містяться у цьому документі або оприлюднені на Сервісі.
                        <br />
                        Умови цього Договору є однаковими для усіх осіб, які вирішили прийняти його
                        умови та зареєструватися на Платформі.
                      </li>
                      <li>
                        Основним завданням (призначенням) Сервісу в рамках цієї Угоди користувача є:
                        реєстрація та адміністрування облікових Профілів (акаунтів, кабінетів)
                        Користувачів, розміщення замовлень (завдань) від Замовників та пропозицій
                        щодо їх виконання від Виконавців, іншого інформаційного та
                        інформаційно-рекламного контенту, надання інших послуг Платформи, а також
                        додаткових послуг та сервісів від Товариства (у т.ч. платних). <br />
                        Платформа є «місцем зустрічі бізнесу та експертів», сприяє встановленню
                        договірних відносин між Замовниками та Фрилансерами в рамках надання послуг
                        (виконання робіт) між ними.
                      </li>
                      <li>
                        Ця Угода користувача є договором приєднання в розумінні статті 634
                        Цивільного кодексу України, оскільки її умови визначені Товариством і можуть
                        бути прийняті іншою стороною лише шляхом приєднання до запропонованого
                        Договору в цілому. У разі незгоди іншої сторони з усіма чи окремими
                        положеннями цього Договору укладення Договору не відбувається та реєстрація
                        такої сторони на Платформі не проводиться (не закінчується процес
                        реєстрації).
                      </li>
                      <li>
                        В частині надання послуг Сервісом цей Договір є публічним договором в
                        розумінні статті 633 Цивільного кодексу України, а особа, яка приймає
                        пропозицію (публічну оферту) про укладення Договору (здійснює акцепт) стає
                        її іншою стороною відповідно до статті 642 Цивільного кодексу України,
                        статті 11 Закону України &ldquo;Про електронну комерцію&ldquo; та умов цього
                        Договору.
                      </li>
                      <li>
                        Ця Оферта є електронним договором в розумінні статті 3 Закону України
                        &ldquo;Про електронну комерцію&ldquo;, укладається з використанням доступних
                        інформаційно-телекомунікаційних систем та не може бути визнаний недійсним у
                        зв’язку з його вчиненням в електронній формі. Текст Договору публікується на
                        основному сайті Товариства за адресою:{' '}
                        <a href="https://expertland.ua/policy-user">
                          https://expertland.ua/policy-user
                        </a>
                        . Договір набуває чинності з моменту його розміщення на вказаному сайті.
                        Товариство має право змінювати умови Договору (у т.ч. у зв’язку зі змінами
                        вимог діючого законодавства, тощо) для всіх нових угод, за якими не було
                        оплати на момент внесення змін до нього.
                        <br />
                        Цей Договір у паперовій формі може бути наданий за окремим зверненням
                        Користувача, а також може бути роздрукований Користувачем самостійно з
                        веб-сайту Товариства.
                      </li>
                      <li>
                        Крім тексту цього Договору, порядок надання послуг і сервісів Товариством,
                        правила користування Платформою, порядок і вимоги до розміщення замовлень,
                        пропозицій, інформаційно-рекламних матеріалів, взаємодії між Користувачами
                        визначаються також і іншими документами, які розроблені Товариством у
                        відповідності до вимог діючого законодавства.
                      </li>
                    </ul>
                    <b>3. Предмет Договору. Акцептування Договору Користувачем</b>
                    <ul>
                      <li>
                        В порядку та на умовах визначених в цьому Договорі, Товариство надає
                        Користувачу доступ до Сервісу та забезпечує надання послуг інформаційного та
                        інформаційно-рекламного характеру, у тому числі додаткових послуг та
                        сервісів, а Користувач зобов&apos;язується прийняти та оплатити (у випадках,
                        передбачених цим Договором) надані послуги.
                      </li>
                      <li>
                        Безумовним акцептом (крім власноручного проставлення «галочки» про
                        ознайомлення і прийняття її умов) цієї Оферти Користувачем (моментом повного
                        підтвердження і беззастережного прийняття всіх умов Оферти, її додатків,
                        правил, доповнень, які є його невід&apos;ємними частинами) визнається момент
                        будь-якої взаємодії Користувача з Платформою в тому числі, але не виключно:
                        реєстрація на Платформі (сайті), заповнення Облікового запису (профілю),
                        проходження тестів (для експертів/фрилансерів), оприлюднення завдання та/або
                        пропозиції, ознайомлення з профілями інших Користувачів, внесення та/або
                        поповнення будь-якого платежу, або будь-яке інше фактичне користування
                        сервісами Платформи.
                      </li>
                      <li>
                        Факт здійснення будь-якої взаємодії на Платформі (вчинення Користувачем
                        будь-яких дій, передбачених пунктом 3.2. цього Договору), вважається
                        безумовним прийняття Користувачем Замовником пропозиції про укладення
                        Договору (здійснення акцепту), а також підтверджує факт повного і
                        безумовного прийняття Замовником умов цього Договору без будь-яких
                        застережень чи зауважень відповідно до статті 642 Цивільного кодексу України
                        та означає, що Користувач ознайомлений, розуміє та повністю приймає ці
                        умови.
                      </li>
                      <li>
                        Товариство має право залучати третіх осіб (субпідрядників) для надання
                        Користувачам всіх та/або частини послуг з використання та/або доступу до
                        Платформи та її ресурсів.
                        <br />
                        Частина послуг (розсилка повідомлень, новин, аналітики, тощо), які надаються
                        Користувачам додатково здійснюється за завданням/замовленням Товариства і
                        Користувач, як правило, має можливість відмовитись від них, вчинивши
                        додаткові дії (відмова від розсилок, тощо).
                      </li>
                      <li>
                        У випадку залучення Товариством до надання послуг третіх осіб
                        (субпідрядників) та у випадку необхідності передачі певних персональних
                        даних, Товариство передає такі дані в мінімально необхідній частині
                        (наприклад, лише номер телефону та/або електронної пошти) і не інакше ніж
                        для надання послуг Товариству або Користувачам (мета передання).
                      </li>
                      <li>
                        Докладніше про правила захисту персональних даних користувачів визначено в
                        <a href="https://expertland.ua/policy">
                          Порядку обробки персональних даних
                        </a>
                        , ознайомлення з яким можливо за наступним посиланням:{' '}
                        <a href="https://expertland.ua/policy">https://expertland.ua/policy</a>.
                      </li>
                      <li>
                        З інформацією про послуги, які надаються на виконання цієї Оферти, їх
                        вартістю (у випадку, якщо послуги належать до категорії платних), а також
                        вимоги, які ставляться до розміщення завдань та пропозицій, відповідних
                        інформаційно-рекламних послуг, Користувач може ознайомитись на кожному
                        окремому сервісі Платформи.
                      </li>
                      <li>
                        Користувач самостійно здійснює вибір сервісів та послуг, доступ до яких
                        та/або послугами яких він має намір отримати або скористатися. Перед
                        початком користування платною послугою Користувач зобов&apos;язаний уважно
                        ознайомитися з умовами її надання. Якщо Замовник не згодний з умовами їх
                        надання, він не вправі користуватися послугами з метою уникнення негативних
                        наслідків (у т.ч. списання і не повернення сплачених за таку послугу
                        грошових коштів).
                      </li>
                    </ul>
                    <b>4. Реєстрація облікового запису та вибір обсягу послуг</b>
                    <p>
                      4.1. Для використання функціоналу Платформи, Користувачу необхідно
                      зареєструватися.
                    </p>
                    <p>
                      Для реєстрації Облікового запису (кабінету, акаунту) Користувача достатньо
                      мати діючий номер телефону (українського оператора мобільного зв’язку) до
                      якого буде здійснена «прив’язка» вказаного акаунту. Кожний Користувач може
                      зареєструвати два типи профілю — обліковий запис Замовника та обліковий запис
                      Виконавця (Експерта).
                    </p>
                    <p>
                      4.2. Користувач самостійно реєструється в сервісі на сайті у розділі
                      &ldquo;Реєстрація&ldquo;, встановлює власний логін та пароль, та після
                      введення коду-підтвердження для доступу автоматично вважається зареєстрованим
                      та отримує доступ до більшості ресурсів Платформи. Для того, щоб
                      зареєструватися, необхідно натиснути на відповідну кнопку на головній сторінці
                      сайту або пройти за посиланням:{' '}
                      <a href="https://expertland.ua/auth/sign-up/">
                        https://expertland.ua/auth/sign-up/
                      </a>
                    </p>
                    <p>
                      4.3. З метою отримання повного доступу до Платформи, у тому числі перегляду
                      Проєктів, надання пропозицій по їх виконанню, необхідне проходження тестових
                      завдань (лише для Користувачів зі статусом Експертів/Виконавців), заповнити
                      Профіль (в залежності від статусу Користувача - як Замовника або Експерта –
                      зазначити вид своєї діяльності та/або галузі у якій надаватимуться послуги),
                      здійснити повну авторизацію, для чого (або/або):
                    </p>
                    <p>(а) надати ім’я, прізвище, адресу електронної пошти, або</p>
                    <p>(b) авторизуватись через діючий акаунт Користувача на Facebook чи Google.</p>
                    <p>
                      Платформа (Товариство) може вимагати від Користувача надання іншої інформації
                      про що зазначено в{' '}
                      <a href="https://expertland.ua/policy">Порядку обробки персональних даних</a>{' '}
                      та/або документів (у тому числі з метою підтвердження повноважень діяти від
                      імені юридичної особи).
                    </p>
                    <p>
                      Усі Виконавці (Експерти) після заповнення анкети та проходження перевірки і
                      тестового відбору потрапляють в рейтинг Виконавців. Оплатити місце в
                      загальному рейтингу неможливо.
                    </p>
                    <p>
                      Оцінка рейтингу формується за якістю експертизи, реалізованими проектами (їх
                      кількістю) і відгуками Замовників.
                    </p>
                    <p>
                      4.4. Реєструючись на Платформі, ви підтверджуєте, що ви є дієздатною фізичною
                      особою, яка діє від власного імені або на законних підставах від імені
                      юридичної особи. Ви також підтверджуєте, що вся інформація, надана в ході
                      реєстрації, є достовірною, точною і актуальною. Крім того, ви підтверджуєте,
                      що реєструєтесь для цілей, що пов&apos;язані зі здійсненням підприємницької
                      діяльності, укладення та виконання договорів цивільно-правового характеру
                      (Платформа не створена для задоволення власних, особистих потреб фізичних
                      осіб, які регулюються законодавством про захист прав споживачів).
                    </p>
                    <p>
                      4.5. Користувач може мати тільки один обліковий запис. Це правило не
                      застосовується, якщо:
                    </p>
                    <p>
                      (а) Користувач використовує різні облікові записи в рамках своєї комерційної
                      діяльності у зв&apos;язку з різним статусом – експерт/виконавець або замовник,
                      або обслуговування облікових записів різними представниками, відділами,
                      філіями бізнесу (однієї й тієї ж юридичною особи), із певними застереженнями,
                      які можуть встановлюватись Товариством;
                    </p>
                    <p>
                      (b) необхідно створити інший обліковий запис через відсутність можливості
                      доступу до «попереднього» облікового запису (забутий пароль та відсутність
                      можливості його відновлення, тощо).
                    </p>
                    <p>
                      4.6. Товариство залишає за собою право перевіряти вищезазначені випадки та
                      дозволяти або не дозволяти (видаляти вже зареєстрований обліковий запис)
                      вказані дії, наприклад, в цілях боротьби з шахраями, особами, які мають намір
                      «обнулити» свій негативний рейтинг та іншими подібними випадками.
                    </p>
                    <p>
                      4.7. Товариство має беззастережне право зупинити доступ/використання
                      облікового запису та/або навіть видалити такий обліковий запис (Користувача) з
                      Платформи у випадках грубого порушення Користувачем даного Договору, зокрема,
                      але не виключно:
                    </p>
                    <p>
                      <u>
                        (a) вчинення дій, які порушують вимоги діючого законодавства, а також
                        прийнятний тон спілкування в інформаційному просторі, неповага/грубе
                        порушення прав та інтересів інших осіб;
                      </u>
                    </p>
                    <p>
                      <u>
                        (b) вчинення дій, які можуть перешкодити нормальному функціонуванню
                        Платформи, в тому числі втручання в зміст Платформи, свій обліковий запис,
                        облікові записи інших користувачів;
                      </u>
                    </p>
                    <p>
                      <u>
                        (c) при використанні (намаганні використання) функціональних можливостей
                        Платформи для передачі або публікації матеріалів, які порушують закон та
                        особисті права, контент терористичного змісту, що містить або поширює
                        порнографію, що порушує права на інтелектуальну власність інших осіб, а
                        також інформацію/матеріали дискримінаційного або расистського характеру;
                      </u>
                    </p>
                    <p>
                      <u>
                        (d) при використанні можливості Платформи для продажу (або здійснення інших
                        дій з аналогічним ефектом) товарів, речовин, які заборонені діючим
                        законодавством, обмежені до продажу і т.д. (у т.ч. лікарські засоби,
                        алкогольні напої, наркотичні, психотропні або інші подібні речовини);
                      </u>
                    </p>
                    <p>
                      <u>
                        (e) при введені в оману інших користувачів Платформи та контрагентів
                        Товариства, наприклад, шляхом надання неправдивої інформації про завдання
                        або пропозицію, ціну (демпінг, тощо), або приховування відповідної
                        інформації, яка впливає на результат надання послуг/виконання робіт
                        (наприклад, щодо безпекових заходів, порушення певних норм, заборон, тощо);
                      </u>
                    </p>
                    <p>
                      <u>
                        (f) містять підозрілу активність, спам, схеми фінансових
                        &ldquo;пірамід&ldquo;; містять посилання на Інтернет-ресурси, що належать
                        Користувачам або третім особам; містять контактні дані Користувача (номер
                        телефону, акаунти соціальних мереж або сервісів миттєвих повідомлень) та
                        надані виключно з метою «обходу» проведення розрахунків/винагороди поза
                        Платформою, тощо;
                      </u>
                    </p>
                    <p>
                      <u>
                        (g) порушення інших правил і норм, які встановлені даним Договором та/або
                        вимогами діючого законодавства.
                      </u>
                    </p>
                    <p>
                      4.8. З інформацією про послуги, які надаються Платформою на виконання цієї
                      Оферти, їх вартістю (у випадку, якщо послуги належать до категорії платних),
                      комісійною винагородою Платформи (у випадку, якщо така встановлена), а також
                      вимогами, які ставляться до розміщення завдань та пропозицій, відповідних
                      інформаційно-рекламних послуг, Користувач може ознайомитись на кожному
                      окремому сервісі Платформи.
                    </p>
                    <p>
                      4.9. Користувач самостійно здійснює вибір сервісів та послуг, доступ до яких
                      та/або послугами яких він має намір отримати або скористатися. Перед початком
                      користування платною послугою Користувач зобов&apos;язаний уважно ознайомитися
                      з умовами її надання. Якщо Замовник не згодний з умовами їх надання, він не
                      вправі користуватися послугами з метою уникнення негативних наслідків (у т.ч.
                      списання і не повернення сплачених за таку послугу грошових коштів).
                    </p>
                    <p>
                      У рамках Платформи передбачена система рейтингів і відгуків, яка дозволяє
                      залишити суб&apos;єктивну оцінку (на підставі свого власного
                      транзакційного/договірного досвіду роботи в іншою стороною):
                    </p>
                    <p>(a) замовникам – про експерта (на підставі співпраці з таким виконавцем);</p>
                    <p>(b) експертам – про замовників.</p>
                    <p>
                      Рейтинг складається в порядку, формі, термінах і категоріях, які зазначені на
                      Платформі.
                    </p>
                    <p>
                      4.11. Товариство може на свій розсуд встановити додаткову функціональність,
                      яка може надати Користувачу доступ до додаткових ресурсів Платформи. Умови
                      додаткових пропозицій розміщуються на сайті і становлять невід’ємну частину
                      цих умов.
                    </p>
                    <p>
                      4.12. Вартість платних послуг, комісійної винагороди, тощо за цим Договором
                      визначається відповідно до діючих тарифних планів, які опубліковані на сайті
                      <a href="https://www.expertland.ua/">https://www.expertland.ua/</a>. Ціни
                      вказуються в національній валюті України. Оплата послуг здійснюється
                      Користувачем шляхом безготівкового платежу на рахунок(-нки) зазначені
                      Товариством або за допомогою Платіжного агрегатора.
                    </p>
                    <p>
                      Користувач самостійно несе відповідальність за правильність здійснених ним
                      платежів.
                    </p>
                    <p>
                      Товариство залишає за собою право на свій розсуд і у будь-який час змінювати
                      умови та вартість платних послуг, тарифних планів, тощо. Зміни вартості послуг
                      не поширюються на періоди, які були оплачені Користувачем, а набувають
                      чинності після спливу оплаченого періоду користування сервісів.
                    </p>
                    <b>5. Початок роботи на Платформі</b>
                    <p>
                      5.1.{' '}
                      <u>
                        Для Замовників (у т.ч. власників бізнесу, представників юридичних осіб,
                        тощо)
                      </u>
                      . Увійдіть на Платформу як Замовник. Реєстрація безкоштовна і займає декілька
                      хвилин. Заповніть анкету. Опублікуйте Проєкт. Вам почнуть надходити релевантні
                      пропозиції від Експертів (Виконавців. Фрилансерів). Обирайте Виконавця і
                      укладайте угоди.
                    </p>
                    <p>
                      5.2. <u>Для Експертів (Виконавців, Фрилансерів)</u>. Зареєструйтеся на сайті
                      як Експерт. Реєстрація безкоштовна і займає декілька хвилин. Заповніть анкету.
                      Після перевірки даних вас буде додано до загального рейтингу Виконавців. Ви
                      отримаєте доступ до відкритих Проєктів та зможете брати Проєкти в роботу (до
                      30 відгуків на Проєкт у місяць).
                    </p>
                    <b>6. Процес створення Проєкту</b>
                    <p>6.1. Етапи створення Проєкту:</p>
                    <p>
                      <u>Крок 1:</u> Вибір режиму роботи
                      <br />
                      Вирішіть, чи будете ви пропонувати часткову чи повну зайнятість для Проєкту.
                      <br />
                      <u>Крок 2:</u> Введення даних та найменування Проєкту
                      <br />
                      Внесіть назву Проєкту у відповідне поле;
                      <br />
                      Оберіть одну чи кілька категорій Проєкту.
                      <br />
                      <u>Крок 3:</u> Опис Проєкту
                      <br />
                      Внесіть детальний опис Проєкту в текстове поле.
                      <br />
                      <u>Крок 4:</u> Вибір типу Проєкту
                      <br />
                      Оберіть чи буде Проєкт разовим чи довгостроковим.
                      <br />
                      <u>Крок 5:</u> Додавання скрінінгових питань (опціонально)
                      <br />
                      При необхідності додайте скрінінгові запитання. Оберіть питання з наданого
                      списку. Сформулюйте власні запитання, якщо потрібно та внесіть їх (вручну).
                      <br />
                      <u>Крок 6:</u> Запит супроводжувального листа (опціонально)
                      <br />
                      Відмітьте чекбокс якщо необхідно зробити запит на супроводжувальний лист.
                      <br />
                      <u>Крок 7:</u> Вибір мови
                      <br />
                      Оберіть потрібну мову з наданого списку.
                      <br />
                      <u>Крок 8:</u> Відправка запрошення Виконавцю
                      <br />
                      Надішліть запрошення обраному Виконавцю.
                      <br />
                      <u>Крок 9:</u> Зазначення бюджету
                      <br />
                      Зазначте бюджет для Проекту. У разі необхідності відмітьте чекбокс щоб не
                      додавати інформацію про оплату.
                      <br />
                      <u>Крок 10:</u> Зазначення тривалості Проєкту Відмітьте чекбокс, який
                      відповідає орієнтовній тривалості проекту:
                      <br />
                      <ul>
                        <li>більше 6 місяців;</li>
                        <li>від 3 до 6 місяців;</li>
                        <li>від 1 до 3 місяців;</li>
                        <li>менше 1 місяця (опціонально).</li>
                      </ul>
                      <u>Крок 11:</u> Попередній перегляд підготовленого тексту Проєкту
                    </p>
                    <p>6.2 . Прогляньте попередній перегляд проекту, який містить у собі:</p>
                    <ul>
                      <li>кнопку &ldquo;Опубліковати проєкт&ldquo;;</li>
                      <li>інформацію про назву та категорії Проєкту з можливістю редагування;</li>
                      <li>опис Проєкту з можливістю редагування;</li>
                      <li>
                        деталі Проєкту такі як: тип Проєкту, скрінінгові питання, супроводжувальний
                        лист з можливістю редагування;
                      </li>
                      <li>
                        досвід який містить: навички, знання мов та рівень експертності з можливістю
                        редагування (зміною вимог);
                      </li>
                      <li>видимість та запрошення з можливістю редагування (пропозицією змін);</li>
                      <li>бюджет з можливістю редагування (зміни);</li>
                      <li>
                        Виконавці, де ви можете додати необхідні дані та надіслати запрошення;
                      </li>
                      <li>
                        Кнопку &ldquo;Опублікувати проєкт&ldquo; для фінальної публікації проекту.
                      </li>
                    </ul>
                    <b>7. Процес оплати за виконання Проєкту</b>
                    <p>
                      7.1. Клієнти Платформи можуть користуватися банківськими картами VISA,
                      MasterCard та Maestro або іншими доступними банківськими картами для
                      здійснення оплати послуг Сервісу та Експертів. Банківські карти, емітовані
                      банками Російської Федерації та Республіки Білорусь не можуть бути використані
                      в зв’язку з існуючими обмеженнями.
                    </p>
                    <p>
                      7.2. Процес оплати для Виконавця (Експерта). Після отримання виконаного
                      Проєкту та погодження виконання Проєкту Замовником, Виконавець (Експерт)
                      вказує номер банківської карти або інші платіжні реквізити для отримання
                      оплати за виконання Проєкту. Крім того між Замовником та Виконавцем
                      (Експертом) може бути погоджений будь-який інший алгоритм здійснення оплати за
                      виконання Проєкту на їх власний розсуд. Відповідальність за оплату лежить
                      повністю на Замовнику (за умови надання Виконавцем правильних та актуальних
                      банківських реквізитів). Розмір комісії при проведенні оплати та отриманні
                      платежу залежить виключно від платіжних сервісів, якими користуються Замовник
                      та Виконавець (Експерт). Платформа не несе відповідальності за процес оплати
                      за виконаний Проєкт.
                    </p>
                    <p>
                      7.3. Процес оплати для Замовника. Перед початком роботи над Проєктом
                      визначається вартість (бюджет) Проєкту та погоджується між Сторонами. Після
                      отримання виконаного Проєкту та погодження виконання Проєкту Замовником,
                      Виконавець (Експерт) надає номер банківської карти або інші необхідні
                      реквізити і Замовник оплачує суму в розмірі погодженої між Сторонами вартості
                      (бюджету) Проєкту. Також він має повідомити на Платформі Виконавця (Експерта)
                      про факт здійснення оплати. Платформа не несе відповідальності за процес
                      оплати за виконаний Проєкт.
                    </p>
                    <p>
                      Якщо платіж з будь-якої причини буде невдалим, Замовник мусить зробити платіж
                      повторно. Відповідальність за оплату лежить повністю на Замовнику(за умови
                      надання Виконавцем правильних та актуальних банківських реквізитів). Платформа
                      не несе відповідальності за процес оплати за виконаний Проєкт.
                    </p>
                    <b>8.Комісія за використання Сервісу при оплаті за виконаний Проєкт</b>
                    <p>
                      8.1. Комісія за використання Сервісу складає 0% від вартості (бюджету)
                      Проєкту.
                    </p>
                    <p>
                      8.2. Розмір комісії при проведенні оплати та отриманні платежу за виконаний
                      Проєкт залежить виключно від платіжних сервісів, якими користуються Замовник
                      та Виконавець (Експерт).
                    </p>
                    <p>
                      8.3. Товариство залишає за собою право ввести комісію за використання Сервісу
                      у майбутньому, шляхом внесення змін до цих Умов користувача. Нові умови
                      починають діяти з дати оприлюднення їх на сайті Сервісу, якщо інший термін не
                      був додатково обумовлений Товариством. Товариство не може вводити комісії на
                      розпочаті (до дати оприлюднення нової редакції цих Умов) та незакінчені
                      короткострокові (до 1 місяця) Проєкти.
                    </p>
                    <b>9.Платні функції Сервісу (опис)</b>
                    <p>
                      <u>
                        9.1. Сервіс пропонує наступний перелік платних функції для Виконавця
                        (Експерта)
                      </u>
                    </p>
                    <p>9.1.1. Пакет «Pro ЕКСПЕРТ», який надає більше можливостей для просування:</p>
                    <ul>
                      <li>Позначка &ldquo;PRO&ldquo; на аватарі та у профілі</li>
                      <li>Підвищення рейтингу на 120%</li>
                      <li>Можливість 30 разів приховати свою ставку від інших Експертів.</li>
                    </ul>
                    <p>
                      Отримайте додаткові можливості для просування і підвищуйте свої позиції у
                      загальному рейтингу Експертів. Послуги пакету сплачуються щомісяця.
                    </p>
                    <p>
                      <u>9.1.2. Інші платні послуги:</u>
                    </p>
                    <div className="table-responsive">
                      <table className="table table-bordered" style={{ width: '700px' }}>
                        <thead>
                          <tr>
                            <th className="text-center">Послуга</th>
                            <th>Опис</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">20 додаткових відгуків</td>
                            <td>
                              <p>
                                Протягом місяця ви можете подати безкоштовно до 30 заявок на участь
                                у Проєктах (нарахування 1-ого числа кожного нового місяця).
                              </p>
                              <p>
                                Активація послуги дозволяє подати ще 20 заявок. Невикористані
                                відгуки згорають через місяць (30/31 календарний день) після
                                придбання.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Закріпити позицію в каталозі експертів</td>
                            <td>
                              <p>
                                Закріпіть свій Профіль у ТОПі каталогу Експертів за вашою
                                спеціалізацією терміном на 7 днів
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">
                              Підняти на одну позицію в каталозі експертів
                            </td>
                            <td>
                              <p>
                                Підніміть ваш профіль у каталозі Експертів вище на одну позицію
                                відносно поточної. Чим вище ваші позиції, тим більше Замовників
                                побачать ваш Профіль
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Додаткові спеціалізації 2-го рівня</td>
                            <td>
                              <p>
                                Додавайте +1 спеціалізацію другого рівня за кожну активацію цієї
                                послуги. Чим більше спеціалізацій, тим більше запрошень у Проєкти ви
                                можете отримати
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Додаткові категорії бізнесу</td>
                            <td>
                              <p>
                                Додавайте +1 категорію бізнесу у ваш профіль за кожну активацію цієї
                                послуги. Чим більше галузей, тим більше запрошень у проєкти ви
                                можете отримати
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Приховати ставку на Проєкт</td>
                            <td>
                              <p>
                                Приховайте свою ставку на проєкт від інших Експертів, щоб її бачив
                                тільки Замовник. Одна активація дає право на одне приховування
                                ставки
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>
                      <u>9.2. Сервіс пропонує наступний перелік платних функції для Замовника</u>
                    </p>
                    <div className="table-responsive">
                      <table className="table table-bordered" style={{ width: '700px' }}>
                        <thead>
                          <tr>
                            <th className="text-center">Послуга</th>
                            <th className="text-center">Опис</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-center">Закріпити Проєкт у стрічці</td>
                            <td>
                              <p>Закріпіть Проєкт у стрічці</p>
                              <p>
                                Ваш Проєкт буде закріплено над Проєктами без статусу на 48 годин.
                              </p>
                              <p>
                                У полі підказка: Закріпіть свій Проєкт у стрічці відразу під преміум
                                Проєктами на 48 годин.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Палаючий (гарячий) Проєкт</td>
                            <td>
                              <p>Палаючий Проєкт</p>
                              <p>Надайте Проєкту статус &ldquo;палаючого&ldquo; на 48 годин.</p>
                              <p>
                                Підказка: приверніть увагу Експертів до термінового Проєкту. Після
                                активації послуги у Проєкті на 48 годин зміниться колір поля і
                                з&aposявиться відповідна позначка.
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Преміум Проєкт</td>
                            <td>
                              Проєкт на ваш вибір отримує статус &ldquo;Преміум&ldquo; і
                              піднімається за каталогом вище Проєктів без статусу.
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Підняти Проєкт у стрічці</td>
                            <td>
                              Підніміть ваш Проєкт у каталозі вище на одну позицію відносно
                              поточної. Чим вище ваші позиції, тим більше Експертів вас побачать
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Приховати ставку на Проєкт</td>
                            <td>
                              Приховайте ставку на Проєкт, щоб Експерти оцінили свою роботу
                              незалежно від вашої пропозиції. Для цього попередньо оплатіть цю
                              послугу та активуйте в момент публікації Проєкту.
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Додаткові категорії бізнесу</td>
                            <td>
                              Додавайте +1 категорію бізнесу у ваш профіль за кожну активацію
                              послуги. Це дасть змогу створювати Проєкти та шукати Експертів у
                              більшому діапазоні галузей бізнесу.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        9.3. З розміром оплати по конкретній/зацікавленій послузі можна ознайомитися
                        у розділі Платні функції на Платформі
                      </p>
                      <p>
                        9.4. При проведенні оплати за платні функції Замовник та Виконавець
                        (Експерт) використовує платіжний агрегатор - фінансову компанію, яка
                        забезпечує платежі в рамках роботи Сервісу.
                      </p>
                      <p>
                        9.5. Розмір банківської комісії при проведенні оплати за платні функції
                        додатково залежить від платіжних сервісів, якими користуються Замовник та
                        Виконавець (Експерт).
                      </p>
                      <b>10. Розгляд претензій за якість надання чи ненадання платних функцій</b>
                      <p>
                        10.1. При виникненні претензій за якість надання чи ненадання платної
                        функції Замовник та Виконавець (Експерт) мають особисто звернутися та
                        написати лист на електронну адресу{' '}
                        <a href="mail:support@expertland.ua">support@expertland.ua</a> , який буде
                        розглянуто у строк до 7 календарних днів (який може буди продовжений до 30
                        календарних днів для перевірки обставин тощо)
                      </p>
                      <p>
                        10.2. Служба підтримки розглядає претензію та приймає рішення щодо подальших
                        кроків, а саме забезпечує Сервісом надання платної функції.
                      </p>
                      <p>
                        10.3. Повернення коштів за неякісне надання чи ненадання платної функції
                        можливе тільки у випадку, якщо Сервіс не має можливості надати якісну платну
                        функцію відповідно до даного договору та при повній ідентифікації особи, яка
                        замовила та оплатила платну функцію. При неможливості провести повну
                        ідентифікацію особи повернення оплати є неможливим. Строк повернення коштів
                        після прийняття рішення про повернення – до 30 календарних днів.
                      </p>
                      <b>11. Арбітраж виконання зобов’язань Виконавцем та Замовником (Експертом)</b>
                      <p>
                        11.1. Виконання зобов’язань Замовника та Виконавця щодо виконання Проєкту,
                        прийняття виконаного Проєкту та проведення оплат лежить виключно на
                        Замовнику та Виконавцеві.
                      </p>
                      <p>
                        11.2. Платформа не несе відповідальності за якість та виконання Проєктів,
                        погодження питань між Виконавцем та Замовником та забезпечення оплати за
                        виконаний Проєкт.
                      </p>
                      <p>
                        11.3. При виникненні претензій за якість виконання чи невиконання
                        зобов’язань сторонами (Виконавцем та Замовником) або недотримань правил
                        цього Договору Замовник та Виконавець (Експерт) можуть особисто звернутися
                        та написати лист на електронну адресу{' '}
                        <a href="mail:support@expertland.ua">support@expertland.ua</a> та попросити
                        про інформаційну підтримку та роз’яснення у вирішенні спірної ситуації, який
                        буде розглянуто у строк до 7 календарних днів (який може буди продовжений до
                        30 календарних днів для перевірки обставин тощо)
                      </p>
                      <p>
                        11.4. Для вирішення спірних питань між Виконавцем та Замовником Платформа
                        може створити Арбітраж – спеціальну комісію, яка розгляне спірну ситуацію
                        або невиконання домовленості однією з сторін.
                      </p>
                      <p>
                        11.5. Спеціальна комісія може розглянути питання та надати інформаційну
                        допомогу та роз’яснення, у випадках якщо:
                      </p>
                      <ul>
                        <li>
                          Виконавець не виконав роботу вчасно (значне перевищення строків, які були
                          погоджені між сторонами);
                        </li>
                        <li>Виконавець виконав роботу не у відповідності із завданням Проєкту;</li>
                        <li>Виконавець не виходить на зв’язок;</li>
                        <li>Замовник не приймає виконаний Експертом Проєкт;</li>
                        <li>
                          Замовник не проводить оплату за виконаний Проєкт або провів її у меншій
                          сумі (нижче ціни погодженого Проєкту).
                        </li>
                      </ul>
                      <b>12. Права та обов’язки Сторін</b>
                      <p>12.1. За цим Договором Користувач має право:</p>
                      <ul>
                        <li>
                          за умови дотримання вимог діючого законодавства та правил, які встановлені
                          Платформою, отримувати послуги, надані Товариством відповідно до умов
                          Договору;
                        </li>
                        <li>
                          отримувати повну та достовірну інформацію про обсяг наданих послуг, а
                          також роз’яснення з питань, що виникають у Користувача в зв’язку з
                          наданням йому послуг;
                        </li>
                        <li>
                          звертатися за допомогою, зокрема, в службу технічної підтримки Товариства,
                          повідомляючи при цьому свій Логін та/або контактні дані;
                        </li>
                        <li>
                          отримувати інформацію про роботу Платформи, у т.ч. підписатися на розсилку
                          смс-повідомлення, в тому числі через месенджери та додатки, та/або на
                          адресу електронної пошти від Платформи;
                        </li>
                      </ul>
                      <p>12.2. Користувач зобов‘язується:</p>
                      <ul>
                        <li>
                          до моменту акцепту цього Договору уважно ознайомитися з його змістом та
                          умовами Договору, а також положеннями{' '}
                          <a href="https://expertland.ua/policy">
                            Порядку обробки персональних даних
                          </a>
                          ;
                        </li>
                        <li>
                          відповідати за достовірність та відповідність законодавству та цьому
                          Договору щодо інформації, яка буде розміщуватись на Платформі;
                        </li>
                        <li>
                          виконувати Умови користування, інші правила, які встановлені на Платформі,
                          а також оплачувати надані йому сервіси в порядку і на умовах, передбачених
                          відповідними умовами, оприлюдненими на Платформі; у встановлених випадках
                          та на запит/вимогу Товариства підписати акти наданих послуг протягом 5
                          (п’яти) робочих днів з дня їх отримання від Товариства;
                        </li>
                        <li>
                          самостійно знайомитися з інформацією про умови надання йому сервісів
                          Платформи та їх вартості. Продовження користування Платформою після
                          внесення змін в умови надання послуг розглядається як згода Користувача з
                          внесеними змінами і доповненнями, якщо інше прямо не буде передбачено в
                          таких змінених документах. Всі ризики, пов&apos;язані з настанням
                          несприятливих наслідків, внаслідок недотримання Користувачем вимог цього
                          пункту, несе Користувач;
                        </li>
                        <li>
                          самостійно слідкувати (на сайті) за актуальною редакцією цього Договору,
                          <u>тарифами на надання послуг Платформи;</u>
                        </li>
                        <li>
                          використовувати Платформу та її сервіси тільки в законних цілях та при
                          використанні дотримуватися чинного законодавства України;
                        </li>
                        <li>
                          не вчиняти дій, які впливають на нормальну роботу Платформи, вважаються
                          несумісними з її використанням (зокрема, але не виключно дії, які
                          передбачені п. 3.7. цього Договору);
                        </li>
                        <li>
                          не здійснювати дій, спрямованих на отримання доступу до чужого особистого
                          профілю, шляхом підбору логіна і пароля, злому або інших дій; не
                          повідомляти іншим особам логін та пароль від свого облікового запису,
                          нести відповідальність за доступ інших осіб до свого облікового запису та
                          інформації, яка буде викладена від імені Користувача;
                        </li>
                        <li>
                          у передбачених випадках самостійно оформляти договірні відносини з іншими
                          Користувачами, які обрали його в якості особи, яка буде виконувати /
                          надавати певні роботи / послуги, в тому числі: витребувати документи, що
                          засвідчують особу Користувача при укладанні договірних відносин, оформляти
                          документи, що підтверджують, в тому числі, виконання / надання таких робіт
                          / послуг, тощо;
                        </li>
                        <li>
                          інші права, які передбачені діючим законодавством та умовами, які
                          встановлені Товариством при користуванні Платформою.
                        </li>
                      </ul>
                      <p>12.3. За цим Договором Товариство має право:</p>
                      <ul>
                        <li>надавати послуги в строк та на умовах, встановлених цією Офертою;</li>
                        <li>
                          надавати роз’яснення з питань, що виникають у Користувача в зв’язку з
                          наданням йому послуг;
                        </li>
                        <li>вчасно отримувати інформацію, необхідну для надання послуг;</li>
                        <li>
                          вимагати від Користувача надання додаткової інформації та/або документів,
                          у т.ч. реєстраційних документів, спеціальних дозволів, ліцензій, які
                          необхідні для надання послуг Платформою та/або перевірки статусу
                          Користувача;
                        </li>
                        <li>
                          змінювати умови цього Договору, інших правил, шляхом їх розміщення на
                          веб-сайті Товариства без спеціального (індивідуального) повідомлення
                          Замовника;
                        </li>
                        <li>
                          самостійно встановлювати ціну послуг та інші умови їх надання шляхом
                          розміщення відповідної інформації на веб-сайті Товариства, а також
                          змінювати їх в односторонньому порядку, розміщуючи їх на сайті
                          <a href="https://expertland.ua/">https://expertland.ua/</a>. Всі зміни
                          набувають чинності з моменту їх публікації (Товариство не має права
                          збільшувати вартість послуг за період, в який вони вже фактично оплачені
                          Користувачем);
                        </li>
                        <li>
                          відправляти інформаційні, в тому числі рекламні повідомлення, на
                          електронну пошту і мобільний телефон Користувача (крім випадків відмови
                          Користувача від вказаної функції);
                        </li>
                        <li>
                          призупинити надання послуг, зупинити доступ/використання облікового запису
                          та/або видалити такий обліковий запис (Користувача) з Платформи у
                          випадках, передбачених цим Договором;
                        </li>
                        <li>
                          інші права, які передбачені діючим законодавством та умовами, які
                          встановлені Товариством при користуванні Платформою.
                        </li>
                      </ul>
                      <p>12.4. Товариство зобов‘язується:</p>
                      <ul>
                        <li>
                          зберігати та обробляти персональні дані, отримані в процесі надання
                          послуг, відповідно до вимог чинного законодавства України та Порядку
                          обробки персональних даних;
                        </li>
                        <li>
                          надавати послуги відповідно до умов цього Договору особисто та/або з
                          залученням третіх осіб, залишаючись при цьому відповідальним в повному
                          обсязі перед Користувачем за надання послуг;
                        </li>
                        <li>
                          надавати роз’яснення з питань, що виникають у Користувача в зв’язку з
                          наданням йому послуг та/або користуванням Платформою;
                        </li>
                        <li>
                          забезпечити можливість доступу Користувача до Платформи, облікового
                          запису, інформаційно-рекламних матеріалів, онлайн заходів, тощо у порядку,
                          передбаченому Договором;
                        </li>
                        <li>
                          не розголошувати будь-яку приватну інформацію про Користувача і не
                          надавати доступ до цієї інформації третім особам, крім випадків,
                          передбачених діючим законодавством та/або{' '}
                          <a href="https://expertland.ua/policy">
                            Порядку обробки персональних даних
                          </a>
                          , ознайомлення з яким можливо за наступним посиланням:
                          <a href="https://expertland.ua/policy">https://expertland.ua/policy</a>;
                        </li>
                        <li>
                          виконувати інші зобов’язання, які передбачені діючим законодавством та/або
                          які пов’язані з нормальним функціонуванням Платформи.
                        </li>
                        <li>Сторони усвідомлюють і підтверджують, що:</li>
                        <li>
                          Товариство і Користувачі є абсолютно незалежними суб&apos;єктами, що не
                          впливають на діяльність один одного;
                        </li>
                        <li>
                          Товариство не відповідає за відповідність законодавству України та
                          очікуванням Користувача взаємовідносин/діяльності, що здійснюється між ним
                          та іншим Користувачем Платформи (замовником та/або експертом). Тобто,
                          Товариство (крім випадків, які встановлені за окремою домовленістю та
                          правилами) не є стороною електронної угоди між користувачем-замовником та
                          користувачем/виконавцем, предметом якої виступають роботи і / або послуги,
                          що пропонуються на Платформі. Всі угоди укладаються безпосередньо між
                          виконавцями і замовниками, Товариство не є учасником таких угод , а лише
                          надає комунікаційний майданчик для розміщення замовлень (завдань),
                          оголошень, пропозицій, та надає можливість зробити вибір замовником
                          виконавця для надання / виконання послуг / робіт в певній категорії.
                          Компанія не несе відповідальності за зміст інформації, що передається або
                          одержуваної інформації і за шкоду, заподіяну в результаті використання
                          Користувачами результатів сервісів Платформи; Товариство не несе
                          відповідальності за якість і терміни виконаних / наданих виконавцями робіт
                          / послуг для замовника.
                        </li>
                      </ul>
                      <b>13. Інформація, яка розміщується на Платформі</b>
                      <p>
                        13.1. На Платформі можуть бути розміщені дані та основна інформація про
                        Користувачів, замовлення (завдання), оголошення, пропозиції, аналітичні та
                        інші документи, інформаційно-рекламні матеріали, тощо. В силу об’єктивних
                        обставин, Товариство не несе відповідальності за достовірність матеріалів,
                        які розміщені не від імені Товариства (а Користувачами) та наслідки їхнього
                        використання.
                      </p>
                      <p>
                        Як правило, дані, отримані з ресурсів третіх осіб, які розміщує
                        безпосередньо Платформа є відкритими даними, отриманими з офіційних джерел,
                        пошукових систем Google, ресурсів інших третіх осіб, які мають право
                        збирати, обробляти та передавати дані згідно з законодавством України.
                        Сервіс збирає, аналізує та використовує дані з відкритих джерел, в тому
                        числі але не виключно з набору даних, які підлягають оприлюдненню.
                      </p>
                      <p>
                        13.2. Платформа може містити посилання на веб-сайти, веб-сторінки та
                        програми третіх сторін. Користувач самостійно несе відповідальність за
                        ознайомлення з умовами використання відповідних веб-сайтів і погоджуєтеся з
                        тим, що використовуєте такі веб-сайти на свій власний ризик та несете повну
                        відповідальність за відповідні дії.
                      </p>
                      <p>
                        13.3. Сукупність програм, даних, торгових марок, об&apos;єктів
                        інтелектуальної власності в тому числі об&apos;єктів авторського права, і
                        інших об&apos;єктів, що використовуються на Платформі, є інтелектуальною
                        власністю його законних правовласників і охороняється законодавством про
                        інтелектуальну власність України, а також відповідними міжнародними
                        правовими договорами і конвенціями. Будь-яке використання елементів,
                        символіки, текстів, графічних зображень, програм та інших об&apos;єктів
                        особами, які не мають на це належні права (власності, користування,
                        ліцензії, тощо) є незаконним і може бути підставою для правового
                        переслідування та притягнення порушників до цивільно-правової,
                        адміністративної та кримінальної відповідальності відповідно до
                        законодавства України.
                      </p>
                      <p>
                        Всі компоненти Платформи, включаючи торгові марки, зображення, дизайн,
                        тексти, які створені працівниками та/або підрядниками Товариства в цілому
                        належать Товариству й охороняються законодавством у сфері захисту прав
                        інтелектуальної власності. Використання в комерційних цілях будь-яких
                        матеріалів або елементів Платформи без письмового дозволу Товариства
                        заборонено.
                      </p>
                      <p>
                        13.4. Персональні дані Користувача обробляються відповідно до Закону України
                        «Про захист персональних даних» у порядку та з метою, яка встановлена{' '}
                        <a href="https://expertland.ua/policy">
                          Порядком обробки персональних даних
                        </a>
                        .
                      </p>
                      <p>
                        13.5. Користувачі зобов’язані забезпечити захист персональних даних, що
                        стали їм відомі з електронних документів (повідомлень) під час вчинення
                        електронних правочинів між ними (при укладені, виконані договірних відносин
                        між замовником та виконавцем при наданні/виконанні послуг робіт), у порядку,
                        передбаченому Законом України &ldquo;Про захист персональних даних&ldquo;.
                      </p>
                      <b>14. Відповідальність Сторін та порядок вирішення спорів</b>
                      <p>
                        14.1. Користувач гарантує, що вся інформація, яка зазначається в обліковому
                        записі, всі документи, які додаються Користувачем (у т.ч. на вимогу/запит
                        Товариства), інформаційно-рекламні матеріали, їх зміст, замовлення
                        (завдання), пропозиції, тощо, є повними, достовірними, не порушують прав
                        інтелектуальної власності третіх осіб, інших прав третіх осіб та
                        відповідають вимогам чинного законодавства України. Під відповідністю змісту
                        розуміється включаючи, але не обмежуючись, відповідність інформації та
                        матеріалів вимогам діючого законодавства України про рекламу, про мови, про
                        обмеження недобросовісної конкуренції, антимонопольне законодавство, про
                        рекламу, дотримання інших вимог законодавства України. Відповідальність
                        інформацію та її зміст і відповідність вимогам, вказаним в цьому пункті,
                        несе особа, яка її розміщує (замовник).
                      </p>
                      <p>
                        14.2. Товариство не несе відповідальність за повноту і достовірність
                        будь-якої інформації, в тому числі, які розміщені в облікових записах
                        Користувачів, або замовленнях (завданнях), оголошеннях, пропозиціях таких
                        користувачів як замовників та/або виконавців, тощо.
                      </p>
                      <p>
                        14.3. Товариство не несе відповідальність за будь-які помилки, упущення,
                        переривання, дефекти і затримки в обробці або передачі даних, збої в лініях
                        зв&apos;язку, знищення будь-якого обладнання, неправомірний доступ третіх
                        осіб до облікового запису, що стали причиною обмеження доступу Користувача
                        до Платформи.
                      </p>
                      <p>
                        14.4. При порушенні Користувачами умов цього Договору та / або норм чинного
                        законодавства України, Товариство залишає за собою право обмежити доступ
                        Користувача до Платформи, та/або відмовити в доступі до Платформи на
                        постійній основі (у т.ч. шляхом видалення облікового запису).
                      </p>
                      <p>
                        14.5. Товариство не несе відповідальності перед Користувачем за обмеження
                        доступу до Платформи, за припинення доступу до сервісів, якщо ці обмеження і
                        припинення виникли внаслідок обставин непереборної сили, які виникли після
                        укладення цього Договору та на настання яких Сторони не могли вплинути,
                        включаючи, але не обмежуючись, наступним: війна, заколоти, страйки, саботаж,
                        ембарго, пожежі, повені, стихійні лиха, погіршення радіоелектронної або
                        радіологічної обстановки, вибухи, дії та/або бездіяльність уряду України або
                        іншої країни, акти державних органів і / або органів місцевого
                        самоврядування, внесення змін до законодавства України, аварії на мережах
                        загального користування, зміни умов доступу до лінійно-кабельних спорудах
                        зв&apos;язку.
                      </p>
                      <p>
                        14.6. Сторони звільняються від відповідальності за невиконання або неналежне
                        виконання зобов&apos;язань за даним Договором, якщо це невиконання стало
                        наслідком обставин непереборної сили, що виникли після укладення цього
                        Договору в результаті дії таких надзвичайних та невідворотних обставин, які
                        передбачені в п. 6.5. вище.
                      </p>
                      <p>
                        Підтвердженням наявності форс - мажорних обставин, їх тривалості є довідки,
                        сертифікати та інші документи, видані відповідними державними органами,
                        установами, Торгово-промисловими палатами або іншими уповноваженими
                        організаціями. Загальновідомі офіційні факти, які опубліковані в засобах
                        масової інформації та які мають вплив на можливість виконання зобов’язань за
                        цим Договором, не підлягають підтвердженню в порядку, передбаченому цим
                        пунктом Договору. Так, Сторони підтверджують, що обставини, які
                        існують/діють на момент укладення цього Договору та які пов’язані з
                        військовою агресією російської федерації та введенням воєнного стану в
                        Україні Указом Президента України від 24.02.2022 № 64/2022 «Про ведення
                        воєнного стану в Україні» та Законом України від 24.02.2022 № 2102-ІХ «Про
                        затвердження Указу Президента України «Про ведення воєнного стану в Україні»
                        об’єктивно не заважають Сторонам укласти та виконати цей Договір, зважаючи
                        на місця (регіони) виконання зобов’язань.
                      </p>
                      <p>
                        14.7. Компанія не несе відповідальність перед Користувачем або будь-якими
                        третіми особами за будь-які прямі та / або непрямі збитки, включаючи упущену
                        вигоду або втрачені дані, шкоду честі, гідності або діловій репутації,
                        понесені у зв&apos;язку з використанням ним Платформи, або неможливості її
                        використання, або несанкціонованого доступу до комунікацій Користувача
                        третіми особами.
                      </p>
                      <p>
                        У разі пред&apos;явлення третіми особами, в тому числі іншим Користувачем,
                        претензій до Товариства, пов&apos;язаних з використанням Користувачем
                        сервісів Платформи, Користувач зобов&apos;язується своїми силами і за свій
                        рахунок врегулювати зазначені претензії з третіми особами, захистивши
                        Товариство від можливих збитків та спорів, або виступити на стороні
                        Товариства в таких спорах, а також відшкодувати збитки Товариства (включаючи
                        судові витрати), завдані йому у зв&apos;язку з претензіями і позовами,
                        пов&apos;язаними з розміщенням матеріалів та/або діяльністю Користувача на
                        Платформі.
                      </p>
                      <p>
                        14.8. Умови цієї Оферти та відносини між Сторонами регулюються
                        законодавством України.
                      </p>
                      <p>
                        14.9. Всі спори, які можуть виникнути в зв’язку з цим Договором, у тому
                        числі будь-які питання стосовно його виконання, укладення, дійсності чи
                        розірвання, Сторони будуть намагатися вирішити шляхом переговорів. Сторони
                        погодили, що для цілей вирішення спорів між Сторонами ефективним і
                        обов’язковим для використання засобом комунікації є листування за
                        електронною адресою та\або телефоном.
                      </p>
                      <p>
                        14.10. У разі, якщо Сторони не дійдуть згоди щодо спору протягом 30
                        (тридцяти) календарних днів, спір передається на розгляд до компетентного
                        суду відповідно до законодавства України.
                      </p>
                      <b>15. Інші умови</b>
                      <p>
                        15.1. Цей Договір і відносини між Товариством та Користувачем регулюються
                        законодавством України. Питання, не врегульовані Договором, підлягають
                        вирішенню відповідно до законодавства України.
                      </p>
                      <p>
                        15.2. Договір набуває чинності з моменту здійснення Покупцем акцепту і діє
                        до повного виконання зобов’язань Сторонами, за винятком випадків його
                        дострокового розірвання.
                      </p>
                      <p>
                        Сторони мають право розірвати цей Договір в односторонньому порядку, у
                        випадках, які передбачені цим Договором і чинним законодавством України.
                      </p>
                      <p>
                        15.3. Якщо з тих чи інших причин будь-які з умов цього Договору будуть
                        визнані недійсними, це не впливає на дійсність або застосовність інших умов
                        Договору. У цьому випадку Товариство (або Сторони, якщо умови стосуються
                        конкретного Користувача), наскільки це можливо, замінює недійсні умови
                        новими дійсними положеннями, що дозволяють максимально досягнути початкових
                        намірів Сторін.
                      </p>
                      <p>
                        15.4. Товариство має право в односторонньому порядку вносити зміни до
                        Договору шляхом оприлюднення змін до Договору на веб-сайті Товариства. Зміни
                        до Договору набирають чинності з дати їх оприлюднення, якщо інший термін
                        набрання чинності не визначений додатково при їх оприлюдненні.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default PolicyUser;
