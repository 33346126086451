import './style.scss';

import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import sprite from 'src/assets/img/sprite.svg';
import { FixLoader } from 'src/components';
import { useTranslation } from 'src/hooks';
import { expertProjectActions } from 'src/store/actions';
import * as Yup from 'yup';

const getNewData = (data) => new Date(data).toISOString().substring(0, 10);

const ResponseModalExpert = ({ handleClose, show, projectId, projectName }) => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const [isSub, setIsSub] = useState(false);
  const reviewInfo = useSelector((state) => state.expertProjectReducer.reviewInfo);
  const waiter = useSelector((state) => state.expertProjectReducer.reviewInfoWaiter);

  useEffect(() => {
    dispatch(expertProjectActions.reviewProjectInfo({ project: projectId }));
  }, []);

  const handleSubmit = async (values) => {
    await dispatch(expertProjectActions.reviewProjectExpert(values));

    setIsSub(true);
  };

  const schema = Yup.object().shape({
    text: Yup.string()
      .min(30, `${translate('Valid.min30')}`)
      .required(translate('Valid.req')),
  });

  const formik = useFormik({
    initialValues: {
      project: projectId,
      rating: 5,
      text: '',
    },
    validationSchema: schema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <>
      {waiter ? (
        <FixLoader />
      ) : (
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <div className="custom-modal-big">
              <button type="button" onClick={handleClose} className="custom-modal__close">
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#modalClose`} />
                </svg>
              </button>
              {isSub ? (
                <div className="choose__expert-modal">
                  <div className="choose__expert-modal__title">{translate('reviewModal.senk')}</div>
                  <div className="choose__expert-modal__info">
                    {translate('reviewModal.senkText')}{' '}
                    <Link to="/current-projects/" onClick={handleClose}>
                      {translate('reviewModal.link')}
                    </Link>
                  </div>
                  <div className="choose__expert-modal__bot">
                    <button
                      type="button"
                      className="btn btn-primary btn--new"
                      onClick={handleClose}
                    >
                      {translate('reviewModal.link')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn--new"
                      onClick={handleClose}
                    >
                      {translate('close')}
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {reviewInfo && (
                    <form className="current-response" onSubmit={formik.handleSubmit}>
                      <div className="current-response__title">{reviewInfo.name}</div>

                      <div className="current-response__data">
                        <div>{translate('reviewModal.title1')}</div>
                        <span>
                          {getNewData(reviewInfo.startDate)}-{getNewData(reviewInfo.endDate)}
                        </span>
                      </div>
                      <div className="current-response__rate">
                        <StarRatingComponent
                          name="rating"
                          starColor="#000"
                          renderStarIcon={() => (
                            <span>
                              <svg width="20px" height="20px" className="not-active">
                                <use xlinkHref={`${sprite}#star-notactive`} />
                              </svg>
                              <svg width="20px" height="20px" className="active">
                                <use xlinkHref={`${sprite}#star-active`} />
                              </svg>
                            </span>
                          )}
                          starCount={5}
                          value={formik.values.rating}
                          onStarClick={(e) => {
                            formik.setFieldValue('rating', e);
                          }}
                        />
                        {reviewInfo.price && (
                          <div className="current-response__rate__price">
                            {reviewInfo.price} {translate('reviewModal.sum')}
                          </div>
                        )}
                      </div>
                      <div className="current-response__text">
                        <div className="textarea">
                          <textarea
                            name="text"
                            placeholder={translate('reviewModal.pl')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`input  ${
                              formik.errors.text && formik.touched.text && 'error'
                            }`}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.text && formik.touched.text && formik.errors.text}
                          </div>
                        </div>

                        <div className="current-response__text__name">{projectName}</div>
                      </div>
                      <div className="current-response__bot">
                        <button type="submit" className="btn btn-primary btn--new">
                          {translate('confirm')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary btn--new"
                          onClick={handleClose}
                        >
                          {translate('close')}
                        </button>
                      </div>
                    </form>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
ResponseModalExpert.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
};
export default ResponseModalExpert;
