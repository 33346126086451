import { Header, NotActiveExpertModal } from 'src/components';

const ForNotActive = () => (
  <div className="app">
    <Header />
    <main className="main">
      <NotActiveExpertModal />
    </main>
  </div>
);

export default ForNotActive;
