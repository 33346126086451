import './style.scss';

import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';

const Step2 = ({ showStep2, handleCloseStep2, handleShow, setFormikCode, handleDelete }) => {
  const phone = useSelector((state) => state.authReducer.user.phone);
  const { deleteErr } = useSelector((state) => state.authReducer);
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const [send, setSend] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [mailSended, setMailSended] = useState(true);

  const handleHandleClose = () => {
    setSend(false);
    handleCloseStep2();
  };

  useEffect(() => {
    const pinCodeInputs = document.querySelectorAll('.pincode__input');
    let fullVal = '';
    pinCodeInputs.forEach((el, index) =>
      el.addEventListener('input', ({ data }) => {
        pinCodeInputs[index].value = data;
        if (data === null) {
          pinCodeInputs[index].value = '';
          if (index > 0) {
            pinCodeInputs[index - 1].focus();
          }
        } else if (data === data.replace(/[0-9]/, '')) {
          pinCodeInputs[index].value = '';
        } else if (pinCodeInputs[index + 1]) {
          pinCodeInputs[index + 1].focus();
        }

        fullVal = '';
        pinCodeInputs.forEach((el2) => {
          fullVal += el2.value;
        });
        setFormikCode(fullVal);

        return false;
      }),
    );
  }, [send]);

  useEffect(() => {
    const sendTimer = setTimeout(
      () =>
        setSeconds((prev) => {
          if (seconds > 0 && mailSended) {
            return prev - 1;
          }
          setMailSended(false);
          return 0;
        }),
      1000,
    );

    return () => {
      clearTimeout(sendTimer);
    };
  }, [seconds, mailSended]);

  const handleSendCode = async () => {
    const pinCodeInputs = document.querySelectorAll('.pincode__input');

    for (let i = 0; i < pinCodeInputs.length; i += 1) {
      pinCodeInputs[i].value = '';
    }

    setFormikCode('');
    await dispatch(authActions.accountDeleteCode());

    setSend(true);
    setMailSended(true);
    setSeconds(60);
  };

  return (
    <Modal show={showStep2} onHide={handleCloseStep2} className="spec-modal">
      <Modal.Body>
        <div className="exp-modal">
          <div className="modal-header">
            <h5 className="modal-title">{translate('DeleteModal.step2Title')}</h5>
            <button type="button" onClick={handleCloseStep2} className="spec-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalCloseWhite`} />
              </svg>
            </button>
          </div>
          {!send ? (
            <div className="spec-modal-body">
              <div className="spec-modal__sub">
                {translate('DeleteModal.step2Phone')} +{phone}
              </div>
              <div className="spec-modal__btns">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    handleSendCode();
                  }}
                >
                  {translate('DeleteModal.sendCode')}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    handleHandleClose();
                    handleShow();
                  }}
                >
                  {translate('cancel')}
                </button>
              </div>
              <div className="spec-modal__bottom spec-modal__bottom--delete">
                {translate('DeleteModal.help')}
                <br />
                <Link to="/help/">{translate('DeleteModal.helpLink')}</Link>{' '}
                {translate('DeleteModal.help2')}
              </div>
            </div>
          ) : (
            <form onSubmit={handleDelete}>
              <div className="spec-modal-body">
                <div className="delete-modal__pinT">{translate('DeleteModal.codeTitle')}</div>
                <div className="pincode pincode--delete">
                  <input type="number" className="pincode__input" maxLength="1" />
                  <input type="number" className="pincode__input" maxLength="1" />
                  <input type="number" className="pincode__input" maxLength="1" />
                  <input type="number" className="pincode__input" maxLength="1" />
                </div>

                {deleteErr && <div className="deleteErr">{deleteErr}</div>}

                {seconds === 0 ? (
                  <button
                    type="button"
                    className="delete-modal__timer"
                    onClick={() => handleSendCode()}
                  >
                    {translate('DeleteModal.resend')}
                  </button>
                ) : (
                  <button type="button" className="delete-modal__timer">
                    {translate('DeleteModal.resend')} ({seconds} {translate('DeleteModal.seconds')})
                  </button>
                )}
              </div>
              <div className="auth-bot-btns auth-bot-btns--delete">
                <button className="prev-btn" type="button" onClick={() => setSend(false)}>
                  {translate('back')}
                </button>
                <button type="submit" className="btn-reset next-btn">
                  {translate('confirm')}
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

Step2.propTypes = {
  showStep2: PropTypes.bool.isRequired,
  handleCloseStep2: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  setFormikCode: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default Step2;
