import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from 'src/socketContext';
import { alertsActions } from 'src/store/actions';

const AlertsSocket = () => {
  const socket = useContext(SocketContext);
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const dispatch = useDispatch();
  const role = useSelector((state) => state.authReducer.user.role);
  const expertId = useSelector((state) =>
    state.authReducer.user.expert ? state.authReducer.user.expert.id : 0,
  );
  const companyId = useSelector((state) =>
    state.authReducer.user.company ? state.authReducer.user.company.id : 0,
  );

  const handleActiveCount = () => {
    if (ifAuth && role) {
      dispatch(alertsActions.activeCount(role));
    }
  };

  useEffect(() => {
    handleActiveCount(role);
  }, [role]);

  useEffect(() => {
    if (ifAuth) {
      socket.on('connect', () => {});

      if (role === 1) {
        socket.emit('subscribe', {
          expertNotification: expertId,
        });

        socket.on(`expertNotification.${expertId}`, (data) => {
          if (data) {
            handleActiveCount();
          }
        });
      } else if (companyId) {
        socket.emit('subscribe', {
          companyNotification: companyId,
        });

        socket.on(`companyNotification.${companyId}`, (data) => {
          if (data) {
            handleActiveCount();
          }
        });
      }
    }
  }, [role, ifAuth, socket]);

  return false;
};
export default AlertsSocket;
