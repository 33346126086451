import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import oldLogo from 'src/assets/img/old-img/logo-mobile.svg';
import sprite from 'src/assets/img/old-img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

const capchaId = process.env.REACT_APP_RECAPHA_SITE_KEY;
const Login = () => {
  const dispatch = useDispatch();
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);
  const errors = useSelector((state) => state.authReducer.errors);
  const { translate } = useTranslation();
  const [passShow, setPassShow] = useState(false);
  const recaptchaRef = useRef();

  useEffect(() => () => dispatch(clearErrors()), []);

  const SignInSchema = Yup.object().shape({
    email: Yup.string().email(translate('Valid.email')).required(translate('Valid.req')),
    password: Yup.string()
      .required(translate('Valid.req'))
      .min(8, `${translate('Valid.pass')}`),
    recaptcha: Yup.string().required(translate('Valid.req')),
  });

  const handleSubmit = async (values) => {
    await dispatch(authActions.adminLogin(values));
    recaptchaRef.current?.reset();
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      recaptcha: '',
    },
    validationSchema: SignInSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <div className="auth">
      <div className="wrapper">
        <div className="content">
          <header className="auth__header">
            <div className="container container--auth">
              <Link to="/" className="auth__logo">
                <img src={oldLogo} alt="" />
                ExpertLand
              </Link>
            </div>
          </header>
          <div className="auth__wrap">
            <div className="container">
              <h1>Вхід у особистий кабінет адміністратора</h1>

              <form className="auth__form" id="signInForm" onSubmit={formik.handleSubmit}>
                <Link to="/" className="auth__form__close">
                  <svg width="20" height="20">
                    <use xlinkHref={`${sprite}#close`} />
                  </svg>
                </Link>
                <div className="auth__form__title">{translate('Auth.authForm')}</div>
                {errors && errors.phone && <strong className="text-danger">{errors.phone}</strong>}
                {errors && errors.google && (
                  <strong className="text-danger">{errors.google}</strong>
                )}
                <div className="input__row">
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="email"
                    className={formik.errors.email && formik.touched.email && 'is-invalid'}
                  />
                  <div className="invalid-feedback">
                    {formik.errors.phone && formik.touched.phone && formik.errors.phone}
                  </div>
                </div>
                <div className="input__row input__row--pass">
                  <input
                    type={passShow ? 'text' : 'password'}
                    placeholder={translate('Auth.password')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="password"
                    className={formik.errors.password && formik.touched.password && 'is-invalid'}
                  />
                  <div className="invalid-feedback">
                    {formik.errors.password && formik.touched.password && formik.errors.password}
                  </div>
                  <button
                    type="button"
                    onClick={() => setPassShow(!passShow)}
                    className={passShow ? 'active' : null}
                  >
                    <svg className="hide">
                      <use xlinkHref={`${sprite}#pass-show`} />
                    </svg>
                    <svg className="show">
                      <use xlinkHref={`${sprite}#pass-hide`} />
                    </svg>
                  </button>
                </div>
                <div className="recaptcha">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={capchaId}
                    onChange={(e) => {
                      if (e) {
                        formik.setFieldValue('recaptcha', e);
                      } else {
                        formik.setFieldValue('recaptcha', '');
                      }
                    }}
                  />
                </div>
                <br />

                <button
                  className="auth__submit"
                  type="submit"
                  disabled={fixWaiter || !formik.values.recaptcha}
                >
                  {translate('Auth.enter')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
