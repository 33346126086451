import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminService } from 'src/services';

export const getKnowledgeBase = createAsyncThunk(
  'admin/getKnowledgeBase',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getKnowledgeBase(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const getKnowledgeBaseDetail = createAsyncThunk(
  'admin/getKnowledgeBaseDetail',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getKnowledgeBaseDetail(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPayments = createAsyncThunk(
  'admin/getPayments',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getPayments(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createKnowledge = createAsyncThunk(
  'admin/createKnowledge',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.createKnowledge(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteKnowledge = createAsyncThunk(
  'admin/deleteKnowledge',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.deleteKnowledge(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateKnowledge = createAsyncThunk(
  'admin/updateKnowledge',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.updateKnowledge(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAllUsers = createAsyncThunk(
  'admin/getAllUsers',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getAllUsers(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAllKnowledgeBaseWithPagination = createAsyncThunk(
  'admin/getAllKnowledgeBaseWithPagination',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getKnowledgeBase(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getUser = createAsyncThunk('admin/getUser', async (data, { rejectWithValue }) => {
  try {
    return await adminService.getUser(data);
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getProjects = createAsyncThunk(
  'admin/getProjects',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getProjects(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProject = createAsyncThunk(
  'admin/getProject',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getProject(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateProjectStatus = createAsyncThunk(
  'admin/updateProjectStatus',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.updateProjectStatus(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const hideProject = createAsyncThunk(
  'admin/hideProject',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.hideProject(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateProject = createAsyncThunk(
  'admin/updateProject',
  async (data, { rejectWithValue }) => {
    try {
      const newData = {
        ...data,
        screeningQuestions: data.screeningQuestions.map((item) => ({
          ...item,
          id: typeof item.id === 'string' ? 0 : item.id,
        })),
      };
      return await adminService.updateProject(newData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectChats = createAsyncThunk(
  'admin/getProjectChats',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getProjectChats(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getChatMessages = createAsyncThunk(
  'admin/getChatMessages',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getChatMessages(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateReviewsText = createAsyncThunk(
  'admin/updateReviewsText',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.updateReviewsText(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertUpdateAvatar = createAsyncThunk(
  'admin/expertUpdateAvatar',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('image', data.file);
      return await adminService.expertUpdateAvatar(data.id, formData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const companyUpdateAvatar = createAsyncThunk(
  'admin/companyUpdateAvatar',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('image', data.file);
      return await adminService.companyUpdateAvatar(data.id, formData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertUpdateCover = createAsyncThunk(
  'admin/expertUpdateCover',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('image', data.file);
      return await adminService.expertUpdateCover(data.id, formData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const companyUpdateCover = createAsyncThunk(
  'admin/companyUpdateCover',
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('image', data.file);
      return await adminService.companyUpdateCover(data.id, formData);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertUpdateInfo = createAsyncThunk(
  'admin/expertUpdateInfo',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.expertUpdateInfo(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const companyUpdateInfo = createAsyncThunk(
  'admin/companyUpdateInfo',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.companyUpdateInfo(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertUpdateStatus = createAsyncThunk(
  'admin/expertUpdateStatus',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.expertUpdateStatus(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertUpdateEducation = createAsyncThunk(
  'admin/expertUpdateEducation',
  async (data, { rejectWithValue }) => {
    try {
      const newData = {
        education: data.education.map((item) => ({ ...item, id: 0 })),
        id: data.id,
      };

      const result = await adminService.expertUpdateEducation(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertUpdateRessetPass = createAsyncThunk(
  'admin/expertUpdateRessetPass',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.expertUpdateRessetPass(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deletePortFolio = createAsyncThunk(
  'admin/deletePortFolio',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.deletePortFolio(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editPortfolio = createAsyncThunk(
  'admin/editPortfolio',
  async ({ values, file, portfolioId }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      if (file) {
        formData.append('document', file);
      }

      formData.append('name', values.name);

      const result = await adminService.editPortfolio(formData, portfolioId);
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteResume = createAsyncThunk(
  'admin/deleteResume',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.deleteResume(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertFeatures = createAsyncThunk(
  'admin/expertFeatures',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.expertFeatures(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertPaidPackages = createAsyncThunk(
  'admin/expertPaidPackages',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.expertPaidPackages(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertPaidServices = createAsyncThunk(
  'admin/expertPaidServices',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.expertPaidServices(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertFeaturesActivate = createAsyncThunk(
  'admin/expertFeaturesActivate',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.expertFeaturesActivate(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const expertFeaturesDeactivate = createAsyncThunk(
  'admin/expertFeaturesDeactivate',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.expertFeaturesDeactivate(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const companyUpdateStatus = createAsyncThunk(
  'admin/companyUpdateStatus',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.companyUpdateStatus(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getProjectsLogs = createAsyncThunk(
  'admin/getProjectsLogs',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getProjectsLogs(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCompanyProjects = createAsyncThunk(
  'admin/getCompanyProjects',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getCompanyProjects(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPaidBusinessCategories = createAsyncThunk(
  'admin/getPaidBusinessCategories',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getPaidBusinessCategories(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getPaidProjectFeatures = createAsyncThunk(
  'admin/getPaidProjectFeatures',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getPaidProjectFeatures(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCompanyFeatures = createAsyncThunk(
  'admin/getCompanyFeatures',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getCompanyFeatures(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const сompanyFeaturesActivate = createAsyncThunk(
  'admin/сompanyFeaturesActivate',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.сompanyFeaturesActivate(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const сompanyFeaturesDeactivate = createAsyncThunk(
  'admin/сompanyFeaturesDeactivate',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.сompanyFeaturesDeactivate(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getUsersLogs = createAsyncThunk(
  'admin/getUsersLogs',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getUsersLogs(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getBazaLogs = createAsyncThunk(
  'admin/getBazaLogs',
  async (data, { rejectWithValue }) => {
    try {
      return await adminService.getBazaLogs(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const userBlock = createAsyncThunk('admin/userBlock', async (data, { rejectWithValue }) => {
  try {
    return await adminService.userBlock(data);
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
