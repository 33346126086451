import './style.scss';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FixLoader, Footer, Header, Sidebar, Status, Subheader } from 'src/components';
import { useTranslation } from 'src/hooks';

import NotRead from './not-read';
import Read from './read';

const Alerts = () => {
  const { translate } = useTranslation();
  const fixWaiter = useSelector((state) => state.alertsReducer.fixWaiter);
  const { role } = useSelector((state) => state.authReducer.user);
  const alerts = useSelector((state) => state.alertsReducer.alerts);
  const [tab, setTab] = useState(0);
  return (
    <>
      {fixWaiter && <FixLoader />}
      <div className="app">
        <Header />
        <main className="main">
          <Subheader />
          <div className="wrap">
            {role && role === 1 && <Status />}
            <div className="exp-content exp-content--company">
              <Sidebar />
              <div className="exp-content__edit">
                <div className="exp-content__top exp-content__top__mess">
                  {translate('Alerts.title')}
                </div>
                <div className="proekt__tabs tabs-nav" data-tabs="#proect-tabs">
                  <ul>
                    <li>
                      <button
                        type="button"
                        className={!tab ? 'active' : ''}
                        onClick={() => setTab(0)}
                      >
                        {translate('Alerts.newAlerts')} ({alerts.length})
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={tab ? 'active' : ''}
                        onClick={() => setTab(1)}
                      >
                        {translate('Alerts.readed')}
                      </button>
                    </li>
                  </ul>
                </div>
                {tab ? <Read /> : <NotRead />}
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Alerts;
