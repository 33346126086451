import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { expertProjectActions } from 'src/store/actions';

const ReviewProjectModal = ({ handleClose, show, projectId }) => {
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);

  const handleReview = async () => {
    await dispatch(expertProjectActions.reviewProject({ project: projectId }));
    setSubmit(true);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" onClick={handleClose} className="custom-modal__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#modalClose`} />
            </svg>
          </button>
          {submit ? (
            <div className="choose__expert-modal">
              <div className="choose__expert-modal__title">
                {translate('CurrentProjects.review')}
              </div>
              <div className="choose__expert-modal__title2">
                {translate('CurrentProjects.reviewText')}
              </div>
              <div className="choose__expert-modal__info">
                {translate('CurrentProjects.reviewText2')}{' '}
                <Link to="/current-projects/" onClick={handleClose}>
                  {translate('CurrentProjects.reviewBtn')}
                </Link>
              </div>
              <div className="choose__expert-modal__bot">
                <button type="button" className="btn btn-primary btn--new" onClick={handleClose}>
                  {translate('CurrentProjects.reviewBtn2')}
                </button>
              </div>
            </div>
          ) : (
            <div className="choose__expert-modal">
              <div className="choose__expert-modal__title">
                {translate('CurrentProjects.sendProject')}
              </div>

              <div className="choose__expert-modal__info">
                {translate('CurrentProjects.reviewText2')}{' '}
                <Link to="/current-projects/" onClick={handleClose}>
                  {translate('CurrentProjects.reviewBtn')}
                </Link>
              </div>
              <div className="choose__expert-modal__bot">
                <button type="button" className="btn btn-primary btn--new" onClick={handleReview}>
                  {translate('send')}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary btn--new"
                  onClick={handleClose}
                >
                  {translate('cancel')}
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
ReviewProjectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
};
export default ReviewProjectModal;
