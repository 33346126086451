import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { FixLoader } from 'src/components';
import { adminActions } from 'src/store/actions';

import { Filter, ProjectsTable, Search } from './components';

const UsersLogs = () => {
  const dispatch = useDispatch();

  const usersLogsMeta = useSelector((state) => state.adminReducer.usersLogsMeta);
  const fixWaiter = useSelector((state) => state.adminReducer.fixWaiter);
  const [date, setDate] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(adminActions.getUsersLogs({ date, page, search: name }));
  }, [name, page, date]);

  return (
    <div className="admin-wrap">
      <div className="wrap">
        {fixWaiter && <FixLoader />}
        <div className="admin_knowledge_header">Журнал змін по користувачам</div>
        <Search handleSearch={setName} SearchName={name} />
        <Filter setDate={setDate} setPage={setPage} />
        <ProjectsTable />
        {fixWaiter ? (
          ''
        ) : (
          <>
            {usersLogsMeta.total > 0 && (
              <div className="proekt__pagination">
                <ReactPaginate
                  breakLabel="....."
                  onPageChange={(nextPage) => setPage(nextPage.selected + 1)}
                  pageCount={usersLogsMeta.last_page}
                  forcePage={usersLogsMeta.current_page - 1}
                  activeClassName="active"
                  pageRangeDisplayed="2"
                  marginPagesDisplayed="1"
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UsersLogs;
