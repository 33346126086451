import { useFormik } from 'formik';
import { gapi } from 'gapi-script';
import { useEffect, useRef, useState } from 'react';
import GoogleLogin from 'react-google-login';
import ReCAPTCHA from 'react-google-recaptcha';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/old-img/sprite.svg';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { authActions } from 'src/store/actions';
import { clearErrors, memberFio } from 'src/store/reducers/auth.reducer';
import * as Yup from 'yup';

const capchaId = process.env.REACT_APP_RECAPHA_SITE_KEY;
const googleID = process.env.REACT_APP_GOOGLE_SITE_KEY;

const SignUp = () => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const dispatch = useDispatch();

  const recaptchaRef = useRef();
  const query = useQuery();
  const ref = query.get('ref');

  const errors = useSelector((state) => state.authReducer.errors);

  const [passShow, setPassShow] = useState(false);

  useEffect(() => () => dispatch(clearErrors()), []);

  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: googleID });
    });
  });

  const handleSubmit = async (values) => {
    await dispatch(authActions.signUp(values)).then((res) => {
      if (res.payload.status === 200) {
        navigate(`/auth/verify-phone/?phone=${values.phone}${ref ? `&ref=${ref}` : ''}`);
      }
    });

    recaptchaRef.current?.reset();
  };

  const SignUpSchema = Yup.object().shape({
    phone: Yup.string().test('len', translate('Valid.min12'), (phone) => {
      if (phone) {
        const phoneNumb = phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '');
        return phoneNumb.length >= 12;
      }
      return false;
    }),
    password: Yup.string()
      .required(translate('Valid.req'))
      .min(8, translate('Valid.pass'))
      .minLowercase(1, translate('Valid.minLow'))
      .minUppercase(1, translate('Valid.minUp'))
      .minNumbers(1, translate('Valid.minCifr')),
    gRecaptchaResponse: Yup.string().required(translate('Valid.req')),
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
      gRecaptchaResponse: '',
      check: false,
    },
    validationSchema: SignUpSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const signUpGoogle = (response) => {
    if (response.profileObj) {
      const data = {
        firstName: response.profileObj.givenName,
        lastName: response.profileObj.familyName,
      };
      dispatch(memberFio(data));
    }

    if (response.accessToken) {
      dispatch(authActions.signUpGoogle({ accessToken: response.accessToken })).then((res) => {
        if (res.payload.status === 200) {
          navigate(`/auth/add-phone/${ref ? `?ref=${ref}` : ''}`);
        }
      });
    }
  };

  return (
    <div className="auth__wrap">
      <div className="container">
        <h1>{translate('Auth.regTitle')}</h1>
        <div className="auth__desc">{translate('Auth.regSubTitle')}</div>
        <form className="auth__form" id="signUpForm" onSubmit={formik.handleSubmit}>
          <Link to="/" className="auth__form__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#close`} />
            </svg>
          </Link>
          <div className="auth__form__title">{translate('Auth.regFormTitle')}</div>
          {errors && errors.google && <strong className="text-danger">{errors.google}</strong>}
          {errors && errors.phone && <strong className="text-danger">{errors?.phone}</strong>}

          <div className="input__row">
            <InputMask
              mask="+380-99-999-99-99"
              placeholder="+380"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={
                (formik.errors.phone && formik.touched.phone && 'is-invalid') ||
                (errors && errors.phone)
                  ? 'error'
                  : ''
              }
            />
            <div className="invalid-feedback">
              {formik.errors.phone && formik.touched.phone && formik.errors.phone}
            </div>
          </div>
          <div className="input__row input__row--pass">
            <input
              type={passShow ? 'text' : 'password'}
              placeholder={translate('Auth.password')}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={formik.errors.password && formik.touched.password && 'is-invalid'}
              autoComplete="new-password"
            />
            <div className="invalid-feedback">
              {formik.errors.password && formik.touched.password && formik.errors.password}
            </div>
            <button
              type="button"
              onClick={() => setPassShow(!passShow)}
              className={passShow ? 'active' : null}
            >
              <svg className="hide">
                <use xlinkHref={`${sprite}#pass-show`} />
              </svg>
              <svg className="show">
                <use xlinkHref={`${sprite}#pass-hide`} />
              </svg>
            </button>
          </div>

          <div className="checkbox">
            <label htmlFor="checkbox1">
              <input
                type="checkbox"
                id="checkbox1"
                name="check"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span>
                <svg className="icon" width="9px" height="8px">
                  <use xlinkHref={`${sprite}#check`} />
                </svg>
              </span>
            </label>
            <div>
              {translate('Auth.policy1')}
              <a href="/policy" target="_blank">
                {translate('Auth.policy3')}
              </a>{' '}
              {translate('Auth.and')}{' '}
              <a href="/policy-user" target="_blank">
                {translate('Auth.policy4')}
              </a>
            </div>
          </div>
          <div className="recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={capchaId}
              onChange={(e) => {
                if (e) {
                  formik.setFieldValue('gRecaptchaResponse', e);
                }
              }}
            />
          </div>

          <br />
          <button className="auth__submit" type="submit" disabled={!formik.values.check}>
            {translate('Auth.regBtn')}
          </button>
          <div className="auth__form__text auth__form__text--right">
            {translate('Auth.haveAkk')}
            <Link to="/auth/sign-in/">{translate('Auth.enter')}</Link>
          </div>
          <div className="auth__reg-width">
            <GoogleLogin
              clientId={googleID}
              buttonText="Login"
              render={(renderProps) => (
                <button
                  type="button"
                  className="auth__reg-width__google"
                  onClick={renderProps.onClick}
                  disabled={!formik.values.check}
                >
                  <div className="img">
                    <svg
                      id="google"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <use xlinkHref={`${sprite}#google`} />
                    </svg>
                  </div>
                  {translate('Auth.google')}
                </button>
              )}
              onSuccess={signUpGoogle}
              onFailure={signUpGoogle}
              cookiePolicy="single_host_origin"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
