import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'src/hooks';

const Status = () => {
  const { translate } = useTranslation();
  const { text, code } = useSelector((state) => state.authReducer.user.expert.profile_status);

  return (
    <div className="status">
      <div className="status__left">
        {translate('Status.title')}
        <div className="status__type tooltips-wrap">
          <div className="status__type__code">{code}</div>
          {text}
          {code !== 'AC' && (
            <div className="tooltips">
              {translate('Status.tool')} <Link to="/otbor/">{translate('Status.do')}</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Status;
