import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { FixLoader } from 'src/components';
import { adminActions } from 'src/store/actions';

import Filter from './Filter';
import ProjectsTable from './ProjectsTable';
import Search from './Search';

const IndexPage = () => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.adminReducer.projects);
  const meta = useSelector((state) => state.adminReducer.projectsMeta);
  const fixWaiter = useSelector((state) => state.adminReducer.fixWaiter);

  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(adminActions.getProjects({ status, page, search: name }));
  }, [name, page, status]);

  return (
    <>
      {fixWaiter && <FixLoader />}
      <div className="admin_knowledge_header">Проєкти</div>
      <Search handleSearch={setName} SearchName={name} />
      <Filter setStatus={setStatus} />
      <ProjectsTable projects={projects} />
      {fixWaiter ? (
        ''
      ) : (
        <>
          {meta.total > 0 && (
            <div className="proekt__pagination">
              <ReactPaginate
                breakLabel="....."
                onPageChange={(nextPage) => setPage(nextPage.selected + 1)}
                pageCount={meta.last_page}
                forcePage={meta.current_page - 1}
                activeClassName="active"
                pageRangeDisplayed="2"
                marginPagesDisplayed="1"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default IndexPage;
