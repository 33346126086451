import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { setUserInitial } from 'src/store/reducers/auth.reducer';

const Success = ({ showSuc }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { translate } = useTranslation();
  const handleCloseSuc = () => {
    dispatch(setUserInitial());
    navigate('/');
  };
  return (
    <Modal show={showSuc} onHide={handleCloseSuc}>
      <Modal.Body>
        <div className="exp-modal delete-success">
          <button type="button" onClick={handleCloseSuc} className="delete-success__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#modalClose`} />
            </svg>
          </button>
          <div className="delete-succeess__title">{translate('DeleteModal.successTitle')}</div>
          <div className="spec-modal__bottom">
            {translate('DeleteModal.help')}{' '}
            <Link to="/help/">{translate('DeleteModal.helpLink')}</Link>
          </div>
          <button type="button" className="btn btn-primary" onClick={handleCloseSuc}>
            {translate('close')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

Success.propTypes = {
  showSuc: PropTypes.bool.isRequired,
};

export default Success;
