import { createContext } from 'react';
import io from 'socket.io-client';

const chatApi = process.env.REACT_APP_API_CHAT;
export const socket = io(chatApi, {
  extraHeaders: {
    Authorization: `Bearer ${localStorage.getItem('userToken')}`,
  },
});

export const SocketContext = createContext(socket);
