import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import avatarDefault from 'src/assets/img/avatar-default.png';
import { useTranslation } from 'src/hooks';

const CustomOption = ({ data, ...props }) => (
  <components.Option {...props}>
    <div className="prpbSelectOption">
      <div className="prpbSelectImg">
        <img src={data.img || avatarDefault} alt={data.label} />
      </div>
      <div className="prpbSelectRight">
        <div className="prpbSelectName">{data.label}</div>
      </div>
    </div>
  </components.Option>
);
const Step3SubStep4 = ({ setValue }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const currentProject = useSelector((state) => state.projectReducer.currentProject);
  const expertsList = useSelector((state) => state.projectReducer.expertList);

  const options = expertsList.map((item) => ({
    value: String(item.id),
    label: item.profileName,
    img: item.avatar,
  }));

  const handleSubmit = async ({ invitations }) => {
    setValue('invitations', invitations);
    navigate('/publication/step3/sub-step5/');
  };

  const formik = useFormik({
    initialValues: {
      invitations: currentProject.invitations || [],
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const setSelectVal = (arr) => {
    formik.setFieldValue(
      'invitations',
      arr.map((item) => item.value),
    );
  };

  return (
    <form className="prpb__wrap" onSubmit={formik.handleSubmit}>
      <div className="prpb__name">{translate('Publication.detail')}</div>
      <div className="prpb__body">
        <div className="prpb__label">{translate('Publication.subStep4')}</div>
        <div className="prpb__detail__text">{translate('Publication.subStep4Text')}</div>
        <div className="performer">
          <div className="prpb__label">{translate('Publication.choosePerformer')}</div>
          <div className="multi-selectize-performer">
            <Select
              defaultValue={options.filter((item) =>
                formik.values.invitations.includes(item.value),
              )}
              options={options}
              classNamePrefix="react-select"
              className="react-select"
              isMulti
              placeholder={translate('Publication.performerPl')}
              components={{ Option: CustomOption }}
              onChange={(performerArr) => setSelectVal(performerArr)}
            />
          </div>
        </div>
      </div>
      <div className="prpb__footer">
        <button className="btn btn-primary" type="submit">
          {translate('next')}
        </button>
        <Link className="btn btn-outline-primary" to="/publication/step3/sub-step3/">
          {translate('cancel')}
        </Link>
      </div>
    </form>
  );
};

Step3SubStep4.propTypes = {
  setValue: PropTypes.func.isRequired,
};

CustomOption.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Step3SubStep4;
