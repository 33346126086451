import { useSelector } from 'react-redux';

import CompanyComplited from './company-complited';
import ExpertComplited from './expert-complited';

const PaidComplited = () => {
  const role = useSelector((state) => state.authReducer.user.role);

  return <>{role === 1 ? <ExpertComplited /> : <CompanyComplited />}</>;
};

export default PaidComplited;
