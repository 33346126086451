import { configureStore } from '@reduxjs/toolkit';

import {
  adminReducer,
  alertsReducer,
  authReducer,
  chatReducer,
  customReducer,
  expertProjectReducer,
  otborReducer,
  paymentReducer,
  projectReducer,
} from './reducers';

export const store = configureStore({
  reducer: {
    authReducer,
    customReducer,
    projectReducer,
    paymentReducer,
    expertProjectReducer,
    chatReducer,
    otborReducer,
    alertsReducer,
    adminReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
});
