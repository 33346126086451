import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const Search = () => {
  const { translate } = useTranslation();
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const handleSub = (e) => {
    e.preventDefault();
    navigate(`/freelancers?name=${name}&`);
  };
  return (
    <form className="s1__form" onSubmit={handleSub}>
      <input
        type="text"
        placeholder={translate('Header.search')}
        onChange={(e) => setName(e.target.value)}
      />
      <button type="submit">
        <svg width="25" height="25" viewBox="0 0 25 25">
          <use xlinkHref={`${sprite}#mainSearch`} />
        </svg>
      </button>
    </form>
  );
};

export default Search;
