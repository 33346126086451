import { createSlice } from '@reduxjs/toolkit';

import { authActions } from '../actions';

const userInitial = {
  id: null,
  email: null,
  phone: null,
  role: null,
  email_verified_at: null,
  phone_verified_at: null,
};

// Видаляєм токен авторизації через місяць годину
if (+new Date() >= parseInt(localStorage.getItem('blockTime'), 10)) {
  localStorage.clear();
}

// видалення не тієї мови
if (localStorage.getItem('language') === 'en') {
  localStorage.clear();
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: userInitial,
    covers: [],
    countries: [],
    sities: [],
    langs: [],
    langsLevels: [],
    companyCategories: [],
    expertCategories: [],
    access_token: localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null,
    errors: false,
    deleteErr: false,
    fixWaiter: false,
    waiter: false,
    mainWaiter: false,
    memberFio: [],
    firstSignUp: false,
    firstVisit: true,
  },
  reducers: {
    clearErrors(state) {
      state.errors = false;
      state.deleteErr = false;
    },
    memberFio(state, action) {
      state.memberFio = action.payload;
    },
    setUserInitial(state) {
      state.user = userInitial;
    },
    availableProjectRequestsMinus(state) {
      const newVal = state.user.expert.availableProjectRequests - 1;
      state.user.expert.availableProjectRequests = newVal;
    },
    setFirstVisit(state) {
      state.firstVisit = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // signIn
      .addCase(authActions.signIn.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.signIn.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.errors = false;
        state.access_token = action.payload.data.token;
        state.user = action.payload.data.user;
      })
      .addCase(authActions.signIn.rejected, (state, action) => {
        state.fixWaiter = false;

        if (action.payload.errors && action.payload.errors.message) {
          state.errors = { phone: action.payload.errors.message };
        }
      })

      // adminLogin
      .addCase(authActions.adminLogin.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.adminLogin.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.errors = false;
        state.access_token = action.payload.data.token;
        state.user = action.payload.data.user;
      })
      .addCase(authActions.adminLogin.rejected, (state, action) => {
        state.fixWaiter = false;

        if (action.payload.errors && action.payload.errors.message) {
          state.errors = { phone: action.payload.errors.message };
        }
      })

      // signInGoogle
      .addCase(authActions.signInGoogle.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.signInGoogle.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.errors = false;
        state.access_token = action.payload.data.token;
        state.user = action.payload.data.user;
      })
      .addCase(authActions.signInGoogle.rejected, (state, action) => {
        state.fixWaiter = false;

        if (action.payload.errors && action.payload.errors.message) {
          state.errors = { google: action.payload.errors.message };
        }
      })

      // signUp
      .addCase(authActions.signUp.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.signUp.fulfilled, (state, action) => {
        state.user = action.payload.data.user;
        state.access_token = action.payload.data.access_token;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.signUp.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // signUpGoogle
      .addCase(authActions.signUpGoogle.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.signUpGoogle.fulfilled, (state, action) => {
        state.user = action.payload.data.user;
        state.access_token = action.payload.data.access_token;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.signUpGoogle.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = { google: action.payload.errors.message };
      })

      // confirmPhone
      .addCase(authActions.confirmPhone.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.confirmPhone.fulfilled, (state) => {
        state.user.phone_verified_at = true;
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.confirmPhone.rejected, (state, action) => {
        state.fixWaiter = false;
        if (action.payload.errors) {
          state.errors = action.payload.errors;
        }
      })

      // forgotPass
      .addCase(authActions.forgotPass.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.forgotPass.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.forgotPass.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // resendPasswordCode
      .addCase(authActions.resendPasswordCode.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.resendPasswordCode.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.resendPasswordCode.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // confirmPasswordCode
      .addCase(authActions.confirmPasswordCode.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.confirmPasswordCode.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.confirmPasswordCode.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // resetPass
      .addCase(authActions.resetPass.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.resetPass.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.resetPass.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // getCompanyCategories
      .addCase(authActions.getCompanyCategories.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(authActions.getCompanyCategories.fulfilled, (state, action) => {
        state.waiter = false;
        state.errors = false;
        state.companyCategories = action.payload.data;
      })
      .addCase(authActions.getCompanyCategories.rejected, (state) => {
        state.waiter = false;
        state.errors = false;
      })

      // getExpertCategories
      .addCase(authActions.getExpertCategories.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(authActions.getExpertCategories.fulfilled, (state, action) => {
        state.waiter = false;
        state.errors = false;
        state.expertCategories = action.payload.data;
      })
      .addCase(authActions.getExpertCategories.rejected, (state) => {
        state.waiter = false;
        state.errors = false;
      })

      // getCountries
      .addCase(authActions.getCountries.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(authActions.getCountries.fulfilled, (state, action) => {
        state.waiter = false;
        state.errors = false;
        state.countries = action.payload.data;
      })
      .addCase(authActions.getCountries.rejected, (state) => {
        state.waiter = false;
        state.errors = false;
      })

      // getSities
      .addCase(authActions.getCities.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(authActions.getCities.fulfilled, (state, action) => {
        state.waiter = false;
        state.errors = false;
        state.sities = action.payload.data;
      })
      .addCase(authActions.getCities.rejected, (state) => {
        state.waiter = false;
        state.errors = false;
      })

      // regComp
      .addCase(authActions.regComp.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.regComp.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.errors = false;
        state.access_token = action.payload.data.access_token;
        state.user = action.payload.data.user;
        state.firstSignUp = true;
      })
      .addCase(authActions.regComp.rejected, (state, action) => {
        state.fixWaiter = false;
        if (action.payload.errors) {
          state.errors = action.payload.errors;
        }
      })

      // regExp
      .addCase(authActions.regExp.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.regExp.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.errors = false;
        state.access_token = action.payload.data.access_token;
        state.user = action.payload.data.user;
        state.firstSignUp = true;
      })
      .addCase(authActions.regExp.rejected, (state, action) => {
        state.fixWaiter = false;
        if (action.payload.errors) {
          state.errors = action.payload.errors;
        }
      })

      // getProfile
      .addCase(authActions.getProfile.pending, (state) => {
        state.mainWaiter = true;
        state.user = userInitial;
      })
      .addCase(authActions.getProfile.fulfilled, (state, action) => {
        state.mainWaiter = false;
        state.user = action.payload.data;
      })
      .addCase(authActions.getProfile.rejected, (state) => {
        state.mainWaiter = false;
        state.user = userInitial;
      })

      // getProfileRefresh
      .addCase(authActions.getProfileRefresh.pending, (state) => {
        state.waiter = true;
      })
      .addCase(authActions.getProfileRefresh.fulfilled, (state, action) => {
        state.waiter = false;
        state.user = action.payload.data;
      })
      .addCase(authActions.getProfileRefresh.rejected, (state) => {
        state.waiter = false;
      })

      // refresh
      .addCase(authActions.refresh.pending, (state) => {
        state.mainWaiter = true;
      })
      .addCase(authActions.refresh.fulfilled, (state, action) => {
        state.mainWaiter = false;
        state.user = action.payload.data.user;
      })
      .addCase(authActions.refresh.rejected, (state) => {
        state.mainWaiter = false;
        state.user = userInitial;
      })

      // logout
      .addCase(authActions.logout.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(authActions.logout.fulfilled, (state) => {
        state.user = userInitial;
        state.access_token = '';
        state.fixWaiter = false;
      })
      .addCase(authActions.logout.rejected, (state) => {
        state.user = userInitial;
        state.fixWaiter = false;
      })

      // changleRole
      .addCase(authActions.changleRole.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(authActions.changleRole.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data.user;
      })
      .addCase(authActions.changleRole.rejected, (state) => {
        state.fixWaiter = false;
      })

      // contactChange
      .addCase(authActions.contactsChange.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.contactsChange.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.contactsChange.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // personalInfo
      .addCase(authActions.personalInfo.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.personalInfo.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.personalInfo.rejected, (state, action) => {
        state.fixWaiter = false;
        if (action.payload.errors && action.payload.errors.companyName) {
          state.errors = { companyName: action.payload.errors.companyName };
        }
        if (action.payload.errors && action.payload.errors.profileName) {
          state.errors = { profileName: action.payload.errors.profileName };
        }
      })

      // education
      .addCase(authActions.education.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.education.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.education.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getLangs
      .addCase(authActions.getLangs.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(authActions.getLangs.fulfilled, (state, action) => {
        state.waiter = false;
        state.langs = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.getLangs.rejected, (state) => {
        state.waiter = false;
      })

      // getLangsLevels
      .addCase(authActions.getLangsLevels.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(authActions.getLangsLevels.fulfilled, (state, action) => {
        state.waiter = false;
        state.langsLevels = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.getLangsLevels.rejected, (state) => {
        state.waiter = false;
      })

      // setLangs
      .addCase(authActions.setLangs.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.setLangs.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.setLangs.rejected, (state) => {
        state.fixWaiter = false;
      })

      // setDes
      .addCase(authActions.setDes.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.setDes.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.setDes.rejected, (state) => {
        state.fixWaiter = false;
      })

      // setCost
      .addCase(authActions.setCost.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.setCost.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.setCost.rejected, (state) => {
        state.fixWaiter = false;
      })

      // uploadAvatar
      .addCase(authActions.uploadAvatar.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.uploadAvatar.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.uploadAvatar.rejected, (state) => {
        state.fixWaiter = false;
      })

      // uploadCover
      .addCase(authActions.uploadCover.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.uploadCover.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.uploadCover.rejected, (state) => {
        state.fixWaiter = false;
      })

      // getCovers
      .addCase(authActions.getCovers.pending, (state) => {
        state.waiter = true;
        state.errors = false;
      })
      .addCase(authActions.getCovers.fulfilled, (state, action) => {
        state.waiter = false;
        state.covers = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.getCovers.rejected, (state) => {
        state.waiter = false;
      })

      // chooseCover
      .addCase(authActions.chooseCover.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.chooseCover.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.errors = false;
      })
      .addCase(authActions.chooseCover.rejected, (state) => {
        state.fixWaiter = false;
      })

      // editCompanyCategories
      .addCase(authActions.editCompanyCategories.pending, (state) => {
        state.fixWaiter = true;
        state.deleteErr = false;
      })
      .addCase(authActions.editCompanyCategories.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.user = action.payload.data;
        state.deleteErr = false;
      })
      .addCase(authActions.editCompanyCategories.rejected, (state) => {
        state.fixWaiter = false;
      })

      // setResume
      .addCase(authActions.setResume.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(authActions.setResume.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(authActions.setResume.rejected, (state) => {
        state.fixWaiter = false;
      })

      // deleteResume
      .addCase(authActions.deleteResume.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(authActions.deleteResume.fulfilled, (state) => {
        state.user.expert.resume = null;
        state.fixWaiter = false;
      })
      .addCase(authActions.deleteResume.rejected, (state) => {
        state.fixWaiter = false;
      })

      // emailUpdate
      .addCase(authActions.emailUpdate.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.emailUpdate.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.emailUpdate.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // emailVerify
      .addCase(authActions.emailVerify.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.emailVerify.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.emailVerify.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // phoneUpdate
      .addCase(authActions.phoneUpdate.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.phoneUpdate.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.phoneUpdate.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // phoneVerify
      .addCase(authActions.phoneVerify.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.phoneVerify.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.phoneVerify.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // accountDeleteCode
      .addCase(authActions.accountDeleteCode.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.accountDeleteCode.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.accountDeleteCode.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // accountDelete
      .addCase(authActions.accountDelete.pending, (state) => {
        state.fixWaiter = true;
        state.deleteErr = false;
      })
      .addCase(authActions.accountDelete.fulfilled, (state) => {
        state.fixWaiter = false;
        state.deleteErr = false;
      })
      .addCase(authActions.accountDelete.rejected, (state, action) => {
        state.fixWaiter = false;
        if (action.payload.message) {
          state.deleteErr = action.payload.message;
        } else if (action.payload.errors.message) {
          state.deleteErr = action.payload.errors.message;
        }
      })

      // putBusinessCategories
      .addCase(authActions.putBusinessCategories.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(authActions.putBusinessCategories.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.fixWaiter = false;
      })
      .addCase(authActions.putBusinessCategories.rejected, (state) => {
        state.fixWaiter = false;
      })

      // saveExpertCatLevel1
      .addCase(authActions.saveExpertCatLevel1.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(authActions.saveExpertCatLevel1.fulfilled, (state, action) => {
        state.user = { ...action.payload.data };
        state.fixWaiter = false;
      })
      .addCase(authActions.saveExpertCatLevel1.rejected, (state) => {
        state.fixWaiter = false;
      })

      // saveExpertCatLevel2and3
      .addCase(authActions.saveExpertCatLevel2and3.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(authActions.saveExpertCatLevel2and3.fulfilled, (state, action) => {
        state.user = { ...action.payload.data };
        state.fixWaiter = false;
      })
      .addCase(authActions.saveExpertCatLevel2and3.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // changePassword
      .addCase(authActions.changePassword.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.changePassword.fulfilled, (state) => {
        state.fixWaiter = false;
        state.errors = false;
      })
      .addCase(authActions.changePassword.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // notificationSettingsExpert
      .addCase(authActions.notificationSettingsExpert.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.notificationSettingsExpert.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.errors = false;
        state.user.expert.emailNotifications = action.meta.arg.email_notifications;
      })
      .addCase(authActions.notificationSettingsExpert.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      })

      // notificationSettingsCompany
      .addCase(authActions.notificationSettingsCompany.pending, (state) => {
        state.fixWaiter = true;
        state.errors = false;
      })
      .addCase(authActions.notificationSettingsCompany.fulfilled, (state, action) => {
        state.fixWaiter = false;
        state.errors = false;
        state.user.company.emailNotifications = action.meta.arg.email_notifications;
      })
      .addCase(authActions.notificationSettingsCompany.rejected, (state, action) => {
        state.fixWaiter = false;
        state.errors = action.payload.errors;
      });
  },
});

export const {
  clearErrors,
  memberFio,
  setUserInitial,
  availableProjectRequestsMinus,
  setFirstVisit,
} = authSlice.actions;
export default authSlice.reducer;
