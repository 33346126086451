import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/hooks';
import * as Yup from 'yup';

const Step1 = ({ setValue }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const [openCat, setOpenCat] = useState(false);
  const { projectCompanyCategories } = useSelector((state) => state.projectReducer);
  const currentProject = useSelector((state) => state.projectReducer.currentProject);
  const step1Schema = Yup.object().shape({
    name: Yup.string()
      .matches(/[a-z-а-я-ё]/, translate('Valid.minLowName'))
      .test('len', translate('Valid.minTwoWords'), (val) => {
        if (val) {
          const split = val.split(' ');
          let counter = 0;
          split.map((item) => {
            if (item.length > 0) {
              counter += 1;
            }
            return false;
          });
          return counter > 1;
        }
        return false;
      })
      .required(translate('Valid.req')),
    businessCategories: Yup.array()
      .min(1, translate('Valid.minCategory'))
      .required(translate('Valid.req')),
  });

  const handleSubmit = async ({ name, businessCategories }) => {
    setValue('name', name);
    setValue('businessCategories', businessCategories);
    navigate('/publication/step2/');
  };

  const formik = useFormik({
    initialValues: {
      name: currentProject.name,
      businessCategories: currentProject.businessCategories,
    },
    validationSchema: step1Schema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <form className="prpb__wrap" onSubmit={formik.handleSubmit}>
      <div className="prpb__name">{translate('Publication.prName')}</div>
      <div className="prpb__body">
        <div className="prpb__label">{translate('Publication.prName')}</div>
        <input
          defaultValue={formik.values.name}
          className="input prpb__input"
          name="name"
          type="text"
          placeholder={translate('Publication.prNamePlaceholder')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          maxLength={50}
        />
        <div className="prpb__error">
          {formik.touched.name && formik.errors.name && (
            <div className="prpb__error__item">{formik.errors.name}</div>
          )}
        </div>
        <div className="prpb__hr" />

        <div className="prpb__category">
          <div className="prpb__label">{translate('Publication.chooseCategory')}</div>
          <div className="prpb__category__text">{translate('Publication.chooseCatText')}</div>

          <button
            id="prpb__category__btn"
            className={`prpb__btn ${openCat ? 'active' : ''}`}
            type="button"
            onClick={() => setOpenCat(true)}
          >
            {translate('Publication.showCategory')}
          </button>

          <div
            className={`prpb__category__wrap ${openCat ? 'active' : ''}`}
            id="prpb__category__wrap"
            role="group"
            aria-labelledby="checkbox-group"
          >
            <div className="prpb__category__col">
              {projectCompanyCategories.length > 0 &&
                projectCompanyCategories.map((item, index) => {
                  if (index % 2 === 0) {
                    return (
                      <label
                        className="prpb__category__item"
                        htmlFor={`id${item.id}`}
                        key={item.id}
                      >
                        <input
                          defaultChecked={formik.values.businessCategories.includes(
                            String(item.id),
                          )}
                          type="checkbox"
                          name="businessCategories"
                          value={item.id}
                          className="form-check-input"
                          id={`id${item.id}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div>{item.name}</div>
                      </label>
                    );
                  }
                  return false;
                })}
            </div>

            <div className="prpb__category__col">
              {projectCompanyCategories.length > 0 &&
                projectCompanyCategories.map((item, index) => {
                  if (index % 2 !== 0) {
                    return (
                      <label
                        className="prpb__category__item"
                        htmlFor={`id${item.id}`}
                        key={item.id}
                      >
                        <input
                          defaultChecked={formik.values.businessCategories.includes(
                            String(item.id),
                          )}
                          type="checkbox"
                          name="businessCategories"
                          value={item.id}
                          className="form-check-input"
                          id={`id${item.id}`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div>{item.name}</div>
                      </label>
                    );
                  }
                  return false;
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="prpb__error">
        {formik.touched.businessCategories && formik.errors.businessCategories && (
          <div className="prpb__error__item">{formik.errors.businessCategories}</div>
        )}
      </div>
      <div className="prpb__footer">
        <button className="btn btn-primary" type="submit">
          {translate('next')}
        </button>
        <Link className="btn btn-outline-primary" to="/my-projects">
          {translate('cancel')}
        </Link>
      </div>
    </form>
  );
};

Step1.propTypes = {
  setValue: PropTypes.func.isRequired,
};

export default Step1;
