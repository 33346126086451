import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from 'src/store/actions';

import Search from './Search';
import UsersTable from './UsersTable';

const UsersIndex = () => {
  const dispatch = useDispatch();

  const meta = useSelector((state) => state.adminReducer.usersMeta);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(adminActions.getAllUsers({ search, page }));
  }, [page, search]);

  return (
    <>
      <div className="admin_knowledge_header">Користувачі</div>
      <Search setSearch={setSearch} setPage={setPage} />
      <UsersTable />
      {meta.total > 0 && (
        <div className="proekt__pagination">
          <ReactPaginate
            breakLabel="....."
            onPageChange={(nextPage) => setPage(nextPage.selected + 1)}
            pageCount={meta.last_page}
            forcePage={meta.current_page - 1}
            activeClassName="active"
            pageRangeDisplayed="2"
            marginPagesDisplayed="1"
          />
        </div>
      )}
    </>
  );
};

export default UsersIndex;
