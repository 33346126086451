import { createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from 'src/services';

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
  try {
    const newData = {
      ...data,
      phone: data.phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, ''),
    };

    const result = await authService.signIn(newData);

    if (result.data.token) {
      localStorage.setItem('userToken', result.data.token);
      localStorage.setItem('blockTime', +new Date() + result.data.time * 1000);
    }

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const signInGoogle = createAsyncThunk(
  'auth/signInGoogle',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.signInGoogle(data);

      if (result.data.token) {
        localStorage.setItem('userToken', result.data.token);
        localStorage.setItem('blockTime', +new Date() + result.data.time * 1000);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const signUp = createAsyncThunk('auth/signUp', async (data, { rejectWithValue }) => {
  try {
    const newData = {
      ...data,
      phone: data.phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, ''),
    };

    const result = await authService.signUp(newData);

    if (result.data.access_token) {
      localStorage.setItem('userToken', result.data.access_token);
      localStorage.setItem('blockTime', +new Date() + result.data.expires_in * 1000);
    }

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const signUpGoogle = createAsyncThunk(
  'auth/signUpGoogle',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.signUpGoogle(data);

      if (result.data.access_token) {
        localStorage.setItem('userToken', result.data.access_token);
        localStorage.setItem('blockTime', +new Date() + result.data.expires_in * 1000);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const confirmPhone = createAsyncThunk(
  'auth/confirmPhone',
  async (data, { rejectWithValue }) => {
    try {
      const newData = {
        ...data,
        phone: data.phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, ''),
      };
      if (!data.ref) {
        delete newData.ref;
      }
      const result = await authService.confirmPhone(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const forgotPass = createAsyncThunk('auth/forgotPass', async (data, { rejectWithValue }) => {
  try {
    const newData = {
      ...data,
      phone: data.phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, ''),
    };
    const result = await authService.forgotPass(newData);

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const resendPasswordCode = createAsyncThunk(
  'auth/resend-password-code',
  async (data, { rejectWithValue }) => {
    try {
      const newData = {
        ...data,
        phone: data.phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, ''),
      };
      const result = await authService.resendPasswordCode(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const confirmPasswordCode = createAsyncThunk(
  'auth/confirm-password-code',
  async (data, { rejectWithValue }) => {
    try {
      const newData = {
        ...data,
        phone: data.phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, ''),
      };
      const result = await authService.confirmPasswordCode(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resetPass = createAsyncThunk('auth/resetPass', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.resetPass(data);

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getCompanyCategories = createAsyncThunk(
  'auth/getCompanyCategories',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.getCompanyCategories();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExpertCategories = createAsyncThunk(
  'auth/getExpertCategories',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.getExpertCategories();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCountries = createAsyncThunk(
  'auth/getCountries',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.getCountries();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCities = createAsyncThunk('auth/getCities', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.getCities();

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const regComp = createAsyncThunk('auth/regComp', async (data, { rejectWithValue }) => {
  try {
    const arrSfera = data.companyCategories.map((item) => item.id).filter((item) => item !== 0);
    const arrSferaUnic = arrSfera.filter((item, index) => arrSfera.indexOf(item) === index);
    const newData = { ...data, companyCategories: arrSferaUnic };
    if (!newData.workers) {
      delete newData.workers;
    }
    const result = await authService.regComp(newData);

    if (result.data.access_token) {
      localStorage.setItem('userToken', result.data.access_token);
      localStorage.setItem('blockTime', +new Date() + result.data.expires_in * 1000);
    }

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const regExp = createAsyncThunk('auth/regExp', async (data, { rejectWithValue }) => {
  try {
    const arrSfera = data.expertCategories.map((item) => item.id).filter((item) => item !== 0);
    const arrSferaUnic = arrSfera.filter((item, index) => arrSfera.indexOf(item) === index);
    const newData = { ...data, expertCategories: arrSferaUnic };

    const result = await authService.regExp(newData);

    if (result.data.access_token) {
      localStorage.setItem('userToken', result.data.access_token);
      localStorage.setItem('blockTime', +new Date() + result.data.expires_in * 1000);
    }

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getProfile = createAsyncThunk('auth/getProfile', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.getProfile();

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getProfileRefresh = createAsyncThunk(
  'auth/getProfileRefresh',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.getProfile();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const refresh = createAsyncThunk('auth/refresh', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.refresh();

    if (result.data.token) {
      localStorage.setItem('userToken', result.data.token);
      localStorage.setItem('blockTime', +new Date() + result.data.time * 1000);
    }

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const logout = createAsyncThunk('auth/logout', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.logout();

    localStorage.clear();

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changleRole = createAsyncThunk(
  'auth/changleRole',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.changleRole(data);

      if (result.data.token) {
        localStorage.clear();
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const contactsChange = createAsyncThunk(
  'user/contactsChange',
  async ({ data, role }, { rejectWithValue }) => {
    try {
      const newData = {
        ...data,
        phone: data.phone.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, ''),
        whatsapp:
          data.whatsappCh.length > 0
            ? data.whatsapp.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '')
            : '',
        viber:
          data.viberCh.length > 0
            ? data.viber.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '')
            : '',
        facebookMessenger:
          data.facebookMessengerCh.length > 0
            ? data.facebookMessenger.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '')
            : '',
        telegram: data.telegramCh.length > 0 ? data.telegram : '',
      };

      let result;
      if (role === 1) {
        result = await authService.contactsChangeExpert(newData);
      } else {
        result = await authService.contactsChangeCompany(newData);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const personalInfo = createAsyncThunk(
  'user/personalInfo',
  async ({ data, role }, { rejectWithValue }) => {
    try {
      let result;
      if (role === 1) {
        result = await authService.personalInfoExpert(data);
      } else {
        result = await authService.personalInfoCompany(data);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const education = createAsyncThunk('user/education', async (data, { rejectWithValue }) => {
  try {
    const newData = { education: data.education.map((item) => ({ ...item, id: 0 })) };

    const result = await authService.education(newData);

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getLangs = createAsyncThunk('user/getLangs', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.getLangs();

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getLangsLevels = createAsyncThunk(
  'user/getLangsLevels',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.getLangsLevels();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const setLangs = createAsyncThunk('user/setLangs', async (data, { rejectWithValue }) => {
  try {
    const newData = {
      languages: data.languages.map((item) => ({ ...item, id: 0 })).filter((item) => item.name > 0),
    };
    const result = await authService.setLangs(newData);

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const setDes = createAsyncThunk(
  'user/setDes',
  async ({ data, role }, { rejectWithValue }) => {
    try {
      let result;
      if (role === 1) {
        result = await authService.setDesExpert(data);
      } else {
        result = await authService.setDesCompany(data);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const setCost = createAsyncThunk('user/setCost', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.setCost(data);

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const uploadAvatar = createAsyncThunk(
  'user/uploadAvatar',
  async ({ file, role }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      let result;
      if (role === 1) {
        result = await authService.uploadAvatarExpert(formData);
      } else {
        result = await authService.uploadAvatarCompany(formData);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const uploadCover = createAsyncThunk(
  'user/uploadCover',
  async ({ file, role }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      let result;
      if (role === 1) {
        result = await authService.uploadCoverExpert(formData);
      } else {
        result = await authService.uploadCoverCompany(formData);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getCovers = createAsyncThunk('user/getCovers', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.getCovers();

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const chooseCover = createAsyncThunk(
  'user/chooseCover',
  async ({ data, role }, { rejectWithValue }) => {
    try {
      let result;
      if (role === 1) {
        result = await authService.chooseCoverExpert(data);
      } else {
        result = await authService.chooseCoverCompany(data);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editCompanyCategories = createAsyncThunk(
  'user/editCompanyCategories',
  async (data, { rejectWithValue }) => {
    try {
      const newData = {
        id: data.companyCategories.map((item) => {
          if (item.id !== '0') {
            return item.id;
          }
          return false;
        }),
      };
      newData.id = newData.id.filter((item) => item);

      const result = await authService.editCompanyCategories(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const setResume = createAsyncThunk(
  'user/setResume',
  async ({ file, type }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      let result;

      if (type === 'img') {
        formData.append('image', file);
        result = await authService.setResume(formData);
      } else {
        formData.append('document', file);
        result = await authService.setResume(formData);
      }

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteResume = createAsyncThunk(
  'user/deleteResume',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.deleteResume(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const emailUpdate = createAsyncThunk(
  'auth/emailUpdate',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.emailUpdate(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const emailVerify = createAsyncThunk(
  'auth/emailVerify',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.emailVerify(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const phoneUpdate = createAsyncThunk(
  'auth/phoneUpdate',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.phoneUpdate(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const phoneVerify = createAsyncThunk(
  'auth/phoneVerify',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.phoneVerify(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const accountDeleteCode = createAsyncThunk(
  'auth/accountDeleteCode',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.accountDeleteCode();

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const accountDelete = createAsyncThunk(
  'user/accountDelete',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.accountDelete(data);
      if (result.status === 204) {
        localStorage.clear();
      }
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const putBusinessCategories = createAsyncThunk(
  'user/putbusinessCategories',
  async (data, { rejectWithValue }) => {
    try {
      const newData = {
        businessCategories: data.businessCategories.map((item) => {
          if (item.id !== '0' && item.id !== 0) {
            return item.id;
          }
          return false;
        }),
      };
      newData.businessCategories = newData.businessCategories.filter((item) => item);

      const result = await authService.putBusinessCategories(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveExpertCatLevel1 = createAsyncThunk(
  'user/saveExpertCatLevel1',
  async (data, { rejectWithValue }) => {
    try {
      const newData = {
        expertCategory: Number(data),
      };
      const result = await authService.saveExpertCatLevel1(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const saveExpertCatLevel2and3 = createAsyncThunk(
  'user/saveExpertCatLevel2and3',
  async (data, { rejectWithValue }) => {
    try {
      const expertCategoryThird = [];
      const expertCategorySecond = [];
      data.map((item) =>
        item.child.map((child) => {
          if (child > 0) {
            expertCategoryThird.push(child);
          }

          return false;
        }),
      );
      data.map((item) => {
        if (item.id > 0) {
          expertCategorySecond.push(item.id);
        }

        return false;
      });
      const newData = {
        expertCategorySecond,
        expertCategoryThird,
      };
      const result = await authService.saveExpertCatLevel2and3(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (data, { rejectWithValue }) => {
    try {
      const result = await authService.changePassword(data);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const notificationSettingsExpert = createAsyncThunk(
  'auth/notificationSettingsExpert',
  async (data, { rejectWithValue }) => {
    const newData = { ...data, email_notifications: Number(data.email_notifications) };
    try {
      const result = await authService.notificationSettingsExpert(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const notificationSettingsCompany = createAsyncThunk(
  'auth/notificationSettingsCompany',
  async (data, { rejectWithValue }) => {
    const newData = { ...data, email_notifications: Number(data.email_notifications) };
    try {
      const result = await authService.notificationSettingsCompany(newData);

      return result;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const adminLogin = createAsyncThunk('auth/adminLogin', async (data, { rejectWithValue }) => {
  try {
    const result = await authService.adminLogin(data);

    if (result.data.token) {
      localStorage.setItem('userToken', result.data.token);
      localStorage.setItem('blockTime', +new Date() + result.data.time * 1000);
    }

    return result;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
