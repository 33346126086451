import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import avatarDefault from 'src/assets/img/avatar-default.png';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { v4 as uuidv4 } from 'uuid';

const Preview = ({ submitForm }) => {
  const { translate } = useTranslation();
  const waiter = useSelector((state) => state.projectReducer.fixWaiter);
  const currentProject = useSelector((state) => state.projectReducer.currentProject);
  const projectCompanyCategories = useSelector(
    (state) => state.projectReducer.projectCompanyCategories,
  );
  const projectExpertCategories = useSelector(
    (state) => state.projectReducer.projectExpertCategories,
  );
  let level3 = [];
  projectExpertCategories.forEach((item) =>
    item.child.forEach((item2) =>
      item2.child.forEach((item3) => {
        level3 = [...level3, item3];
      }),
    ),
  );
  level3 = level3.filter((item) => currentProject.expertThreeCategories.includes(item.id));

  const langs = useSelector((state) =>
    state.projectReducer.langs.filter((item) => currentProject.languages.includes(item.id)),
  );
  const expertList = useSelector((state) =>
    state.projectReducer.expertList.filter((item) =>
      currentProject.invitations.includes(item.id.toString()),
    ),
  );

  const projectDurations = useSelector((state) => state.projectReducer.projectDurations);
  const size = Object.keys(projectDurations).length;
  let projectDurationsList = [];
  for (let i = 0; i < size; i += 1) {
    projectDurationsList[i] = projectDurations[i + 1];
  }
  projectDurationsList = projectDurationsList.filter(
    (item) => Number(currentProject.projectDuration) === item.id,
  );

  return (
    <div className="prpb__wrap">
      <div className="prpb__name prpb__name__preview">
        {translate('Publication.preview')}
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={submitForm}
          disabled={waiter}
        >
          {translate('Publication.publicProject')}
        </button>
      </div>
      <div className="prpb__body">
        <div className="prpreview">
          <div className="prpreview__t">{translate('Publication.previewName')}</div>
          <div className="prpb__prname">
            <div className="name">{currentProject.name}</div>
          </div>
          <div className="prpreview__t">{translate('Publication.previewCat')}</div>
          <div className="prpreview__tags">
            {projectCompanyCategories.length > 0 &&
              projectCompanyCategories.map((item) => {
                if (currentProject.businessCategories.includes(item.id.toString())) {
                  return (
                    <div className="prpreview__tags__item" key={item.id}>
                      {item.name}
                    </div>
                  );
                }
                return false;
              })}
          </div>
          <Link to="/publication/" className="editbtn">
            <svg width="24" height="24">
              <use xlinkHref={`${sprite}#pbEdit`} />
            </svg>
          </Link>
        </div>
        <div className="prpreview">
          <div className="prpreview__t">{translate('Publication.previewDes')}</div>
          <div className="prpb__des__text">
            <p>{currentProject.description}</p>
          </div>
          <Link to="/publication/step2/" className="editbtn">
            <svg width="24" height="24">
              <use xlinkHref={`${sprite}#pbEdit`} />
            </svg>
          </Link>
        </div>
        <div className="prpb__detail prpreview">
          <div className="prpreview__t">{translate('Publication.previewCatSpec')}</div>
          <div className="prpreview__tabs tabs-nav" data-tabs="#proect-tabs" />
          <div className="tabs" id="proect-tabs">
            <div className="tabs__item active">
              <div className="prtags">
                {level3.length > 0 &&
                  level3.map((item) => (
                    <div className="prtags__item" key={item.id}>
                      {item.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <Link to="/publication/step3/" className="editbtn">
            <svg width="24" height="24">
              <use xlinkHref={`${sprite}#pbEdit`} />
            </svg>
          </Link>
        </div>
        <div className="prpb__types prpreview">
          <div>
            <div className="prpb__type__block">
              <div className="prpreview__t">{translate('Publication.previewType')}</div>
              {currentProject.projectType ? (
                <div className="prpb__type__answ">{translate('Publication.type1')}</div>
              ) : (
                <div className="prpb__type__answ">{translate('Publication.type2')}</div>
              )}
            </div>

            {currentProject.screeningQuestions.length > 0 && (
              <div className="prpb__type__block">
                <div className="prpreview__t">{translate('Publication.qaShort')}</div>
                {currentProject.screeningQuestions.map((item) => (
                  <div className="prpb__type__answ" key={uuidv4()}>
                    {item}
                  </div>
                ))}
              </div>
            )}

            <div className="prpb__type__block">
              <div className="prpreview__t">{translate('Publication.askLatter')}</div>
              <div className="prpb__type__answ">
                {currentProject.transmittalLetter ? translate('yes') : translate('no')}
              </div>
            </div>
          </div>
          <Link to="/publication/step3/sub-step2" className="editbtn">
            <svg width="24" height="24">
              <use xlinkHref={`${sprite}#pbEdit`} />
            </svg>
          </Link>
        </div>
        {langs.length > 0 && (
          <div className="prpb__prlang prpreview">
            <div className="prpreview__t">{translate('Publication.langsShort')}</div>
            <div className="prtags prtags__mulish">
              {langs.map((item) => (
                <div className="prtags__item" key={item.id}>
                  {item.name}
                </div>
              ))}
            </div>
            <Link to="/publication/step3/sub-step3" className="editbtn">
              <svg width="24" height="24">
                <use xlinkHref={`${sprite}#pbEdit`} />
              </svg>
            </Link>
          </div>
        )}

        {expertList.length > 0 && (
          <div className="prpb__invite prpreview">
            <div className="prpreview__t">{translate('Publication.previewInvite')}</div>
            {expertList.map((item) => (
              <div className="prpb__invite__item" key={item.id}>
                <div
                  className="img"
                  style={{ backgroundImage: `url(${item.avatar || avatarDefault})` }}
                />
                <div className="about">
                  <div className="name">{item.profileName}</div>
                </div>
              </div>
            ))}
            <Link to="/publication/step3/sub-step4" className="editbtn">
              <svg width="24" height="24">
                <use xlinkHref={`${sprite}#pbEdit`} />
              </svg>
            </Link>
          </div>
        )}

        <div className="prpb__prate prpreview">
          <div className="prpreview__t">{translate('Publication.durationPreview')}</div>
          {projectDurationsList[0].data}
          <Link to="/publication/step3/sub-step5" className="editbtn">
            <svg width="24" height="24">
              <use xlinkHref={`${sprite}#pbEdit`} />
            </svg>
          </Link>
        </div>

        <div className="prpb__pbudget prpreview">
          <div className="prpreview__t">{translate('Publication.budget')}</div>
          {currentProject.doNotShowPayment ? (
            <>{translate('Publication.budgetHiden')}</>
          ) : (
            <>{currentProject.budget && currentProject.budget} грн</>
          )}

          <Link to="/publication/step4/" className="editbtn">
            <svg width="24" height="24">
              <use xlinkHref={`${sprite}#pbEdit`} />
            </svg>
          </Link>
        </div>
      </div>
      <div className="prpb__footer">
        <button className="btn btn-primary" type="button" onClick={submitForm} disabled={waiter}>
          {translate('Publication.publicProject')}
        </button>
        <Link className="btn btn-outline-primary" to="/publication/step4/">
          {translate('cancel')}
        </Link>
      </div>
    </div>
  );
};

Preview.propTypes = {
  submitForm: PropTypes.func.isRequired,
};

export default Preview;
