import { api } from 'src/api';
import { getHeaders } from 'src/helpers';

export const getKnowledgeBase = async (data) => {
  let result = [];

  if (data?.search && data?.page) {
    result = await api.get(
      `/admin/knowledge-base-article?page=${data.page}&search=${data.search}`,
      getHeaders(),
    );
  } else if (data?.search) {
    result = await api.get(`/admin/knowledge-base-article?search=${data.search}`, getHeaders());
  } else if (data?.page) {
    result = await api.get(`/admin/knowledge-base-article?page=${data.page}`, getHeaders());
  } else {
    result = await api.get('/admin/knowledge-base-article', getHeaders());
  }

  return result;
};

export const getKnowledgeBaseDetail = async (id) => {
  const result = await api.get(`/admin/knowledge-base-article/${id}`, getHeaders());

  return result;
};
export const createKnowledge = async (data) => {
  const result = await api.post('/admin/knowledge-base-article', data, getHeaders());

  return result;
};

export const deleteKnowledge = async (id) => {
  const result = await api.delete(`/admin/knowledge-base-article/${id}`, getHeaders());

  return result;
};

export const updateKnowledge = async (data) => {
  const result = await api.put(`/admin/knowledge-base-article/${data.id}`, data, getHeaders());

  return result;
};

export const getAllUsers = async (data) => {
  let url = `/admin/users?page=${data.page}&`;
  if (data.search) {
    url += `search=${data.search}&`;
  }

  const result = await api.get(url, getHeaders());

  return result;
};

export const getUser = async (data) => {
  const result = await api.get(`/admin/users/${data}`, getHeaders());

  return result;
};

export const getPayments = async (data) => {
  let url = `/admin/payments?page=${data.page}&`;
  if (data.search) {
    url += `search=${data.search}&`;
  }
  if (data.date) {
    url += `date=${data.date}&`;
  }
  if (data.status) {
    url += `status=${data.status}&`;
  }

  const result = await api.get(url, getHeaders());

  return result;
};

export const getProjects = async (data) => {
  let apiUrl = '/admin/projects?';

  if (data?.page) {
    apiUrl += `page=${data.page}&`;
  }

  if (data?.search) {
    apiUrl += `search=${data.search}&`;
  }

  if (data?.status) {
    apiUrl += `status=${data.status}&`;
  }

  const result = await api.get(apiUrl, getHeaders());

  return result;
};

export const getProject = async (data) => {
  const result = await api.get(`/admin/projects/${data.id}`, getHeaders());

  return result;
};

export const updateProject = async (data) => {
  const result = await api.put(`/admin/projects/${data.id}/update`, data, getHeaders());

  return result;
};

export const updateProjectStatus = async (data) => {
  const result = await api.post(`/admin/projects/${data.id}/update-status`, data, getHeaders());

  return result;
};

export const hideProject = async (data) => {
  await api.post(`/admin/projects/${data.id}/hide`, null, getHeaders());
};

export const getProjectChats = async (data) => {
  const result = await api.get(`/admin/projects/${data.id}/chats`, getHeaders());

  return result;
};

export const getChatMessages = async (data) => {
  const result = await api.get(`/admin/projects/chats/${data.id}/messages`, getHeaders());

  return result;
};

export const updateReviewsText = async (data) => {
  const result = await api.post(`/admin/reviews/${data.id}/update-text`, data, getHeaders());

  return result;
};

export const expertUpdateAvatar = async (id, data) => {
  const result = await api.post(`/admin/users/expert/${id}/update-avatar`, data, getHeaders());

  return result;
};

export const companyUpdateAvatar = async (id, data) => {
  const result = await api.post(`/admin/users/company/${id}/update-avatar`, data, getHeaders());

  return result;
};

export const expertUpdateCover = async (id, data) => {
  const result = await api.post(`/admin/users/expert/${id}/update-cover`, data, getHeaders());

  return result;
};

export const companyUpdateCover = async (id, data) => {
  const result = await api.post(`/admin/users/company/${id}/update-cover`, data, getHeaders());

  return result;
};

export const expertUpdateInfo = async (data) => {
  const result = await api.put(`/admin/users/expert/${data.id}/update-info`, data, getHeaders());

  return result;
};

export const companyUpdateInfo = async (data) => {
  const result = await api.put(`/admin/users/company/${data.id}/update-info`, data, getHeaders());

  return result;
};

export const expertUpdateStatus = async (data) => {
  const result = await api.post(`/admin/users/expert/${data.id}/update-status`, data, getHeaders());

  return result;
};

export const expertUpdateEducation = async (data) => {
  const result = await api.put(`/admin/users/expert/${data.id}/education`, data, getHeaders());

  return result;
};

export const expertUpdateRessetPass = async (id) => {
  const result = await api.post(`/admin/users/${id}/reset-password`, null, getHeaders());

  return result;
};

export const deletePortFolio = async (portfolioId) => {
  const result = await api.delete(`/admin/users/expert/portfolio/${portfolioId}`, getHeaders());

  return result;
};

export const editPortfolio = async (formData, portfolioId) => {
  const result = await api.post(
    `/admin/users/expert/update-portfolio/${portfolioId}`,
    formData,
    getHeaders(),
  );

  return result;
};

export const deleteResume = async (resumeId) => {
  const result = await api.delete(`/admin/users/expert/resume/${resumeId}`, getHeaders());

  return result;
};

export const expertFeatures = async (id) => {
  const result = await api.get(`/admin/users/expert/${id}/features`, getHeaders());

  return result;
};

export const expertPaidPackages = async (id) => {
  const result = await api.get(`/admin/users/expert/${id}/paid-packages`, getHeaders());

  return result;
};

export const expertPaidServices = async (id) => {
  const result = await api.get(`/admin/users/expert/${id}/paid-services`, getHeaders());

  return result;
};
export const expertFeaturesActivate = async (data) => {
  const result = await api.post(`/admin/users/expert/features/activate`, data, getHeaders());

  return result;
};

export const expertFeaturesDeactivate = async (data) => {
  const result = await api.post(`/admin/users/expert/features/deactivate`, data, getHeaders());

  return result;
};

export const companyUpdateStatus = async (data) => {
  const result = await api.post(
    `/admin/users/company/${data.id}/update-status`,
    data,
    getHeaders(),
  );

  return result;
};

export const getProjectsLogs = async (data) => {
  let apiUrl = '/admin/logs/projects?';

  if (data.page) {
    apiUrl += `page=${data.page}&`;
  }

  if (data.search) {
    apiUrl += `search=${data.search}&`;
  }

  if (data.date) {
    apiUrl += `date=${data.date}&`;
  }

  const result = await api.get(apiUrl, getHeaders());

  return result;
};

export const getCompanyProjects = async (id) => {
  const result = await api.get(`/admin/users/company/${id}/projects`, getHeaders());

  return result;
};

export const getPaidBusinessCategories = async (id) => {
  const result = await api.get(`/admin/users/company/${id}/paid-business-categories`, getHeaders());

  return result;
};

export const getPaidProjectFeatures = async (id) => {
  const result = await api.get(`/admin/users/company/${id}/paid-project-features`, getHeaders());

  return result;
};

export const getCompanyFeatures = async (id) => {
  const result = await api.get(
    `/admin/users/company/features${id > 0 ? `?project=${id}` : ''}`,
    getHeaders(),
  );

  return result;
};

export const сompanyFeaturesActivate = async (data) => {
  const result = await api.post(`/admin/users/company/features/activate`, data, getHeaders());

  return result;
};

export const сompanyFeaturesDeactivate = async (data) => {
  const result = await api.post(`/admin/users/company/features/deactivate`, data, getHeaders());

  return result;
};

export const getUsersLogs = async (data) => {
  let apiUrl = '/admin/logs/users?';

  if (data.page) {
    apiUrl += `page=${data.page}&`;
  }

  if (data.search) {
    apiUrl += `search=${data.search}&`;
  }

  if (data.date) {
    apiUrl += `date=${data.date}&`;
  }

  const result = await api.get(apiUrl, getHeaders());

  return result;
};

export const getBazaLogs = async (data) => {
  let apiUrl = '/admin/logs/knowledge-base?';

  if (data.page) {
    apiUrl += `page=${data.page}&`;
  }

  if (data.search) {
    apiUrl += `search=${data.search}&`;
  }

  if (data.date) {
    apiUrl += `date=${data.date}&`;
  }

  const result = await api.get(apiUrl, getHeaders());

  return result;
};

export const userBlock = async (data) => {
  const result = await api.post(`/admin/block-user`, data, getHeaders());

  return result;
};
