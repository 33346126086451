import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const Reviews = () => {
  const dispatch = useDispatch();

  const company = useSelector((state) => state.adminReducer.project.companyReview);
  const expert = useSelector((state) => state.adminReducer.project.expertReview);

  const [review, setReview] = useState(false);
  const [reviewText, setReviewText] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setReview(false);
    setReviewText('');
    setShow(false);
  };
  const handleShow = (item) => {
    setReview(item);
    setReviewText(item.text);
    setShow(true);
  };

  const handleSub = () => {
    dispatch(adminActions.updateReviewsText({ id: review.id, text: reviewText }));
  };

  return (
    <>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Імя</th>
            <th>Дата</th>
            <th>Кількість зірок</th>
            <th>Текст</th>
            <th>Сумма</th>
            <th>Назва проекту</th>
            <th>Дії</th>
          </tr>
        </thead>
        <tbody className="admin_knowledge_action_btn">
          {expert && (
            <tr>
              <td>{expert.id}</td>
              <td>
                <a href={`/expert/${expert.expert.slug}`}>{expert.expert.profileName}</a>
              </td>
              <td>{expert.createdAt}</td>
              <td>{expert.rating}</td>
              <td>{expert.text}</td>
              <td>{expert.projectBudget}</td>
              <td>{expert.projectName}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    handleShow(expert);
                  }}
                >
                  Редагувати
                </button>
              </td>
            </tr>
          )}
          {company && (
            <tr>
              <td>{company.id}</td>
              <td>
                <a href={`/expert/${company.company.slug}`}>{company.company.companyName}</a>
              </td>
              <td>{company.createdAt}</td>
              <td>{company.rating}</td>
              <td>{company.text}</td>
              <td>{company.projectBudget}</td>
              <td>{company.projectName}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleShow(company)}
                >
                  Редагувати
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="admin-edit-review">
              <div className="not-auth__title">Редагувати текст</div>
              <div className="textarea">
                <textarea value={reviewText} onChange={(e) => setReviewText(e.target.value)} />
              </div>
            </div>

            <div className="not-auth__btn">
              <button
                type="button"
                onClick={() => {
                  handleSub();
                  handleClose();
                }}
                className="btn btn-primary btn--new"
              >
                Зберегти
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={() => {
                  handleClose();
                }}
              >
                Відмінити
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Reviews;
