import Education from './education';
import ExpAbout from './exp-about';
import InfoMain from './info-main';
import PassResset from './pass-resset';
import Status from './status';

const Info = () => (
  <>
    <div className="exp-content__top">Особиста інформація</div>
    <ExpAbout />
    <InfoMain />
    <Status />
    <Education />
    <PassResset />
  </>
);
export default Info;
