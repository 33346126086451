import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const HideBudgetModal = ({ show, newProjectId }) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(`/my-projects/`);
  };
  return (
    <Modal show={show} onHide={handleClose} className="invite">
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" onClick={handleClose} className="custom-modal__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#modalClose`} />
            </svg>
          </button>

          <div className="custom-modal__title custom-modal__title--public">
            {translate('HideBudgetModal.title')}
          </div>
          <div className="custom-modal__subt custom-modal__subt--public">
            {translate('HideBudgetModal.text')}
          </div>
          <div className="custom-modal__btns">
            <Link
              to={`/payment/paid/?projectId=${newProjectId}&hideBudget=1`}
              className="btn btn-primary"
            >
              {translate('HideBudgetModal.btn1')}
            </Link>

            <Link to="/my-projects/" className="btn btn-outline-primary">
              {translate('HideBudgetModal.btn2')}
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

HideBudgetModal.propTypes = {
  show: PropTypes.bool.isRequired,
  newProjectId: PropTypes.number.isRequired,
};
export default HideBudgetModal;
