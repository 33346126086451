import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from 'src/store/actions';

import Filter from './Filter';
import PaymentTable from './PaymentTable';
import Search from './Search';

const IndexPage = () => {
  const dispatch = useDispatch();
  const meta = useSelector((state) => state.adminReducer.paymentsMeta);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState('');

  const [status, setStatus] = useState('');

  useEffect(() => {
    dispatch(adminActions.getPayments({ search, status, date, page }));
  }, [page, search, status, date]);

  return (
    <>
      <div className="admin_knowledge_header">Платежі</div>

      <Search setSearch={setSearch} setPage={setPage} />
      <Filter setDate={setDate} setStatus={setStatus} setPage={setPage} />

      <PaymentTable />
      {meta.total > 0 && (
        <div className="proekt__pagination">
          <ReactPaginate
            breakLabel="....."
            onPageChange={(nextPage) => setPage(nextPage.selected + 1)}
            pageCount={meta.last_page}
            forcePage={meta.current_page - 1}
            activeClassName="active"
            pageRangeDisplayed="2"
            marginPagesDisplayed="1"
          />
        </div>
      )}
    </>
  );
};

export default IndexPage;
