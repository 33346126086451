import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const TopSearch = ({ handleSearch, SearchName }) => {
  const { translate } = useTranslation();
  const [name, setName] = useState(SearchName);
  const waiter = useSelector((state) => state.projectReducer.getProjectsWaiter);
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);

  const handleSub = (e) => {
    e.preventDefault();
    handleSearch(name);
  };

  return (
    <div className="top-search">
      <div className="top-search__left">
        <nav className="prpb__breadcrumb">
          <ul>
            <li>
              <Link to="/">{translate('Projects.main')}</Link>
            </li>
            {ifAuth && (
              <li>
                <span>{translate('Projects.profile')}</span>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <div className="top-search__right">
        <form className="proekt__search" onSubmit={handleSub}>
          <div className="proekt__search__wrap">
            <div className="selectize-search">
              <svg width="17.5px" height="17.5px" className="top-search__icon">
                <use xlinkHref={`${sprite}#search`} />
              </svg>
              <input
                className="input"
                type="text"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={translate('Projects.searchTopPlaceholder')}
              />
            </div>

            <button type="submit" className="btn btn-primary top-search__btn" disabled={waiter}>
              {translate('Projects.seachBtn')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

TopSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  SearchName: PropTypes.string.isRequired,
};

export default TopSearch;
