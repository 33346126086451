import ProfileHead from '../profile-head';
import Info from './info';

const CompanyProfile = () => (
  <div className="exp-content">
    <div className="exp-profile exp-profile--company">
      <ProfileHead />
      <div className="exp-profile__tabs">
        <div className="exp-profile__tabs__wrap">
          <Info />
        </div>
      </div>
    </div>
  </div>
);

export default CompanyProfile;
