import { createSlice } from '@reduxjs/toolkit';

import { expertProjectActions } from '../actions';

const expertProjectSlice = createSlice({
  name: 'expertProject',
  initialState: {
    myOffersWaiter: false,
    myOffersWaiterMore: false,
    myRequests: [],
    myRequestsPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    myRejectedRequests: [],
    myRejectedRequestsPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    myInvitations: [],
    myInvitationsPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    myRejectedInvitations: [],
    myRejectedInvitationsPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    myCurrentProjects: [],
    myCurrentProjectsPag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    myCurrentProjectsComplete: [],
    myCurrentProjectsCompletePag: {
      total: 0,
      current_page: 0,
      per_page: 0,
      last_page: 0,
    },
    reviewInfo: false,
    reviewInfoWaiter: false,
    myOffersFixWaiter: false,
  },
  reducers: {
    clearErrors(state) {
      state.errors = false;
    },
  },
  extraReducers: (builder) => {
    builder

      // getMyRequests
      .addCase(expertProjectActions.getMyRequests.pending, (state) => {
        state.myOffersWaiter = true;
        state.knowledgeBase = [];
      })
      .addCase(expertProjectActions.getMyRequests.fulfilled, (state, action) => {
        state.myOffersWaiter = false;
        state.myRequests = action.payload.data.data;
        state.myRequestsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyRequests.rejected, (state) => {
        state.myOffersWaiter = false;
      })

      // getMyRequestsMore
      .addCase(expertProjectActions.getMyRequestsMore.pending, (state) => {
        state.myOffersWaiterMore = true;
        state.knowledgeBase = [];
      })
      .addCase(expertProjectActions.getMyRequestsMore.fulfilled, (state, action) => {
        state.myOffersWaiterMore = false;
        state.myRequests = [...state.myRequests, ...action.payload.data.data];
        state.myRequestsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyRequestsMore.rejected, (state) => {
        state.myOffersWaiterMore = false;
      })

      // getMyRejectedRequests
      .addCase(expertProjectActions.getMyRejectedRequests.pending, (state) => {
        state.myOffersWaiter = true;
        state.knowledgeBase = [];
      })
      .addCase(expertProjectActions.getMyRejectedRequests.fulfilled, (state, action) => {
        state.myOffersWaiter = false;
        state.myRejectedRequests = action.payload.data.data;
        state.myRejectedRequestsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyRejectedRequests.rejected, (state) => {
        state.myOffersWaiter = false;
      })

      // getMyRejectedRequestsMore
      .addCase(expertProjectActions.getMyRejectedRequestsMore.pending, (state) => {
        state.myOffersWaiterMore = true;
        state.knowledgeBase = [];
      })
      .addCase(expertProjectActions.getMyRejectedRequestsMore.fulfilled, (state, action) => {
        state.myOffersWaiterMore = false;
        state.myRejectedRequests = [...state.myRejectedRequests, ...action.payload.data.data];
        state.myRejectedRequestsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyRejectedRequestsMore.rejected, (state) => {
        state.myOffersWaiterMore = false;
      })

      // getMyinvitations
      .addCase(expertProjectActions.getMyinvitations.pending, (state) => {
        state.myOffersWaiter = true;
      })
      .addCase(expertProjectActions.getMyinvitations.fulfilled, (state, action) => {
        state.myOffersWaiter = false;
        state.myInvitations = action.payload.data.data;
        state.myInvitationsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyinvitations.rejected, (state) => {
        state.myOffersWaiter = false;
      })

      // getMyinvitationsMore
      .addCase(expertProjectActions.getMyinvitationsMore.pending, (state) => {
        state.myOffersWaiterMore = true;
      })
      .addCase(expertProjectActions.getMyinvitationsMore.fulfilled, (state, action) => {
        state.myOffersWaiterMore = false;
        state.myInvitations = [...state.myInvitations, ...action.payload.data.data];
        state.myInvitationsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyinvitationsMore.rejected, (state) => {
        state.myOffersWaiterMore = false;
      })

      // getMyRejectedinvitations
      .addCase(expertProjectActions.getMyRejectedinvitations.pending, (state) => {
        state.myOffersWaiter = true;
      })
      .addCase(expertProjectActions.getMyRejectedinvitations.fulfilled, (state, action) => {
        state.myOffersWaiter = false;
        state.myRejectedInvitations = action.payload.data.data;
        state.myRejectedInvitationsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyRejectedinvitations.rejected, (state) => {
        state.myOffersWaiter = false;
      })

      // getMyRejectedinvitationsMore
      .addCase(expertProjectActions.getMyRejectedinvitationsMore.pending, (state) => {
        state.myOffersWaiterMore = true;
      })
      .addCase(expertProjectActions.getMyRejectedinvitationsMore.fulfilled, (state, action) => {
        state.myOffersWaiterMore = false;
        state.myRejectedInvitations = [...state.myRejectedInvitations, ...action.payload.data.data];
        state.myRejectedInvitationsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyRejectedinvitationsMore.rejected, (state) => {
        state.myOffersWaiterMore = false;
      })

      // getMyCurrent
      .addCase(expertProjectActions.getMyCurrent.pending, (state) => {
        state.myOffersWaiter = true;
      })
      .addCase(expertProjectActions.getMyCurrent.fulfilled, (state, action) => {
        state.myOffersWaiter = false;
        state.myCurrentProjects = action.payload.data.data;
        state.myCurrentProjectsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyCurrent.rejected, (state) => {
        state.myOffersWaiter = false;
      })

      // getMyCurrentMore
      .addCase(expertProjectActions.getMyCurrentMore.pending, (state) => {
        state.myOffersWaiterMore = true;
      })
      .addCase(expertProjectActions.getMyCurrentMore.fulfilled, (state, action) => {
        state.myOffersWaiterMore = false;
        state.myCurrentProjects = [...state.myCurrentProjects, ...action.payload.data.data];
        state.myCurrentProjectsPag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyCurrentMore.rejected, (state) => {
        state.myOffersWaiterMore = false;
      })

      // getMyCurrentComplete
      .addCase(expertProjectActions.getMyCurrentComplete.pending, (state) => {
        state.myOffersWaiter = true;
      })
      .addCase(expertProjectActions.getMyCurrentComplete.fulfilled, (state, action) => {
        state.myOffersWaiter = false;
        state.myCurrentProjectsComplete = action.payload.data.data;
        state.myCurrentProjectsCompletePag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyCurrentComplete.rejected, (state) => {
        state.myOffersWaiter = false;
      })

      // getMyCurrentCompleteMore
      .addCase(expertProjectActions.getMyCurrentCompleteMore.pending, (state) => {
        state.myOffersWaiterMore = true;
      })
      .addCase(expertProjectActions.getMyCurrentCompleteMore.fulfilled, (state, action) => {
        state.myOffersWaiterMore = false;
        state.myCurrentProjectsComplete = [
          ...state.myCurrentProjectsComplete,
          ...action.payload.data.data,
        ];
        state.myCurrentProjectsCompletePag = action.payload.data.meta;
      })
      .addCase(expertProjectActions.getMyCurrentCompleteMore.rejected, (state) => {
        state.myOffersWaiterMore = false;
      })

      // startProject
      .addCase(expertProjectActions.startProject.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(expertProjectActions.startProject.fulfilled, (state, action) => {
        const newProject = action.payload.data;
        state.fixWaiter = false;
        state.myCurrentProjects = state.myCurrentProjects.map((item) => {
          if (item.id === newProject.id) {
            return newProject;
          }
          return item;
        });
      })
      .addCase(expertProjectActions.startProject.rejected, (state) => {
        state.fixWaiter = false;
      })

      // declineProjectInCurrent
      .addCase(expertProjectActions.declineProjectInCurrent.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(expertProjectActions.declineProjectInCurrent.fulfilled, (state, action) => {
        const id = action.meta.arg.project;
        state.myCurrentProjects = state.myCurrentProjects.filter((item) => item.id !== id);
        state.fixWaiter = false;
      })
      .addCase(expertProjectActions.declineProjectInCurrent.rejected, (state) => {
        state.fixWaiter = false;
      })

      // reviewProject
      .addCase(expertProjectActions.reviewProject.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(expertProjectActions.reviewProject.fulfilled, (state, action) => {
        const newProject = action.payload.data;
        state.fixWaiter = false;
        state.myCurrentProjects = state.myCurrentProjects.map((item) => {
          if (item.id === newProject.id) {
            return newProject;
          }
          return item;
        });
      })
      .addCase(expertProjectActions.reviewProject.rejected, (state) => {
        state.fixWaiter = false;
      })

      // sendDataForPayment
      .addCase(expertProjectActions.sendDataForPayment.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(expertProjectActions.sendDataForPayment.fulfilled, (state, action) => {
        const newProject = action.payload.data;
        state.myCurrentProjectsComplete = state.myCurrentProjectsComplete.map((item) => {
          if (item.id === newProject.id) {
            return newProject;
          }
          return item;
        });
        state.fixWaiter = false;
      })
      .addCase(expertProjectActions.sendDataForPayment.rejected, (state) => {
        state.fixWaiter = false;
      })

      // changeDataForPayment
      .addCase(expertProjectActions.changeDataForPayment.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(expertProjectActions.changeDataForPayment.fulfilled, (state, action) => {
        const newProject = action.payload.data;
        state.myCurrentProjectsComplete = state.myCurrentProjectsComplete.map((item) => {
          if (item.id === newProject.id) {
            return newProject;
          }
          return item;
        });
        state.fixWaiter = false;
      })
      .addCase(expertProjectActions.changeDataForPayment.rejected, (state) => {
        state.fixWaiter = false;
      })

      // reviewProjectInfo
      .addCase(expertProjectActions.reviewProjectInfo.pending, (state) => {
        state.reviewInfoWaiter = true;
        state.reviewInfo = false;
      })
      .addCase(expertProjectActions.reviewProjectInfo.fulfilled, (state, action) => {
        state.reviewInfoWaiter = false;
        state.reviewInfo = action.payload.data;
      })
      .addCase(expertProjectActions.reviewProjectInfo.rejected, (state) => {
        state.reviewInfoWaiter = false;
        state.reviewInfo = false;
      })

      // reviewProjectExpert
      .addCase(expertProjectActions.reviewProjectExpert.pending, (state) => {
        state.reviewInfoWaiter = true;
      })
      .addCase(expertProjectActions.reviewProjectExpert.fulfilled, (state, action) => {
        const projectId = action.payload.data.project.id;
        state.myCurrentProjectsComplete = state.myCurrentProjectsComplete.map((item) => {
          if (item.id === projectId) {
            if (item.companyLeftReview) {
              return { ...item, expertLeftReview: true, status: 8 };
            }
            return { ...item, expertLeftReview: true };
          }
          return item;
        });
        state.reviewInfoWaiter = false;
      })
      .addCase(expertProjectActions.reviewProjectExpert.rejected, (state) => {
        state.reviewInfoWaiter = false;
      })

      // declineProject
      .addCase(expertProjectActions.declineProject.pending, (state) => {
        state.myOffersFixWaiter = true;
      })
      .addCase(expertProjectActions.declineProject.fulfilled, (state, action) => {
        const id = action.meta.arg.invitation;
        const { total } = state.myInvitationsPag;
        state.myInvitations = state.myInvitations.filter((item) => item.id !== id);
        state.myInvitationsPag.total = total - 1;
        state.myOffersFixWaiter = false;
      })
      .addCase(expertProjectActions.declineProject.rejected, (state) => {
        state.myOffersFixWaiter = false;
      })

      // acceptProject
      .addCase(expertProjectActions.acceptProject.pending, (state) => {
        state.myOffersFixWaiter = true;
      })
      .addCase(expertProjectActions.acceptProject.fulfilled, (state, action) => {
        const id = action.meta.arg.invitation;
        state.myInvitations = state.myInvitations.map((item) => {
          if (item.id === id) {
            return { ...item, isAccepted: true };
          }
          return item;
        });
        state.myOffersFixWaiter = false;
      })
      .addCase(expertProjectActions.acceptProject.rejected, (state) => {
        state.myOffersFixWaiter = false;
      })

      // acceptProject
      .addCase(expertProjectActions.acceptPay.pending, (state) => {
        state.fixWaiter = true;
      })
      .addCase(expertProjectActions.acceptPay.fulfilled, (state, action) => {
        const newProject = action.payload.data;
        state.myCurrentProjectsComplete = state.myCurrentProjectsComplete.map((item) => {
          if (item.id === newProject.id) {
            return newProject;
          }
          return item;
        });
        state.fixWaiter = false;
      })
      .addCase(expertProjectActions.acceptPay.rejected, (state) => {
        state.myOffersFixWaiter = false;
      });
  },
});

export const { clearErrors } = expertProjectSlice.actions;
export default expertProjectSlice.reducer;
