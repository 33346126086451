import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import oldLogo from 'src/assets/img/old-img/logo-mobile.svg';
import { FixLoader } from 'src/components';
import { useQuery } from 'src/helpers';

import EmailVer from './email-ver';
import PhoneVer from './phone-ver';
import Thank from './thank';

const Verify = () => {
  const fixWaiter = useSelector((state) => state.authReducer.fixWaiter);

  const query = useQuery();
  const phone = query.get('phone');
  const email = query.get('email');

  const [needPhone, setNeedPhone] = useState(!!phone);
  const [needEmail, setNeedEmail] = useState(!!email);

  return (
    <>
      {fixWaiter && <FixLoader />}

      <div className="auth">
        <div className="wrapper">
          <div className="content">
            <header className="auth__header">
              <div className="container container--auth">
                <Link to="/" className="auth__logo">
                  <img src={oldLogo} alt="" />
                  ExpertLand
                </Link>
              </div>
            </header>
            {needPhone ? (
              <PhoneVer setNeedPhone={setNeedPhone} />
            ) : (
              <>{needEmail ? <EmailVer setNeedEmail={setNeedEmail} /> : <Thank />}</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Verify;
