import { PropTypes } from 'prop-types';
import { useState } from 'react';
import sprite from 'src/assets/img/sprite.svg';

const Search = ({ setSearch, setPage }) => {
  const [text, setText] = useState('');

  const handelSub = (e) => {
    e.preventDefault();
    setPage(1);
    setSearch(text);
  };

  return (
    <div>
      <form className="admin_search" onSubmit={handelSub}>
        <div className="proekt__search__wrap">
          <div className="selectize-search">
            <svg width="17.5px" height="17.5px" className="top-search__icon">
              <use xlinkHref={`${sprite}#search`} />
            </svg>
            <input
              type="text"
              className="input"
              onChange={(e) => setText(e.target.value)}
              placeholder="пошук..."
            />
          </div>

          <button type="submit" className="btn btn-primary top-search__btn">
            Пошук
          </button>
        </div>
      </form>
    </div>
  );
};

Search.propTypes = {
  setSearch: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default Search;
