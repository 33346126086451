import { PropTypes } from 'prop-types';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { expertProjectActions } from 'src/store/actions';

const StartProjectModal = ({ handleClose, show, projectId }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const myCurrentProjects = useSelector((state) =>
    state.expertProjectReducer.myCurrentProjects.filter((item) => item.id === projectId),
  );
  const [disabled, setDisabled] = useState(true);
  const project = myCurrentProjects[0];

  const handleDecline = () => {
    dispatch(expertProjectActions.declineProjectInCurrent({ project: project.id }));
    handleClose();
  };

  const handleAccept = () => {
    dispatch(expertProjectActions.startProject({ project: project.id }));
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" onClick={handleClose} className="custom-modal__close">
            <svg width="20" height="20">
              <use xlinkHref={`${sprite}#modalClose`} />
            </svg>
          </button>
          <div className="choose__expert-modal">
            <div className="choose__expert-modal__title">{translate('CurrentProjects.work')}</div>
            {project.isChangedConditions && (
              <div className="choose__expert-modal__title pt-0">
                {translate('CurrentProjects.changeCond')}
              </div>
            )}
            <div className="choose__expert-modal__title2">
              {translate('CurrentProjects.projectName')}: {project.name}
            </div>
            <div className="choose__expert-modal__title2">
              {project.bid ? (
                <>
                  {translate('CurrentProjects.stavkaExp')}: {project.bid} {translate('grnPr')}
                </>
              ) : (
                <>
                  {translate('CurrentProjects.budget')}: {project.budget} {translate('grnPr')}
                </>
              )}
            </div>
            <div className="choose__expert-modal__title2">
              {project.projectDuration ? (
                <>
                  {translate('CurrentProjects.duration')}:{' '}
                  {project.completionTime.text
                    ? project.completionTime.text
                    : `${project.completionTime} ${translate('days')}`}
                </>
              ) : (
                <>
                  {translate('CurrentProjects.duration')}:{' '}
                  {project.projectDuration.text
                    ? project.projectDuration.text
                    : `${project.projectDuration} ${translate('days')}`}
                </>
              )}
            </div>
            <div className="choose__expert-modal__info">
              {translate('CurrentProjects.infoText')}{' '}
              <button
                type="button"
                onClick={() => setDisabled(false)}
                className="choose__expert-modal__info__btn"
              >
                {translate('CurrentProjects.accept')}
              </button>{' '}
              {translate('or')},{' '}
              <button
                type="button"
                onClick={handleDecline}
                className="choose__expert-modal__info__btn red"
              >
                {translate('CurrentProjects.decline')}
              </button>
            </div>
            <div className="choose__expert-modal__bot--new">
              <div className="change__cond">
                {disabled && (
                  <>
                    <div className="change__cond__t">{translate('CurrentProjects.asq1')}</div>
                    <div className="change__cond__wrap">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-new"
                        onClick={() => setDisabled(false)}
                      >
                        {translate('CurrentProjects.accept')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-new"
                        onClick={handleDecline}
                      >
                        {translate('CurrentProjects.decline')}
                      </button>
                    </div>
                  </>
                )}
                <div className="change__cond__actions">
                  <button
                    type="button"
                    className="btn btn-primary btn--new"
                    disabled={disabled}
                    onClick={handleAccept}
                  >
                    {translate('CurrentProjects.workStart')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn--new"
                    onClick={() => handleClose(false)}
                  >
                    {translate('CurrentProjects.inMyPr')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
StartProjectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  projectId: PropTypes.number.isRequired,
};
export default StartProjectModal;
