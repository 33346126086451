import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import avatarDefault from 'src/assets/img/avatar-default.png';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import {
  sidebarTopMenuCompany,
  sidebarTopMenuExpert,
  sidebarTopMenuPublication,
} from 'src/constants';
import { useTranslation, useWindowDimensions } from 'src/hooks';

const SidebarPublication = ({ showAllLinks }) => {
  const role = useSelector((state) => state.authReducer.user.role);
  const sidebarTopMenu = role === 1 ? sidebarTopMenuExpert : sidebarTopMenuCompany;
  const { avatar } = useSelector((state) => state.authReducer.user.company);
  const menu = sidebarTopMenuPublication;
  const fullName = useSelector((state) => state.authReducer.user.company.companyName);
  const { language } = useTranslation();
  const location = useLocation();

  const { width } = useWindowDimensions();

  const getMenuId = () => {
    switch (location.pathname) {
      case '/my-projects/publication':
        return 1;
      case '/publication/step2/':
        return 2;
      case '/publication/step3/':
        return 3;
      case '/publication/step3/sub-step2/':
        return 3;
      case '/publication/step3/sub-step3/':
        return 3;
      case '/publication/step3/sub-step4/':
        return 3;
      case '/publication/step3/sub-step5/':
        return 3;
      case '/publication/step4/':
        return 4;
      case '/publication/preview/':
        return 5;
      default:
        return 1;
    }
  };

  return (
    <div className="exp-content__sidebar">
      <div className="sidebar">
        <div className="sidebar__top">
          <Link
            to="/profile/"
            className="sidebar__img"
            style={{
              backgroundImage: `url(${avatar || (role === 1 ? avatarDefault : avatarDefaultComp)})`,
            }}
          />
          <div className="sidebar__name">
            <Link to="/profile/">{fullName}</Link>
          </div>
        </div>

        {width > 768 && (
          <nav className="sidebar__nav">
            <ul className="sidebar__nav__menu1">
              {sidebarTopMenu &&
                sidebarTopMenu[language].map((item) => (
                  <li key={item.id}>
                    <Link to={item.to}>{item.name}</Link>
                  </li>
                ))}
            </ul>
            <ul>
              {menu[language].map((item) => (
                <li key={item.id} className="active">
                  {getMenuId() < item.id && !showAllLinks ? (
                    <span className="disable-span">{item.name}</span>
                  ) : (
                    <NavLink
                      to={item.to}
                      className={getMenuId() >= item.id || showAllLinks ? 'active' : 'notActive'}
                    >
                      {item.name}
                      {getMenuId() === item.id && (
                        <svg width="24px" height="24px" className="open">
                          <use xlinkHref={`${sprite}#edit`} />
                        </svg>
                      )}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

SidebarPublication.propTypes = {
  showAllLinks: PropTypes.bool.isRequired,
};

export default SidebarPublication;
