import { useTranslation } from 'src/hooks';

import Education from './education';
import Langs from './langs';
import PersonalInfo from './personal-info';
import PhotoBlock from './photo-block';
import Services from './services';
import ServicesCost from './services-cost';

const ExpAbout = () => {
  const { translate } = useTranslation();

  return (
    <>
      <PhotoBlock />

      <div className="exp-content__title">
        <h3>{translate('About.subtitleExp')}</h3>
      </div>

      <PersonalInfo />

      <Education />

      <Langs />

      <Services />

      <ServicesCost />
    </>
  );
};

export default ExpAbout;
