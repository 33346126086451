import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Loader } from 'src/components';
import { useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { authActions, projectActions } from 'src/store/actions';

const Filter = ({ setFilter, filter }) => {
  const { translate } = useTranslation();
  const query = useQuery();
  const queryName = query.get('name') || '';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const catWaiter = useSelector((state) => state.projectReducer.waiter);
  const waiter = useSelector((state) => state.projectReducer.getProjectsWaiter);
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const [cLevel1, setСLevel1] = useState(filter.expertCategory);
  const [cLevel2, setСLevel2] = useState(filter.expertCategorySecond);
  const [cLevel3, setСLevel3] = useState(filter.expertCategoryThird);

  useEffect(() => {
    setСLevel1(Number(filter.expertCategory));
    setСLevel2(Number(filter.expertCategorySecond));
    setСLevel3(Number(filter.expertCategoryThird));
  }, [filter]);

  const [slug, setSlug] = useState('');

  const [filterMob, setFilterMob] = useState(false);

  const projectExpertCategories = useSelector(
    (state) => state.projectReducer.projectExpertCategories,
  );
  const projectExpertCategoriesForOptions = projectExpertCategories.map((item) => ({
    value: String(item.id),
    label: item.name,
  }));
  let projectExpertCategoriesOptions = [{ value: '', label: translate('Projects.expSpecChoose') }];
  projectExpertCategoriesOptions = [
    ...projectExpertCategoriesOptions,
    ...projectExpertCategoriesForOptions,
  ];

  const projectCompanyCategories = useSelector(
    (state) => state.projectReducer.projectCompanyCategories,
  );

  const projectCompanyCategoriesForOptions = [
    { value: '', label: translate('Projects.bCatChoose') },
  ];
  let projectCompanyCategoriesOptions = projectCompanyCategories.map((item) => ({
    value: String(item.id),
    label: item.name,
  }));
  projectCompanyCategoriesOptions = [
    ...projectCompanyCategoriesForOptions,
    ...projectCompanyCategoriesOptions,
  ];

  const countries = useSelector((state) => state.authReducer.countries);

  useEffect(() => {
    if (projectCompanyCategories.length === 0) {
      dispatch(projectActions.getCompanyCategories());
    }
    if (countries.length === 0) {
      dispatch(authActions.getCountries());
    }
  }, []);

  const getQueryParam = ({ companyCategory, budget }) => {
    let param = '?';

    if (companyCategory) {
      param += `companyCategory=${companyCategory}&`;
    }

    if (budget.length) {
      budget.forEach((item) => {
        param += `budget[]=${item}&`;
      });
    }

    if (queryName) {
      param += `name=${queryName}&`;
    }

    return param;
  };

  const handleSubmit = async (values) => {
    await navigate(`/projects/${slug}${getQueryParam(values)}`);

    setFilter(values);
  };

  const formik = useFormik({
    initialValues: {
      expertCategory: filter.expertCategory,
      expertCategorySecond: filter.expertCategorySecond,
      expertCategoryThird: filter.expertCategoryThird,

      companyCategory: filter.companyCategory,
      budget: filter.budget,
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  // Зміна фільтру категорій id
  const handleLevel1Change = (val) => {
    setСLevel1(Number(val));
    setСLevel2(0);
    setСLevel3(0);
    formik.setFieldValue('expertCategory', val);
    formik.setFieldValue('expertCategorySecond', '');
    formik.setFieldValue('expertCategoryThird', '');
  };

  const handleLevel2Change = (val) => {
    setСLevel2(Number(val));
    setСLevel3(0);
    formik.setFieldValue('expertCategorySecond', val);
    formik.setFieldValue('expertCategoryThird', '');
  };
  const handleLevel3Change = (val) => {
    setСLevel3(Number(val));
    formik.setFieldValue('expertCategoryThird', val);
  };

  // Фільтрація категорій
  const level2 = formik.values.expertCategory
    ? projectExpertCategories.filter((item) => item.id === Number(formik.values.expertCategory))
    : projectExpertCategories;

  const level2ForForOptions = level2.length
    ? level2[0].child.map((item) => ({
        value: String(item.id),
        label: item.name,
      }))
    : [];
  let level2ForOptions = [{ value: '', label: translate('Projects.expSpecChoose') }];
  level2ForOptions = [...level2ForOptions, ...level2ForForOptions];

  let level3;
  level3 = formik.values.expertCategory
    ? projectExpertCategories.filter((item) => item.id === Number(formik.values.expertCategory))
    : projectExpertCategories;
  if (formik.values.expertCategorySecond) {
    level3 = level3.map((item) => ({
      ...item,
      child: item.child.filter((item2) => item2.id === Number(formik.values.expertCategorySecond)),
    }));
  }
  const level3ForForOptions =
    level3.length && level3[0] && level3[0].child.length
      ? level3[0].child[0].child.map((item) => ({
          value: String(item.id),
          label: item.name,
        }))
      : [];

  let level3ForOptions = [{ value: '', label: translate('Projects.expSpecChoose') }];
  level3ForOptions = [...level3ForOptions, ...level3ForForOptions];

  // Зміна фільтру категорій
  useEffect(() => {
    let clevel1Slug = '';
    let clevel2Slug = '';
    let clevel3Slug = '';

    projectExpertCategories.forEach((item) => {
      if (item.id === cLevel1) {
        clevel1Slug = item.slug;
      }
      return false;
    });
    if (clevel1Slug && cLevel2) {
      level2[0].child.forEach((item) => {
        if (item.id === cLevel2) {
          clevel2Slug = item.slug;
        }
        return false;
      });
    }
    if (clevel2Slug && cLevel3) {
      level3[0].child[0].child.forEach((item) => {
        if (item.id === cLevel3) {
          clevel3Slug = item.slug;
        }
        return false;
      });
    }

    setSlug(
      `${clevel1Slug ? `${clevel1Slug}/` : ''}${clevel2Slug ? `${clevel2Slug}/` : ''}${
        clevel3Slug ? `${clevel3Slug}/` : ''
      }`,
    );
  }, [cLevel1, cLevel2, cLevel3]);

  return (
    <form className="filter" id="filter-form">
      {width <= 768 ? (
        <div
          className={`filter__title ${filterMob ? 'active' : ''}`}
          onClick={() => setFilterMob(!filterMob)}
          onKeyPress={() => setFilterMob(!filterMob)}
          role="button"
          tabIndex="0"
        >
          {translate('Projects.filters')}
        </div>
      ) : (
        <div className="filter__title">{translate('Projects.filters')}</div>
      )}
      {catWaiter ? (
        <Loader />
      ) : (
        <div className="filter__content" style={{ display: filterMob ? 'block' : '' }}>
          <div className="filter__row">
            <div className="filter__label">{translate('Projects.expSpec')}</div>

            <Select
              classNamePrefix="react-select"
              className={`react-select react-select--border ${
                formik.values.expertCategory === '' ? 'zero' : ''
              }`}
              options={projectExpertCategoriesOptions}
              name="expertCategory"
              onChange={(e) => handleLevel1Change(e.value)}
              placeholder={translate('Projects.expSpecChoose')}
              value={
                projectExpertCategoriesOptions.filter(
                  (option) => option.value === formik.values.expertCategory,
                )[0]
              }
            />
          </div>

          {formik.values.expertCategory > 0 && (
            <div className="filter__row">
              <div className="filter__label">{translate('Projects.expSpec2')}</div>

              <Select
                classNamePrefix="react-select"
                className={`react-select react-select--border ${
                  formik.values.expertCategorySecond === '' ? 'zero' : ''
                }`}
                options={level2ForOptions}
                name="expertCategorySecond"
                onChange={(e) => handleLevel2Change(e.value)}
                placeholder={translate('Projects.expSpecChoose')}
                value={
                  level2ForOptions.filter(
                    (option) => option.value === formik.values.expertCategorySecond,
                  )[0]
                }
              />
            </div>
          )}

          {formik.values.expertCategorySecond > 0 && formik.values.expertCategory > 0 && (
            <div className="filter__row">
              <div className="filter__label">{translate('Projects.expSpec3')}</div>
              <Select
                classNamePrefix="react-select"
                className={`react-select react-select--border ${
                  formik.values.expertCategoryThird === '' ? 'zero' : ''
                }`}
                options={level3ForOptions}
                name="expertCategoryThird"
                onChange={(e) => handleLevel3Change(e.value)}
                placeholder={translate('Projects.expSpecChoose')}
                value={
                  level3ForOptions.filter(
                    (option) => option.value === formik.values.expertCategoryThird,
                  )[0]
                }
              />
            </div>
          )}

          <div className="filter__row">
            <div className="filter__label">{translate('Projects.bCat')}</div>
            <Select
              classNamePrefix="react-select"
              className={`react-select react-select--border ${
                formik.values.companyCategory === '' ? 'zero' : ''
              }`}
              options={projectCompanyCategoriesOptions}
              name="companyCategory"
              onChange={(e) => formik.setFieldValue('companyCategory', e.value)}
              placeholder={translate('Projects.bCatChoose')}
              value={
                projectCompanyCategoriesOptions.filter(
                  (option) => option.value === formik.values.companyCategory,
                )[0]
              }
            />
          </div>

          <div className="filter__row">
            <div className="filter__label">{translate('Projects.budget')}</div>
            <div className="filter__checkboxes">
              <label className="form-check" htmlFor="flexCheckChecked5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="0-1000"
                  id="flexCheckChecked5"
                  name="budget"
                  checked={formik.values.budget.includes('0-1000')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="form-check-label">0 грн-1000 грн</div>
              </label>
              <label className="form-check" htmlFor="flexCheckChecked6">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1000-5000"
                  id="flexCheckChecked6"
                  name="budget"
                  checked={formik.values.budget.includes('1000-5000')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="form-check-label">1000 грн-5000 грн</div>
              </label>
              <label className="form-check" htmlFor="flexCheckChecked7">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="5000-10000"
                  id="flexCheckChecked7"
                  name="budget"
                  checked={formik.values.budget.includes('5000-10000')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="form-check-label">5000 грн-10000 грн</div>
              </label>
              <label className="form-check" htmlFor="flexCheckChecked8">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="10000"
                  id="flexCheckChecked8"
                  name="budget"
                  checked={formik.values.budget.includes('10000')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="form-check-label">10000+ грн</div>
              </label>
            </div>
          </div>

          <div className="filter__row">
            <ul className="filter__links">
              <li>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleSubmit(formik.values)}
                  disabled={waiter}
                >
                  {translate('Projects.filterSub')}
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </form>
  );
};

Filter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    expertCategory: PropTypes.string,
    expertCategorySecond: PropTypes.string,
    expertCategoryThird: PropTypes.string,
    companyCategory: PropTypes.string,
    budget: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default Filter;
