import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from 'src/assets/img/header/logo.svg';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const HeaderContentPage = () => {
  const { setLanguage, language } = useTranslation();
  const [lang, setLang] = useState(false);
  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.header__lang')) {
        setLang(false);
      }
    });
  });
  return (
    <header className="header">
      <div className="wrap">
        <div className="header__wrap">
          <div className="header__left">
            <Link to="/" className="header__logo">
              <img alt="img" src={logo} />
              ExpertLand
            </Link>
          </div>
          <div className="header__right">
            <div className="header__lang">
              <button
                type="button"
                className="header__lang__m_btn"
                onClick={() => {
                  setLang(!lang);
                }}
              >
                <span className="header__lang__name">{language}</span>
                <svg width="24" height="24">
                  <use xlinkHref={`${sprite}#mainArrBlack`} />
                </svg>
              </button>
              <div className={`header__lang__toggle ${lang ? 'active' : ''}`}>
                <button
                  type="button"
                  onClick={() => {
                    setLanguage('ru');
                    window.location.reload();
                  }}
                >
                  Ru
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setLanguage('ua');
                    window.location.reload();
                  }}
                >
                  Ua
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderContentPage;
