import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import avatarDefault from 'src/assets/img/proekt/avatar-default.jpg';
import sprite from 'src/assets/img/sprite.svg';
import { ResponseModalExpert } from 'src/components';
import { useTranslation } from 'src/hooks';
import { expertProjectActions } from 'src/store/actions';

import {
  CardModal,
  CardModalChange,
  ReviewProjectModal,
  StartProjectModal,
  Status,
} from './components';

const Item = ({ projectId, tab }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const role = 1;
  const myCurrentProjects = useSelector((state) =>
    state.expertProjectReducer.myCurrentProjects.filter((item) => item.id === projectId),
  );
  const myCurrentProjectsComplete = useSelector((state) =>
    state.expertProjectReducer.myCurrentProjectsComplete.filter((item) => item.id === projectId),
  );
  const project = tab === 1 ? myCurrentProjects[0] : myCurrentProjectsComplete[0];
  const [showDetail, setShowDetail] = useState(false);

  // Модалка прийнятя умов
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // отправить проект на проверку
  const [showReview, setShowReview] = useState(false);
  const handleCloseReview = () => setShowReview(false);
  const handleShowReview = async () => {
    setShowReview(true);
  };

  // Отримав оплату
  const handleAcceptPay = () => {
    dispatch(expertProjectActions.acceptPay({ project: project.id }));
  };

  // Модалка додавання відгуку
  const [showResponse, setShowResponse] = useState(false);
  const handleCloseResponse = () => setShowResponse(false);
  const handleShowResponse = () => setShowResponse(true);

  return (
    <>
      <div className={`project-current__item ${showDetail ? 'detail' : ''}`}>
        {showDetail && (
          <div className={`project-current__head ${project.status === 3 ? 'greeting' : ''}`}>
            {project.status === 3 && (
              <div className="project-current__greeting">
                {translate('CurrentProjects.victory')}
              </div>
            )}

            <div className="project-current__head__wrap">
              <div className="project-current__head__title">{project.name}</div>

              <Link
                to={`/messages/${project.actualChatId}?company${project.company.id}&project=${project.id}`}
                className="btn btn-primary"
              >
                {translate('CurrentProjects.chat')}
              </Link>
            </div>
          </div>
        )}
        <div className="proekt__content__top">
          <div className="proekt__content__left">
            {showDetail && <Status status={project.status} />}
            <div className="proekt__content__status">
              {!showDetail ? project.name : translate('CurrentProjects.company')}
            </div>
            <div className="proekt__content__person">
              <Link
                className="proekt__content__avatar"
                to={`/employers/${project.company.slug}`}
                style={{ backgroundImage: `url(${project.company.avatar || avatarDefault})` }}
              />

              <Link
                className="proekt__content__person__name"
                to={`/employers/${project.company.slug}`}
              >
                {project.company.companyName}
              </Link>
              <div className="proekt__location">
                <svg width="14" height="20">
                  <use xlinkHref={`${sprite}#location`} />
                </svg>
                {project.company.country && project.company.country.name},{' '}
                {project.company.city && project.company.city.name}
              </div>
            </div>
            <div className="proekt__tags">
              {!project.doNotShowPayment && (
                <div className="proekt__tags__item">
                  <span className="tags-point">{project.budget} грн/проект</span>
                </div>
              )}

              <div className="proekt__tags__item">
                <span className="tags-point">{project.projectType.data.title}</span>
              </div>
              {project.businessCategories.map((item) => (
                <div className="proekt__tags__item" key={item.id}>
                  <span className="tags-point">{item.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="proekt__content__right">
            <div className="proekt__stat">
              <div className="proekt__stat__status">{translate('CurrentProjects.publicTitle')}</div>
              <div className="proekt__stat__item">
                <svg width="20" height="20">
                  <use xlinkHref={`${sprite}#timeSvg`} />
                </svg>
                {project.created}
              </div>
              <div className="proekt__stat__item">
                <svg width="22" height="16">
                  <use xlinkHref={`${sprite}#viewSvg`} />
                </svg>
                {project.views}
              </div>
            </div>
          </div>
        </div>
        <div className="project-current__contnent">
          <div className="proekt-detail__level1">
            {project.expertCategories.map((item) => (
              <div className="item" key={item.id}>
                {item.name}
              </div>
            ))}
          </div>
          <div className="proekt-detail__level2">
            {project.expertTwoCategories.map((item) => (
              <div className="item" key={item.id}>
                {item.name}
              </div>
            ))}
            {project.expertThreeCategories.map((item) => (
              <div className="item" key={item.id}>
                {item.name}
              </div>
            ))}
          </div>
          <div className="proekt__content__text">{project.description}</div>
          {showDetail ? (
            <>
              <div className="proekt-detail__lang">
                {project.languages.map((lang) => (
                  <div className="item" key={lang.id}>
                    {lang.name}
                  </div>
                ))}
              </div>

              {project.completionTime && (
                <div className="proekt-detail__duration">
                  <div className="duration1">{translate('CurrentProjects.duration')}</div>
                  <div className="duration2">
                    {project.completionTime.text
                      ? project.completionTime.text
                      : `${project.completionTime} ${translate('days')}`}
                  </div>
                </div>
              )}

              <div className="project-current__stavka">
                {project.status === 6 && project.canPay && (
                  <div className="project-current__card">{translate('CurrentProjects.card')}</div>
                )}

                {project.bid && (
                  <>
                    <div className="project-current__stavka__title">
                      {translate('CurrentProjects.stavka')}
                    </div>
                    <div className="proekt__tags">
                      <div className="proekt__tags__item">
                        <span className="tags-point">
                          {project.bid} {translate('grnPr')}
                        </span>
                      </div>
                      <div className="proekt__tags__item">
                        <span className="tags-point">
                          {project.projectDuration.text
                            ? project.projectDuration.text
                            : `${project.projectDuration} ${translate('days')}`}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className={`project-current__actions ${project.status === 5 ? 'status5' : ''} `}>
                {project.status === 3 && (
                  <button type="button" className="btn btn-primary" onClick={handleShow}>
                    {translate('CurrentProjects.btn1')}
                  </button>
                )}
                {project.status === 4 && (
                  <button type="button" className="btn btn-primary" onClick={handleShowReview}>
                    {translate('CurrentProjects.btn2')}
                  </button>
                )}

                {project.status === 6 && !project.canPay && (
                  <button type="button" className="btn btn-primary" onClick={handleAcceptPay}>
                    {translate('CurrentProjects.btn3')}
                  </button>
                )}

                {project.status === 7 && !project.expertLeftReview && (
                  <button type="button" className="btn btn-primary" onClick={handleShowResponse}>
                    {translate('CurrentProjects.btn4')}
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn--suport"
                  onClick={() => {
                    window.HelpCrunch('openChat');
                  }}
                >
                  {translate('CurrentProjects.support')}
                </button>
              </div>
              <div className="project-current__bot project-current__bot--detail">
                <button type="button" className="proekt__chat" onClick={() => setShowDetail(false)}>
                  {translate('hide')}
                  <svg width="44" height="21">
                    <use xlinkHref={`${sprite}#detail`} />
                  </svg>
                </button>
                <Link to={`/employers/${project.company.slug}`} className="btn btn-outline-primary">
                  {translate('CurrentProjects.allPr')}
                </Link>
              </div>
            </>
          ) : (
            <div className="project-current__bot">
              <button type="button" className="proekt__chat" onClick={() => setShowDetail(true)}>
                {translate('detail')}
                <svg width="44" height="21">
                  <use xlinkHref={`${sprite}#detail`} />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
      {show && <StartProjectModal handleClose={handleClose} show={show} projectId={projectId} />}

      {showReview && (
        <ReviewProjectModal
          handleClose={handleCloseReview}
          show={showReview}
          projectId={projectId}
        />
      )}

      {showResponse && (
        <ResponseModalExpert
          handleClose={handleCloseResponse}
          show={showResponse}
          projectId={projectId}
          role={role}
          projectName={project.company.companyName}
        />
      )}
    </>
  );
};
Item.propTypes = {
  projectId: PropTypes.number.isRequired,
  tab: PropTypes.number.isRequired,
};
export default Item;
