import { PropTypes } from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';

const NotActiveModal = ({ show, handleClose }) => {
  const { translate } = useTranslation();
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="custom-modal-big">
          <button type="button" className="proekt-modal__close" onClick={handleClose}>
            <svg className="icon" width="20px" height="20px">
              <use xlinkHref={`${sprite}#close`} />
            </svg>
          </button>
          <div className="not-auth__title not-auth__title--not-active">
            {translate('notActiveModal.title')}
          </div>

          <div className="not-auth__btn">
            <Link to="/otbor/" className="btn btn-primary btn--new">
              {translate('notActiveModal.btn1')}
            </Link>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-outline-primary btn--new"
            >
              {translate('back')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

NotActiveModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NotActiveModal;
