import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Cropper from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { useTranslation } from 'src/hooks';
import { adminActions } from 'src/store/actions';

import getCroppedImg from './cropImage';

const CoverUpload = ({ show, handleClose }) => {
  const { translate } = useTranslation();
  const { id } = useSelector((state) => state.adminReducer.user.company);
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.1);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    if (croppedImage) {
      const file = new File([croppedImage], 'image.jpeg', {
        type: croppedImage.type,
      });

      dispatch(adminActions.companyUpdateCover({ id, file }));
      handleClose();
    }
  }, [croppedImage]);

  const onCropChange = (cropData) => {
    setCrop(cropData);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels2) => {
    setCroppedAreaPixels(croppedAreaPixels2);
  };

  const onZoomChange = (zoomData) => {
    setZoom(zoomData);
  };

  const showCroppedImage = async () => {
    const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels);

    setCroppedImage(croppedImg);
  };

  const readFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });

  const onFileInputChange = async (event) => {
    const res = await readFile(event.target.files[0]);

    setImageSrc(res);
  };

  return (
    <Modal show={show} onHide={handleClose} className="spec-modal">
      <Modal.Body>
        <div className="exp-modal exp-modal-pic">
          <div className="modal-header">
            <h5 className="modal-title">{translate('About.addShablon')}</h5>
            <button type="button" onClick={handleClose} className="spec-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalCloseWhite`} />
              </svg>
            </button>
          </div>

          <div className="exp-modal__addProect exp-modal__addProect--about">
            <form id="formpic">
              {!imageSrc ? (
                <label htmlFor="upload2" className="exp-modal__file">
                  <div className="text">{translate('About.oblojka')}</div>
                  <svg width="404px" height="250px">
                    <use xlinkHref={`${sprite}#thumbnail`} />
                  </svg>

                  <div className="text2">
                    {translate('About.uploadLabel')}
                    <br />
                    {translate('About.uploadLabelSize')}

                    <span>{translate('About.uploadOr')}</span>
                    <button type="button" className="btn btn-primary">
                      {translate('About.uploadText')}
                    </button>
                  </div>

                  <input
                    type="file"
                    className="file"
                    accept="image/png, image/gif, image/jpeg"
                    id="upload2"
                    onChange={onFileInputChange}
                  />
                </label>
              ) : (
                <div className="exp-modal__file upload">
                  <div className="text">Фото обложки</div>

                  <div className="cropContainer">
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={520 / 131}
                      onCropChange={onCropChange}
                      onCropComplete={onCropComplete}
                      onZoomChange={onZoomChange}
                    />
                  </div>
                  <div className="cropRange">
                    <input
                      type="range"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e) => {
                        setZoom(e.target.value);
                      }}
                      className="zoom-range"
                    />
                  </div>

                  <button
                    type="button"
                    className="btn btn-reset text-edit"
                    onClick={() => setImageSrc(null)}
                  >
                    {translate('About.uploadChange')}
                  </button>
                </div>
              )}
            </form>
          </div>

          <div className="exp-modal__addProect exp-modal__addProect--about" />

          <div className="exp-modal-footer">
            <div className="upload-wrap__btns">
              <button type="button" className="btn btn-primary" onClick={showCroppedImage}>
                {translate('save')}
              </button>
              <button type="button" className="btn btn-outline-primary" onClick={handleClose}>
                {translate('cancel')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
CoverUpload.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default CoverUpload;
