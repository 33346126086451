import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from 'src/components';
import { customActions } from 'src/store/actions';

const Detail = ({ setDetailname }) => {
  const dispatch = useDispatch();
  const waiter = useSelector((state) => state.customReducer.waiter);
  const { slug } = useParams();
  const knowledgeDetail = useSelector((state) =>
    state.customReducer.knowledgeBase.filter((item) => item.slug === slug),
  )[0];

  useEffect(() => {
    dispatch(customActions.knowledgeBase());
  }, []);

  useEffect(() => {
    if (knowledgeDetail) {
      setDetailname(knowledgeDetail.name);
    }
  }, [knowledgeDetail]);

  const getNewData = (data) => new Date(data).toISOString().substring(0, 10);

  return (
    <>
      {waiter ? (
        <Loader />
      ) : (
        <>
          {knowledgeDetail && (
            <div className="base__detail">
              <p className="data">{getNewData(knowledgeDetail.created)}</p>
              <h3>{knowledgeDetail.name}</h3>
              <h4>{knowledgeDetail.subtitle}</h4>
              <div dangerouslySetInnerHTML={{ __html: knowledgeDetail.text }} />
            </div>
          )}
        </>
      )}
    </>
  );
};

Detail.propTypes = {
  setDetailname: PropTypes.func.isRequired,
};

export default Detail;
